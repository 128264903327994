import React, {useEffect, useState} from "react";
import {Box, FormLabel, Typography, useTheme} from "@mui/material";
import Person from "../../../model/Person";
import PersonCard from "./PersonCard";
import IconicButton from "../../../components/controls/IconicButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Model from "../../../model/Model";
import Visible from "../../../components/Visible";
import {createUUID} from "../../../stores/StoreUtilities";
import TimelineChart from "./charts/TimelineChart";
import Plan from "../../../model/Plan";
import {useResources} from "../../../stores/ResourceProvider";

enum TimelineChartView {
  Timeline
}

const TimelineChartNames: string[] = [
  "Retirement Timeline"
]

const TimelineView = ({
  model,
  plan,
  updatedAt,
  onChange
} : {
  model: Model
  plan: Plan
  updatedAt: string
  onChange?(model: Model): void
}) => {
  const [persons, setPersons] = useState<Person[]>([])
  const [chartView, setChartView] = useState<TimelineChartView>(TimelineChartView.Timeline)

  const {modelStore} = useResources()
  const theme = useTheme()

  useEffect(() => {
    if (model) {
        setPersons(model.persons.filter((p: Person) => p.id !== model.jointId))
    }
  }, [model, plan, updatedAt])

  const styles = {
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"],
    },
  }

  const handleAddPerson = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (model) {
      const newPerson = new Person({
        id: createUUID(),
        accountId: model.accountId,
        userId: model.userId,
        modelId: model.id,
        nickname: `Person ${model.persons.length + 1}`,
      })
      setPersons([...persons, newPerson])
    }
  }

  const handleSavePerson = async (person: Person) => {
    setPersons(model.persons.filter((p: Person) => p.id !== model.jointId))
    if (onChange) {
      onChange(model)
    }
  }

  const handleDeletePerson = (deleted: Person) => {
    const index = persons.findIndex((p: Person) => p.id === deleted.id)
    if (index >= 0) {
      persons.splice(index, 1)
    }
    setPersons([...persons])
    if (onChange) {
      onChange(model)
    }
  }

  // const renderViewMenu = () => {
  //
  //   return (
  //     <ViewMenu
  //       view={chartView}
  //       variant="auto"
  //       options={TimelineChartNames}
  //       onChange={(index: number) => {
  //         setChartView(index)
  //       }}
  //     />
  //   )
  // }

  return (
    <Box sx={{display:"flex", flexDirection:"row", mt:1, minHeight: "calc(100vh - 150px)"}}>
      <Box sx={{
        width: { xs:"100%", sm:360}, minHeight:"100%"}}>
        <Box sx={{display:"flex", mt:0, pl:0, pb:0, mb:1}}>
          <Typography variant="h2" color="primary">Timeline</Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          {persons.map((person: Person, index: number) =>
            <PersonCard person={person} model={model} key={`person-${index}`}
                        onSave={handleSavePerson}
                        onDelete={handleDeletePerson}/>
          )}
          <Visible cond={persons.length < 2}>
            <IconicButton onClick={handleAddPerson} label="Add Person">
              <AddCircleIcon sx={{color: theme.palette.secondary.light}}/>
            </IconicButton>
          </Visible>
        </Box>

      </Box>

      <Box width="100%" pl={2} py={0} mt={0}>
        <Box sx={{display:"flex", flexGrow:1, flexDirection:"row", justifyContent:"stretch", paddingLeft: 0, paddingRight: 0.5, maxHeight: 50}}>
          <Box sx={{display:"flex", flexGrow:2, flexDirection:"column", minWidth:250}}>
            <Box sx={{display:"flex", flexGrow:1, justifyContent:"space-between", maxHeight:16}}>
              <FormLabel sx={styles.formLabel}>&nbsp;</FormLabel>
            </Box>
            <Box sx={{display:"flex", flexGrow:1, flexWrap:"nowrap", marginBottom:"4px"}}>
              <Typography variant="h3" color="primary" gutterBottom>Retirement Timeline</Typography>
            </Box>
          </Box>
        </Box>
        <TimelineChart persons={persons} showTable/>
      </Box>
    </Box>
  )
}

export default TimelineView