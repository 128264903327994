import {UserRole} from "../API";


class UserInvite {
  id: string
  createdAt: string
  updatedAt: string
  userId!: string
  accountId: string
  firstName: string
  lastName: string
  email: string
  role: UserRole
  invitedOn: string

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.userId = data.userId
    this.accountId = data.accountId
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.email = data.email
    this.role = data.role
    this.invitedOn = data.invitedOn
  }
}

export default UserInvite