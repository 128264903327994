import User from "../../model/User";
import {useState} from "react";
import {useResources} from "../../stores/ResourceProvider";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import DialogButton from "../../components/form/DialogButton";
import * as React from "react";
import {FormLabel, Grid, Typography, useTheme} from "@mui/material";
import {isoToLocalDateString} from "../../stores/StoreUtilities";
import ProgressButton from "../../components/form/ProgressButton";
import {htmlDecode} from "js-htmlencode";

const MigrateUserDialog = ({
  open,
  user,
  onClose,
  onMigrate
}: {
  open?: boolean
  user: any,
  onClose(): void
  onMigrate?(user: User): void
}) => {
  const [isMigrating, setIsMigrating] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("")
  const [result, setResult] = useState<"" | "in progress" | "success" | "error">("")
  const [response, setResponse] = useState<any>(0)

  const { migrateAPI, userStore} = useResources()
  const theme = useTheme()

  const styles = {
    row: {
      height: 40,
      minHeight: 40,
      maxHeight: 40,
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "1.0em",
      maxHeight: 15,
      textWrap: "nowrap",
      color: theme.palette.grey["700"]
    },
  }

  const intervalDuration = 10 * 1000 // seconds
  let intervalId: number = 0

  const handleMigrate = async () => {
    setIsMigrating(true)
    setResult("in progress")
    setMessage("Migration in progress...")
    setResponse(undefined)

    try {
      const response = await migrateAPI.migrateUser(user.user_id)
      setResponse(response)
      setResult("success")
      setMessage(`Migration succeeded!`)
      setIsMigrating(false)
    } catch (err: any) {
      if (err.cause === 504) {
        intervalId = window.setInterval(waitOnMigrate, intervalDuration)
        console.debug(`intervalId = ${intervalId}`)
      } else {
        setResult("error")
        setMessage(`Error: ${err.message}`)
        setIsMigrating(false)
      }
    }
  }

  const waitOnMigrate = () => {
    console.debug(`waitOnMigrate`)
    userStore.getUserOnly(String(user.user_id))
      .then((user: User | undefined) =>{
        if (user && user.migrateStatus) {
          console.debug(`migrateStatus: ${user.migrateStatus}`)
          if (user.migrateStatus.startsWith("In Progress")) {
            setMessage(user.migrateStatus)
          } else if (user.migrateStatus.startsWith("Error")) {
            setMessage(`Migration Error: ${user.migrateStatus}`)
            setResult("error")
            console.debug(`clearInterval(${intervalId})`)
            window.clearInterval(intervalId)
            intervalId = 0
            setIsMigrating(false)
          } else if (user.migrateStatus.startsWith("Success")) {
            setMessage("Migration Success!")
            setResult("success")
            setResponse(user)
            console.debug(`clearInterval(${intervalId})`)
            window.clearInterval(intervalId)
            intervalId = 0
            setIsMigrating(false)
          }
        }
      })
      .catch((err: any) =>{
        setMessage(err.message)
        setResult("error")
        console.debug(`clearInterval(${intervalId})`)
        window.clearInterval(intervalId)
        intervalId = 0
        setIsMigrating(false)
      })
  }

  const handleClose = () => {
    if (result === "success" && response && onMigrate) {
      const user = new User(response)
      onMigrate(user)
    } else {
      if (intervalId) {
        console.debug(`clearInterval(${intervalId})`)
        window.clearInterval(intervalId)
        intervalId = 0
      }
      onClose()
    }
  }

  const getAccountStatusDescription = (account_status: number) => {
    let description
    switch (account_status) {
      case -1:
        description = "Deleted"
        break
      case 0:
        description = "Cancelled"
        break
      case 1:
        description = "Lifetime"
        break
      case 2:
        description = "Legacy"
        break
      case 3:
        description = "Premium"
        break
      case 4:
        description = "Free"
        break
      case 5:
        description = "Client"
        break
      default:
        description = "Unknown"
        break
    }
    return description
  }

  const instructions = user.migratedOn ? "Confirm re-migrating this user. Existing data will be replaced." :
                                        "Click Migrate to import this user."
  
  return (
    <Dialog
      id="migrateUserDialog"
      open={open === true}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth
      aria-labelledby="migrateUserDialogTitle"
    >
      <DialogTitle id="migrateUserDialogTitle">
        Confirm Migrate User
      </DialogTitle>
      <DialogContent sx={{mt:2}}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="body1" sx={{lineHeight:1.1, paddingBottom:1}}>{instructions}</Typography>
          </Grid>
          <Grid item xs={12} sx={styles.row}>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={6}>
                <FormLabel sx={styles.formLabel}>Name</FormLabel>
                <Typography variant="body2">{htmlDecode(user.user_fname)} {user.user_lname}</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormLabel sx={styles.formLabel}>Email</FormLabel>
                <Typography variant="body2">{user.user_email}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={styles.row}>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={6}>
                <FormLabel sx={styles.formLabel}>Account Status</FormLabel>
                <Typography variant="body2">{getAccountStatusDescription(user.account_status)}</Typography>
              </Grid>
              {user.migratedOn &&
                <Grid item xs={6}>
                  <FormLabel sx={styles.formLabel}>Migrated Date</FormLabel>
                  <Typography variant="body2">{user.migratedOn ? isoToLocalDateString(user.migratedOn, "M/d/yyyy h:mm a") : ""}</Typography>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center" sx={{
              color: result === "error" ? theme.palette.error.main : theme.palette.success.main,
              fontWeight:400,
              paddingTop: 1
            }}>
              {message}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          <DialogButton variant="secondary" type="button" spacing="right" onClick={handleClose}>
            {result === "success" ? "Close" : "Cancel"}
          </DialogButton>
          {result !== "success" &&
            <ProgressButton
              size="medium"
              variant="contained"
              color="primary"
              fullWidth={false}
              type="button"
              onClick={handleMigrate}
              processing={isMigrating}
            >
              Migrate
            </ProgressButton>
          }
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default MigrateUserDialog