import {observer} from "mobx-react";
import {useResources} from "../stores/ResourceProvider";
import * as React from "react";
import {useEffect, useState} from "react";
import {
  Box,
  DialogContentText,
  FormControlLabel,
  Grid, IconButton,
  InputAdornment,
  Paper,
  Typography,
  useTheme
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {Link} from "@reach/router";
import {
  CreateAccountInput,
  CreatePersonInput,
  CreateUserInput,
  Gender,
  MaritalStatus,
  UpdatePersonInput,
  UpdateUserInput,
  UserRole,
  UserStatus,
  UserType
} from "../API";
import User from "../model/User";
import Account from "../model/Account";
import Model from "../model/Model";
import {getISODateFromDate, humanizeString, isoToLocalDate} from "../stores/StoreUtilities";
import {AccountStoreConstants} from "../stores/AccountStore";
import {UserStoreConstants} from "../stores/UserStore";
import Tracking from "../components/Tracking";
import Logger from "../components/Logger";
import {differenceInYears} from "date-fns";
import RoutesConfig, {RoutesConfigHelpers} from "../RoutesConfig";
import TrialImg from "../images/30daytrial.png";
import CheckAlt from "../images/CheckAlt.png";
import FormValidator from "../components/form/FormValidator";
import Visible from "../components/Visible";
import TextFieldValidator from "../components/form/TextFieldValidator";
import CheckboxValidator from "../components/form/CheckboxValidator";
import DialogButton from "../components/form/DialogButton";
import FormGroupSpacer from "../components/form/FormGroupSpacer";
import DatePickerValidator from "../components/form/DatePickerValidator";
import ProgressButton from "../components/form/ProgressButton";
import globalStyles from "../styles/globalStyles";
import Page from "../components/page/Page";
import MarginRow from "../components/page/MarginRow";
import UserInvite from "../model/UserInvite";
import Person, {GenderOptions} from "../model/Person";
import LoadingProgress from "../components/LoadingProgress";
import Footer from "../components/page/Footer";
import TermsOfUseDialog from "../components/page/TermsOfUseDialog";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import useNavigationHelper from "../components/NavigationHelper";

interface InvitePageProps {
  token?: string
}

enum SignupStep {
  Loading,
  UserInfo,
  Confirming,
  Person1,
  Person2
}

const InvitePage = observer((props: InvitePageProps) => {
  const { accountStore, notify, progress, userStore, modelStore, calculator, privacyPolicy } = useResources()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [step, setStep] = useState<SignupStep>(SignupStep.Loading)
  const [message, setMessage] = useState<string | undefined>()
  const [route, setRoute] = useState<undefined>()

  const theme = useTheme()
  const navigate = useNavigate();
  const {navigateHome} = useNavigationHelper()
  const location = useLocation()

  const [userInvite, setUserInvite] = useState<UserInvite | undefined>()
  const [accountType, setAccountType] = useState<string>("Cusotmer")
  const [accountName, setAccountName] = useState<string>("")
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [userId, setUserId] = useState<string>("")
  const [accountId, setAccountId] = useState<string>("")
  const [role, setRole] = useState<UserRole>(UserRole.Customer)
  const [confirmationCode, setConfirmationCode] = useState<string>("")
  const [termsCheckbox, setTermsCheckbox] = useState<boolean>(false)
  const [nickname1, setNickname1] = useState<string>("")
  const [gender1, setGender1] = useState<Gender | undefined>()
  const [birthDate1, setBirthDate1] = useState<Date | undefined>()
  const [retireDate1, setRetireDate1] = useState<Date | undefined>()
  const [maritalStatus, setMaritalStatus] = useState<MaritalStatus | undefined>()
  const [nickname2, setNickname2] = useState<string>("")
  const [gender2, setGender2] = useState<Gender | undefined>()
  const [birthDate2, setBirthDate2] = useState<Date | undefined>()
  const [retireDate2, setRetireDate2] = useState<Date | undefined>()
  const [showTermsOfUse, setShowTermsOfUse] = useState<boolean>(false)
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)

  let user: User | void | null
  let account: Account | undefined | void = undefined
  let model: Model | void | null

  useEffect(() => {
    if (step === SignupStep.Loading) {
      const token = RoutesConfigHelpers.getPathSegment(location.pathname, 2)
      if (token) {
        accountStore.getUserInvite(token)
          .then((invite: UserInvite | undefined) => {
            if (invite) {
              setUserInvite(invite)
              setFirstName(invite.firstName)
              setLastName(invite.lastName)
              setEmail(invite.email)
              setPassword("")
              setUserId(invite.userId)
              setAccountId(invite.accountId)
              setRole(invite.role)
              setConfirmationCode("")
              setTermsCheckbox(false)
              setNickname1(invite.firstName)
              setGender1(undefined)
              setBirthDate1(undefined)
              setRetireDate1(undefined)
              setMaritalStatus(undefined)
              setNickname2("")
              setGender2(undefined)
              setBirthDate2(undefined)
              setRetireDate2(undefined)

              if (userStore.isAuthenticated && userStore.user!.id === invite.userId) {
                // This code is necessary because the page gets reloaded after signing in the new user
                const user = userStore.user
                let model = modelStore.models.find((m: Model) => m.description === "Primary")
                if (model) {
                  modelStore.setCurrentModel(model)
                }
                modelStore.getCurrentModel()
                    .then ((model: Model | undefined) => {
                      // Set values
                      if (model) {
                        const person1 = model.person1
                        if (person1) {
                          if (person1.nickname && person1.gender && person1.birthDate && person1.retireDate && person1.maritalStatus) {
                            // Skip person setup
                            return
                          }
                          setGender1(person1.gender)
                          if (person1.birthDate) {
                            setBirthDate1(isoToLocalDate(person1.birthDate))
                          }
                          if (person1.retireDate) {
                            setRetireDate1(isoToLocalDate(person1.retireDate))
                          }
                          if (person1.maritalStatus) {
                            setMaritalStatus(person1.maritalStatus)
                          }
                        }
                        const person2 = model.person2
                        if (person2) {
                          setNickname2(person2.nickname)
                          setGender2(person2.gender)
                          if (person2.birthDate) {
                            setBirthDate2(isoToLocalDate(person2.birthDate))
                          }
                          if (person2.retireDate) {
                              setRetireDate2(isoToLocalDate(person2.retireDate))
                          }
                        }
                        setStep(SignupStep.Person1)
                      }
                    })
              } else {
                setStep(SignupStep.UserInfo)
              }
            }
          })
          .catch ((error: any) => {
            setMessage(`Sorry, invitation not found`)
          })
      }
    }
  }, [step])

  const styles = {

  }

  const handleAcceptTerms = (accept: boolean) => {
    setTermsAccepted(accept)
    setTermsCheckbox(accept)
    setShowTermsOfUse(false)
  }

  const onSubmit = async () => {
    if (step === SignupStep.UserInfo) {
      // Sign-up user
      setIsProcessing(true)

      if (!termsCheckbox) {
        notify!.show("error", "You must check the Terms box to indicate you agree with the Terms of Use and Privacy Policy.")
        setIsProcessing(false)
        return
      }

      const signupResult = await userStore!.signUp(userId, password, email.toLowerCase(), undefined,
        accountId, role)
        .catch((err: Error) => {
          let message
          if (err.name === UserStoreConstants.USERNAME_EXISTS_EXCEPTION || err.message === UserStoreConstants.EMAIL_EXISTS_MESSAGE) {
            message = "This email is already in use."
            Tracking.event({action: 'SignupError', label:"Email in use"})
          } else {
            message = `Unable to create user. ${err.message}`
            Tracking.event({action: 'SignupError', label: `Unable to create user: ${err.message}`})
          }
          notify!.show("error", message)
          setIsProcessing(false)
        })

      // setNickname1(firstName)

      if (signupResult) {
        setStep(SignupStep.Confirming)
      }
      setIsProcessing(false)
    } else if (step === SignupStep.Confirming) {
      setIsProcessing(true)

      const confirmation = await userStore!.confirmSignUp(userId, confirmationCode)
        .catch((err: Error) => {
          notify!.show("error", "Unable to verify account")
          Tracking.event({action: 'SignupError', label: `Unable to verify account: ${err.message}`})
          setIsProcessing(false)
        })

      if (confirmation === "SUCCESS") {
        // Sign in
        user = await userStore!.signIn(userId, password)
          .catch((err: Error) => {
            if (err.message !== UserStoreConstants.USER_NOT_FOUND) {
              notify!.show("error", "Unable to log in to account")
              Tracking.event({action: 'SignupError', label: `Unable to log into account: ${err.message}`})
              setIsProcessing(false)
            }
          })

        if (!user) {
          // Create user
          const createUserInput: CreateUserInput = {
            id: userId,
            userType: UserType.Premium,
            userStatus: UserStatus.Active,
            accountId: AccountStoreConstants.PRIMARY_ACCOUNT_ID,
            firstName: firstName,
            lastName: lastName,
            email: email.toLowerCase(),
            role: role,
          }

          user = await userStore!.createUser(createUserInput, true)
            .catch((err: Error) => {
              notify!.show("error", "Unable to create user")
              Tracking.event({action: 'SignupError', label: `Unable to create user: ${err.message}`})
              setIsProcessing(false)
            })

          if (user) {
            // Get Primary account
            account = await accountStore.loadAccount(AccountStoreConstants.PRIMARY_ACCOUNT_ID)
            if (!account) {
              // Create primary account
              const createAccountInput: CreateAccountInput = {
                id: accountId,
                ownerId: user.id,
                name: `RBC Primary`,
              }

              account = await accountStore.createAccount(createAccountInput)
                .catch((err: Error) => {
                  notify!.show("error", "Unable to create account")
                  Tracking.event({action: 'SignupError', label: `Unable to create account: ${err.message}`})
                  setIsProcessing(false)
                })
            }

            if (account) {
              if (user) {
                // Sign in again to get updated attributes and groups
                user = await userStore!.signIn(email.toLowerCase(), password)
                  .catch((err: Error) => {
                    notify!.show("error", "Unable to sign in new user")
                    Logger.error('Unable to sign in new user', err)
                    Tracking.event({action: 'SignupError', label: `Unable to sign in new user: ${err.message}`})
                    setIsProcessing(false)
                  })
              }
            }
          } else {
            user = await userStore!.signIn(email.toLowerCase(), password)
              .catch((err: Error) => {
                notify!.show("error", "Unable to sign in new user")
                Logger.error('Unable to sign in new user', err)
                Tracking.event({action: 'SignupError', label: `Unable to sign in new user: ${err.message}`})
                setIsProcessing(false)
              })
          }

          setIsProcessing(false)
        }

        if (user) {
          account = await accountStore!.init(user.account)
          const models = await modelStore.loadUserModels(user)
          // Look for primary model
          let model = models.find((m: Model) => m.description === "Primary")
          if (model) {
            modelStore.setCurrentModel(model)
            const input: UpdateUserInput = {
              id: user.id,
              currentModelId: model.id
            }
            await userStore.updateUser(input)
          }
          model = await modelStore.getCurrentModel()
          if (model) {
            const person1 = model.person1
            if (person1) {
              if (person1.nickname && person1.gender && person1.birthDate && person1.retireDate && person1.maritalStatus) {
                // Skip Person setup
                await completeInvite(userStore.user!)
                initSettings()
                notify!.show("success", "Welcome to Retirement Budget Calculator!")
                navigate(`${RoutesConfig.calc.pathname}`)
                return
              }
              setNickname1(person1.nickname)
              setGender1(person1.gender)
              if (person1.birthDate) {
                setBirthDate1(isoToLocalDate(person1.birthDate))
              }
              if (person1.retireDate) {
                setRetireDate1(isoToLocalDate(person1.retireDate))
              }
              if (person1.maritalStatus) {
                setMaritalStatus(person1.maritalStatus)
              }
            }
            const person2 = model.person2
            if (person2) {
              setNickname2(person2.nickname)
              setGender2(person2.gender)
              if (person2.birthDate) {
                setBirthDate2(isoToLocalDate(person2.birthDate))
              }
              if (person2.retireDate) {
                setRetireDate2(isoToLocalDate(person2.retireDate))
              }
            }
          }
          setStep(SignupStep.Person1)
        }
      }
    } else if (step === SignupStep.Person1) {
      account = accountStore.account
      model = await modelStore.getCurrentModel()

      if (account && model) {
        if (model.persons.length >= 1) {
          // Update person 1
          let lifeExpectancy
          if (birthDate1) {
            const age = Math.floor(differenceInYears(new Date(), birthDate1))
            lifeExpectancy = Math.round(calculator.lifeExpectancyYears(age, 0, gender1 === Gender.Male)) + age
          }

          const personUpdate: UpdatePersonInput = {
            id: model.persons[0].id,
            nickname: nickname1,
            gender: gender1,
            maritalStatus: maritalStatus,
            birthDate: birthDate1 ? getISODateFromDate(birthDate1) : null,
            retireDate: retireDate1 ? getISODateFromDate(retireDate1) : null,
            lifeExpectancy: lifeExpectancy
          }
          const person1 = await modelStore.updatePerson(personUpdate)
        }
      }

      if (maritalStatus === MaritalStatus.Married) {
        setStep(SignupStep.Person2)
      } else {
        await completeInvite(userStore.user!)
        initSettings()
        notify!.show("success", "Welcome to Retirement Budget Calculator!")
        navigate(`${RoutesConfig.calc.pathname}`)
      }
    } else if (step === SignupStep.Person2) {
      account = accountStore.account
      model = await modelStore.getCurrentModel()
      let person2: Person | undefined

      if (account && model) {
        let lifeExpectancy
        if (birthDate2) {
          const age = Math.floor(differenceInYears(new Date(), birthDate2))
          lifeExpectancy = Math.round(calculator.lifeExpectancyYears(age, 0, gender2 === Gender.Male)) + age
        }
        if (model.person2) {
          const personUpdate: UpdatePersonInput = {
            id: model.person2.id,
            accountId: account.id,
            userId: model.userId,
            modelId: model.id,
            nickname: nickname2,
            gender: gender2,
            maritalStatus: maritalStatus,
            birthDate: birthDate2 ? getISODateFromDate(birthDate2) : null,
            retireDate: retireDate2 ? getISODateFromDate(retireDate2) : null,
            lifeExpectancy: lifeExpectancy
          }

          person2 = await modelStore.updatePerson(personUpdate)
        } else {
          const personInput: CreatePersonInput = {
            accountId: account.id,
            userId: model.userId,
            modelId: model.id,
            nickname: nickname2,
            gender: gender2,
            maritalStatus: maritalStatus,
            birthDate: birthDate2 ? getISODateFromDate(birthDate2) : null,
            retireDate: retireDate2 ? getISODateFromDate(retireDate2) : null,
            lifeExpectancy: lifeExpectancy
          }

          person2 = await modelStore.createPerson(personInput)
        }
        if (person2) {
          await completeInvite(userStore.user!)
          initSettings()
          notify!.show("success", "Welcome to Retirement Budget Calculator!")
          navigate(`${RoutesConfig.calc.pathname}`)
        }
      }
    }
  }

  const completeInvite = async (user: User) => {
    const update : UpdateUserInput = {
      id: user.id,
      userStatus: UserStatus.Active
    }
    await userStore.updateUser(update)
    await accountStore.revokeUserInvite(user)
  }

  const initSettings = () => {
    const pageViewSettings = {
      chatOpen: true
    }
    window.localStorage.setItem(`${userStore.user!.id}.PageViewSettings`, JSON.stringify(pageViewSettings))
    Tracking.gtag("event", "sign_up", {method: "Invited"})
  }

  const onCancel = () => {
    navigateHome()
  }

  const onResendCode = async () => {
    userStore!.resendSignUp(userId)
      .then((result: any) => {
        notify!.show("success", "Verification code resent")
        Tracking.event({action: 'SignupResendCode'})
      })
      .catch((err: Error) => {
        notify!.show("error", "Unable to resend verification code")
        Tracking.event({action: 'SignupError', label: `Unable to resend verification code: ${err.message}`})
      })
  }

  const renderPromo = () => {

    return (
      <Paper
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          overflow: 'hidden',
          mx: {
            md: 'auto'
          },
          p: 2,
          maxWidth: {
            md: theme.breakpoints.values.lg
          },
          minHeight: 532
        }}
        elevation={2}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h2" color="primary">Live the life you want with confidence!</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1"><b>Retirement Budget Calculator</b> is an easy-to-use tool designed to help you make the best decisions so that you can achieve your goals and live the life you want.</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1"><b>RBC will help you:</b></Typography>
            <ul>
              <li><Typography variant="body1">Project how much you will need in retirement.</Typography></li>
              <li><Typography variant="body1">Simulate different scenarios.</Typography></li>
              <li><Typography variant="body1">See the impact of different withdrawal strategies.</Typography></li>
              <li><Typography variant="body1">Create a cash flow plan.</Typography></li>
            </ul>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" sx={{py:2}}>
              <Grid item xs={2}>
              </Grid>
              <Grid item xs={2}>
                <img src={TrialImg} alt="Risk Free Trial"/>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1" sx={{fontStyle:"italic"}}>
                  Try Retirement Budget Calculator for free!<br/>
                  No credit card required!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box display="flex" justifyContent="space-evenly" my={2}>
              <Box display="flex" alignItems="center">
                <img src={CheckAlt} alt="Check"/>
                <Typography variant="h3" sx={{pl:2}}>Clarity</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <img src={CheckAlt} alt="Check"/>
                <Typography variant="h3" sx={{pl:2}}>Confidence</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <img src={CheckAlt} alt="Check"/>
                <Typography variant="h3" sx={{pl:2}}>Freedom</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  const renderSignUpDialog = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          overflow: 'hidden',
          mx: {
            md: 'auto'
          },
          p: 2,
          maxWidth: {
            md: theme.breakpoints.values.lg
          },
        }}
      >
        {/*<Typography variant="h4" color="primary" gutterBottom sx={{letterSpacing: "0.1em"}}>*/}
        {/*  STEP {step === SignupStep.UserInfo ? "1" : "2"} OF 2*/}
        {/*</Typography>*/}
        <Typography variant="h1" gutterBottom>
          Your RBC invitation!
        </Typography>
        <Typography variant="body1">
          Congratulations, you are just a few steps away from knowing if you are on track for a successful retirement!
        </Typography>
        <FormValidator
          // onSubmit={onSubmit}
          autoComplete="off"
          name="signupForm"
          id="signupForm"
        >
          <Box py={2}>
            <Visible cond={step === SignupStep.UserInfo}>
              <TextFieldValidator
                margin="dense"
                name="firstName"
                label="First Name"
                // autoFocus
                type="text"
                variant="standard"
                validators={{required:true}}
                onChange={(event: any) => setFirstName(event.target.value)}
                value={firstName}
                placeholder=""
                fullWidth
              />
              <TextFieldValidator
                margin="dense"
                name="lastName"
                label="Last Name"
                type="text"
                variant="standard"
                validators={{required:true}}
                onChange={(event: any) => setLastName(event.target.value)}
                value={lastName}
                placeholder=""
                fullWidth
              />
              <TextFieldValidator
                margin="dense"
                name="email"
                id="EmailInput"
                label="Email"
                variant="standard"
                placeholder=""
                type="text"
                validators={{required:true, isEmail: true}}
                value={email}
                disabled={true}
                fullWidth
                autoCorrect="off"
                autoCapitalize="off"
                autoComplete="off"
              />
              <TextFieldValidator
                margin="dense"
                name="password"
                label="Create Password"
                type={showPassword ? "text" : "password"}
                variant="standard"
                validators={{required:true, isStrongPassword:3}}
                autoComplete="off"
                value={password}
                onChange={(event: any) => setPassword(event.target.value)}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {/*<TextFieldValidator*/}
              {/*  margin="dense"*/}
              {/*  name="phone"*/}
              {/*  label="Phone Number"*/}
              {/*  type="text"*/}
              {/*  variant="standard"*/}
              {/*  validators={{isMobilePhone:null, required:true}}*/}
              {/*  onChange={onChange}*/}
              {/*  value={phone}*/}
              {/*  fullWidth*/}
              {/*/>*/}
              <FormControlLabel
                sx={{ px: 2, py: 1 }}
                control={
                  <CheckboxValidator
                    name="termsCheckbox"
                    color="primary"
                    checked={termsCheckbox}
                    value="checkedG"
                    onChange={(event: any) => {
                      if (event.target.checked) {
                        if (!termsAccepted) {
                          setTermsCheckbox(false)
                          setShowTermsOfUse(true)
                        } else {
                          setTermsCheckbox(true)
                        }
                      } else {
                        setTermsCheckbox(false)
                        setTermsAccepted(false)
                      }
                    }}
                  />
                }
                label={
                  <Typography variant="body2" sx={{ pl: 1 }}>
                    I confirm that I am signing up from within the USA and agree to the RBC&nbsp;
                    <Link to="." onClick={() => setShowTermsOfUse(true)} style={globalStyles.link}>Terms of Use</Link> and&nbsp;
                    <Link to="." onClick={() => privacyPolicy.show()} style={globalStyles.link}>Privacy Policy</Link>. I give permission to communicate with me via email.
                  </Typography>                }
              />
            </Visible>

            { message &&
              <DialogContentText
                sx={{
                  color: 'red',
                  marginTop: 10
                }}
              >
                {message}
              </DialogContentText>
            }

            <Visible cond={step === SignupStep.Confirming}>
              <Typography variant="body2">
                Please check your email for a verification code.
              </Typography>
              <TextFieldValidator
                margin="dense"
                name="confirmationCode"
                label="Verification Code"
                type="text"
                variant="standard"
                value={confirmationCode}
                validators={{required:true, matches:"^\\d{6}$"}}
                onChange={(event: any) => setConfirmationCode(event.target.value)}
                fullWidth
              />
              <DialogButton variant="tertiary" onClick={onResendCode}>
                Resend verification code
              </DialogButton>

            </Visible>

            <Visible cond={step === SignupStep.Person1}>
              <Typography variant="h4">
                Please complete your retirement details:
              </Typography>
              <TextFieldValidator
                margin="dense"
                name="nickname1"
                label="Preferred Name"
                autoFocus
                type="text"
                variant="standard"
                validators={{required:true}}
                value={nickname1}
                onChange={(event: any) => setNickname1(event.target.value)}
                placeholder=""
                fullWidth
              />
              <Box display="flex" justifyContent="space-between">
                <TextFieldValidator
                  type="text"
                  validators={{ required: true }}
                  required
                  name="gender1"
                  label="Gender"
                  value={gender1}
                  variant="standard"
                  fullWidth
                  styleProp={{width:"100%"}}
                  autocompleteOptions={{
                    sx: {width:"100%"},
                    freeSolo: false,
                    options: GenderOptions,
                    value: gender1,
                    onChange: (event: any, value: Gender, reason: any) => {
                      setGender1(value)
                    }
                  }}
                />
                <FormGroupSpacer/>
                <TextFieldValidator
                  type="text"
                  validators={{ required: true }}
                  required
                  name="maritalStatus"
                  label="Marital Status"
                  value={maritalStatus}
                  variant="standard"
                  fullWidth
                  styleProp={{width:"100%"}}
                  autocompleteOptions={{
                    sx: {width:"100%"},
                    freeSolo: false,
                    options: Object.values(MaritalStatus),
                    getOptionLabel: (option: string) => option ? humanizeString(option) : "",
                    value: maritalStatus,
                    onChange: (event: any, value: MaritalStatus, reason: any) => {
                      setMaritalStatus(value)
                    }
                  }}
                />
              </Box>
              <Box display="flex" flexGrow={1} justifyContent="space-between">
                <DatePickerValidator
                  name="birthDate1"
                  label="Birth Date"
                  value={birthDate1}
                  format="MM-dd-yyyy"
                  variant="standard"
                  margin="dense"
                  required
                  onChange={(newValue: any) => {
                    setBirthDate1(newValue)
                  }}
                />
                <FormGroupSpacer/>
                <DatePickerValidator
                  name="retireDate1"
                  label="Est Retirement Date"
                  value={retireDate1}
                  format="MM-dd-yyyy"
                  variant="standard"
                  margin="dense"
                  required
                  onChange={(newValue: any) => {
                    setRetireDate1(newValue)
                  }}
                />
              </Box>
            </Visible>

            <Visible cond={step === SignupStep.Person2}>
              <Typography variant="h4">
                Please complete your spouse's retirement details:
              </Typography>
              <TextFieldValidator
                margin="dense"
                name="nickname2"
                label="Preferred Name"
                autoFocus
                type="text"
                variant="standard"
                validators={{required:true}}
                onChange={(event: any) => setNickname2(event.target.value)}
                value={nickname2}
                placeholder=""
                fullWidth
              />
              <Box display="flex" justifyContent="space-between">
                <TextFieldValidator
                  type="text"
                  validators={{ required: true }}
                  required
                  name="gender2"
                  label="Gender"
                  value={gender2}
                  variant="standard"
                  fullWidth
                  styleProp={{width:"100%"}}
                  autocompleteOptions={{
                    sx: {width:"100%"},
                    freeSolo: false,
                    options: GenderOptions,
                    value: gender2,
                    onChange: (event: any, value: Gender, reason: any) => {
                      setGender2(value)
                    }
                  }}
                />
                <FormGroupSpacer/>
                <Box display="flex" flexGrow={1} width="100%"></Box>
              </Box>
              <Box display="flex" flexGrow={1} justifyContent="space-between">
                <DatePickerValidator
                  name="birthDate2"
                  label="Birth Date"
                  value={birthDate2}
                  format="MM-dd-yyyy"
                  variant="standard"
                  margin="dense"
                  required
                  onChange={(newValue: any) => {
                    setBirthDate2(newValue)
                  }}
                />
                <FormGroupSpacer/>
                <DatePickerValidator
                  name="retireDate2"
                  label="Est Retirement Date"
                  value={retireDate2}
                  format="MM-dd-yyyy"
                  variant="standard"
                  margin="dense"
                  required
                  onChange={(newValue: any) => {
                    setRetireDate2(newValue)
                  }}
                />
              </Box>
            </Visible>
          </Box>
          <Box display="flex" justifyContent="space-between">
            {/*<DialogButton*/}
            {/*  variant="secondary"*/}
            {/*  size="large"*/}
            {/*  onClick={onCancel}*/}
            {/*>*/}
            {/*  Cancel*/}
            {/*</DialogButton>*/}
            <ProgressButton
              size="large"
              variant="contained"
              color="secondary"
              sx={globalStyles.webFlowLargeButton}
              fullWidth={true}
              type="submit"
              onClick={onSubmit}
              processing={isProcessing}
            >
              { step === SignupStep.Confirming ? "Verify" : "Next" }
            </ProgressButton>
          </Box>
        </FormValidator>
      </Box>
    )
  }

  return (
    <Page title="Invite">
      <MarginRow maxWidth={theme.breakpoints.values.xl}>
        {step === SignupStep.Loading &&
          <LoadingProgress />
        }
        {step !== SignupStep.Loading &&
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="top"
            sx={{
              minHeight: 'calc(100vh - 206px)',
              my: {
                sm: 0,
                md: "auto"
              },
              px: {
                sm: 2,
                md: 0
              },
              py: {
                sm: 0,
                md: 4,
              }
            }}
            spacing={6}
          >
            <Grid item sm={12} md={6}>
              {renderPromo()}
            </Grid>
            <Grid item sm={12} md={6}>
              {renderSignUpDialog()}
            </Grid>
          </Grid>
        }
        {showTermsOfUse &&
          <TermsOfUseDialog onAccept={handleAcceptTerms}/>
        }
        <Footer/>
      </MarginRow>
    </Page>
  )})

export default InvitePage
