import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, FormControlLabel, IconButton, Radio, Tooltip, Typography, useTheme} from "@mui/material";
import Model from "../../model/Model";
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconicButton from "./IconicButton";
import {useResources} from "../../stores/ResourceProvider";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {UserType} from "../../API";

interface IModelMenuProps {
  sx?: any
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined
  value: Model
  options: Model[]
  onChange(option: Model): void
  onEdit?(option: Model): void
  onNew?(option?: Model): void
  onDelete?(option: Model): void
}

export const ClientModelMax = 4

export default function ModelMenu(props: IModelMenuProps) {
  const [canAddModel, setCanAddModel] = useState<boolean>(true)

  const theme = useTheme()
  const { userStore, accountStore, modelStore, confirm, notify } = useResources()
  const {options} = props

  useEffect(() => {
    if (userStore.isCustomer) {
      // Limit models
      const clientModels = options.filter((model: Model) => !model.advisorCreated)
      const userType = userStore!.user?.userType
      setCanAddModel((userType !== UserType.Free || clientModels.length > 1) && clientModels.length < ClientModelMax)
    }
  }, [options, userStore.isLoading])

  const styles = {
    checkedIcon: {
      height: 24,
      width: 24,
      color: theme.palette.action.active
    },
    uncheckedIcon: {
      color: theme.palette.action.disabled,
      height: 24,
      width: 24
    },
  }
  const handleClose = () => {

  }

  const renderOption = (option: Model) => {
    const value = props.value

    return (
      <Box display="flex" flexDirection="row" key={option.id} py={0} my={0}>
        <Box display="flex" flexGrow={1} minWidth={218} flexDirection="row">
          <FormControlLabel
            control={<Radio checked={option.id === value.id}
                            checkedIcon={<VisibilityIcon sx={styles.checkedIcon}/>}
                            icon={<VisibilityOffIcon sx={styles.uncheckedIcon}/>}
                            onChange={(event: any) => {
                              if (event.target.checked) {
                                props.onChange(option)
                              }}}
                            onClick={(event: any) => handleClose()}
                            sx={{paddingTop:"0px", paddingBottom:"0px"}}
            />}
            label={
              <Typography variant="body2" sx={{fontSize:15, whiteSpace:"normal", lineHeight:1.1}}>{option.name}</Typography>
            }
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Box width={32}>
            <Tooltip title="Edit" placement="top">
              <IconButton id={`${option.id}-edit-btn`} disabled={!modelStore.canEdit(option)} size="small"
                          sx={{paddingTop:0, paddingBottom:0}}
                          onClick={(event: any) => {
                                    if (props.onEdit) {
                                      props.onEdit(option)
                                  }}}
              >
                <EditIcon sx={{width:18, height:18}}/>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column" width="100%" p={0} mt={0.5} sx={{zIndex: theme.zIndex.drawer + 1}}>
      {props.options.map((option: Model) =>
        renderOption(option)
      )}
      {canAddModel &&
        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" bgcolor={theme.palette.background.paper}>
          <IconicButton label="New Model" labelPosition="right"
                        onClick={(event: any) => {
                          handleClose()
                          if (props.onNew) {
                            props.onNew(new Model({}))
                          }}}>
            <AddCircleIcon sx={{color: theme.palette.secondary.light, paddingRight:"2px"}}/>
          </IconicButton>
        </Box>
      }
    </Box>
  );
}
