import Model from "./Model";
import Asset from "./Asset";
import {IncomeType} from "./Income";
import IModelItem from "./IModelItem";

export enum AssetConversionType {
  RothConversion = "RothConversion",
  OtherConversion = "OtherConversion"
}

class AssetConversion implements IModelItem {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  userId: string
  modelId: string
  model?: Model
  description: string
  conversionType: AssetConversionType
  srcAssetId: string
  srcAsset?: Asset
  dstAssetId: string
  dstAsset?: Asset
  year: number
  amount: number
  percent: number
  sortOrder: number
  completedAt: string
  taxableAmount: number // Depracated
  nonTaxableAmount: number
  incomeType: IncomeType


  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.userId = data.userId
    this.modelId = data.modelId
    this.description = data.description
    this.conversionType = data.conversionType ?? AssetConversionType.OtherConversion
    this.model = data.model
    this.srcAssetId = data.srcAssetId
    this.dstAssetId = data.dstAssetId
    this.year = data.year
    this.amount = data.amount
    this.percent = data.percent
    this.sortOrder = data.sortOrder
    this.completedAt = data.completedAt
    this.taxableAmount = data.taxableAmount
    this.nonTaxableAmount = data.nonTaxableAmount ?? 0
    this.incomeType = data.incomeType
  }
}

export default AssetConversion