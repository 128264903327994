import React, {useEffect, useState} from 'react'
import Chart from "react-apexcharts";
import {FutureCalc, IFutureYear} from "../../../../components/calculator/Calculator";
import {humanizeString, numberToMoneyFormat} from "../../../../stores/StoreUtilities";
import {globalColors} from "../../../../styles/globalStyles"
import {Box, FormControlLabel, Grid, Paper, Switch, TableContainer, Typography, useTheme} from "@mui/material";
import cloneDeep from "lodash.clonedeep";
import Asset from "../../../../model/Asset";
import {TaxType} from "../../../../model/Tax";
import TextFieldValidator from "../../../../components/form/TextFieldValidator";
import FormValidator from "../../../../components/form/FormValidator";
import Model, {ModelSetting} from "../../../../model/Model";
import {parseNumber} from "../../../../model/ModelUtilities";
import {useResources} from "../../../../stores/ResourceProvider";

const defaultOptions = {
  chart: {
    id: "budget-projection",
    type: 'bar',
    stacked: false,
    height: 350,
    width: "100%",
    // zoom: {
    //   autoScaleYaxis: true
    // },
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      }
    }
  },
  plotOptions: {
    bar: {
      columnWidth: "90%",
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    }
  },
  title: {
    text: "",
    align: "center",
    margin: 0,
    style: {
      fontSize: "16px",
      fontFamily: "Poppins,sans-serif",
      color: "rgb(31, 92, 167)"
    }
  },
  dataLabels: {
    enabled: true,
    textAnchor: "middle",
    offsetY: -28,
    style: {
      fontSize: '18px',
      colors: ["rgb(90, 125, 74)", "rgb(0,125,144)", "#C08B61", "#CC5221", "#F29F05", "#894E30"],
      // colors: ["rgb(31, 92, 167)", "rgb(31, 92, 167)", "rgb(31, 92, 167)", "rgb(31, 92, 167)"]
    },
    formatter: (value: number) => {
      return numberToMoneyFormat(value, 0)
    },
  },
  legend: {
    position: 'top', // TODO: Figure out why this isn't working
    horizontalAlign: 'left'
  },
  xaxis: {
    categories: ['Income', 'Conservative', 'Moderate', 'Growth'],
    axisBorder: {
      show: true
    },
    labels: {
      style: {
        fontSize: '20px',
        fontWeight: 600,
        colors: ["rgb(31, 92, 167)", "rgb(31, 92, 167)", "rgb(31, 92, 167)", "rgb(31, 92, 167)"]
      },
      offsetY: 0
    }
  },
  yaxis: {
    labels: {
      formatter: (value: number) => {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  tooltip: {
    y: {
      formatter: function (value: number) {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  // tooltip: {
  //   x: {
  //     format: 'dd MMM yyyy'
  //   }
  // },
  colors: ["rgb(90, 125, 74)", "rgb(0,125,144)", "#C08B61", "#CC5221", "#F29F05", "#894E30"],
  // fill: {
  //   type: 'gradient',
  //   gradient: {
  //     shadeIntensity: 1,
  //     opacityFrom: 0.7,
  //     opacityTo: 0.9,
  //     stops: [0, 100]
  //   }
  // },
  // stroke: {
  //   width: 3
  // },
  resonsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const BucketsChart = ({
  futureCalcs,
  onChange
}: {
  futureCalcs: FutureCalc[]
  onChange?: (year: number) => any
}) => {
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<any>()
  const [model, setModel] = useState<Model | undefined>()
  const [yearOption, setYearOption] = useState<number>(0)
  const [yearOptions, setYearOptions] = useState<number[]>([])

  const { modelStore} = useResources()

  const theme = useTheme()

  useEffect(() => {
    if (futureCalcs.length > 0) {
      const futureCalc = futureCalcs[0]
      let startYear = futureCalc.latestRetirementYear
      let endYear = futureCalc.latestLifeExpectancyYear
      if (futureCalc.model) {
        setModel(futureCalc.model)
        const bucketsStartYear = futureCalc.model.settings[ModelSetting.BucketsStartYear]
        if (bucketsStartYear > 0) {
          startYear = Math.max(bucketsStartYear, futureCalc.latestRetirementYear)
        }
        setYearOption(startYear)
      }
      const yrs = range(futureCalc.latestRetirementYear, endYear)
      setYearOptions(yrs)
    }
  }, [futureCalcs])

  useEffect(() => {
    if (yearOption > 0) {
      calculateData(futureCalcs)
    }
  }, [yearOption])

  const range = (start: number, end: number) => Array.from({length: (end - start)}, (v, k) => k + start);

  const fillBucket = (array: number[], start: number, end: number, capital: number) => {
    let shortfall = 0;
    return [
      array.slice(start - 1, end).reduce(function (s, c) {
        [s, c] = [Math.max(Number(s), 0), Math.min(Number(c), 0)];
        s += c;
        c -= Math.min(s, 0);
        shortfall -= c;
        return s;
      }, capital), shortfall];
  };

  const calculateData = (calcs: FutureCalc[]) => {
    if (calcs.length === 0) {
      return
    }
    let seriesData: any[] = []

    futureCalcs.forEach((fc: FutureCalc) => {
      let firstYear
      if (yearOption) {
        firstYear = yearOption
      } else {
        firstYear = fc.latestRetirementYear
      }
      let start = Math.max(firstYear - fc.startYear, 0)

      let workingCapital = fc.futureYears[start].currentLiquidAssets
      let years = new Array<number>(10)
      for (let i = 0; i < 10 && start < fc.endYear; i++) {
        const year = fc.futureYears[start + i]
        // Ignore surplus amounts
        years[i] = Math.min(year.surplus, 0) - year.rmds
      }

      let income;
      [workingCapital, income] = fillBucket(years, 1, 3, workingCapital);

      let conservative;
      [workingCapital, conservative] = fillBucket(years, 4, 6, workingCapital);

      let moderate;
      [workingCapital, moderate] = fillBucket(years, 7, 9, workingCapital);

      let growth = Math.max(workingCapital, 0);

      seriesData.push({
        name: fc.label,
        data: [income, conservative, moderate, growth]
      })
    })

    setSeries(seriesData)

    const newOptions = cloneDeep(defaultOptions)
    if (calcs.length > 4) {
      newOptions.dataLabels.enabled = false
    } else {
      newOptions.dataLabels.style.fontSize = `${18 - 2 * calcs.length}px`
    }
    setOptions(newOptions)
  }

  const handleSetYear = (value: number) => {
    setYearOption(value)
    if (model) {
      model.settings[ModelSetting.BucketsStartYear] = value
      modelStore.saveSettings(model)
    }
  }

  if (options && series) {
    return (
      <React.Fragment>
        <TableContainer component={Paper} sx={{paddingTop:1, overflowY:"hidden"}}>
          <Chart
            options={options}
            series={series}
            type="bar"
            height="500"
          />
        </TableContainer>

          <TableContainer component={Paper} sx={{paddingTop:1, marginTop:2}}>
            <Box display="flex" flexGrow={1} flexDirection="column" px={2} py={1}>
            <Typography variant="body1">
              The Bucket Strategy is designed to help you allocate your money across time the first year of retirement.
              This method of asset allocation suggests that when drawing money out of your account,
              you will want to take less risk with the money you need in the near term,
              and you can afford to take more risk with money that you won't need to tap into for several years into the future.
            </Typography>
            <FormValidator id="buckets-form" name="bucketsForm" autoComplete="off">
              <Box pt={2} pb={0} width="100%" display="flex" flexDirection="row" alignItems="center">
                <Box width={150}>
                  <TextFieldValidator
                    type="text"
                    validators={{ required: true }}
                    required
                    name="year"
                    label="Start Year"
                    variant="outlined"
                    size="small"
                    sx={{maxWidth: "150px"}}
                    autocompleteOptions={{
                      freeSolo: false,
                      options: yearOptions,
                      disableClearable: true,
                      getOptionLabel: (option: number) => {
                        // return "Label"
                        return(String(option))
                      },
                      value: yearOption,
                      onChange: (event: any, value: number, reason: any) => {
                        handleSetYear(value)
                      }
                    }}
                  />
                </Box>
                <Box pl={2}>
                  <Typography variant="body2">The year you plan to start withdrawing from your retirement assets.</Typography>
                </Box>
              </Box>
            </FormValidator>
            <Grid container sx={{marginTop:0}} spacing={2}>
              <Grid item sm={3}>
                <Typography variant="h3" color="primary" align="left">Income</Typography>
                <Typography variant="body2">
                  Your Income Bucket represents the withdrawals required to support your spending plan from years 1 to 3 after you retire.
                </Typography>
              </Grid>
              <Grid item sm={3}>
                <Typography variant="h3" color="primary" align="left">Conservative</Typography>
                <Typography variant="body2">
                  Your Conservative Bucket represents the withdrawals required to support your spending plan from years years 4 to 6 after you retire.
                </Typography>
              </Grid>
              <Grid item sm={3}>
                <Typography variant="h3" color="primary" align="left">Moderate</Typography>
                <Typography variant="body2">
                  Your Moderate Bucket represents the withdrawals required to support your spending plan from years years 7 to 9 after you retire.
                </Typography>
              </Grid>
              <Grid item sm={3}>
                <Typography variant="h3" color="primary" align="left">Growth</Typography>
                <Typography variant="body2">
                  Your Growth Bucket represents the current liquid assets of the selected year, subtracting the sum of the other three buckets (Income, Conservative, Moderate). This is money that will not be needed until year 10.
                </Typography>
              </Grid>
            </Grid>
            </Box>
          </TableContainer>
      </React.Fragment>
    )
  } else {
    return null
  }
}

export default BucketsChart