import * as React from 'react'
import Page from '../components/page/Page'
import { RouteComponentProps } from '@reach/router'
import MarginRow from '../components/page/MarginRow'
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material';

class NotFoundPage extends React.Component<RouteComponentProps>  {
  render() {
    return (
      <Page>
        <MarginRow>
          <Grid container>
            <Grid item lg={12}>
              <Box 
                sx={{
                  justifyContent: "center"
                }}
              >
                {/*<img src={Logo} className={ classes.logoImage } />*/}
                <Box
                  sx={{
                    paddingTop: 'calc(50vh - 100px)',
                    textAlign: 'center',
                    pb: {
                      xs: 15, 
                      sm: 0 
                    }
                  }}
                >
                  <Typography variant={'subtitle1'}>
                    Sorry, this page doesn't exist or cannot be accessed.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </MarginRow>
      </Page>
    )
  }
}

export default NotFoundPage;