// import theme from "./theme"

import {grey} from "@mui/material/colors";

export const globalColors = {
  primary: 'rgb(31, 92, 167)', // #1F5CA7
  webFlowButton: 'hsl(41, 100%, 69%)',
  iconContrast: "rgb(255,255,255)",
  border: "rgba(0, 0, 0, 0.12)",
  fold: "rgb(31, 92, 167)",
  unfold: "rgb(31, 92, 167)",
  user: "rgb(51, 122, 183)",
  security: "rgb(51, 122, 183)",
  subscription: "rgb(0, 128, 0)",
  maleGender: "rgb(102, 119, 167)",
  maleText: "blue",
  femaleGender: "rgb(154, 95, 124)",
  femaleText: "red",
  warning: "darkorange", //"rgb(255, 191, 0)",

  liquidAsset: "rgb(0, 128, 0)", // "rgb(110,192,7)",
  realAsset: "#0393ee", // "#0393ee", // "rgb(0, 128, 0)", // "rgb(52,162,3)",
  personalAsset: "#ff7f26", // "rgb(0, 100, 0)", // "rgb(61,134,11)",
  otherAsset: "#ffd240", // "rgb(0, 75, 35)", // "rgb(0,97,14)",
  liability: "rgb(184,9,0)",
  income: "rgb(0, 128, 0)", // "rgb(110,192,7)",
  withdrawal: "#6cc07d", // "rgb(0, 160, 0)",
  deductionExpense: "#0393ee", // "rgb(136, 30, 16)",
  taxExpense: "#002d60", //'rgb(31, 92, 167)', // "rgb(184,9,0)",
  estimatedTax: '#005a99', // "#a1cfe2",
  essentialExpense: "rgb(224, 100, 83)",
  discretionaryExpense: "rgb(241,175,170)",
  expenseCategory: [
    "#EEEEEE",          // None
    "#894E30",          // Housing #6F6534
    "rgb(136,140,86)",  // FoodAndPersonalCare
    "rgb(0,125,144)",   // InsuranceAndMedical
    "#C08B61",          // VehiclesAndTransportation
    "#EB781B",          // LoansAndLiabilities
    "#CC5221",          // TravelAndEntertainment
    "#F29F05"           // GivingAndMisc
  ],
  details: "rgb(244, 244, 244)",
  positive: "#5A7D4A",
  negative: "rgb(136, 30, 16)",
  event: "rgb(51, 122, 183)",
  rate: "rgb(0, 128, 0)",
  withdrawalOrder: "rgb(0, 128, 0)",
  plan: "rgb(51, 122, 183)", // "rgb(0, 128, 0)",
  planChange: "rgb(51, 122, 183)",
  planChangeIcon: "#0393ee",
  timelineOptimizer: "#CC5221",
  taxOptimizer: "rgb(184,9,0)",
  longTermCareAnalyzer: "rgb(24, 66, 118)",
  longevityAnalyzer: "rgb(136,140,86)",
  economyAnalyzer: "rgb(180, 65, 50)",
  conversionOptimizer: "rgb(0,125,144)",
  giftingOptimizer: "#F29F05",

  premium: "orange",
  taxable: "#6cc07d",
  taxDeferredRMD: "rgb(31, 92, 167)", // Primary Dark,
  taxDeferredElective: "#0393ee", // Primary Light
  taxFree: "#EB781B",
  categorical: [
    "#0ea037",
    "rgb(31, 92, 167)",
    "#6c3d97",
    "#ff7f26",
    "rgb(136, 30, 16)",
    "#6cc07d",
    "#0393ee",
    "rgb(154, 95, 124)",
    "#ffd240",
    "rgb(184,9,0)" // "#d9365c"
  ],
  categorical2: [
    "#0ea037",
    "#0078b2",
    "#ec1b27",
    "#ff7f26",
    "#6c3d97",
    "#b85930",
    "#aedf8f",
    "#a1cfe2",
    "#ff9b9b",
    "#ffbe76",
    "#cbb2d4",
    "#ffffa1"
  ],
}

const globalStyles = {
  formLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: grey["700"],
  },
  webFlowButton: {
    color: globalColors.primary,
    backgroundColor: globalColors.webFlowButton,
    fontWeight: 400,
    "&:hover": {
      color: globalColors.webFlowButton,
      backgroundColor: globalColors.primary
    }
  },
  webFlowLargeButton: {
    fontSize: 20,
    fontWeight: 700,
    borderRadius: 1,
    color: globalColors.primary,
    backgroundColor: globalColors.webFlowButton,
    "&:hover": {
      color: globalColors.webFlowButton,
      backgroundColor: globalColors.primary
    },
  },
  trRetired: {
    backgroundColor: "rgba(183, 206, 175, 0.8)"
  },
  trHisLifeExpectancy: {
    backgroundColor: "rgba(153, 206, 224, 0.8)"
  },
  trHerLifeExpectancy: {
    backgroundColor: "rgba(255, 176, 190, 0.8)"
  },
  trAssetsDepleted: {
    backgroundColor: "rgba(255, 255, 0, 0.5)"
  },
  trTotal: {
    border: `1px solid ${globalColors.border}`,
    paddingTop: "8px"
  },
  legendLabel: {
    fontSize: 14,
    marginLeft:8,
    marginRight: 16,
    lineHeight: 1.0
  },
  link: {
    color: 'rgb(31, 92, 167)', // theme.palette.primary.main,
    textDecoration: "none"
  },
  detailedTooltip : {
    bgcolor: '#fff',
    color: "rgb(1, 87, 155)",
    fontSize: 14,
    fontWeight: 400,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.15)",
    lineHeight: 1.3,
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px"
  }
}

export default globalStyles