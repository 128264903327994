import * as React from 'react';
import Income from "../../../model/Income";
import {FormLabel, Grid, Typography, useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import {
  dateToLocalFormat,
  numberToMoneyFormat,
  numberToPercentFormat
} from "../../../stores/StoreUtilities";
import Model from "../../../model/Model";
import IncomeEditDialog from "./IncomeEditDialog";
import {IncomeIcon} from "../../../styles/Icons";
import ModelCard2 from "../../../components/model/ModelCard2";
import {isNumber} from "../../../model/ModelUtilities";

const IncomeCard = ({
  income,
  model,
  projected,
  expanded,
  onChange,
  onSave,
  onDelete
}: {
  income: Income
  model: Model
  projected?: number
  expanded?: boolean
  onChange?(event: any, expanded: boolean): void
  onSave?(expense: Income): void
  onDelete?(deleted: Income): void
}) => {
  const [incomeModel, setIncomeModel] = useState<Income>(income)
  const [description, setDescription] = useState<string>("")
  const [amount, setAmount] = useState<string | undefined>()
  const [projectedAmount, setProjectedAmount] = useState<string | undefined>()
  const [incomeType, setIncomeType] = useState<string | undefined>()
  const [owner, setOwner] = useState<string | undefined>()
  const [schedule, setSchedule] = useState<string | undefined>()
  const [startDate, setStartDate] = useState<string | undefined>()
  const [endDate, setEndDate] = useState<string | undefined>()
  const [annualInf, setAnnualInf] = useState<string | undefined>()
  const [annualInfLock, setAnnualInfLock] = useState<boolean>(false)
  const [survivorPercent, setSurvivorPercent] = useState<string | undefined>()
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)

  useEffect(() => {
    init(income)
  }, [income, projected])

  const init = (income: Income) => {
    setIncomeModel(income)
    setDescription(income.description)
    setAmount(income.amount ? numberToMoneyFormat(income.amount, 0) : "$0")
    setProjectedAmount(projected ? numberToMoneyFormat(projected, 0) : "$0")
    setIncomeType(income.incomeTypeDef.label)
    setOwner(model.getPerson(income.ownerId)?.nickname ?? Model.jointNickname)
    setSchedule(income.schedule.name)
    setStartDate(dateToLocalFormat(income.startDate))
    setEndDate(dateToLocalFormat(income.endDate))
    setAnnualInf(numberToPercentFormat(isNumber(income.annualInf) ? income.annualInf : model.getDefaultInflationRate("income"), 2))
    setAnnualInfLock(!isNumber(income.annualInf) || model.hasLock(income.id, "annualInf"))
    if (income.incomeTypeDef.requiredExtraFields && (income.incomeTypeDef.requiredExtraFields.includes("survivorAnnuityPercent"))) {
      setSurvivorPercent(income.survivorPercent !== undefined ? `${income.survivorPercent}%` : undefined)
    }
    setEditOpen(!income.createdAt)
  }

  const theme = useTheme()

  const styles = {
    heading: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    row: {
      height: 40,
      minHeight: 40,
      maxHeight: 40,
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "1.0em",
      maxHeight: 15,
      marginBottom: "1px",
      textWrap: "nowrap",
      color: theme.palette.grey["700"]
    },
    value: {
      border: "1px solid red"
    }
  }

  const renderContent = () => {
    return (
      <Grid container direction="column" spacing={0}>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Amount</FormLabel>
              <Typography variant="body2">
                {amount}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormLabel sx={styles.formLabel}>Frequency</FormLabel>
              <Typography variant="body2">
                {schedule}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Owner</FormLabel>
              <Typography variant="body2">
                {owner}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormLabel sx={styles.formLabel}>Income Type</FormLabel>
              <Typography variant="body2" style={{height:20, overflowY:"hidden"}}>
                {incomeType}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {survivorPercent !== undefined && survivorPercent !== "0%" &&
          <Grid item xs={12} sx={styles.row}>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={4}>
              </Grid>
              <Grid item xs={8}>
                <FormLabel sx={styles.formLabel}>Survivor %</FormLabel>
                <Typography variant="body2">
                  {survivorPercent}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        }
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Start Date</FormLabel>
              <Typography variant="body2">
                {startDate}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>End Date</FormLabel>
              <Typography variant="body2" color={income.end ? "inherit" : "darkgrey"}>
                {endDate}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Annual Inflation %</FormLabel>
              <Typography variant="body2" color={annualInfLock ? "text.secondary" : "inherit"}>
                {annualInf}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <React.Fragment>
    <ModelCard2
      icon={<IncomeIcon/>}
      title={description}
      value={projectedAmount}
      expanded={expanded}
      onChange={onChange}
      editButtonId={`edit-btn-${incomeModel.id}`}
      onEdit={(event: any) => {
        setEditOpen(!editOpen)
      }}
    >
      {renderContent()}
    </ModelCard2>
      {editOpen &&
        <IncomeEditDialog income={incomeModel} model={model}
                          open={editOpen}
                          onSave={(update: Income) => {
                            init(update)
                            setEditOpen(false)
                            if (onSave) {
                              onSave(update)
                              setSaved(true)
                            }
                          }}
                          onDelete={(deleted: Income) => {
                            setEditOpen(false)
                            if (onDelete) {
                              onDelete(deleted)
                            }
                          }}
                          onClose={() => {
                            setEditOpen(false)
                            if (!incomeModel.createdAt && onDelete) {
                              onDelete(incomeModel)
                            }
                          }}/>
      }
    </React.Fragment>
  )
}

export default IncomeCard