import * as React from 'react';
import {
  Box, InputAdornment,
  useTheme
} from "@mui/material";
import {useResources} from "../../stores/ResourceProvider";
import User from "../../model/User";
import {useEffect, useState} from "react";
import {Autocomplete} from "@mui/lab";
import TextField from "@mui/material/TextField";
import {ModelUserFilterInput} from "../../API";
import SearchIcon from '@mui/icons-material/Search';

interface IClientMenuProps {
  sx?: any
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined
  client: User
  onChangeClient?(client: User): void
}

export default function ClientMenu(props: IClientMenuProps) {
  const [clients, setClients] = useState<User[]>([])
  const [client, setClient] = useState<User | undefined>()

  const theme = useTheme()
  const { userStore, accountStore, modelStore, confirm, notify } = useResources()

  useEffect(() => {
    // setClient(props.client)
    if (userStore.user) {
      if (userStore.isAdvisorOrAdmin && clients.length === 0) {
        let filter : ModelUserFilterInput | undefined
        if (userStore.isAdvisor) {
          filter = {
            advisorAccess: {eq: true}
          }
        } else if (userStore.user.isCustomer) {
          return
        }
        accountStore.listUsersByAccount(userStore.user.accountId, filter)
          .then((users: User[]) => {
            if (users) {
              const currentUser = userStore.user!
              if (users.findIndex((u: User) => u.id === currentUser.id) < 0) {
                users.push(currentUser)
              }
              users.sort((a: User, b: User) => a.fullName.localeCompare(b.fullName))
              setClients(users)
            }
          })
      }
    }
  }, [userStore.isLoading, props.client])

  const isMe = (user: User): boolean => {
    return (user.id === userStore.user?.id)
  }

  const renderClientSelector = () => {
    if (!userStore.isAdvisorOrAdmin || clients.length === 0) {
      return null
    }

    return (
      <Box display="flex" flexDirection="row" flexGrow={1} width="100%"
           bgcolor={theme.palette.background.paper}
      >
        <Autocomplete
          autoSelect
          includeInputInList
          options={clients}
          value={client}
          sx={{minWidth:"100%", fontSize:12,
            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
              fontSize: 16,
            }}}
          componentsProps={{
            paper: {
              sx: {
                minWidth: 500,
                maxWidth: 500
              }
            }
          }}
          ListboxProps={{
            sx: { fontSize: 16, lineHeight: 1.2 },
          }}
          disableClearable={true}
          getOptionLabel={(option: User) => {
            if (isMe(option)) {
              return `Me: ${option.fullName} (${option.email})`
            } else {
              return option && option.firstName ? `${option.fullName} (${option.email})` : option.email
            }
          }}
          isOptionEqualToValue={(option: User, value: User) => option.id === value.id}
          onChange={(event: any, value: User | null, reason: any) => {
            if (value && props.onChangeClient) {
              props.onChangeClient(value)
            }
          }}
          renderInput={(params: any) =>
            <div ref={params.InputProps.ref}>
              <TextField
                {...params}
                placeholder="Search"
                required
                variant="standard"
                fullWidth
                sx={{minWidth:"100%", backgroundColor:theme.palette.grey["50"],
                  '.MuiInputBase-input': { fontSize:'16px' }}}
                size="small"
                name="client"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),

                }}
              />
            </div>
          }
        />
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column" minWidth={360} p={0}>
      {renderClientSelector()}
    </Box>
  );
}
