import React, {useEffect, useState} from 'react'
import Chart from "react-apexcharts";
import Asset from "../../../../model/Asset";
import Snapshot from "../../../../model/Snapshot";
import {compareDesc, startOfToday, startOfTomorrow} from "date-fns";
import cloneDeep from "lodash.clonedeep";
import {dateToLocalFormat} from "../../../../stores/StoreUtilities";
import {useTheme} from "@mui/material";
import {globalColors} from "../../../../styles/globalStyles";

const startYear = 2000
const years = 51

const labels: string[] = []
const netWorth: number[] = []
for (let y = 0; y < years; y++) {
  labels.push((startYear + y).toString())
  netWorth.push(200000 + (10000 * y))
}

const defaultOptions = {
  chart: {
    id: "line-datetime",
    type: 'line',
    height: 350,
    width: "100%",
    zoom: {
      enabled: false,
      autoScaleYaxis: true
    },
    // sparkline: {
    //   enabled: true
    // },
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      }
    },
    toolbar: {
      show: false,
      tools: {
        download: false
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    position: 'top', // TODO: Figure out why this isn't working
    horizontalAlign: 'left',
    onItemClick: {
      toggleDataSeries: false
    }
  },
  markers: {
    size: 5,
    style: 'hollow',
  },
  xaxis: {
    type: 'datetime',
    min: new Date('01 Jan 2020').getTime(),
    tickAmount: 6,
    axisBorder: {
      show: true
    }
  },
  tooltip: {
    x: {
      format: 'dd MMM yyyy'
    }
  },
  colors: globalColors.categorical2,
  // colors: [globalColors.liability, globalColors.otherAsset, globalColors.personalAsset, globalColors.realAsset, globalColors.liquidAsset],
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100]
    }
  },
  stroke: {
    width: 3
  },
  resonsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const LiquidAssetsApexChart = ({
  liquidAssets,
  snapshots,
  selected,
  onSelect
}: {
  liquidAssets: Asset[]
  snapshots: Snapshot[]
  selected?: number
  onSelect?(index: number): void
}) => {
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<any>()

  const theme = useTheme()

  useEffect(() => {
    if (snapshots.length > 0) {
      const newOptions = cloneDeep(defaultOptions)
      const minDate = snapshots.reduce((accum: Date, item: Snapshot) => compareDesc(accum, item.date) > 0 ? accum : item.date, new Date())
      if (minDate) {
        newOptions.xaxis.min = minDate.getTime()
      }
      if (selected !== undefined) {
        const snapshot = snapshots[selected]
        const annotations = {
          xaxis: [
            {
              x: snapshot.date.getTime(),
              borderColor: theme.palette.secondary.main, //'#00E396',
              label: {
                borderColor: theme.palette.secondary.main, //'#00E396',
                orientation: 'horizontal',
                text: dateToLocalFormat(snapshot.date, "MMM d, yyyy"),
                style: {
                  fontSize: '14px',
                  fontWeight: 600
                }
              }
            }
          ]
        }
        newOptions["annotations"] = annotations
        newOptions.chart.events["markerClick"] = (event: any, chartContext: any, details: {dataPointIndex: number, seriesIndex: number}) => {
          if (details.dataPointIndex >= 0 && onSelect) {
            onSelect(snapshots.length - details.dataPointIndex - 1)
          }
        }
      }
      setOptions(newOptions)

      const liquidSeries = liquidAssets.map((asset: Asset) => {
        const data = snapshots.map((s: Snapshot) =>
          [new Date(s.date).getTime(), s.getSnapshotBalance(asset.id, "Asset")]
        )
        if (snapshots.length === 1) {
          // TODO: Figure out why this isn't working to display chart
          data.push([startOfToday().getTime(), data[0][1]])
          data.push([startOfTomorrow().getTime(), data[0][1]])
        }
        data.reverse() // Sort in ascending order
        return (
          {name: asset.description, data: data}
        )
      })
      setSeries(liquidSeries)
    }
  }, [liquidAssets, snapshots, selected])

  if (options && series) {
    return (
      <Chart
        options={options}
        series={series}
        type="line"
        height="500"
        width="100%"
      />
    )
  } else {
    return null
  }
}

export default LiquidAssetsApexChart