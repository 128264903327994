import { observer } from "mobx-react";
import LoadingPage from "../../pages/LoadingPage";
// import LoginPage from "../../pages/LoginPage";
import NotFoundPage from "../../pages/NotFoundPage";
import RoutesConfig, { RoutesConfigHelpers } from "../../RoutesConfig";
import Logger from "../Logger";
import Tracking from "../Tracking";
import { useLocation, Outlet, Navigate } from 'react-router-dom';
import { useResources } from "../../stores/ResourceProvider";
import SignInPage from "../../pages/SignInPage";

/*
  Potential way to wrap class components so they can use useNavigate and useLocation from React Router. 

  https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb
*/
// interface WithNavigation {
//   navigate: NavigateFunction
// }
// const withNavigation = (Component: React.ComponentType) => {
//   return (props: any) => <Component {...props} navigate={useNavigate()} />;
// }

// function withParams(Component: React.ComponentType) {
//   return (props: any) => <Component {...props} params={useParams()} />;
// }

// interface WithLocation {
//   location: Location 
// }

// const withLocation = <P extends unknown>(Component: React.ComponentType<P>) => {
//   return (props: any) => <Component {...props} location={useLocation()} />
// }

interface IControlTowerProps {
  routes: any
}

const ControlTower = observer((props: IControlTowerProps) => {
  // let _lastNavigatedPath = "";
  const routes = props.routes 
  const { userStore } = useResources()
  const location = useLocation() 
  const pathname = location.pathname
  const route = RoutesConfigHelpers.getRouteForPathname(pathname, routes)

  if (route === RoutesConfig.signUp) {
    Logger.info(`Routing to ${pathname}`)
    return <Outlet />
  } else if (userStore && userStore!.isLoading === true) {
    Logger.info(`Routing ${pathname} to LoadingPage`)
    return <LoadingPage route={pathname} />
  } else if (userStore && userStore.isAuthenticated === false && route && RoutesConfigHelpers.routeRequiresLogin(route)) {
    Logger.info(`Routing ${pathname} to LoginPage for authentication`)
    return <SignInPage route={pathname} />
  } else if (userStore && userStore.isAuthenticated === false && route === undefined) {
    return <Navigate to={RoutesConfig.signIn.pathname!} state={{ route: route }} />
  } else if (userStore && route && userStore.user && !RoutesConfigHelpers.roleMeetsRouteRequirements(route, userStore.user.role)) {
    Logger.info(`Route ${pathname} requires role`)
    return <NotFoundPage />
  } else if (RoutesConfigHelpers.routeExistsForPathname(pathname, routes)) {
    Logger.info(`Routing to ${pathname}`)
    // _lastNavigatedPath = pathname;
    Tracking.pageView(pathname)
    return <Outlet />
  } else {
    Logger.info(`Route ${pathname} not found`)
    // _lastNavigatedPath = RoutesConfig.notFound.pathname
    return <NotFoundPage />
  }
})

export default ControlTower
