import * as React from 'react';
import Tax, {TaxType} from "../../../model/Tax";
import {FormLabel, Grid, Typography, useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import {
  dateToLocalFormat,
  humanizeString,
  numberToMoneyFormat,
  numberToPercentFormat
} from "../../../stores/StoreUtilities";
import Model from "../../../model/Model";
import {TaxExpenseIcon} from "../../../styles/Icons";
import ModelCard2 from "../../../components/model/ModelCard2";
import TaxEditDialog from "./TaxEditDialog";
import {isNumber} from "../../../model/ModelUtilities";

const TaxCard = ({
  tax,
  model,
  projected,
  expanded,
  onChange,
  onSave,
  onDelete
}: {
  tax: Tax
  model: Model
  projected?: number
  expanded?: boolean
  onChange?(event: any, expanded: boolean): void
  onSave?(expense: Tax): void
  onDelete?(deleted: Tax): void
}) => {
  const [taxModel, setTaxModel] = useState<Tax>(tax)
  const [description, setDescription] = useState<string>("")
  const [amount, setAmount] = useState<string | undefined>()
  const [projectedAmount, setProjectedAmount] = useState<string | undefined>()
  const [taxType, setTaxType] = useState<string | undefined>()
  const [owner, setOwner] = useState<string | undefined>()
  const [schedule, setSchedule] = useState<string | undefined>()
  const [startDate, setStartDate] = useState<string | undefined>()
  const [endDate, setEndDate] = useState<string | undefined>()
  const [annualInf, setAnnualInf] = useState<string | undefined>()
  const [annualInfLock, setAnnualInfLock] = useState<boolean>(false)
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)

  useEffect(() => {
    init(tax)
  }, [tax, projected])

  const init = (tax: Tax) => {
    setTaxModel(tax)
    setDescription(tax.description)
    setAmount(tax.amount ? numberToMoneyFormat(tax.amount, 0) : "$0")
    setProjectedAmount(projected ? numberToMoneyFormat(projected, 0) : "$0")
    setTaxType(tax.taxType ? humanizeString(TaxType[tax.taxType]) : undefined)
    setOwner(model.getPerson(tax.ownerId)?.nickname ?? Model.jointNickname)
    setSchedule(tax.schedule.name)
    setStartDate(dateToLocalFormat(tax.startDate))
    setEndDate(dateToLocalFormat(tax.endDate))
    setAnnualInf(numberToPercentFormat(isNumber(tax.annualInf) ? tax.annualInf : model.getDefaultInflationRate("tax"), 2))
    setAnnualInfLock(!isNumber(tax.annualInf) || model.hasLock(tax.id, "annualInf"))
    // TODO: receiptMonths, Days
    setEditOpen(!tax.createdAt)
  }

  const theme = useTheme()

  const styles = {
    heading: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    row: {
      height: 40,
      minHeight: 40,
      maxHeight: 40,
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "1.0em",
      maxHeight: 15,
      marginBottom: "1px",
      textWrap: "nowrap",
      color: theme.palette.grey["700"]
    }
  }

  const renderContent = () => {
    return (
      <Grid container direction="column" spacing={0}>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Amount</FormLabel>
              <Typography variant="body2">
                {amount}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormLabel sx={styles.formLabel}>Frequency</FormLabel>
              <Typography variant="body2">
                {schedule}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Owner</FormLabel>
              <Typography variant="body2">
                {owner}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormLabel sx={styles.formLabel}>Tax Type</FormLabel>
              <Typography variant="body2">
                {taxType}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Start Date</FormLabel>
              <Typography variant="body2">
                {startDate}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>End Date</FormLabel>
              <Typography variant="body2" color={tax.end ? "inherit" : "darkgrey"}>
                {endDate}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Annual Inflation %</FormLabel>
              <Typography variant="body2" color={annualInfLock ? "text.secondary" : "inherit"}>
                {annualInf}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <React.Fragment>
      <ModelCard2
        icon={<TaxExpenseIcon/>}
        title={description}
        value={projectedAmount}
        valueColor="#881E10"
        expanded={expanded}
        onChange={onChange}
        editButtonId={`edit-btn-${taxModel.id}`}
        onEdit={(event: any) => {
          setEditOpen(!editOpen)
        }}
      >
        {renderContent()}
      </ModelCard2>
      {editOpen &&
        <TaxEditDialog tax={taxModel} model={model}
                       open={editOpen}
                       onSave={(update: Tax) => {
                         init(update)
                         setEditOpen(false)
                         if (onSave) {
                           onSave(update)
                           setSaved(true)
                         }
                       }}
                       onDelete={(deleted: Tax) => {
                         setEditOpen(false)
                         if (onDelete) {
                           onDelete(deleted)
                         }
                       }}
                       onClose={() => {
                         setEditOpen(false)
                         if (!taxModel.createdAt && onDelete) {
                           onDelete(taxModel)
                         }
                       }}/>
      }
    </React.Fragment>
  )
}

export default TaxCard