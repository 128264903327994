import Model from "../../../model/Model";
import Plan from "../../../model/Plan";
import PlanChange, {PlanChangeType} from "../../../model/PlanChange";
import React, {ReactNode, SyntheticEvent, useEffect, useState} from "react";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import MuiEventIcon from "@mui/icons-material/Event";
import {globalColors} from "../../../styles/globalStyles";
import SecurityIcon from "@mui/icons-material/Security";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import {useResources} from "../../../stores/ResourceProvider";
import {UpdatePlanChangeInput, UpdatePlanInput} from "../../../API";
import {Box, useTheme} from "@mui/material";
import GrowthStrategyDialog from "./GrowthStrategyDialog";
import TimelineStrategyDialog from "./TimelineStrategyDialog";
import SocialSecurityStrategyDialog from "./SocialSecurityStrategyDialog";
import WithdrawalStrategyDialog from "./WithdrawalStrategyDialog";
import ConversionStrategyDialog from "./ConversionStrategyDialog";
import PlanChangeListItem from "./PlanChangeListItem";
import DialogButton from "../../../components/form/DialogButton";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import ExpenseStrategyDialog from "./ExpenseStrategyDialog";
import InflationStrategyDialog from "./InflationStrategyDialog";
import InflationStrategyIcon from "@mui/icons-material/AddShoppingCart";
import ExpenseStrategyIcon from '@mui/icons-material/AttachMoney';

const PlanChangeList = ({
  model,
  plan,
  updatedAt,
  drawerOpen,
  onChange
} : {
  model: Model
  plan: Plan
  updatedAt: string
  drawerOpen?: boolean
  onChange?(plan: Plan, committed?: Model): void
}) => {
  const [currentModel, setCurrentModel] = useState<Model | undefined>()
  const [expandedNodes, setExpandedNodes] = useState<string[]>([])
  const [currentPlan, setCurrentPlan] = useState<Plan | undefined>()
  const [showExpenseStrategyDialog, setShowExpenseStrategyDialog] = useState<boolean>(false)
  const [showGrowthStrategyDialog, setShowGrowthStrategyDialog] = useState<boolean>(false)
  const [showInflationStrategyDialog, setShowInflationStrategyDialog] = useState<boolean>(false)
  const [showTimelineStrategyDialog, setShowTimelineStrategyDialog] = useState<boolean>(false)
  const [showSocialSecurityStrategyDialog, setShowSocialSecurityStrategyDialog] = useState<boolean>(false)
  const [showWithdrawalStrategyDialog, setShowWithdrawalStrategyDialog] = useState<boolean>(false)
  const [showRothStrategyDialog, setShowRothStrategyDialog] = useState<boolean>(false)

  const {modelStore, confirm, notify} = useResources()
  const theme = useTheme()

  useEffect(() => {
    setCurrentModel(model)
    setCurrentPlan(plan)
  }, [model, plan, updatedAt])

  const setExpandedNode = (nodeId: string, expanded: boolean) => {
    if (expanded) {
      if (!expandedNodes.includes(nodeId)) {
        setExpandedNodes([...expandedNodes, nodeId])
      }
    } else {
      const index = expandedNodes.findIndex((id: string) => id === nodeId)
      if (index >= 0) {
        expandedNodes.splice(index, 1)
        setExpandedNodes([...expandedNodes])
      }
    }
  }

  const handleSavePlan = async (plan: Plan) => {
    // Update plan
    const updateInput: UpdatePlanInput = {
      id: plan.id
    }
    const update = await modelStore.updatePlan(updateInput)

    // Update model
    // if (currentModel) {
    //   const index = currentModel.plans.findIndex((p: Plan) => p.id === plan.id)
    //   if (index >= 0) {
    //     currentModel.plans[index] = plan
    //   } else {
    //     currentModel.plans.push(plan)
    //   }
    // }

    if (onChange && update) {
      onChange(update)
    }
  }

  const handleCheckPlanChange = (plan: Plan, changeType: PlanChangeType, checked: boolean) => {
    const change = plan.getChange(changeType)
    if (change) {
      const input: UpdatePlanChangeInput = {
        id: change.id,
        enabled: checked
      }
      modelStore.updatePlanChange(input)
        .then((update?: PlanChange) => {
          if (update) {
            handleSavePlanChange(plan, update)
          }
        })
    }
  }

  const handleSavePlanChange = (plan: Plan, change: PlanChange) => {
    plan.setChange(change)
    handleSavePlan(plan)
    setExpandedNode(`${plan.id}-${change.changeType}`, change.enabled)
    // if (onChange) {
    //   onChange(plan)
    // }
  }

  const handleCommit = () => {
    confirm.show('Confirm Commit', 'This will permanently apply the enabled scenario changes to the model.',
      ['Commit', 'Cancel'],
      async () => {
        try {
          const updatedModel = await modelStore.commitPlanChanges(plan, model)
          if (updatedModel) {
            if (onChange) {
              const updatedPlan = updatedModel.plans.find((p: Plan) => p.id === plan.id)
              onChange(updatedPlan ?? plan, updatedModel)
            }
            return true
          }
          return false
        }
        catch (err: any) {
          notify.show('error', err.message)
          return false
        }
      })
  }

  const renderPlanChangeItem = (plan: Plan, changeType: PlanChangeType) => {
    // Uncomment to not allow other than Growth Strategy for default
    // if (plan.default && changeType !== PlanChangeType.GrowthStrategy) {
    //   return null
    // }
    let item: ReactNode | null = null
    let change = plan.getChange(changeType)
    let nodeId: string
    let expanded = false
    switch (changeType) {
      case PlanChangeType.ExpenseStrategy:
        nodeId = `${plan.id}-${PlanChangeType.ExpenseStrategy}`
        item = <PlanChangeListItem key={nodeId}
          labelIcon={ExpenseStrategyIcon}
          iconColor={theme.palette.action.selected}
          labelText={change ? change.name : "Expense Strategy"}
          content={change ? change.description : ""}
          expanded={drawerOpen && change && change.enabled}
          checked={change ? change.enabled : false}
          disabled={!change || !change.enabled}
          nodeId={nodeId}
          onEdit={(event: any) => {
            if (!showExpenseStrategyDialog) {
              setShowExpenseStrategyDialog(true)
            } else {
              setShowExpenseStrategyDialog(false)
            }
          }}
          onCheck={(event: any) => {
            handleCheckPlanChange(plan, changeType, event.target.checked)
            if (!change) {
              setShowExpenseStrategyDialog(true)
            }
          }}
          onChange={(event: SyntheticEvent, expanded: boolean) => {
            setExpandedNode(nodeId, expanded)
          }}
        />
        break

      case PlanChangeType.GrowthStrategy:
        nodeId = `${plan.id}-${PlanChangeType.GrowthStrategy}`
        item = <PlanChangeListItem key={nodeId}
          labelIcon={TrendingUpIcon}
          iconColor={theme.palette.action.selected}
          labelText={change ? change.name : "Growth Strategy"}
          content={change ? change.description : ""}
          expanded={drawerOpen && change && change.enabled}
          checked={change ? change.enabled : false}
          disabled={!change || !change.enabled}
          nodeId={nodeId}
          onEdit={(event: any) => {
            if (!showGrowthStrategyDialog) {
              setShowGrowthStrategyDialog(true)
            } else {
              setShowGrowthStrategyDialog(false)
            }
          }}
          onCheck={(event: any) => {
            handleCheckPlanChange(plan, changeType, event.target.checked)
            if (!change) {
              setShowGrowthStrategyDialog(true)
            }
          }}
          onChange={(event: SyntheticEvent, expanded: boolean) => {
            setExpandedNode(nodeId, expanded)
          }}
        />
        break

      case PlanChangeType.InflationStrategy:
        nodeId = `${plan.id}-${PlanChangeType.InflationStrategy}`
        item = <PlanChangeListItem key={nodeId}
                                   labelIcon={InflationStrategyIcon}
                                   iconColor={theme.palette.action.selected}
                                   labelText={change ? change.name : "Inflation Strategy"}
                                   content={change ? change.description : ""}
                                   expanded={drawerOpen && change && change.enabled}
                                   checked={change ? change.enabled : false}
                                   disabled={!change || !change.enabled}
                                   nodeId={nodeId}
                                   onEdit={(event: any) => {
                                     if (!showInflationStrategyDialog) {
                                       setShowInflationStrategyDialog(true)
                                     } else {
                                       setShowInflationStrategyDialog(false)
                                     }
                                   }}
                                   onCheck={(event: any) => {
                                     handleCheckPlanChange(plan, changeType, event.target.checked)
                                     if (!change) {
                                       setShowInflationStrategyDialog(true)
                                     }
                                   }}
                                   onChange={(event: SyntheticEvent, expanded: boolean) => {
                                     setExpandedNode(nodeId, expanded)
                                   }}
        />
        break

      case PlanChangeType.TimelineStrategy:
        nodeId = `${plan.id}-${PlanChangeType.TimelineStrategy}`
        item = <PlanChangeListItem key={nodeId}
          labelIcon={AvTimerIcon}
          iconColor={theme.palette.action.selected}
          labelText={change ? change.name : "Timeline Strategy"}
          content={change ? change.description : ""}
          expanded={drawerOpen && change && change.enabled}
          checked={change ? change.enabled : false}
          disabled={!change || !change.enabled}
          nodeId={nodeId}
          onEdit={(event: any) => {
            if (!showTimelineStrategyDialog) {
              setShowTimelineStrategyDialog(true)
            } else {
              setShowTimelineStrategyDialog(false)
            }
          }}
          onCheck={(event: any) => {
            handleCheckPlanChange(plan, changeType, event.target.checked)
            if (!change) {
              setShowTimelineStrategyDialog(true)
            }
          }}
          onChange={(event: SyntheticEvent, expanded: boolean) => {
            // setExpandedNode(nodeId, expanded)
          }}
        />
        break

      case PlanChangeType.SocialSecurityStrategy:
        nodeId = `${plan.id}-${PlanChangeType.SocialSecurityStrategy}`
        item = <PlanChangeListItem key={nodeId}
          labelIcon={SecurityIcon}
          iconColor={theme.palette.action.selected}
          labelText={change ? change.name : "Social Security Strategy"}
          content={change ? change.description : ""}
          expanded={drawerOpen && change && change.enabled}
          checked={change ? change.enabled : false}
          disabled={!change || !change.enabled}
          nodeId={nodeId}
          onEdit={(event: any) => {
            if (!showSocialSecurityStrategyDialog) {
              setShowSocialSecurityStrategyDialog(true)
            } else {
              setShowSocialSecurityStrategyDialog(false)
            }
          }}
          onCheck={(event: any) => {
            handleCheckPlanChange(plan, changeType, event.target.checked)
            if (!change) {
              setShowSocialSecurityStrategyDialog(true)
            }
          }}
          onChange={(event: SyntheticEvent, expanded: boolean) => {
            setExpandedNode(nodeId, expanded)
          }}
        />
        break

      case PlanChangeType.WithdrawalStrategy:
        nodeId = `${plan.id}-${PlanChangeType.WithdrawalStrategy}`
        item = <PlanChangeListItem key={nodeId}
          labelIcon={ViewTimelineIcon}
          iconColor={theme.palette.action.selected}
          labelText={change ? change.name : "Withdrawal Strategy"}
          content={change ? change.description : ""}
          expanded={drawerOpen && change && change.enabled}
          checked={change ? change.enabled : false}
          disabled={!change || !change.enabled}
          nodeId={nodeId}
          onEdit={(event: any) => {
            if (!showWithdrawalStrategyDialog) {
              setShowWithdrawalStrategyDialog(true)
            } else {
              setShowWithdrawalStrategyDialog(false)
            }
          }}
          onCheck={(event: any) => {
            handleCheckPlanChange(plan, changeType, event.target.checked)
            if (!change) {
              setShowWithdrawalStrategyDialog(true)
            }
          }}
          onChange={(event: SyntheticEvent, expanded: boolean) => {
            setExpandedNode(nodeId, expanded)
          }}
        />
        break

      case PlanChangeType.ConversionStrategy:
        nodeId = `${plan.id}-${PlanChangeType.ConversionStrategy}`
        item = <PlanChangeListItem key={nodeId}
          labelIcon={CurrencyExchangeIcon}
          iconColor={theme.palette.action.selected}
          labelText={change ? change.name : "Conversion Strategy"}
          content={change ? change.description : ""}
          expanded={drawerOpen && change && change.enabled}
          checked={change ? change.enabled : false}
          disabled={!change || !change.enabled}
          nodeId={nodeId}
          onEdit={(event: any) => {
            if (!showRothStrategyDialog) {
              setShowRothStrategyDialog(true)
            } else {
              setShowRothStrategyDialog(false)
            }
          }}
          onCheck={(event: any) => {
            handleCheckPlanChange(plan, changeType, event.target.checked)
            if (!change) {
              setShowRothStrategyDialog(true)
            }
          }}
          onChange={(event: SyntheticEvent, expanded: boolean) => {
            setExpandedNode(nodeId, expanded)
          }}
        />
        break
    }

    return item
  }

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} pl={2} pr={1.5}>
      {renderPlanChangeItem(plan, PlanChangeType.ConversionStrategy)}
      {renderPlanChangeItem(plan, PlanChangeType.ExpenseStrategy)}
      {renderPlanChangeItem(plan, PlanChangeType.GrowthStrategy)}
      {renderPlanChangeItem(plan, PlanChangeType.InflationStrategy)}
      {renderPlanChangeItem(plan, PlanChangeType.SocialSecurityStrategy)}
      {renderPlanChangeItem(plan, PlanChangeType.TimelineStrategy)}
      {renderPlanChangeItem(plan, PlanChangeType.WithdrawalStrategy)}
      {/*{drawerOpen &&*/}
      {/*  <Box display="flex" justifyContent="space-around" mt={1}>*/}
      {/*    <DialogButton type="button" variant="secondary" onClick={handleCommit}>*/}
      {/*      Commit Scenario*/}
      {/*    </DialogButton>*/}
      {/*  </Box>*/}
      {/*}*/}

      {showExpenseStrategyDialog &&
        <ExpenseStrategyDialog plan={currentPlan!} model={currentModel!}
                               open={showExpenseStrategyDialog}
                              onSave={(plan: Plan, update: PlanChange) => {
                                setShowExpenseStrategyDialog(false)
                                handleSavePlanChange(plan, update)
                                setExpandedNode(`${plan.id}-${PlanChangeType.ExpenseStrategy}`, true)
                              }}
                              onClose={() => {
                                setShowExpenseStrategyDialog(false)
                              }}/>
      }

      {showGrowthStrategyDialog &&
        <GrowthStrategyDialog plan={currentPlan!} model={currentModel!}
                              target={showGrowthStrategyDialog}
                              onSave={(plan: Plan, update: PlanChange) => {
                                setShowGrowthStrategyDialog(false)
                                handleSavePlanChange(plan, update)
                                setExpandedNode(`${plan.id}-${PlanChangeType.GrowthStrategy}`, true)
                              }}
                              onClose={() => {
                                setShowGrowthStrategyDialog(false)
                              }}/>
      }

      {showInflationStrategyDialog &&
        <InflationStrategyDialog plan={currentPlan!} model={currentModel!}
                              open={showInflationStrategyDialog}
                              onSave={(plan: Plan, update: PlanChange) => {
                                setShowInflationStrategyDialog(false)
                                handleSavePlanChange(plan, update)
                                setExpandedNode(`${plan.id}-${PlanChangeType.InflationStrategy}`, true)
                              }}
                              onClose={() => {
                                setShowInflationStrategyDialog(false)
                              }}/>
      }

      {showTimelineStrategyDialog &&
        <TimelineStrategyDialog plan={currentPlan!} model={currentModel!}
                                open={showTimelineStrategyDialog}
                                onSave={(plan: Plan, update: PlanChange) => {
                                  setShowTimelineStrategyDialog(false)
                                  handleSavePlanChange(plan, update)
                                  setExpandedNode(`${plan.id}-${PlanChangeType.TimelineStrategy}`, true)
                                }}
                                onClose={() => {
                                  setShowTimelineStrategyDialog(false)
                                }}/>
      }

      {showSocialSecurityStrategyDialog &&
        <SocialSecurityStrategyDialog plan={currentPlan!} model={currentModel!}
                                      open={showSocialSecurityStrategyDialog}
                                      onSave={(plan: Plan, update: PlanChange) => {
                                        setShowSocialSecurityStrategyDialog(false)
                                        handleSavePlanChange(plan, update)
                                        setExpandedNode(`${plan.id}-${PlanChangeType.SocialSecurityStrategy}`, true)
                                      }}
                                      onClose={() => {
                                        setShowSocialSecurityStrategyDialog(false)
                                      }}/>
      }

      {showWithdrawalStrategyDialog &&
        <WithdrawalStrategyDialog plan={currentPlan!} model={currentModel!}
                                  open={showWithdrawalStrategyDialog}
                                  onSave={(plan: Plan, update: PlanChange) => {
                                    setShowWithdrawalStrategyDialog(false)
                                    handleSavePlanChange(plan, update)
                                    setExpandedNode(`${plan.id}-${PlanChangeType.WithdrawalStrategy}`, true)
                                  }}
                                  onClose={() => {
                                    setShowWithdrawalStrategyDialog(false)
                                  }}/>
      }

      {showRothStrategyDialog &&
        <ConversionStrategyDialog plan={currentPlan!} model={currentModel!}
                                  open={showRothStrategyDialog}
                                  onSave={(plan: Plan, update: PlanChange) => {
                              setShowRothStrategyDialog(false)
                              handleSavePlanChange(plan, update)
                              setExpandedNode(`${plan.id}-${PlanChangeType.ConversionStrategy}`, true)
                            }}
                                  onClose={() => {
                              setShowRothStrategyDialog(false)
                            }}/>
      }
    </Box>
  )
}

export default PlanChangeList