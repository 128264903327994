import {FutureCalc, INetWorthCalc} from "../../../../components/calculator/Calculator";
import {Box, FormLabel, useTheme} from "@mui/material";
import {numberToPercentFormat} from "../../../../stores/StoreUtilities";
import React, {useEffect, useState} from "react";
import {isNumber} from "../../../../model/ModelUtilities";


const RetirementBenchmarksWidget = ({
  futureCalc,
  netWorthCalc,
  width
} : {
  futureCalc: FutureCalc
  netWorthCalc: INetWorthCalc
  width?: string
}) => {
  const [secureIncomeScore, setSecureIncomeScore] = useState<number>(0)
  const [withdrawalRate, setWithdrawalRate] = useState<number>(0)
  const [debtToAssetRatio, setDebtToAssetRatio] = useState<number>(0)

  useEffect(() => {
    setSecureIncomeScore(isNumber(futureCalc.benchmarkData.secureIncomeScore) ? futureCalc.benchmarkData.secureIncomeScore : 0)
    setWithdrawalRate(isNumber(futureCalc.benchmarkData.withdrawalRate) ? futureCalc.benchmarkData.withdrawalRate: 0)
    if (netWorthCalc) {
      const ratio = netWorthCalc.liabilitiesTotal / netWorthCalc.assetsTotal
      setDebtToAssetRatio(isNumber(ratio) ? ratio : 0)
    }
  }, [futureCalc])

  const theme = useTheme()

  const styles = {
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"],
    },
    comparisonBarGraph: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      marginBottom: "5px",
    },
    comparisonBars: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      height: 48,
      marginRight: "4px",
      marginTop: "4px"
    },
    comparisonBar: {
      display: "flex",
      flexDirection: "row",
      height: 20,
      width: "100%",
      marginBottom: "2px"
    },
    comparisonBarSegment: {
      display: "inline-flex",
      justifyContent: "flex-end",
      alignItems: "center",
      height: 20,
      fontSize: 13,
      overflowX: "hidden",
    },
    benchmarkBarSegmentToRight: {
      display: "inline-flex",
      justifyContent: "flex-end",
      alignItems: "center",
      height: 20,
      fontSize: 13,
      overflowX: "hidden",
      backgroundImage: "linear-gradient(to right, #AD2D1E40, #5A7D4A40)"
    },
    benchmarkBarSegmentToLeft: {
      display: "inline-flex",
      justifyContent: "flex-end",
      alignItems: "center",
      height: 20,
      fontSize: 13,
      overflowX: "hidden",
      backgroundImage: "linear-gradient(to left, #AD2D1E40, #5A7D4A40)"
    },
    benchmarkBarLabel: {
      display: "inline-flex",
      paddingLeft: "5px",
      alignItems: "center",
      fontSize: 14,
      color: "#18427680",
    },
    comparisonBarLabel: {
      display: "inline-flex",
      paddingLeft: "5px",
      alignItems: "center",
      fontSize: 14,
      color: "#184276",
      fontWeight: 600
    }

  }

  const goodColor = "#5A7D4A";
  const badColor = "#AD2D1E";

  const secureIncomeBenchmark = 0.80
  const safeWithdrawalBenchmark = 0.04
  const debtToAssetBenchmark = 0.20

  const renderComparisonBarGraph = (title: string, benchmark: number, value: number, greaterIsBetter: boolean = true) => {
    const max = Math.max(benchmark, value)
    const benchmarkPct = numberToPercentFormat(benchmark / max, 0)
    const benchmarkLabel = numberToPercentFormat(benchmark, 0)
    const benchmarkSegmentStyle = greaterIsBetter ? styles.benchmarkBarSegmentToRight : styles.benchmarkBarSegmentToLeft
    const comparisonPct = numberToPercentFormat(value / max, 0)
    const comparisonLabel = numberToPercentFormat(value, 1)
    let comparisonBarColor: string = ""
    if (greaterIsBetter) {
      comparisonBarColor = (value >= benchmark) ? goodColor : badColor
    } else {
      comparisonBarColor = (value <= benchmark) ? goodColor : badColor
    }

    return (
      <Box sx={styles.comparisonBarGraph} width={width}>
        <FormLabel sx={styles.formLabel}>{title}</FormLabel>
        <Box sx={styles.comparisonBars}>
          <Box sx={styles.comparisonBar}>
            <Box sx={benchmarkSegmentStyle} width={benchmarkPct}>Benchmark:&nbsp;</Box><Box sx={styles.benchmarkBarLabel}>{benchmarkLabel}</Box>
          </Box>
          <Box sx={styles.comparisonBar}>
            <Box sx={styles.comparisonBarSegment} width={comparisonPct} bgcolor={comparisonBarColor}></Box><Box sx={styles.comparisonBarLabel}>{comparisonLabel}</Box>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column" flexGrow={0}>
      {renderComparisonBarGraph("Secure Income Score", secureIncomeBenchmark, secureIncomeScore, true)}
      {renderComparisonBarGraph("Withdrawal Rate", safeWithdrawalBenchmark, withdrawalRate, false)}
      {renderComparisonBarGraph("Debt to Asset Ratio", debtToAssetBenchmark, debtToAssetRatio, false)}
    </Box>
  )
}

export default RetirementBenchmarksWidget
