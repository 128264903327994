import React, {ReactElement, ReactNode, SyntheticEvent, useEffect, useState} from "react";
import {useResources} from "../../../stores/ResourceProvider";
import {Box, FormLabel, IconButton, Typography, useTheme} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Model from "../../../model/Model";
import Income, {IncomeType} from "../../../model/Income";
import IncomeCard from "./IncomeCard";
import Person from "../../../model/Person";
import ExpenseCard from "./ExpenseCard";
import Expense, {ExpenseCategory} from "../../../model/Expense";
import {
  arrayMove,
  createUUID, getISODateStartOfYear,
  numberToMoneyFormat
} from "../../../stores/StoreUtilities";
import Schedule, {ScheduleNames} from "../../../model/Schedule";
import Deduction from "../../../model/Deduction";
import Tax, {TaxType} from "../../../model/Tax";
import DeductionCard from "./DeductionCard";
import TaxCard from "./TaxCard";
import BasicMenuButton from "../../../components/controls/BasicMenuButton";
import {AddCircleRounded} from "@mui/icons-material";
import Section from "../../../components/controls/Section";
import {
  IBudgetCalc, IDeductionItemSummary,
  IExpenseCategorySummary,
  IExpenseItemSummary, IFutureYear, IIncomeItemSummary,
  IPersonTimeline, ITaxItemSummary
} from "../../../components/calculator/Calculator";
import {startOfToday} from "date-fns";
import range from "lodash.range"
import BudgetProjectionApexChart from "./charts/BudgetProjectionApexChart";
import MonthlyBudgetApexChart from "./charts/MonthlyBudgetApexChart";
import AnnualBudgetApexChart from "./charts/AnnualBudgetApexChart";
import MonthBudgetApexChart from "./charts/MonthBudgetApexChart";
import Asset, {AssetCategory} from "../../../model/Asset";
import YearSlider from "../../../components/controls/YearSlider";
import MonthSlider from "../../../components/controls/MonthSlider";
import ViewMenu from "../../../components/controls/ViewMenu";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreDoubleIcon from "@mui/icons-material/UnfoldMoreDouble";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Plan from "../../../model/Plan";
import {DragDropContext, DraggableProvided, DroppableProvided, DropResult} from "react-beautiful-dnd";
import DraggableList from "../../../components/controls/DraggableList";
import ModelStore from "../../../stores/ModelStore";
import {UpdateDeductionInput, UpdateExpenseInput, UpdateIncomeInput, UpdateTaxInput} from "../../../API";
import Tracking from "../../../components/Tracking";
import IModelItem from "../../../model/IModelItem";

enum NetIncomeView {
  Annual,
  MonthlyAvg
}

const NetIncomeViewNames: string[] = [
  'Annual Surplus/Shortfall',
  'Monthly Avg Surplus/Shortfall'
]

enum BudgetChartView {
  AnnualBudget,
  MonthBudget,
  MonthlyBudget,
}

const BudgetChartNames: string[] = [
  "Annual Budget",
  "Month Budget",
  "Monthly Budget",
  // "Budget Projection",
  // "Net Income",
  // "Annual Budget",
  // "Monthly Average Budget",
]


enum BudgetSection {
  Income = "Income",
  Deductions = "Deductions",
  Taxes = "Taxes",
  Housing = "Housing",
  LoansAndLiabilities = "LoansAndLiabilities",
  FoodAndPersonal = "FoodAndPersonal",
  InsuranceAndMedical = "InsuranceAndMedical",
  VehiclesAndTransportation = "VehiclesAndTransportation",
  TravelAndEntertainment = "TravelAndEntertainment",
  GivingAndMisc = "GivingAndMisc"
}

interface IBudgetViewSettings {
  netIncomeView: NetIncomeView
  expandedSections: string[]
  expandedCards: string[]
  chartView: BudgetChartView
  year?: number
  month?: number
}

const BudgetView = ({
  model,
  updatedAt,
  width,
  onChange
} : {
  model: Model
  updatedAt: string
  width: number
  onChange?(item: IModelItem): void
}) => {
  const sectionCount = 10
  const [viewStorageKey, setViewStorageKey] = useState<string>("")
  const [currentModel, setCurrentModel] = useState<Model | undefined>()
  const [persons, setPersons] = useState<Person[]>([])
  const [timelines, setTimelines] = useState<(IPersonTimeline | null)[]>([])
  const [incomes, setIncomes] = useState<Income[]>([])
  const [deductions, setDeductions] = useState<Deduction[]>([])
  const [taxes, setTaxes] = useState<Tax[]>([])
  const [expenses, setExpenses] = useState<Expense[]>([])
  const [housingExpenses, setHousingExpenses] = useState<Expense[]>([])
  const [loanAndLiabilityExpenses, setLoanAndLiabilityExpenses] = useState<Expense[]>([])
  const [foodAndPersonalCareExpenses, setFoodAndPersonalCareExpenses] = useState<Expense[]>([])
  const [insuranceAndMedicalExpenses, setInsuranceAndMedicalExpenses] = useState<Expense[]>([])
  const [vehiclesAndTransportationExpenses, setVehiclesAndTransportationExpenses] = useState<Expense[]>([])
  const [travelAndEntertainmentExpenses, setTravelAndEntertainmentExpenses] = useState<Expense[]>([])
  const [givingAndMiscExpenses, setGivingAndMiscExpenses] = useState<Expense[]>([])
  const [currentYear, setCurrentYear] = useState<number>(0)
  const [currentMonth, setCurrentMonth] = useState<number | undefined>()
  const [startYear, setStartYear] = useState<number>(0)
  const [endYear, setEndYear] = useState<number>(0)
  const [currentBudgetCalc, setCurrentBudgetCalc] = useState<IBudgetCalc | undefined>()
  const [currentYearCalc, setCurrentYearCalc] = useState<IFutureYear | undefined>()
  const [totalIncome, setTotalIncome] = useState<number>(0)
  const [totalDeductions, setTotalDeductions] = useState<number>(0)
  const [totalTaxes, setTotalTaxes] = useState<number>(0)
  const [totalHousing, setTotalHousing] = useState<number>(0)
  const [totalLoansAndLiabilities, setTotalLoansAndLiabilities] = useState<number>(0)
  const [totalFoodAndPersonal, setTotalFoodAndPersonal] = useState<number>(0)
  const [totalInsuranceAndMedical, setTotalInsuranceAndMedical] = useState<number>(0)
  const [totalVehiclesAndTransportation, setTotalVehiclesAndTransportation] = useState<number>(0)
  const [totalTravelAndEntertainment, setTotalTravelAndEntertainment] = useState<number>(0)
  const [totalGivingAndMisc, setTotalGivingAndMisc] = useState<number>(0)

  const [netIncomeView, setNetIncomeView] = useState<NetIncomeView>(NetIncomeView.Annual)
  const [expandedSections, setExpandedSections] = useState<Set<string>>(new Set<string>([]))
  const [expandedCards, setExpandedCards] = useState<Set<string>>(new Set<string>([]))
  const [chartView, setChartView] = useState<BudgetChartView>(BudgetChartView.AnnualBudget)

  const theme = useTheme()
  const { modelStore, calculator } = useResources()

  useEffect(() => {
    if (model) {
      setCurrentModel(model)
      model.persons.sort((a: Person, b: Person) => a.createdAt.localeCompare(b.createdAt))
      setPersons(model.persons)
      const lines = model.persons.map((p: Person) => calculator.personTimeline((p)))
      setTimelines(lines)
      const startDate = startOfToday()
      let endDate = startDate
      if (lines.length >= 0 && lines[0]) {
        endDate = lines[0].lifeExpectancyDate
        if (lines.length >= 1 && lines[1]) {
          if (lines[1]?.lifeExpectancyDate.getTime() > endDate.getTime()) {
            endDate = lines[1]?.lifeExpectancyDate
          }
        }
      }
      setStartYear(startDate.getFullYear())
      setCurrentYear(startDate.getFullYear())
      setEndYear(endDate.getFullYear()+1)
      setIncomes(model.incomes)
      setDeductions(model.deductions)
      setTaxes(model.taxes)
      setExpenses(model.expenses)
    }

    // Load localStorage view settings
    loadSettings()
  }, [model, model.updatedAt, updatedAt])

  useEffect(() => {
      if (currentModel) {
        saveSettings()
      }
    }, [netIncomeView, expandedSections, expandedCards, chartView, currentYear, currentMonth, viewStorageKey])

  useEffect(() => {
    if (currentModel && currentYear > 0) {
      console.debug(`BudgetView.calculate: ${currentModel.name} ${currentYear}`)
      currentModel.incomes = incomes
      currentModel.deductions = deductions
      currentModel.taxes = taxes
      currentModel.expenses = expenses

      const futureCalc = calculator.calculateFuture(currentModel, "Budget View", currentYear)
      const thisYear = futureCalc.futureYears[futureCalc.futureYears.length-1]
      setCurrentYearCalc(thisYear)
      const budgetCalc = thisYear.budgetCalc
      // const budgetCalc = calculator.calculateBudget(currentYear, model)
      setHousingExpenses(expenses.filter((expense: Expense) => expense.expenseCategory === ExpenseCategory.Housing))
      setLoanAndLiabilityExpenses(expenses.filter((expense: Expense) => expense.expenseCategory === ExpenseCategory.LoansAndLiabilities))
      setFoodAndPersonalCareExpenses(expenses.filter((expense: Expense) => expense.expenseCategory === ExpenseCategory.FoodAndPersonalCare))
      setInsuranceAndMedicalExpenses(expenses.filter((expense: Expense) => expense.expenseCategory === ExpenseCategory.InsuranceAndMedical))
      setVehiclesAndTransportationExpenses(expenses.filter((expense: Expense) => expense.expenseCategory === ExpenseCategory.VehiclesAndTransportation))
      setTravelAndEntertainmentExpenses(expenses.filter((expense: Expense) => expense.expenseCategory === ExpenseCategory.TravelAndEntertainment))
      setGivingAndMiscExpenses(expenses.filter((expense: Expense) => expense.expenseCategory === ExpenseCategory.GivingAndMisc))
      setCurrentBudgetCalc(budgetCalc)
      setTotalIncome(budgetCalc.totalIncome)
      setTotalDeductions(budgetCalc.totalDeductions)
      setTotalTaxes(budgetCalc.totalTaxes)
      // setTotalTaxes(thisYear.taxes)
      setTotalHousing(getExpenseCategoryTotal(ExpenseCategory.Housing, budgetCalc))
      setTotalLoansAndLiabilities(getExpenseCategoryTotal(ExpenseCategory.LoansAndLiabilities, budgetCalc))
      setTotalFoodAndPersonal(getExpenseCategoryTotal(ExpenseCategory.FoodAndPersonalCare, budgetCalc))
      setTotalInsuranceAndMedical(getExpenseCategoryTotal(ExpenseCategory.InsuranceAndMedical, budgetCalc))
      setTotalVehiclesAndTransportation(getExpenseCategoryTotal(ExpenseCategory.VehiclesAndTransportation, budgetCalc))
      setTotalTravelAndEntertainment(getExpenseCategoryTotal(ExpenseCategory.TravelAndEntertainment, budgetCalc))
      setTotalGivingAndMisc(getExpenseCategoryTotal(ExpenseCategory.GivingAndMisc, budgetCalc))
    }
  }, [currentModel?.updatedAt, incomes, deductions, taxes, expenses, currentYear, netIncomeView])

  const styles = {
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"],
    },
  }

  const loadSettings = () => {
    let storageKey = viewStorageKey
    if (!storageKey) {
      storageKey = `${modelStore.currentUser?.id}.BudgetViewSettings`
      setViewStorageKey(storageKey)
    }
    const data = window.localStorage.getItem(storageKey)
    if (data) {
      const settings: IBudgetViewSettings = JSON.parse(data)
      setNetIncomeView(settings.netIncomeView ?? NetIncomeView.Annual)

      if (settings.expandedSections && settings.expandedSections.length && typeof settings.expandedSections[0] === "string") {
        setExpandedSections(new Set<string>([...settings.expandedSections]))
      } else {
        setAllSectionsExpanded(true)
      }
      if (settings.expandedCards && settings.expandedCards.length) {
        setExpandedCards(new Set<string>([...settings.expandedCards]))
      } else {
        setExpandedCards(new Set<string>([]))
      }

      setChartView(settings.chartView && settings.chartView <= BudgetChartView.MonthBudget ? settings.chartView : BudgetChartView.AnnualBudget)
      if (settings.chartView === BudgetChartView.MonthBudget) {
        setCurrentMonth(settings.month ?? 0)
      }
      if (settings.year) {
        setCurrentYear(settings.year)
      }
    }
  }

  const saveSettings = () => {
    const settings = JSON.stringify({
      netIncomeView: netIncomeView,
      expandedSections: Array.from(expandedSections),
      expandedCards: Array.from(expandedCards),
      chartView: chartView,
      month: (chartView === BudgetChartView.MonthBudget) ? currentMonth : undefined,
      year: currentYear
    })
    window.localStorage.setItem(viewStorageKey, settings)
  }

  const getExpandedSection = (id: BudgetSection) => {
    return expandedSections.has(id)
  }

  const setExpandedSection = (id: BudgetSection, expand: boolean) => {
    if (expand) {
      expandedSections.add(id)
    } else {
      expandedSections.delete(id)
    }
    setExpandedSections(new Set(expandedSections))
  }

  const getAllSectionsExpanded = (): boolean => {
    return expandedSections.size === sectionCount
  }

  const setAllSectionsExpanded = (expanded: boolean) => {
    if (expanded) {
      setExpandedSections(new Set<string>(Object.values(BudgetSection)))
    } else {
      setExpandedSections(new Set<string>([]))
    }
  }

  const getExpandedCard = (id: string) => {
    const expanded = expandedCards.has(id)
    return expanded
  }

  const setExpandedCard = (id: string, expand: boolean) => {
    if (expand) {
      expandedCards.add(id)
    } else {
      expandedCards.delete(id)
    }
    setExpandedCards(new Set(expandedCards))
  }

  const getAllCardsExpanded = (): boolean => {
    const totalCards = incomes.length + deductions.length + taxes.length + expenses.length
    return (expandedCards.size === totalCards)
  }

  const setAllCardsExpanded = (expanded: boolean) => {
    if (expanded) {
      const set = new Set<string>([])
      incomes.forEach((item: Income) => set.add(item.id))
      deductions.forEach((item: Deduction) => set.add(item.id))
      taxes.forEach((item: Tax) => set.add(item.id))
      expenses.forEach((item: Expense) => set.add(item.id))
      setExpandedCards(set)
    } else {
      setExpandedCards(new Set<string>([]))
    }
  }


  const getSectionTotal = (amount: number): string => {
    let total
    if (netIncomeView === NetIncomeView.MonthlyAvg) {
      total = Math.round(amount / 12)
    } else {
      total = amount
    }
    return numberToMoneyFormat(total, 0)
  }

  const getProjectedIncome = (income: Income) => {
    const {id} = income

    let total = 0
    if (currentBudgetCalc) {
      const item = currentBudgetCalc.incomeSummary.byItem.find((item: IIncomeItemSummary) => item.id === id)
      if (item) {
        total = item.total
      }
    }
    if (netIncomeView === NetIncomeView.MonthlyAvg) {
      return Math.round(total / 12)
    } else {
      return total
    }
  }

  const getProjectedDeduction = (deduction: Deduction) => {
    const {id} = deduction

    let total = 0
    if (currentBudgetCalc) {
      const item = currentBudgetCalc.deductionSummary.byItem.find((item: IDeductionItemSummary) => item.id === id)
      if (item) {
        total = item.total
      }
    }
    if (netIncomeView === NetIncomeView.MonthlyAvg) {
      return Math.round(total / 12)
    } else {
      return total
    }
  }

  const getProjectedTax = (tax: Tax) => {
    const {id} = tax

    let total = 0
    if (currentBudgetCalc) {
      const item = currentBudgetCalc.taxSummary.byItem.find((item: ITaxItemSummary) => item.id === id)
      if (item) {
        total = item.total
      }
    }
    if (netIncomeView === NetIncomeView.MonthlyAvg) {
      return Math.round(total / 12)
    } else {
      return total
    }
  }

  const getProjectedExpense = (expense: Expense) => {
    const {id, expenseCategory} = expense
    
    let total = 0
    if (currentBudgetCalc) {
      const summary = currentBudgetCalc.expenseSummary.byCategory.find((summary: IExpenseCategorySummary) => summary.category === expenseCategory)
      if (summary) {
        const item = summary.byItem.find((item: IExpenseItemSummary) => item.id === id)
        if (item) {
          total = item.total
        }
      }
    }
    if (netIncomeView === NetIncomeView.MonthlyAvg) {
      return Math.round(total / 12)
    } else {
      return total
    }
  }

  const getExpenseCategoryTotal = (category: ExpenseCategory, budgetCalc?: IBudgetCalc) => {
    let total = 0
    if (budgetCalc) {
      const summary = budgetCalc.expenseSummary.byCategory.find((summary: IExpenseCategorySummary) => summary.category === category)
      if (summary) {
        total = summary.total
      }
    }
    return total
  }

  const handleAddIncome = (index: number) => {
    const option = incomeMenuOptions[index]
    if (currentModel && option) {
      const income = new Income({
        id: createUUID(),
        accountId: currentModel.accountId,
        userId: currentModel.userId,
        modelId: currentModel.id,
        incomeType: option.incomeType,
        description: option.description,
        schedule: Schedule.getDefaultSchedule(ScheduleNames.Monthly),
        start: option.incomeType !== IncomeType.SocialSecurityBenefits ? getISODateStartOfYear() : undefined,
        amount: 0,
        sortOrder: incomes.length > 0 ? incomes[incomes.length-1].sortOrder + ModelStore.orderInterval : 0
      })
      setIncomes([...incomes, income])
      setExpandedSection(BudgetSection.Income, true)
      setExpandedCard(income.id, true)
    }
  }

  const handleSaveIncome = (income: Income) => {
    const index = incomes.findIndex((e: Income) => e.id === income.id)
    if (index >= 0) {
      incomes[index] = income
      setIncomes([...incomes])
    }

    if (onChange) {
      onChange(income)
    }
  }

  const handleDeleteIncome = (deleted: Income) => {
    const index = incomes.findIndex((e: Income) => e.id === deleted.id)
    if (index >= 0) {
      incomes.splice(index, 1)
    }
    setIncomes([...incomes])
  }

  const handleAddDeduction = (index: number) => {
    const options = getDeductionMenuOptions()
    const option = options[index]

    if (currentModel && option) {
      const deduction = new Deduction({
        id: createUUID(),
        accountId: currentModel.accountId,
        userId: currentModel.userId,
        modelId: currentModel.id,
        description: option,
        schedule: Schedule.getDefaultSchedule(ScheduleNames.Monthly),
        start: getISODateStartOfYear(),
        amount: 0,
        sortOrder: deductions.length > 0 ? deductions[deductions.length-1].sortOrder + ModelStore.orderInterval : 0
      })
      if (option !== 'Other' && currentModel) {
        const asset = currentModel.getAssetByDescription(option)
        if (asset) {
          deduction.assetId = asset.id
        }
      }
      setDeductions([...deductions, deduction])
      setExpandedSection(BudgetSection.Deductions, true)
      setExpandedCard(deduction.id, true)
    }
  }

  const handleSaveDeduction = (deduction: Deduction) => {
    const index = deductions.findIndex((e: Deduction) => e.id === deduction.id)
    if (index >= 0) {
      deductions[index] = deduction
      setDeductions([...deductions])
    }

    if (onChange) {
      onChange(deduction)
    }
  }

  const handleDeleteDeduction = (deleted: Deduction) => {
    const index = deductions.findIndex((e: Deduction) => e.id === deleted.id)
    if (index >= 0) {
      deductions.splice(index, 1)
    }
    setDeductions([...deductions])
  }

  const handleAddTax = (index: number) => {
    const option = taxMenuOptions[index]
    if (currentModel && option) {
      const tax = new Tax({
        id: createUUID(),
        accountId: currentModel.accountId,
        userId: currentModel.userId,
        modelId: currentModel.id,
        description: option.description,
        taxType: option.taxType,
        schedule: Schedule.getDefaultSchedule(ScheduleNames.Monthly),
        start: getISODateStartOfYear(),
        amount: 0,
        sortOrder: taxes.length > 0 ? taxes[taxes.length-1].sortOrder + ModelStore.orderInterval : 0
      })
      setTaxes([...taxes, tax])
      setExpandedSection(BudgetSection.Taxes, true)
      setExpandedCard(tax.id, true)
    }
  }

  const handleSaveTax = (tax: Tax) => {
    const index = taxes.findIndex((e: Tax) => e.id === tax.id)
    if (index >= 0) {
      taxes[index] = tax
      setTaxes([...taxes])
    }

    if (onChange) {
      onChange(tax)
    }
  }

  const handleDeleteTax = (deleted: Tax) => {
    const index = taxes.findIndex((e: Tax) => e.id === deleted.id)
    if (index >= 0) {
      taxes.splice(index, 1)
    }
    setTaxes([...taxes])
  }
  
  const handleAddExpense = (index: number, expenseMenuOptions: Expense[], section: BudgetSection, expenseGroup: Expense[]) => {
    const option = expenseMenuOptions[index]
    if (currentModel && option) {
      const expense = new Expense({
        id: createUUID(),
        accountId: currentModel.accountId,
        userId: currentModel.userId,
        modelId: currentModel.id,
        description: option.description,
        expenseCategory: option.expenseCategory,
        schedule: Schedule.getDefaultSchedule(ScheduleNames.Monthly),
        start: getISODateStartOfYear(),
        amount: 0,
        sortOrder: expenseGroup.length > 0 ? expenseGroup[expenseGroup.length-1].sortOrder + ModelStore.orderInterval : 0
      })
      setExpenses([...expenses, expense])
      setExpandedSection(section, true)
      setExpandedCard(expense.id, true)
    }
  }

  const handleSaveExpense = (expense: Expense) => {
    const index = expenses.findIndex((e: Expense) => e.id === expense.id)
    if (index >= 0) {
      expenses[index] = expense
      setExpenses([...expenses])
    }
    if (onChange) {
      onChange(expense)
    }
  }

  const handleDeleteExpense = (deleted: Expense) => {
    const index = expenses.findIndex((e: Expense) => e.id === deleted.id)
    if (index >= 0) {
      expenses.splice(index, 1)
    }
    setExpenses([...expenses])
  }

  const incomeMenuOptions = [
    new Income({description: "Wages, Salary or Tips", incomeType: IncomeType.WagesSalariesTips}),
    new Income({description: "Taxable Interest", incomeType: IncomeType.TaxableInterest}),
    new Income({description: "Tax Exempt Interest", incomeType: IncomeType.TaxExemptInterest}),
    new Income({description: "Qualified Dividends", incomeType: IncomeType.QualifiedDividends}),
    new Income({description: "Nonqualified Dividends", incomeType: IncomeType.NonqualifiedDividends}),
    new Income({description: "Pensions & Annuities (taxable)", incomeType: IncomeType.PensionsAndAnnuitiesTaxable}),
    new Income({description: "Pensions & Annuities (non-taxable)", incomeType: IncomeType.PensionsAndAnnuitiesNonTaxable}),
    new Income({description: "Social Security Benefit", incomeType: IncomeType.SocialSecurityBenefits}),
    new Income({description: "Social Security Child Benefit", incomeType: IncomeType.SocialSecurityChildBenefits}),
    new Income({description: "Short Term Capital Gains", incomeType: IncomeType.ShortTermCapitalGainsOrLoss}),
    new Income({description: "Long Term Capital Gains", incomeType: IncomeType.LongTermCapitalGainsOrLoss}),
    new Income({description: "Other Income", incomeType: IncomeType.OtherIncome}),
  ]

  const getIncomeMenuOptions = () => {
    return incomeMenuOptions.map((item: Income) => item.description)
  }

  const getDeductionMenuOptions = () => {
    let options: string[] = []
    if (currentModel) {
      const assets = currentModel.getAssetsByCategory(AssetCategory.LiquidInvestableAssets)
        .filter((a: Asset) => {
          // Filter out assets that already have a deduction
          return (currentModel.deductions.find((d: Deduction) => d.assetId === a.id) === undefined)
        })
      options = assets.map((a: Asset) => a.description)
    }
    return [...options, 'Other']
  }

  // const deductionMenuOptions = [
  //   "Retirement (401k)",
  //   "Other"
  // ]
  //
  const taxMenuOptions = [
    new Tax({description: "Federal Income Tax", taxType: TaxType.FederalIncomeTax}),
    new Tax({description: "Social Security Tax", taxType: TaxType.SocialSecurityTax}),
    new Tax({description: "Medicare Tax", taxType: TaxType.MedicareTax}),
    new Tax({description: "State Income Tax", taxType: TaxType.StateIncomeTax}),
    new Tax({description: "Local Income Tax", taxType: TaxType.LocalIncomeTax}),
    new Tax({description: "Other Tax", taxType: TaxType.OtherTax}),
  ]

  const getTaxMenuOptions = () => {
    return taxMenuOptions.map((item: Tax) => item.description)
  }

  const housingExpenseOptions = [
    new Expense({description: "Cable", expenseCategory: ExpenseCategory.Housing}),
    new Expense({description: "Cellular Phone", expenseCategory: ExpenseCategory.Housing}),
    new Expense({description: "Electric", expenseCategory: ExpenseCategory.Housing}),
    new Expense({description: "Furniture", expenseCategory: ExpenseCategory.Housing}),
    new Expense({description: "Garbage", expenseCategory: ExpenseCategory.Housing}),
    new Expense({description: "HOA Dues", expenseCategory: ExpenseCategory.Housing}),
    new Expense({description: "Home Improvements", expenseCategory: ExpenseCategory.Housing}),
    new Expense({description: "Home Phone", expenseCategory: ExpenseCategory.Housing}),
    new Expense({description: "Internet", expenseCategory: ExpenseCategory.Housing}),
    new Expense({description: "Natural Gas", expenseCategory: ExpenseCategory.Housing}),
    new Expense({description: "Real Estate Taxes", expenseCategory: ExpenseCategory.Housing}),
    new Expense({description: "Security System", expenseCategory: ExpenseCategory.Housing}),
    new Expense({description: "Sewer", expenseCategory: ExpenseCategory.Housing}),
    new Expense({description: "Water", expenseCategory: ExpenseCategory.Housing}),
    new Expense({description: "Yard Maintenance / Gardening", expenseCategory: ExpenseCategory.Housing}),
    new Expense({description: "Other", expenseCategory: ExpenseCategory.Housing}),
  ]

  const loanAndLiabilityExpenseOptions = [
    new Expense({description: "Alimony", expenseCategory: ExpenseCategory.LoansAndLiabilities}),
    new Expense({description: "Auto Loan(s)", expenseCategory: ExpenseCategory.LoansAndLiabilities}),
    new Expense({description: "Boat Loan", expenseCategory: ExpenseCategory.LoansAndLiabilities}),
    new Expense({description: "Child Support", expenseCategory: ExpenseCategory.LoansAndLiabilities}),
    new Expense({description: "Credit Card", expenseCategory: ExpenseCategory.LoansAndLiabilities}),
    new Expense({description: "House Mortgage (P&I)", expenseCategory: ExpenseCategory.LoansAndLiabilities}),
    new Expense({description: "RV / Camping trailer loan", expenseCategory: ExpenseCategory.LoansAndLiabilities}),
    new Expense({description: "Student Loan", expenseCategory: ExpenseCategory.LoansAndLiabilities}),
    new Expense({description: "Other", expenseCategory: ExpenseCategory.LoansAndLiabilities}),
  ]

  const foodAndPersonalCareExpenseOptions = [
    new Expense({description: "Chiropractor", expenseCategory: ExpenseCategory.FoodAndPersonalCare}),
    new Expense({description: "Clothes & Shoes", expenseCategory: ExpenseCategory.FoodAndPersonalCare}),
    new Expense({description: "Dry Cleaning", expenseCategory: ExpenseCategory.FoodAndPersonalCare}),
    new Expense({description: "Groceries", expenseCategory: ExpenseCategory.FoodAndPersonalCare}),
    new Expense({description: "Gym membership", expenseCategory: ExpenseCategory.FoodAndPersonalCare}),
    new Expense({description: "Haircuts / Nails / Massage", expenseCategory: ExpenseCategory.FoodAndPersonalCare}),
    new Expense({description: "Pet Food & Medication", expenseCategory: ExpenseCategory.FoodAndPersonalCare}),
    new Expense({description: "Restaurants", expenseCategory: ExpenseCategory.FoodAndPersonalCare}),
    new Expense({description: "Spending Cash", expenseCategory: ExpenseCategory.FoodAndPersonalCare}),
    new Expense({description: "Other", expenseCategory: ExpenseCategory.FoodAndPersonalCare}),
  ]

  const insuranceAndMedicalExpenseOptions = [
    new Expense({description: "Auto Insurance", expenseCategory: ExpenseCategory.InsuranceAndMedical}),
    new Expense({description: "Dental Insurance", expenseCategory: ExpenseCategory.InsuranceAndMedical}),
    new Expense({description: "Health Insurance", expenseCategory: ExpenseCategory.InsuranceAndMedical}),
    new Expense({description: "Home Owners Insurance", expenseCategory: ExpenseCategory.InsuranceAndMedical}),
    new Expense({description: "Life Insurance", expenseCategory: ExpenseCategory.InsuranceAndMedical}),
    new Expense({description: "Long Term Care Insurance", expenseCategory: ExpenseCategory.InsuranceAndMedical}),
    new Expense({description: "Medicare Supplement Insurance", expenseCategory: ExpenseCategory.InsuranceAndMedical}),
    new Expense({description: "Medications", expenseCategory: ExpenseCategory.InsuranceAndMedical}),
    new Expense({description: "Vision & Eyecare", expenseCategory: ExpenseCategory.InsuranceAndMedical}),
    new Expense({description: "Other", expenseCategory: ExpenseCategory.InsuranceAndMedical}),
  ]

  const vehiclesAndTransportationExpenseOptions = [
    new Expense({description: "Annual Tune Up", expenseCategory: ExpenseCategory.VehiclesAndTransportation}),
    new Expense({description: "Fuel", expenseCategory: ExpenseCategory.VehiclesAndTransportation}),
    new Expense({description: "Oil Change(s)", expenseCategory: ExpenseCategory.VehiclesAndTransportation}),
    new Expense({description: "License Renewal Tabs", expenseCategory: ExpenseCategory.VehiclesAndTransportation}),
    new Expense({description: "Maintenance", expenseCategory: ExpenseCategory.VehiclesAndTransportation}),
    new Expense({description: "Memberships", expenseCategory: ExpenseCategory.VehiclesAndTransportation}),
    new Expense({description: "Public Transportation", expenseCategory: ExpenseCategory.VehiclesAndTransportation}),
    new Expense({description: "Repairs", expenseCategory: ExpenseCategory.VehiclesAndTransportation}),
    new Expense({description: "Tires", expenseCategory: ExpenseCategory.VehiclesAndTransportation}),
    new Expense({description: "Other", expenseCategory: ExpenseCategory.VehiclesAndTransportation}),
  ]

  const travelAndEntertainmentExpenseOptions = [
    new Expense({description: "Amazon Prime", expenseCategory: ExpenseCategory.TravelAndEntertainment}),
    new Expense({description: "Anniversary", expenseCategory: ExpenseCategory.TravelAndEntertainment}),
    new Expense({description: "Birthdays", expenseCategory: ExpenseCategory.TravelAndEntertainment}),
    new Expense({description: "Christmas", expenseCategory: ExpenseCategory.TravelAndEntertainment}),
    new Expense({description: "Dates", expenseCategory: ExpenseCategory.TravelAndEntertainment}),
    new Expense({description: "Hobbies & Lessons ", expenseCategory: ExpenseCategory.TravelAndEntertainment}),
    new Expense({description: "Magazine & Newspaper", expenseCategory: ExpenseCategory.TravelAndEntertainment}),
    new Expense({description: "Netflix", expenseCategory: ExpenseCategory.TravelAndEntertainment}),
    new Expense({description: "Software Subscriptions", expenseCategory: ExpenseCategory.TravelAndEntertainment}),
    new Expense({description: "Vacations", expenseCategory: ExpenseCategory.TravelAndEntertainment}),
    new Expense({description: "Other", expenseCategory: ExpenseCategory.TravelAndEntertainment}),
  ]

  const givingAndMiscExpenseOptions = [
    new Expense({description: "Charitable Donations", expenseCategory: ExpenseCategory.GivingAndMisc}),
    new Expense({description: "Financial Adviser", expenseCategory: ExpenseCategory.GivingAndMisc}),
    new Expense({description: "Missions", expenseCategory: ExpenseCategory.GivingAndMisc}),
    new Expense({description: "Tax Preparation", expenseCategory: ExpenseCategory.GivingAndMisc}),
    new Expense({description: "Tithe & Offerings", expenseCategory: ExpenseCategory.GivingAndMisc}),
    new Expense({description: "Other", expenseCategory: ExpenseCategory.GivingAndMisc}),
  ]

  const handleChangeYear = (year: number) => {
    setCurrentYear(year)
  }

  const handleChangeMonth = (month?: number) => {
    setCurrentMonth(month)
    if (month !== undefined) {
      setChartView(BudgetChartView.MonthBudget)
    } else {
      setChartView(BudgetChartView.MonthlyBudget)
    }
  }

  const renderYearSelector = () => {
    if (startYear > 0) {
      return (
        <BasicMenuButton
          icon={<ExpandMoreIcon sx={{color: theme.palette.primary.main}}/>}
          label={
            <Typography variant="h3" color="primary" sx={{padding:0, margin:0}}>
              {currentYear}
            </Typography>
          }
          labelPosition="left"
          options={range(startYear, endYear).map((year: number) => year.toString())}
          value={currentYear - startYear}
          onClickOption={(index: number) => {
            handleChangeYear(startYear + index)
          }}
        />
      )

    } else {
      return null
    }
  }

  const renderNetIncomeSelector = () => {
    if (startYear > 0) {
      return (
        <BasicMenuButton
          icon={<ExpandMoreIcon sx={{color: theme.palette.primary.main, width:18, height:18}}/>}
          label={
            <FormLabel sx={styles.formLabel}>{NetIncomeViewNames[netIncomeView]}</FormLabel>
          }
          labelPosition="left"
          options={NetIncomeViewNames}
          value={netIncomeView}
          onClickOption={(index: number) => {
            setNetIncomeView(index)
          }}
        />
      )

    } else {
      return null
    }
  }

  const renderNetIncome = () => {
    let netIncome = 0
    if (currentYearCalc && currentBudgetCalc) {
      netIncome = currentBudgetCalc.totalIncome - currentBudgetCalc.totalDeductions - currentBudgetCalc.totalExpenses
      if (currentBudgetCalc.totalEstimatedTaxes) {
        netIncome -= currentBudgetCalc.totalEstimatedTaxes
      } else {
        netIncome -= currentBudgetCalc.totalTaxes
      }
    }

    const color = (netIncome < 0) ? "red" : "green"
    const income = (netIncomeView === NetIncomeView.MonthlyAvg) ? Math.round(netIncome / 12) : netIncome
    return (
      <Typography variant="h4" color={color}>{numberToMoneyFormat(income, 0)}</Typography>
    )
  }

  const renderUnfoldMenu = () => {
    const allSectionsExpanded = getAllSectionsExpanded()
    const allCardsExpanded = getAllCardsExpanded()

    let icon
    if (allSectionsExpanded) {
      if (allCardsExpanded) {
        icon = <UnfoldLessIcon/>
      } else {
        icon = <UnfoldMoreDoubleIcon/>
      }
    } else {
      icon = <UnfoldMoreIcon/>
    }
    return (
      <IconButton sx={{marginLeft:0, paddingLeft:"4px", paddingRight:0}}
                  onClick={() => {
                    if (allSectionsExpanded) {
                      if (allCardsExpanded) {
                        setAllCardsExpanded(false)
                        setAllSectionsExpanded(false)
                      } else {
                        setAllCardsExpanded(true)
                      }
                    } else {
                      setAllSectionsExpanded(true)
                    }
                  }}
      >
        {icon}
      </IconButton>
    )
  }

  const renderViewMenu = () => {

    return (
      <ViewMenu
        view={chartView}
        variant="auto"
        options={BudgetChartNames}
        onChange={(index: number) => {
          setChartView(index)
          Tracking.event({action: "Budget View", label: BudgetChartNames[index]})
          if (index === BudgetChartView.MonthBudget && currentMonth === undefined) {
            setCurrentMonth(0)
          }
        }}
      />
    )
  }

  // const renderMonthMenu = () => {
  //   if (chartView !== BudgetChartView.MonthlyBudget && chartView !== BudgetChartView.MonthBudget) {
  //     return null
  //   }
  //   const label = (currentMonth !== undefined) ? getMonthName(currentMonth) : "All Months"
  //
  //   return (
  //     <BasicMenuButton
  //       icon={<ExpandMoreIcon sx={{color: theme.palette.primary.main}}/>}
  //       label={
  //         <Typography variant="h6" color="primary" sx={{padding:0, margin:0}}>
  //           {label}
  //         </Typography>
  //       }
  //       labelPosition="left"
  //       options={['All Months', ...getMonthNames()]}
  //       onClickOption={(index: number) => {
  //         if (index > 0) {
  //           setChartView(BudgetChartView.MonthBudget)
  //           setCurrentMonth(index - 1)
  //         } else {
  //           setChartView(BudgetChartView.MonthlyBudget)
  //           setCurrentMonth(undefined)
  //         }
  //       }}
  //     />
  //   )
  // }

  const renderSlider = () => {
    if (chartView !== BudgetChartView.MonthBudget) {
      return (
        <YearSlider
          value={currentYear}
          min={startYear}
          max={endYear}
          onChange={(year: number) => {
            setCurrentYear(year)
          }}
        />
      )
    } else if (chartView === BudgetChartView.MonthBudget && currentMonth !== undefined) {
      return (
        <MonthSlider
          value={currentMonth}
          onChange={(month: number) => {
            setCurrentMonth(month)
          }}
        />
      )
    } else {
      return null
    }
  }

  const renderListWrapper = (children: ReactNode, providedMain: DroppableProvided) => {
    return (
      <div ref={providedMain.innerRef} {...providedMain.droppableProps}>
        {children}
      </div>
    )
  }

  // Income handlers
  
  const renderIncome = (income: Income, provided: DraggableProvided): ReactElement => {
    return (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <IncomeCard income={income} model={currentModel!} /*key={income.id}*/
                    projected={getProjectedIncome(income)}
                    expanded={getExpandedCard(income.id)}
                    onChange={(event: SyntheticEvent, value: boolean) => setExpandedCard(income.id, value)}
                    onSave={handleSaveIncome} onDelete={handleDeleteIncome}
        />
      </div>
    )
  }

  const handleIncomeDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const srcIndex = result.source.index
    const dstIndex = result.destination.index
    let length
    let sortOrder
    if (result.source.droppableId === "incomes") {
      length = incomes.length
      if (incomes[0].sortOrder) {
        if (dstIndex === 0) {
          // Beginning of list - Set to halfway between 0 and first item
          sortOrder = incomes[0].sortOrder / 2
        } else if (dstIndex >= length-1) {
          // End of list - Set to last item + orderInterval
          sortOrder = incomes[length - 1].sortOrder + ModelStore.orderInterval
        } else {
          // Middle of list - Set to halfway between the adjacent item orders
          sortOrder = incomes[dstIndex-1].sortOrder + (incomes[dstIndex].sortOrder - incomes[dstIndex-1].sortOrder) / 2
        }
        let income = incomes[srcIndex]
        // Move before async operation to avoid extra refresh
        arrayMove(incomes, srcIndex, dstIndex)
        const input: UpdateIncomeInput = {
          id: income.id,
          accountId: income.accountId,
          sortOrder: sortOrder
        }
        let update = await modelStore.updateIncome(input)
        // if (update) {
        //   incomes[dstIndex] = update
        // }
        // setIncomes([...incomes])
      } else {
        // Reorder incomes
        arrayMove(incomes, srcIndex, dstIndex)
        setIncomes(await modelStore.reorderIncomes(incomes))
      }
    }
  }

// Deduction handlers

  const renderDeduction = (deduction: Deduction, provided: DraggableProvided): ReactElement => {
    return (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <DeductionCard deduction={deduction} model={currentModel!} /*key={deduction.id}*/
                    projected={getProjectedDeduction(deduction)}
                    expanded={getExpandedCard(deduction.id)}
                    onChange={(event: SyntheticEvent, value: boolean) => setExpandedCard(deduction.id, value)}
                    onSave={handleSaveDeduction} onDelete={handleDeleteDeduction}
        />
      </div>
    )
  }

  const handleDeductionDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const srcIndex = result.source.index
    const dstIndex = result.destination.index
    let length
    let sortOrder
    if (result.source.droppableId === "deductions") {
      length = deductions.length
      if (deductions[0].sortOrder) {
        if (dstIndex === 0) {
          // Beginning of list - Set to halfway between 0 and first item
          sortOrder = deductions[0].sortOrder / 2
        } else if (dstIndex >= length-1) {
          // End of list - Set to last item + orderInterval
          sortOrder = deductions[length - 1].sortOrder + ModelStore.orderInterval
        } else {
          // Middle of list - Set to halfway between the adjacent item orders
          sortOrder = deductions[dstIndex-1].sortOrder + (deductions[dstIndex].sortOrder - deductions[dstIndex-1].sortOrder) / 2
        }
        let deduction = deductions[srcIndex]
        // Move before async operation to avoid extra refresh
        arrayMove(deductions, srcIndex, dstIndex)
        const input: UpdateDeductionInput = {
          id: deduction.id,
          accountId: deduction.accountId,
          sortOrder: sortOrder
        }
        let update = await modelStore.updateDeduction(input)
        // if (update) {
        //   deductions[dstIndex] = update
        // }
        // setDeductions([...deductions])
      } else {
        // Reorder deductions
        arrayMove(deductions, srcIndex, dstIndex)
        setDeductions(await modelStore.reorderDeductions(deductions))
      }
    }
  }

  // Tax handlers

  const renderTax = (tax: Tax, provided: DraggableProvided): ReactElement => {
    return (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <TaxCard tax={tax} model={currentModel!} /*key={tax.id}*/
                       projected={getProjectedTax(tax)}
                       expanded={getExpandedCard(tax.id)}
                       onChange={(event: SyntheticEvent, value: boolean) => setExpandedCard(tax.id, value)}
                       onSave={handleSaveTax} onDelete={handleDeleteTax}
        />
      </div>
    )
  }

  const handleTaxDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const srcIndex = result.source.index
    const dstIndex = result.destination.index
    let length
    let sortOrder
    if (result.source.droppableId === "taxes") {
      length = taxes.length
      if (taxes[0].sortOrder) {
        if (dstIndex === 0) {
          // Beginning of list - Set to halfway between 0 and first item
          sortOrder = taxes[0].sortOrder / 2
        } else if (dstIndex >= length-1) {
          // End of list - Set to last item + orderInterval
          sortOrder = taxes[length - 1].sortOrder + ModelStore.orderInterval
        } else {
          // Middle of list - Set to halfway between the adjacent item orders
          sortOrder = taxes[dstIndex-1].sortOrder + (taxes[dstIndex].sortOrder - taxes[dstIndex-1].sortOrder) / 2
        }
        let tax = taxes[srcIndex]
        // Move before async operation to avoid extra refresh
        arrayMove(taxes, srcIndex, dstIndex)
        const input: UpdateTaxInput = {
          id: tax.id,
          accountId: tax.accountId,
          sortOrder: sortOrder
        }
        let update = await modelStore.updateTax(input)
        // if (update) {
        //   taxes[dstIndex] = update
        // }
        // setTaxes([...taxes])
      } else {
        // Reorder taxes
        arrayMove(taxes, srcIndex, dstIndex)
        setTaxes(await modelStore.reorderTaxes(taxes))
      }
    }
  }

  // Expense handlers

  const renderExpense = (expense: Expense, provided: DraggableProvided): ReactElement => {
    return (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <ExpenseCard expense={expense} model={currentModel!} /*key={expense.id}*/
                 projected={getProjectedExpense(expense)}
                 expanded={getExpandedCard(expense.id)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedCard(expense.id, value)}
                 onSave={handleSaveExpense} onDelete={handleDeleteExpense}
        />
      </div>
    )
  }

  const handleExpenseDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    }

    let srcIndex = result.source.index
    let dstIndex = result.destination.index
    let expenseGroup: Expense[]
    let setExpenseGroup = setHousingExpenses
    let length: number
    let sortOrder
    switch (result.source.droppableId) {
      case "housingExpenses":
        expenseGroup = housingExpenses
        setExpenseGroup = setHousingExpenses
        break
      case "loanAndLiabilityExpenses":
        expenseGroup = loanAndLiabilityExpenses
        setExpenseGroup = setLoanAndLiabilityExpenses
        break
      case "foodAndPersonalCareExpenses":
        expenseGroup = foodAndPersonalCareExpenses
        setExpenseGroup = setFoodAndPersonalCareExpenses
        break
      case "insuranceAndMedicalExpenses":
        expenseGroup = insuranceAndMedicalExpenses
        setExpenseGroup = setHousingExpenses
        break
      case "vehiclesAndTransportationExpenses":
        expenseGroup = vehiclesAndTransportationExpenses
        setExpenseGroup = setVehiclesAndTransportationExpenses
        break
      case "travelAndEntertainmentExpenses":
        expenseGroup = travelAndEntertainmentExpenses
        setExpenseGroup = setTravelAndEntertainmentExpenses
        break
      case "givingAndMiscExpenses":
        expenseGroup = givingAndMiscExpenses
        setExpenseGroup = setGivingAndMiscExpenses
        break
      default:
        return
    }

    length = expenseGroup.length
    if (expenseGroup[0].sortOrder) {
      if (dstIndex === 0) {
        // Beginning of list - Set to halfway between 0 and first item
        sortOrder = expenseGroup[0].sortOrder / 2
      } else if (dstIndex >= length-1) {
        // End of list - Set to last item + orderInterval
        sortOrder = expenseGroup[length - 1].sortOrder + ModelStore.orderInterval
      } else {
        // Middle of list - Set to halfway between the adjacent item orders
        sortOrder = expenseGroup[dstIndex-1].sortOrder + (expenseGroup[dstIndex].sortOrder - expenseGroup[dstIndex-1].sortOrder) / 2
      }
      let expense = expenseGroup[srcIndex]
      // Move before async operation to avoid extra refresh
      // Reorder expenses
      arrayMove(expenseGroup, srcIndex, dstIndex)
      setExpenseGroup(expenseGroup)
      // const srcId = expenseGroup[srcIndex].id
      // srcIndex = expenses.findIndex((e: Expense) => e.id === srcId)
      // const dstId = expenseGroup[dstIndex].id
      // dstIndex = expenses.findIndex((e: Expense) => e.id === dstId)
      // if (srcIndex >= 0 && dstIndex >= 0) {
      //   arrayMove(expenses, srcIndex, dstIndex)
      // }
      const input: UpdateExpenseInput = {
        id: expense.id,
        accountId: expense.accountId,
        sortOrder: sortOrder
      }
      let update = await modelStore.updateExpense(input)
      // if (update) {
      //   const index = expenses.findIndex((e: Expense) => e.id === update!.id)
      //   if (index >= 0) {
      //     expenses[index] = update
      //   }
      // }
      // currentModel!.sortExpenses(expenses)
      // setExpenses([...expenses])
    } else {
      // Reorder expenses
      const srcId = expenseGroup[srcIndex].id
      srcIndex = expenses.findIndex((e: Expense) => e.id === srcId)
      const dstId = expenseGroup[dstIndex].id
      dstIndex = expenses.findIndex((e: Expense) => e.id === dstId)
      if (srcIndex >= 0 && dstIndex >= 0) {
        arrayMove(expenses, srcIndex, dstIndex)
      }
      setExpenses(await modelStore.reorderExpenses(expenses))
    }
  }

  const leftPanelWidth = 360
  const rightPanelWidth = width - leftPanelWidth - 36

  return (
    <Box sx={{display:"flex", flexDirection:"row", mt:1, minHeight: "calc(100vh - 180px)"}}>
      <Box sx={{display:"flex", flexGrow:0, width: { xs:"100%", sm:leftPanelWidth},
        minWidth: { xs:"100%", sm:leftPanelWidth}, minHeight:"100%", maxHeight: "calc(100vh - 84px)",
        overflowY:"scroll", flexDirection:"column"}}>
        <Box sx={{display:"flex", mt:0, pl:0, pb:0, mb:"2px"}}>
          <Typography variant="h2" color="primary">Budget</Typography>
        </Box>
        <Box sx={{display:"flex", flexGrow:0, flexDirection:"row", justifyContent:"space-between", paddingLeft: 0.5, paddingRight: 0.5, maxHeight: 50}}>
          <Box sx={{display:"flex", flexGrow:0, flexDirection:"column"}}>
            <Box sx={{display:"flex", flexGrow:0, justifyContent:"space-between", maxHeight:16, marginBottom:"4px"}}>
              <FormLabel sx={styles.formLabel}>Year</FormLabel>
            </Box>
            <Box sx={{display:"flex", flexGrow:0}}>
              {renderYearSelector()}
            </Box>
          </Box>
          <Box sx={{display:"flex", flexGrow:1, flexDirection:"column", alignItems:"flex-end", textAlign:"right"}}>
            {renderNetIncomeSelector()}
            {renderNetIncome()}
          </Box>
          <Box sx={{display:"flex", flexGrow:0, paddingTop:"4px"}}>
            {renderUnfoldMenu()}
          </Box>
        </Box>

        <Section title="Income" total={getSectionTotal(totalIncome)}
                 expanded={getExpandedSection(BudgetSection.Income)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedSection(BudgetSection.Income, value)}
                 button={
                   <BasicMenuButton
                     icon={<AddCircleRounded sx={{color: theme.palette.secondary.light}}/>}
                     label=""
                     options={getIncomeMenuOptions()}
                     onClickOption={handleAddIncome}
                   />
                 }
        >
          <DragDropContext onDragEnd={handleIncomeDragEnd}>
            <DraggableList droppableId="incomes" data={incomes} renderWrapper={renderListWrapper}
                         renderItem={renderIncome}
            />
          </DragDropContext>
        </Section>

        <Section title="Deductions & Contributions" total={getSectionTotal(totalDeductions)}
                 titleSx={{letterSpacing: "-0.05em"}}
                 expanded={getExpandedSection(BudgetSection.Deductions)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedSection(BudgetSection.Deductions, value)}
                 button={
                   <BasicMenuButton
                     icon={<AddCircleRounded sx={{color: theme.palette.secondary.light}}/>}
                     label=""
                     options={getDeductionMenuOptions()}
                     onClickOption={handleAddDeduction}
                   />
                 }
        >
          <DragDropContext onDragEnd={handleDeductionDragEnd}>
            <DraggableList droppableId="deductions" data={deductions} renderWrapper={renderListWrapper}
                           renderItem={renderDeduction}
            />
          </DragDropContext>
        </Section>

        <Section title="Tax Withholdings" total={getSectionTotal(totalTaxes)}
                 expanded={getExpandedSection(BudgetSection.Taxes)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedSection(BudgetSection.Taxes, value)}
                 button={
                   <BasicMenuButton
                     icon={<AddCircleRounded sx={{color: theme.palette.secondary.light}}/>}
                     label=""
                     options={getTaxMenuOptions()}
                     onClickOption={handleAddTax}
                   />
                 }
        >
          <DragDropContext onDragEnd={handleTaxDragEnd}>
            <DraggableList droppableId="taxes" data={taxes} renderWrapper={renderListWrapper}
                           renderItem={renderTax}
            />
          </DragDropContext>
        </Section>

        <Section title="Housing" total={getSectionTotal(totalHousing)}
                 expanded={getExpandedSection(BudgetSection.Housing)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedSection(BudgetSection.Housing, value)}
                 button={
                   <BasicMenuButton
                     icon={<AddCircleRounded sx={{color: theme.palette.secondary.light}}/>}
                     label=""
                     options={housingExpenseOptions.map((expense: Expense) => expense.description)}
                     onClickOption={(index: number) => handleAddExpense(index, housingExpenseOptions, BudgetSection.Housing, housingExpenses)}
                   />
                 }
        >
          <DragDropContext onDragEnd={handleExpenseDragEnd}>
            <DraggableList droppableId="housingExpenses" data={housingExpenses} renderWrapper={renderListWrapper}
                           renderItem={renderExpense}
            />
          </DragDropContext>
        </Section>

        <Section title="Loans & Liabilities" total={getSectionTotal(totalLoansAndLiabilities)}
                 expanded={getExpandedSection(BudgetSection.LoansAndLiabilities)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedSection(BudgetSection.LoansAndLiabilities, value)}
                 button={
                   <BasicMenuButton
                     icon={<AddCircleRounded sx={{color: theme.palette.secondary.light}}/>}
                     label=""
                     options={loanAndLiabilityExpenseOptions.map((expense: Expense) => expense.description)}
                     onClickOption={(index: number) => handleAddExpense(index, loanAndLiabilityExpenseOptions, BudgetSection.LoansAndLiabilities, loanAndLiabilityExpenses)}
                   />
                 }
        >
          <DragDropContext onDragEnd={handleExpenseDragEnd}>
            <DraggableList droppableId="loanAndLiabilityExpenses" data={loanAndLiabilityExpenses} renderWrapper={renderListWrapper}
                           renderItem={renderExpense}
            />
          </DragDropContext>
        </Section>

        <Section title="Food & Personal Care" total={getSectionTotal(totalFoodAndPersonal)}
                 expanded={getExpandedSection(BudgetSection.FoodAndPersonal)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedSection(BudgetSection.FoodAndPersonal, value)}
                 button={
                   <BasicMenuButton
                     icon={<AddCircleRounded sx={{color: theme.palette.secondary.light}}/>}
                     label=""
                     options={foodAndPersonalCareExpenseOptions.map((expense: Expense) => expense.description)}
                     onClickOption={(index: number) => handleAddExpense(index, foodAndPersonalCareExpenseOptions, BudgetSection.FoodAndPersonal, foodAndPersonalCareExpenses)}
                   />
                 }
        >
          <DragDropContext onDragEnd={handleExpenseDragEnd}>
            <DraggableList droppableId="foodAndPersonalCareExpenses" data={foodAndPersonalCareExpenses} renderWrapper={renderListWrapper}
                           renderItem={renderExpense}
            />
          </DragDropContext>
        </Section>

        <Section title="Insurance & Medical" total={getSectionTotal(totalInsuranceAndMedical)}
                 expanded={getExpandedSection(BudgetSection.InsuranceAndMedical)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedSection(BudgetSection.InsuranceAndMedical, value)}
                 button={
                   <BasicMenuButton
                     icon={<AddCircleRounded sx={{color: theme.palette.secondary.light}}/>}
                     label=""
                     options={insuranceAndMedicalExpenseOptions.map((expense: Expense) => expense.description)}
                     onClickOption={(index: number) => handleAddExpense(index, insuranceAndMedicalExpenseOptions, BudgetSection.InsuranceAndMedical, insuranceAndMedicalExpenses)}
                   />
                 }
        >
          <DragDropContext onDragEnd={handleExpenseDragEnd}>
            <DraggableList droppableId="insuranceAndMedicalExpenses" data={insuranceAndMedicalExpenses} renderWrapper={renderListWrapper}
                           renderItem={renderExpense}
            />
          </DragDropContext>
        </Section>

        <Section title="Vehicles & Transportation" total={getSectionTotal(totalVehiclesAndTransportation)}
                 expanded={getExpandedSection(BudgetSection.VehiclesAndTransportation)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedSection(BudgetSection.VehiclesAndTransportation, value)}
                 button={
                   <BasicMenuButton
                     icon={<AddCircleRounded sx={{color: theme.palette.secondary.light}}/>}
                     label=""
                     options={vehiclesAndTransportationExpenseOptions.map((expense: Expense) => expense.description)}
                     onClickOption={(index: number) => handleAddExpense(index, vehiclesAndTransportationExpenseOptions, BudgetSection.VehiclesAndTransportation, vehiclesAndTransportationExpenses)}
                   />
                 }
        >
          <DragDropContext onDragEnd={handleExpenseDragEnd}>
            <DraggableList droppableId="vehiclesAndTransportationExpenses" data={vehiclesAndTransportationExpenses} renderWrapper={renderListWrapper}
                           renderItem={renderExpense}
            />
          </DragDropContext>
        </Section>

        <Section title="Travel & Entertainment" total={getSectionTotal(totalTravelAndEntertainment)}
                 expanded={getExpandedSection(BudgetSection.TravelAndEntertainment)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedSection(BudgetSection.TravelAndEntertainment, value)}
                 button={
                   <BasicMenuButton
                     icon={<AddCircleRounded sx={{color: theme.palette.secondary.light}}/>}
                     label=""
                     options={travelAndEntertainmentExpenseOptions.map((expense: Expense) => expense.description)}
                     onClickOption={(index: number) => handleAddExpense(index, travelAndEntertainmentExpenseOptions, BudgetSection.TravelAndEntertainment, travelAndEntertainmentExpenses)}
                   />
                 }
        >
          <DragDropContext onDragEnd={handleExpenseDragEnd}>
            <DraggableList droppableId="travelAndEntertainmentExpenses" data={travelAndEntertainmentExpenses} renderWrapper={renderListWrapper}
                           renderItem={renderExpense}
            />
          </DragDropContext>
        </Section>

        <Section title="Giving & Misc" total={getSectionTotal(totalGivingAndMisc)}
                 expanded={getExpandedSection(BudgetSection.GivingAndMisc)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedSection(BudgetSection.GivingAndMisc, value)}
                 button={
                   <BasicMenuButton
                     icon={<AddCircleRounded sx={{color: theme.palette.secondary.light}}/>}
                     label=""
                     options={givingAndMiscExpenseOptions.map((expense: Expense) => expense.description)}
                     onClickOption={(index: number) => handleAddExpense(index, givingAndMiscExpenseOptions, BudgetSection.GivingAndMisc, givingAndMiscExpenses)}
                   />
                 }
        >
          <DragDropContext onDragEnd={handleExpenseDragEnd}>
            <DraggableList droppableId="givingAndMiscExpenses" data={givingAndMiscExpenses} renderWrapper={renderListWrapper}
                           renderItem={renderExpense}
            />
          </DragDropContext>
        </Section>
        <Typography variant="body2">* Discretionary expense</Typography>
      </Box>

      <Box sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        width: rightPanelWidth,
        maxWidth: rightPanelWidth,
        minHeight: "100%",
        // maxHeight: "calc(100vh - 84px)",
        // overflowY:"scroll"
      }}>
        <Box sx={{display:"flex", flexGrow:1, flexDirection:"row", justifyContent:"stretch", paddingLeft: 2, paddingRight: 0.5, maxHeight: 50}}>
          <Box sx={{display:"flex", flexGrow:2, flexDirection:"column", minWidth:250}}>
            <Box sx={{display:"flex", flexGrow:1, justifyContent:"space-between", maxHeight:16}}>
              <FormLabel sx={styles.formLabel}>View</FormLabel>
            </Box>
            <Box sx={{display:"flex", flexGrow:1, flexWrap:"nowrap", marginBottom:"4px"}}>
              {renderViewMenu()}
            </Box>
          </Box>
        </Box>
        <Box sx={{display:"flex", flexGrow:1, paddingLeft:2, paddingRight:0, paddingTop:"14px"}}>
          {renderSlider()}
        </Box>

        <Box display="flex" flexDirection="column" pl={2} mb={2}>
          {currentBudgetCalc &&
            <React.Fragment>
              {chartView === BudgetChartView.AnnualBudget && currentYearCalc &&
                <AnnualBudgetApexChart futureYear={currentYearCalc} budgetCalc={currentBudgetCalc} view={netIncomeView === NetIncomeView.MonthlyAvg ? "MonthlyAvg" : "Annual"}/>
              }
              {chartView === BudgetChartView.MonthBudget && currentYearCalc &&
                <MonthBudgetApexChart futureYear={currentYearCalc} budgetCalc={currentBudgetCalc} month={currentMonth ?? 0}  onChange={handleChangeMonth}/>
              }
              {chartView === BudgetChartView.MonthlyBudget &&
                <MonthlyBudgetApexChart budgetCalc={currentBudgetCalc} onChange={handleChangeMonth}/>
              }
            </React.Fragment>
          }
        </Box>
      </Box>
    </Box>
  )
}

export default BudgetView