import {isoToLocalDate} from "../stores/StoreUtilities";
import INetWorth from "./INetWorth";
import {compareAsc, isToday} from "date-fns";
import Asset from "./Asset";
import Liability from "./Liability";
import IModelItem from "./IModelItem";

export type SnapshotDetailTypename = "Asset" | "Liability" | ""

export interface ISnapshotDetail {
  id: string
  typename: SnapshotDetailTypename
  balance: number
}

export class SnapshotDetail implements ISnapshotDetail {
  id: string
  typename: SnapshotDetailTypename
  balance: number

  constructor (data: ISnapshotDetail) {
    this.id = data.id
    this.typename = data.typename
    this.balance = data.balance ?? 0
  }
}

class Snapshot implements IModelItem {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  userId: string
  modelId: string
  date: Date
  description: string
  details: ISnapshotDetail[]

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.userId = data.userId
    this.modelId = data.modelId
    this.date = isoToLocalDate(data.date)
    this.description = data.description
    this.details = []
    if (data.details) {
      this.loadSnapshotDetails(data.details)
    }
  }

  loadSnapshotDetails(data: any) {
    if (typeof data === "string") {
      const details = JSON.parse(data)
      if (details.length) {
        this.details = details.map((detail: ISnapshotDetail) => new SnapshotDetail(detail))
      }
    } else {
      this.details = data
    }
  }

  getSnapshotDetail(item: INetWorth, filterByStartDate: boolean = false): ISnapshotDetail | undefined {
    let detail: ISnapshotDetail | undefined
    const typename = item instanceof Asset ? "Asset" : item instanceof Liability ? "Liability" : ""
    if (!filterByStartDate) {
      detail = this.details.find((detail: ISnapshotDetail) => detail.id === item.id && detail.typename === typename)
    } else {
      // Only return snapshot details that are on or after the item start date
      const startDate = item.startDate
      if (startDate && compareAsc(startDate, this.date) <= 0) {
        detail = this.details.find((detail: ISnapshotDetail) => detail.id === item.id && detail.typename === typename)
      }
    }

    return detail
  }

  getSnapshotBalance(id: string, typename: SnapshotDetailTypename) {
    const detail = this.details.find((detail: ISnapshotDetail) => detail.id === id && detail.typename === typename)
    return detail ? detail.balance : 0
  }

  get isCurrent(): boolean {
    return (this.id === "current" || isToday(this.date))
  }

}

export default Snapshot