import { Box, Divider, IconButton, Menu, Stack } from '@mui/material'
import { observer } from "mobx-react"
import * as React from 'react'
import { INavItem, NavigationConfigHelpers } from '../../NavigationConfig'
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import NavMenuItem from './NavMenuItem'
import NavSelect from './NavSelect'
import NavButton from './NavButton'
import NavExpandableMenu from './NavExpandableMenu'
import { useResources } from '../../stores/ResourceProvider'

const SimpleMenu = observer(() => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const navigate = useNavigate() 
  const location = useLocation() 

  const renderDesktopNav = (items: INavItem[]) => {
    // Render as links or buttons, with dropdown select menus for any sub-menus. 
    return (
      <Stack alignItems="center" direction="row">
        {items.map(item => {
          if (item.subNavItems) {
            return (
              <Box key={item.route.title}>
                <NavSelect 
                  item={item} 
                  onClickSubNavItem={(e: React.MouseEvent, subMenuItem: INavItem) => {
                    const route = subMenuItem.route
                    navigate(route.pathname ?? route.href!) 
                  }} 
                />
              </Box>
            )
          } else {
            return (
              <Box key={item.route.title}>
                <NavButton 
                  navItem={item} 
                  onClickButton={(e: React.MouseEvent) => {
                    const route = item.route
                    navigate(route.pathname ?? route.href!) 
                  }} 
                />
              </Box>
            )
          }
        })}
      </Stack>
    )
  }

  const renderMobileNav = (items: INavItem[]) => {
    // Render as "hamburger" menu, all in one menu popover. 
    return (
      <React.Fragment>
        <IconButton
          color="inherit"
          aria-label="Menu"
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MenuIcon color="primary" />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          PaperProps={{
            sx: { width: '100%' }
          }}
        >
          {items.map((item, index) => {
            if (item.subNavItems) {
              return (
                <Box key={item.route.title}>
                  {index > 0 && <Divider variant="middle" />}
                  <NavExpandableMenu 
                    item={item} 
                    onClickSubNavItem={(e: React.MouseEvent, subNavItem: INavItem) => {
                      handleCloseMenu() 
                      const route = subNavItem.route
                      navigate(route.pathname ?? route.href!)
                    }}
                  /> 
                </Box>
              )
            } else {
              return (
                <Box key={item.route.title}>
                  {index > 0 && <Divider variant="middle" />}
                  <NavMenuItem 
                    navItem={item} 
                    onClickMenuItem={(e: React.MouseEvent, item: INavItem) => {
                      handleCloseMenu() 
                      const route = item.route
                      navigate(route.pathname ?? route.href!)
                    }} 
                  />
                </Box>
              )
            }
          })}
        </Menu>
      </React.Fragment>
    )
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  
  const { userStore } = useResources()
  const user = userStore!.user 

  // TODO: Cache this list of nav items until users logs in or signs out, or role changes? 
  const navItems = NavigationConfigHelpers.getNavItems(userStore!.isAuthenticated, user ? user.role : undefined, location.pathname)

  return (
    <Box>
      {/* Desktop Only */}
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        {renderDesktopNav(navItems)}
      </Box>

      {/* Mobile Only */}
      <Box sx={{ display: { xs: 'flex', md: 'none'}}}>
        {renderMobileNav(navItems)}
      </Box>
    </Box>
  )
})

export default SimpleMenu
