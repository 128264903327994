import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseButton from "../controls/CloseButton";
import Tracking from "../Tracking";
import {Box, useTheme} from "@mui/material";
import ReactPlayer from "react-player";

const VideoPlayer = ({
  open,
  url,
  onClose
} : {
  open?: boolean
  url: string
  onClose(event?: any): void
}) => {
  const theme = useTheme()

  const styles = {
    content: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      margin: "auto"
    },
    iframe: {
      boxShadow: "none",
      border: "none",
      width: 852,  // 560, //"100%",
      height: 479, // 315, //"100%",
      zIndex: 9999
    },
  }

   const handleClose = () => {
    onClose()
  };

  return (
    <Dialog
      id="video-dialog"
      open={open === true}
      onClose={handleClose}
      scroll="paper"
      maxWidth="md"
      fullWidth
      aria-labelledby="video-dialog-title">
      <DialogContent
        sx={{backgroundColor: theme.palette.background.default}}
      >
        <Box sx={styles.content}>
          {/*<iframe src={url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen style={styles.iframe}>*/}
          {/*</iframe>*/}
          <ReactPlayer
            url={url}
            // ref={this.ref}
            controls={true}
            playsinline={true}
            width={852}
            height={479}
          />
        </Box>
      </DialogContent>
      <CloseButton onClick={() => {
        Tracking.event({action: "Video Closed"})
        onClose()
      }}
      />
    </Dialog>
  )

}

export default VideoPlayer