import Person from "./Person";
import Income from "./Income";
import Expense from "./Expense";
import Deduction from "./Deduction";
import Tax from "./Tax";
import Asset, {AssetCategory, AssetType} from "./Asset";
import Liability from "./Liability";
import Plan from "./Plan";
import Snapshot from "./Snapshot";
import TaxValue from "./TaxValue";
import {ClientAccess, Gender, MaritalStatus, UpdateModelInput} from "../API";
import {getISODateToday, isoToLocalDate} from "../stores/StoreUtilities";
import GrowthStrategy from "./GrowthStrategy";
import Milestone, {MilestoneId} from "./Milestone";
import {compareAsc, compareDesc, differenceInYears} from "date-fns";
import {PlanChangeType} from "./PlanChange";
import TimelineStrategyChange from "./changes/TimelineStrategyChange";
import AssetConversion from "./AssetConversion";
import {InflationStrategy} from "./changes/InflationStrategyChange";

export enum ModelSetting {
  BucketsStartYear = "Buckets.startYear",
  InflationRate = "Inflation.rate",
  UserPlanId = "User.planId",
  AdvisorPlanId = "Advisor.planId"
}

class Model {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  userId: string
  name: string
  description: string
  isPrimary: boolean
  startedAt: string
  duration: number  // TODO: Needed?
  advisorCreated: boolean
  clientAccess: ClientAccess
  settings: any
  persons: Person[]
  milestones: Map<string, Milestone>
  plans: Plan[]
  assets: Asset[]
  liabilities: Liability[]
  incomes: Income[]
  deductions: Deduction[]
  taxes: Tax[]
  expenses: Expense[]
  snapshots: Snapshot[]
  taxValues: TaxValue[]
  assetConversions: AssetConversion[]
  locks: Set<string>
  growthStrategy: GrowthStrategy
  inflationStrategy: InflationStrategy
  appliedPlan?: Plan

  static defaultInflationRate = 0.03
  static defaultGrowthRate = 0.04

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.userId = data.userId
    this.name = data.name
    this.description = data.description
    this.isPrimary = data.isPrimary
    this.startedAt = data.startedAt
    this.duration = data.duration
    this.advisorCreated = data.advisorCreated === true
    this.clientAccess = data.clientAccess ?? ClientAccess.Owner
    if (typeof data.settings === "string") {
      this.settings = JSON.parse(data.settings) ?? {}
    } else {
      this.settings = {}
    }
    this.persons = []
    if (data.persons && data.persons.items) {
      this.loadPersons(data.persons.items)
    }
    this.milestones = new Map<string, Milestone>()
    this.loadMilestones()
    this.plans = []
    if (data.plans && data.plans.items) {
      this.loadPlans(data.plans.items)
    }
    this.assets = []
    if (data.assets && data.assets.items) {
      this.loadAssets(data.assets.items)
    }
    this.assetConversions = []
    if (data.assetConversions && data.assetConversions.items) {
      this.loadAssetConversions(data.assetConversions.items)
    }
    this.liabilities = []
    if (data.liabilities && data.liabilities.items) {
      this.loadLiabilities(data.liabilities.items)
    }
    this.incomes = []
    if (data.incomes && data.incomes.items) {
      this.loadIncomes(data.incomes.items)
    }
    this.deductions = []
    if (data.deductions && data.deductions.items) {
      this.loadDeductions(data.deductions.items)
    }
    this.taxes = []
    if (data.taxes && data.taxes.items) {
      this.loadTaxes(data.taxes.items)
    }
    this.expenses = []
    if (data.expenses && data.expenses.items) {
      this.loadExpenses(data.expenses.items)
    }
    this.snapshots = []
    if (data.snapshots) {
      this.loadSnapshots(data.snapshots.items)
    }
    this.taxValues = []
    if (data.taxValues) {
      this.loadTaxValues(data.taxValues.items)
    }
    this.locks = new Set<string>([])

    // GrowthStrategy is not currently stored in the Model table
    this.growthStrategy = new GrowthStrategy({})
    this.inflationStrategy = {
      deduction: { rate: Model.defaultInflationRate, lock: false },
      expense: { rate: Model.defaultInflationRate, lock: false },
      income: { rate: Model.defaultInflationRate, lock: false },
      tax: { rate: 0, lock: false }
    }
    // this.growthStrategy.inflationRate = this.getSetting(ModelSetting.InflationRate, this.growthStrategy.inflationRate)
  }

  update(input: UpdateModelInput, data: any) {
    if (data.updatedAt) {
      this.updatedAt = data.updatedAt
    }
    if (input.name !== undefined) {
      this.name = data.name
    }
    if (input.description !== undefined) {
      this.description = data.description
    }
    if (input.advisorCreated !== undefined) {
      this.advisorCreated = data.advisorCreated
    }
    if (input.clientAccess !== undefined) {
      this.clientAccess = data.clientAccess
    }
    if (input.settings !== undefined) {
      this.settings = JSON.parse(data.settings) ?? {}
    }
  }

  loadPersons(items: any[]) {
    this.persons = []
    Model.sortPersons(items as Person[])
    items.forEach((item: any, index: number) => {
      const person = new Person(item)
      // if (person.nickname || person.birthDate || person.retireDate) {
      //   this.persons.push(person)
      // }
      if (!person.nickname) {
        person.nickname = `Person ${index+1}`
      }
      this.persons.push(person)
    })

    if (this.persons.length > 1) {
      Model.sortPersons(this.persons)
      this.persons[0].spouse = this.persons[1]
      this.persons[1].spouse = this.persons[0]
      this.persons.push(this.createJointPerson())
    }
  }

  static sortPersons(items: Person[]) {
    items.sort((a: Person, b: Person) => {
        return (a.createdAt.localeCompare(b.createdAt))
    })
  }

  createJointPerson() {
    const createdAt = "2999-12-31"  // Make sure it is the end
    const earliestBirthDate = (this.persons[0].birthDate <= this.persons[1].birthDate) ? this.persons[0].birthDate : this.persons[1].birthDate
    const latestRetireDate = (this.persons[0].retireDate >= this.persons[1].retireDate) ? this.persons[0].retireDate : this.persons[1].retireDate
    const latestLifeExpectancyDate = this.persons[0].latestLifeExpectancyDate
    let lifeExpectancy
    if (earliestBirthDate && latestLifeExpectancyDate) {
      lifeExpectancy = differenceInYears(isoToLocalDate(latestLifeExpectancyDate), isoToLocalDate(earliestBirthDate))
    } else {
      lifeExpectancy = this.persons[0].lifeExpectancy
    }

    const joint = new Person({
      id: this.jointId,
      createdAt: createdAt,
      updatedAt: createdAt,
      accountId: this.accountId,
      modelId: this.id,
      num: 3,
      nickname: Model.jointNickname,
      gender: Gender.Neutral,
      maritalStatus: MaritalStatus.Married,
      birthDate: earliestBirthDate,
      hereditaryAdjust: 0,
      retireDate: latestRetireDate,
      lifeExpectancy: lifeExpectancy,
      state: this.persons[1].state
    })
    return joint
  }

  updateJointPerson() {
    if (this.persons.length === 3) {
      const joint = this.persons[2]
      const updated = this.createJointPerson()
      joint.birthDate = updated.birthDate
      joint.hereditaryAdjust = updated.hereditaryAdjust
      joint.retireDate = updated.retireDate
      joint.lifeExpectancy = updated.lifeExpectancy
    }
  }

  getPerson(id: string): Person | undefined {
    return this.persons.find((p: Person) => p.id === id)
  }

  get person1(): Person | undefined {
    if (this.persons.length > 0) {
      return this.persons[0]
    } else {
      return undefined
    }
  }

  get person2(): Person | undefined {
    if (this.persons.length > 1) {
      return this.persons[1]
    } else {
      return undefined
    }
  }

  getExpectedMaritalStatus(year: number) {
      if (this.persons.length > 1 &&
        this.persons[0].lifeExpectancyYear >= year &&
        this.persons[1].lifeExpectancyYear >= year) {
        return MaritalStatus.Married
    } else {
      return MaritalStatus.Single
    }
  }

  getOwner(id: string): Person | undefined {
    let owner
    if (!id || id === this.jointId) {
      owner = this.persons.find((p: Person) => p.id === this.jointId)
    } else {
      owner = this.persons.find((p: Person) => p.id === id)
    }
    if (!owner && this.persons.length > 0) {
      owner = this.persons[0]
    }
    return owner
  }

  loadMilestones() {
    if (this.persons.length > 0) {
      const person = this.persons[0]
      if (person.retireDate) {
        this.milestones.set(MilestoneId.retirement1, new Milestone({
          id: MilestoneId.retirement1,
          label: `${person.nickname}'s Retirement`,
          date: person.retireDate
        }))
      }
      if (person.lifeExpectancy > 0) {
        this.milestones.set(MilestoneId.lifeExpectancy1, new Milestone({
          id: MilestoneId.lifeExpectancy1,
          label: `${person.nickname}'s Life Expectancy`,
          date: person.lifeExpectancyDate,
          age: person.lifeExpectancy
        }))
      }
    }
    if (this.persons.length > 1) {
      const person = this.persons[1]
      if (person.retireDate) {
        this.milestones.set(MilestoneId.retirement2, new Milestone({
          id: MilestoneId.retirement2,
          label: `${person.nickname}'s Retirement`,
          date: person.retireDate
        }))
      }
      if (person.lifeExpectancy > 0) {
        this.milestones.set(MilestoneId.lifeExpectancy2, new Milestone({
          id: MilestoneId.lifeExpectancy2,
          label: `${person.nickname}'s Life Expectancy`,
          date: person.lifeExpectancyDate,
          age: person.lifeExpectancy
        }))
      }
    }

    if (this.persons.length > 0) {
      // Setup other milestones
      const person = this.persons[0]
      this.milestones.set(MilestoneId.earliestRetirement, new Milestone({
        id: MilestoneId.earliestRetirement,
        label: `Earliest Retirement`,
        date: person.earliestRetireDate
      }))
      this.milestones.set(MilestoneId.latestRetirement, new Milestone({
        id: MilestoneId.latestRetirement,
        label: `Latest Retirement`,
        date: person.latestRetireDate
      }))
      this.milestones.set(MilestoneId.earliestLifeExpectancy, new Milestone({
        id: MilestoneId.earliestLifeExpectancy,
        label: `Earliest Life Expectancy`,
        date: person.earliestLifeExpectancyDate
      }))
      this.milestones.set(MilestoneId.latestLifeExpectancy, new Milestone({
        id: MilestoneId.latestLifeExpectancy,
        label: `Latest Life Expectancy`,
        date: person.latestLifeExpectancyDate
      }))

    }
  }

  findMilestone(id?: string) {
    if (id) {
      return this.milestones.get(id)
    } else {
      return undefined
    }
  }

  // For future use (WIP)
  // getMilestoneLabel = (id?: MilestoneId, defaultId?: MilestoneId) => {
  //   let milestone: Milestone | undefined
  //   if (id) {
  //     milestone = this.milestones.get(id)
  //   }
  //   if (!milestone && defaultId) {
  //     milestone = this.milestones.get(defaultId)
  //   }
  //   if (milestone) {
  //     return milestone.label
  //   } else {
  //     return id ?? defaultId ?? ""
  //   }
  // }

  getDate(value?: string): Date | undefined {
    let date: Date | undefined
    if (value) {
      const first = value.charAt(0)
      if (first >= '0' && first <= '9') {
        date = isoToLocalDate(value)
      } else {
        // check for Milestone
        const milestone = this.milestones.get(value)
        if (milestone) {
          date = milestone.date
        }
      }
    }
    return date
  }

  getPlanEndDate(plan: Plan): Date | undefined {
    let date: Date | undefined
    const milestone = this.milestones.get(MilestoneId.latestLifeExpectancy)
    if (milestone) {
      date = milestone.date

      // Search plan for override
      const timelineChange = plan.getChange(PlanChangeType.TimelineStrategy) as TimelineStrategyChange
      if (timelineChange && timelineChange.enabled) {
        let l1 = timelineChange.milestones.get(MilestoneId.lifeExpectancy1)
        if (!l1) {
          l1 = this.milestones.get(MilestoneId.lifeExpectancy1)
        }
        let l2 = timelineChange.milestones.get(MilestoneId.lifeExpectancy2)
        if (!l2) {
          l2 = this.milestones.get(MilestoneId.lifeExpectancy2)
        }

        if (l1 && l2) {
          date = compareAsc(l1.date, l2.date) >= 0 ? l1.date : l2.date
        } else if (l1) {
          date = l1.date
        }
      }
    }

    return date
  }

  loadPlans(items: any[]) {
    this.plans = items.map((item: any) => new Plan(item))
    Model.sortPlans(this.plans)
  }

  static sortPlans(plans: Plan[]) {
    plans.sort((a: Plan, b: Plan) => a.name.localeCompare(b.name))
  }

  findPlan(planId: string) {
    return this.plans.find((p: Plan) => p.id === planId)
  }

  findPlanIndex(planId: string) {
    return this.plans.findIndex((p: Plan) => p.id === planId)
  }

  loadAssets(items: any[]) {
    this.assets = items.map((item: any) => {
      const asset = new Asset(item)
      asset.model = this
      if (asset.ownerId) {
        asset.owner = this.getOwner(asset.ownerId)
      }
      return asset
    })
    Model.sortAssets(this.assets)
  }

  static sortAssets(items: Asset[]) {
    items.sort((a: Asset, b: Asset) => {
      if (a.assetCategory === b.assetCategory) {
        if (a.sortOrder === b.sortOrder) {
          return (a.description.localeCompare(b.description))
        } else {
          return (a.sortOrder - b.sortOrder)
        }
      } else {
        return (a.assetCategory - b.assetCategory)
      }
    })
  }

  loadAssetConversions(items: any[]) {
    this.assetConversions = items.map((item: any) => {
      const assetConversion = new AssetConversion(item)
      assetConversion.model = this
      if (assetConversion.srcAssetId) {
        assetConversion.srcAsset = this.getAssetById(assetConversion.srcAssetId)
      }
      if (assetConversion.dstAssetId) {
        assetConversion.dstAsset = this.getAssetById(assetConversion.dstAssetId)
      }
      return assetConversion
    })
    Model.sortAssetConversions(this.assetConversions)
  }

  static sortAssetConversions(items: AssetConversion[]) {
    items.sort((a: AssetConversion, b: AssetConversion) => {
      if (a.sortOrder === b.sortOrder) {
        return (a.description.localeCompare(b.description))
      } else {
        return (a.sortOrder - b.sortOrder)
      }
    })
  }

  loadLiabilities(items: any[]) {
    this.liabilities = items.map((item: any) => {
      const liability = new Liability(item)
      liability.model = this
      if (liability.ownerId) {
        liability.owner = this.getOwner(liability.ownerId)
      }
      return liability
    })
    Model.sortLiabilities(this.liabilities)
  }

  static sortLiabilities(items: Liability[]) {
    items.sort((a: Liability, b: Liability) => {
      if (a.sortOrder === b.sortOrder) {
        return (a.description.localeCompare(b.description))
      } else {
        return (a.sortOrder - b.sortOrder)
      }
    })
  }
  
  loadIncomes(items: any[]) {
    this.incomes = items.map((item: any) => {
      const income = new Income(item)
      income.model = this
      income.owner = this.getOwner(income.ownerId)
      return income
    })
    Model.sortIncomes(this.incomes)
  }

  static sortIncomes(items: Income[]) {
    items.sort((a: Income, b: Income) => {
      if (a.sortOrder === b.sortOrder) {
        return (a.description.localeCompare(b.description))
      } else {
        return (a.sortOrder - b.sortOrder)
      }
    })
  }

  loadDeductions(items: any[]) {
    let noneAsset: Asset
    this.deductions = items.map((item: any) => {
      const deduction = new Deduction(item)
      deduction.model = this
      if (deduction.assetId) {
        deduction.asset = this.assets.find((a: Asset) => a.id === deduction.assetId)
      }
      if (!deduction.asset) {
        if (!noneAsset) {
          noneAsset = new Asset({
            id: "none",
            assetCategory: AssetCategory.LiquidInvestableAssets,
            assetType: AssetType.None,
            description: "None",
            balance: 0,
            balanceDate: getISODateToday(),
            ownerId: this.jointId
          })
          noneAsset.owner = this.getOwner(noneAsset.ownerId)
          // this.assets.push(noneAsse)
        }
        deduction.assetId = noneAsset.id
        deduction.asset = noneAsset
      }
      return deduction
    })
    Model.sortDeductions(this.deductions)
  }

  static sortDeductions(items: Deduction[]) {
    items.sort((a: Deduction, b: Deduction) => {
      if (a.sortOrder === b.sortOrder) {
        return (a.description.localeCompare(b.description))
      } else {
        return (a.sortOrder - b.sortOrder)
      }
    })
  }

  loadTaxes(items: any[]) {
    this.taxes = items.map((item: any) => {
      const tax = new Tax(item)
      tax.model = this
      tax.owner = this.getOwner(tax.ownerId)
      return tax
    })
    Model.sortTaxes(this.taxes)
  }

  static sortTaxes(items: Tax[]) {
    items.sort((a: Tax, b: Tax) => {
      if (a.sortOrder === b.sortOrder) {
        return (a.description.localeCompare(b.description))
      } else {
        return (a.sortOrder - b.sortOrder)
      }
    })
  }

  loadExpenses(items: any[]) {
    let noneAsset: Asset
    this.expenses = items.map((item: any) => {
      const expense = new Expense(item)
      expense.model = this
      if (expense.assetId) {
        expense.asset = this.assets.find((a: Asset) => a.id === expense.assetId)
      }
      if (expense.liabilityId) {
        expense.liability = this.liabilities.find((l: Liability) => l.id === expense.liabilityId)
      }
      expense.owner = this.getOwner(expense.ownerId)
      return expense
    })
    Model.sortExpenses(this.expenses)
  }

  static sortExpenses(items: Expense[]) {
    items.sort((a: Expense, b: Expense) => {
      if (a.expenseCategory === b.expenseCategory) {
        if (a.sortOrder === b.sortOrder) {
          return (a.description.localeCompare(b.description))
        } else {
          return (a.sortOrder - b.sortOrder)
        }
      } else {
        return (a.expenseCategory - b.expenseCategory)
      }
    })
  }


  loadSnapshots(items: any[]) {
    this.snapshots = items.map((item: any) => new Snapshot(item))
    // Make sure all assets and liabilities have the correct latest balance
    if (this.snapshots.length >= 1) {
    //   const snapshot = this.snapshots[this.snapshots.length-1]
    //   snapshot.details.forEach((detail: ISnapshotDetail) => {
    //     let nwItem: INetWorth | undefined = this.getAssetById(detail.id)
    //     if (!nwItem) {
    //       nwItem = this.getLiabilityById(detail.id)
    //     }
    //     if (nwItem) {
    //       if (compareAsc(nwItem.balanceDate, snapshot.date) !== 0) {
    //         console.debug(`Fixing ${nwItem.description} balanceDate`)
    //         nwItem.balanceDate = snapshot.date
    //       }
    //       if (nwItem.balance !== detail.balance) {
    //         console.debug(`Fixing ${nwItem.description} balance`)
    //         nwItem.balance = detail.balance
    //       }
    //     }
    //   })
      Model.sortSnapshots(this.snapshots)
    }
  }

  static sortSnapshots(snapshots: Snapshot[]) {
    // Sort in descending order (latest first)
    snapshots.sort((a: Snapshot, b: Snapshot) => compareDesc(a.date, b.date))
  }

  loadTaxValues(items: any[]) {
    this.taxValues = items.map((item: any) => new TaxValue(item))
    Model.sortTaxValues(this.taxValues)
  }

  static sortTaxValues(values: TaxValue[]) {
    // Sort by year
    values.sort((a: TaxValue, b: TaxValue) => compareAsc(a.year, b.year))
  }


  get isLoaded(): boolean {
    return this.persons.length > 0
  }

  get isTimelineComplete(): boolean {
    if (this.persons.length > 0) {
      if (!this.persons[0].isComplete) {
        return false
      }
      if (this.persons.length > 1) {
        if (!this.persons[1].isComplete) {
          return false
        }
      }
      return true
    }
    return false
  }

  get jointId(): string {
    return `${this.id}-joint`
  }

  static jointNickname = "Joint"

  getPersonNicknames(allowJoint: boolean = true) {
    const nicknames = this.persons.map((p: Person) => p.nickname)
    if (allowJoint && nicknames.length === 2) {
      nicknames.push(Model.jointNickname)
    } else if (!allowJoint && nicknames.length === 3) {
      return nicknames.slice(0, 2)
    } else {
      return nicknames
    }
  }

  getPersonByNickname(nickname?: string) {
    return this.persons.find((p: Person) => p.nickname === nickname)
  }

  getDefaultPersonNickname(allowJoint: boolean = true) {
    if (this.persons.length === 1) {
      return this.persons[0].nickname
    } else if (allowJoint && this.persons.length > 1) {
      return Model.jointNickname
    } else {
      return undefined
    }
  }

  getLatestLifeExpectancyYear() : number {
    if (this.person1) {
      return this.person1.latestLifeExpectancyYear
    } else {
      return (new Date()).getFullYear()
    }
  }

  getAssetsByCategory(category: AssetCategory) {
    const assets = this.assets.filter((asset: Asset) => asset.assetCategory === category)
    assets.sort((a: Asset, b: Asset) => a.description.localeCompare(b.description))
    return assets
  }

  getAssetById(assetId?: string): Asset | undefined {
    let asset
    if (assetId) {
      asset = this.assets.find((a: Asset) => a.id === assetId)
    }
    return asset
  }

  getLiabilityById(liabilityId?: string): Liability | undefined {
    let liability
    if (liabilityId) {
      liability = this.liabilities.find((a: Liability) => a.id === liabilityId)
    }
    return liability
  }

  getAssetByDescription(description: string) {
    let asset = this.assets.find((a: Asset) => a.description === description)
    return asset
  }

  hasLock(id: string, scope: string): boolean {
    return this.locks.has(`${id}.${scope}`)
  }

  addLock(id: string, scope: string) {
    this.locks.add(`${id}.${scope}`)
  }

  deleteLock(id: string, scope: string) {
    this.locks.delete(`${id}.${scope}`)
  }

  // Settings
  getSetting(setting: ModelSetting, defaultValue?: any) {
    return (this.settings[setting] ?? defaultValue)
  }

  setSetting(setting: ModelSetting, value: any) {
    this.settings[setting] = value
  }

  getDefaultGrowthRate() {
    if (this.growthStrategy && this.growthStrategy.growthRate) {
      return this.growthStrategy.growthRate
    } else {
      return Model.defaultGrowthRate
    }
  }

  getOverrideGrowthLock() {
    if (this.growthStrategy && this.growthStrategy.ignoreCustomGrowthRates) {
      return true
    } else {
      return false
    }
  }

  getDefaultInflationRate(typename: string) {
    if (this.inflationStrategy[typename]) {
      return this.inflationStrategy[typename].rate
    } else {
      return Model.defaultInflationRate
    }
  }

  getOverrideInflationLock(typename: string) {
    if (this.inflationStrategy[typename]) {
      return this.inflationStrategy[typename].lock
    } else {
      return false
    }
  }
}



export default Model