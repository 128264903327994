import Page from "../../components/page/Page";
import {Box, Divider, List, Typography, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import User from "../../model/User";
import {useResources} from "../../stores/ResourceProvider";
import MarginRow from "../../components/page/MarginRow";
import UserCard from "./UserCard";
import SecurityCard from "./SecurityCard";
import Footer from "../../components/page/Footer";
import SubscriptionCard from "./SubscriptionCard";
import Drawer, {openDrawerWidth} from "../../components/mui/Drawer";
import NavListItem from "../../components/controls/NavListItem";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const SettingsPage = () => {
  const [user, setUser] = useState<User | undefined>()

  const theme = useTheme()
  const { userStore } = useResources()

  useEffect(() => {
    if (!userStore.isLoading && userStore.user) {
      setUser(userStore.user)
    }
  }, [userStore.isLoading, userStore.user])

  const openDrawerWidth = 300;

  const renderDrawer = () => {
    const drawerLabel = "Account Settings"

    return (
      <Drawer variant="permanent" open={true}
              PaperProps={{
                sx: {
                  backgroundColor: theme.palette.background.default,
                  marginTop: "70px",
                  paddingLeft: theme.spacing(2),
                  borderStyle:"none",
                  width: openDrawerWidth
                }
              }}
      >
        <Divider />
        <Box sx={{display:"flex", mt:3.5, pl:1, pb:0, mb:1,}}>
          <Typography variant="h5" color="primary">{drawerLabel}</Typography>
        </Box>
        {user &&
          <UserCard user={user}
                    onSave={(user: User) => {
                      setUser(user)
                    }}
          />
        }
        {user &&
          <SecurityCard user={user}
                        onSave={(user: User) => {
                          setUser(user)
                        }}
          />
        }
        {user &&
          <SubscriptionCard user={user}
                            onSave={(user: User) => {
                              setUser(user)
                            }}
          />
        }
      </Drawer>
    )
  }

  return (
    <Page title="Settings" backgroundColor="default">
      <MarginRow>
        {renderDrawer()}
        <Box component="main" sx={{ flexGrow:1, ml:`${openDrawerWidth}px`, minHeight:`calc(100vh - 206px)`}}>
        </Box>
        <Box ml={`${openDrawerWidth}px`}>
          <Footer />
        </Box>
      </MarginRow>
    </Page>
  )

}

export default SettingsPage