import {useEffect, useState} from "react";
import {useResources} from "../stores/ResourceProvider";
import {UserType} from "../API";
import Page from "../components/page/Page";
import MarginRow from "../components/page/MarginRow";
import {Box, useTheme} from "@mui/material";
import PremiumPreview, {PremiumFeature} from "../components/page/PremiumPreview";


const CommunityPage = () => {
  const [hasAccess, setHasAccess] = useState<boolean>(false)

  const { userStore } = useResources()

  const theme = useTheme()
  const styles = {
    content: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      margin: "auto"
    },
    iframe: {
      boxShadow: "none",
      border: "none",
      width: "100%",
      // maxWidth: theme.breakpoints.values.xl,
      height: "100%"
    },
    preview: {
      display: "flex",
      width: "100%",
      height: "100%",
      justifyContent: "space-around",
      alignItems: "center"
    }
  }

  useEffect(() => {
    if (userStore.isAuthenticated && userStore.user) {
      setHasAccess(userStore.user.userType !== UserType.Free)
    }
  }, [userStore.isAuthenticated, userStore.user])


  return (
    <Page title="Community">
      {hasAccess &&
        <Box sx={styles.content}>
          <iframe src="https://retirementbudgetcalculator.circle.so/join?invitation_token=e94695d153cbe86b3c873012f2789c4ff31eed6d-b942861c-c920-4844-8f1e-07acad6ff147&iframe=true" style={styles.iframe}>
          </iframe>
        </Box>
      }
      {!hasAccess &&
        <Box sx={styles.preview}>
          <PremiumPreview feature={PremiumFeature.Community}/>
        </Box>
      }
    </Page>
  )
}

export default CommunityPage