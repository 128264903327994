import * as React from 'react';
import {useEffect, useState} from 'react';
import Expense, {ExpenseCategory} from "../../../model/Expense";
import {FormLabel, Grid, Typography, useTheme} from "@mui/material";
import {
  dateToLocalFormat,
  humanizeString,
  numberToMoneyFormat,
  numberToPercentFormat
} from "../../../stores/StoreUtilities";
import Model from "../../../model/Model";
import {
  DiscretionaryExpenseIcon,
  FoodAndPersonalExpenseIcon,
  GivingAndMiscExpenseIcon,
  HousingExpenseIcon,
  InsuranceAndMedicalExpenseIcon,
  LoanExpenseIcon,
  TravelAndEntertainmentExpenseIcon,
  VehiclesAndTransporationExpenseIcon
} from "../../../styles/Icons";
import ModelCard2 from "../../../components/model/ModelCard2";
import {globalColors} from "../../../styles/globalStyles";
import ExpenseEditDialog from "./ExpenseEditDialog";
import {isNumber} from "../../../model/ModelUtilities";
import Asset from "../../../model/Asset";
import Liability from "../../../model/Liability";

const ExpenseCard = ({
  expense,
  model,
  projected,
  expanded,
  onChange,
  onSave,
  onDelete
}: {
  expense: Expense
  model: Model
  projected?: number
  expanded?: boolean
  onChange?(event: any, expanded: boolean): void
  onSave?(expense: Expense): void
  onDelete?(deleted: Expense): void
}) => {
  const [expenseModel, setExpenseModel] = useState<Expense>(expense)
  const [description, setDescription] = useState<string>("")
  const [amount, setAmount] = useState<string | undefined>()
  const [amountLock, setAmountLock] = useState<boolean>(false)
  const [projectedAmount, setProjectedAmount] = useState<string | undefined>()
  const [expenseCategory, setExpenseCategory] = useState<string | undefined>()
  const [owner, setOwner] = useState<string | undefined>()
  const [schedule, setSchedule] = useState<string | undefined>()
  const [startDate, setStartDate] = useState<string | undefined>()
  const [endDate, setEndDate] = useState<string | undefined>()
  const [annualInf, setAnnualInf] = useState<string | undefined>()
  const [annualInfLock, setAnnualInfLock] = useState<boolean>(false)
  const [discretionary, setDiscretionary] = useState<boolean | undefined>()
  const [asset, setAsset] = useState<Asset | undefined>()
  const [liability, setLiability] = useState<Liability | undefined>()
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)

  useEffect(() => {
    init(expense)
  }, [expense, model, projected])

  const init = (expense: Expense) => {
    setExpenseModel(expense)
    setDescription(`${expense.description}${expense.discretionary ? '*' : ''}`)
    setAmount(expense.amount ? numberToMoneyFormat(expense.amount, 0) : "$0")
    setAmountLock(model.hasLock(expense.id, "amount"))
    setProjectedAmount(projected ? numberToMoneyFormat(projected, 0) : "$0")
    setExpenseCategory(expense.expenseCategory ? humanizeString(ExpenseCategory[expense.expenseCategory]) : undefined)
    setOwner(model.getPerson(expense.ownerId)?.nickname ?? model.getDefaultPersonNickname())
    setSchedule(expense.schedule.name)
    setStartDate(dateToLocalFormat(expense.startDate))
    setEndDate(dateToLocalFormat(expense.endDate))
    setAnnualInf(numberToPercentFormat(isNumber(expense.annualInf) ? expense.annualInf : model.getDefaultInflationRate("expense"), 2))
    setAnnualInfLock(!isNumber(expense.annualInf) || model.hasLock(expense.id, "annualInf"))
    setDiscretionary(expense.discretionary ?? false)
    const asset = expense.asset ? expense.asset : model.getAssetById(expense.assetId)
    setAsset(asset)
    const liability = expense.liability ? expense.liability : model.getLiabilityById(expense.liabilityId)
    setLiability(liability)
    setEditOpen(!expense.createdAt)
  }

  const theme = useTheme()

  const styles = {
    heading: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    row: {
      height: 40,
      minHeight: 40,
      maxHeight: 40,
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "1.0em",
      maxHeight: 15,
      marginBottom: "1px",
      textWrap: "nowrap",
      color: theme.palette.grey["700"]
    }
  }

  const renderContent = () => {
    return (
      <Grid container direction="column" spacing={0}>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Amount</FormLabel>
              <Typography variant="body2" color={amountLock ? "text.secondary" : "inherit"}>
                {amount}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormLabel sx={styles.formLabel}>Frequency</FormLabel>
              <Typography variant="body2">
                {schedule}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Owner</FormLabel>
              <Typography variant="body2">
                {owner}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormLabel sx={styles.formLabel}>Type</FormLabel>
              <Typography variant="body2">
                {discretionary ? "Discretionary" : "Essential"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Start Date</FormLabel>
              <Typography variant="body2">
                {startDate}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>End Date</FormLabel>
              <Typography variant="body2" color={expense.end ? "inherit" : "text.secondary"}>
                {endDate}
              </Typography>
            </Grid>
            {expense.expenseCategory !== ExpenseCategory.LoansAndLiabilities &&
              <Grid item xs={4}>
                <FormLabel sx={styles.formLabel}>Annual Inflation %</FormLabel>
                <Typography variant="body2" color={annualInfLock ? "text.secondary" : "inherit"}>
                  {annualInf}
                </Typography>
              </Grid>
            }
          </Grid>
        </Grid>
        {asset &&
          <Grid item xs={12} sx={styles.row}>
            <FormLabel sx={styles.formLabel}>Payment Account</FormLabel>
            <Typography variant="body2">
              {asset.description}
            </Typography>
          </Grid>
        }
        {(liability || expense.expenseCategory === ExpenseCategory.LoansAndLiabilities) &&
          <Grid item xs={12} sx={styles.row}>
            <FormLabel sx={styles.formLabel}>Liability Account</FormLabel>
            <Typography variant="body2" color={!liability ? "error" : undefined}>
              {liability ? liability.description : "Not set"}
            </Typography>
          </Grid>
        }
      </Grid>
    )
  }

  let iconColor = globalColors.expenseCategory[expense.expenseCategory]
  let icon
  switch (expense.expenseCategory  ?? ExpenseCategory.GivingAndMisc) {
    case ExpenseCategory.Housing:
      icon = <HousingExpenseIcon backgroundColor={iconColor}/>
      break
    case ExpenseCategory.LoansAndLiabilities:
      icon = <LoanExpenseIcon backgroundColor={iconColor}/>
      break
    case ExpenseCategory.FoodAndPersonalCare:
      icon = <FoodAndPersonalExpenseIcon backgroundColor={iconColor}/>
      break
    case ExpenseCategory.InsuranceAndMedical:
      icon = <InsuranceAndMedicalExpenseIcon backgroundColor={iconColor}/>
      break
    case ExpenseCategory.VehiclesAndTransportation:
      icon = <VehiclesAndTransporationExpenseIcon backgroundColor={iconColor}/>
      break
    case ExpenseCategory.TravelAndEntertainment:
      icon = <TravelAndEntertainmentExpenseIcon backgroundColor={iconColor}/>
      break
    case ExpenseCategory.GivingAndMisc:
      icon = <GivingAndMiscExpenseIcon backgroundColor={iconColor}/>
      break
    default:
      icon = <DiscretionaryExpenseIcon/>
      break
  }

  return (
    <React.Fragment>
      <ModelCard2
        icon={icon}
        title={description}
        value={projectedAmount}
        valueColor="#881E10"
        expanded={expanded}
        onChange={onChange}
        editButtonId={`edit-btn-${expenseModel.id}`}
        onEdit={(event: any) => {
          setEditOpen(!editOpen)
        }}
      >
        {renderContent()}
      </ModelCard2>
      {editOpen &&
        <ExpenseEditDialog expense={expenseModel} model={model}
                           open={editOpen}
                           onSave={(update: Expense) => {
                             init(update)
                             setEditOpen(false)
                             if (onSave) {
                               onSave(update)
                               setSaved(true)
                             }
                           }}
                           onDelete={(deleted: Expense) => {
                             setEditOpen(false)
                             if (onDelete) {
                               onDelete(deleted)
                             }
                           }}
                           onClose={() => {
                             setEditOpen(false)
                             if (!expenseModel.createdAt && onDelete) {
                               onDelete(expenseModel)
                             }
                           }}/>
      }
    </React.Fragment>
  )
}

export default ExpenseCard