import * as React from "react";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useResources} from "../../stores/ResourceProvider";
import Page from "../../components/page/Page";
import MarginRow from "../../components/page/MarginRow";
import {Box, Paper, Typography, useTheme} from "@mui/material";
import {Link} from "@reach/router";
import {observer} from "mobx-react";
import RoutesConfig, {RoutesConfigHelpers} from "../../RoutesConfig";
import {UpdateUserInput, UserType} from "../../API";
import User from "../../model/User";
import CheckImg from "../../images/Check_1Check.png";
import "../../css/normalize.css"
import "../../css/webflow.css"
import "../../css/next-retirement.webflow.css"
import globalStyles from "../../styles/globalStyles";
import ProgressButton from "../../components/form/ProgressButton";
import {getISODateToday} from "../../stores/StoreUtilities";
import {ConvertKitTag} from "../../apis/ConvertKitAPI";
import Tracking from "../../components/Tracking";

interface ICheckoutReturnProps {
  sessionId?: string
}

const CheckoutReturnPage = observer((props: ICheckoutReturnProps) => {
  const [session, setSession] = useState<any>()
  const [status, setStatus] = useState<string | undefined>();
  const [customerEmail, setCustomerEmail] = useState<string | undefined>();

  const location = useLocation()
  const navigate = useNavigate()
  const { billingAPI, convertKitAPI, userStore, notify } = useResources()

  const theme = useTheme()
  const styles = {
    link: {
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 400,
      textDecoration: "none"
    }
  }

  useEffect(() => {
    const sessionId = RoutesConfigHelpers.getPathSegment(location.pathname, 2)

    if (sessionId) {
      if (!session) {
        billingAPI.getSession(sessionId)
          .then((response: any) => {
            const session = response.session
            setSession(session)
            setStatus(session.status)
            setCustomerEmail(session.customer_email)
            const user = userStore.user
            if (user && session.customer) {
              if (user.userType !== UserType.Premium || user.customerId !== session.customer) {
                updateUser(session)
                  .then((user: any) => {
                    Tracking.gtag("event", "purchase", {currency: "USD", value: session.amount_total / 100})
                    addConvertKitSubscriber(user, ConvertKitTag.PremiumSubscription)
                    // Show intro video
                    const calcPageSettings = {
                      showIntro: true
                    }
                    window.localStorage.setItem(`${user.id}.CalcPageSettings`, JSON.stringify(calcPageSettings))
                  })
                  .catch((err: any) => {
                    notify.show("error", err.message)
                  })
              }
            }
          })
          .catch((err: any) => {
            notify.show("error", err.message)
          })
      }
    } else {
      setStatus("free")
      addConvertKitSubscriber(userStore.user!, ConvertKitTag.FreeSubscription)
    }


  }, [])

  const addConvertKitSubscriber = async (user: User, tag: ConvertKitTag) => {
    convertKitAPI.addSubscriber({email: user.email,
      first_name: user.firstName, last_name: user.lastName,
      tags: [tag]})
      .then ((response: any) => {
        console.log(`Added Subscriber`, response)
      })
      .catch ((err: any) => {
        console.error("Add Subscriber Error", err)
      })
  }

  const updateUser = async (session: any) => {
    const user = userStore.user
    if (user && session.customer) {
      if (user.userType !== UserType.Premium || user.customerId !== session.customer) {
        const update: UpdateUserInput = {
          id: user.id,
          userType: UserType.Premium,
          customerId: session.customer,
          subscribedOn: getISODateToday()
        }
        const updatedUser = await userStore.updateUser(update)
        return updatedUser
      }
    }
    return (user)
  }

  const onSubmit = () => {
    navigate(`${RoutesConfig.calc.pathname}/timeline`)
  }

  const renderFreeWelcome = () => {
    return (
      <Box>
        <Typography variant="h2" color="primary" gutterBottom>
          Welcome to RBC Free!
        </Typography>
        <Typography variant="body2" gutterBottom>
          We appreciate your business!
        </Typography>
        <Typography variant="body2" gutterBottom>
          If you have any questions, please email <Link to="." onClick={() => window.open('mailto:support@retirementbudgetcalculator.com', "_blank")} style={styles.link}>
          support@retirementbudgetcalculator.com
        </Link>.
        </Typography>
        <Typography variant="h4" sx={{mt:2, mb:1}}>
          Please enjoy these free features:
        </Typography>
        <div className="list-div">
          <div className="advsor-feature">
            <img
              alt=""
              loading="lazy"
              src={CheckImg}
              className="checkmark-icon"
            />
            <div>Basic Retirement Dashboard</div>
          </div>
          <div className="advsor-feature">
            <img
              alt=""
              loading="lazy"
              src={CheckImg}
              className="checkmark-icon"
            />
            <div>Retirement Budgeting</div>
          </div>
          <div className="advsor-feature">
            <img
              alt=""
              loading="lazy"
              src={CheckImg}
              className="checkmark-icon"
            />
            <div>Asset Tracking</div>
          </div>
          <div className="advsor-feature">
            <img
              alt=""
              loading="lazy"
              src={CheckImg}
              className="checkmark-icon"
            />
            <div>Free Forever</div>
          </div>
        </div>
        <Box display="flex" justifyContent="space-around" alignItems="center" mt={2}>
          <ProgressButton
            size="large"
            variant="contained"
            color="secondary"
            sx={globalStyles.webFlowLargeButton}
            type="button"
            onClick={onSubmit}
          >
            Get Started with Free
          </ProgressButton>
        </Box>
      </Box>
    )
  }

  const renderPremiumWelcome = () => {
    return (
      <Box>
        <Typography variant="h2" color="primary" gutterBottom>
          Welcome to RBC Premium!
        </Typography>
        <Typography variant="body2" gutterBottom>
          We appreciate your business! A confirmation email will be sent to {customerEmail}.
        </Typography>
        <Typography variant="body2" gutterBottom>
          If you have any questions, please email <Link to="." onClick={() => window.open('mailto:support@retirementbudgetcalculator.com', "_blank")} style={styles.link}>
          support@retirementbudgetcalculator.com
        </Link>.
        </Typography>
        <Typography variant="h4" sx={{mt:2, mb:1}}>
          Please enjoy these Premium Features:
        </Typography>
        <div className="list-div">
          <div className="advsor-feature">
            <img
              alt=""
              loading="lazy"
              src={CheckImg}
              className="checkmark-icon"
            />
            <div>Retirement Optimizer</div>
          </div>
          <div className="advsor-feature">
            <img
              alt=""
              loading="lazy"
              src={CheckImg}
              className="checkmark-icon"
            />
            <div>Tax Estimator</div>
          </div>
          <div className="advsor-feature">
            <img
              alt=""
              loading="lazy"
              src={CheckImg}
              className="checkmark-icon"
            />
            <div>AI Financial Assistant</div>
          </div>
          <div className="advsor-feature">
            <img
              alt=""
              loading="lazy"
              src={CheckImg}
              className="checkmark-icon"
            />
            <div>RBC Community Access</div>
          </div>
        </div>
        <Box display="flex" justifyContent="space-around" alignItems="center" mt={2}>
          <ProgressButton
            size="large"
            variant="contained"
            color="secondary"
            sx={globalStyles.webFlowLargeButton}
            type="button"
            onClick={onSubmit}
          >
            Get Started with Premium
          </ProgressButton>
        </Box>
      </Box>
    )
  }

  return (
    <Page title="Checkout Return">
      <MarginRow>
        {status === 'open' &&
          <Typography>Checkout is still open - TODO</Typography>
        }
        {status === 'complete' &&
          <Box display="flex" justifyContent="center" alignItems="center" margin="auto" pt={2}>
            <Paper
              sx={{
                width: {xs:"100%", sm:"50%"},
                maxWidth: {xs:"100%", sm:"650px"},
                p: 3
              }}
            >
              {renderPremiumWelcome()}
            </Paper>

          </Box>
        }
        {status === 'free' &&
          <Box display="flex" justifyContent="center" alignItems="center" margin="auto" pt={2}>
            <Paper
              sx={{
                width: {xs:"100%", sm:"50%"},
                maxWidth: {xs:"100%", sm:"650px"},
                p: 3
              }}
            >
              {renderFreeWelcome()}
            </Paper>

          </Box>
        }
      </MarginRow>
    </Page>
  )
})

export default CheckoutReturnPage