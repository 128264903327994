import {FutureCalc, IBudgetCalc, IFutureYear} from "../../../../components/calculator/Calculator";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {numberToMoneyFormat, numberToPercentFormat} from "../../../../stores/StoreUtilities";
import {Gender} from "../../../../API";
import globalStyles from "../../../../styles/globalStyles";


const LifetimeTaxTable = ({
  futureCalc,
  year,
  onChange
}: {
  futureCalc: FutureCalc
  year?: number
  onChange?: (year: number) => any
}) => {
  const [currentYear, setCurrentYear] = useState<number>(0)

  useEffect(() => {
    if (year) {
      setCurrentYear(year)
    }
  }, [year])

  const handleChange = (year: number) => {
    if (onChange) {
      setCurrentYear(year)
      onChange(year)
    }
  }

  const styles = {
    total: {
      fontWeight: 700
    }
  }

  let agiTotal = 0
  let deductionsTotal = 0
  let taxableIncomeTotal = 0
  let estimatedTaxTotal = 0

  return (
    <Box display="flex" flexGrow={1} flexDirection="column" mt={2}>
      <Box display="flex" flexGrow={1} justifyContent="space-between" minHeight={40}>
        <Typography variant="h3" color="primary" gutterBottom>Lifetime Tax Details</Typography>
        <Box display="flex" alignItems="center" pt={0}>
          <Box width={14} height={14} sx={globalStyles.trRetired}></Box>
          <Typography variant="body2" style={globalStyles.legendLabel}>Planned Retirement</Typography>
          {futureCalc.person1 &&
            <React.Fragment>
              <Box width={14} height={14} sx={futureCalc.person1.gender === Gender.Male ? globalStyles.trHisLifeExpectancy : globalStyles.trHerLifeExpectancy}></Box>
              <Typography variant="body2" style={globalStyles.legendLabel}>{futureCalc.person1.nickname}'s Life Expectancy</Typography>
            </React.Fragment>
          }
          {futureCalc.person2 &&
            <React.Fragment>
              <Box width={14} height={14} sx={futureCalc.person2.gender === Gender.Male ? globalStyles.trHisLifeExpectancy : globalStyles.trHerLifeExpectancy}></Box>
              <Typography variant="body2" style={globalStyles.legendLabel}>{futureCalc.person2.nickname}'s Life Expectancy</Typography>
            </React.Fragment>
          }
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" width="100%">
          <TableHead>
            <TableRow>
              <TableCell>Year</TableCell>
              <TableCell align="right">Adjusted Gross Income</TableCell>
              <TableCell align="right">Deductions</TableCell>
              <TableCell align="right">Taxable Income</TableCell>
              <TableCell align="right">Estimated Tax</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {futureCalc.futureYears.map((fy: IFutureYear, index: number) => {
              const taxInput = fy.taxInput
              const taxEstimate = fy.taxEstimate
              const taxYear = fy.year
              const agi = taxEstimate?.adjustedGrossIncome ?? 0
              agiTotal += agi
              const deductions = (taxEstimate?.itemizedDeductions ?? 0) + (taxEstimate?.standardDeduction ?? 0) + (taxInput?.otherQualifyingDeductionsToTaxableIncome ?? 0)
              deductionsTotal += deductions
              const taxableIncome = taxEstimate?.taxableIncome ?? 0
              taxableIncomeTotal += taxableIncome
              const estimatedTax = taxEstimate?.totalEstimatedTax ?? 0
              estimatedTaxTotal += estimatedTax

              let rowStyle
              if (fy.year === futureCalc.latestRetirementYear) {
                rowStyle = globalStyles.trRetired
              } else if (futureCalc.person1 && fy.year === futureCalc.person1!.lifeExpectancyYear) {
                rowStyle = (futureCalc.person1.gender === Gender.Male) ? globalStyles.trHisLifeExpectancy : globalStyles.trHerLifeExpectancy
              } else if (futureCalc.person2 && fy.year === futureCalc.person2!.lifeExpectancyYear) {
                rowStyle = (futureCalc.person2.gender === Gender.Male) ? globalStyles.trHisLifeExpectancy : globalStyles.trHerLifeExpectancy
              }

              return (
                <TableRow selected={currentYear === taxYear} key={`row${index}`} style={rowStyle}
                          onClick={() => {handleChange(fy.year)}}>
                  <TableCell>{taxYear}</TableCell>
                  <TableCell align="right">{numberToMoneyFormat(agi, 0)}</TableCell>
                  <TableCell align="right">{numberToMoneyFormat(deductions, 0)}</TableCell>
                  <TableCell align="right">{numberToMoneyFormat(taxableIncome, 0)}</TableCell>
                  <TableCell align="right">{numberToMoneyFormat(estimatedTax, 0)}</TableCell>
                </TableRow>
              )
            }
            )}
            <TableRow key={`totalRow`} style={globalStyles.trTotal}>
              <TableCell style={styles.total}>Lifetime Tax Totals</TableCell>
              <TableCell align="right" style={styles.total}>{numberToMoneyFormat(agiTotal, 0)}</TableCell>
              <TableCell align="right" style={styles.total}>{numberToMoneyFormat(deductionsTotal, 0)}</TableCell>
              <TableCell align="right" style={styles.total}>{numberToMoneyFormat(taxableIncomeTotal, 0)}</TableCell>
              <TableCell align="right" style={styles.total}>{numberToMoneyFormat(estimatedTaxTotal, 0)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default LifetimeTaxTable