import Page from "../components/page/Page"
import {observer} from "mobx-react";
import {
  Box,
  DialogContentText,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
  useTheme
} from "@mui/material";
import FormValidator from "../components/form/FormValidator";
import Visible from "../components/Visible";
import * as React from "react";
import {useEffect, useState} from "react";
import {
  CreateAccountInput,
  CreatePersonInput,
  CreateUserInput,
  Gender,
  MaritalStatus,
  UpdatePersonInput,
  UserRole,
  UserStatus,
  UserType
} from "../API";
import TextFieldValidator from "../components/form/TextFieldValidator";
import CheckboxValidator from "../components/form/CheckboxValidator";
import {useNavigate} from 'react-router-dom';
import DialogButton from "../components/form/DialogButton";
import ProgressButton from "../components/form/ProgressButton";
import {useResources} from "../stores/ResourceProvider";
import Tracking from "../components/Tracking";
import MarginRow from "../components/page/MarginRow";
import {createUUID, getISODateFromDate, humanizeString} from "../stores/StoreUtilities";
import {UserStoreConstants} from "../stores/UserStore";
import Logger from "../components/Logger";
import RoutesConfig from "../RoutesConfig";
import DatePickerValidator from "../components/form/DatePickerValidator";
import FormGroupSpacer from "../components/form/FormGroupSpacer";
import User from "../model/User";
import Model from "../model/Model";
import Account from "../model/Account";
import {AccountStoreConstants} from "../stores/AccountStore";
import {differenceInYears} from "date-fns";
import TrialImg from "../images/30daytrial.png"
import CheckAlt from "../images/CheckAlt.png"
import globalStyles from "../styles/globalStyles";
import Footer from "../components/page/Footer";
import TermsOfUseDialog from "../components/page/TermsOfUseDialog";
import SubscriptionEditDialog from "./settings/SubscriptionEditDialog";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {ConvertKitTag} from "../apis/ConvertKitAPI";
import useNavigationHelper from "../components/NavigationHelper";
import {GenderOptions} from "../model/Person";
import {Link} from "@reach/router";

enum SignupStep {
  UserInfo,
  Confirming,
  Person1,
  Person2,
  Subscription
}

const SignupPage = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [step, setStep] = useState<SignupStep>(SignupStep.UserInfo)
  const [message, setMessage] = useState<string | undefined>()
  const [route, setRoute] = useState<undefined>()

  const { accountStore, notify, progress, userStore, modelStore, calculator, privacyPolicy, migrateAPI, convertKitAPI } = useResources()
  const theme = useTheme()
  const navigate = useNavigate();
  const {navigateHome} = useNavigationHelper()

  // const [values, setValues] = useState<any>({})
  const [accountType, setAccountType] = useState<string>("Cusotmer")
  const [accountName, setAccountName] = useState<string>("")
  const [betaCode, setBetaCode] = useState<string>("")
  const [betaCodeError, setBetaCodeError] = useState<string>()
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [referrer, setReferrer] = useState<string | undefined>()
  const [userId, setUserId] = useState<string>("")
  const [accountId, setAccountId] = useState<string>("")
  const [role, setRole] = useState<UserRole>(UserRole.Customer)
  const [confirmationCode, setConfirmationCode] = useState<string>("")
  const [termsCheckbox, setTermsCheckbox] = useState<boolean>(false)
  const [user, setUser] = useState<User | undefined>()
  const [nickname1, setNickname1] = useState<string>("")
  const [gender1, setGender1] = useState<Gender | undefined>()
  const [birthDate1, setBirthDate1] = useState<Date | undefined>()
  const [retireDate1, setRetireDate1] = useState<Date | undefined>()
  const [maritalStatus, setMaritalStatus] = useState<MaritalStatus | undefined>()
  const [nickname2, setNickname2] = useState<string>("")
  const [gender2, setGender2] = useState<Gender | undefined>()
  const [birthDate2, setBirthDate2] = useState<Date | undefined>()
  const [retireDate2, setRetireDate2] = useState<Date | undefined>()
  const [showTermsOfUse, setShowTermsOfUse] = useState<boolean>(false)
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)

  let account: Account | undefined | void = undefined
  let model: Model | void | null

  useEffect(() => {
    if (step === SignupStep.UserInfo && !userStore.isAuthenticated) {
      setAccountName("")
      setFirstName("")
      setLastName("")
      setEmail("")
      setPassword("")
      setUserId(createUUID())
      setAccountId(AccountStoreConstants.PRIMARY_ACCOUNT_ID)
      setRole(UserRole.Customer)
      setConfirmationCode("")
      setTermsCheckbox(false)
      setNickname1("")
      setGender1(undefined)
      setBirthDate1(undefined)
      setRetireDate1(undefined)
      setMaritalStatus(undefined)
      setNickname2("")
      setGender2(undefined)
      setBirthDate2(undefined)
      setRetireDate2(undefined)
    }
  }, [])

  const styles = {

  }

  const advisorBase64: string = "hEBsqdYPyD2rgOwB4/OPyEMUYR1wrBAOcbRpwS07d/k="
  const customerBase64: string = "CVpCoOyPRKVMXTwchHC3hOSpEfzQA8W8pnmTrM9bvs4="

  const validateBetaCode = async (code: string) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(betaCode);
    const arrayBuffer = await crypto.subtle.digest('SHA-256', data);
    const base64String = arrayBufferToBase64(arrayBuffer);

    if (base64String === advisorBase64) {
      setRole(UserRole.Advisor)
      return true
    } else if (base64String === customerBase64) {
      setRole(UserRole.Customer)
      return true
    } else {
      return false
    }
  }

  const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const onSubmit = async () => {
    setMessage(undefined)

    if (step === SignupStep.UserInfo) {
      // Sign-up user
      setIsProcessing(true)
      setBetaCodeError("")

      // if (!await validateBetaCode(betaCode)) {
      //   setBetaCodeError("The Beta Program Code is invalid.")
      //   setIsProcessing(false)
      //   return
      // }

      if (!termsCheckbox) {
        notify!.show("error", "You must check the Terms box to indicate you agree with the Terms of Use and Privacy Policy.")
        setIsProcessing(false)
        return
      }

      // Check for existing legacy user
      const legacyUser = await migrateAPI.verify(email.toLowerCase())
        .catch((err: any) => {
          // Legacy user not found, so ignore
          console.debug(`Legacy user for ${email} not found`)
        })
      if (legacyUser) {
        setMessage(`This email is already signed up for RBC. Please use Sign in`)
        setIsProcessing(false)
        return
      }

      const signupResult = await userStore!.signUp(userId, password, email.toLowerCase(), undefined,
        accountId, role)
        .catch((err: Error) => {
          let message
          if (err.name === UserStoreConstants.USERNAME_EXISTS_EXCEPTION ||
              err.message === UserStoreConstants.EMAIL_EXISTS_MESSAGE ||
              err.message.includes("User already exists")) {
            message = "This email is already in use. Please use Sign in."
            Tracking.event({action: 'SignupError', label:"Email in use"})
          } else {
            message = `Unable to create user. ${err.message}`
            Tracking.event({action: 'SignupError', label: `Unable to create user: ${err.message}`})
          }
          // notify!.show("error", message)
          setMessage(message)
          setIsProcessing(false)
          return
        })

      setNickname1(firstName)

      if (signupResult) {
        setStep(SignupStep.Confirming)
      }
      setIsProcessing(false)
    } else if (step === SignupStep.Confirming) {
      setIsProcessing(true)

      const confirmation = await userStore!.confirmSignUp(userId, confirmationCode)
        .catch((err: Error) => {
          notify!.show("error", "Unable to verify account")
          Tracking.event({action: 'SignupError', label: `Unable to verify account: ${err.message}`})
          setIsProcessing(false)
        })

      if (confirmation === "SUCCESS") {
        // Sign in
        let user = await userStore!.signIn(userId, password)
          .catch((err: Error) => {
            if (err.message !== UserStoreConstants.USER_NOT_FOUND) {
              notify!.show("error", "Unable to log in to account")
              Tracking.event({action: 'SignupError', label: `Unable to log into account: ${err.message}`})
              setIsProcessing(false)
            }
          })

        if (!user) {
          // Create user
          const createUserInput: CreateUserInput = {
            id: userId,
            userType: UserType.Free,
            userStatus: UserStatus.Active,
            accountId: AccountStoreConstants.PRIMARY_ACCOUNT_ID,
            firstName: firstName,
            lastName: lastName,
            email: email.toLowerCase(),
            role: role,
            referrer: referrer
          }

          user = await userStore!.createUser(createUserInput, true)
            .catch((err: Error) => {
              notify!.show("error", "Unable to create user")
              Tracking.event({action: 'SignupError', label: `Unable to create user: ${err.message}`})
              setIsProcessing(false)
            })

          if (user) {
            setUser(user)
            // Get Primary account
            account = await accountStore.loadAccount(AccountStoreConstants.PRIMARY_ACCOUNT_ID)
            if (!account) {
              // Create primary account
              const createAccountInput: CreateAccountInput = {
                id: accountId,
                ownerId: user.id,
                name: `RBC Primary`,
              }

              account = await accountStore.createAccount(createAccountInput)
                .catch((err: Error) => {
                  notify!.show("error", "Unable to create account")
                  Tracking.event({action: 'SignupError', label: `Unable to create account: ${err.message}`})
                  setIsProcessing(false)
                })
            }

            if (account) {
              if (user) {
                // Sign in again to get updated attributes and groups
                user = await userStore!.signIn(email.toLowerCase(), password)
                  .catch((err: Error) => {
                    notify!.show("error", "Unable to sign in new user")
                    Logger.error('Unable to sign in new user', err)
                    Tracking.event({action: 'SignupError', label: `Unable to sign in new user: ${err.message}`})
                    setIsProcessing(false)
                  })
                setUser(user)
                Tracking.gtag("event", "sign_up", {method: referrer})
              }
            }
          } else {
            user = await userStore!.signIn(email.toLowerCase(), password)
              .catch((err: Error) => {
                notify!.show("error", "Unable to sign in new user")
                Logger.error('Unable to sign in new user', err)
                Tracking.event({action: 'SignupError', label: `Unable to sign in new user: ${err.message}`})
                setIsProcessing(false)
              })
            setUser(user)
          }

          setIsProcessing(false)

          if (user) {
            setUser(user)
            account = await accountStore!.init(user.account)
            await modelStore!.loadUserModels(user)
            // Show intro video
            const calcPageSettings = {
              showIntro: true
            }
            window.localStorage.setItem(`${user.id}.CalcPageSettings`, JSON.stringify(calcPageSettings))

            setStep(SignupStep.Person1)
          }
        }
      }
    } else if (step === SignupStep.Person1) {
      account = accountStore.account
      model = await modelStore.getCurrentModel()

      if (account && model) {
        if (model.persons.length === 1) {
          // Update person 1
          let lifeExpectancy
          if (birthDate1) {
            const age = Math.floor(differenceInYears(new Date(), birthDate1))
            lifeExpectancy = Math.round(calculator.lifeExpectancyYears(age, 0, gender1 === Gender.Male)) + age
          }

          const personUpdate: UpdatePersonInput = {
            id: model.persons[0].id,
            nickname: nickname1,
            gender: gender1,
            maritalStatus: maritalStatus,
            birthDate: birthDate1 ? getISODateFromDate(birthDate1) : null,
            retireDate: retireDate1 ? getISODateFromDate(retireDate1) : null,
            lifeExpectancy: lifeExpectancy
          }
          const person1 = await modelStore.updatePerson(personUpdate)
          if (person1) {
            model.persons[0] = person1
          }
        }
      }

      if (maritalStatus === MaritalStatus.Married) {
        setStep(SignupStep.Person2)
      } else {
        setUser(userStore.user)
        setStep(SignupStep.Subscription)
      }
    } else if (step === SignupStep.Person2) {
      account = accountStore.account
      model = await modelStore.getCurrentModel()

      if (account && model) {
        let lifeExpectancy
        if (birthDate2) {
          const age = Math.floor(differenceInYears(new Date(), birthDate2))
          lifeExpectancy = Math.round(calculator.lifeExpectancyYears(age, 0, gender2 === Gender.Male)) + age
        }

        const personInput: CreatePersonInput = {
          accountId: account.id,
          userId: model.userId,
          modelId: model.id,
          nickname: nickname2,
          gender: gender2,
          maritalStatus: maritalStatus,
          birthDate: birthDate2 ? getISODateFromDate(birthDate2) : null,
          retireDate: retireDate2 ? getISODateFromDate(retireDate2) : null,
          lifeExpectancy: lifeExpectancy
        }

        const person2 = await modelStore.createPerson(personInput)
        if (person2) {
          model.persons.push(person2)
          setUser(userStore.user)
          setStep(SignupStep.Subscription)
        }
      }

    }
  }

  const onCancel = () => {
    navigateHome()
  }

  const onResendCode = async () => {
    userStore!.resendSignUp(userId)
      .then((result: any) => {
        notify!.show("success", "Verification code resent")
        Tracking.event({action: 'SignupResendCode'})
      })
      .catch((err: Error) => {
        notify!.show("error", "Unable to resend verification code")
        Tracking.event({action: 'SignupError', label: `Unable to resend verification code: ${err.message}`})
      })
  }

  const handleAcceptTerms = (accept: boolean) => {
    setTermsAccepted(accept)
    setTermsCheckbox(accept)
    setShowTermsOfUse(false)
  }

  const handleSave = async (user: User) => {
    addConvertKitSubscriber(user, ConvertKitTag.FreeSubscription)

    // Show intro video
    const calcPageSettings = {
      showIntro: true
    }
    window.localStorage.setItem(`${userStore.user!.id}.CalcPageSettings`, JSON.stringify(calcPageSettings))

    navigate(`${RoutesConfig.checkoutReturn.pathname}`)
  }

  const addConvertKitSubscriber = async (user: User, tag: ConvertKitTag) => {
    convertKitAPI.addSubscriber({email: user.email,
      first_name: user.firstName, last_name: user.lastName,
      tags: [tag]})
      .then ((response: any) => {
        console.log(`Added Subscriber`, response)
      })
      .catch ((err: any) => {
        console.error("Add Subscriber Error", err)
      })
  }

  const renderPromo = () => {

    return (
      <Paper
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          overflow: 'hidden',
          mx: {
            md: 'auto'
          },
          mt: {
            sm: 0,
            md: 8,
          },
          p: 2,
          maxWidth: {
            md: theme.breakpoints.values.lg
          },
          minHeight: 532
        }}
        elevation={2}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h2" color="primary">Live the life you want with confidence!</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1"><b>Retirement Budget Calculator</b> is an easy-to-use tool designed to help you make the best decisions so that you can achieve your goals and live the life you want.</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1"><b>RBC will help you:</b></Typography>
            <ul>
              <li><Typography variant="body1">Project how much you will need in retirement.</Typography></li>
              <li><Typography variant="body1">Simulate different scenarios.</Typography></li>
              <li><Typography variant="body1">See the impact of different withdrawal strategies.</Typography></li>
              <li><Typography variant="body1">Create a cash flow plan.</Typography></li>
            </ul>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" sx={{py:2}}>
              <Grid item xs={2}>
              </Grid>
              <Grid item xs={2}>
                <img src={TrialImg} alt="Risk Free Trial"/>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1" sx={{fontStyle:"italic"}}>
                  Try Retirement Budget Calculator for free!<br/>
                  No credit card required!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box display="flex" justifyContent="space-evenly" my={2}>
              <Box display="flex" alignItems="center">
                <img src={CheckAlt} alt="Check"/>
                <Typography variant="h3" sx={{pl:2}}>Clarity</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <img src={CheckAlt} alt="Check"/>
                <Typography variant="h3" sx={{pl:2}}>Confidence</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <img src={CheckAlt} alt="Check"/>
                <Typography variant="h3" sx={{pl:2}}>Freedom</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  const renderSignUpDialog = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          overflow: 'hidden',
          mx: {
            md: 'auto'
          },
          // mt: -3,
          px: 2,
          maxWidth: {
            md: theme.breakpoints.values.lg
          },
        }}
      >
        {/*<Typography variant="h4" color="primary" gutterBottom sx={{letterSpacing: "0.1em"}}>*/}
        {/*  STEP {step === SignupStep.UserInfo ? "1" : "2"} OF 2*/}
        {/*</Typography>*/}
        <Typography variant="h1" gutterBottom>
          Get started for free
        </Typography>
        <Typography variant="body1">
          Congratulations, you are just a few steps away from knowing if you are on track for a successful retirement!&nbsp;
          If you already have an RBC account, <Link to="#" onClick={() => navigate(RoutesConfig.signIn.pathname)}>{RoutesConfig.signIn.title} here</Link>.
        </Typography>
        <FormValidator
          // onSubmit={onSubmit}
          autoComplete="off" 
          name="signupForm" 
          id="signupForm"
        >
          <Box py={2}>
            <Visible cond={step === SignupStep.UserInfo}>
              {/*<TextFieldValidator*/}
              {/*  margin="dense"*/}
              {/*  name="betaCode"*/}
              {/*  label="Beta Program Code"*/}
              {/*  autoFocus*/}
              {/*  type="password"*/}
              {/*  variant="standard"*/}
              {/*  validators={{required:true}}*/}
              {/*  onChange={(event: any) => {*/}
              {/*    setBetaCode(event.target.value)*/}
              {/*    setBetaCodeError("")*/}
              {/*  }}*/}
              {/*  value={betaCode}*/}
              {/*  placeholder=""*/}
              {/*  helperText={betaCodeError ?? "Required for beta program access"}*/}
              {/*  error={Boolean(betaCodeError)}*/}
              {/*  fullWidth*/}
              {/*/>*/}
              <TextFieldValidator
                margin="dense"
                name="firstName"
                label="First Name"
                // autoFocus
                type="text"
                variant="standard"
                validators={{required:true}}
                onChange={(event: any) => setFirstName(event.target.value)}
                value={firstName}
                placeholder=""
                fullWidth
              />
              <TextFieldValidator
                margin="dense"
                name="lastName"
                label="Last Name"
                type="text"
                variant="standard"
                validators={{required:true}}
                onChange={(event: any) => setLastName(event.target.value)}
                value={lastName}
                placeholder=""
                fullWidth
              />
              <TextFieldValidator
                margin="dense"
                name="email"
                id="EmailInput"
                label="Email"
                variant="standard"
                placeholder=""
                type="text"
                validators={{required:true, isEmail: true}}
                value={email}
                onChange={(event: any) => setEmail(event.target.value)}
                fullWidth
                autoCorrect="off"
                autoCapitalize="off"
                autoComplete="on"
              />
              <TextFieldValidator
                margin="dense"
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                variant="standard"
                validators={{required:true, isStrongPassword:3}}
                value={password}
                onChange={(event: any) => setPassword(event.target.value)}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextFieldValidator
                margin="dense"
                name="referrer"
                label="Where did you hear about RBC?"
                type="text"
                variant="standard"
                validators={{required:false}}
                onChange={(event: any) => setReferrer(event.target.value)}
                value={referrer}
                placeholder=""
                fullWidth
              />
              {/*<TextFieldValidator*/}
              {/*  margin="dense"*/}
              {/*  name="phone"*/}
              {/*  label="Phone Number"*/}
              {/*  type="text"*/}
              {/*  variant="standard"*/}
              {/*  validators={{isMobilePhone:null, required:true}}*/}
              {/*  onChange={onChange}*/}
              {/*  value={phone}*/}
              {/*  fullWidth*/}
              {/*/>*/}
              <FormControlLabel 
                sx={{ px: 2, py: 1 }}
                control={
                  <CheckboxValidator 
                    name="termsCheckbox" 
                    color="primary"
                    checked={termsCheckbox}
                    value="checkedG" 
                    onChange={(event: any) => {
                      if (event.target.checked) {
                        if (!termsAccepted) {
                          setTermsCheckbox(false)
                          setShowTermsOfUse(true)
                        } else {
                          setTermsCheckbox(true)
                        }
                      } else {
                        setTermsCheckbox(false)
                        setTermsAccepted(false)
                      }
                    }}
                  />
                }
                label={
                  <Typography variant="body2" sx={{ pl: 1 }}>
                    I confirm that I am signing up from within the USA and agree to the RBC&nbsp;
                    <Link to="." onClick={() => setShowTermsOfUse(true)} style={globalStyles.link}>Terms of Use</Link> and&nbsp;
                    <Link to="." onClick={() => privacyPolicy.show()} style={globalStyles.link}>Privacy Policy</Link>.
                    I give permission to communicate with me via email.
                  </Typography>
                }
              />
            </Visible>

            { message && 
              <DialogContentText 
                sx={{
                  color: 'red'
                }}
              >
                {message}
              </DialogContentText>
            }

            <Visible cond={step === SignupStep.Confirming}>
              <Typography variant="body2">
                Please check your email for a verification code.
              </Typography>
              <TextFieldValidator
                margin="dense"
                name="confirmationCode"
                label="Verification Code"
                type="text"
                variant="standard"
                value={confirmationCode}
                validators={{required:true, matches:"^\\d{6}$"}}
                onChange={(event: any) => setConfirmationCode(event.target.value)}
                fullWidth
              />
              <DialogButton variant="tertiary" onClick={onResendCode}>
                Resend verification code
              </DialogButton>

            </Visible>

            <Visible cond={step === SignupStep.Person1}>
              <Typography variant="h4">
                Please enter your retirement details:
              </Typography>
              <TextFieldValidator
                margin="dense"
                name="nickname1"
                label="Preferred Name"
                autoFocus
                type="text"
                variant="standard"
                validators={{required:true}}
                value={nickname1}
                onChange={(event: any) => setNickname1(event.target.value)}
                placeholder=""
                fullWidth
              />
              <Box display="flex" justifyContent="space-between">
                <TextFieldValidator
                  type="text"
                  validators={{ required: true }}
                  required
                  name="gender1"
                  label="Gender"
                  variant="standard"
                  fullWidth
                  styleProp={{width:"100%"}}
                  autocompleteOptions={{
                    sx: {width:"100%"},
                    freeSolo: false,
                    options: GenderOptions,
                    value: gender1,
                    onChange: (event: any, value: Gender, reason: any) => {
                      setGender1(value)
                    }
                  }}
                />
                <FormGroupSpacer/>
                <TextFieldValidator
                  type="text"
                  validators={{ required: true }}
                  required
                  name="maritalStatus"
                  label="Marital Status"
                  variant="standard"
                  fullWidth
                  styleProp={{width:"100%"}}
                  autocompleteOptions={{
                    sx: {width:"100%"},
                    freeSolo: false,
                    options: Object.values(MaritalStatus),
                    getOptionLabel: (option: string) => option ? humanizeString(option) : "",
                    value: maritalStatus,
                    onChange: (event: any, value: MaritalStatus, reason: any) => {
                      setMaritalStatus(value)
                    }
                  }}
                />
              </Box>
              <Box display="flex" flexGrow={1} justifyContent="space-between">
                <DatePickerValidator
                  name="birthDate1"
                  label="Birth Date"
                  value={birthDate1}
                  format="MM-dd-yyyy"
                  variant="standard"
                  margin="dense"
                  required
                  onChange={(newValue: any) => {
                    setBirthDate1(newValue)
                  }}
                />
                <FormGroupSpacer/>
                <DatePickerValidator
                  name="retireDate1"
                  label="Est Retirement Date"
                  value={retireDate1}
                  format="MM-dd-yyyy"
                  variant="standard"
                  margin="dense"
                  required
                  onChange={(newValue: any) => {
                    setRetireDate1(newValue)
                  }}
                />
              </Box>
            </Visible>

            <Visible cond={step === SignupStep.Person2}>
              <Typography variant="h4">
                Please enter your spouse's retirement details:
              </Typography>
              <TextFieldValidator
                margin="dense"
                name="nickname2"
                label="Preferred Name"
                autoFocus
                type="text"
                variant="standard"
                validators={{required:true}}
                onChange={(event: any) => setNickname2(event.target.value)}
                value={nickname2}
                placeholder=""
                fullWidth
              />
              <Box display="flex" justifyContent="space-between">
                <TextFieldValidator
                  type="text"
                  validators={{ required: true }}
                  required
                  name="gender2"
                  label="Gender"
                  variant="standard"
                  fullWidth
                  styleProp={{width:"100%"}}
                  autocompleteOptions={{
                    sx: {width:"100%"},
                    freeSolo: false,
                    options: GenderOptions,
                    value: gender2,
                    onChange: (event: any, value: Gender, reason: any) => {
                      setGender2(value)
                    }
                  }}
                />
                <FormGroupSpacer/>
                <Box display="flex" flexGrow={1} width="100%"></Box>
              </Box>
              <Box display="flex" flexGrow={1} justifyContent="space-between">
                <DatePickerValidator
                  name="birthDate2"
                  label="Birth Date"
                  value={birthDate2}
                  format="MM-dd-yyyy"
                  variant="standard"
                  margin="dense"
                  required
                  onChange={(newValue: any) => {
                    setBirthDate2(newValue)
                  }}
                />
                <FormGroupSpacer/>
                <DatePickerValidator
                  name="retireDate2"
                  label="Est Retirement Date"
                  value={retireDate2}
                  format="MM-dd-yyyy"
                  variant="standard"
                  margin="dense"
                  required
                  onChange={(newValue: any) => {
                    setRetireDate2(newValue)
                  }}
                />
              </Box>
            </Visible>

            <Visible cond={step === SignupStep.Subscription}>
              <SubscriptionEditDialog user={user!} subscriptionPlan="none" subscriptionFrequency="Monthly"
                                      open={step === SignupStep.Subscription}
                                      onSave={(update: User) => {
                                        handleSave(update)
                                      }}
                                      onClose={() => {
                                        handleSave(user!)
                                      }}/>
            </Visible>
          </Box>
          <Box display="flex" justifyContent="space-between">
            {/*<DialogButton*/}
            {/*  variant="secondary"*/}
            {/*  size="large"*/}
            {/*  onClick={onCancel}*/}
            {/*>*/}
            {/*  Cancel*/}
            {/*</DialogButton>*/}
            <ProgressButton 
              size="large"
              variant="contained" 
              color="secondary"
              sx={globalStyles.webFlowLargeButton}
              fullWidth={true}
              type="submit"
              onClick={onSubmit}
              processing={isProcessing}
            >
              { step === SignupStep.Confirming ? "Verify" : "Next" }
            </ProgressButton>
          </Box>
        </FormValidator>
      </Box>
    )
  }

  return (
    <Page title="Get Started">
      <MarginRow maxWidth={theme.breakpoints.values.xl}>
        <Grid 
          container 
          direction="row"
          justifyContent="flex-start"
          alignItems="top"
          sx={{
            minHeight: 'calc(100vh - 206px)',
            // my: {
            //   sm: 0,
            //   xl: "auto"
            // },
            px: {
              sm: 2,
              md: 0
            },
          }}
          spacing={6}
        >
          <Grid item sm={12} md={6}>
            {renderPromo()}
          </Grid>
          <Grid item sm={12} md={6}>
            {renderSignUpDialog()}
          </Grid>
        </Grid>
        {showTermsOfUse &&
          <TermsOfUseDialog onAccept={handleAcceptTerms}/>
        }
        <Footer/>
      </MarginRow>
    </Page>
  )
})

export default SignupPage