import {Gender, MaritalStatus} from "../API";
import {addYears, differenceInMonths, differenceInYears, endOfYear} from "date-fns";
import {getISODateFromDate, isoToLocalDate} from "../stores/StoreUtilities";
import IModelItem from "./IModelItem";

export const GenderOptions = [
  Gender.Male, Gender.Female
]

interface IPerson {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  userId: string
  modelId: string
  nickname: string
  gender: Gender
  maritalStatus: MaritalStatus
  birthDate: string
  retireDate: string
  hereditaryAdjust: number
  lifeExpectancy: number
  state: string
}


class Person implements IModelItem, IPerson {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  userId: string
  modelId: string
  nickname: string
  gender: Gender
  maritalStatus: MaritalStatus
  birthDate: string
  retireDate: string
  hereditaryAdjust: number
  lifeExpectancy: number
  state: string
  spouse?: Person

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.userId = data.userId
    this.modelId = data.modelId
    this.nickname = data.nickname
    this.gender = data.gender
    this.maritalStatus = data.maritalStatus
    this.birthDate = data.birthDate
    this.hereditaryAdjust = data.hereditaryAdjust ?? 0
    this.retireDate = data.retireDate
    this.lifeExpectancy = data.lifeExpectancy ? data.lifeExpectancy : this.getLifeExpectancyYears()
    this.state = data.state // TODO: Do we need this
  }

  get isJoint(): boolean {
    return (this.id.endsWith('joint'))
  }

  get isComplete() : boolean {
    if (this.nickname && this.gender && this.maritalStatus && this.birthDate && this.retireDate) {
      return true
    } else {
      return false
    }
  }

  getAge(year: number = 0): number {
    let age = 0
    if (this.birthDate) {
      const birthdate = isoToLocalDate(this.birthDate)
      if (year > 0) {
        age = year - birthdate.getFullYear()
      } else {
        const today = new Date()
        age = Math.floor(differenceInYears(today, birthdate))
      }
    }
    return age
  }

  getAgeOnDate(date: Date): number {
    return differenceInMonths(date, isoToLocalDate(this.birthDate)) / 12
  }

  getDateOnAge(age: number): Date {
    const birthDate = isoToLocalDate(this.birthDate)
    return addYears(birthDate, age)
  }

  get birthYear(): number {
    if (this.birthDate) {
      return new Date(this.birthDate).getFullYear()
    } else {
      return 0
    }
  }

  get retireYear(): number {
    if (this.retireDate) {
      return new Date(this.retireDate).getFullYear()
    } else {
      return 0
    }
  }

  get retireAge(): number {
    if (this.birthDate && this.retireDate) {
      return differenceInYears(new Date(this.birthDate), new Date(this.retireDate))
    } else {
      return 0
    }
  }

  get earliestRetireDate(): string {
    if (!this.spouse) {
      return this.retireDate
    } else {
      return (this.retireDate <= this.spouse.retireDate) ? this.retireDate : this.spouse.retireDate
    }
  }

  get latestRetireDate(): string {
    if (!this.spouse) {
      return this.retireDate
    } else {
      return (this.retireDate >= this.spouse.retireDate) ? this.retireDate : this.spouse.retireDate
    }
  }

  get latestRetireYear() : number {
    if (!this.spouse) {
      return this.retireYear
    } else {
      return (Math.max(this.retireYear, this.spouse.retireYear))
    }
  }

  get lifeExpectancyDate(): string {
    if (this.hereditaryAdjust > 0 && this.birthDate) {
      const date = endOfYear(addYears(isoToLocalDate(this.birthDate), this.hereditaryAdjust))
      return getISODateFromDate(date)
    } else if (this.lifeExpectancy > 0 && this.birthDate) {
      const date = endOfYear(addYears(isoToLocalDate(this.birthDate), this.lifeExpectancy))
      return getISODateFromDate(date)
    } else {
      return ``
    }
  }

  get lifeExpectancyYear(): number {
    if (this.hereditaryAdjust > 0 && this.birthDate) {
      const date = addYears(isoToLocalDate(this.birthDate), this.hereditaryAdjust)
      return date.getFullYear()
    } else if (this.lifeExpectancy > 0 && this.birthDate) {
      const date = addYears(isoToLocalDate(this.birthDate), this.lifeExpectancy)
      return date.getFullYear()
    } else {
      return 0
    }
  }

  get latestLifeExpectancyYear() : number {
    if (!this.spouse) {
      return this.lifeExpectancyYear
    } else {
      return (Math.max(this.lifeExpectancyYear, this.spouse.lifeExpectancyYear))
    }
  }

  get earliestLifeExpectancyDate(): string {
    if (!this.spouse) {
      return this.lifeExpectancyDate
    } else {
      return (this.lifeExpectancyDate <= this.spouse.lifeExpectancyDate) ? this.lifeExpectancyDate : this.spouse.lifeExpectancyDate
    }
  }

  get latestLifeExpectancyDate(): string {
    if (!this.spouse) {
      return this.lifeExpectancyDate
    } else {
       return (this.lifeExpectancyDate >= this.spouse.lifeExpectancyDate) ? this.lifeExpectancyDate : this.spouse.lifeExpectancyDate
    }
  }

  static maleLifeExpectancy = [76.22, 75.69, 74.72, 73.74, 72.76, 71.77, 70.78, 69.79, 68.79, 67.8, 66.81, 65.82, 64.82, 63.83, 62.85, 61.87, 60.89, 59.93, 58.97, 58.01, 57.07, 56.13, 55.2, 54.27, 53.35, 52.42, 51.5, 50.58, 49.66, 48.74, 47.83, 46.91, 46, 45.09, 44.18, 43.27, 42.36, 41.45, 40.55, 39.64, 38.74, 37.84, 36.94, 36.04, 35.15, 34.26, 33.37, 32.49, 31.61, 30.74, 29.88, 29.02, 28.18, 27.34, 26.51, 25.69, 24.89, 24.09, 23.31, 22.53, 21.77, 21.01, 20.27, 19.54, 18.81, 18.09, 17.37, 16.67, 15.97, 15.28, 14.59, 13.91, 13.25, 12.59, 11.95, 11.32, 10.71, 10.11, 9.54, 8.97, 8.43, 7.9, 7.39, 6.91, 6.44, 6, 5.57, 5.17, 4.8, 4.45, 4.12, 3.82, 3.54, 3.29, 3.06, 2.86, 2.69, 2.54, 2.4, 2.28, 2.16, 2.05, 1.94, 1.83, 1.73, 1.63, 1.54, 1.45, 1.37, 1.28, 1.21, 1.13, 1.06, 0.99, 0.92, 0.86, 0.8, 0.74, 0.68, 0.63]
  static femaleLifeExpectancy = [81.28, 80.69, 79.72, 78.73, 77.75, 76.76, 75.77, 74.77, 73.78, 72.79, 71.8, 70.8, 69.81, 68.82, 67.83, 66.84, 65.85, 64.87, 63.89, 62.91, 61.93, 60.95, 59.98, 59.01, 58.04, 57.07, 56.11, 55.14, 54.17, 53.21, 52.25, 51.29, 50.34, 49.38, 48.43, 47.48, 46.53, 45.59, 44.64, 43.7, 42.76, 41.82, 40.88, 39.95, 39.01, 38.08, 37.16, 36.24, 35.32, 34.41, 33.5, 32.6, 31.71, 30.82, 29.93, 29.06, 28.19, 27.33, 26.48, 25.63, 24.79, 23.96, 23.14, 22.32, 21.51, 20.7, 19.89, 19.1, 18.31, 17.52, 16.75, 16, 15.25, 14.52, 13.8, 13.1, 12.41, 11.74, 11.08, 10.45, 9.83, 9.23, 8.65, 8.09, 7.56, 7.05, 6.56, 6.1, 5.67, 5.26, 4.88, 4.52, 4.2, 3.9, 3.63, 3.39, 3.17, 2.98, 2.81, 2.65, 2.49, 2.34, 2.2, 2.07, 1.94, 1.82, 1.7, 1.59, 1.48, 1.38, 1.28, 1.19, 1.1, 1.02, 0.94, 0.87, 0.8, 0.74, 0.68, 0.63];

  getLifeExpectancyYears() {
    const ageInYears = this.getAge()
    const herAdjust = this.hereditaryAdjust
    const isMale = this.gender === Gender.Male

    // 2019 Update from https://www.ssa.gov/oact/STATS/table4c6.html
    var lifeExpYears = 0;
    if (herAdjust <= 0) {
      if (ageInYears >= 0 && ageInYears < 120) {
        if (isMale) {
          lifeExpYears = Person.maleLifeExpectancy[ageInYears];
        } else {
          lifeExpYears = Person.femaleLifeExpectancy[ageInYears];
        }
      }
    } else {
      lifeExpYears = herAdjust - ageInYears;
      if (lifeExpYears < 0) {
        lifeExpYears = 0;
      }
    }
    return Math.round(lifeExpYears) + ageInYears;
  }

  toJSON(): IPerson {
    return {
      id: this.id,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      accountId: this.accountId,
      userId: this.userId,
      modelId: this.modelId,
      nickname: this.nickname,
      gender: this.gender,
      maritalStatus: this.maritalStatus,
      birthDate: this.birthDate,
      retireDate: this.retireDate,
      hereditaryAdjust: this.hereditaryAdjust,
      lifeExpectancy: this.lifeExpectancy,
      state: this.state
    }
  }

}

export default Person