import {Box, Checkbox, FormControlLabel, Grid, Typography, useTheme} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import TextFieldValidator from "../../../components/form/TextFieldValidator";
import {humanizeString, numberToPercentFormat} from "../../../stores/StoreUtilities";
import {CreatePlanChangeInput, UpdatePlanChangeInput} from "../../../API";
import {useResources} from "../../../stores/ResourceProvider";
import Plan from "../../../model/Plan";
import GrowthStrategy, {getGrowthModelLabel, GrowthModel, ReturnSequence} from "../../../model/GrowthStrategy";
import Asset, {AssetCategory} from "../../../model/Asset";
import Model from "../../../model/Model";
import GrowthStrategyChange from "../../../model/changes/GrowthStrategyChange";
import PlanChange, {PlanChangeType} from "../../../model/PlanChange";
import ModelEditDialog from "../../../components/model/ModelEditDialog";
import Visible from "../../../components/Visible";
import SimpleGrowth from "../../../images/SimpleGrowth.png"
import AgressivePie from "../../../images/7030pie.png"
import BalancedPie from "../../../images/6040pie.png"
import ModeratePie from "../../../images/5050pie.png"
import Tracking from "../../../components/Tracking";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";

const GrowthStrategyDialog = ({
  target,
  plan,
  model,
  onClose,
  onSave
}: {
  target? : any // React.MouseEvent<HTMLElement>
  plan: Plan
  model: Model
  onClose?(): void
  onSave?(plan: Plan, update: PlanChange): void
}) => {
  const [planModel, setPlanModel] = useState<Plan>(plan)
  const [editTarget, setEditTarget] = useState<any | undefined>()
  const [growthStrategyChange, setGrowthStrategyChange] = useState<GrowthStrategyChange | undefined>()
  const [name, setName] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [growthModel, setGrowthModel] = useState<GrowthModel>(GrowthModel.Simple)
  const [returnSequence, setReturnSequence] = useState<ReturnSequence>(ReturnSequence.Historical)
  const [growthRate, setGrowthRate] = useState<string>()
  const [ignoreCustomGrowthRates, setIgnoreCustomGrowthRates] = useState<boolean>(false)
  const [surplusAssetId, setSurplusAssetId] = useState<string>()
  const [surplusAsset, setSurplusAsset] = useState<Asset | undefined>()
  const [spendSurplusAsset, setSpendSurplusAsset] = useState<Asset>(new Asset({id: "spend", description: "Spend Surplus"}))

  const theme = useTheme()
  const { modelStore, notify } = useResources()

  const styles = {
    actions: {
      display:"flex",
      flexDirection:"row",
      width:"100%",
      justifyContent:"flex-end",
      alignItems:"center",
      marginTop: theme.spacing(1)
    },
    row: {
      maxHeight: 48
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"]
    },
    stateField: {
      top: 5,
      width: "100%"
    },
    detailText: {
      fontSize: 14
    }
  }

  useEffect(() => {
    setPlanModel(plan)
    setEditTarget(target)
    let change: GrowthStrategyChange = plan.getChange(PlanChangeType.GrowthStrategy) as GrowthStrategyChange
    if (!change) {
      change = new GrowthStrategyChange({
        accountId: plan.accountId,
        userId: model.userId,
        modelId: model.id,
        planId: plan.id,
        changeType: PlanChangeType.GrowthStrategy,
        name: "Growth Strategy",
        description: "",
        enabled: true,
      })
    }
    setGrowthStrategyChange(change)
    setName("Growth Strategy")
    setDescription(change.description)
    const gs = change.growthStrategy
    setGrowthModel(gs.growthModel)
    setReturnSequence(gs.returnSequence)
    setGrowthRate(gs.growthRate ? numberToPercentFormat(gs.growthRate * 100, 2, 'decimal') : undefined)
    setIgnoreCustomGrowthRates(gs.ignoreCustomGrowthRates === true)
    if (gs.surplusAssetId) {
      const asset = model.assets.find((a: Asset) => a.id === gs.surplusAssetId)
      if (asset) {
        setSurplusAssetId(asset.id)
      }
      setSurplusAsset(asset)
    } else {
      setSurplusAssetId(spendSurplusAsset.id)
      setSurplusAsset(spendSurplusAsset)
    }
  }, [plan, target])

  const handleClose = async (event: any) => {
    if (onClose) {
      onClose()
    }
  }

  const handleSave = async (event: any) => {
    try {
      const growthStrategy: GrowthStrategy = {
        growthModel: growthModel,
        returnSequence: returnSequence,
        growthRate: growthRate ? parseFloat(growthRate) / 100.0 : 0.04,
        ignoreCustomGrowthRates: ignoreCustomGrowthRates,
        surplusAssetId: surplusAssetId !== spendSurplusAsset.id ? surplusAssetId : undefined
      }

      let change: PlanChange | undefined

      if (growthStrategyChange && !growthStrategyChange.id) {
        const input: CreatePlanChangeInput = {
          accountId: growthStrategyChange?.accountId,
          userId: growthStrategyChange.userId,
          modelId: growthStrategyChange.modelId,
          planId: growthStrategyChange.planId,
          changeType: growthStrategyChange.changeType,
          name: name ?? growthStrategyChange.name,
          description: generateDescription(),
          enabled: true,
          details: JSON.stringify(growthStrategy)
        }
        change = await modelStore.createPlanChange(input)
        Tracking.event({action: "Growth Strategy Created"})
      } else if (growthStrategyChange) {
        const input: UpdatePlanChangeInput = {
          id: growthStrategyChange.id,
          name: name,
          description: generateDescription(),
          enabled: true,
          details: JSON.stringify(growthStrategy)
        }
        change = await modelStore.updatePlanChange(input)
        Tracking.event({action: "Growth Strategy Updated"})
      }

      if (onSave && change) {
        onSave(plan, change)
      } else if (onClose) {
        onClose()
      }
    } catch (err: any) {
      notify.show('error', err.message)
    }
  }

  const generateDescription = () => {
    let desc = ""
    if (growthModel === GrowthModel.Simple) {
      desc = `Simple ${growthRate}% ${ignoreCustomGrowthRates ? 'override' : 'default'} growth`
    } else {
      desc = `${growthModel}/${humanizeString(returnSequence)} ${ignoreCustomGrowthRates ? 'override' : 'default'} growth`
    }
    if (surplusAsset) {
      if (surplusAsset.id === 'spend') {
        desc += `, ${surplusAsset.description}`
      } else {
        desc += `, Surplus into ${surplusAsset.description}`
      }
    }
    return desc
  }

  if (!growthStrategyChange) {
    return null
  }

  return (
    <ModelEditDialog title="Growth Strategy" open={true} size="md"
                     onCancel={handleClose}
                     onSave={handleSave}
    >
      <Grid container>
        <Grid item xs={12} sm={6}>
            <TextFieldValidator
              type="text"
              validators={{ required: true }}
              required
              name="growthModel"
              label="Growth Model"
              variant="standard"
              autocompleteOptions={{
                freeSolo: false,
                options: Object.values(GrowthModel),
                getOptionLabel: (option: GrowthModel) => {
                  // return "Label"
                  return(getGrowthModelLabel(option))
                },
                value: growthModel,
                onChange: (event: any, value: GrowthModel, reason: any) => {
                  setGrowthModel(value)
                  if (value !== GrowthModel.Simple) {
                    setIgnoreCustomGrowthRates(true)
                  }
                }
              }}
            />
            {growthModel !== GrowthModel.Simple &&
              <TextFieldValidator
                type="text"
                validators={{ required: true }}
                required
                name="returnSequence"
                label="Sequence of Returns"
                variant="standard"
                autocompleteOptions={{
                  freeSolo: false,
                  options: Object.values(ReturnSequence),
                  getOptionLabel: (option: ReturnSequence) => {
                    // return "Label"
                    return(humanizeString(option))
                  },
                  value: returnSequence,
                  onChange: (event: any, value: ReturnSequence, reason: any) => {
                    setReturnSequence(value)
                  }
                }}
              />
            }
            {growthModel === GrowthModel.Simple &&
              <TextFieldValidator
                type="number"
                validators={{ required: true, minValue: 0 }}
                name="growthRate"
                variant="standard"
                margin="dense"
                fullWidth
                label="Default Return Rate %"
                value={growthRate}
                onChange={(event: any) => setGrowthRate(event.target.value)}
              />
            }
            <FormControlLabel value={ignoreCustomGrowthRates}
                              label={<Typography variant="body2">Lock return rates <br/><em>(Overrides unlocked liquid asset return rates)</em></Typography>}
                              control={
                                <Checkbox name="ignoreCustomGrowthRates" value={true}
                                          checked={ignoreCustomGrowthRates}
                                          icon={<LockOpenIcon />}
                                          checkedIcon={<LockIcon />}
                                          onClick={(event: any) => setIgnoreCustomGrowthRates(event.target.checked)}
                                />}
            />
            <TextFieldValidator
              type="text"
              validators={{ required: false }}
              // required
              name="surplusAsset"
              label="Yearly Surplus Handling"
              variant="standard"
              helperText="How to handle yearly surplus income"
              autocompleteOptions={{
                freeSolo: false,
                disableClearable: true,
                options: [spendSurplusAsset, ...model.getAssetsByCategory(AssetCategory.LiquidInvestableAssets)],
                getOptionLabel: (option: Asset) => {
                  if (option?.id === 'spend') {
                    return option.description
                  } else if (option) {
                    return `Invest into ${option.description}`
                  } else {
                    return ""
                  }
                },
                isOptionEqualToValue: (option: Asset, value: Asset) => option.id === value.id,
                value: surplusAsset,
                onChange: (event: any, value: Asset, reason: any) => {
                  setSurplusAsset(value)
                  setSurplusAssetId(value.id ?? undefined)
                }
              }}
            />
            {/*<TextFieldValidator*/}
            {/*  margin="normal"*/}
            {/*  name="description"*/}
            {/*  label="Description"*/}
            {/*  type="text"*/}
            {/*  fullWidth*/}
            {/*  variant="standard"*/}
            {/*  validators={{ required: false }}*/}
            {/*  value={description}*/}
            {/*  onChange={(event: any) => setDescription(event.target.value)}*/}
            {/*/>*/}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Visible cond={growthModel === GrowthModel.Simple}>
            <Grid container>
              <Grid item xs={4} textAlign="center">
                <img src={SimpleGrowth} style={{width:"80%", marginTop:"4px"}}/>
                {/*<Box sx={{width:"80%", marginTop:"4px"}}>*/}
                {/*  <TrendingFlatIcon sx={{width:"100%", height:"50%"}}/>*/}
                {/*</Box>*/}
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h5">
                  Simple Growth
                </Typography>
                <Typography variant="body2" style={styles.detailText}>
                  The Simple Growth Model represents a simple interest rate that compounds annually. Select the rate that best represents what you think the average will be over the remainder of the future years.
                </Typography>
              </Grid>
            </Grid>
          </Visible>
          <Visible cond={growthModel === GrowthModel.Aggressive}>
            <Grid container>
              <Grid item xs={4} textAlign="center">
                <img src={AgressivePie} style={{width:"80%", marginTop:"4px"}}/>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h5">
                  Aggressive - 70/30 Stocks/Bonds
                </Typography>
                <Typography variant="body2" style={styles.detailText}>
                  This model represents an aggressive blend of stocks (70% of the portfolio as represented by a U.S. large cap stock index) and bonds (30% of the portfolio as represented by intermediate term U.S. Treasury bonds) and assumes annual re-balancing, dividends re-invested and no fees.
                </Typography>
              </Grid>
            </Grid>
          </Visible>
          <Visible cond={growthModel === GrowthModel.Balanced}>
            <Grid container>
              <Grid item xs={4} textAlign="center">
                <img src={BalancedPie} style={{width:"80%", marginTop:"4px"}}/>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h5">
                  Balanced - 60/40 Stocks/Bonds
                </Typography>
                <Typography variant="body2" style={styles.detailText}>
                  This model represents a balanced blend of stocks (60% of the portfolio as represented by a U.S. large cap stock index) and bonds (40% of the portfolio as represented by intermediate term U.S. Treasury bonds) and assumes annual re-balancing, dividends re-invested and no fees.
                </Typography>
              </Grid>
            </Grid>
          </Visible>
          <Visible cond={growthModel === GrowthModel.Moderate}>
            <Grid container>
              <Grid item xs={4} textAlign="center">
                <img src={ModeratePie} style={{width:"80%", marginTop:"4px"}}/>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h5">
                  Moderate - 50/50 Stocks/Bonds
                </Typography>
                <Typography variant="body2" style={styles.detailText}>
                  This model represents a moderate blend of stocks (50% of the portfolio as represented by a U.S. large cap stock index) and bonds (50% of the portfolio as represented by intermediate term U.S. Treasury bonds) and assumes annual re-balancing, dividends re-invested and no fees.
                </Typography>
              </Grid>
            </Grid>
          </Visible>
          <Visible cond={growthModel !== GrowthModel.Simple && returnSequence === ReturnSequence.Historical}>
            <Grid container>
              <Grid item xs={4} textAlign="center">
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h5">
                  Historical Sequence of Returns
                </Typography>
                <Typography variant="body2" style={styles.detailText}>
                  It uses the historic sequence of returns starting from 1978 and projects those returns forward. Year one in the plan below would represent the returns from 1978, year two would be the returns from 1979 and so on for 40 years. The phrase "history repeats itself" is the operating concept for this sequence.
                </Typography>
              </Grid>
            </Grid>
          </Visible>
          <Visible cond={growthModel !== GrowthModel.Simple && returnSequence === ReturnSequence.BadTiming}>
            <Grid container>
              <Grid item xs={4} textAlign="center">
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h5">
                  Bad Timing Sequence of Returns
                </Typography>
                <Typography variant="body2" style={styles.detailText}>
                  When selecting bad timing sequence of returns the calculator uses the years 2000 - 2018 as the starting point to represent a time period where the negative returns occur during the first three years of retirement and also includes the negative returns of the financial crisis of 2008. Year one below would represent the year 2000 returns. After the return sequence from 2000 - 2018 we then assume historical returns from the year 1978 and project that return sequence for the remainder of the plan. The phrase "bad timing sequence of returns" is the operating concept for this sequence.
                </Typography>
              </Grid>
            </Grid>
          </Visible>
        </Grid>
      </Grid>

    </ModelEditDialog>
  )
}

export default GrowthStrategyDialog