import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import { inject } from "mobx-react";
import * as React from 'react';
import {SxProps, Theme} from "@mui/material/styles";

export const usaStates = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
];

interface IStateSelectorProps {
    formvalidator?: any
    onChange?: any
    label?: string
    value?: string
    sx?: SxProps<Theme>
    isRequired?: boolean
    name?: string
}

@inject("formvalidator")
class StateSelector extends React.Component<IStateSelectorProps> {

    state = {
      value: '',
      isValid: true,
    };

  componentDidMount() {
    const {formvalidator, value} = this.props as IStateSelectorProps
    if (formvalidator) {
      formvalidator.attachToForm(this)
    }

    this.setState({value})
  }

  handleChange = (event: any) => {
        this.setState({ value: event.target.value });
        this.validate(event.target.value)
        if (this.props.onChange) {
            this.props.onChange(event)
        }
    };

    validate = (value: string): boolean => {
      const isRequired = this.props.isRequired ?? true
      const isValid = !isRequired || (usaStates.indexOf(value) >= 0)
      this.setState({isValid})
      return isValid
    }

    render() {
        const { sx, label } = this.props

        return <FormControl
            variant="standard"
            sx={sx}>
            <InputLabel htmlFor="state-required">{label ?? "State"}</InputLabel>
            <Select
                native
                value={this.state.value}
                variant="standard"
                margin="dense"
                fullWidth
                onChange={this.handleChange}
                inputProps={{
                    name: this.props.name ?? 'state',
                    id: 'state-required',
                }}
            >
                <option value="" />
                {usaStates.map((usaState) => <option key={usaState} value={usaState}>{usaState}</option>)}
            </Select>
            <FormHelperText error={!this.state.isValid}>
              {this.state.isValid ? "" : "Required"}
            </FormHelperText>
        </FormControl>
    }
}

export default StateSelector
