import * as React from "react";
import {inject} from "mobx-react";
import {IFieldValidator} from "./FormValidator";
import {
  DateField,
  DateFieldProps,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {FormControlProps} from "@mui/material/FormControl";

// TODO: When needed
// interface IDateFieldValidations {
//   required?: boolean
//   minDate?: string
//   maxDate?: string
// }

interface IDateFieldValidatorProps {
  name: string
  // error?: boolean
  helperText?: React.ReactNode
  label?: React.ReactNode
  // inputRef?: any
  required?: boolean
  // validators?: IDateFieldValidations
  // errorMessages?: any
  // onBlur?: any // React.EventHandler<any>
  onChange?: any // React.EventHandler<any>
  formvalidator?: any
  validate?: any
  value: Date | null | undefined
  format: string
  variant?: "standard" | "filled" | "outlined"
  // placeholder?: string
  margin?: 'dense' | 'none' | 'normal'
  fullWidth?: boolean
  // autoOk?: boolean
  id?: string
  // renderInput?: any
}

export type DateFieldalidatorProps = DateFieldProps<Date> & IDateFieldValidatorProps & IFieldValidator


@inject("formvalidator")
class DateFieldValidator extends React.Component<DateFieldalidatorProps > {

  state = {
    isValid: true,
    date: null,
    errorMessage: ""
  }

  componentDidMount() {
    const {formvalidator, value} = this.props as IDateFieldValidatorProps
    if (formvalidator) {
      formvalidator.attachToForm(this)
    }
    this.setState({isValid: true, date: value, errorMessage: ""})
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.value !== prevProps.value) {
      this.setState({date: this.props.value})
    }
  }


  render() {
    const {
      // inputRef,
      // error,
      // onBlur,
      onChange,
      // value,
      required,
      // renderInput,
      // variant,
      fullWidth,
      // helperText,
      ...rest
    } = this.props as IDateFieldValidatorProps;

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateField
          {...rest}
          value={this.state.date}
          onChange={this.onChange}
        />
      </LocalizationProvider>
    )
  }

  private onBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    const { props } = this
    const { onBlur } = props as FormControlProps

    this.validate(value)

    if (onBlur) {
      onBlur(event);
    }
  }

  private onChange = (value: Date | null, context: any): void => {
    const { props } = this
    const { onChange } = props // as FormControlProps

    // console.log(`onChange(${date?.toLocaleString()}, ${value})`)
    //
    // if (date && !isNaN(date)) {
    //   value = getISODateFromDate(date)
    //   this.setState({date: date})
    // }
    //
    // if (!this.state.isValid) {
    //   this.validate(value)
    // }
    //
    // if (onChange) {
    //   const event = {
    //     target: {
    //       name: props.name,
    //       value
    //     }
    //   }
    //   onChange(event);
    // }

    if (onChange) {
      onChange(value)
    }
  }

  validate = (value: string | null | undefined): boolean => {
    const { required } = this.props
    let isValid = true
    let errorMessage = ""
    if (required && (value === undefined || value === "")) {
      isValid = false
      errorMessage = `${this.props.label} is required`
    }

    this.setState({isValid, errorMessage})

    return isValid
  }

}

export default DateFieldValidator