import {
  addDays,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  format, startOfDay, startOfYear
} from "date-fns";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { v4 as uuid } from 'uuid';

// UUID 

export function createUUID() {
  return uuid()
}

export function isUUID(s: string) {
  return /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i.test(s)
}

// Date & Time 

export function getISODateFromDate(date: Date): string {
  const adjDate = new Date(date)
  // Adjust by time zone offset to make sure the date string doesn't shift
  adjDate.setMinutes(-adjDate.getTimezoneOffset())
  return adjDate.toISOString().substr(0, 10)
}

export function getISODateFromDateString(date: string | null | undefined): string {
  if (date) {
    const adjDate = new Date(date)
    return adjDate.toISOString().substr(0, 10)
  } else {
    return ""
  }
}

export function getISODateTimeFromDate(date: Date): string {
  return date.toISOString()
}

export function getDateToday(): Date {
  return startOfDay(new Date())
}

export function getISODateToday(): string {
  const date = startOfDay(new Date())
  return getISODateFromDate(date)
}

export function getISODateTodayAddDays(days: number): string {
  let date = new Date()
  date = addDays(date, days)
  return getISODateFromDate(date)
}

export function getISODateTime(): string {
  const date = new Date()
  return date.toISOString()
}

export function getISODateStartOfYear(): string {
  const date = startOfYear(new Date())
  return getISODateFromDate(date)
}

export function durationBetweenISODates(isoStartDate: string, isoEndDate?: string): string {
  const startString = isoToLocalDateString(isoStartDate)
  const startDate = isoToLocalDate(isoStartDate)
  if (isoEndDate) {
    const endString = isoToLocalDateString(isoEndDate)
    const endDate = isoToLocalDate(isoEndDate)
    let differenceMonths = differenceInCalendarMonths(endDate, startDate)
    let differenceString 
    if (differenceMonths === 0) {
      let differenceDays = differenceInCalendarDays(endDate, startDate)
      if (differenceDays === 1) {
        differenceString = `1 Day`
      } else {
        differenceString = `${differenceDays} Days`
      }
    } else if (differenceMonths === 1) {
      differenceString = `1 Month`
    } else {
      differenceString = `${differenceMonths} Months`
    }
    return `${startString} - ${endString} (${differenceString})`
  } else {
    return `${startString}`
  }
}

export function isoToLocalDate(isoDate: string) {
  const date = new Date(isoDate)
  if (isoDate && isoDate.length <= 10) {
    // Adjust for time zone if date only
    date.setMinutes(date.getTimezoneOffset()) // This should be automatic with UTC
  }
  return date
}

export function isoToLocalDateString(isoDate?: string, dateFormat: string = "M/d/yyyy") {
  if (isoDate) {
    const date = new Date(isoDate)
    if (isoDate.length <= 10) {
      // Adjust for time zone if date only
      date.setMinutes(date.getTimezoneOffset()) // This should be automatic with UTC
    }
    return format(date, dateFormat)
  } else {
    return ""
  }
}

export function dateToLocalFormat(date?: Date, dateFormat: string = "M/d/yyyy") {
  if (date) {
    return format(date, dateFormat)
  } else {
    return ("")
  }
}

export function isoToLocalDateTime(isoDateTime: string) {
  const date = new Date(isoDateTime)
  return date
}

export function timestampToISOString(timestamp: number): string {
  if (timestamp) {
    const date = new Date(timestamp * 1000)
    return date.toISOString()
  } else {
    return ""
  }
}

export function isISODate(value?: string): boolean {
  if (!value) {
    return false
  }
  const regex = /^\d{4}-\d{2}-\d{2}/
  return value.search(regex) === 0
}

export function pad(num: number, size: number) {
  let padded = num.toString();
  while (padded.length < size) padded = "0" + padded;
  return padded;
}

// Error

export function getErrorMessage(err: any) {
  if (err.response && err.response.data && err.response.data.details && err.response.data.details.raw && err.response.data.details.raw.message) {
    return err.response.data.details.raw.message
  } else if (err.response && err.response.data && err.response.data.message) {
    return err.response.data.message
  } else if (err.message) {
    return err.message
  } else {
    return "Unknown error"
  }
}

// Phone 

export function phoneToE164Format(phone: string): string | null {
  const phoneNumber = parsePhoneNumberFromString(phone, "US")
  if (phoneNumber && phoneNumber.isValid()) {
    return phoneNumber.number.toString()
  } else {
    return null
  }
}

export function phoneToNationalFormat(phone: string): string {
  if (phone) {
    const phoneNumber = parsePhoneNumberFromString(phone, "US")
    if (phoneNumber && phoneNumber.isValid()) {
      return phoneNumber.formatNational()
    }
  }

  return phone
}

// Number

export function numberToStringFormat(value?: number, fractionDigits: number = 2): string {
  if (value === undefined) {
    return ""
  }
  if (typeof value === "string") {
    return value
  }
  // TODO: May want to use a library called "numeral".
  // return `${ value ? numeral(value).format('$0,0.00') : '...' }`

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    useGrouping: false,
    // minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  })

  if (value === -0.00) {
    value = 0.00
  }
  const result = formatter.format(value)
  return result
}

export function ageToStringFormat(value?: number): string {
  if (value === undefined) {
    return ""
  }
  if (typeof value === "string") {
    return value
  }
  if (isNaN(value)) {
    return ""
  }

  const years = Math.trunc(value)
  const months = Math.round((value - years) * 12)
  if (months === 0) {
    return String(years)
  } else {
    return `${years}+${months} mo`
  }
}

// Money 

export function numberToMoneyFormatOrLoading(input?: number): string {
  // return `${ input ? numeral(input).format('$0,0.00') : '...' }`
  return `${ input !== undefined ? numberToMoneyFormat(input) : '...' }`
}

export function numberToMoneyFormat(value?: number, fractionDigits: number = 2): string {
  if (value === undefined) {
    return ""
  }
  // TODO: May want to use a library called "numeral". 
  // return `${ value ? numeral(value).format('$0,0.00') : '...' }`

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  })

  if (value === -0.00) {
    value = 0.00
  }
  const result = formatter.format(value)
  return result
}

export function moneyToNumberFormat(text?: string, fractionDigits: number = 2): number {
  // TODO: May want to use a library called "numeral". 
  // numeral(text).value()

  let result = 0

  if (text) {
    // const cleaned = text.replace(/[\$\d.,-]/g, '')
    // const cleaned = text.replace(/[^\$\d.-]/g, '')
    const cleaned = text.replace(/[$,]/g, "")

    const value = Number(cleaned)
    if (!isNaN(value)) {
      if (fractionDigits === 0) {
        result = Math.trunc(value)
      } else {
        result = Math.round(value * (10 ^ fractionDigits)) / (10 ^ fractionDigits)
      }
    }
  }

  return result
}

export function numberToPercentFormat(value: number, fractionDigits: number = 2, style: string = "percent"): string {

  const formatter = new Intl.NumberFormat('en-US', {
    style: style,
    minimumFractionDigits: 0,
    maximumFractionDigits: fractionDigits
  })

  if (value === -0.00) {
    value = 0.00
  }
  return formatter.format(value)
}

export function roundMoney(amount: number) {
  return (Math.round(amount * 100) / 100)
}

export function truncMoney(amount: number) {
  return Math.trunc(amount)
}

// String

export function humanizeString(input: string): string {
  if (typeof input === 'string') {
    input = decamelize(input, ' ');
    input = titleCase(input)
  }

  return input;
}

export function decamelize(text: string, separator: string = '_'): string {
  return text
    .replace(/([\p{Lowercase_Letter}\d])(\p{Uppercase_Letter})/gu, `$1${separator}$2`)
    .replace(/(\p{Uppercase_Letter}+)(\p{Uppercase_Letter}\p{Lowercase_Letter}+)/gu, `$1${separator}$2`)
    .toLowerCase();
}

export function titleCase(str: string): string {
  return str.toLowerCase().split(' ').map(function (word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

export function getExt(filename: string) {
  return filename.substr(filename.lastIndexOf('.')+1)
}

export function charCount(s: string, c: string) {
  let count = 0;
  c = c.charAt(0);
  for(let i = 0; i < s.length; ++i) {
    if(c === s.charAt(i)) {
      ++count;
    }
  }
  return count;
}

// Truncate to the number of words to show before we show an ellipses.
export function truncateString(input: string, wordsLimit: number) {
  let truncated = input.split(" ").splice(0, wordsLimit).join(" ")
  truncated = `${truncated} ...` // Add ellipses to the end.
  return truncated 
}

const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export function getShortMonthNames() {
  return shortMonthNames
}

export function getShortMonthName(month?: number) {
  return (month !== undefined && month >= 0 && month <= 12) ? shortMonthNames[month] : ""
}

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export function getMonthNames() {
  return monthNames
}

export function getMonthName(month?: number) {
  return (month !== undefined && month >= 0 && month <= 12) ? monthNames[month] : ""
}

// Array

export function arrayMove(array: any[], srcIndex: number, dstIndex: number) {
  const [removed] = array.splice(srcIndex, 1)
  array.splice(dstIndex, 0, removed)
  return array
}

// Code Generation

export function generateUniqueCode(length: number) {
  const characters = 'abcdefghijklmnopqrstuvwxyz012345689';
  let result = '';
  const charactersLength = characters.length;
  for(let i = 0; i < length; i++) {
    result +=
      characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result
}

