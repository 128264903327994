import User from "./User";

class Account {
  id: string
  createdAt: string
  updatedAt: string
  ownerId: string
  name: string
  address: string
  city: string
  state: string
  postalCode: string
  users: User[]

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.ownerId = data.ownerId
    this.name = data.name
    this.address = data.address
    this.city = data.city
    this.state = data.state
    this.postalCode = data.postalCode
    this.users = []
    if (data.users && data.users.items) {
      this.loadUsers(data.users.items)
    }
  }

  loadUsers(items: any[]) {
    this.users = items.map((item: any) => new User(item))
  }

}

export default Account