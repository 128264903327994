import {IBudgetCalc, IBudgetItemDetail} from "../../../../components/calculator/Calculator";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography, useTheme
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {numberToMoneyFormat} from "../../../../stores/StoreUtilities";
import {compareAsc, endOfMonth, endOfYear, format} from "date-fns";
// import IconicButton from "../../../../components/controls/IconicButton";
// import CancelIcon from "@mui/icons-material/Cancel";
import {Link} from "@reach/router";
import {useResources} from "../../../../stores/ResourceProvider";


const MonthlyBudgetTable = ({
  budgetCalc,
  month,
  onChange
}: {
  budgetCalc: IBudgetCalc
  month?: number
  onChange?: (month?: number) => any
}) => {
  const [currentMonth, setCurrentMonth] = useState<number | undefined>()
  const [isFree, setIsFree] = useState<boolean>(false)

  const { userStore } = useResources()

  const theme = useTheme()
  const styles = {
    link: {
      color: theme.palette.primary.main,
    }
  }

  useEffect(() => {
    if (month !== undefined) {
      setCurrentMonth(month)
    }
    setIsFree(userStore.isFree)
  }, [month])

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  const handleChange = (month?: number) => {
    setCurrentMonth(month)
    if (onChange) {
      onChange(month)
    }
  }

  const getNetIncomeForMonth = (month: number) => {
    const netIncome = budgetCalc.incomeSummary.byMonth[month] -
      budgetCalc.deductionSummary.byMonth[month] -
      (isFree ? budgetCalc.taxSummary.byMonth[month] : budgetCalc.totalEstimatedTaxes / 12) -
      budgetCalc.expenseSummary.byMonth[month]
    return netIncome
  }

  const renderDetailView = () => {
    if (currentMonth === undefined || !budgetCalc || budgetCalc.details.length === 0) {
      return null
    }
    const year = budgetCalc.year

    let title = ""
    let startDate: Date
    let endDate: Date
    if (currentMonth >= 0) {
      startDate = new Date(year, currentMonth, 1)
      endDate = endOfMonth(startDate)
      title = `${format(startDate, "MMMM yyyy")} Budget Details`
    } else {
      startDate = new Date(year, 0, 1)
      endDate = endOfYear(startDate)
      title = `${year} Budget Details`
    }

    const filtered = budgetCalc.details.filter((item: IBudgetItemDetail) => {
        if (compareAsc(startDate, item.date) <= 0 && compareAsc(endDate, item.date) > 0) {
          return true
        }
      }
    )

    return (
      <Box display="flex" flexGrow={1} flexDirection="column" mt={2}>
        <Box display="flex" flexGrow={1} justifyContent="flex-start" maxHeight={40} mb={1}>
            <Typography variant="h3" color="primary">{title}</Typography>
            {/*<IconicButton onClick={() => handleChange(undefined)}>*/}
            {/*  <CancelIcon/>*/}
            {/*</IconicButton>*/}
        </Box>
          <TableContainer component={Paper} sx={{paddingTop:0}}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell width="15%">Date</TableCell>
                      <TableCell width="10%">Type</TableCell>
                      <TableCell width="15%" align="center">Discretionary</TableCell>
                      <TableCell width="40%">Description</TableCell>
                      <TableCell width="20%" align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filtered.map((detail: IBudgetItemDetail, index: number) =>
                      <TableRow key={`row${index}`}>
                        <TableCell>{format(detail.date, "MM-dd-yyyy")}</TableCell>
                        <TableCell sx={{whiteSpace:"nowrap"}}>{detail.itemType}</TableCell>
                        <TableCell align="center">{detail.discretionary === true ? "X" : ""}</TableCell>
                        <TableCell>{detail.description}</TableCell>
                        <TableCell align="right">{numberToMoneyFormat(detail.amount, 2)}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
          </TableContainer>
      </Box>
    )
  }

  if (currentMonth !== undefined) {
    return (
      renderDetailView()
    )
  } else {
    const taxLabel = isFree ? "Taxes Withheld" : "Estimated Taxes"

    return (
      <Box display="flex" flexGrow={1} flexDirection="column" mt={2}>
        <Box display="flex" flexGrow={1} justifyContent="space-between" maxHeight={40}>
          <Typography variant="h3" color="primary" gutterBottom>{budgetCalc.year} Monthly Budget Details</Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table size="small" width="100%">
            <TableHead>
              <TableRow>
                <TableCell>Month</TableCell>
                <TableCell align="right">Income</TableCell>
                <TableCell align="right">Deductions</TableCell>
                <TableCell align="right">{taxLabel}</TableCell>
                <TableCell align="right">Essential Expenses</TableCell>
                <TableCell align="right">Discretionary Expenses</TableCell>
                <TableCell align="right">Surplus/Shortfall</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {months.map((monthName: string, month: number) =>
                <TableRow>
                  <TableCell>
                    <Link to="." style={styles.link}
                          onClick={() => {
                            handleChange(month)
                          }}>{monthName}</Link>
                  </TableCell>
                  <TableCell
                    align="right">{numberToMoneyFormat(budgetCalc.incomeSummary.byMonth[month], 0)}</TableCell>
                  <TableCell
                    align="right">{numberToMoneyFormat(budgetCalc.deductionSummary.byMonth[month], 0)}</TableCell>
                  <TableCell align="right">{numberToMoneyFormat(isFree ? budgetCalc.taxSummary.byMonth[month] : budgetCalc.totalEstimatedTaxes/12, 0)}</TableCell>
                  <TableCell
                    align="right">{numberToMoneyFormat(budgetCalc.expenseSummary.byMonthEssential[month], 0)}</TableCell>
                  <TableCell
                    align="right">{numberToMoneyFormat(budgetCalc.expenseSummary.byMonthDiscretionary[month], 0)}</TableCell>
                  <TableCell align="right">{numberToMoneyFormat(getNetIncomeForMonth(month), 0)}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
}

export default MonthlyBudgetTable