import {isoToLocalDate} from "../stores/StoreUtilities";

export enum MilestoneId {
  retirement1= "retirement1",
  retirement2 = "retirement2",
  earliestRetirement = "earliestRetirement",
  latestRetirement = "latestRetirement",
  lifeExpectancy1 = "lifeExpectancy1",
  lifeExpectancy2 = "lifeExpectancy2",
  earliestLifeExpectancy = "earliestLifeExpectancy",
  latestLifeExpectancy = "latestLifeExpectancy"
}

class Milestone {
  id: string
  label: string
  date: Date
  age: number

  constructor (data: any) {
    this.id = data.id
    this.label = data.label
    this.date = isoToLocalDate(data.date)
    this.age = data.age ?? 0
  }
}

export default Milestone