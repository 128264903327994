/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateAccountInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  ownerId?: string | null,
  name: string,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
};

export type ModelAccountConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  ownerId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Account = {
  __typename: "Account",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  ownerId?: string | null,
  name: string,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  users?: ModelUserConnection | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type User = {
  __typename: "User",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userType: UserType,
  userStatus: UserStatus,
  startDate?: string | null,
  endDate?: string | null,
  accountId: string,
  account?: Account | null,
  firstName: string,
  lastName: string,
  email: string,
  phone?: string | null,
  mfaPreference?: string | null,
  role: UserRole,
  advisorAccess?: boolean | null,
  lastAccess?: string | null,
  customerId?: string | null,
  currentModelId?: string | null,
  advisorModelId?: string | null,
  currentPlanId?: string | null,
  models?: ModelModelConnection | null,
  migrateStatus?: string | null,
  migratedOn?: string | null,
  subscribedOn?: string | null,
  cancelledOn?: string | null,
  referrer?: string | null,
  settings?: ModelUserSettingConnection | null,
};

export enum UserType {
  Lifetime = "Lifetime",
  Legacy = "Legacy",
  Premium = "Premium",
  Free = "Free",
  Client = "Client",
}


export enum UserStatus {
  Created = "Created",
  Invited = "Invited",
  Active = "Active",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
}


export enum UserRole {
  Admin = "Admin",
  Advisor = "Advisor",
  Customer = "Customer",
}


export type ModelModelConnection = {
  __typename: "ModelModelConnection",
  items:  Array<Model | null >,
  nextToken?: string | null,
};

export type Model = {
  __typename: "Model",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  name: string,
  description?: string | null,
  startedAt?: string | null,
  advisorCreated?: boolean | null,
  clientAccess?: ClientAccess | null,
  settings?: string | null,
  persons?: ModelPersonConnection | null,
  plans?: ModelPlanConnection | null,
  incomes?: ModelIncomeConnection | null,
  deductions?: ModelDeductionConnection | null,
  taxes?: ModelTaxConnection | null,
  expenses?: ModelExpenseConnection | null,
  assets?: ModelAssetConnection | null,
  liabilities?: ModelLiabilityConnection | null,
  snapshots?: ModelSnapshotConnection | null,
  taxValues?: ModelTaxValueConnection | null,
  assetConversions?: ModelAssetConversionConnection | null,
};

export enum ClientAccess {
  None = "None",
  View = "View",
  Edit = "Edit",
  Owner = "Owner",
}


export type ModelPersonConnection = {
  __typename: "ModelPersonConnection",
  items:  Array<Person | null >,
  nextToken?: string | null,
};

export type Person = {
  __typename: "Person",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  nickname?: string | null,
  gender?: Gender | null,
  maritalStatus?: MaritalStatus | null,
  birthDate?: string | null,
  hereditaryAdjust?: number | null,
  lifeExpectancy?: number | null,
  retireDate?: string | null,
  state?: string | null,
};

export enum Gender {
  Male = "Male",
  Female = "Female",
  Neutral = "Neutral",
}


export enum MaritalStatus {
  Single = "Single",
  Married = "Married",
}


export type ModelPlanConnection = {
  __typename: "ModelPlanConnection",
  items:  Array<Plan | null >,
  nextToken?: string | null,
};

export type Plan = {
  __typename: "Plan",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  name?: string | null,
  description?: string | null,
  enabled?: boolean | null,
  default?: boolean | null,
  changes?: ModelPlanChangeConnection | null,
};

export type ModelPlanChangeConnection = {
  __typename: "ModelPlanChangeConnection",
  items:  Array<PlanChange | null >,
  nextToken?: string | null,
};

export type PlanChange = {
  __typename: "PlanChange",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  planId: string,
  changeType: string,
  name: string,
  description?: string | null,
  enabled?: boolean | null,
  details?: string | null,
};

export type ModelIncomeConnection = {
  __typename: "ModelIncomeConnection",
  items:  Array<Income | null >,
  nextToken?: string | null,
};

export type Income = {
  __typename: "Income",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  incomeType?: number | null,
  description?: string | null,
  amount?: number | null,
  schedule?: string | null,
  infAdjust?: boolean | null,
  infLock?: boolean | null,
  annualInf?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  sortOrder?: number | null,
  ownerId?: string | null,
  survivorPercent?: number | null,
};

export type ModelDeductionConnection = {
  __typename: "ModelDeductionConnection",
  items:  Array<Deduction | null >,
  nextToken?: string | null,
};

export type Deduction = {
  __typename: "Deduction",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  amount?: number | null,
  schedule?: string | null,
  infAdjust?: boolean | null,
  infLock?: boolean | null,
  annualInf?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  description?: string | null,
  sortOrder?: number | null,
  assetId?: string | null,
};

export type ModelTaxConnection = {
  __typename: "ModelTaxConnection",
  items:  Array<Tax | null >,
  nextToken?: string | null,
};

export type Tax = {
  __typename: "Tax",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  taxType?: number | null,
  description?: string | null,
  amount?: number | null,
  schedule?: string | null,
  infAdjust?: boolean | null,
  infLock?: boolean | null,
  annualInf?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  sortOrder?: number | null,
  ownerId?: string | null,
};

export type ModelExpenseConnection = {
  __typename: "ModelExpenseConnection",
  items:  Array<Expense | null >,
  nextToken?: string | null,
};

export type Expense = {
  __typename: "Expense",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  expenseCategory?: number | null,
  description?: string | null,
  amount?: number | null,
  schedule?: string | null,
  infAdjust?: boolean | null,
  infLock?: boolean | null,
  annualInf?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  discretionary?: boolean | null,
  sortOrder?: number | null,
  ownerId?: string | null,
  assetId?: string | null,
  liabilityId?: string | null,
};

export type ModelAssetConnection = {
  __typename: "ModelAssetConnection",
  items:  Array<Asset | null >,
  nextToken?: string | null,
};

export type Asset = {
  __typename: "Asset",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  assetCategory?: number | null,
  assetType?: number | null,
  description?: string | null,
  risk?: number | null,
  balance?: number | null,
  balanceDate?: string | null,
  returnRate?: number | null,
  rateLock?: boolean | null,
  withdrawalOrder?: number | null,
  inheritedYear?: number | null,
  originalOwnerBirthYear?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  sortOrder?: number | null,
  ownerId?: string | null,
  priorEoYBalance?: number | null,
  withdrawalsYTD?: number | null,
};

export type ModelLiabilityConnection = {
  __typename: "ModelLiabilityConnection",
  items:  Array<Liability | null >,
  nextToken?: string | null,
};

export type Liability = {
  __typename: "Liability",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  description?: string | null,
  balance?: number | null,
  balanceDate?: string | null,
  payoffDate?: string | null,
  ownerId?: string | null,
  sortOrder?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  interestRate?: number | null,
};

export type ModelSnapshotConnection = {
  __typename: "ModelSnapshotConnection",
  items:  Array<Snapshot | null >,
  nextToken?: string | null,
};

export type Snapshot = {
  __typename: "Snapshot",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  date: string,
  description?: string | null,
  details?: string | null,
};

export type ModelTaxValueConnection = {
  __typename: "ModelTaxValueConnection",
  items:  Array<TaxValue | null >,
  nextToken?: string | null,
};

export type TaxValue = {
  __typename: "TaxValue",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  year: number,
  key: string,
  value: number,
};

export type ModelAssetConversionConnection = {
  __typename: "ModelAssetConversionConnection",
  items:  Array<AssetConversion | null >,
  nextToken?: string | null,
};

export type AssetConversion = {
  __typename: "AssetConversion",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  description?: string | null,
  conversionType?: string | null,
  srcAssetId: string,
  dstAssetId: string,
  year?: number | null,
  amount?: number | null,
  percent?: number | null,
  sortOrder?: number | null,
  completedAt?: string | null,
  taxableAmount?: number | null,
  nonTaxableAmount?: number | null,
  incomeType?: number | null,
};

export type ModelUserSettingConnection = {
  __typename: "ModelUserSettingConnection",
  items:  Array<UserSetting | null >,
  nextToken?: string | null,
};

export type UserSetting = {
  __typename: "UserSetting",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  name: string,
  description?: string | null,
  settingType?: string | null,
  settings?: string | null,
};

export type UpdateAccountInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  ownerId?: string | null,
  name?: string | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
};

export type DeleteAccountInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userType: UserType,
  userStatus: UserStatus,
  startDate?: string | null,
  endDate?: string | null,
  accountId: string,
  firstName: string,
  lastName: string,
  email: string,
  phone?: string | null,
  mfaPreference?: string | null,
  role: UserRole,
  advisorAccess?: boolean | null,
  lastAccess?: string | null,
  customerId?: string | null,
  currentModelId?: string | null,
  advisorModelId?: string | null,
  currentPlanId?: string | null,
  migrateStatus?: string | null,
  migratedOn?: string | null,
  subscribedOn?: string | null,
  cancelledOn?: string | null,
  referrer?: string | null,
};

export type ModelUserConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userType?: ModelUserTypeInput | null,
  userStatus?: ModelUserStatusInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  mfaPreference?: ModelStringInput | null,
  role?: ModelUserRoleInput | null,
  advisorAccess?: ModelBooleanInput | null,
  lastAccess?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  currentModelId?: ModelIDInput | null,
  advisorModelId?: ModelIDInput | null,
  currentPlanId?: ModelIDInput | null,
  migrateStatus?: ModelStringInput | null,
  migratedOn?: ModelStringInput | null,
  subscribedOn?: ModelStringInput | null,
  cancelledOn?: ModelStringInput | null,
  referrer?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelUserTypeInput = {
  eq?: UserType | null,
  ne?: UserType | null,
};

export type ModelUserStatusInput = {
  eq?: UserStatus | null,
  ne?: UserStatus | null,
};

export type ModelUserRoleInput = {
  eq?: UserRole | null,
  ne?: UserRole | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateUserInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userType?: UserType | null,
  userStatus?: UserStatus | null,
  startDate?: string | null,
  endDate?: string | null,
  accountId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phone?: string | null,
  mfaPreference?: string | null,
  role?: UserRole | null,
  advisorAccess?: boolean | null,
  lastAccess?: string | null,
  customerId?: string | null,
  currentModelId?: string | null,
  advisorModelId?: string | null,
  currentPlanId?: string | null,
  migrateStatus?: string | null,
  migratedOn?: string | null,
  subscribedOn?: string | null,
  cancelledOn?: string | null,
  referrer?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateUserSettingInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  name: string,
  description?: string | null,
  settingType?: string | null,
  settings?: string | null,
};

export type ModelUserSettingConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  settingType?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  and?: Array< ModelUserSettingConditionInput | null > | null,
  or?: Array< ModelUserSettingConditionInput | null > | null,
  not?: ModelUserSettingConditionInput | null,
};

export type UpdateUserSettingInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  accountId?: string | null,
  name?: string | null,
  description?: string | null,
  settingType?: string | null,
  settings?: string | null,
};

export type DeleteUserSettingInput = {
  id: string,
};

export type CreateUserInviteInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  userId: string,
  firstName: string,
  lastName: string,
  email: string,
  role: UserRole,
  invitedOn: string,
};

export type ModelUserInviteConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  role?: ModelUserRoleInput | null,
  invitedOn?: ModelStringInput | null,
  and?: Array< ModelUserInviteConditionInput | null > | null,
  or?: Array< ModelUserInviteConditionInput | null > | null,
  not?: ModelUserInviteConditionInput | null,
};

export type UserInvite = {
  __typename: "UserInvite",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  userId: string,
  firstName: string,
  lastName: string,
  email: string,
  role: UserRole,
  invitedOn: string,
};

export type UpdateUserInviteInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId?: string | null,
  userId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  role?: UserRole | null,
  invitedOn?: string | null,
};

export type DeleteUserInviteInput = {
  id: string,
};

export type CreateModelInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  name: string,
  description?: string | null,
  startedAt?: string | null,
  advisorCreated?: boolean | null,
  clientAccess?: ClientAccess | null,
  settings?: string | null,
};

export type ModelModelConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  startedAt?: ModelStringInput | null,
  advisorCreated?: ModelBooleanInput | null,
  clientAccess?: ModelClientAccessInput | null,
  settings?: ModelStringInput | null,
  and?: Array< ModelModelConditionInput | null > | null,
  or?: Array< ModelModelConditionInput | null > | null,
  not?: ModelModelConditionInput | null,
};

export type ModelClientAccessInput = {
  eq?: ClientAccess | null,
  ne?: ClientAccess | null,
};

export type UpdateModelInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  accountId?: string | null,
  name?: string | null,
  description?: string | null,
  startedAt?: string | null,
  advisorCreated?: boolean | null,
  clientAccess?: ClientAccess | null,
  settings?: string | null,
};

export type DeleteModelInput = {
  id: string,
};

export type CreateSnapshotInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  date: string,
  description?: string | null,
  details?: string | null,
};

export type ModelSnapshotConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  date?: ModelStringInput | null,
  description?: ModelStringInput | null,
  details?: ModelStringInput | null,
  and?: Array< ModelSnapshotConditionInput | null > | null,
  or?: Array< ModelSnapshotConditionInput | null > | null,
  not?: ModelSnapshotConditionInput | null,
};

export type UpdateSnapshotInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  accountId?: string | null,
  modelId?: string | null,
  date?: string | null,
  description?: string | null,
  details?: string | null,
};

export type DeleteSnapshotInput = {
  id: string,
};

export type CreatePersonInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  nickname?: string | null,
  gender?: Gender | null,
  maritalStatus?: MaritalStatus | null,
  birthDate?: string | null,
  hereditaryAdjust?: number | null,
  lifeExpectancy?: number | null,
  retireDate?: string | null,
  state?: string | null,
};

export type ModelPersonConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  nickname?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  maritalStatus?: ModelMaritalStatusInput | null,
  birthDate?: ModelStringInput | null,
  hereditaryAdjust?: ModelIntInput | null,
  lifeExpectancy?: ModelIntInput | null,
  retireDate?: ModelStringInput | null,
  state?: ModelStringInput | null,
  and?: Array< ModelPersonConditionInput | null > | null,
  or?: Array< ModelPersonConditionInput | null > | null,
  not?: ModelPersonConditionInput | null,
};

export type ModelGenderInput = {
  eq?: Gender | null,
  ne?: Gender | null,
};

export type ModelMaritalStatusInput = {
  eq?: MaritalStatus | null,
  ne?: MaritalStatus | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdatePersonInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  accountId?: string | null,
  modelId?: string | null,
  nickname?: string | null,
  gender?: Gender | null,
  maritalStatus?: MaritalStatus | null,
  birthDate?: string | null,
  hereditaryAdjust?: number | null,
  lifeExpectancy?: number | null,
  retireDate?: string | null,
  state?: string | null,
};

export type DeletePersonInput = {
  id: string,
};

export type CreatePlanInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  name?: string | null,
  description?: string | null,
  enabled?: boolean | null,
  default?: boolean | null,
};

export type ModelPlanConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  default?: ModelBooleanInput | null,
  and?: Array< ModelPlanConditionInput | null > | null,
  or?: Array< ModelPlanConditionInput | null > | null,
  not?: ModelPlanConditionInput | null,
};

export type UpdatePlanInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  accountId?: string | null,
  modelId?: string | null,
  name?: string | null,
  description?: string | null,
  enabled?: boolean | null,
  default?: boolean | null,
};

export type DeletePlanInput = {
  id: string,
};

export type CreatePlanChangeInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  planId: string,
  changeType: string,
  name: string,
  description?: string | null,
  enabled?: boolean | null,
  details?: string | null,
};

export type ModelPlanChangeConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  planId?: ModelIDInput | null,
  changeType?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  details?: ModelStringInput | null,
  and?: Array< ModelPlanChangeConditionInput | null > | null,
  or?: Array< ModelPlanChangeConditionInput | null > | null,
  not?: ModelPlanChangeConditionInput | null,
};

export type UpdatePlanChangeInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  accountId?: string | null,
  modelId?: string | null,
  planId?: string | null,
  changeType?: string | null,
  name?: string | null,
  description?: string | null,
  enabled?: boolean | null,
  details?: string | null,
};

export type DeletePlanChangeInput = {
  id: string,
};

export type CreateIncomeInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  incomeType?: number | null,
  description?: string | null,
  amount?: number | null,
  schedule?: string | null,
  infAdjust?: boolean | null,
  infLock?: boolean | null,
  annualInf?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  sortOrder?: number | null,
  ownerId?: string | null,
  survivorPercent?: number | null,
};

export type ModelIncomeConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  incomeType?: ModelIntInput | null,
  description?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  schedule?: ModelStringInput | null,
  infAdjust?: ModelBooleanInput | null,
  infLock?: ModelBooleanInput | null,
  annualInf?: ModelFloatInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  sortOrder?: ModelFloatInput | null,
  ownerId?: ModelIDInput | null,
  survivorPercent?: ModelIntInput | null,
  and?: Array< ModelIncomeConditionInput | null > | null,
  or?: Array< ModelIncomeConditionInput | null > | null,
  not?: ModelIncomeConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateIncomeInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  accountId?: string | null,
  modelId?: string | null,
  incomeType?: number | null,
  description?: string | null,
  amount?: number | null,
  schedule?: string | null,
  infAdjust?: boolean | null,
  infLock?: boolean | null,
  annualInf?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  sortOrder?: number | null,
  ownerId?: string | null,
  survivorPercent?: number | null,
};

export type DeleteIncomeInput = {
  id: string,
};

export type CreateIncomeTypeInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  description?: string | null,
};

export type ModelIncomeTypeConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelIncomeTypeConditionInput | null > | null,
  or?: Array< ModelIncomeTypeConditionInput | null > | null,
  not?: ModelIncomeTypeConditionInput | null,
};

export type IncomeType = {
  __typename: "IncomeType",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  description?: string | null,
};

export type UpdateIncomeTypeInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  description?: string | null,
};

export type DeleteIncomeTypeInput = {
  id: string,
};

export type CreateDeductionInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  amount?: number | null,
  schedule?: string | null,
  infAdjust?: boolean | null,
  infLock?: boolean | null,
  annualInf?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  description?: string | null,
  sortOrder?: number | null,
  assetId?: string | null,
};

export type ModelDeductionConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  amount?: ModelIntInput | null,
  schedule?: ModelStringInput | null,
  infAdjust?: ModelBooleanInput | null,
  infLock?: ModelBooleanInput | null,
  annualInf?: ModelFloatInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  description?: ModelStringInput | null,
  sortOrder?: ModelFloatInput | null,
  assetId?: ModelIDInput | null,
  and?: Array< ModelDeductionConditionInput | null > | null,
  or?: Array< ModelDeductionConditionInput | null > | null,
  not?: ModelDeductionConditionInput | null,
};

export type UpdateDeductionInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  accountId?: string | null,
  modelId?: string | null,
  amount?: number | null,
  schedule?: string | null,
  infAdjust?: boolean | null,
  infLock?: boolean | null,
  annualInf?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  description?: string | null,
  sortOrder?: number | null,
  assetId?: string | null,
};

export type DeleteDeductionInput = {
  id: string,
};

export type CreateExpenseInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  expenseCategory?: number | null,
  description?: string | null,
  amount?: number | null,
  schedule?: string | null,
  infAdjust?: boolean | null,
  infLock?: boolean | null,
  annualInf?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  discretionary?: boolean | null,
  sortOrder?: number | null,
  ownerId?: string | null,
  assetId?: string | null,
  liabilityId?: string | null,
};

export type ModelExpenseConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  expenseCategory?: ModelIntInput | null,
  description?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  schedule?: ModelStringInput | null,
  infAdjust?: ModelBooleanInput | null,
  infLock?: ModelBooleanInput | null,
  annualInf?: ModelFloatInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  discretionary?: ModelBooleanInput | null,
  sortOrder?: ModelFloatInput | null,
  ownerId?: ModelIDInput | null,
  assetId?: ModelIDInput | null,
  liabilityId?: ModelIDInput | null,
  and?: Array< ModelExpenseConditionInput | null > | null,
  or?: Array< ModelExpenseConditionInput | null > | null,
  not?: ModelExpenseConditionInput | null,
};

export type UpdateExpenseInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  accountId?: string | null,
  modelId?: string | null,
  expenseCategory?: number | null,
  description?: string | null,
  amount?: number | null,
  schedule?: string | null,
  infAdjust?: boolean | null,
  infLock?: boolean | null,
  annualInf?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  discretionary?: boolean | null,
  sortOrder?: number | null,
  ownerId?: string | null,
  assetId?: string | null,
  liabilityId?: string | null,
};

export type DeleteExpenseInput = {
  id: string,
};

export type CreateExpenseCategoryInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  description?: string | null,
  sortOrder?: number | null,
};

export type ModelExpenseCategoryConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  sortOrder?: ModelFloatInput | null,
  and?: Array< ModelExpenseCategoryConditionInput | null > | null,
  or?: Array< ModelExpenseCategoryConditionInput | null > | null,
  not?: ModelExpenseCategoryConditionInput | null,
};

export type ExpenseCategory = {
  __typename: "ExpenseCategory",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  description?: string | null,
  sortOrder?: number | null,
};

export type UpdateExpenseCategoryInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  description?: string | null,
  sortOrder?: number | null,
};

export type DeleteExpenseCategoryInput = {
  id: string,
};

export type CreateTaxInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  taxType?: number | null,
  description?: string | null,
  amount?: number | null,
  schedule?: string | null,
  infAdjust?: boolean | null,
  infLock?: boolean | null,
  annualInf?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  sortOrder?: number | null,
  ownerId?: string | null,
};

export type ModelTaxConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  taxType?: ModelIntInput | null,
  description?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  schedule?: ModelStringInput | null,
  infAdjust?: ModelBooleanInput | null,
  infLock?: ModelBooleanInput | null,
  annualInf?: ModelFloatInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  sortOrder?: ModelFloatInput | null,
  ownerId?: ModelIDInput | null,
  and?: Array< ModelTaxConditionInput | null > | null,
  or?: Array< ModelTaxConditionInput | null > | null,
  not?: ModelTaxConditionInput | null,
};

export type UpdateTaxInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  accountId?: string | null,
  modelId?: string | null,
  taxType?: number | null,
  description?: string | null,
  amount?: number | null,
  schedule?: string | null,
  infAdjust?: boolean | null,
  infLock?: boolean | null,
  annualInf?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  sortOrder?: number | null,
  ownerId?: string | null,
};

export type DeleteTaxInput = {
  id: string,
};

export type CreateAssetInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  assetCategory?: number | null,
  assetType?: number | null,
  description?: string | null,
  risk?: number | null,
  balance?: number | null,
  balanceDate?: string | null,
  returnRate?: number | null,
  rateLock?: boolean | null,
  withdrawalOrder?: number | null,
  inheritedYear?: number | null,
  originalOwnerBirthYear?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  sortOrder?: number | null,
  ownerId?: string | null,
  priorEoYBalance?: number | null,
  withdrawalsYTD?: number | null,
};

export type ModelAssetConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  assetCategory?: ModelIntInput | null,
  assetType?: ModelIntInput | null,
  description?: ModelStringInput | null,
  risk?: ModelIntInput | null,
  balance?: ModelIntInput | null,
  balanceDate?: ModelStringInput | null,
  returnRate?: ModelFloatInput | null,
  rateLock?: ModelBooleanInput | null,
  withdrawalOrder?: ModelIntInput | null,
  inheritedYear?: ModelIntInput | null,
  originalOwnerBirthYear?: ModelIntInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  sortOrder?: ModelFloatInput | null,
  ownerId?: ModelIDInput | null,
  priorEoYBalance?: ModelIntInput | null,
  withdrawalsYTD?: ModelIntInput | null,
  and?: Array< ModelAssetConditionInput | null > | null,
  or?: Array< ModelAssetConditionInput | null > | null,
  not?: ModelAssetConditionInput | null,
};

export type UpdateAssetInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  accountId?: string | null,
  modelId?: string | null,
  assetCategory?: number | null,
  assetType?: number | null,
  description?: string | null,
  risk?: number | null,
  balance?: number | null,
  balanceDate?: string | null,
  returnRate?: number | null,
  rateLock?: boolean | null,
  withdrawalOrder?: number | null,
  inheritedYear?: number | null,
  originalOwnerBirthYear?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  sortOrder?: number | null,
  ownerId?: string | null,
  priorEoYBalance?: number | null,
  withdrawalsYTD?: number | null,
};

export type DeleteAssetInput = {
  id: string,
};

export type CreateAssetCategoryInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  description?: string | null,
  sortOrder?: number | null,
};

export type ModelAssetCategoryConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  sortOrder?: ModelFloatInput | null,
  and?: Array< ModelAssetCategoryConditionInput | null > | null,
  or?: Array< ModelAssetCategoryConditionInput | null > | null,
  not?: ModelAssetCategoryConditionInput | null,
};

export type AssetCategory = {
  __typename: "AssetCategory",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  description?: string | null,
  sortOrder?: number | null,
};

export type UpdateAssetCategoryInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  description?: string | null,
  sortOrder?: number | null,
};

export type DeleteAssetCategoryInput = {
  id: string,
};

export type CreateAssetTypeInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  description?: string | null,
};

export type ModelAssetTypeConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelAssetTypeConditionInput | null > | null,
  or?: Array< ModelAssetTypeConditionInput | null > | null,
  not?: ModelAssetTypeConditionInput | null,
};

export type AssetType = {
  __typename: "AssetType",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  description?: string | null,
};

export type UpdateAssetTypeInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  description?: string | null,
};

export type DeleteAssetTypeInput = {
  id: string,
};

export type CreateAssetConversionInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  description?: string | null,
  conversionType?: string | null,
  srcAssetId: string,
  dstAssetId: string,
  year?: number | null,
  amount?: number | null,
  percent?: number | null,
  sortOrder?: number | null,
  completedAt?: string | null,
  taxableAmount?: number | null,
  nonTaxableAmount?: number | null,
  incomeType?: number | null,
};

export type ModelAssetConversionConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  description?: ModelStringInput | null,
  conversionType?: ModelStringInput | null,
  srcAssetId?: ModelIDInput | null,
  dstAssetId?: ModelIDInput | null,
  year?: ModelIntInput | null,
  amount?: ModelIntInput | null,
  percent?: ModelFloatInput | null,
  sortOrder?: ModelFloatInput | null,
  completedAt?: ModelStringInput | null,
  taxableAmount?: ModelIntInput | null,
  nonTaxableAmount?: ModelIntInput | null,
  incomeType?: ModelIntInput | null,
  and?: Array< ModelAssetConversionConditionInput | null > | null,
  or?: Array< ModelAssetConversionConditionInput | null > | null,
  not?: ModelAssetConversionConditionInput | null,
};

export type UpdateAssetConversionInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  accountId?: string | null,
  modelId?: string | null,
  description?: string | null,
  conversionType?: string | null,
  srcAssetId?: string | null,
  dstAssetId?: string | null,
  year?: number | null,
  amount?: number | null,
  percent?: number | null,
  sortOrder?: number | null,
  completedAt?: string | null,
  taxableAmount?: number | null,
  nonTaxableAmount?: number | null,
  incomeType?: number | null,
};

export type DeleteAssetConversionInput = {
  id: string,
};

export type CreateLiabilityInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  description?: string | null,
  balance?: number | null,
  balanceDate?: string | null,
  payoffDate?: string | null,
  ownerId?: string | null,
  sortOrder?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  interestRate?: number | null,
};

export type ModelLiabilityConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  description?: ModelStringInput | null,
  balance?: ModelIntInput | null,
  balanceDate?: ModelStringInput | null,
  payoffDate?: ModelStringInput | null,
  ownerId?: ModelIDInput | null,
  sortOrder?: ModelFloatInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  interestRate?: ModelFloatInput | null,
  and?: Array< ModelLiabilityConditionInput | null > | null,
  or?: Array< ModelLiabilityConditionInput | null > | null,
  not?: ModelLiabilityConditionInput | null,
};

export type UpdateLiabilityInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  accountId?: string | null,
  modelId?: string | null,
  description?: string | null,
  balance?: number | null,
  balanceDate?: string | null,
  payoffDate?: string | null,
  ownerId?: string | null,
  sortOrder?: number | null,
  start?: string | null,
  end?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  interestRate?: number | null,
};

export type DeleteLiabilityInput = {
  id: string,
};

export type CreateTaxValueInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  modelId: string,
  year: number,
  key: string,
  value: number,
};

export type ModelTaxValueConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  year?: ModelIntInput | null,
  key?: ModelStringInput | null,
  value?: ModelFloatInput | null,
  and?: Array< ModelTaxValueConditionInput | null > | null,
  or?: Array< ModelTaxValueConditionInput | null > | null,
  not?: ModelTaxValueConditionInput | null,
};

export type UpdateTaxValueInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  accountId?: string | null,
  modelId?: string | null,
  year?: number | null,
  key?: string | null,
  value?: number | null,
};

export type DeleteTaxValueInput = {
  id: string,
};

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  ownerId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userType?: ModelUserTypeInput | null,
  userStatus?: ModelUserStatusInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  mfaPreference?: ModelStringInput | null,
  role?: ModelUserRoleInput | null,
  advisorAccess?: ModelBooleanInput | null,
  lastAccess?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  currentModelId?: ModelIDInput | null,
  advisorModelId?: ModelIDInput | null,
  currentPlanId?: ModelIDInput | null,
  migrateStatus?: ModelStringInput | null,
  migratedOn?: ModelStringInput | null,
  subscribedOn?: ModelStringInput | null,
  cancelledOn?: ModelStringInput | null,
  referrer?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserSettingFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  settingType?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  and?: Array< ModelUserSettingFilterInput | null > | null,
  or?: Array< ModelUserSettingFilterInput | null > | null,
  not?: ModelUserSettingFilterInput | null,
};

export type ModelModelFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  startedAt?: ModelStringInput | null,
  advisorCreated?: ModelBooleanInput | null,
  clientAccess?: ModelClientAccessInput | null,
  settings?: ModelStringInput | null,
  and?: Array< ModelModelFilterInput | null > | null,
  or?: Array< ModelModelFilterInput | null > | null,
  not?: ModelModelFilterInput | null,
};

export type ModelSnapshotFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  date?: ModelStringInput | null,
  description?: ModelStringInput | null,
  details?: ModelStringInput | null,
  and?: Array< ModelSnapshotFilterInput | null > | null,
  or?: Array< ModelSnapshotFilterInput | null > | null,
  not?: ModelSnapshotFilterInput | null,
};

export type ModelPersonFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  nickname?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  maritalStatus?: ModelMaritalStatusInput | null,
  birthDate?: ModelStringInput | null,
  hereditaryAdjust?: ModelIntInput | null,
  lifeExpectancy?: ModelIntInput | null,
  retireDate?: ModelStringInput | null,
  state?: ModelStringInput | null,
  and?: Array< ModelPersonFilterInput | null > | null,
  or?: Array< ModelPersonFilterInput | null > | null,
  not?: ModelPersonFilterInput | null,
};

export type ModelPlanFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  default?: ModelBooleanInput | null,
  and?: Array< ModelPlanFilterInput | null > | null,
  or?: Array< ModelPlanFilterInput | null > | null,
  not?: ModelPlanFilterInput | null,
};

export type ModelPlanChangeFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  planId?: ModelIDInput | null,
  changeType?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  details?: ModelStringInput | null,
  and?: Array< ModelPlanChangeFilterInput | null > | null,
  or?: Array< ModelPlanChangeFilterInput | null > | null,
  not?: ModelPlanChangeFilterInput | null,
};

export type ModelIncomeFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  incomeType?: ModelIntInput | null,
  description?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  schedule?: ModelStringInput | null,
  infAdjust?: ModelBooleanInput | null,
  infLock?: ModelBooleanInput | null,
  annualInf?: ModelFloatInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  sortOrder?: ModelFloatInput | null,
  ownerId?: ModelIDInput | null,
  survivorPercent?: ModelIntInput | null,
  and?: Array< ModelIncomeFilterInput | null > | null,
  or?: Array< ModelIncomeFilterInput | null > | null,
  not?: ModelIncomeFilterInput | null,
};

export type ModelIncomeTypeFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelIncomeTypeFilterInput | null > | null,
  or?: Array< ModelIncomeTypeFilterInput | null > | null,
  not?: ModelIncomeTypeFilterInput | null,
};

export type ModelIncomeTypeConnection = {
  __typename: "ModelIncomeTypeConnection",
  items:  Array<IncomeType | null >,
  nextToken?: string | null,
};

export type ModelDeductionFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  amount?: ModelIntInput | null,
  schedule?: ModelStringInput | null,
  infAdjust?: ModelBooleanInput | null,
  infLock?: ModelBooleanInput | null,
  annualInf?: ModelFloatInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  description?: ModelStringInput | null,
  sortOrder?: ModelFloatInput | null,
  assetId?: ModelIDInput | null,
  and?: Array< ModelDeductionFilterInput | null > | null,
  or?: Array< ModelDeductionFilterInput | null > | null,
  not?: ModelDeductionFilterInput | null,
};

export type ModelExpenseFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  expenseCategory?: ModelIntInput | null,
  description?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  schedule?: ModelStringInput | null,
  infAdjust?: ModelBooleanInput | null,
  infLock?: ModelBooleanInput | null,
  annualInf?: ModelFloatInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  discretionary?: ModelBooleanInput | null,
  sortOrder?: ModelFloatInput | null,
  ownerId?: ModelIDInput | null,
  assetId?: ModelIDInput | null,
  liabilityId?: ModelIDInput | null,
  and?: Array< ModelExpenseFilterInput | null > | null,
  or?: Array< ModelExpenseFilterInput | null > | null,
  not?: ModelExpenseFilterInput | null,
};

export type ModelExpenseCategoryFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  sortOrder?: ModelFloatInput | null,
  and?: Array< ModelExpenseCategoryFilterInput | null > | null,
  or?: Array< ModelExpenseCategoryFilterInput | null > | null,
  not?: ModelExpenseCategoryFilterInput | null,
};

export type ModelExpenseCategoryConnection = {
  __typename: "ModelExpenseCategoryConnection",
  items:  Array<ExpenseCategory | null >,
  nextToken?: string | null,
};

export type ModelTaxFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  taxType?: ModelIntInput | null,
  description?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  schedule?: ModelStringInput | null,
  infAdjust?: ModelBooleanInput | null,
  infLock?: ModelBooleanInput | null,
  annualInf?: ModelFloatInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  sortOrder?: ModelFloatInput | null,
  ownerId?: ModelIDInput | null,
  and?: Array< ModelTaxFilterInput | null > | null,
  or?: Array< ModelTaxFilterInput | null > | null,
  not?: ModelTaxFilterInput | null,
};

export type ModelAssetFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  assetCategory?: ModelIntInput | null,
  assetType?: ModelIntInput | null,
  description?: ModelStringInput | null,
  risk?: ModelIntInput | null,
  balance?: ModelIntInput | null,
  balanceDate?: ModelStringInput | null,
  returnRate?: ModelFloatInput | null,
  rateLock?: ModelBooleanInput | null,
  withdrawalOrder?: ModelIntInput | null,
  inheritedYear?: ModelIntInput | null,
  originalOwnerBirthYear?: ModelIntInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  sortOrder?: ModelFloatInput | null,
  ownerId?: ModelIDInput | null,
  priorEoYBalance?: ModelIntInput | null,
  withdrawalsYTD?: ModelIntInput | null,
  and?: Array< ModelAssetFilterInput | null > | null,
  or?: Array< ModelAssetFilterInput | null > | null,
  not?: ModelAssetFilterInput | null,
};

export type ModelAssetCategoryFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  sortOrder?: ModelFloatInput | null,
  and?: Array< ModelAssetCategoryFilterInput | null > | null,
  or?: Array< ModelAssetCategoryFilterInput | null > | null,
  not?: ModelAssetCategoryFilterInput | null,
};

export type ModelAssetCategoryConnection = {
  __typename: "ModelAssetCategoryConnection",
  items:  Array<AssetCategory | null >,
  nextToken?: string | null,
};

export type ModelAssetTypeFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelAssetTypeFilterInput | null > | null,
  or?: Array< ModelAssetTypeFilterInput | null > | null,
  not?: ModelAssetTypeFilterInput | null,
};

export type ModelAssetTypeConnection = {
  __typename: "ModelAssetTypeConnection",
  items:  Array<AssetType | null >,
  nextToken?: string | null,
};

export type ModelAssetConversionFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  description?: ModelStringInput | null,
  conversionType?: ModelStringInput | null,
  srcAssetId?: ModelIDInput | null,
  dstAssetId?: ModelIDInput | null,
  year?: ModelIntInput | null,
  amount?: ModelIntInput | null,
  percent?: ModelFloatInput | null,
  sortOrder?: ModelFloatInput | null,
  completedAt?: ModelStringInput | null,
  taxableAmount?: ModelIntInput | null,
  nonTaxableAmount?: ModelIntInput | null,
  incomeType?: ModelIntInput | null,
  and?: Array< ModelAssetConversionFilterInput | null > | null,
  or?: Array< ModelAssetConversionFilterInput | null > | null,
  not?: ModelAssetConversionFilterInput | null,
};

export type ModelLiabilityFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  description?: ModelStringInput | null,
  balance?: ModelIntInput | null,
  balanceDate?: ModelStringInput | null,
  payoffDate?: ModelStringInput | null,
  ownerId?: ModelIDInput | null,
  sortOrder?: ModelFloatInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  interestRate?: ModelFloatInput | null,
  and?: Array< ModelLiabilityFilterInput | null > | null,
  or?: Array< ModelLiabilityFilterInput | null > | null,
  not?: ModelLiabilityFilterInput | null,
};

export type ModelTaxValueFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  modelId?: ModelIDInput | null,
  year?: ModelIntInput | null,
  key?: ModelStringInput | null,
  value?: ModelFloatInput | null,
  and?: Array< ModelTaxValueFilterInput | null > | null,
  or?: Array< ModelTaxValueFilterInput | null > | null,
  not?: ModelTaxValueFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelUserInviteFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  role?: ModelUserRoleInput | null,
  invitedOn?: ModelStringInput | null,
  and?: Array< ModelUserInviteFilterInput | null > | null,
  or?: Array< ModelUserInviteFilterInput | null > | null,
  not?: ModelUserInviteFilterInput | null,
};

export type ModelUserInviteConnection = {
  __typename: "ModelUserInviteConnection",
  items:  Array<UserInvite | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionAccountFilterInput = {
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  postalCode?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountFilterInput | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  userType?: ModelSubscriptionStringInput | null,
  userStatus?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  mfaPreference?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  advisorAccess?: ModelSubscriptionBooleanInput | null,
  lastAccess?: ModelSubscriptionStringInput | null,
  customerId?: ModelSubscriptionStringInput | null,
  currentModelId?: ModelSubscriptionIDInput | null,
  advisorModelId?: ModelSubscriptionIDInput | null,
  currentPlanId?: ModelSubscriptionIDInput | null,
  migrateStatus?: ModelSubscriptionStringInput | null,
  migratedOn?: ModelSubscriptionStringInput | null,
  subscribedOn?: ModelSubscriptionStringInput | null,
  cancelledOn?: ModelSubscriptionStringInput | null,
  referrer?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionUserSettingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  settingType?: ModelSubscriptionStringInput | null,
  settings?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserSettingFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserSettingFilterInput | null > | null,
};

export type ModelSubscriptionModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  startedAt?: ModelSubscriptionStringInput | null,
  advisorCreated?: ModelSubscriptionBooleanInput | null,
  clientAccess?: ModelSubscriptionStringInput | null,
  settings?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionModelFilterInput | null > | null,
};

export type ModelSubscriptionSnapshotFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  modelId?: ModelSubscriptionIDInput | null,
  date?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  details?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSnapshotFilterInput | null > | null,
  or?: Array< ModelSubscriptionSnapshotFilterInput | null > | null,
};

export type ModelSubscriptionPersonFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  modelId?: ModelSubscriptionIDInput | null,
  nickname?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  maritalStatus?: ModelSubscriptionStringInput | null,
  birthDate?: ModelSubscriptionStringInput | null,
  hereditaryAdjust?: ModelSubscriptionIntInput | null,
  lifeExpectancy?: ModelSubscriptionIntInput | null,
  retireDate?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPersonFilterInput | null > | null,
  or?: Array< ModelSubscriptionPersonFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionPlanFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  modelId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  enabled?: ModelSubscriptionBooleanInput | null,
  default?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionPlanFilterInput | null > | null,
  or?: Array< ModelSubscriptionPlanFilterInput | null > | null,
};

export type ModelSubscriptionPlanChangeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  modelId?: ModelSubscriptionIDInput | null,
  planId?: ModelSubscriptionIDInput | null,
  changeType?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  enabled?: ModelSubscriptionBooleanInput | null,
  details?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPlanChangeFilterInput | null > | null,
  or?: Array< ModelSubscriptionPlanChangeFilterInput | null > | null,
};

export type ModelSubscriptionIncomeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  modelId?: ModelSubscriptionIDInput | null,
  incomeType?: ModelSubscriptionIntInput | null,
  description?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionIntInput | null,
  schedule?: ModelSubscriptionStringInput | null,
  infAdjust?: ModelSubscriptionBooleanInput | null,
  infLock?: ModelSubscriptionBooleanInput | null,
  annualInf?: ModelSubscriptionFloatInput | null,
  start?: ModelSubscriptionStringInput | null,
  end?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  sortOrder?: ModelSubscriptionFloatInput | null,
  ownerId?: ModelSubscriptionIDInput | null,
  survivorPercent?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionIncomeFilterInput | null > | null,
  or?: Array< ModelSubscriptionIncomeFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionIncomeTypeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionIncomeTypeFilterInput | null > | null,
  or?: Array< ModelSubscriptionIncomeTypeFilterInput | null > | null,
};

export type ModelSubscriptionDeductionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  modelId?: ModelSubscriptionIDInput | null,
  amount?: ModelSubscriptionIntInput | null,
  schedule?: ModelSubscriptionStringInput | null,
  infAdjust?: ModelSubscriptionBooleanInput | null,
  infLock?: ModelSubscriptionBooleanInput | null,
  annualInf?: ModelSubscriptionFloatInput | null,
  start?: ModelSubscriptionStringInput | null,
  end?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  sortOrder?: ModelSubscriptionFloatInput | null,
  assetId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionDeductionFilterInput | null > | null,
  or?: Array< ModelSubscriptionDeductionFilterInput | null > | null,
};

export type ModelSubscriptionExpenseFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  modelId?: ModelSubscriptionIDInput | null,
  expenseCategory?: ModelSubscriptionIntInput | null,
  description?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionIntInput | null,
  schedule?: ModelSubscriptionStringInput | null,
  infAdjust?: ModelSubscriptionBooleanInput | null,
  infLock?: ModelSubscriptionBooleanInput | null,
  annualInf?: ModelSubscriptionFloatInput | null,
  start?: ModelSubscriptionStringInput | null,
  end?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  discretionary?: ModelSubscriptionBooleanInput | null,
  sortOrder?: ModelSubscriptionFloatInput | null,
  ownerId?: ModelSubscriptionIDInput | null,
  assetId?: ModelSubscriptionIDInput | null,
  liabilityId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionExpenseFilterInput | null > | null,
  or?: Array< ModelSubscriptionExpenseFilterInput | null > | null,
};

export type ModelSubscriptionExpenseCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  sortOrder?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionExpenseCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionExpenseCategoryFilterInput | null > | null,
};

export type ModelSubscriptionTaxFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  modelId?: ModelSubscriptionIDInput | null,
  taxType?: ModelSubscriptionIntInput | null,
  description?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionIntInput | null,
  schedule?: ModelSubscriptionStringInput | null,
  infAdjust?: ModelSubscriptionBooleanInput | null,
  infLock?: ModelSubscriptionBooleanInput | null,
  annualInf?: ModelSubscriptionFloatInput | null,
  start?: ModelSubscriptionStringInput | null,
  end?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  sortOrder?: ModelSubscriptionFloatInput | null,
  ownerId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionTaxFilterInput | null > | null,
  or?: Array< ModelSubscriptionTaxFilterInput | null > | null,
};

export type ModelSubscriptionAssetFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  modelId?: ModelSubscriptionIDInput | null,
  assetCategory?: ModelSubscriptionIntInput | null,
  assetType?: ModelSubscriptionIntInput | null,
  description?: ModelSubscriptionStringInput | null,
  risk?: ModelSubscriptionIntInput | null,
  balance?: ModelSubscriptionIntInput | null,
  balanceDate?: ModelSubscriptionStringInput | null,
  returnRate?: ModelSubscriptionFloatInput | null,
  rateLock?: ModelSubscriptionBooleanInput | null,
  withdrawalOrder?: ModelSubscriptionIntInput | null,
  inheritedYear?: ModelSubscriptionIntInput | null,
  originalOwnerBirthYear?: ModelSubscriptionIntInput | null,
  start?: ModelSubscriptionStringInput | null,
  end?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  sortOrder?: ModelSubscriptionFloatInput | null,
  ownerId?: ModelSubscriptionIDInput | null,
  priorEoYBalance?: ModelSubscriptionIntInput | null,
  withdrawalsYTD?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionAssetFilterInput | null > | null,
  or?: Array< ModelSubscriptionAssetFilterInput | null > | null,
};

export type ModelSubscriptionAssetCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  sortOrder?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionAssetCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionAssetCategoryFilterInput | null > | null,
};

export type ModelSubscriptionAssetTypeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAssetTypeFilterInput | null > | null,
  or?: Array< ModelSubscriptionAssetTypeFilterInput | null > | null,
};

export type ModelSubscriptionAssetConversionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  modelId?: ModelSubscriptionIDInput | null,
  description?: ModelSubscriptionStringInput | null,
  conversionType?: ModelSubscriptionStringInput | null,
  srcAssetId?: ModelSubscriptionIDInput | null,
  dstAssetId?: ModelSubscriptionIDInput | null,
  year?: ModelSubscriptionIntInput | null,
  amount?: ModelSubscriptionIntInput | null,
  percent?: ModelSubscriptionFloatInput | null,
  sortOrder?: ModelSubscriptionFloatInput | null,
  completedAt?: ModelSubscriptionStringInput | null,
  taxableAmount?: ModelSubscriptionIntInput | null,
  nonTaxableAmount?: ModelSubscriptionIntInput | null,
  incomeType?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionAssetConversionFilterInput | null > | null,
  or?: Array< ModelSubscriptionAssetConversionFilterInput | null > | null,
};

export type ModelSubscriptionLiabilityFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  modelId?: ModelSubscriptionIDInput | null,
  description?: ModelSubscriptionStringInput | null,
  balance?: ModelSubscriptionIntInput | null,
  balanceDate?: ModelSubscriptionStringInput | null,
  payoffDate?: ModelSubscriptionStringInput | null,
  ownerId?: ModelSubscriptionIDInput | null,
  sortOrder?: ModelSubscriptionFloatInput | null,
  start?: ModelSubscriptionStringInput | null,
  end?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  interestRate?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionLiabilityFilterInput | null > | null,
  or?: Array< ModelSubscriptionLiabilityFilterInput | null > | null,
};

export type ModelSubscriptionTaxValueFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  modelId?: ModelSubscriptionIDInput | null,
  year?: ModelSubscriptionIntInput | null,
  key?: ModelSubscriptionStringInput | null,
  value?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionTaxValueFilterInput | null > | null,
  or?: Array< ModelSubscriptionTaxValueFilterInput | null > | null,
};

export type ModelSubscriptionUserInviteFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  accountId?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  invitedOn?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserInviteFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserInviteFilterInput | null > | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userType: UserType,
        userStatus: UserStatus,
        startDate?: string | null,
        endDate?: string | null,
        accountId: string,
        account?:  {
          __typename: "Account",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          ownerId?: string | null,
          name: string,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          users?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        mfaPreference?: string | null,
        role: UserRole,
        advisorAccess?: boolean | null,
        lastAccess?: string | null,
        customerId?: string | null,
        currentModelId?: string | null,
        advisorModelId?: string | null,
        currentPlanId?: string | null,
        models?:  {
          __typename: "ModelModelConnection",
          items:  Array< {
            __typename: "Model",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            name: string,
            description?: string | null,
            startedAt?: string | null,
            advisorCreated?: boolean | null,
            clientAccess?: ClientAccess | null,
            settings?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        migrateStatus?: string | null,
        migratedOn?: string | null,
        subscribedOn?: string | null,
        cancelledOn?: string | null,
        referrer?: string | null,
        settings?:  {
          __typename: "ModelUserSettingConnection",
          items:  Array< {
            __typename: "UserSetting",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            name: string,
            description?: string | null,
            settingType?: string | null,
            settings?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userType: UserType,
        userStatus: UserStatus,
        startDate?: string | null,
        endDate?: string | null,
        accountId: string,
        account?:  {
          __typename: "Account",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          ownerId?: string | null,
          name: string,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          users?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        mfaPreference?: string | null,
        role: UserRole,
        advisorAccess?: boolean | null,
        lastAccess?: string | null,
        customerId?: string | null,
        currentModelId?: string | null,
        advisorModelId?: string | null,
        currentPlanId?: string | null,
        models?:  {
          __typename: "ModelModelConnection",
          items:  Array< {
            __typename: "Model",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            name: string,
            description?: string | null,
            startedAt?: string | null,
            advisorCreated?: boolean | null,
            clientAccess?: ClientAccess | null,
            settings?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        migrateStatus?: string | null,
        migratedOn?: string | null,
        subscribedOn?: string | null,
        cancelledOn?: string | null,
        referrer?: string | null,
        settings?:  {
          __typename: "ModelUserSettingConnection",
          items:  Array< {
            __typename: "UserSetting",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            name: string,
            description?: string | null,
            settingType?: string | null,
            settings?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userType: UserType,
        userStatus: UserStatus,
        startDate?: string | null,
        endDate?: string | null,
        accountId: string,
        account?:  {
          __typename: "Account",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          ownerId?: string | null,
          name: string,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          users?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        mfaPreference?: string | null,
        role: UserRole,
        advisorAccess?: boolean | null,
        lastAccess?: string | null,
        customerId?: string | null,
        currentModelId?: string | null,
        advisorModelId?: string | null,
        currentPlanId?: string | null,
        models?:  {
          __typename: "ModelModelConnection",
          items:  Array< {
            __typename: "Model",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            name: string,
            description?: string | null,
            startedAt?: string | null,
            advisorCreated?: boolean | null,
            clientAccess?: ClientAccess | null,
            settings?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        migrateStatus?: string | null,
        migratedOn?: string | null,
        subscribedOn?: string | null,
        cancelledOn?: string | null,
        referrer?: string | null,
        settings?:  {
          __typename: "ModelUserSettingConnection",
          items:  Array< {
            __typename: "UserSetting",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            name: string,
            description?: string | null,
            settingType?: string | null,
            settings?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userType: UserType,
    userStatus: UserStatus,
    startDate?: string | null,
    endDate?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userType: UserType,
          userStatus: UserStatus,
          startDate?: string | null,
          endDate?: string | null,
          accountId: string,
          account?:  {
            __typename: "Account",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            ownerId?: string | null,
            name: string,
            address?: string | null,
            city?: string | null,
            state?: string | null,
            postalCode?: string | null,
          } | null,
          firstName: string,
          lastName: string,
          email: string,
          phone?: string | null,
          mfaPreference?: string | null,
          role: UserRole,
          advisorAccess?: boolean | null,
          lastAccess?: string | null,
          customerId?: string | null,
          currentModelId?: string | null,
          advisorModelId?: string | null,
          currentPlanId?: string | null,
          models?:  {
            __typename: "ModelModelConnection",
            nextToken?: string | null,
          } | null,
          migrateStatus?: string | null,
          migratedOn?: string | null,
          subscribedOn?: string | null,
          cancelledOn?: string | null,
          referrer?: string | null,
          settings?:  {
            __typename: "ModelUserSettingConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string | null,
    mfaPreference?: string | null,
    role: UserRole,
    advisorAccess?: boolean | null,
    lastAccess?: string | null,
    customerId?: string | null,
    currentModelId?: string | null,
    advisorModelId?: string | null,
    currentPlanId?: string | null,
    models?:  {
      __typename: "ModelModelConnection",
      items:  Array< {
        __typename: "Model",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        name: string,
        description?: string | null,
        startedAt?: string | null,
        advisorCreated?: boolean | null,
        clientAccess?: ClientAccess | null,
        settings?: string | null,
        persons?:  {
          __typename: "ModelPersonConnection",
          items:  Array< {
            __typename: "Person",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            nickname?: string | null,
            gender?: Gender | null,
            maritalStatus?: MaritalStatus | null,
            birthDate?: string | null,
            hereditaryAdjust?: number | null,
            lifeExpectancy?: number | null,
            retireDate?: string | null,
            state?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        plans?:  {
          __typename: "ModelPlanConnection",
          items:  Array< {
            __typename: "Plan",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            name?: string | null,
            description?: string | null,
            enabled?: boolean | null,
            default?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        incomes?:  {
          __typename: "ModelIncomeConnection",
          items:  Array< {
            __typename: "Income",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            incomeType?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            survivorPercent?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        deductions?:  {
          __typename: "ModelDeductionConnection",
          items:  Array< {
            __typename: "Deduction",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            description?: string | null,
            sortOrder?: number | null,
            assetId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        taxes?:  {
          __typename: "ModelTaxConnection",
          items:  Array< {
            __typename: "Tax",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            taxType?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        expenses?:  {
          __typename: "ModelExpenseConnection",
          items:  Array< {
            __typename: "Expense",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            expenseCategory?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            discretionary?: boolean | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            assetId?: string | null,
            liabilityId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        assets?:  {
          __typename: "ModelAssetConnection",
          items:  Array< {
            __typename: "Asset",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            assetCategory?: number | null,
            assetType?: number | null,
            description?: string | null,
            risk?: number | null,
            balance?: number | null,
            balanceDate?: string | null,
            returnRate?: number | null,
            rateLock?: boolean | null,
            withdrawalOrder?: number | null,
            inheritedYear?: number | null,
            originalOwnerBirthYear?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            priorEoYBalance?: number | null,
            withdrawalsYTD?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        liabilities?:  {
          __typename: "ModelLiabilityConnection",
          items:  Array< {
            __typename: "Liability",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            description?: string | null,
            balance?: number | null,
            balanceDate?: string | null,
            payoffDate?: string | null,
            ownerId?: string | null,
            sortOrder?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            interestRate?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        snapshots?:  {
          __typename: "ModelSnapshotConnection",
          items:  Array< {
            __typename: "Snapshot",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            date: string,
            description?: string | null,
            details?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        taxValues?:  {
          __typename: "ModelTaxValueConnection",
          items:  Array< {
            __typename: "TaxValue",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            year: number,
            key: string,
            value: number,
          } | null >,
          nextToken?: string | null,
        } | null,
        assetConversions?:  {
          __typename: "ModelAssetConversionConnection",
          items:  Array< {
            __typename: "AssetConversion",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            description?: string | null,
            conversionType?: string | null,
            srcAssetId: string,
            dstAssetId: string,
            year?: number | null,
            amount?: number | null,
            percent?: number | null,
            sortOrder?: number | null,
            completedAt?: string | null,
            taxableAmount?: number | null,
            nonTaxableAmount?: number | null,
            incomeType?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    migrateStatus?: string | null,
    migratedOn?: string | null,
    subscribedOn?: string | null,
    cancelledOn?: string | null,
    referrer?: string | null,
    settings?:  {
      __typename: "ModelUserSettingConnection",
      items:  Array< {
        __typename: "UserSetting",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        name: string,
        description?: string | null,
        settingType?: string | null,
        settings?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userType: UserType,
    userStatus: UserStatus,
    startDate?: string | null,
    endDate?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userType: UserType,
          userStatus: UserStatus,
          startDate?: string | null,
          endDate?: string | null,
          accountId: string,
          account?:  {
            __typename: "Account",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            ownerId?: string | null,
            name: string,
            address?: string | null,
            city?: string | null,
            state?: string | null,
            postalCode?: string | null,
          } | null,
          firstName: string,
          lastName: string,
          email: string,
          phone?: string | null,
          mfaPreference?: string | null,
          role: UserRole,
          advisorAccess?: boolean | null,
          lastAccess?: string | null,
          customerId?: string | null,
          currentModelId?: string | null,
          advisorModelId?: string | null,
          currentPlanId?: string | null,
          models?:  {
            __typename: "ModelModelConnection",
            nextToken?: string | null,
          } | null,
          migrateStatus?: string | null,
          migratedOn?: string | null,
          subscribedOn?: string | null,
          cancelledOn?: string | null,
          referrer?: string | null,
          settings?:  {
            __typename: "ModelUserSettingConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string | null,
    mfaPreference?: string | null,
    role: UserRole,
    advisorAccess?: boolean | null,
    lastAccess?: string | null,
    customerId?: string | null,
    currentModelId?: string | null,
    advisorModelId?: string | null,
    currentPlanId?: string | null,
    models?:  {
      __typename: "ModelModelConnection",
      items:  Array< {
        __typename: "Model",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        name: string,
        description?: string | null,
        startedAt?: string | null,
        advisorCreated?: boolean | null,
        clientAccess?: ClientAccess | null,
        settings?: string | null,
        persons?:  {
          __typename: "ModelPersonConnection",
          items:  Array< {
            __typename: "Person",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            nickname?: string | null,
            gender?: Gender | null,
            maritalStatus?: MaritalStatus | null,
            birthDate?: string | null,
            hereditaryAdjust?: number | null,
            lifeExpectancy?: number | null,
            retireDate?: string | null,
            state?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        plans?:  {
          __typename: "ModelPlanConnection",
          items:  Array< {
            __typename: "Plan",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            name?: string | null,
            description?: string | null,
            enabled?: boolean | null,
            default?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        incomes?:  {
          __typename: "ModelIncomeConnection",
          items:  Array< {
            __typename: "Income",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            incomeType?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            survivorPercent?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        deductions?:  {
          __typename: "ModelDeductionConnection",
          items:  Array< {
            __typename: "Deduction",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            description?: string | null,
            sortOrder?: number | null,
            assetId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        taxes?:  {
          __typename: "ModelTaxConnection",
          items:  Array< {
            __typename: "Tax",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            taxType?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        expenses?:  {
          __typename: "ModelExpenseConnection",
          items:  Array< {
            __typename: "Expense",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            expenseCategory?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            discretionary?: boolean | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            assetId?: string | null,
            liabilityId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        assets?:  {
          __typename: "ModelAssetConnection",
          items:  Array< {
            __typename: "Asset",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            assetCategory?: number | null,
            assetType?: number | null,
            description?: string | null,
            risk?: number | null,
            balance?: number | null,
            balanceDate?: string | null,
            returnRate?: number | null,
            rateLock?: boolean | null,
            withdrawalOrder?: number | null,
            inheritedYear?: number | null,
            originalOwnerBirthYear?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            priorEoYBalance?: number | null,
            withdrawalsYTD?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        liabilities?:  {
          __typename: "ModelLiabilityConnection",
          items:  Array< {
            __typename: "Liability",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            description?: string | null,
            balance?: number | null,
            balanceDate?: string | null,
            payoffDate?: string | null,
            ownerId?: string | null,
            sortOrder?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            interestRate?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        snapshots?:  {
          __typename: "ModelSnapshotConnection",
          items:  Array< {
            __typename: "Snapshot",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            date: string,
            description?: string | null,
            details?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        taxValues?:  {
          __typename: "ModelTaxValueConnection",
          items:  Array< {
            __typename: "TaxValue",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            year: number,
            key: string,
            value: number,
          } | null >,
          nextToken?: string | null,
        } | null,
        assetConversions?:  {
          __typename: "ModelAssetConversionConnection",
          items:  Array< {
            __typename: "AssetConversion",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            description?: string | null,
            conversionType?: string | null,
            srcAssetId: string,
            dstAssetId: string,
            year?: number | null,
            amount?: number | null,
            percent?: number | null,
            sortOrder?: number | null,
            completedAt?: string | null,
            taxableAmount?: number | null,
            nonTaxableAmount?: number | null,
            incomeType?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    migrateStatus?: string | null,
    migratedOn?: string | null,
    subscribedOn?: string | null,
    cancelledOn?: string | null,
    referrer?: string | null,
    settings?:  {
      __typename: "ModelUserSettingConnection",
      items:  Array< {
        __typename: "UserSetting",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        name: string,
        description?: string | null,
        settingType?: string | null,
        settings?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userType: UserType,
    userStatus: UserStatus,
    startDate?: string | null,
    endDate?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userType: UserType,
          userStatus: UserStatus,
          startDate?: string | null,
          endDate?: string | null,
          accountId: string,
          account?:  {
            __typename: "Account",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            ownerId?: string | null,
            name: string,
            address?: string | null,
            city?: string | null,
            state?: string | null,
            postalCode?: string | null,
          } | null,
          firstName: string,
          lastName: string,
          email: string,
          phone?: string | null,
          mfaPreference?: string | null,
          role: UserRole,
          advisorAccess?: boolean | null,
          lastAccess?: string | null,
          customerId?: string | null,
          currentModelId?: string | null,
          advisorModelId?: string | null,
          currentPlanId?: string | null,
          models?:  {
            __typename: "ModelModelConnection",
            nextToken?: string | null,
          } | null,
          migrateStatus?: string | null,
          migratedOn?: string | null,
          subscribedOn?: string | null,
          cancelledOn?: string | null,
          referrer?: string | null,
          settings?:  {
            __typename: "ModelUserSettingConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string | null,
    mfaPreference?: string | null,
    role: UserRole,
    advisorAccess?: boolean | null,
    lastAccess?: string | null,
    customerId?: string | null,
    currentModelId?: string | null,
    advisorModelId?: string | null,
    currentPlanId?: string | null,
    models?:  {
      __typename: "ModelModelConnection",
      items:  Array< {
        __typename: "Model",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        name: string,
        description?: string | null,
        startedAt?: string | null,
        advisorCreated?: boolean | null,
        clientAccess?: ClientAccess | null,
        settings?: string | null,
        persons?:  {
          __typename: "ModelPersonConnection",
          items:  Array< {
            __typename: "Person",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            nickname?: string | null,
            gender?: Gender | null,
            maritalStatus?: MaritalStatus | null,
            birthDate?: string | null,
            hereditaryAdjust?: number | null,
            lifeExpectancy?: number | null,
            retireDate?: string | null,
            state?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        plans?:  {
          __typename: "ModelPlanConnection",
          items:  Array< {
            __typename: "Plan",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            name?: string | null,
            description?: string | null,
            enabled?: boolean | null,
            default?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        incomes?:  {
          __typename: "ModelIncomeConnection",
          items:  Array< {
            __typename: "Income",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            incomeType?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            survivorPercent?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        deductions?:  {
          __typename: "ModelDeductionConnection",
          items:  Array< {
            __typename: "Deduction",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            description?: string | null,
            sortOrder?: number | null,
            assetId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        taxes?:  {
          __typename: "ModelTaxConnection",
          items:  Array< {
            __typename: "Tax",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            taxType?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        expenses?:  {
          __typename: "ModelExpenseConnection",
          items:  Array< {
            __typename: "Expense",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            expenseCategory?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            discretionary?: boolean | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            assetId?: string | null,
            liabilityId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        assets?:  {
          __typename: "ModelAssetConnection",
          items:  Array< {
            __typename: "Asset",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            assetCategory?: number | null,
            assetType?: number | null,
            description?: string | null,
            risk?: number | null,
            balance?: number | null,
            balanceDate?: string | null,
            returnRate?: number | null,
            rateLock?: boolean | null,
            withdrawalOrder?: number | null,
            inheritedYear?: number | null,
            originalOwnerBirthYear?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            priorEoYBalance?: number | null,
            withdrawalsYTD?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        liabilities?:  {
          __typename: "ModelLiabilityConnection",
          items:  Array< {
            __typename: "Liability",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            description?: string | null,
            balance?: number | null,
            balanceDate?: string | null,
            payoffDate?: string | null,
            ownerId?: string | null,
            sortOrder?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            interestRate?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        snapshots?:  {
          __typename: "ModelSnapshotConnection",
          items:  Array< {
            __typename: "Snapshot",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            date: string,
            description?: string | null,
            details?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        taxValues?:  {
          __typename: "ModelTaxValueConnection",
          items:  Array< {
            __typename: "TaxValue",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            year: number,
            key: string,
            value: number,
          } | null >,
          nextToken?: string | null,
        } | null,
        assetConversions?:  {
          __typename: "ModelAssetConversionConnection",
          items:  Array< {
            __typename: "AssetConversion",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            description?: string | null,
            conversionType?: string | null,
            srcAssetId: string,
            dstAssetId: string,
            year?: number | null,
            amount?: number | null,
            percent?: number | null,
            sortOrder?: number | null,
            completedAt?: string | null,
            taxableAmount?: number | null,
            nonTaxableAmount?: number | null,
            incomeType?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    migrateStatus?: string | null,
    migratedOn?: string | null,
    subscribedOn?: string | null,
    cancelledOn?: string | null,
    referrer?: string | null,
    settings?:  {
      __typename: "ModelUserSettingConnection",
      items:  Array< {
        __typename: "UserSetting",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        name: string,
        description?: string | null,
        settingType?: string | null,
        settings?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateUserSettingMutationVariables = {
  input: CreateUserSettingInput,
  condition?: ModelUserSettingConditionInput | null,
};

export type CreateUserSettingMutation = {
  createUserSetting?:  {
    __typename: "UserSetting",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    name: string,
    description?: string | null,
    settingType?: string | null,
    settings?: string | null,
  } | null,
};

export type UpdateUserSettingMutationVariables = {
  input: UpdateUserSettingInput,
  condition?: ModelUserSettingConditionInput | null,
};

export type UpdateUserSettingMutation = {
  updateUserSetting?:  {
    __typename: "UserSetting",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    name: string,
    description?: string | null,
    settingType?: string | null,
    settings?: string | null,
  } | null,
};

export type DeleteUserSettingMutationVariables = {
  input: DeleteUserSettingInput,
  condition?: ModelUserSettingConditionInput | null,
};

export type DeleteUserSettingMutation = {
  deleteUserSetting?:  {
    __typename: "UserSetting",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    name: string,
    description?: string | null,
    settingType?: string | null,
    settings?: string | null,
  } | null,
};

export type CreateUserInviteMutationVariables = {
  input: CreateUserInviteInput,
  condition?: ModelUserInviteConditionInput | null,
};

export type CreateUserInviteMutation = {
  createUserInvite?:  {
    __typename: "UserInvite",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    role: UserRole,
    invitedOn: string,
  } | null,
};

export type UpdateUserInviteMutationVariables = {
  input: UpdateUserInviteInput,
  condition?: ModelUserInviteConditionInput | null,
};

export type UpdateUserInviteMutation = {
  updateUserInvite?:  {
    __typename: "UserInvite",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    role: UserRole,
    invitedOn: string,
  } | null,
};

export type DeleteUserInviteMutationVariables = {
  input: DeleteUserInviteInput,
  condition?: ModelUserInviteConditionInput | null,
};

export type DeleteUserInviteMutation = {
  deleteUserInvite?:  {
    __typename: "UserInvite",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    role: UserRole,
    invitedOn: string,
  } | null,
};

export type CreateModelMutationVariables = {
  input: CreateModelInput,
  condition?: ModelModelConditionInput | null,
};

export type CreateModelMutation = {
  createModel?:  {
    __typename: "Model",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    name: string,
    description?: string | null,
    startedAt?: string | null,
    advisorCreated?: boolean | null,
    clientAccess?: ClientAccess | null,
    settings?: string | null,
    persons?:  {
      __typename: "ModelPersonConnection",
      items:  Array< {
        __typename: "Person",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        nickname?: string | null,
        gender?: Gender | null,
        maritalStatus?: MaritalStatus | null,
        birthDate?: string | null,
        hereditaryAdjust?: number | null,
        lifeExpectancy?: number | null,
        retireDate?: string | null,
        state?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    plans?:  {
      __typename: "ModelPlanConnection",
      items:  Array< {
        __typename: "Plan",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        name?: string | null,
        description?: string | null,
        enabled?: boolean | null,
        default?: boolean | null,
        changes?:  {
          __typename: "ModelPlanChangeConnection",
          items:  Array< {
            __typename: "PlanChange",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            planId: string,
            changeType: string,
            name: string,
            description?: string | null,
            enabled?: boolean | null,
            details?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    incomes?:  {
      __typename: "ModelIncomeConnection",
      items:  Array< {
        __typename: "Income",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        incomeType?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        survivorPercent?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deductions?:  {
      __typename: "ModelDeductionConnection",
      items:  Array< {
        __typename: "Deduction",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        description?: string | null,
        sortOrder?: number | null,
        assetId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    taxes?:  {
      __typename: "ModelTaxConnection",
      items:  Array< {
        __typename: "Tax",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        taxType?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    expenses?:  {
      __typename: "ModelExpenseConnection",
      items:  Array< {
        __typename: "Expense",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        expenseCategory?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        discretionary?: boolean | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        assetId?: string | null,
        liabilityId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    assets?:  {
      __typename: "ModelAssetConnection",
      items:  Array< {
        __typename: "Asset",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        assetCategory?: number | null,
        assetType?: number | null,
        description?: string | null,
        risk?: number | null,
        balance?: number | null,
        balanceDate?: string | null,
        returnRate?: number | null,
        rateLock?: boolean | null,
        withdrawalOrder?: number | null,
        inheritedYear?: number | null,
        originalOwnerBirthYear?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        priorEoYBalance?: number | null,
        withdrawalsYTD?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    liabilities?:  {
      __typename: "ModelLiabilityConnection",
      items:  Array< {
        __typename: "Liability",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        description?: string | null,
        balance?: number | null,
        balanceDate?: string | null,
        payoffDate?: string | null,
        ownerId?: string | null,
        sortOrder?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        interestRate?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    snapshots?:  {
      __typename: "ModelSnapshotConnection",
      items:  Array< {
        __typename: "Snapshot",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        date: string,
        description?: string | null,
        details?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    taxValues?:  {
      __typename: "ModelTaxValueConnection",
      items:  Array< {
        __typename: "TaxValue",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        year: number,
        key: string,
        value: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    assetConversions?:  {
      __typename: "ModelAssetConversionConnection",
      items:  Array< {
        __typename: "AssetConversion",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        description?: string | null,
        conversionType?: string | null,
        srcAssetId: string,
        dstAssetId: string,
        year?: number | null,
        amount?: number | null,
        percent?: number | null,
        sortOrder?: number | null,
        completedAt?: string | null,
        taxableAmount?: number | null,
        nonTaxableAmount?: number | null,
        incomeType?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateModelMutationVariables = {
  input: UpdateModelInput,
  condition?: ModelModelConditionInput | null,
};

export type UpdateModelMutation = {
  updateModel?:  {
    __typename: "Model",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    name: string,
    description?: string | null,
    startedAt?: string | null,
    advisorCreated?: boolean | null,
    clientAccess?: ClientAccess | null,
    settings?: string | null,
    persons?:  {
      __typename: "ModelPersonConnection",
      items:  Array< {
        __typename: "Person",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        nickname?: string | null,
        gender?: Gender | null,
        maritalStatus?: MaritalStatus | null,
        birthDate?: string | null,
        hereditaryAdjust?: number | null,
        lifeExpectancy?: number | null,
        retireDate?: string | null,
        state?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    plans?:  {
      __typename: "ModelPlanConnection",
      items:  Array< {
        __typename: "Plan",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        name?: string | null,
        description?: string | null,
        enabled?: boolean | null,
        default?: boolean | null,
        changes?:  {
          __typename: "ModelPlanChangeConnection",
          items:  Array< {
            __typename: "PlanChange",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            planId: string,
            changeType: string,
            name: string,
            description?: string | null,
            enabled?: boolean | null,
            details?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    incomes?:  {
      __typename: "ModelIncomeConnection",
      items:  Array< {
        __typename: "Income",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        incomeType?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        survivorPercent?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deductions?:  {
      __typename: "ModelDeductionConnection",
      items:  Array< {
        __typename: "Deduction",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        description?: string | null,
        sortOrder?: number | null,
        assetId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    taxes?:  {
      __typename: "ModelTaxConnection",
      items:  Array< {
        __typename: "Tax",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        taxType?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    expenses?:  {
      __typename: "ModelExpenseConnection",
      items:  Array< {
        __typename: "Expense",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        expenseCategory?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        discretionary?: boolean | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        assetId?: string | null,
        liabilityId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    assets?:  {
      __typename: "ModelAssetConnection",
      items:  Array< {
        __typename: "Asset",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        assetCategory?: number | null,
        assetType?: number | null,
        description?: string | null,
        risk?: number | null,
        balance?: number | null,
        balanceDate?: string | null,
        returnRate?: number | null,
        rateLock?: boolean | null,
        withdrawalOrder?: number | null,
        inheritedYear?: number | null,
        originalOwnerBirthYear?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        priorEoYBalance?: number | null,
        withdrawalsYTD?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    liabilities?:  {
      __typename: "ModelLiabilityConnection",
      items:  Array< {
        __typename: "Liability",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        description?: string | null,
        balance?: number | null,
        balanceDate?: string | null,
        payoffDate?: string | null,
        ownerId?: string | null,
        sortOrder?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        interestRate?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    snapshots?:  {
      __typename: "ModelSnapshotConnection",
      items:  Array< {
        __typename: "Snapshot",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        date: string,
        description?: string | null,
        details?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    taxValues?:  {
      __typename: "ModelTaxValueConnection",
      items:  Array< {
        __typename: "TaxValue",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        year: number,
        key: string,
        value: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    assetConversions?:  {
      __typename: "ModelAssetConversionConnection",
      items:  Array< {
        __typename: "AssetConversion",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        description?: string | null,
        conversionType?: string | null,
        srcAssetId: string,
        dstAssetId: string,
        year?: number | null,
        amount?: number | null,
        percent?: number | null,
        sortOrder?: number | null,
        completedAt?: string | null,
        taxableAmount?: number | null,
        nonTaxableAmount?: number | null,
        incomeType?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteModelMutationVariables = {
  input: DeleteModelInput,
  condition?: ModelModelConditionInput | null,
};

export type DeleteModelMutation = {
  deleteModel?:  {
    __typename: "Model",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    name: string,
    description?: string | null,
    startedAt?: string | null,
    advisorCreated?: boolean | null,
    clientAccess?: ClientAccess | null,
    settings?: string | null,
    persons?:  {
      __typename: "ModelPersonConnection",
      items:  Array< {
        __typename: "Person",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        nickname?: string | null,
        gender?: Gender | null,
        maritalStatus?: MaritalStatus | null,
        birthDate?: string | null,
        hereditaryAdjust?: number | null,
        lifeExpectancy?: number | null,
        retireDate?: string | null,
        state?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    plans?:  {
      __typename: "ModelPlanConnection",
      items:  Array< {
        __typename: "Plan",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        name?: string | null,
        description?: string | null,
        enabled?: boolean | null,
        default?: boolean | null,
        changes?:  {
          __typename: "ModelPlanChangeConnection",
          items:  Array< {
            __typename: "PlanChange",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            planId: string,
            changeType: string,
            name: string,
            description?: string | null,
            enabled?: boolean | null,
            details?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    incomes?:  {
      __typename: "ModelIncomeConnection",
      items:  Array< {
        __typename: "Income",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        incomeType?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        survivorPercent?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deductions?:  {
      __typename: "ModelDeductionConnection",
      items:  Array< {
        __typename: "Deduction",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        description?: string | null,
        sortOrder?: number | null,
        assetId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    taxes?:  {
      __typename: "ModelTaxConnection",
      items:  Array< {
        __typename: "Tax",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        taxType?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    expenses?:  {
      __typename: "ModelExpenseConnection",
      items:  Array< {
        __typename: "Expense",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        expenseCategory?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        discretionary?: boolean | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        assetId?: string | null,
        liabilityId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    assets?:  {
      __typename: "ModelAssetConnection",
      items:  Array< {
        __typename: "Asset",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        assetCategory?: number | null,
        assetType?: number | null,
        description?: string | null,
        risk?: number | null,
        balance?: number | null,
        balanceDate?: string | null,
        returnRate?: number | null,
        rateLock?: boolean | null,
        withdrawalOrder?: number | null,
        inheritedYear?: number | null,
        originalOwnerBirthYear?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        priorEoYBalance?: number | null,
        withdrawalsYTD?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    liabilities?:  {
      __typename: "ModelLiabilityConnection",
      items:  Array< {
        __typename: "Liability",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        description?: string | null,
        balance?: number | null,
        balanceDate?: string | null,
        payoffDate?: string | null,
        ownerId?: string | null,
        sortOrder?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        interestRate?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    snapshots?:  {
      __typename: "ModelSnapshotConnection",
      items:  Array< {
        __typename: "Snapshot",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        date: string,
        description?: string | null,
        details?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    taxValues?:  {
      __typename: "ModelTaxValueConnection",
      items:  Array< {
        __typename: "TaxValue",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        year: number,
        key: string,
        value: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    assetConversions?:  {
      __typename: "ModelAssetConversionConnection",
      items:  Array< {
        __typename: "AssetConversion",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        description?: string | null,
        conversionType?: string | null,
        srcAssetId: string,
        dstAssetId: string,
        year?: number | null,
        amount?: number | null,
        percent?: number | null,
        sortOrder?: number | null,
        completedAt?: string | null,
        taxableAmount?: number | null,
        nonTaxableAmount?: number | null,
        incomeType?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateSnapshotMutationVariables = {
  input: CreateSnapshotInput,
  condition?: ModelSnapshotConditionInput | null,
};

export type CreateSnapshotMutation = {
  createSnapshot?:  {
    __typename: "Snapshot",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    date: string,
    description?: string | null,
    details?: string | null,
  } | null,
};

export type UpdateSnapshotMutationVariables = {
  input: UpdateSnapshotInput,
  condition?: ModelSnapshotConditionInput | null,
};

export type UpdateSnapshotMutation = {
  updateSnapshot?:  {
    __typename: "Snapshot",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    date: string,
    description?: string | null,
    details?: string | null,
  } | null,
};

export type DeleteSnapshotMutationVariables = {
  input: DeleteSnapshotInput,
  condition?: ModelSnapshotConditionInput | null,
};

export type DeleteSnapshotMutation = {
  deleteSnapshot?:  {
    __typename: "Snapshot",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    date: string,
    description?: string | null,
    details?: string | null,
  } | null,
};

export type CreatePersonMutationVariables = {
  input: CreatePersonInput,
  condition?: ModelPersonConditionInput | null,
};

export type CreatePersonMutation = {
  createPerson?:  {
    __typename: "Person",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    nickname?: string | null,
    gender?: Gender | null,
    maritalStatus?: MaritalStatus | null,
    birthDate?: string | null,
    hereditaryAdjust?: number | null,
    lifeExpectancy?: number | null,
    retireDate?: string | null,
    state?: string | null,
  } | null,
};

export type UpdatePersonMutationVariables = {
  input: UpdatePersonInput,
  condition?: ModelPersonConditionInput | null,
};

export type UpdatePersonMutation = {
  updatePerson?:  {
    __typename: "Person",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    nickname?: string | null,
    gender?: Gender | null,
    maritalStatus?: MaritalStatus | null,
    birthDate?: string | null,
    hereditaryAdjust?: number | null,
    lifeExpectancy?: number | null,
    retireDate?: string | null,
    state?: string | null,
  } | null,
};

export type DeletePersonMutationVariables = {
  input: DeletePersonInput,
  condition?: ModelPersonConditionInput | null,
};

export type DeletePersonMutation = {
  deletePerson?:  {
    __typename: "Person",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    nickname?: string | null,
    gender?: Gender | null,
    maritalStatus?: MaritalStatus | null,
    birthDate?: string | null,
    hereditaryAdjust?: number | null,
    lifeExpectancy?: number | null,
    retireDate?: string | null,
    state?: string | null,
  } | null,
};

export type CreatePlanMutationVariables = {
  input: CreatePlanInput,
  condition?: ModelPlanConditionInput | null,
};

export type CreatePlanMutation = {
  createPlan?:  {
    __typename: "Plan",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    name?: string | null,
    description?: string | null,
    enabled?: boolean | null,
    default?: boolean | null,
    changes?:  {
      __typename: "ModelPlanChangeConnection",
      items:  Array< {
        __typename: "PlanChange",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        planId: string,
        changeType: string,
        name: string,
        description?: string | null,
        enabled?: boolean | null,
        details?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdatePlanMutationVariables = {
  input: UpdatePlanInput,
  condition?: ModelPlanConditionInput | null,
};

export type UpdatePlanMutation = {
  updatePlan?:  {
    __typename: "Plan",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    name?: string | null,
    description?: string | null,
    enabled?: boolean | null,
    default?: boolean | null,
    changes?:  {
      __typename: "ModelPlanChangeConnection",
      items:  Array< {
        __typename: "PlanChange",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        planId: string,
        changeType: string,
        name: string,
        description?: string | null,
        enabled?: boolean | null,
        details?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeletePlanMutationVariables = {
  input: DeletePlanInput,
  condition?: ModelPlanConditionInput | null,
};

export type DeletePlanMutation = {
  deletePlan?:  {
    __typename: "Plan",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    name?: string | null,
    description?: string | null,
    enabled?: boolean | null,
    default?: boolean | null,
    changes?:  {
      __typename: "ModelPlanChangeConnection",
      items:  Array< {
        __typename: "PlanChange",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        planId: string,
        changeType: string,
        name: string,
        description?: string | null,
        enabled?: boolean | null,
        details?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreatePlanChangeMutationVariables = {
  input: CreatePlanChangeInput,
  condition?: ModelPlanChangeConditionInput | null,
};

export type CreatePlanChangeMutation = {
  createPlanChange?:  {
    __typename: "PlanChange",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    planId: string,
    changeType: string,
    name: string,
    description?: string | null,
    enabled?: boolean | null,
    details?: string | null,
  } | null,
};

export type UpdatePlanChangeMutationVariables = {
  input: UpdatePlanChangeInput,
  condition?: ModelPlanChangeConditionInput | null,
};

export type UpdatePlanChangeMutation = {
  updatePlanChange?:  {
    __typename: "PlanChange",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    planId: string,
    changeType: string,
    name: string,
    description?: string | null,
    enabled?: boolean | null,
    details?: string | null,
  } | null,
};

export type DeletePlanChangeMutationVariables = {
  input: DeletePlanChangeInput,
  condition?: ModelPlanChangeConditionInput | null,
};

export type DeletePlanChangeMutation = {
  deletePlanChange?:  {
    __typename: "PlanChange",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    planId: string,
    changeType: string,
    name: string,
    description?: string | null,
    enabled?: boolean | null,
    details?: string | null,
  } | null,
};

export type CreateIncomeMutationVariables = {
  input: CreateIncomeInput,
  condition?: ModelIncomeConditionInput | null,
};

export type CreateIncomeMutation = {
  createIncome?:  {
    __typename: "Income",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    incomeType?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    survivorPercent?: number | null,
  } | null,
};

export type UpdateIncomeMutationVariables = {
  input: UpdateIncomeInput,
  condition?: ModelIncomeConditionInput | null,
};

export type UpdateIncomeMutation = {
  updateIncome?:  {
    __typename: "Income",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    incomeType?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    survivorPercent?: number | null,
  } | null,
};

export type DeleteIncomeMutationVariables = {
  input: DeleteIncomeInput,
  condition?: ModelIncomeConditionInput | null,
};

export type DeleteIncomeMutation = {
  deleteIncome?:  {
    __typename: "Income",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    incomeType?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    survivorPercent?: number | null,
  } | null,
};

export type CreateIncomeTypeMutationVariables = {
  input: CreateIncomeTypeInput,
  condition?: ModelIncomeTypeConditionInput | null,
};

export type CreateIncomeTypeMutation = {
  createIncomeType?:  {
    __typename: "IncomeType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
  } | null,
};

export type UpdateIncomeTypeMutationVariables = {
  input: UpdateIncomeTypeInput,
  condition?: ModelIncomeTypeConditionInput | null,
};

export type UpdateIncomeTypeMutation = {
  updateIncomeType?:  {
    __typename: "IncomeType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
  } | null,
};

export type DeleteIncomeTypeMutationVariables = {
  input: DeleteIncomeTypeInput,
  condition?: ModelIncomeTypeConditionInput | null,
};

export type DeleteIncomeTypeMutation = {
  deleteIncomeType?:  {
    __typename: "IncomeType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
  } | null,
};

export type CreateDeductionMutationVariables = {
  input: CreateDeductionInput,
  condition?: ModelDeductionConditionInput | null,
};

export type CreateDeductionMutation = {
  createDeduction?:  {
    __typename: "Deduction",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    description?: string | null,
    sortOrder?: number | null,
    assetId?: string | null,
  } | null,
};

export type UpdateDeductionMutationVariables = {
  input: UpdateDeductionInput,
  condition?: ModelDeductionConditionInput | null,
};

export type UpdateDeductionMutation = {
  updateDeduction?:  {
    __typename: "Deduction",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    description?: string | null,
    sortOrder?: number | null,
    assetId?: string | null,
  } | null,
};

export type DeleteDeductionMutationVariables = {
  input: DeleteDeductionInput,
  condition?: ModelDeductionConditionInput | null,
};

export type DeleteDeductionMutation = {
  deleteDeduction?:  {
    __typename: "Deduction",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    description?: string | null,
    sortOrder?: number | null,
    assetId?: string | null,
  } | null,
};

export type CreateExpenseMutationVariables = {
  input: CreateExpenseInput,
  condition?: ModelExpenseConditionInput | null,
};

export type CreateExpenseMutation = {
  createExpense?:  {
    __typename: "Expense",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    expenseCategory?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    discretionary?: boolean | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    assetId?: string | null,
    liabilityId?: string | null,
  } | null,
};

export type UpdateExpenseMutationVariables = {
  input: UpdateExpenseInput,
  condition?: ModelExpenseConditionInput | null,
};

export type UpdateExpenseMutation = {
  updateExpense?:  {
    __typename: "Expense",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    expenseCategory?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    discretionary?: boolean | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    assetId?: string | null,
    liabilityId?: string | null,
  } | null,
};

export type DeleteExpenseMutationVariables = {
  input: DeleteExpenseInput,
  condition?: ModelExpenseConditionInput | null,
};

export type DeleteExpenseMutation = {
  deleteExpense?:  {
    __typename: "Expense",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    expenseCategory?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    discretionary?: boolean | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    assetId?: string | null,
    liabilityId?: string | null,
  } | null,
};

export type CreateExpenseCategoryMutationVariables = {
  input: CreateExpenseCategoryInput,
  condition?: ModelExpenseCategoryConditionInput | null,
};

export type CreateExpenseCategoryMutation = {
  createExpenseCategory?:  {
    __typename: "ExpenseCategory",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
    sortOrder?: number | null,
  } | null,
};

export type UpdateExpenseCategoryMutationVariables = {
  input: UpdateExpenseCategoryInput,
  condition?: ModelExpenseCategoryConditionInput | null,
};

export type UpdateExpenseCategoryMutation = {
  updateExpenseCategory?:  {
    __typename: "ExpenseCategory",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
    sortOrder?: number | null,
  } | null,
};

export type DeleteExpenseCategoryMutationVariables = {
  input: DeleteExpenseCategoryInput,
  condition?: ModelExpenseCategoryConditionInput | null,
};

export type DeleteExpenseCategoryMutation = {
  deleteExpenseCategory?:  {
    __typename: "ExpenseCategory",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
    sortOrder?: number | null,
  } | null,
};

export type CreateTaxMutationVariables = {
  input: CreateTaxInput,
  condition?: ModelTaxConditionInput | null,
};

export type CreateTaxMutation = {
  createTax?:  {
    __typename: "Tax",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    taxType?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
  } | null,
};

export type UpdateTaxMutationVariables = {
  input: UpdateTaxInput,
  condition?: ModelTaxConditionInput | null,
};

export type UpdateTaxMutation = {
  updateTax?:  {
    __typename: "Tax",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    taxType?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
  } | null,
};

export type DeleteTaxMutationVariables = {
  input: DeleteTaxInput,
  condition?: ModelTaxConditionInput | null,
};

export type DeleteTaxMutation = {
  deleteTax?:  {
    __typename: "Tax",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    taxType?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
  } | null,
};

export type CreateAssetMutationVariables = {
  input: CreateAssetInput,
  condition?: ModelAssetConditionInput | null,
};

export type CreateAssetMutation = {
  createAsset?:  {
    __typename: "Asset",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    assetCategory?: number | null,
    assetType?: number | null,
    description?: string | null,
    risk?: number | null,
    balance?: number | null,
    balanceDate?: string | null,
    returnRate?: number | null,
    rateLock?: boolean | null,
    withdrawalOrder?: number | null,
    inheritedYear?: number | null,
    originalOwnerBirthYear?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    priorEoYBalance?: number | null,
    withdrawalsYTD?: number | null,
  } | null,
};

export type UpdateAssetMutationVariables = {
  input: UpdateAssetInput,
  condition?: ModelAssetConditionInput | null,
};

export type UpdateAssetMutation = {
  updateAsset?:  {
    __typename: "Asset",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    assetCategory?: number | null,
    assetType?: number | null,
    description?: string | null,
    risk?: number | null,
    balance?: number | null,
    balanceDate?: string | null,
    returnRate?: number | null,
    rateLock?: boolean | null,
    withdrawalOrder?: number | null,
    inheritedYear?: number | null,
    originalOwnerBirthYear?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    priorEoYBalance?: number | null,
    withdrawalsYTD?: number | null,
  } | null,
};

export type DeleteAssetMutationVariables = {
  input: DeleteAssetInput,
  condition?: ModelAssetConditionInput | null,
};

export type DeleteAssetMutation = {
  deleteAsset?:  {
    __typename: "Asset",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    assetCategory?: number | null,
    assetType?: number | null,
    description?: string | null,
    risk?: number | null,
    balance?: number | null,
    balanceDate?: string | null,
    returnRate?: number | null,
    rateLock?: boolean | null,
    withdrawalOrder?: number | null,
    inheritedYear?: number | null,
    originalOwnerBirthYear?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    priorEoYBalance?: number | null,
    withdrawalsYTD?: number | null,
  } | null,
};

export type CreateAssetCategoryMutationVariables = {
  input: CreateAssetCategoryInput,
  condition?: ModelAssetCategoryConditionInput | null,
};

export type CreateAssetCategoryMutation = {
  createAssetCategory?:  {
    __typename: "AssetCategory",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
    sortOrder?: number | null,
  } | null,
};

export type UpdateAssetCategoryMutationVariables = {
  input: UpdateAssetCategoryInput,
  condition?: ModelAssetCategoryConditionInput | null,
};

export type UpdateAssetCategoryMutation = {
  updateAssetCategory?:  {
    __typename: "AssetCategory",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
    sortOrder?: number | null,
  } | null,
};

export type DeleteAssetCategoryMutationVariables = {
  input: DeleteAssetCategoryInput,
  condition?: ModelAssetCategoryConditionInput | null,
};

export type DeleteAssetCategoryMutation = {
  deleteAssetCategory?:  {
    __typename: "AssetCategory",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
    sortOrder?: number | null,
  } | null,
};

export type CreateAssetTypeMutationVariables = {
  input: CreateAssetTypeInput,
  condition?: ModelAssetTypeConditionInput | null,
};

export type CreateAssetTypeMutation = {
  createAssetType?:  {
    __typename: "AssetType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
  } | null,
};

export type UpdateAssetTypeMutationVariables = {
  input: UpdateAssetTypeInput,
  condition?: ModelAssetTypeConditionInput | null,
};

export type UpdateAssetTypeMutation = {
  updateAssetType?:  {
    __typename: "AssetType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
  } | null,
};

export type DeleteAssetTypeMutationVariables = {
  input: DeleteAssetTypeInput,
  condition?: ModelAssetTypeConditionInput | null,
};

export type DeleteAssetTypeMutation = {
  deleteAssetType?:  {
    __typename: "AssetType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
  } | null,
};

export type CreateAssetConversionMutationVariables = {
  input: CreateAssetConversionInput,
  condition?: ModelAssetConversionConditionInput | null,
};

export type CreateAssetConversionMutation = {
  createAssetConversion?:  {
    __typename: "AssetConversion",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    description?: string | null,
    conversionType?: string | null,
    srcAssetId: string,
    dstAssetId: string,
    year?: number | null,
    amount?: number | null,
    percent?: number | null,
    sortOrder?: number | null,
    completedAt?: string | null,
    taxableAmount?: number | null,
    nonTaxableAmount?: number | null,
    incomeType?: number | null,
  } | null,
};

export type UpdateAssetConversionMutationVariables = {
  input: UpdateAssetConversionInput,
  condition?: ModelAssetConversionConditionInput | null,
};

export type UpdateAssetConversionMutation = {
  updateAssetConversion?:  {
    __typename: "AssetConversion",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    description?: string | null,
    conversionType?: string | null,
    srcAssetId: string,
    dstAssetId: string,
    year?: number | null,
    amount?: number | null,
    percent?: number | null,
    sortOrder?: number | null,
    completedAt?: string | null,
    taxableAmount?: number | null,
    nonTaxableAmount?: number | null,
    incomeType?: number | null,
  } | null,
};

export type DeleteAssetConversionMutationVariables = {
  input: DeleteAssetConversionInput,
  condition?: ModelAssetConversionConditionInput | null,
};

export type DeleteAssetConversionMutation = {
  deleteAssetConversion?:  {
    __typename: "AssetConversion",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    description?: string | null,
    conversionType?: string | null,
    srcAssetId: string,
    dstAssetId: string,
    year?: number | null,
    amount?: number | null,
    percent?: number | null,
    sortOrder?: number | null,
    completedAt?: string | null,
    taxableAmount?: number | null,
    nonTaxableAmount?: number | null,
    incomeType?: number | null,
  } | null,
};

export type CreateLiabilityMutationVariables = {
  input: CreateLiabilityInput,
  condition?: ModelLiabilityConditionInput | null,
};

export type CreateLiabilityMutation = {
  createLiability?:  {
    __typename: "Liability",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    description?: string | null,
    balance?: number | null,
    balanceDate?: string | null,
    payoffDate?: string | null,
    ownerId?: string | null,
    sortOrder?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    interestRate?: number | null,
  } | null,
};

export type UpdateLiabilityMutationVariables = {
  input: UpdateLiabilityInput,
  condition?: ModelLiabilityConditionInput | null,
};

export type UpdateLiabilityMutation = {
  updateLiability?:  {
    __typename: "Liability",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    description?: string | null,
    balance?: number | null,
    balanceDate?: string | null,
    payoffDate?: string | null,
    ownerId?: string | null,
    sortOrder?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    interestRate?: number | null,
  } | null,
};

export type DeleteLiabilityMutationVariables = {
  input: DeleteLiabilityInput,
  condition?: ModelLiabilityConditionInput | null,
};

export type DeleteLiabilityMutation = {
  deleteLiability?:  {
    __typename: "Liability",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    description?: string | null,
    balance?: number | null,
    balanceDate?: string | null,
    payoffDate?: string | null,
    ownerId?: string | null,
    sortOrder?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    interestRate?: number | null,
  } | null,
};

export type CreateTaxValueMutationVariables = {
  input: CreateTaxValueInput,
  condition?: ModelTaxValueConditionInput | null,
};

export type CreateTaxValueMutation = {
  createTaxValue?:  {
    __typename: "TaxValue",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    year: number,
    key: string,
    value: number,
  } | null,
};

export type UpdateTaxValueMutationVariables = {
  input: UpdateTaxValueInput,
  condition?: ModelTaxValueConditionInput | null,
};

export type UpdateTaxValueMutation = {
  updateTaxValue?:  {
    __typename: "TaxValue",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    year: number,
    key: string,
    value: number,
  } | null,
};

export type DeleteTaxValueMutationVariables = {
  input: DeleteTaxValueInput,
  condition?: ModelTaxValueConditionInput | null,
};

export type DeleteTaxValueMutation = {
  deleteTaxValue?:  {
    __typename: "TaxValue",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    year: number,
    key: string,
    value: number,
  } | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userType: UserType,
        userStatus: UserStatus,
        startDate?: string | null,
        endDate?: string | null,
        accountId: string,
        account?:  {
          __typename: "Account",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          ownerId?: string | null,
          name: string,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          users?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        mfaPreference?: string | null,
        role: UserRole,
        advisorAccess?: boolean | null,
        lastAccess?: string | null,
        customerId?: string | null,
        currentModelId?: string | null,
        advisorModelId?: string | null,
        currentPlanId?: string | null,
        models?:  {
          __typename: "ModelModelConnection",
          items:  Array< {
            __typename: "Model",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            name: string,
            description?: string | null,
            startedAt?: string | null,
            advisorCreated?: boolean | null,
            clientAccess?: ClientAccess | null,
            settings?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        migrateStatus?: string | null,
        migratedOn?: string | null,
        subscribedOn?: string | null,
        cancelledOn?: string | null,
        referrer?: string | null,
        settings?:  {
          __typename: "ModelUserSettingConnection",
          items:  Array< {
            __typename: "UserSetting",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            name: string,
            description?: string | null,
            settingType?: string | null,
            settings?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListAccountsQueryVariables = {
  id?: string | null,
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userType: UserType,
          userStatus: UserStatus,
          startDate?: string | null,
          endDate?: string | null,
          accountId: string,
          account?:  {
            __typename: "Account",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            ownerId?: string | null,
            name: string,
            address?: string | null,
            city?: string | null,
            state?: string | null,
            postalCode?: string | null,
          } | null,
          firstName: string,
          lastName: string,
          email: string,
          phone?: string | null,
          mfaPreference?: string | null,
          role: UserRole,
          advisorAccess?: boolean | null,
          lastAccess?: string | null,
          customerId?: string | null,
          currentModelId?: string | null,
          advisorModelId?: string | null,
          currentPlanId?: string | null,
          models?:  {
            __typename: "ModelModelConnection",
            nextToken?: string | null,
          } | null,
          migrateStatus?: string | null,
          migratedOn?: string | null,
          subscribedOn?: string | null,
          cancelledOn?: string | null,
          referrer?: string | null,
          settings?:  {
            __typename: "ModelUserSettingConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userType: UserType,
    userStatus: UserStatus,
    startDate?: string | null,
    endDate?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userType: UserType,
          userStatus: UserStatus,
          startDate?: string | null,
          endDate?: string | null,
          accountId: string,
          account?:  {
            __typename: "Account",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            ownerId?: string | null,
            name: string,
            address?: string | null,
            city?: string | null,
            state?: string | null,
            postalCode?: string | null,
          } | null,
          firstName: string,
          lastName: string,
          email: string,
          phone?: string | null,
          mfaPreference?: string | null,
          role: UserRole,
          advisorAccess?: boolean | null,
          lastAccess?: string | null,
          customerId?: string | null,
          currentModelId?: string | null,
          advisorModelId?: string | null,
          currentPlanId?: string | null,
          models?:  {
            __typename: "ModelModelConnection",
            nextToken?: string | null,
          } | null,
          migrateStatus?: string | null,
          migratedOn?: string | null,
          subscribedOn?: string | null,
          cancelledOn?: string | null,
          referrer?: string | null,
          settings?:  {
            __typename: "ModelUserSettingConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string | null,
    mfaPreference?: string | null,
    role: UserRole,
    advisorAccess?: boolean | null,
    lastAccess?: string | null,
    customerId?: string | null,
    currentModelId?: string | null,
    advisorModelId?: string | null,
    currentPlanId?: string | null,
    models?:  {
      __typename: "ModelModelConnection",
      items:  Array< {
        __typename: "Model",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        name: string,
        description?: string | null,
        startedAt?: string | null,
        advisorCreated?: boolean | null,
        clientAccess?: ClientAccess | null,
        settings?: string | null,
        persons?:  {
          __typename: "ModelPersonConnection",
          items:  Array< {
            __typename: "Person",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            nickname?: string | null,
            gender?: Gender | null,
            maritalStatus?: MaritalStatus | null,
            birthDate?: string | null,
            hereditaryAdjust?: number | null,
            lifeExpectancy?: number | null,
            retireDate?: string | null,
            state?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        plans?:  {
          __typename: "ModelPlanConnection",
          items:  Array< {
            __typename: "Plan",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            name?: string | null,
            description?: string | null,
            enabled?: boolean | null,
            default?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        incomes?:  {
          __typename: "ModelIncomeConnection",
          items:  Array< {
            __typename: "Income",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            incomeType?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            survivorPercent?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        deductions?:  {
          __typename: "ModelDeductionConnection",
          items:  Array< {
            __typename: "Deduction",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            description?: string | null,
            sortOrder?: number | null,
            assetId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        taxes?:  {
          __typename: "ModelTaxConnection",
          items:  Array< {
            __typename: "Tax",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            taxType?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        expenses?:  {
          __typename: "ModelExpenseConnection",
          items:  Array< {
            __typename: "Expense",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            expenseCategory?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            discretionary?: boolean | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            assetId?: string | null,
            liabilityId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        assets?:  {
          __typename: "ModelAssetConnection",
          items:  Array< {
            __typename: "Asset",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            assetCategory?: number | null,
            assetType?: number | null,
            description?: string | null,
            risk?: number | null,
            balance?: number | null,
            balanceDate?: string | null,
            returnRate?: number | null,
            rateLock?: boolean | null,
            withdrawalOrder?: number | null,
            inheritedYear?: number | null,
            originalOwnerBirthYear?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            priorEoYBalance?: number | null,
            withdrawalsYTD?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        liabilities?:  {
          __typename: "ModelLiabilityConnection",
          items:  Array< {
            __typename: "Liability",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            description?: string | null,
            balance?: number | null,
            balanceDate?: string | null,
            payoffDate?: string | null,
            ownerId?: string | null,
            sortOrder?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            interestRate?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        snapshots?:  {
          __typename: "ModelSnapshotConnection",
          items:  Array< {
            __typename: "Snapshot",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            date: string,
            description?: string | null,
            details?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        taxValues?:  {
          __typename: "ModelTaxValueConnection",
          items:  Array< {
            __typename: "TaxValue",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            year: number,
            key: string,
            value: number,
          } | null >,
          nextToken?: string | null,
        } | null,
        assetConversions?:  {
          __typename: "ModelAssetConversionConnection",
          items:  Array< {
            __typename: "AssetConversion",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            description?: string | null,
            conversionType?: string | null,
            srcAssetId: string,
            dstAssetId: string,
            year?: number | null,
            amount?: number | null,
            percent?: number | null,
            sortOrder?: number | null,
            completedAt?: string | null,
            taxableAmount?: number | null,
            nonTaxableAmount?: number | null,
            incomeType?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    migrateStatus?: string | null,
    migratedOn?: string | null,
    subscribedOn?: string | null,
    cancelledOn?: string | null,
    referrer?: string | null,
    settings?:  {
      __typename: "ModelUserSettingConnection",
      items:  Array< {
        __typename: "UserSetting",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        name: string,
        description?: string | null,
        settingType?: string | null,
        settings?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  id?: string | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userType: UserType,
      userStatus: UserStatus,
      startDate?: string | null,
      endDate?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        users?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userType: UserType,
            userStatus: UserStatus,
            startDate?: string | null,
            endDate?: string | null,
            accountId: string,
            firstName: string,
            lastName: string,
            email: string,
            phone?: string | null,
            mfaPreference?: string | null,
            role: UserRole,
            advisorAccess?: boolean | null,
            lastAccess?: string | null,
            customerId?: string | null,
            currentModelId?: string | null,
            advisorModelId?: string | null,
            currentPlanId?: string | null,
            migrateStatus?: string | null,
            migratedOn?: string | null,
            subscribedOn?: string | null,
            cancelledOn?: string | null,
            referrer?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      phone?: string | null,
      mfaPreference?: string | null,
      role: UserRole,
      advisorAccess?: boolean | null,
      lastAccess?: string | null,
      customerId?: string | null,
      currentModelId?: string | null,
      advisorModelId?: string | null,
      currentPlanId?: string | null,
      models?:  {
        __typename: "ModelModelConnection",
        items:  Array< {
          __typename: "Model",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          name: string,
          description?: string | null,
          startedAt?: string | null,
          advisorCreated?: boolean | null,
          clientAccess?: ClientAccess | null,
          settings?: string | null,
          persons?:  {
            __typename: "ModelPersonConnection",
            nextToken?: string | null,
          } | null,
          plans?:  {
            __typename: "ModelPlanConnection",
            nextToken?: string | null,
          } | null,
          incomes?:  {
            __typename: "ModelIncomeConnection",
            nextToken?: string | null,
          } | null,
          deductions?:  {
            __typename: "ModelDeductionConnection",
            nextToken?: string | null,
          } | null,
          taxes?:  {
            __typename: "ModelTaxConnection",
            nextToken?: string | null,
          } | null,
          expenses?:  {
            __typename: "ModelExpenseConnection",
            nextToken?: string | null,
          } | null,
          assets?:  {
            __typename: "ModelAssetConnection",
            nextToken?: string | null,
          } | null,
          liabilities?:  {
            __typename: "ModelLiabilityConnection",
            nextToken?: string | null,
          } | null,
          snapshots?:  {
            __typename: "ModelSnapshotConnection",
            nextToken?: string | null,
          } | null,
          taxValues?:  {
            __typename: "ModelTaxValueConnection",
            nextToken?: string | null,
          } | null,
          assetConversions?:  {
            __typename: "ModelAssetConversionConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      migrateStatus?: string | null,
      migratedOn?: string | null,
      subscribedOn?: string | null,
      cancelledOn?: string | null,
      referrer?: string | null,
      settings?:  {
        __typename: "ModelUserSettingConnection",
        items:  Array< {
          __typename: "UserSetting",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          name: string,
          description?: string | null,
          settingType?: string | null,
          settings?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserSettingQueryVariables = {
  id: string,
};

export type GetUserSettingQuery = {
  getUserSetting?:  {
    __typename: "UserSetting",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    name: string,
    description?: string | null,
    settingType?: string | null,
    settings?: string | null,
  } | null,
};

export type ListUserSettingsQueryVariables = {
  id?: string | null,
  filter?: ModelUserSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserSettingsQuery = {
  listUserSettings?:  {
    __typename: "ModelUserSettingConnection",
    items:  Array< {
      __typename: "UserSetting",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      name: string,
      description?: string | null,
      settingType?: string | null,
      settings?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetModelQueryVariables = {
  id: string,
};

export type GetModelQuery = {
  getModel?:  {
    __typename: "Model",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    name: string,
    description?: string | null,
    startedAt?: string | null,
    advisorCreated?: boolean | null,
    clientAccess?: ClientAccess | null,
    settings?: string | null,
    persons?:  {
      __typename: "ModelPersonConnection",
      items:  Array< {
        __typename: "Person",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        nickname?: string | null,
        gender?: Gender | null,
        maritalStatus?: MaritalStatus | null,
        birthDate?: string | null,
        hereditaryAdjust?: number | null,
        lifeExpectancy?: number | null,
        retireDate?: string | null,
        state?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    plans?:  {
      __typename: "ModelPlanConnection",
      items:  Array< {
        __typename: "Plan",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        name?: string | null,
        description?: string | null,
        enabled?: boolean | null,
        default?: boolean | null,
        changes?:  {
          __typename: "ModelPlanChangeConnection",
          items:  Array< {
            __typename: "PlanChange",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            planId: string,
            changeType: string,
            name: string,
            description?: string | null,
            enabled?: boolean | null,
            details?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    incomes?:  {
      __typename: "ModelIncomeConnection",
      items:  Array< {
        __typename: "Income",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        incomeType?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        survivorPercent?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deductions?:  {
      __typename: "ModelDeductionConnection",
      items:  Array< {
        __typename: "Deduction",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        description?: string | null,
        sortOrder?: number | null,
        assetId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    taxes?:  {
      __typename: "ModelTaxConnection",
      items:  Array< {
        __typename: "Tax",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        taxType?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    expenses?:  {
      __typename: "ModelExpenseConnection",
      items:  Array< {
        __typename: "Expense",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        expenseCategory?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        discretionary?: boolean | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        assetId?: string | null,
        liabilityId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    assets?:  {
      __typename: "ModelAssetConnection",
      items:  Array< {
        __typename: "Asset",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        assetCategory?: number | null,
        assetType?: number | null,
        description?: string | null,
        risk?: number | null,
        balance?: number | null,
        balanceDate?: string | null,
        returnRate?: number | null,
        rateLock?: boolean | null,
        withdrawalOrder?: number | null,
        inheritedYear?: number | null,
        originalOwnerBirthYear?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        priorEoYBalance?: number | null,
        withdrawalsYTD?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    liabilities?:  {
      __typename: "ModelLiabilityConnection",
      items:  Array< {
        __typename: "Liability",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        description?: string | null,
        balance?: number | null,
        balanceDate?: string | null,
        payoffDate?: string | null,
        ownerId?: string | null,
        sortOrder?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        interestRate?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    snapshots?:  {
      __typename: "ModelSnapshotConnection",
      items:  Array< {
        __typename: "Snapshot",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        date: string,
        description?: string | null,
        details?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    taxValues?:  {
      __typename: "ModelTaxValueConnection",
      items:  Array< {
        __typename: "TaxValue",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        year: number,
        key: string,
        value: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    assetConversions?:  {
      __typename: "ModelAssetConversionConnection",
      items:  Array< {
        __typename: "AssetConversion",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        description?: string | null,
        conversionType?: string | null,
        srcAssetId: string,
        dstAssetId: string,
        year?: number | null,
        amount?: number | null,
        percent?: number | null,
        sortOrder?: number | null,
        completedAt?: string | null,
        taxableAmount?: number | null,
        nonTaxableAmount?: number | null,
        incomeType?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListModelsQueryVariables = {
  id?: string | null,
  filter?: ModelModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListModelsQuery = {
  listModels?:  {
    __typename: "ModelModelConnection",
    items:  Array< {
      __typename: "Model",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      name: string,
      description?: string | null,
      startedAt?: string | null,
      advisorCreated?: boolean | null,
      clientAccess?: ClientAccess | null,
      settings?: string | null,
      persons?:  {
        __typename: "ModelPersonConnection",
        items:  Array< {
          __typename: "Person",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          nickname?: string | null,
          gender?: Gender | null,
          maritalStatus?: MaritalStatus | null,
          birthDate?: string | null,
          hereditaryAdjust?: number | null,
          lifeExpectancy?: number | null,
          retireDate?: string | null,
          state?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      plans?:  {
        __typename: "ModelPlanConnection",
        items:  Array< {
          __typename: "Plan",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          name?: string | null,
          description?: string | null,
          enabled?: boolean | null,
          default?: boolean | null,
          changes?:  {
            __typename: "ModelPlanChangeConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      incomes?:  {
        __typename: "ModelIncomeConnection",
        items:  Array< {
          __typename: "Income",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          incomeType?: number | null,
          description?: string | null,
          amount?: number | null,
          schedule?: string | null,
          infAdjust?: boolean | null,
          infLock?: boolean | null,
          annualInf?: number | null,
          start?: string | null,
          end?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          sortOrder?: number | null,
          ownerId?: string | null,
          survivorPercent?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      deductions?:  {
        __typename: "ModelDeductionConnection",
        items:  Array< {
          __typename: "Deduction",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          amount?: number | null,
          schedule?: string | null,
          infAdjust?: boolean | null,
          infLock?: boolean | null,
          annualInf?: number | null,
          start?: string | null,
          end?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          description?: string | null,
          sortOrder?: number | null,
          assetId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      taxes?:  {
        __typename: "ModelTaxConnection",
        items:  Array< {
          __typename: "Tax",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          taxType?: number | null,
          description?: string | null,
          amount?: number | null,
          schedule?: string | null,
          infAdjust?: boolean | null,
          infLock?: boolean | null,
          annualInf?: number | null,
          start?: string | null,
          end?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          sortOrder?: number | null,
          ownerId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      expenses?:  {
        __typename: "ModelExpenseConnection",
        items:  Array< {
          __typename: "Expense",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          expenseCategory?: number | null,
          description?: string | null,
          amount?: number | null,
          schedule?: string | null,
          infAdjust?: boolean | null,
          infLock?: boolean | null,
          annualInf?: number | null,
          start?: string | null,
          end?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          discretionary?: boolean | null,
          sortOrder?: number | null,
          ownerId?: string | null,
          assetId?: string | null,
          liabilityId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      assets?:  {
        __typename: "ModelAssetConnection",
        items:  Array< {
          __typename: "Asset",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          assetCategory?: number | null,
          assetType?: number | null,
          description?: string | null,
          risk?: number | null,
          balance?: number | null,
          balanceDate?: string | null,
          returnRate?: number | null,
          rateLock?: boolean | null,
          withdrawalOrder?: number | null,
          inheritedYear?: number | null,
          originalOwnerBirthYear?: number | null,
          start?: string | null,
          end?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          sortOrder?: number | null,
          ownerId?: string | null,
          priorEoYBalance?: number | null,
          withdrawalsYTD?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      liabilities?:  {
        __typename: "ModelLiabilityConnection",
        items:  Array< {
          __typename: "Liability",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          description?: string | null,
          balance?: number | null,
          balanceDate?: string | null,
          payoffDate?: string | null,
          ownerId?: string | null,
          sortOrder?: number | null,
          start?: string | null,
          end?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          interestRate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      snapshots?:  {
        __typename: "ModelSnapshotConnection",
        items:  Array< {
          __typename: "Snapshot",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          date: string,
          description?: string | null,
          details?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      taxValues?:  {
        __typename: "ModelTaxValueConnection",
        items:  Array< {
          __typename: "TaxValue",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          year: number,
          key: string,
          value: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      assetConversions?:  {
        __typename: "ModelAssetConversionConnection",
        items:  Array< {
          __typename: "AssetConversion",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          description?: string | null,
          conversionType?: string | null,
          srcAssetId: string,
          dstAssetId: string,
          year?: number | null,
          amount?: number | null,
          percent?: number | null,
          sortOrder?: number | null,
          completedAt?: string | null,
          taxableAmount?: number | null,
          nonTaxableAmount?: number | null,
          incomeType?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSnapshotQueryVariables = {
  id: string,
};

export type GetSnapshotQuery = {
  getSnapshot?:  {
    __typename: "Snapshot",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    date: string,
    description?: string | null,
    details?: string | null,
  } | null,
};

export type ListSnapshotsQueryVariables = {
  id?: string | null,
  filter?: ModelSnapshotFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSnapshotsQuery = {
  listSnapshots?:  {
    __typename: "ModelSnapshotConnection",
    items:  Array< {
      __typename: "Snapshot",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      date: string,
      description?: string | null,
      details?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPersonQueryVariables = {
  id: string,
};

export type GetPersonQuery = {
  getPerson?:  {
    __typename: "Person",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    nickname?: string | null,
    gender?: Gender | null,
    maritalStatus?: MaritalStatus | null,
    birthDate?: string | null,
    hereditaryAdjust?: number | null,
    lifeExpectancy?: number | null,
    retireDate?: string | null,
    state?: string | null,
  } | null,
};

export type ListPeopleQueryVariables = {
  id?: string | null,
  filter?: ModelPersonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPeopleQuery = {
  listPeople?:  {
    __typename: "ModelPersonConnection",
    items:  Array< {
      __typename: "Person",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      nickname?: string | null,
      gender?: Gender | null,
      maritalStatus?: MaritalStatus | null,
      birthDate?: string | null,
      hereditaryAdjust?: number | null,
      lifeExpectancy?: number | null,
      retireDate?: string | null,
      state?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPlanQueryVariables = {
  id: string,
};

export type GetPlanQuery = {
  getPlan?:  {
    __typename: "Plan",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    name?: string | null,
    description?: string | null,
    enabled?: boolean | null,
    default?: boolean | null,
    changes?:  {
      __typename: "ModelPlanChangeConnection",
      items:  Array< {
        __typename: "PlanChange",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        planId: string,
        changeType: string,
        name: string,
        description?: string | null,
        enabled?: boolean | null,
        details?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListPlansQueryVariables = {
  id?: string | null,
  filter?: ModelPlanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPlansQuery = {
  listPlans?:  {
    __typename: "ModelPlanConnection",
    items:  Array< {
      __typename: "Plan",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      name?: string | null,
      description?: string | null,
      enabled?: boolean | null,
      default?: boolean | null,
      changes?:  {
        __typename: "ModelPlanChangeConnection",
        items:  Array< {
          __typename: "PlanChange",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          planId: string,
          changeType: string,
          name: string,
          description?: string | null,
          enabled?: boolean | null,
          details?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPlanChangeQueryVariables = {
  id: string,
};

export type GetPlanChangeQuery = {
  getPlanChange?:  {
    __typename: "PlanChange",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    planId: string,
    changeType: string,
    name: string,
    description?: string | null,
    enabled?: boolean | null,
    details?: string | null,
  } | null,
};

export type ListPlanChangesQueryVariables = {
  id?: string | null,
  filter?: ModelPlanChangeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPlanChangesQuery = {
  listPlanChanges?:  {
    __typename: "ModelPlanChangeConnection",
    items:  Array< {
      __typename: "PlanChange",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      planId: string,
      changeType: string,
      name: string,
      description?: string | null,
      enabled?: boolean | null,
      details?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIncomeQueryVariables = {
  id: string,
};

export type GetIncomeQuery = {
  getIncome?:  {
    __typename: "Income",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    incomeType?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    survivorPercent?: number | null,
  } | null,
};

export type ListIncomesQueryVariables = {
  id?: string | null,
  filter?: ModelIncomeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListIncomesQuery = {
  listIncomes?:  {
    __typename: "ModelIncomeConnection",
    items:  Array< {
      __typename: "Income",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      incomeType?: number | null,
      description?: string | null,
      amount?: number | null,
      schedule?: string | null,
      infAdjust?: boolean | null,
      infLock?: boolean | null,
      annualInf?: number | null,
      start?: string | null,
      end?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      sortOrder?: number | null,
      ownerId?: string | null,
      survivorPercent?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIncomeTypeQueryVariables = {
  id: string,
};

export type GetIncomeTypeQuery = {
  getIncomeType?:  {
    __typename: "IncomeType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
  } | null,
};

export type ListIncomeTypesQueryVariables = {
  id?: string | null,
  filter?: ModelIncomeTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListIncomeTypesQuery = {
  listIncomeTypes?:  {
    __typename: "ModelIncomeTypeConnection",
    items:  Array< {
      __typename: "IncomeType",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      description?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDeductionQueryVariables = {
  id: string,
};

export type GetDeductionQuery = {
  getDeduction?:  {
    __typename: "Deduction",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    description?: string | null,
    sortOrder?: number | null,
    assetId?: string | null,
  } | null,
};

export type ListDeductionsQueryVariables = {
  id?: string | null,
  filter?: ModelDeductionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDeductionsQuery = {
  listDeductions?:  {
    __typename: "ModelDeductionConnection",
    items:  Array< {
      __typename: "Deduction",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      amount?: number | null,
      schedule?: string | null,
      infAdjust?: boolean | null,
      infLock?: boolean | null,
      annualInf?: number | null,
      start?: string | null,
      end?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      description?: string | null,
      sortOrder?: number | null,
      assetId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetExpenseQueryVariables = {
  id: string,
};

export type GetExpenseQuery = {
  getExpense?:  {
    __typename: "Expense",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    expenseCategory?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    discretionary?: boolean | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    assetId?: string | null,
    liabilityId?: string | null,
  } | null,
};

export type ListExpensesQueryVariables = {
  id?: string | null,
  filter?: ModelExpenseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListExpensesQuery = {
  listExpenses?:  {
    __typename: "ModelExpenseConnection",
    items:  Array< {
      __typename: "Expense",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      expenseCategory?: number | null,
      description?: string | null,
      amount?: number | null,
      schedule?: string | null,
      infAdjust?: boolean | null,
      infLock?: boolean | null,
      annualInf?: number | null,
      start?: string | null,
      end?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      discretionary?: boolean | null,
      sortOrder?: number | null,
      ownerId?: string | null,
      assetId?: string | null,
      liabilityId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetExpenseCategoryQueryVariables = {
  id: string,
};

export type GetExpenseCategoryQuery = {
  getExpenseCategory?:  {
    __typename: "ExpenseCategory",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
    sortOrder?: number | null,
  } | null,
};

export type ListExpenseCategoriesQueryVariables = {
  id?: string | null,
  filter?: ModelExpenseCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListExpenseCategoriesQuery = {
  listExpenseCategories?:  {
    __typename: "ModelExpenseCategoryConnection",
    items:  Array< {
      __typename: "ExpenseCategory",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      description?: string | null,
      sortOrder?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTaxQueryVariables = {
  id: string,
};

export type GetTaxQuery = {
  getTax?:  {
    __typename: "Tax",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    taxType?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
  } | null,
};

export type ListTaxesQueryVariables = {
  id?: string | null,
  filter?: ModelTaxFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTaxesQuery = {
  listTaxes?:  {
    __typename: "ModelTaxConnection",
    items:  Array< {
      __typename: "Tax",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      taxType?: number | null,
      description?: string | null,
      amount?: number | null,
      schedule?: string | null,
      infAdjust?: boolean | null,
      infLock?: boolean | null,
      annualInf?: number | null,
      start?: string | null,
      end?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      sortOrder?: number | null,
      ownerId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAssetQueryVariables = {
  id: string,
};

export type GetAssetQuery = {
  getAsset?:  {
    __typename: "Asset",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    assetCategory?: number | null,
    assetType?: number | null,
    description?: string | null,
    risk?: number | null,
    balance?: number | null,
    balanceDate?: string | null,
    returnRate?: number | null,
    rateLock?: boolean | null,
    withdrawalOrder?: number | null,
    inheritedYear?: number | null,
    originalOwnerBirthYear?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    priorEoYBalance?: number | null,
    withdrawalsYTD?: number | null,
  } | null,
};

export type ListAssetsQueryVariables = {
  id?: string | null,
  filter?: ModelAssetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAssetsQuery = {
  listAssets?:  {
    __typename: "ModelAssetConnection",
    items:  Array< {
      __typename: "Asset",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      assetCategory?: number | null,
      assetType?: number | null,
      description?: string | null,
      risk?: number | null,
      balance?: number | null,
      balanceDate?: string | null,
      returnRate?: number | null,
      rateLock?: boolean | null,
      withdrawalOrder?: number | null,
      inheritedYear?: number | null,
      originalOwnerBirthYear?: number | null,
      start?: string | null,
      end?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      sortOrder?: number | null,
      ownerId?: string | null,
      priorEoYBalance?: number | null,
      withdrawalsYTD?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAssetCategoryQueryVariables = {
  id: string,
};

export type GetAssetCategoryQuery = {
  getAssetCategory?:  {
    __typename: "AssetCategory",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
    sortOrder?: number | null,
  } | null,
};

export type ListAssetCategoriesQueryVariables = {
  id?: string | null,
  filter?: ModelAssetCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAssetCategoriesQuery = {
  listAssetCategories?:  {
    __typename: "ModelAssetCategoryConnection",
    items:  Array< {
      __typename: "AssetCategory",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      description?: string | null,
      sortOrder?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAssetTypeQueryVariables = {
  id: string,
};

export type GetAssetTypeQuery = {
  getAssetType?:  {
    __typename: "AssetType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
  } | null,
};

export type ListAssetTypesQueryVariables = {
  id?: string | null,
  filter?: ModelAssetTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAssetTypesQuery = {
  listAssetTypes?:  {
    __typename: "ModelAssetTypeConnection",
    items:  Array< {
      __typename: "AssetType",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      description?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAssetConversionQueryVariables = {
  id: string,
};

export type GetAssetConversionQuery = {
  getAssetConversion?:  {
    __typename: "AssetConversion",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    description?: string | null,
    conversionType?: string | null,
    srcAssetId: string,
    dstAssetId: string,
    year?: number | null,
    amount?: number | null,
    percent?: number | null,
    sortOrder?: number | null,
    completedAt?: string | null,
    taxableAmount?: number | null,
    nonTaxableAmount?: number | null,
    incomeType?: number | null,
  } | null,
};

export type ListAssetConversionsQueryVariables = {
  id?: string | null,
  filter?: ModelAssetConversionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAssetConversionsQuery = {
  listAssetConversions?:  {
    __typename: "ModelAssetConversionConnection",
    items:  Array< {
      __typename: "AssetConversion",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      description?: string | null,
      conversionType?: string | null,
      srcAssetId: string,
      dstAssetId: string,
      year?: number | null,
      amount?: number | null,
      percent?: number | null,
      sortOrder?: number | null,
      completedAt?: string | null,
      taxableAmount?: number | null,
      nonTaxableAmount?: number | null,
      incomeType?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLiabilityQueryVariables = {
  id: string,
};

export type GetLiabilityQuery = {
  getLiability?:  {
    __typename: "Liability",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    description?: string | null,
    balance?: number | null,
    balanceDate?: string | null,
    payoffDate?: string | null,
    ownerId?: string | null,
    sortOrder?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    interestRate?: number | null,
  } | null,
};

export type ListLiabilitiesQueryVariables = {
  id?: string | null,
  filter?: ModelLiabilityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListLiabilitiesQuery = {
  listLiabilities?:  {
    __typename: "ModelLiabilityConnection",
    items:  Array< {
      __typename: "Liability",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      description?: string | null,
      balance?: number | null,
      balanceDate?: string | null,
      payoffDate?: string | null,
      ownerId?: string | null,
      sortOrder?: number | null,
      start?: string | null,
      end?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      interestRate?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTaxValueQueryVariables = {
  id: string,
};

export type GetTaxValueQuery = {
  getTaxValue?:  {
    __typename: "TaxValue",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    year: number,
    key: string,
    value: number,
  } | null,
};

export type ListTaxValuesQueryVariables = {
  id?: string | null,
  filter?: ModelTaxValueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTaxValuesQuery = {
  listTaxValues?:  {
    __typename: "ModelTaxValueConnection",
    items:  Array< {
      __typename: "TaxValue",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      year: number,
      key: string,
      value: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUsersByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersByAccountQuery = {
  listUsersByAccount?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userType: UserType,
      userStatus: UserStatus,
      startDate?: string | null,
      endDate?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        users?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userType: UserType,
            userStatus: UserStatus,
            startDate?: string | null,
            endDate?: string | null,
            accountId: string,
            firstName: string,
            lastName: string,
            email: string,
            phone?: string | null,
            mfaPreference?: string | null,
            role: UserRole,
            advisorAccess?: boolean | null,
            lastAccess?: string | null,
            customerId?: string | null,
            currentModelId?: string | null,
            advisorModelId?: string | null,
            currentPlanId?: string | null,
            migrateStatus?: string | null,
            migratedOn?: string | null,
            subscribedOn?: string | null,
            cancelledOn?: string | null,
            referrer?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      phone?: string | null,
      mfaPreference?: string | null,
      role: UserRole,
      advisorAccess?: boolean | null,
      lastAccess?: string | null,
      customerId?: string | null,
      currentModelId?: string | null,
      advisorModelId?: string | null,
      currentPlanId?: string | null,
      models?:  {
        __typename: "ModelModelConnection",
        items:  Array< {
          __typename: "Model",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          name: string,
          description?: string | null,
          startedAt?: string | null,
          advisorCreated?: boolean | null,
          clientAccess?: ClientAccess | null,
          settings?: string | null,
          persons?:  {
            __typename: "ModelPersonConnection",
            nextToken?: string | null,
          } | null,
          plans?:  {
            __typename: "ModelPlanConnection",
            nextToken?: string | null,
          } | null,
          incomes?:  {
            __typename: "ModelIncomeConnection",
            nextToken?: string | null,
          } | null,
          deductions?:  {
            __typename: "ModelDeductionConnection",
            nextToken?: string | null,
          } | null,
          taxes?:  {
            __typename: "ModelTaxConnection",
            nextToken?: string | null,
          } | null,
          expenses?:  {
            __typename: "ModelExpenseConnection",
            nextToken?: string | null,
          } | null,
          assets?:  {
            __typename: "ModelAssetConnection",
            nextToken?: string | null,
          } | null,
          liabilities?:  {
            __typename: "ModelLiabilityConnection",
            nextToken?: string | null,
          } | null,
          snapshots?:  {
            __typename: "ModelSnapshotConnection",
            nextToken?: string | null,
          } | null,
          taxValues?:  {
            __typename: "ModelTaxValueConnection",
            nextToken?: string | null,
          } | null,
          assetConversions?:  {
            __typename: "ModelAssetConversionConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      migrateStatus?: string | null,
      migratedOn?: string | null,
      subscribedOn?: string | null,
      cancelledOn?: string | null,
      referrer?: string | null,
      settings?:  {
        __typename: "ModelUserSettingConnection",
        items:  Array< {
          __typename: "UserSetting",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          name: string,
          description?: string | null,
          settingType?: string | null,
          settings?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserSettingsByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserSettingsByUserQuery = {
  listUserSettingsByUser?:  {
    __typename: "ModelUserSettingConnection",
    items:  Array< {
      __typename: "UserSetting",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      name: string,
      description?: string | null,
      settingType?: string | null,
      settings?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListModelsByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListModelsByUserQuery = {
  listModelsByUser?:  {
    __typename: "ModelModelConnection",
    items:  Array< {
      __typename: "Model",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      name: string,
      description?: string | null,
      startedAt?: string | null,
      advisorCreated?: boolean | null,
      clientAccess?: ClientAccess | null,
      settings?: string | null,
      persons?:  {
        __typename: "ModelPersonConnection",
        items:  Array< {
          __typename: "Person",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          nickname?: string | null,
          gender?: Gender | null,
          maritalStatus?: MaritalStatus | null,
          birthDate?: string | null,
          hereditaryAdjust?: number | null,
          lifeExpectancy?: number | null,
          retireDate?: string | null,
          state?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      plans?:  {
        __typename: "ModelPlanConnection",
        items:  Array< {
          __typename: "Plan",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          name?: string | null,
          description?: string | null,
          enabled?: boolean | null,
          default?: boolean | null,
          changes?:  {
            __typename: "ModelPlanChangeConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      incomes?:  {
        __typename: "ModelIncomeConnection",
        items:  Array< {
          __typename: "Income",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          incomeType?: number | null,
          description?: string | null,
          amount?: number | null,
          schedule?: string | null,
          infAdjust?: boolean | null,
          infLock?: boolean | null,
          annualInf?: number | null,
          start?: string | null,
          end?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          sortOrder?: number | null,
          ownerId?: string | null,
          survivorPercent?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      deductions?:  {
        __typename: "ModelDeductionConnection",
        items:  Array< {
          __typename: "Deduction",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          amount?: number | null,
          schedule?: string | null,
          infAdjust?: boolean | null,
          infLock?: boolean | null,
          annualInf?: number | null,
          start?: string | null,
          end?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          description?: string | null,
          sortOrder?: number | null,
          assetId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      taxes?:  {
        __typename: "ModelTaxConnection",
        items:  Array< {
          __typename: "Tax",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          taxType?: number | null,
          description?: string | null,
          amount?: number | null,
          schedule?: string | null,
          infAdjust?: boolean | null,
          infLock?: boolean | null,
          annualInf?: number | null,
          start?: string | null,
          end?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          sortOrder?: number | null,
          ownerId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      expenses?:  {
        __typename: "ModelExpenseConnection",
        items:  Array< {
          __typename: "Expense",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          expenseCategory?: number | null,
          description?: string | null,
          amount?: number | null,
          schedule?: string | null,
          infAdjust?: boolean | null,
          infLock?: boolean | null,
          annualInf?: number | null,
          start?: string | null,
          end?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          discretionary?: boolean | null,
          sortOrder?: number | null,
          ownerId?: string | null,
          assetId?: string | null,
          liabilityId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      assets?:  {
        __typename: "ModelAssetConnection",
        items:  Array< {
          __typename: "Asset",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          assetCategory?: number | null,
          assetType?: number | null,
          description?: string | null,
          risk?: number | null,
          balance?: number | null,
          balanceDate?: string | null,
          returnRate?: number | null,
          rateLock?: boolean | null,
          withdrawalOrder?: number | null,
          inheritedYear?: number | null,
          originalOwnerBirthYear?: number | null,
          start?: string | null,
          end?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          sortOrder?: number | null,
          ownerId?: string | null,
          priorEoYBalance?: number | null,
          withdrawalsYTD?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      liabilities?:  {
        __typename: "ModelLiabilityConnection",
        items:  Array< {
          __typename: "Liability",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          description?: string | null,
          balance?: number | null,
          balanceDate?: string | null,
          payoffDate?: string | null,
          ownerId?: string | null,
          sortOrder?: number | null,
          start?: string | null,
          end?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          interestRate?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      snapshots?:  {
        __typename: "ModelSnapshotConnection",
        items:  Array< {
          __typename: "Snapshot",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          date: string,
          description?: string | null,
          details?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      taxValues?:  {
        __typename: "ModelTaxValueConnection",
        items:  Array< {
          __typename: "TaxValue",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          year: number,
          key: string,
          value: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      assetConversions?:  {
        __typename: "ModelAssetConversionConnection",
        items:  Array< {
          __typename: "AssetConversion",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          description?: string | null,
          conversionType?: string | null,
          srcAssetId: string,
          dstAssetId: string,
          year?: number | null,
          amount?: number | null,
          percent?: number | null,
          sortOrder?: number | null,
          completedAt?: string | null,
          taxableAmount?: number | null,
          nonTaxableAmount?: number | null,
          incomeType?: number | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSnapshotsByModelQueryVariables = {
  modelId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSnapshotFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSnapshotsByModelQuery = {
  listSnapshotsByModel?:  {
    __typename: "ModelSnapshotConnection",
    items:  Array< {
      __typename: "Snapshot",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      date: string,
      description?: string | null,
      details?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPersonsByModelQueryVariables = {
  modelId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPersonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPersonsByModelQuery = {
  listPersonsByModel?:  {
    __typename: "ModelPersonConnection",
    items:  Array< {
      __typename: "Person",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      nickname?: string | null,
      gender?: Gender | null,
      maritalStatus?: MaritalStatus | null,
      birthDate?: string | null,
      hereditaryAdjust?: number | null,
      lifeExpectancy?: number | null,
      retireDate?: string | null,
      state?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPlansByModelQueryVariables = {
  modelId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPlansByModelQuery = {
  listPlansByModel?:  {
    __typename: "ModelPlanConnection",
    items:  Array< {
      __typename: "Plan",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      name?: string | null,
      description?: string | null,
      enabled?: boolean | null,
      default?: boolean | null,
      changes?:  {
        __typename: "ModelPlanChangeConnection",
        items:  Array< {
          __typename: "PlanChange",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId: string,
          accountId: string,
          modelId: string,
          planId: string,
          changeType: string,
          name: string,
          description?: string | null,
          enabled?: boolean | null,
          details?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListChangesByPlanQueryVariables = {
  planId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlanChangeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChangesByPlanQuery = {
  listChangesByPlan?:  {
    __typename: "ModelPlanChangeConnection",
    items:  Array< {
      __typename: "PlanChange",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      planId: string,
      changeType: string,
      name: string,
      description?: string | null,
      enabled?: boolean | null,
      details?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListIncomesByModelQueryVariables = {
  modelId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIncomeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIncomesByModelQuery = {
  listIncomesByModel?:  {
    __typename: "ModelIncomeConnection",
    items:  Array< {
      __typename: "Income",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      incomeType?: number | null,
      description?: string | null,
      amount?: number | null,
      schedule?: string | null,
      infAdjust?: boolean | null,
      infLock?: boolean | null,
      annualInf?: number | null,
      start?: string | null,
      end?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      sortOrder?: number | null,
      ownerId?: string | null,
      survivorPercent?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListDeductionsByModelQueryVariables = {
  modelId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDeductionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeductionsByModelQuery = {
  listDeductionsByModel?:  {
    __typename: "ModelDeductionConnection",
    items:  Array< {
      __typename: "Deduction",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      amount?: number | null,
      schedule?: string | null,
      infAdjust?: boolean | null,
      infLock?: boolean | null,
      annualInf?: number | null,
      start?: string | null,
      end?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      description?: string | null,
      sortOrder?: number | null,
      assetId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListExpensesByModelQueryVariables = {
  modelId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExpenseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExpensesByModelQuery = {
  listExpensesByModel?:  {
    __typename: "ModelExpenseConnection",
    items:  Array< {
      __typename: "Expense",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      expenseCategory?: number | null,
      description?: string | null,
      amount?: number | null,
      schedule?: string | null,
      infAdjust?: boolean | null,
      infLock?: boolean | null,
      annualInf?: number | null,
      start?: string | null,
      end?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      discretionary?: boolean | null,
      sortOrder?: number | null,
      ownerId?: string | null,
      assetId?: string | null,
      liabilityId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTaxesByModelQueryVariables = {
  modelId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaxFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTaxesByModelQuery = {
  listTaxesByModel?:  {
    __typename: "ModelTaxConnection",
    items:  Array< {
      __typename: "Tax",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      taxType?: number | null,
      description?: string | null,
      amount?: number | null,
      schedule?: string | null,
      infAdjust?: boolean | null,
      infLock?: boolean | null,
      annualInf?: number | null,
      start?: string | null,
      end?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      sortOrder?: number | null,
      ownerId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAssetsByModelQueryVariables = {
  modelId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAssetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAssetsByModelQuery = {
  listAssetsByModel?:  {
    __typename: "ModelAssetConnection",
    items:  Array< {
      __typename: "Asset",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      assetCategory?: number | null,
      assetType?: number | null,
      description?: string | null,
      risk?: number | null,
      balance?: number | null,
      balanceDate?: string | null,
      returnRate?: number | null,
      rateLock?: boolean | null,
      withdrawalOrder?: number | null,
      inheritedYear?: number | null,
      originalOwnerBirthYear?: number | null,
      start?: string | null,
      end?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      sortOrder?: number | null,
      ownerId?: string | null,
      priorEoYBalance?: number | null,
      withdrawalsYTD?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAssetConversionsByModelQueryVariables = {
  modelId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAssetConversionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAssetConversionsByModelQuery = {
  listAssetConversionsByModel?:  {
    __typename: "ModelAssetConversionConnection",
    items:  Array< {
      __typename: "AssetConversion",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      description?: string | null,
      conversionType?: string | null,
      srcAssetId: string,
      dstAssetId: string,
      year?: number | null,
      amount?: number | null,
      percent?: number | null,
      sortOrder?: number | null,
      completedAt?: string | null,
      taxableAmount?: number | null,
      nonTaxableAmount?: number | null,
      incomeType?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListLiabilitiesByModelQueryVariables = {
  modelId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLiabilityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLiabilitiesByModelQuery = {
  listLiabilitiesByModel?:  {
    __typename: "ModelLiabilityConnection",
    items:  Array< {
      __typename: "Liability",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      description?: string | null,
      balance?: number | null,
      balanceDate?: string | null,
      payoffDate?: string | null,
      ownerId?: string | null,
      sortOrder?: number | null,
      start?: string | null,
      end?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      interestRate?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTaxValuesByModelQueryVariables = {
  modelId: string,
  year?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaxValueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTaxValuesByModelQuery = {
  listTaxValuesByModel?:  {
    __typename: "ModelTaxValueConnection",
    items:  Array< {
      __typename: "TaxValue",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      modelId: string,
      year: number,
      key: string,
      value: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserInviteQueryVariables = {
  id: string,
};

export type GetUserInviteQuery = {
  getUserInvite?:  {
    __typename: "UserInvite",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    role: UserRole,
    invitedOn: string,
  } | null,
};

export type ListUserInvitesQueryVariables = {
  id?: string | null,
  filter?: ModelUserInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserInvitesQuery = {
  listUserInvites?:  {
    __typename: "ModelUserInviteConnection",
    items:  Array< {
      __typename: "UserInvite",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      role: UserRole,
      invitedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserInvitesByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserInvitesByAccountQuery = {
  listUserInvitesByAccount?:  {
    __typename: "ModelUserInviteConnection",
    items:  Array< {
      __typename: "UserInvite",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      role: UserRole,
      invitedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
  ownerId?: string | null,
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userType: UserType,
        userStatus: UserStatus,
        startDate?: string | null,
        endDate?: string | null,
        accountId: string,
        account?:  {
          __typename: "Account",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          ownerId?: string | null,
          name: string,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          users?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        mfaPreference?: string | null,
        role: UserRole,
        advisorAccess?: boolean | null,
        lastAccess?: string | null,
        customerId?: string | null,
        currentModelId?: string | null,
        advisorModelId?: string | null,
        currentPlanId?: string | null,
        models?:  {
          __typename: "ModelModelConnection",
          items:  Array< {
            __typename: "Model",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            name: string,
            description?: string | null,
            startedAt?: string | null,
            advisorCreated?: boolean | null,
            clientAccess?: ClientAccess | null,
            settings?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        migrateStatus?: string | null,
        migratedOn?: string | null,
        subscribedOn?: string | null,
        cancelledOn?: string | null,
        referrer?: string | null,
        settings?:  {
          __typename: "ModelUserSettingConnection",
          items:  Array< {
            __typename: "UserSetting",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            name: string,
            description?: string | null,
            settingType?: string | null,
            settings?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
  ownerId?: string | null,
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userType: UserType,
        userStatus: UserStatus,
        startDate?: string | null,
        endDate?: string | null,
        accountId: string,
        account?:  {
          __typename: "Account",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          ownerId?: string | null,
          name: string,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          users?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        mfaPreference?: string | null,
        role: UserRole,
        advisorAccess?: boolean | null,
        lastAccess?: string | null,
        customerId?: string | null,
        currentModelId?: string | null,
        advisorModelId?: string | null,
        currentPlanId?: string | null,
        models?:  {
          __typename: "ModelModelConnection",
          items:  Array< {
            __typename: "Model",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            name: string,
            description?: string | null,
            startedAt?: string | null,
            advisorCreated?: boolean | null,
            clientAccess?: ClientAccess | null,
            settings?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        migrateStatus?: string | null,
        migratedOn?: string | null,
        subscribedOn?: string | null,
        cancelledOn?: string | null,
        referrer?: string | null,
        settings?:  {
          __typename: "ModelUserSettingConnection",
          items:  Array< {
            __typename: "UserSetting",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            name: string,
            description?: string | null,
            settingType?: string | null,
            settings?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
  ownerId?: string | null,
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userType: UserType,
        userStatus: UserStatus,
        startDate?: string | null,
        endDate?: string | null,
        accountId: string,
        account?:  {
          __typename: "Account",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          ownerId?: string | null,
          name: string,
          address?: string | null,
          city?: string | null,
          state?: string | null,
          postalCode?: string | null,
          users?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        mfaPreference?: string | null,
        role: UserRole,
        advisorAccess?: boolean | null,
        lastAccess?: string | null,
        customerId?: string | null,
        currentModelId?: string | null,
        advisorModelId?: string | null,
        currentPlanId?: string | null,
        models?:  {
          __typename: "ModelModelConnection",
          items:  Array< {
            __typename: "Model",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            name: string,
            description?: string | null,
            startedAt?: string | null,
            advisorCreated?: boolean | null,
            clientAccess?: ClientAccess | null,
            settings?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        migrateStatus?: string | null,
        migratedOn?: string | null,
        subscribedOn?: string | null,
        cancelledOn?: string | null,
        referrer?: string | null,
        settings?:  {
          __typename: "ModelUserSettingConnection",
          items:  Array< {
            __typename: "UserSetting",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            name: string,
            description?: string | null,
            settingType?: string | null,
            settings?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userType: UserType,
    userStatus: UserStatus,
    startDate?: string | null,
    endDate?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userType: UserType,
          userStatus: UserStatus,
          startDate?: string | null,
          endDate?: string | null,
          accountId: string,
          account?:  {
            __typename: "Account",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            ownerId?: string | null,
            name: string,
            address?: string | null,
            city?: string | null,
            state?: string | null,
            postalCode?: string | null,
          } | null,
          firstName: string,
          lastName: string,
          email: string,
          phone?: string | null,
          mfaPreference?: string | null,
          role: UserRole,
          advisorAccess?: boolean | null,
          lastAccess?: string | null,
          customerId?: string | null,
          currentModelId?: string | null,
          advisorModelId?: string | null,
          currentPlanId?: string | null,
          models?:  {
            __typename: "ModelModelConnection",
            nextToken?: string | null,
          } | null,
          migrateStatus?: string | null,
          migratedOn?: string | null,
          subscribedOn?: string | null,
          cancelledOn?: string | null,
          referrer?: string | null,
          settings?:  {
            __typename: "ModelUserSettingConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string | null,
    mfaPreference?: string | null,
    role: UserRole,
    advisorAccess?: boolean | null,
    lastAccess?: string | null,
    customerId?: string | null,
    currentModelId?: string | null,
    advisorModelId?: string | null,
    currentPlanId?: string | null,
    models?:  {
      __typename: "ModelModelConnection",
      items:  Array< {
        __typename: "Model",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        name: string,
        description?: string | null,
        startedAt?: string | null,
        advisorCreated?: boolean | null,
        clientAccess?: ClientAccess | null,
        settings?: string | null,
        persons?:  {
          __typename: "ModelPersonConnection",
          items:  Array< {
            __typename: "Person",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            nickname?: string | null,
            gender?: Gender | null,
            maritalStatus?: MaritalStatus | null,
            birthDate?: string | null,
            hereditaryAdjust?: number | null,
            lifeExpectancy?: number | null,
            retireDate?: string | null,
            state?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        plans?:  {
          __typename: "ModelPlanConnection",
          items:  Array< {
            __typename: "Plan",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            name?: string | null,
            description?: string | null,
            enabled?: boolean | null,
            default?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        incomes?:  {
          __typename: "ModelIncomeConnection",
          items:  Array< {
            __typename: "Income",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            incomeType?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            survivorPercent?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        deductions?:  {
          __typename: "ModelDeductionConnection",
          items:  Array< {
            __typename: "Deduction",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            description?: string | null,
            sortOrder?: number | null,
            assetId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        taxes?:  {
          __typename: "ModelTaxConnection",
          items:  Array< {
            __typename: "Tax",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            taxType?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        expenses?:  {
          __typename: "ModelExpenseConnection",
          items:  Array< {
            __typename: "Expense",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            expenseCategory?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            discretionary?: boolean | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            assetId?: string | null,
            liabilityId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        assets?:  {
          __typename: "ModelAssetConnection",
          items:  Array< {
            __typename: "Asset",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            assetCategory?: number | null,
            assetType?: number | null,
            description?: string | null,
            risk?: number | null,
            balance?: number | null,
            balanceDate?: string | null,
            returnRate?: number | null,
            rateLock?: boolean | null,
            withdrawalOrder?: number | null,
            inheritedYear?: number | null,
            originalOwnerBirthYear?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            priorEoYBalance?: number | null,
            withdrawalsYTD?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        liabilities?:  {
          __typename: "ModelLiabilityConnection",
          items:  Array< {
            __typename: "Liability",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            description?: string | null,
            balance?: number | null,
            balanceDate?: string | null,
            payoffDate?: string | null,
            ownerId?: string | null,
            sortOrder?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            interestRate?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        snapshots?:  {
          __typename: "ModelSnapshotConnection",
          items:  Array< {
            __typename: "Snapshot",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            date: string,
            description?: string | null,
            details?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        taxValues?:  {
          __typename: "ModelTaxValueConnection",
          items:  Array< {
            __typename: "TaxValue",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            year: number,
            key: string,
            value: number,
          } | null >,
          nextToken?: string | null,
        } | null,
        assetConversions?:  {
          __typename: "ModelAssetConversionConnection",
          items:  Array< {
            __typename: "AssetConversion",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            description?: string | null,
            conversionType?: string | null,
            srcAssetId: string,
            dstAssetId: string,
            year?: number | null,
            amount?: number | null,
            percent?: number | null,
            sortOrder?: number | null,
            completedAt?: string | null,
            taxableAmount?: number | null,
            nonTaxableAmount?: number | null,
            incomeType?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    migrateStatus?: string | null,
    migratedOn?: string | null,
    subscribedOn?: string | null,
    cancelledOn?: string | null,
    referrer?: string | null,
    settings?:  {
      __typename: "ModelUserSettingConnection",
      items:  Array< {
        __typename: "UserSetting",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        name: string,
        description?: string | null,
        settingType?: string | null,
        settings?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userType: UserType,
    userStatus: UserStatus,
    startDate?: string | null,
    endDate?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userType: UserType,
          userStatus: UserStatus,
          startDate?: string | null,
          endDate?: string | null,
          accountId: string,
          account?:  {
            __typename: "Account",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            ownerId?: string | null,
            name: string,
            address?: string | null,
            city?: string | null,
            state?: string | null,
            postalCode?: string | null,
          } | null,
          firstName: string,
          lastName: string,
          email: string,
          phone?: string | null,
          mfaPreference?: string | null,
          role: UserRole,
          advisorAccess?: boolean | null,
          lastAccess?: string | null,
          customerId?: string | null,
          currentModelId?: string | null,
          advisorModelId?: string | null,
          currentPlanId?: string | null,
          models?:  {
            __typename: "ModelModelConnection",
            nextToken?: string | null,
          } | null,
          migrateStatus?: string | null,
          migratedOn?: string | null,
          subscribedOn?: string | null,
          cancelledOn?: string | null,
          referrer?: string | null,
          settings?:  {
            __typename: "ModelUserSettingConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string | null,
    mfaPreference?: string | null,
    role: UserRole,
    advisorAccess?: boolean | null,
    lastAccess?: string | null,
    customerId?: string | null,
    currentModelId?: string | null,
    advisorModelId?: string | null,
    currentPlanId?: string | null,
    models?:  {
      __typename: "ModelModelConnection",
      items:  Array< {
        __typename: "Model",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        name: string,
        description?: string | null,
        startedAt?: string | null,
        advisorCreated?: boolean | null,
        clientAccess?: ClientAccess | null,
        settings?: string | null,
        persons?:  {
          __typename: "ModelPersonConnection",
          items:  Array< {
            __typename: "Person",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            nickname?: string | null,
            gender?: Gender | null,
            maritalStatus?: MaritalStatus | null,
            birthDate?: string | null,
            hereditaryAdjust?: number | null,
            lifeExpectancy?: number | null,
            retireDate?: string | null,
            state?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        plans?:  {
          __typename: "ModelPlanConnection",
          items:  Array< {
            __typename: "Plan",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            name?: string | null,
            description?: string | null,
            enabled?: boolean | null,
            default?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        incomes?:  {
          __typename: "ModelIncomeConnection",
          items:  Array< {
            __typename: "Income",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            incomeType?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            survivorPercent?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        deductions?:  {
          __typename: "ModelDeductionConnection",
          items:  Array< {
            __typename: "Deduction",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            description?: string | null,
            sortOrder?: number | null,
            assetId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        taxes?:  {
          __typename: "ModelTaxConnection",
          items:  Array< {
            __typename: "Tax",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            taxType?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        expenses?:  {
          __typename: "ModelExpenseConnection",
          items:  Array< {
            __typename: "Expense",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            expenseCategory?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            discretionary?: boolean | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            assetId?: string | null,
            liabilityId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        assets?:  {
          __typename: "ModelAssetConnection",
          items:  Array< {
            __typename: "Asset",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            assetCategory?: number | null,
            assetType?: number | null,
            description?: string | null,
            risk?: number | null,
            balance?: number | null,
            balanceDate?: string | null,
            returnRate?: number | null,
            rateLock?: boolean | null,
            withdrawalOrder?: number | null,
            inheritedYear?: number | null,
            originalOwnerBirthYear?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            priorEoYBalance?: number | null,
            withdrawalsYTD?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        liabilities?:  {
          __typename: "ModelLiabilityConnection",
          items:  Array< {
            __typename: "Liability",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            description?: string | null,
            balance?: number | null,
            balanceDate?: string | null,
            payoffDate?: string | null,
            ownerId?: string | null,
            sortOrder?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            interestRate?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        snapshots?:  {
          __typename: "ModelSnapshotConnection",
          items:  Array< {
            __typename: "Snapshot",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            date: string,
            description?: string | null,
            details?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        taxValues?:  {
          __typename: "ModelTaxValueConnection",
          items:  Array< {
            __typename: "TaxValue",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            year: number,
            key: string,
            value: number,
          } | null >,
          nextToken?: string | null,
        } | null,
        assetConversions?:  {
          __typename: "ModelAssetConversionConnection",
          items:  Array< {
            __typename: "AssetConversion",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            description?: string | null,
            conversionType?: string | null,
            srcAssetId: string,
            dstAssetId: string,
            year?: number | null,
            amount?: number | null,
            percent?: number | null,
            sortOrder?: number | null,
            completedAt?: string | null,
            taxableAmount?: number | null,
            nonTaxableAmount?: number | null,
            incomeType?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    migrateStatus?: string | null,
    migratedOn?: string | null,
    subscribedOn?: string | null,
    cancelledOn?: string | null,
    referrer?: string | null,
    settings?:  {
      __typename: "ModelUserSettingConnection",
      items:  Array< {
        __typename: "UserSetting",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        name: string,
        description?: string | null,
        settingType?: string | null,
        settings?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userType: UserType,
    userStatus: UserStatus,
    startDate?: string | null,
    endDate?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          createdAt?: string | null,
          updatedAt?: string | null,
          userType: UserType,
          userStatus: UserStatus,
          startDate?: string | null,
          endDate?: string | null,
          accountId: string,
          account?:  {
            __typename: "Account",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            ownerId?: string | null,
            name: string,
            address?: string | null,
            city?: string | null,
            state?: string | null,
            postalCode?: string | null,
          } | null,
          firstName: string,
          lastName: string,
          email: string,
          phone?: string | null,
          mfaPreference?: string | null,
          role: UserRole,
          advisorAccess?: boolean | null,
          lastAccess?: string | null,
          customerId?: string | null,
          currentModelId?: string | null,
          advisorModelId?: string | null,
          currentPlanId?: string | null,
          models?:  {
            __typename: "ModelModelConnection",
            nextToken?: string | null,
          } | null,
          migrateStatus?: string | null,
          migratedOn?: string | null,
          subscribedOn?: string | null,
          cancelledOn?: string | null,
          referrer?: string | null,
          settings?:  {
            __typename: "ModelUserSettingConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string | null,
    mfaPreference?: string | null,
    role: UserRole,
    advisorAccess?: boolean | null,
    lastAccess?: string | null,
    customerId?: string | null,
    currentModelId?: string | null,
    advisorModelId?: string | null,
    currentPlanId?: string | null,
    models?:  {
      __typename: "ModelModelConnection",
      items:  Array< {
        __typename: "Model",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        name: string,
        description?: string | null,
        startedAt?: string | null,
        advisorCreated?: boolean | null,
        clientAccess?: ClientAccess | null,
        settings?: string | null,
        persons?:  {
          __typename: "ModelPersonConnection",
          items:  Array< {
            __typename: "Person",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            nickname?: string | null,
            gender?: Gender | null,
            maritalStatus?: MaritalStatus | null,
            birthDate?: string | null,
            hereditaryAdjust?: number | null,
            lifeExpectancy?: number | null,
            retireDate?: string | null,
            state?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        plans?:  {
          __typename: "ModelPlanConnection",
          items:  Array< {
            __typename: "Plan",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            name?: string | null,
            description?: string | null,
            enabled?: boolean | null,
            default?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        incomes?:  {
          __typename: "ModelIncomeConnection",
          items:  Array< {
            __typename: "Income",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            incomeType?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            survivorPercent?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        deductions?:  {
          __typename: "ModelDeductionConnection",
          items:  Array< {
            __typename: "Deduction",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            description?: string | null,
            sortOrder?: number | null,
            assetId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        taxes?:  {
          __typename: "ModelTaxConnection",
          items:  Array< {
            __typename: "Tax",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            taxType?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        expenses?:  {
          __typename: "ModelExpenseConnection",
          items:  Array< {
            __typename: "Expense",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            expenseCategory?: number | null,
            description?: string | null,
            amount?: number | null,
            schedule?: string | null,
            infAdjust?: boolean | null,
            infLock?: boolean | null,
            annualInf?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            discretionary?: boolean | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            assetId?: string | null,
            liabilityId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        assets?:  {
          __typename: "ModelAssetConnection",
          items:  Array< {
            __typename: "Asset",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            assetCategory?: number | null,
            assetType?: number | null,
            description?: string | null,
            risk?: number | null,
            balance?: number | null,
            balanceDate?: string | null,
            returnRate?: number | null,
            rateLock?: boolean | null,
            withdrawalOrder?: number | null,
            inheritedYear?: number | null,
            originalOwnerBirthYear?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            sortOrder?: number | null,
            ownerId?: string | null,
            priorEoYBalance?: number | null,
            withdrawalsYTD?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        liabilities?:  {
          __typename: "ModelLiabilityConnection",
          items:  Array< {
            __typename: "Liability",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            description?: string | null,
            balance?: number | null,
            balanceDate?: string | null,
            payoffDate?: string | null,
            ownerId?: string | null,
            sortOrder?: number | null,
            start?: string | null,
            end?: string | null,
            startDate?: string | null,
            endDate?: string | null,
            interestRate?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        snapshots?:  {
          __typename: "ModelSnapshotConnection",
          items:  Array< {
            __typename: "Snapshot",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            date: string,
            description?: string | null,
            details?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        taxValues?:  {
          __typename: "ModelTaxValueConnection",
          items:  Array< {
            __typename: "TaxValue",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            year: number,
            key: string,
            value: number,
          } | null >,
          nextToken?: string | null,
        } | null,
        assetConversions?:  {
          __typename: "ModelAssetConversionConnection",
          items:  Array< {
            __typename: "AssetConversion",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            description?: string | null,
            conversionType?: string | null,
            srcAssetId: string,
            dstAssetId: string,
            year?: number | null,
            amount?: number | null,
            percent?: number | null,
            sortOrder?: number | null,
            completedAt?: string | null,
            taxableAmount?: number | null,
            nonTaxableAmount?: number | null,
            incomeType?: number | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    migrateStatus?: string | null,
    migratedOn?: string | null,
    subscribedOn?: string | null,
    cancelledOn?: string | null,
    referrer?: string | null,
    settings?:  {
      __typename: "ModelUserSettingConnection",
      items:  Array< {
        __typename: "UserSetting",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        name: string,
        description?: string | null,
        settingType?: string | null,
        settings?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateUserSettingSubscriptionVariables = {
  filter?: ModelSubscriptionUserSettingFilterInput | null,
  userId?: string | null,
};

export type OnCreateUserSettingSubscription = {
  onCreateUserSetting?:  {
    __typename: "UserSetting",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    name: string,
    description?: string | null,
    settingType?: string | null,
    settings?: string | null,
  } | null,
};

export type OnUpdateUserSettingSubscriptionVariables = {
  filter?: ModelSubscriptionUserSettingFilterInput | null,
  userId?: string | null,
};

export type OnUpdateUserSettingSubscription = {
  onUpdateUserSetting?:  {
    __typename: "UserSetting",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    name: string,
    description?: string | null,
    settingType?: string | null,
    settings?: string | null,
  } | null,
};

export type OnDeleteUserSettingSubscriptionVariables = {
  filter?: ModelSubscriptionUserSettingFilterInput | null,
  userId?: string | null,
};

export type OnDeleteUserSettingSubscription = {
  onDeleteUserSetting?:  {
    __typename: "UserSetting",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    name: string,
    description?: string | null,
    settingType?: string | null,
    settings?: string | null,
  } | null,
};

export type OnCreateModelSubscriptionVariables = {
  filter?: ModelSubscriptionModelFilterInput | null,
  userId?: string | null,
};

export type OnCreateModelSubscription = {
  onCreateModel?:  {
    __typename: "Model",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    name: string,
    description?: string | null,
    startedAt?: string | null,
    advisorCreated?: boolean | null,
    clientAccess?: ClientAccess | null,
    settings?: string | null,
    persons?:  {
      __typename: "ModelPersonConnection",
      items:  Array< {
        __typename: "Person",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        nickname?: string | null,
        gender?: Gender | null,
        maritalStatus?: MaritalStatus | null,
        birthDate?: string | null,
        hereditaryAdjust?: number | null,
        lifeExpectancy?: number | null,
        retireDate?: string | null,
        state?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    plans?:  {
      __typename: "ModelPlanConnection",
      items:  Array< {
        __typename: "Plan",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        name?: string | null,
        description?: string | null,
        enabled?: boolean | null,
        default?: boolean | null,
        changes?:  {
          __typename: "ModelPlanChangeConnection",
          items:  Array< {
            __typename: "PlanChange",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            planId: string,
            changeType: string,
            name: string,
            description?: string | null,
            enabled?: boolean | null,
            details?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    incomes?:  {
      __typename: "ModelIncomeConnection",
      items:  Array< {
        __typename: "Income",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        incomeType?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        survivorPercent?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deductions?:  {
      __typename: "ModelDeductionConnection",
      items:  Array< {
        __typename: "Deduction",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        description?: string | null,
        sortOrder?: number | null,
        assetId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    taxes?:  {
      __typename: "ModelTaxConnection",
      items:  Array< {
        __typename: "Tax",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        taxType?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    expenses?:  {
      __typename: "ModelExpenseConnection",
      items:  Array< {
        __typename: "Expense",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        expenseCategory?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        discretionary?: boolean | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        assetId?: string | null,
        liabilityId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    assets?:  {
      __typename: "ModelAssetConnection",
      items:  Array< {
        __typename: "Asset",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        assetCategory?: number | null,
        assetType?: number | null,
        description?: string | null,
        risk?: number | null,
        balance?: number | null,
        balanceDate?: string | null,
        returnRate?: number | null,
        rateLock?: boolean | null,
        withdrawalOrder?: number | null,
        inheritedYear?: number | null,
        originalOwnerBirthYear?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        priorEoYBalance?: number | null,
        withdrawalsYTD?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    liabilities?:  {
      __typename: "ModelLiabilityConnection",
      items:  Array< {
        __typename: "Liability",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        description?: string | null,
        balance?: number | null,
        balanceDate?: string | null,
        payoffDate?: string | null,
        ownerId?: string | null,
        sortOrder?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        interestRate?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    snapshots?:  {
      __typename: "ModelSnapshotConnection",
      items:  Array< {
        __typename: "Snapshot",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        date: string,
        description?: string | null,
        details?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    taxValues?:  {
      __typename: "ModelTaxValueConnection",
      items:  Array< {
        __typename: "TaxValue",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        year: number,
        key: string,
        value: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    assetConversions?:  {
      __typename: "ModelAssetConversionConnection",
      items:  Array< {
        __typename: "AssetConversion",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        description?: string | null,
        conversionType?: string | null,
        srcAssetId: string,
        dstAssetId: string,
        year?: number | null,
        amount?: number | null,
        percent?: number | null,
        sortOrder?: number | null,
        completedAt?: string | null,
        taxableAmount?: number | null,
        nonTaxableAmount?: number | null,
        incomeType?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateModelSubscriptionVariables = {
  filter?: ModelSubscriptionModelFilterInput | null,
  userId?: string | null,
};

export type OnUpdateModelSubscription = {
  onUpdateModel?:  {
    __typename: "Model",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    name: string,
    description?: string | null,
    startedAt?: string | null,
    advisorCreated?: boolean | null,
    clientAccess?: ClientAccess | null,
    settings?: string | null,
    persons?:  {
      __typename: "ModelPersonConnection",
      items:  Array< {
        __typename: "Person",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        nickname?: string | null,
        gender?: Gender | null,
        maritalStatus?: MaritalStatus | null,
        birthDate?: string | null,
        hereditaryAdjust?: number | null,
        lifeExpectancy?: number | null,
        retireDate?: string | null,
        state?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    plans?:  {
      __typename: "ModelPlanConnection",
      items:  Array< {
        __typename: "Plan",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        name?: string | null,
        description?: string | null,
        enabled?: boolean | null,
        default?: boolean | null,
        changes?:  {
          __typename: "ModelPlanChangeConnection",
          items:  Array< {
            __typename: "PlanChange",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            planId: string,
            changeType: string,
            name: string,
            description?: string | null,
            enabled?: boolean | null,
            details?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    incomes?:  {
      __typename: "ModelIncomeConnection",
      items:  Array< {
        __typename: "Income",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        incomeType?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        survivorPercent?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deductions?:  {
      __typename: "ModelDeductionConnection",
      items:  Array< {
        __typename: "Deduction",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        description?: string | null,
        sortOrder?: number | null,
        assetId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    taxes?:  {
      __typename: "ModelTaxConnection",
      items:  Array< {
        __typename: "Tax",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        taxType?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    expenses?:  {
      __typename: "ModelExpenseConnection",
      items:  Array< {
        __typename: "Expense",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        expenseCategory?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        discretionary?: boolean | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        assetId?: string | null,
        liabilityId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    assets?:  {
      __typename: "ModelAssetConnection",
      items:  Array< {
        __typename: "Asset",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        assetCategory?: number | null,
        assetType?: number | null,
        description?: string | null,
        risk?: number | null,
        balance?: number | null,
        balanceDate?: string | null,
        returnRate?: number | null,
        rateLock?: boolean | null,
        withdrawalOrder?: number | null,
        inheritedYear?: number | null,
        originalOwnerBirthYear?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        priorEoYBalance?: number | null,
        withdrawalsYTD?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    liabilities?:  {
      __typename: "ModelLiabilityConnection",
      items:  Array< {
        __typename: "Liability",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        description?: string | null,
        balance?: number | null,
        balanceDate?: string | null,
        payoffDate?: string | null,
        ownerId?: string | null,
        sortOrder?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        interestRate?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    snapshots?:  {
      __typename: "ModelSnapshotConnection",
      items:  Array< {
        __typename: "Snapshot",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        date: string,
        description?: string | null,
        details?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    taxValues?:  {
      __typename: "ModelTaxValueConnection",
      items:  Array< {
        __typename: "TaxValue",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        year: number,
        key: string,
        value: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    assetConversions?:  {
      __typename: "ModelAssetConversionConnection",
      items:  Array< {
        __typename: "AssetConversion",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        description?: string | null,
        conversionType?: string | null,
        srcAssetId: string,
        dstAssetId: string,
        year?: number | null,
        amount?: number | null,
        percent?: number | null,
        sortOrder?: number | null,
        completedAt?: string | null,
        taxableAmount?: number | null,
        nonTaxableAmount?: number | null,
        incomeType?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteModelSubscriptionVariables = {
  filter?: ModelSubscriptionModelFilterInput | null,
  userId?: string | null,
};

export type OnDeleteModelSubscription = {
  onDeleteModel?:  {
    __typename: "Model",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    name: string,
    description?: string | null,
    startedAt?: string | null,
    advisorCreated?: boolean | null,
    clientAccess?: ClientAccess | null,
    settings?: string | null,
    persons?:  {
      __typename: "ModelPersonConnection",
      items:  Array< {
        __typename: "Person",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        nickname?: string | null,
        gender?: Gender | null,
        maritalStatus?: MaritalStatus | null,
        birthDate?: string | null,
        hereditaryAdjust?: number | null,
        lifeExpectancy?: number | null,
        retireDate?: string | null,
        state?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    plans?:  {
      __typename: "ModelPlanConnection",
      items:  Array< {
        __typename: "Plan",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        name?: string | null,
        description?: string | null,
        enabled?: boolean | null,
        default?: boolean | null,
        changes?:  {
          __typename: "ModelPlanChangeConnection",
          items:  Array< {
            __typename: "PlanChange",
            id: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId: string,
            accountId: string,
            modelId: string,
            planId: string,
            changeType: string,
            name: string,
            description?: string | null,
            enabled?: boolean | null,
            details?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    incomes?:  {
      __typename: "ModelIncomeConnection",
      items:  Array< {
        __typename: "Income",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        incomeType?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        survivorPercent?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deductions?:  {
      __typename: "ModelDeductionConnection",
      items:  Array< {
        __typename: "Deduction",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        description?: string | null,
        sortOrder?: number | null,
        assetId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    taxes?:  {
      __typename: "ModelTaxConnection",
      items:  Array< {
        __typename: "Tax",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        taxType?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    expenses?:  {
      __typename: "ModelExpenseConnection",
      items:  Array< {
        __typename: "Expense",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        expenseCategory?: number | null,
        description?: string | null,
        amount?: number | null,
        schedule?: string | null,
        infAdjust?: boolean | null,
        infLock?: boolean | null,
        annualInf?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        discretionary?: boolean | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        assetId?: string | null,
        liabilityId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    assets?:  {
      __typename: "ModelAssetConnection",
      items:  Array< {
        __typename: "Asset",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        assetCategory?: number | null,
        assetType?: number | null,
        description?: string | null,
        risk?: number | null,
        balance?: number | null,
        balanceDate?: string | null,
        returnRate?: number | null,
        rateLock?: boolean | null,
        withdrawalOrder?: number | null,
        inheritedYear?: number | null,
        originalOwnerBirthYear?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        sortOrder?: number | null,
        ownerId?: string | null,
        priorEoYBalance?: number | null,
        withdrawalsYTD?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    liabilities?:  {
      __typename: "ModelLiabilityConnection",
      items:  Array< {
        __typename: "Liability",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        description?: string | null,
        balance?: number | null,
        balanceDate?: string | null,
        payoffDate?: string | null,
        ownerId?: string | null,
        sortOrder?: number | null,
        start?: string | null,
        end?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        interestRate?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    snapshots?:  {
      __typename: "ModelSnapshotConnection",
      items:  Array< {
        __typename: "Snapshot",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        date: string,
        description?: string | null,
        details?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    taxValues?:  {
      __typename: "ModelTaxValueConnection",
      items:  Array< {
        __typename: "TaxValue",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        year: number,
        key: string,
        value: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    assetConversions?:  {
      __typename: "ModelAssetConversionConnection",
      items:  Array< {
        __typename: "AssetConversion",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        description?: string | null,
        conversionType?: string | null,
        srcAssetId: string,
        dstAssetId: string,
        year?: number | null,
        amount?: number | null,
        percent?: number | null,
        sortOrder?: number | null,
        completedAt?: string | null,
        taxableAmount?: number | null,
        nonTaxableAmount?: number | null,
        incomeType?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateSnapshotSubscriptionVariables = {
  filter?: ModelSubscriptionSnapshotFilterInput | null,
  userId?: string | null,
};

export type OnCreateSnapshotSubscription = {
  onCreateSnapshot?:  {
    __typename: "Snapshot",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    date: string,
    description?: string | null,
    details?: string | null,
  } | null,
};

export type OnUpdateSnapshotSubscriptionVariables = {
  filter?: ModelSubscriptionSnapshotFilterInput | null,
  userId?: string | null,
};

export type OnUpdateSnapshotSubscription = {
  onUpdateSnapshot?:  {
    __typename: "Snapshot",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    date: string,
    description?: string | null,
    details?: string | null,
  } | null,
};

export type OnDeleteSnapshotSubscriptionVariables = {
  filter?: ModelSubscriptionSnapshotFilterInput | null,
  userId?: string | null,
};

export type OnDeleteSnapshotSubscription = {
  onDeleteSnapshot?:  {
    __typename: "Snapshot",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    date: string,
    description?: string | null,
    details?: string | null,
  } | null,
};

export type OnCreatePersonSubscriptionVariables = {
  filter?: ModelSubscriptionPersonFilterInput | null,
  userId?: string | null,
};

export type OnCreatePersonSubscription = {
  onCreatePerson?:  {
    __typename: "Person",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    nickname?: string | null,
    gender?: Gender | null,
    maritalStatus?: MaritalStatus | null,
    birthDate?: string | null,
    hereditaryAdjust?: number | null,
    lifeExpectancy?: number | null,
    retireDate?: string | null,
    state?: string | null,
  } | null,
};

export type OnUpdatePersonSubscriptionVariables = {
  filter?: ModelSubscriptionPersonFilterInput | null,
  userId?: string | null,
};

export type OnUpdatePersonSubscription = {
  onUpdatePerson?:  {
    __typename: "Person",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    nickname?: string | null,
    gender?: Gender | null,
    maritalStatus?: MaritalStatus | null,
    birthDate?: string | null,
    hereditaryAdjust?: number | null,
    lifeExpectancy?: number | null,
    retireDate?: string | null,
    state?: string | null,
  } | null,
};

export type OnDeletePersonSubscriptionVariables = {
  filter?: ModelSubscriptionPersonFilterInput | null,
  userId?: string | null,
};

export type OnDeletePersonSubscription = {
  onDeletePerson?:  {
    __typename: "Person",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    nickname?: string | null,
    gender?: Gender | null,
    maritalStatus?: MaritalStatus | null,
    birthDate?: string | null,
    hereditaryAdjust?: number | null,
    lifeExpectancy?: number | null,
    retireDate?: string | null,
    state?: string | null,
  } | null,
};

export type OnCreatePlanSubscriptionVariables = {
  filter?: ModelSubscriptionPlanFilterInput | null,
  userId?: string | null,
};

export type OnCreatePlanSubscription = {
  onCreatePlan?:  {
    __typename: "Plan",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    name?: string | null,
    description?: string | null,
    enabled?: boolean | null,
    default?: boolean | null,
    changes?:  {
      __typename: "ModelPlanChangeConnection",
      items:  Array< {
        __typename: "PlanChange",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        planId: string,
        changeType: string,
        name: string,
        description?: string | null,
        enabled?: boolean | null,
        details?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdatePlanSubscriptionVariables = {
  filter?: ModelSubscriptionPlanFilterInput | null,
  userId?: string | null,
};

export type OnUpdatePlanSubscription = {
  onUpdatePlan?:  {
    __typename: "Plan",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    name?: string | null,
    description?: string | null,
    enabled?: boolean | null,
    default?: boolean | null,
    changes?:  {
      __typename: "ModelPlanChangeConnection",
      items:  Array< {
        __typename: "PlanChange",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        planId: string,
        changeType: string,
        name: string,
        description?: string | null,
        enabled?: boolean | null,
        details?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeletePlanSubscriptionVariables = {
  filter?: ModelSubscriptionPlanFilterInput | null,
  userId?: string | null,
};

export type OnDeletePlanSubscription = {
  onDeletePlan?:  {
    __typename: "Plan",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    name?: string | null,
    description?: string | null,
    enabled?: boolean | null,
    default?: boolean | null,
    changes?:  {
      __typename: "ModelPlanChangeConnection",
      items:  Array< {
        __typename: "PlanChange",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        modelId: string,
        planId: string,
        changeType: string,
        name: string,
        description?: string | null,
        enabled?: boolean | null,
        details?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreatePlanChangeSubscriptionVariables = {
  filter?: ModelSubscriptionPlanChangeFilterInput | null,
  userId?: string | null,
};

export type OnCreatePlanChangeSubscription = {
  onCreatePlanChange?:  {
    __typename: "PlanChange",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    planId: string,
    changeType: string,
    name: string,
    description?: string | null,
    enabled?: boolean | null,
    details?: string | null,
  } | null,
};

export type OnUpdatePlanChangeSubscriptionVariables = {
  filter?: ModelSubscriptionPlanChangeFilterInput | null,
  userId?: string | null,
};

export type OnUpdatePlanChangeSubscription = {
  onUpdatePlanChange?:  {
    __typename: "PlanChange",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    planId: string,
    changeType: string,
    name: string,
    description?: string | null,
    enabled?: boolean | null,
    details?: string | null,
  } | null,
};

export type OnDeletePlanChangeSubscriptionVariables = {
  filter?: ModelSubscriptionPlanChangeFilterInput | null,
  userId?: string | null,
};

export type OnDeletePlanChangeSubscription = {
  onDeletePlanChange?:  {
    __typename: "PlanChange",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    planId: string,
    changeType: string,
    name: string,
    description?: string | null,
    enabled?: boolean | null,
    details?: string | null,
  } | null,
};

export type OnCreateIncomeSubscriptionVariables = {
  filter?: ModelSubscriptionIncomeFilterInput | null,
  userId?: string | null,
};

export type OnCreateIncomeSubscription = {
  onCreateIncome?:  {
    __typename: "Income",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    incomeType?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    survivorPercent?: number | null,
  } | null,
};

export type OnUpdateIncomeSubscriptionVariables = {
  filter?: ModelSubscriptionIncomeFilterInput | null,
  userId?: string | null,
};

export type OnUpdateIncomeSubscription = {
  onUpdateIncome?:  {
    __typename: "Income",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    incomeType?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    survivorPercent?: number | null,
  } | null,
};

export type OnDeleteIncomeSubscriptionVariables = {
  filter?: ModelSubscriptionIncomeFilterInput | null,
  userId?: string | null,
};

export type OnDeleteIncomeSubscription = {
  onDeleteIncome?:  {
    __typename: "Income",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    incomeType?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    survivorPercent?: number | null,
  } | null,
};

export type OnCreateIncomeTypeSubscriptionVariables = {
  filter?: ModelSubscriptionIncomeTypeFilterInput | null,
};

export type OnCreateIncomeTypeSubscription = {
  onCreateIncomeType?:  {
    __typename: "IncomeType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
  } | null,
};

export type OnUpdateIncomeTypeSubscriptionVariables = {
  filter?: ModelSubscriptionIncomeTypeFilterInput | null,
};

export type OnUpdateIncomeTypeSubscription = {
  onUpdateIncomeType?:  {
    __typename: "IncomeType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
  } | null,
};

export type OnDeleteIncomeTypeSubscriptionVariables = {
  filter?: ModelSubscriptionIncomeTypeFilterInput | null,
};

export type OnDeleteIncomeTypeSubscription = {
  onDeleteIncomeType?:  {
    __typename: "IncomeType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
  } | null,
};

export type OnCreateDeductionSubscriptionVariables = {
  filter?: ModelSubscriptionDeductionFilterInput | null,
  userId?: string | null,
};

export type OnCreateDeductionSubscription = {
  onCreateDeduction?:  {
    __typename: "Deduction",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    description?: string | null,
    sortOrder?: number | null,
    assetId?: string | null,
  } | null,
};

export type OnUpdateDeductionSubscriptionVariables = {
  filter?: ModelSubscriptionDeductionFilterInput | null,
  userId?: string | null,
};

export type OnUpdateDeductionSubscription = {
  onUpdateDeduction?:  {
    __typename: "Deduction",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    description?: string | null,
    sortOrder?: number | null,
    assetId?: string | null,
  } | null,
};

export type OnDeleteDeductionSubscriptionVariables = {
  filter?: ModelSubscriptionDeductionFilterInput | null,
  userId?: string | null,
};

export type OnDeleteDeductionSubscription = {
  onDeleteDeduction?:  {
    __typename: "Deduction",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    description?: string | null,
    sortOrder?: number | null,
    assetId?: string | null,
  } | null,
};

export type OnCreateExpenseSubscriptionVariables = {
  filter?: ModelSubscriptionExpenseFilterInput | null,
  userId?: string | null,
};

export type OnCreateExpenseSubscription = {
  onCreateExpense?:  {
    __typename: "Expense",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    expenseCategory?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    discretionary?: boolean | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    assetId?: string | null,
    liabilityId?: string | null,
  } | null,
};

export type OnUpdateExpenseSubscriptionVariables = {
  filter?: ModelSubscriptionExpenseFilterInput | null,
  userId?: string | null,
};

export type OnUpdateExpenseSubscription = {
  onUpdateExpense?:  {
    __typename: "Expense",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    expenseCategory?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    discretionary?: boolean | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    assetId?: string | null,
    liabilityId?: string | null,
  } | null,
};

export type OnDeleteExpenseSubscriptionVariables = {
  filter?: ModelSubscriptionExpenseFilterInput | null,
  userId?: string | null,
};

export type OnDeleteExpenseSubscription = {
  onDeleteExpense?:  {
    __typename: "Expense",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    expenseCategory?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    discretionary?: boolean | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    assetId?: string | null,
    liabilityId?: string | null,
  } | null,
};

export type OnCreateExpenseCategorySubscriptionVariables = {
  filter?: ModelSubscriptionExpenseCategoryFilterInput | null,
};

export type OnCreateExpenseCategorySubscription = {
  onCreateExpenseCategory?:  {
    __typename: "ExpenseCategory",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
    sortOrder?: number | null,
  } | null,
};

export type OnUpdateExpenseCategorySubscriptionVariables = {
  filter?: ModelSubscriptionExpenseCategoryFilterInput | null,
};

export type OnUpdateExpenseCategorySubscription = {
  onUpdateExpenseCategory?:  {
    __typename: "ExpenseCategory",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
    sortOrder?: number | null,
  } | null,
};

export type OnDeleteExpenseCategorySubscriptionVariables = {
  filter?: ModelSubscriptionExpenseCategoryFilterInput | null,
};

export type OnDeleteExpenseCategorySubscription = {
  onDeleteExpenseCategory?:  {
    __typename: "ExpenseCategory",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
    sortOrder?: number | null,
  } | null,
};

export type OnCreateTaxSubscriptionVariables = {
  filter?: ModelSubscriptionTaxFilterInput | null,
  userId?: string | null,
};

export type OnCreateTaxSubscription = {
  onCreateTax?:  {
    __typename: "Tax",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    taxType?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
  } | null,
};

export type OnUpdateTaxSubscriptionVariables = {
  filter?: ModelSubscriptionTaxFilterInput | null,
  userId?: string | null,
};

export type OnUpdateTaxSubscription = {
  onUpdateTax?:  {
    __typename: "Tax",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    taxType?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
  } | null,
};

export type OnDeleteTaxSubscriptionVariables = {
  filter?: ModelSubscriptionTaxFilterInput | null,
  userId?: string | null,
};

export type OnDeleteTaxSubscription = {
  onDeleteTax?:  {
    __typename: "Tax",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    taxType?: number | null,
    description?: string | null,
    amount?: number | null,
    schedule?: string | null,
    infAdjust?: boolean | null,
    infLock?: boolean | null,
    annualInf?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
  } | null,
};

export type OnCreateAssetSubscriptionVariables = {
  filter?: ModelSubscriptionAssetFilterInput | null,
  userId?: string | null,
};

export type OnCreateAssetSubscription = {
  onCreateAsset?:  {
    __typename: "Asset",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    assetCategory?: number | null,
    assetType?: number | null,
    description?: string | null,
    risk?: number | null,
    balance?: number | null,
    balanceDate?: string | null,
    returnRate?: number | null,
    rateLock?: boolean | null,
    withdrawalOrder?: number | null,
    inheritedYear?: number | null,
    originalOwnerBirthYear?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    priorEoYBalance?: number | null,
    withdrawalsYTD?: number | null,
  } | null,
};

export type OnUpdateAssetSubscriptionVariables = {
  filter?: ModelSubscriptionAssetFilterInput | null,
  userId?: string | null,
};

export type OnUpdateAssetSubscription = {
  onUpdateAsset?:  {
    __typename: "Asset",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    assetCategory?: number | null,
    assetType?: number | null,
    description?: string | null,
    risk?: number | null,
    balance?: number | null,
    balanceDate?: string | null,
    returnRate?: number | null,
    rateLock?: boolean | null,
    withdrawalOrder?: number | null,
    inheritedYear?: number | null,
    originalOwnerBirthYear?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    priorEoYBalance?: number | null,
    withdrawalsYTD?: number | null,
  } | null,
};

export type OnDeleteAssetSubscriptionVariables = {
  filter?: ModelSubscriptionAssetFilterInput | null,
  userId?: string | null,
};

export type OnDeleteAssetSubscription = {
  onDeleteAsset?:  {
    __typename: "Asset",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    assetCategory?: number | null,
    assetType?: number | null,
    description?: string | null,
    risk?: number | null,
    balance?: number | null,
    balanceDate?: string | null,
    returnRate?: number | null,
    rateLock?: boolean | null,
    withdrawalOrder?: number | null,
    inheritedYear?: number | null,
    originalOwnerBirthYear?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    sortOrder?: number | null,
    ownerId?: string | null,
    priorEoYBalance?: number | null,
    withdrawalsYTD?: number | null,
  } | null,
};

export type OnCreateAssetCategorySubscriptionVariables = {
  filter?: ModelSubscriptionAssetCategoryFilterInput | null,
};

export type OnCreateAssetCategorySubscription = {
  onCreateAssetCategory?:  {
    __typename: "AssetCategory",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
    sortOrder?: number | null,
  } | null,
};

export type OnUpdateAssetCategorySubscriptionVariables = {
  filter?: ModelSubscriptionAssetCategoryFilterInput | null,
};

export type OnUpdateAssetCategorySubscription = {
  onUpdateAssetCategory?:  {
    __typename: "AssetCategory",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
    sortOrder?: number | null,
  } | null,
};

export type OnDeleteAssetCategorySubscriptionVariables = {
  filter?: ModelSubscriptionAssetCategoryFilterInput | null,
};

export type OnDeleteAssetCategorySubscription = {
  onDeleteAssetCategory?:  {
    __typename: "AssetCategory",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
    sortOrder?: number | null,
  } | null,
};

export type OnCreateAssetTypeSubscriptionVariables = {
  filter?: ModelSubscriptionAssetTypeFilterInput | null,
};

export type OnCreateAssetTypeSubscription = {
  onCreateAssetType?:  {
    __typename: "AssetType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
  } | null,
};

export type OnUpdateAssetTypeSubscriptionVariables = {
  filter?: ModelSubscriptionAssetTypeFilterInput | null,
};

export type OnUpdateAssetTypeSubscription = {
  onUpdateAssetType?:  {
    __typename: "AssetType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
  } | null,
};

export type OnDeleteAssetTypeSubscriptionVariables = {
  filter?: ModelSubscriptionAssetTypeFilterInput | null,
};

export type OnDeleteAssetTypeSubscription = {
  onDeleteAssetType?:  {
    __typename: "AssetType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    description?: string | null,
  } | null,
};

export type OnCreateAssetConversionSubscriptionVariables = {
  filter?: ModelSubscriptionAssetConversionFilterInput | null,
  userId?: string | null,
};

export type OnCreateAssetConversionSubscription = {
  onCreateAssetConversion?:  {
    __typename: "AssetConversion",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    description?: string | null,
    conversionType?: string | null,
    srcAssetId: string,
    dstAssetId: string,
    year?: number | null,
    amount?: number | null,
    percent?: number | null,
    sortOrder?: number | null,
    completedAt?: string | null,
    taxableAmount?: number | null,
    nonTaxableAmount?: number | null,
    incomeType?: number | null,
  } | null,
};

export type OnUpdateAssetConversionSubscriptionVariables = {
  filter?: ModelSubscriptionAssetConversionFilterInput | null,
  userId?: string | null,
};

export type OnUpdateAssetConversionSubscription = {
  onUpdateAssetConversion?:  {
    __typename: "AssetConversion",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    description?: string | null,
    conversionType?: string | null,
    srcAssetId: string,
    dstAssetId: string,
    year?: number | null,
    amount?: number | null,
    percent?: number | null,
    sortOrder?: number | null,
    completedAt?: string | null,
    taxableAmount?: number | null,
    nonTaxableAmount?: number | null,
    incomeType?: number | null,
  } | null,
};

export type OnDeleteAssetConversionSubscriptionVariables = {
  filter?: ModelSubscriptionAssetConversionFilterInput | null,
  userId?: string | null,
};

export type OnDeleteAssetConversionSubscription = {
  onDeleteAssetConversion?:  {
    __typename: "AssetConversion",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    description?: string | null,
    conversionType?: string | null,
    srcAssetId: string,
    dstAssetId: string,
    year?: number | null,
    amount?: number | null,
    percent?: number | null,
    sortOrder?: number | null,
    completedAt?: string | null,
    taxableAmount?: number | null,
    nonTaxableAmount?: number | null,
    incomeType?: number | null,
  } | null,
};

export type OnCreateLiabilitySubscriptionVariables = {
  filter?: ModelSubscriptionLiabilityFilterInput | null,
  userId?: string | null,
};

export type OnCreateLiabilitySubscription = {
  onCreateLiability?:  {
    __typename: "Liability",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    description?: string | null,
    balance?: number | null,
    balanceDate?: string | null,
    payoffDate?: string | null,
    ownerId?: string | null,
    sortOrder?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    interestRate?: number | null,
  } | null,
};

export type OnUpdateLiabilitySubscriptionVariables = {
  filter?: ModelSubscriptionLiabilityFilterInput | null,
  userId?: string | null,
};

export type OnUpdateLiabilitySubscription = {
  onUpdateLiability?:  {
    __typename: "Liability",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    description?: string | null,
    balance?: number | null,
    balanceDate?: string | null,
    payoffDate?: string | null,
    ownerId?: string | null,
    sortOrder?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    interestRate?: number | null,
  } | null,
};

export type OnDeleteLiabilitySubscriptionVariables = {
  filter?: ModelSubscriptionLiabilityFilterInput | null,
  userId?: string | null,
};

export type OnDeleteLiabilitySubscription = {
  onDeleteLiability?:  {
    __typename: "Liability",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    description?: string | null,
    balance?: number | null,
    balanceDate?: string | null,
    payoffDate?: string | null,
    ownerId?: string | null,
    sortOrder?: number | null,
    start?: string | null,
    end?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    interestRate?: number | null,
  } | null,
};

export type OnCreateTaxValueSubscriptionVariables = {
  filter?: ModelSubscriptionTaxValueFilterInput | null,
  userId?: string | null,
};

export type OnCreateTaxValueSubscription = {
  onCreateTaxValue?:  {
    __typename: "TaxValue",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    year: number,
    key: string,
    value: number,
  } | null,
};

export type OnUpdateTaxValueSubscriptionVariables = {
  filter?: ModelSubscriptionTaxValueFilterInput | null,
  userId?: string | null,
};

export type OnUpdateTaxValueSubscription = {
  onUpdateTaxValue?:  {
    __typename: "TaxValue",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    year: number,
    key: string,
    value: number,
  } | null,
};

export type OnDeleteTaxValueSubscriptionVariables = {
  filter?: ModelSubscriptionTaxValueFilterInput | null,
  userId?: string | null,
};

export type OnDeleteTaxValueSubscription = {
  onDeleteTaxValue?:  {
    __typename: "TaxValue",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    modelId: string,
    year: number,
    key: string,
    value: number,
  } | null,
};

export type OnCreateUserInviteSubscriptionVariables = {
  filter?: ModelSubscriptionUserInviteFilterInput | null,
  userId?: string | null,
};

export type OnCreateUserInviteSubscription = {
  onCreateUserInvite?:  {
    __typename: "UserInvite",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    role: UserRole,
    invitedOn: string,
  } | null,
};

export type OnUpdateUserInviteSubscriptionVariables = {
  filter?: ModelSubscriptionUserInviteFilterInput | null,
  userId?: string | null,
};

export type OnUpdateUserInviteSubscription = {
  onUpdateUserInvite?:  {
    __typename: "UserInvite",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    role: UserRole,
    invitedOn: string,
  } | null,
};

export type OnDeleteUserInviteSubscriptionVariables = {
  filter?: ModelSubscriptionUserInviteFilterInput | null,
  userId?: string | null,
};

export type OnDeleteUserInviteSubscription = {
  onDeleteUserInvite?:  {
    __typename: "UserInvite",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    role: UserRole,
    invitedOn: string,
  } | null,
};
