import Income, {getIncomeTypeDef, IncomeType} from "../../../model/Income";
import {Box, IconButton, InputAdornment} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import TextFieldValidator from "../../../components/form/TextFieldValidator";
import {CreateIncomeInput, UpdateIncomeInput} from "../../../API";
import {useResources} from "../../../stores/ResourceProvider";

import Model from "../../../model/Model";
import Schedule, {Frequency} from "../../../model/Schedule";
import ScheduleSelector from "../../../components/controls/ScheduleSelector";
import FormGroupSpacer from "../../../components/form/FormGroupSpacer";
import MilestoneDateFieldValidator from "../../../components/form/MilestoneDateFieldValidator";
import ModelEditDialog from "../../../components/model/ModelEditDialog";
import {
  getISODateFromDate, isoToLocalDate,
  isoToLocalDateString,
  moneyToNumberFormat,
  numberToMoneyFormat, numberToPercentFormat
} from "../../../stores/StoreUtilities";
import {isNumber} from "../../../model/ModelUtilities";
import Milestone from "../../../model/Milestone";
import {addYears} from "date-fns";
import Person from "../../../model/Person";
import Tracking from "../../../components/Tracking";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const IncomeEditDialog = ({
  open,
  income,
  model,
  onClose,
  onSave,
  onDelete
}: {
  open?: boolean
  income: Income
  model: Model
  onClose?(): void
  onSave?(update: Income): void
  onDelete?(expense: Income): void
}) => {
  const [incomeModel, setIncomeModel] = useState<Income>(income)
  const [description, setDescription] = useState<string>("")
  const [amount, setAmount] = useState<string | undefined>()
  const [amountLock, setAmountLock] = useState<boolean>(false)
  const [incomeType, setIncomeType] = useState<IncomeType | undefined>()
  const [person, setPerson] = useState<Person | undefined>()
  const [owner, setOwner] = useState<string | undefined>()
  const [allowJoint, setAllowJoint] = useState<boolean>(true)
  const [schedule, setSchedule] = useState<Schedule | undefined>()
  const [start, setStart] = useState<string>()
  const [startLock, setStartLock] = useState<boolean>(false)
  const [end, setEnd] = useState<string>()
  const [annualInf, setAnnualInf] = useState<string | undefined>()
  const [annualInfLock, setAnnualInfLock] = useState<"Model" | "User" | undefined>()
  const [survivorPercent, setSurvivorPercent] = useState<string | undefined>()
  const [milestones, setMilestones] = useState<Milestone[]>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { modelStore, userStore, calculator, notify } = useResources()

  // const styles = {
  //   actions: {
  //     display:"flex",
  //     flexDirection:"row",
  //     width:"100%",
  //     justifyContent:"space-between",
  //     alignItems:"center",
  //     marginTop: theme.spacing(1)
  //   },
  //   row: {
  //     maxHeight: 48
  //   },
  //   formLabel: {
  //     fontSize: 12,
  //     fontWeight: 400,
  //     color: theme.palette.grey["700"]
  //   },
  //   stateField: {
  //     top: 5,
  //     width: "100%"
  //   }
  // }

  useEffect(() => {
    const typedef = getIncomeTypeDef(income.incomeType ?? IncomeType.None)
    setIncomeModel(income)
    setDescription(income.description)
    setAmount(numberToMoneyFormat(income.amount, 0))
    setIncomeType(income.incomeType)
    const person = income.ownerId ? model.getPerson(income.ownerId) : undefined
    setPerson(person)
    setOwner(person ? person.nickname : model.getDefaultPersonNickname(typedef.allowJoint))
    setAllowJoint(typedef.allowJoint)
    setSchedule(income.schedule ?? new Schedule({name: "Monthly", frequency: Frequency.Monthly, interval: 1}))
    setStart(income.start)
    setEnd(income.end)
    setAnnualInf(isNumber(income.annualInf) ? numberToPercentFormat(income.annualInf, 2) : "")
    if (income.infLock) {
      setAnnualInfLock("User")
    } else if (model.hasLock(income.id, "annualInf")) {
      setAnnualInfLock("Model")
    }
    setSurvivorPercent(income.survivorPercent !== undefined ? numberToPercentFormat(income.survivorPercent, 2) : undefined)
    setAmountLock(model.hasLock(income.id, "amount"))
    setStartLock(model.hasLock(income.id, "start"))
    setIsOpen(open === true)
  }, [income, open])

  useEffect(() => {
    if (person) {
      setMilestones(getMilestones(person))
    }
  }, [person])

  useEffect(() => {
    const typedef = getIncomeTypeDef(incomeType ?? IncomeType.None)
    setAllowJoint(typedef.allowJoint)
  }, [incomeType])

  const handleClose = async (event: any) => {
    if (onClose) {
      onClose()
    }
  }

  const handleSave = async (event: any) => {
    try {
      const person = model.getPersonByNickname(owner)
      let updatedIncome: Income | undefined
      const amountValue = moneyToNumberFormat(amount, 0) ?? 0

      if (!incomeModel.createdAt) {
        const input: CreateIncomeInput = {
          id: incomeModel.id,
          accountId: incomeModel.accountId,
          userId: incomeModel.userId,
          modelId: incomeModel.modelId,
          description: description,
          amount: amountValue,
          incomeType: incomeType,
          schedule: JSON.stringify(schedule),
          start: start,
          end: end ?? null,
          annualInf: annualInf !== undefined ? parseFloat(annualInf) / 100.0 : null,
          infLock: annualInfLock === "User",
          survivorPercent: null,
          ownerId: person ? person.id : null,
          sortOrder: income.sortOrder
        }
        if (incomeType === IncomeType.PensionsAndAnnuitiesNonTaxable || incomeType === IncomeType.PensionsAndAnnuitiesTaxable) {
          input.survivorPercent = survivorPercent !== undefined ? parseInt(survivorPercent) : null
        }

        updatedIncome = await modelStore.createIncome(input)
        Tracking.event({action: "Income Created"})
      } else {
        const input: UpdateIncomeInput = {
          id: incomeModel.id,
          accountId: incomeModel.accountId,
          userId: incomeModel.userId,
          modelId: incomeModel.modelId,
          description: description,
          amount: amountValue,
          incomeType: incomeType,
          schedule: JSON.stringify(schedule),
          start: start,
          end: end ?? null,
          annualInf: annualInf !== undefined ? parseFloat(annualInf) / 100.0 : null,
          infLock: annualInfLock === "User",
          survivorPercent: null,
          ownerId: person ? person.id : null,
          sortOrder: income.sortOrder
        }
        if (amountLock) {
          delete input.amount
        }
        if (startLock) {
          delete input.start
        }
        if (annualInfLock === "Model") {
          delete input.annualInf
        }

        if (incomeType === IncomeType.PensionsAndAnnuitiesNonTaxable || incomeType === IncomeType.PensionsAndAnnuitiesTaxable) {
          input.survivorPercent = survivorPercent !== undefined ? parseInt(survivorPercent) : null
        }

        updatedIncome = await modelStore.updateIncome(input)
        Tracking.event({action: "Income Updated"})
      }

      if (onSave && updatedIncome) {
        onSave(updatedIncome)
      } else if (onClose) {
        onClose()
      }
    } catch (err: any) {
      notify.show('error', err.message)
    }
  }

  const handleDelete = async () => {
    if (onDelete) {
      if (incomeModel.createdAt) {
        const deleted = await modelStore.deleteIncome(incomeModel.id)
        if (deleted) {
          onDelete(deleted)
        }
      } else {
        onDelete(incomeModel)
      }
    }
  }

  const getMilestones = (person: Person): Milestone[] => {
    let options: Milestone[] = []
    const timeline = calculator.personTimeline(person)
    if (timeline) {
      const ages = [62, 63, 64, 65, 66, 67, 68, 69, 70]
      options = ages.map((age: number) => {
        let label
        if (age === timeline.earlySocialSecurityAge) {
          label = `${age} (Early Social Security)`
        } else if (age === timeline.fullSocialSecurityAge) {
          label = `${age} (Full Social Security)`
        } else if (age === timeline.delayedSocialSecurityAge) {
          label = `${age} (Delayed Social Security)`
        } else {
          label = String(age)
        }
        const date = addYears(timeline.birthDate, age)
        return new Milestone({
          id: getISODateFromDate(date),
          label: label,
          date: date
        })
      })
    }
    return options
  }

  const handleInfLock = (typename: string) => {
    if (annualInfLock === "User") {
      if (model.getOverrideInflationLock(typename)) {
        setAnnualInfLock("Model")
        setAnnualInf(numberToPercentFormat(model.getDefaultInflationRate(typename)))
      } else {
        setAnnualInfLock(undefined)
      }
    } else if (annualInfLock === "Model") {
      setAnnualInfLock("User")
    } else if (annualInfLock === undefined) {
      setAnnualInfLock("User")
    }
  }

  const getEndHelperText = (incomeType?: IncomeType) => {
    let helperText = ""
    if (incomeType !== undefined) {
      const incomeTypeDef = getIncomeTypeDef(incomeType)
      helperText = (incomeTypeDef.defaultEndDate === 'retireDate') ?
        "Default: Owner's Retirement" : "Default: Owner's Life Exp"
    }
    return helperText
  }

  const renderAnnualInfField = (typename: string) => {
    let endAdornment
    let helperText
    let disabled = false
    if (userStore.isFree) {
      helperText = `Default: ${Model.defaultInflationRate}`
    } else if (annualInfLock === "User") {
      endAdornment = (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle inflation lock"
            onClick={() => handleInfLock(typename)}
            edge="end"
          >
            <LockPersonIcon/>
          </IconButton>
        </InputAdornment>
      )
      helperText = "Locked by User"
    } else if (annualInfLock === "Model") {
      endAdornment = (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle inflation lock"
            onClick={() => handleInfLock(typename)}
            edge="end"
          >
            <LockIcon/>
          </IconButton>
        </InputAdornment>
      )
      helperText = "Locked by Inflation Strategy"
      disabled = true
    } else {
      endAdornment = (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle inflation lock"
            onClick={() => handleInfLock(typename)}
            edge="end"
          >
            <LockOpenIcon/>
          </IconButton>
        </InputAdornment>
      )
      helperText = `Default: ${numberToPercentFormat(model.getDefaultInflationRate(typename), 2)}`
    }

    return (
      <TextFieldValidator
        type="string"
        validators={{ required: false, minValue: 0 }}
        name="annualInf"
        variant="standard"
        margin="dense"
        fullWidth
        label="Annual Inflation %"
        value={annualInf}
        InputProps={{
          endAdornment: endAdornment
        }}
        helperText={helperText}
        disabled={disabled}
        onChange={(event: any) => setAnnualInf(event.target.value)}
      />
    )
  }

  if (!incomeModel || !isOpen) {
    return null
  }

  return (
    <ModelEditDialog title="Edit Income" open={isOpen}
                     onCancel={handleClose}
                     onSave={handleSave}
                     onDelete={handleDelete}
    >
      <TextFieldValidator
        margin="normal"
        name="description"
        label="Title"
        type="text"
        fullWidth
        variant="standard"
        required
        validators={{ required: true }}
        value={description}
        onChange={(event: any) => setDescription(event.target.value)}
      />
      <TextFieldValidator
        type="string"
        validators={{ isMoney: true }}
        name="amount"
        variant="standard"
        margin="dense"
        fullWidth
        label="Amount"
        value={amount}
        locked={amountLock}
        helperText={amountLock ? "Locked by Social Security Strategy" : undefined}
        onChange={(event: any) => setAmount(event.target.value)}
      />
      <ScheduleSelector
        value={schedule!}
        startDate={model.getDate(start)}
        onChange={(value: Schedule) => setSchedule(value)}
      />
      <TextFieldValidator
        type="text"
        validators={{ required: true }}
        required
        name="owner"
        label="Owner"
        variant="standard"
        autocompleteOptions={{
          freeSolo: false,
          options: model.getPersonNicknames(allowJoint),
          value: owner,
          onChange: (event: any, value: string, reason: any) => {
            setOwner(value)
            setPerson(model.getPersonByNickname(value))
          }
        }}
      />
      <TextFieldValidator
        type="text"
        validators={{ required: true }}
        required
        name="incomeType"
        label="Income Type"
        variant="standard"
        autocompleteOptions={{
          freeSolo: false,
          options: Object.values(IncomeType).filter((v) => !isNaN(Number(v))),
          getOptionLabel: (option: number) => {
            const incomeTypeDef = getIncomeTypeDef(option)
            return(incomeTypeDef.label)
          },
          value: incomeType,
          onChange: (event: any, value: number, reason: any) => {
            setIncomeType(value)
          }
        }}
      />
      {(incomeType === IncomeType.PensionsAndAnnuitiesNonTaxable || incomeType === IncomeType.PensionsAndAnnuitiesTaxable) &&
        <TextFieldValidator
          type="number"
          validators={{ required: false }}
          name="survivorPercent"
          variant="standard"
          margin="dense"
          fullWidth
          label="Survivor %"
          value={survivorPercent}
          onChange={(event: any) => setSurvivorPercent(event.target.value)}
        />
      }
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        {incomeType === IncomeType.SocialSecurityBenefits && owner &&
          <MilestoneDateFieldValidator
            name="start"
            label="Start Date"
            milestones={milestones}
            value={start}
            width="50%"
            required={incomeType === IncomeType.SocialSecurityBenefits}
            disabled={startLock}
            helperText={startLock ? "Locked by Social Security Strategy" : undefined}
            onChange={(value: string) => {
              setStart(value)
            }}
          />
        }
        {(incomeType !== IncomeType.SocialSecurityBenefits || !owner) &&
          <MilestoneDateFieldValidator
            name="start"
            label="Start Date"
            model={model}
            value={start}
            width="50%"
            required={incomeType !== IncomeType.SocialSecurityBenefits}
            disabled={startLock}
            helperText={startLock ? "Locked by Social Security Strategy" : undefined}
            onChange={(value: string) => setStart(value)}
          />
        }
        <FormGroupSpacer/>
        <MilestoneDateFieldValidator
          name="end"
          label="End Date"
          model={model}
          value={end}
          width="50%"
          helperText={getEndHelperText(incomeType)}
          onChange={(value: string) => setEnd(value)}
        />
      </Box>
      {renderAnnualInfField("income")}
    </ModelEditDialog>
  )
}

export default IncomeEditDialog