import {Auth} from 'aws-amplify';
import * as React from 'react';
import {useEffect, useState} from 'react';
import CancelButton from '../../components/form/CancelButton';
import DialogButton from '../../components/form/DialogButton';
import FormValidator from '../../components/form/FormValidator';
import ProgressButton from '../../components/form/ProgressButton';
import TextFieldValidator from '../../components/form/TextFieldValidator';
import Visible from '../../components/Visible';
import {useResources} from "../../stores/ResourceProvider";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {phoneToE164Format, phoneToNationalFormat} from "../../stores/StoreUtilities";
import {Box, FormControlLabel, IconButton, InputAdornment, Switch, Typography, useTheme} from "@mui/material";
import IconicButton from "../../components/controls/IconicButton";
import EditIcon from "@mui/icons-material/Edit";
import User, {MFAPreference} from "../../model/User";
import CheckboxValidator from "../../components/form/CheckboxValidator";
import {UpdateUserInput} from "../../API";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const SecurityEditDialog = ({
  open,
  user,
  onClose,
  onSave
}: {
  open?: boolean
  user: User
  onClose(): void
  onSave(update: User): void
}) => {
  const [editPassword, setEditPassword] = useState<boolean>(false)
  const [oldPassword, setOldPassword] = useState<string>("")
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false)
  const [newPassword, setNewPassword] = useState<string>("")
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
  const [oldPhone, setOldPhone] = useState<string>("")
  const [newPhone, setNewPhone] = useState<string>("")
  const [code, setCode] = useState<string>("")
  const [mfaPreference, setMfaPreference] = useState<MFAPreference>(MFAPreference.off)
  const [optInCheckbox, setOptInCheckbox] = useState<boolean>(false)
  const [optInDisabled, setOptInDisabled] = useState<boolean>(false)
  const [advisorAccess, setAdvisorAccess] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("")
  const [isConfirming, setIsConfirming] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const { authAPI, notify } = useResources()

  useEffect( () => {
    setMfaPreference(user.mfaPreference)
    if (user.mfaPreference === MFAPreference.sms) {
      setOptInCheckbox(true)
      setOptInDisabled(true)
    }
    setOldPhone(user.phone)
    setNewPhone(phoneToNationalFormat(user.phone))
    setAdvisorAccess(user.advisorAccess)
  }, [user])

  const {userStore} = useResources()

  const theme = useTheme()

  const styles = {
    submitButton: {
      color: "#fff",
    },
    instructions: {
      marginTop: theme.spacing(2),
      marginBottom: 0
    }
  }

  const handleClose = () => {
    setMessage("")
    onClose()
  };

  const handleSubmit = async () => {
    setMessage("")

    if (mfaPreference === MFAPreference.sms && !optInCheckbox) {
      setMessage("You must check the Opt-in checkbox to enable MFA.")
      return
    }

    try {
      setIsProcessing(true)
      let updateUser = false

      const phone_number = newPhone ? phoneToE164Format(newPhone) : ""

      if (newPassword) {
        const authUser = await Auth.currentAuthenticatedUser()
        if (authUser) {
          const result = await Auth.changePassword(authUser, oldPassword, newPassword)
          if (result && result === "SUCCESS") {
            notify!.show("success", "Password changed!")
          }
        }
      }

      if ((mfaPreference === MFAPreference.sms && user.mfaPreference !== MFAPreference.sms && !oldPhone) ||
          (mfaPreference === MFAPreference.sms && oldPhone !== phone_number)) {
        // Confirm the phone number

        if (!isConfirming) {
          // const alias = await authAPI?.getAlias(newPhone)
          //
          // if (alias && alias.status === "VERIFIED") {
          //   setMessage("The new phone is already in use.")
          //   return
          // }

          const authUser = await Auth.currentAuthenticatedUser()
          if (authUser) {
            const result = await Auth.updateUserAttributes(authUser, {phone_number: phone_number})
            if (result) {
              setIsConfirming(true)
              return
            }
          }
        } else {
          let result = await Auth.verifyCurrentUserAttributeSubmit('phone_number', code)
          if (result) {
            const authUser = await Auth.currentAuthenticatedUser()
            result = await Auth.setPreferredMFA(authUser, "SMS_MFA")
            if (result) {
              updateUser = true
            }
          }
        }
      } else if (mfaPreference !== user.mfaPreference) {
        const authUser = await Auth.currentAuthenticatedUser()
        const result = await Auth.setPreferredMFA(authUser, mfaPreference === MFAPreference.sms ? "SMS_MFA" : "NOMFA")
        if (result) {
          updateUser = true
        }
      } else {
        updateUser = true
      }

      if (updateUser) {
        // Update User record
        const input: UpdateUserInput = {
          id: user.id,
          accountId: user.accountId,
          mfaPreference,
          advisorAccess
        }
        if (phone_number) {
          input.phone = phone_number
        }

        const updatedUser = await userStore.updateUser(input)
        if (updatedUser) {
          notify!.show("success", "Security settings updated!")
          onSave(updatedUser)
        }
      }

    } catch (err: any) {
      setMessage(err.message)
    } finally {
      setIsProcessing(false)
    }


  }

  const handleResendCode = () => {
    setMessage("")

    Auth.currentAuthenticatedUser()
      .then(user => {
        setIsProcessing(true)
        Auth.verifyCurrentUserAttribute("phone_number")
          .then(data => {
            setIsConfirming (true)
            setIsProcessing(false)
            notify!.show("success", "Confirmation phone resent!")
          })
          .catch(err => {
            setMessage(err.message)
            setIsProcessing(false)
          });
      })
      .catch(err => {
        setMessage(err.message)
      });
  }

  return (
    <Dialog
      id="security-change-dialog"
      open={open === true}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      aria-labelledby="security-change-dialog-title">
      <FormValidator onSubmit={handleSubmit} autoComplete="off">
        <DialogTitle id="security-change-dialog-title">Security Settings</DialogTitle>
        <DialogContent>
          <Box pt={2}>
            {message &&
              <DialogContentText color="error">
                {message}
              </DialogContentText>
            }
            <TextFieldValidator
              type={showOldPassword ? "text" : "password"}
              variant="standard"
              autoFocus={editPassword}
              label={editPassword ? "Current Password" : "Password"}
              value={editPassword ? oldPassword : "*********************"}
              fullWidth
              disabled={!editPassword || isConfirming}
              onChange={(event: any) => setOldPassword(event.target.value)}
              inputProps={{ autoComplete: 'new-password'}}
              InputProps={!editPassword ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconicButton onClick={() => {
                      setEditPassword(true)
                    }}>
                      <EditIcon />
                    </IconicButton>
                  </InputAdornment>
                ),
              } : {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                    >
                      {showOldPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {editPassword &&
              <TextFieldValidator
                type={showNewPassword ? "text" : "password"}
                variant="standard"
                label="New Password"
                validators={{ required: true, isStrongPassword: 3 }}
                value={newPassword}
                disabled={isConfirming}
                fullWidth
                onChange={(event: any) => setNewPassword(event.target.value)}
                inputProps={{ autoComplete: 'new-password'}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            }
            <FormControlLabel control={
                <Switch checked={mfaPreference === MFAPreference.sms} disabled={isConfirming}
                        onChange={(event: any) => {
                          setMfaPreference(event.target.checked ? MFAPreference.sms : MFAPreference.off)
                        }}
                />
              }
              label="Enable Multi-Factor Authentication (MFA) by SMS Text"
            />
            {mfaPreference === MFAPreference.sms &&
              <TextFieldValidator
                margin="dense"
                name="newPhone"
                label="Phone Number"
                type="phone"
                variant="standard"
                validators={{ required: mfaPreference === MFAPreference.sms, isMobilePhone: true }}
                required={mfaPreference === MFAPreference.sms}
                value={newPhone}
                onChange={(event: any) => setNewPhone(event.target.value)}
                disabled={isConfirming}
                fullWidth
              />
            }
            {mfaPreference === MFAPreference.sms &&
              <FormControlLabel
                sx={{ px: 2, mt: 1 }}
                control={
                  <CheckboxValidator
                    name="optInCheckbox"
                    color="primary"
                    checked={optInCheckbox}
                    required={true}
                    value="checkedG"
                    disabled={optInDisabled || isConfirming}
                    onChange={(event: any) => setOptInCheckbox(event.target.checked)}
                  />
                }
                label={
                  <Typography variant="body2" sx={{ paddingLeft:"16px" }}>
                    By checking this box, I Opt-In to receiving Multi-Factor Authentication (MFA) text messages during sign-in from Retirement Budget Calculator to the phone number provided. Data rates may apply. I understand I may revoke this at any time by returning to this page.
                  </Typography>
                }
              />
            }
            <FormControlLabel control={
                <Switch checked={advisorAccess} disabled={isConfirming}
                  onChange={(event: any) => {
                      setAdvisorAccess(event.target.checked)
                  }}
                />
              }
              label="Enable Adivsor Access for Parker Financial LLC"
            />
            <Visible cond={isConfirming}>
              <DialogContentText sx={styles.instructions}>
                Please check your phone for a confirmation code.
              </DialogContentText>
              <TextFieldValidator
                margin="dense"
                name="code"
                label="Confirmation Code"
                type="text"
                variant="standard"
                value={code}
                validators={{ required: true, matches: "^\\d{6}$" }}
                onChange={(event: any) => setCode(event.target.value)}
                fullWidth
              />
              <DialogButton variant="tertiary" onClick={handleResendCode}>
                Resend confirmation code
              </DialogButton>
            </Visible>
          </Box>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleClose} />
          <ProgressButton variant="contained" color="primary"
            type="submit" processing={isProcessing}
            onClick={handleSubmit}>
            {isConfirming ? "Confirm" : "Save"}
          </ProgressButton>
        </DialogActions>
      </FormValidator>
    </Dialog>
  )
}

export default SecurityEditDialog