import * as React from "react";
import {useEffect, useState} from "react";
import {
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  useTheme
} from "@mui/material";
import User from "../../model/User";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import {useResources} from "../../stores/ResourceProvider";
import ModelEditDialog from "../../components/model/ModelEditDialog";
import {CreateUserInput, ModelUserFilterInput, UpdateUserInput, UserRole, UserStatus, UserType} from "../../API";
import IconicButton from "../../components/controls/IconicButton";
import EditIcon from "@mui/icons-material/Edit";
import DialogContentText from "@mui/material/DialogContentText";
import {Auth} from "aws-amplify";
import Visible from "../../components/Visible";
import DialogButton from "../../components/form/DialogButton";
import {getISODateToday} from "../../stores/StoreUtilities";
import Model from "../../model/Model";
import {ISubscriber} from "../../apis/ConvertKitAPI";

enum InviteOption {
  None = "None",
  Send = "Send",
  Resend = "Resend",
  Revoke = "Revoke"
}

const UserEditDialog = ({
  open,
  user,
  onClose,
  onSave,
  onDelete
}: {
  open?: boolean
  user: User
  onClose?(): void
  onSave?(update: User): void
  onDelete?(user: User): void
}) => {
  const [userModel, setUserModel] = useState<User>(user)
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>()
  const [editEmail, setEditEmail] = useState<boolean>(false)
  const [isSelf, setIsSelf] = useState<boolean>(false)
  const [userStatus, setUserStatus] = useState<UserStatus>(UserStatus.Created)
  const [userStatusOptions, setUserStatusOptions] = useState<UserStatus[]>([])
  const [inviteOptions, setInviteOptions] = useState<InviteOption[]>([])
  const [inviteOption, setInviteOption] = useState<InviteOption | undefined>()
  const [userType, setUserType] = useState<UserType>(UserType.Free)
  const [role, setRole] = useState<UserRole>(UserRole.Customer)
  const [customerId, setCustomerId] = useState<string | undefined>()
  // const [advisorAccess, setAdvisorAccess] = useState<boolean>(false)
  const [newEmail, setNewEmail] = useState<string>("")
  const [code, setCode] = useState<string>("")
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("")
  const [isConfirming, setIsConfirming] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const theme = useTheme()
  const { userStore, accountStore, modelStore, confirm, authAPI, convertKitAPI,notify } = useResources()

  const styles = {
    actions: {
      display:"flex",
      flexDirection:"row",
      width:"100%",
      justifyContent:"space-between",
      alignItems:"center"
    },
    row: {
      maxHeight: 48
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"]
    },
    instructions: {
      marginTop: theme.spacing(2),
      marginBottom: 0
    }
  }

  useEffect(() => {
    setFirstName(user.firstName)
    setLastName(user.lastName)
    setEmail(user.email)
    setUserStatus(user.userStatus)
    setUserType(user.userType)
    setRole(user.role)
    setCustomerId(user.customerId)
    // setAdvisorAccess(user.advisorAccess)
    setIsSelf(userStore?.user?.id === user.id)
    setIsOpen(open === true)
    setUserStatusOptions(getUserStatusOptions(user))
    setInviteOptions(getInviteOptions(user))
    setInviteOption(InviteOption.None)
  }, [user, open])

  const getUserStatusOptions = (user: User): UserStatus[] => {
    let options: UserStatus[] = []

    if (userStore.isAdvisorOrAdmin) {
      if (user.userStatus === UserStatus.Created || user.userStatus === UserStatus.Invited) {
        options = [UserStatus.Created, UserStatus.Invited, UserStatus.Active]
      } else if (user.userStatus === UserStatus.Active || user.userStatus === UserStatus.Cancelled || user.userStatus === UserStatus.Deleted) {
        options = [UserStatus.Created, UserStatus.Active, UserStatus.Cancelled]
      }
    }

    return options
  }

  const getInviteOptions = (user: User): InviteOption[] => {
    let options: InviteOption[] = []

    if (userStore.isAdvisorOrAdmin) {
      if (user.userStatus === UserStatus.Created) {
        options = [InviteOption.None, InviteOption.Send]
      } else if (user.userStatus === UserStatus.Invited) {
        options = [InviteOption.None, InviteOption.Resend, InviteOption.Revoke]
      } else {
        options = [InviteOption.None]
      }
    }

    return options
  }

  const handleClose = async (event: any) => {
    if (onClose) {
      onClose()
    }
  }

  const handleSave = async (event: any) => {
    setMessage("")

    try {
      setIsProcessing(true)
      if (!userModel.id) {
        if (await isEmailInUse(email!)) {
          setMessage("This email is already in use.")
          return
        }
        const input: CreateUserInput = {
          userStatus: userStatus,
          userType: userType,
          accountId: userModel.accountId,
          firstName: firstName,
          lastName: lastName,
          email: email!,
          role: role,
          // advisorAccess: advisorAccess,
          startDate: getISODateToday(),
          customerId: customerId ?? null
        }
        const user = await userStore.createUser(input, false)

        if (user) {
          await modelStore.createBaseModel(user)

          if (inviteOption === InviteOption.Send) {
            await accountStore.sendUserInvite(user)
          }

          if (onSave) {
            onSave(user)
          }
        }
      } else {
        let updateUser = false

        if (editEmail) {
          if (!isConfirming) {
            const alias = await authAPI?.getAlias(newEmail)

            if (alias && alias.status === "VERIFIED") {
              setMessage("The new email is already in use.")
              return
            }

            const authUser = await Auth.currentAuthenticatedUser()
            if (authUser) {
              const result = await Auth.updateUserAttributes(authUser, { email: newEmail.toLowerCase() })
              if (result) {
                setIsConfirming(true)
                return
              }
            }
          } else {
            const result = await Auth.verifyCurrentUserAttributeSubmit('email', code)
              if (result) {
                notify!.show("success", "Email address updated!")
                setIsConfirming(false)
                setEmail(newEmail)
                setEditEmail(false)
                updateUser = true
              }
          }
        } else {
          updateUser = true
        }

        if (updateUser) {
          if (email !== userModel.email && await isEmailInUse(email!)) {
            setMessage("This email is already in use.")
            return
          }
          const update: UpdateUserInput = {
            id: user.id,
            accountId: user.accountId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            // advisorAccess
          }
          if (editEmail) {
            update.email = newEmail
          }
          if (isAdvisorOrAdmin && userStatus !== user.userStatus) {
            update.userStatus = userStatus
          }
          if (isAdvisorOrAdmin && userType !== user.userType) {
            update.userType = userType
          }
          if (isAdvisorOrAdmin && role !== user.role) {
            update.role = role
          }
          if (isAdvisorOrAdmin && customerId !== user.customerId) {
            update.customerId = customerId
          }

          const updatedUser = await userStore.updateUser(update)

          if (updatedUser) {
            if (inviteOption === InviteOption.Send || inviteOption === InviteOption.Resend) {
              await accountStore.sendUserInvite(user)
            } else if (inviteOption === InviteOption.Revoke) {
              await accountStore.revokeUserInvite(user)
            }
          }

          if (updatedUser) {
            await updateConvertKitSubscriber(user, update)
          }

          if (onSave && updatedUser) {
            onSave(updatedUser)
          } else if (onClose) {
            onClose()
          }
        }

      }
    } catch (err: any) {
      setIsProcessing(false)
      notify.show('error', err.message)
    }
  }

  const updateConvertKitSubscriber = async (user: User, input: UpdateUserInput) => {
    // Get subscriber
    const response = await convertKitAPI.getSubscriber(user.email)
      .catch((err: any) => {
        // Ignore
        console.log(`Subscriber not found`)
      })

    if (response && response.subscribers && response.subscribers.length > 0) {
      console.log(`Got Subscriber`)
      // Update subscriber
      const subscriber = response.subscribers[0]
      const update: ISubscriber = {}
      if (input.email) {
        update.email = input.email
      }
      if (input.firstName) {
        update.first_name = input.firstName
      }
      if (input.lastName) {
        update.last_name = input.lastName
      }
      const result = await convertKitAPI.putSubscriber(subscriber.id, update)
        .catch ((err: any) => {
          // Ignore
          console.log(`Unable to update subcriber`)
        })
    }
  }

  const isEmailInUse = async (email: string) => {
    // Confirm the email isn't already in use by another user
    const filter: ModelUserFilterInput = {
      email: {eq: email.toLowerCase()}
    }
    const users = await accountStore.listUsersByAccount(userModel.accountId, filter)
    return (users.length > 0 && users[0].id !== userModel.id)
  }

  const handleDelete = async () => {
    if (onDelete) {
      const user = await userStore.getUser(userModel.id)
      if (user) {
        // Delete models
        const promises : Promise<Model | undefined>[] = []
        user.models.forEach((model: Model) => {
          promises.push(modelStore.deleteModel(model.id, true))
        })
        await Promise.all(promises)

        const deleted = await accountStore.deleteUser(user.id)
        if (deleted && onDelete) {
          onDelete(deleted)
        }
      }
    }
  }

  const handleResendCode = () => {
    setMessage("")

    Auth.currentAuthenticatedUser()
      .then(user => {
        setIsProcessing(true)
        Auth.verifyCurrentUserAttribute("email")
          .then(data => {
            setIsConfirming (true)
            setIsProcessing(false)
            notify!.show("success", "Confirmation email resent!")
          })
          .catch(err => {
            setMessage(err.message)
            setIsProcessing(false)
          });
      })
      .catch(err => {
        setMessage(err.message)
      });
  }

  if (!userModel || !isOpen) {
    return null
  }

  const isAdvisorOrAdmin = userStore.isAdvisorOrAdmin

  const emailDisabled = userModel.userStatus !== UserStatus.Created && userModel.userStatus !== UserStatus.Invited
  const userStatusEnabled = userStore.isAdvisorOrAdmin /* && userModel.userStatus &&
    (userModel.userStatus === UserStatus.Active || userModel.userStatus === UserStatus.Cancelled || userModel.userStatus === UserStatus.Deleted) */
  const inviteEnabled = userStore.isAdvisorOrAdmin &&
    (!userModel.userStatus || userModel.userStatus === UserStatus.Created || userModel.userStatus === UserStatus.Invited)

  return (
    <React.Fragment>
      <ModelEditDialog title="User Settings" open={isOpen}
                       onCancel={handleClose}
                       onSave={handleSave}
                       onDelete={onDelete && userModel.id ? handleDelete : undefined}
        >
          {message &&
            <DialogContentText color="error">
              {message}
            </DialogContentText>
          }
          <TextFieldValidator
            autoFocus={true}
            type="text"
            variant="standard"
            validators={{ required: true }}
            name="firstName"
            label="First Name"
            value={firstName}
            onChange={(event: any) => setFirstName(event.target.value)}
            fullWidth
          />
          <TextFieldValidator
            type="text"
            variant="standard"
            validators={{ required: true }}
            name="lastName"
            label="Last Name"
            value={lastName}
            onChange={(event: any) => setLastName(event.target.value)}
            fullWidth
          />
          <TextFieldValidator
            type="text"
            variant="standard"
            validators={{ required: true, isEmail: null }}
            name="email"
            label="Email"
            value={email}
            onChange={(event: any) => setEmail(String(event.target.value).toLowerCase())}
            fullWidth
            disabled={emailDisabled}
            InputProps={isSelf ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconicButton onClick={() => setEditEmail(true)}>
                    <EditIcon />
                  </IconicButton>
                </InputAdornment>
              ),
            }: {}}
          />
          {editEmail &&
            <TextFieldValidator
              margin="dense"
              name="newEmail"
              label="New Email"
              type="email"
              variant="standard"
              validators={{ required: true, isEmail: true }}
              value={newEmail}
              onChange={(event: any) => setNewEmail(String(event.target.value).toLowerCase())}
              disabled={isConfirming}
              fullWidth
            />
          }
          {isAdvisorOrAdmin &&
            <TextFieldValidator
              type="text"
              validators={{ required: true }}
              required
              name="role"
              label="User Role"
              variant="standard"
              autocompleteOptions={{
                freeSolo: false,
                options: Object.values(UserRole),
                value: role,
                onChange: (event: any, value: UserRole, reason: any) => {
                  setRole(value)
                }
              }}
            />
          }
          {isAdvisorOrAdmin &&
            <TextFieldValidator
              type="text"
              validators={{ required: true }}
              required
              name="userType"
              label="User Type"
              variant="standard"
              autocompleteOptions={{
                freeSolo: false,
                options: Object.values(UserType),
                value: userType,
                onChange: (event: any, value: UserType, reason: any) => {
                  setUserType(value)
                }
              }}
            />
          }
          {isAdvisorOrAdmin && userType === UserType.Premium &&
            <TextFieldValidator
              type="text"
              validators={{ required: false }}
              name="customerId"
              label="Stripe Customer ID"
              variant="standard"
              value={customerId}
              onChange={(event: any) => setCustomerId(event.target.value)}
              fullWidth
            />
          }
          {userStatusEnabled &&
            <TextFieldValidator
              type="text"
              validators={{ required: true }}
              required
              name="userStatus"
              label="User Status"
              variant="standard"
              autocompleteOptions={{
                freeSolo: false,
                options: userStatusOptions,
                value: userStatus,
                onChange: (event: any, value: UserStatus, reason: any) => {
                  setUserStatus(value)
                }
              }}
            />
          }
          {inviteEnabled &&
            <FormGroup>
              <FormLabel sx={styles.formLabel}>Invitation Email</FormLabel>
              <RadioGroup aria-label="inviteOption" name="inviteOption" value={String(inviteOption)} row
                          onChange={(event: any) => {
                            setInviteOption(event.target.value)
                            if (event.target.value === "Send" || event.target.value === "Resend") {
                              setUserStatus(UserStatus.Invited)
                            } else if (event.target.value === "Revoke") {
                              setUserStatus(UserStatus.Created)
                            }
                          }}>
                {inviteOptions.map((option: InviteOption) =>
                    <FormControlLabel
                      value={option}
                      control={<Radio color="secondary"/>}
                      label={option}
                      labelPlacement="end"
                    />
                  )}
              </RadioGroup>
            </FormGroup>
          }
          {/*{isAdvisorOrAdmin &&*/}
          {/*  <FormGroup>*/}
          {/*    <FormLabel sx={styles.formLabel}>Advisor Access</FormLabel>*/}
          {/*    <RadioGroup aria-label="advisorAccess" name="advisorAccess" value={String(advisorAccess)} row*/}
          {/*                onChange={(event: any) => {*/}
          {/*                  setAdvisorAccess(event.target.value === "true")*/}
          {/*                }}>*/}
          {/*      <FormControlLabel*/}
          {/*        value="false"*/}
          {/*        control={<Radio color="secondary"/>}*/}
          {/*        label="No"*/}
          {/*        labelPlacement="end"*/}
          {/*      />*/}
          {/*      <FormControlLabel*/}
          {/*        value="true"*/}
          {/*        control={<Radio color="secondary"/>}*/}
          {/*        label="Yes"*/}
          {/*        labelPlacement="end"*/}
          {/*      />*/}
          {/*    </RadioGroup>*/}
          {/*  </FormGroup>*/}
          {/*}*/}
          <Visible cond={isConfirming}>
            <DialogContentText sx={styles.instructions}>
              Please check your new email for a confirmation code.
            </DialogContentText>
            <TextFieldValidator
              margin="dense"
              name="code"
              label="Confirmation Code"
              type="text"
              variant="standard"
              value={code}
              validators={{ required: true, matches: "^\\d{6}$" }}
              onChange={(event: any) => setCode(event.target.value)}
              fullWidth
            />
            <DialogButton variant="tertiary" onClick={handleResendCode}>
              Resend confirmation code
            </DialogButton>
          </Visible>
      </ModelEditDialog>
    </React.Fragment>
  )
}

export default UserEditDialog