import {styled, Theme} from "@mui/material/styles";
import {Box, CSSObject} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";

const openedMixin = (theme: Theme): CSSObject => ({
  width: openDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
});

export const openDrawerWidth = 300;
export const closedDrawerWidth = 32

export interface IDrawerHeaderProps {
  open?: boolean
}

export const DrawerHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open'
})<IDrawerHeaderProps>(
  ({ theme, open }) => ({
  position: `fixed`,
  top: 115,
  left: open ? openDrawerWidth : closedDrawerWidth,
  marginLeft: -12,
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-end",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create("left ", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}));


interface IDrawerProps {
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})<IDrawerProps>(
  ({ theme, open }) => ({
    width: open ? openDrawerWidth : closedDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
      // ".toggle-button": {
      //   opacity: 1,
      //   transitionDelay: `0.2s`
      // },
      // "+ .toggle-button": {
      //   opacity: 0,
      //   "&:not([open]):active": {
      //     pointerEvents: `none`
      //   }
      // }
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      // ".toggle-button": { opacity: 0 },
      // "+ .toggle-button": { opacity: 1 },
      // "&:hover ": {
      //   ...openedMixin(theme),
      //   "& .MuiDrawer-paper": openedMixin(theme),
      //   ".toggle-button": {
      //     opacity: 1,
      //     left: openDrawerWidth,
      //     transitionDelay: `0.2s`,
      //     svg: {
      //       transform: `rotate(180deg)`
      //     }
      //   },
      //   "+ .toggle-button": {
      //     opacity: 0
      //   }
      // }
    }),
  }),
);

export default Drawer