import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Drawer, IconButton, useMediaQuery, useTheme} from "@mui/material";
import { positions } from '@mui/system';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const ChatbotDrawer = ({
  open,
  onChange
}: {
  open?: boolean
  onChange(open: boolean): void
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [width, setWidth] = useState<number>(0)

  const openWidth = 400

  useEffect(() => {
    setIsOpen(open === true)
    if (open === true) {
      setWidth(openWidth)
    } else {
      setWidth(0)
    }
  }, [open])

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('xl'))
  const styles = {
    panel: {
      display: "flex",
      width: width,
      flexShrink: 0,
      padding:0
    },
    overlay: {
      position: "absolute",
      top: 80,
      right: 0,
      height: 'calc(100vh - 80px',
      width: width,
      minWidth: width,
      padding:0,
      zIndex: 9999,
    },
    drawer: {
      width: openWidth,
      flexShrink: 0
    },
    chatCloseBtn: {
      color:"inherit",
      p:0,
      mr:1,
      width: 24,
      height: 24,
      backgroundColor: `#dfdfee`,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    }
  }

  const handleDrawerOpen = () => {
    onChange(true)
  };

  const handleDrawerClose = () => {
    onChange(false);
  };

  const toggleDrawer = () => {
    onChange(!isOpen)
  }

  if (!isOpen) {
    return null
  }

  return (
    <Drawer
      variant={isLarge ? "permanent" : "permanent"}
      anchor="right"
      open={isOpen}
      sx={styles.drawer}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        // necessary for content to be below app bar
        // ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
        marginTop: "76px"
      }}>
        <Box sx={{position:"fixed", top:126, right:4}}>
          <IconButton size="small" onClick={toggleDrawer} sx={styles.chatCloseBtn}>
            {isOpen ?  <ChevronRightIcon/> : <ChevronLeftIcon/> }
          </IconButton>
        </Box>
        {/*<IconButton onClick={handleDrawerClose}>*/}
        {/*  {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}*/}
        {/*</IconButton>*/}
        <iframe
          src="https://www.chatbase.co/chatbot-iframe/UwMm3JBWLZX_M4yxT1dgm"
          width="100%"
          style={{height: "calc(100vh - 80px)", minHeight: 700, width: openWidth}}
          frameBorder="0"
        ></iframe>
      </Box>
    </Drawer>
  )
}

export default ChatbotDrawer