import {Box, Button, FormLabel, Grid, InputAdornment, Paper, TableContainer, Typography, useTheme} from "@mui/material";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {useResources} from "../../../stores/ResourceProvider";
import Person from "../../../model/Person";
import {differenceInYears, startOfToday} from "date-fns";
import Model from "../../../model/Model";
import {
  FutureCalc,
  IAssetDetail,
  IFutureYear,
  IIncomeSummary,
  IIncomeTypeSummary,
  IPersonTimeline,
  ITaxSummary,
  ITaxTypeSummary
} from "../../../components/calculator/Calculator";
import BasicMenuButton from "../../../components/controls/BasicMenuButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import range from "lodash.range";
import {humanizeString, numberToMoneyFormat, numberToPercentFormat} from "../../../stores/StoreUtilities";
import Section from "../../../components/controls/Section";
import fill from "lodash.fill";
import TaxValue, {TaxValueKey} from "../../../model/TaxValue";
import TextFieldValidator from "../../../components/form/TextFieldValidator";
import {CreateTaxValueInput, MaritalStatus, UpdateTaxValueInput} from "../../../API";
import {TaxEstimate, TaxFilingStatus, TaxInput} from "../../../components/tax/TaxEstimator";
import FormValidator from "../../../components/form/FormValidator";
import {parseNumber} from "../../../model/ModelUtilities";
import {IncomeType} from "../../../model/Income";
import {TaxType} from "../../../model/Tax";
import ExpandIcon from '@mui/icons-material/Expand';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import Plan from "../../../model/Plan";
import ViewMenu from "../../../components/controls/ViewMenu";
import TaxableIncomeEstimateChart from "./charts/TaxableIncomeEstimateChart";
import LifetimeEstimatedTaxesChart from "./charts/LifetimeEstimatedTaxesChart";
import TaxableIncomeByTaxBracketChart from "./charts/TaxableIncomeByTaxBracketChart";
import TaxTables from "./charts/TaxTables";
import Tracking from "../../../components/Tracking";
import {useNavigate} from "react-router-dom";
import RoutesConfig from "../../../RoutesConfig";
import LifetimeTaxTable from "./charts/LifetimeTaxTable";
import IModelItem from "../../../model/IModelItem";

enum TaxesChartView {
  LifetimeEstTax,
  TaxableIncomeEstTax,
  TaxableIncomeByTaxBracket,
  TaxTables
}

enum TaxSection {
  Household,
  Income,
  Tax,
  Payments,
  FederalResults,
  OtherTaxWithholding,
  Totals
}

const ViewStorageKey = "RBC.TaxesViewSettings"
interface ITaxesViewSettings {
  expandLeftPanel: boolean
  expandedSections: boolean[]
  currentYear: number
  chartView: TaxesChartView
}

const TaxesView = ({
  model,
  plan,
  updatedAt,
  year,
  width,
  onChange
} : {
  model: Model
  plan: Plan
  updatedAt: string
  year?: number
  width: number
  onChange?(item: IModelItem): void
}) => {
  const [currentModel, setCurrentModel] = useState<Model | undefined>()
  const [currentPlan, setCurrentPlan] = useState<Plan | undefined>()
  const [currentUpdate, setCurrentUpdate] = useState<string>("")
  const [startYear, setStartYear] = useState<number>(0)
  const [endYear, setEndYear] = useState<number>(0)
  const [currentYear, setCurrentYear] = useState<number>(0)
  const [expandedSections, setExpandedSections] = useState<boolean[]>(fill(new Array<boolean>(TaxSection.Totals+1), false, 0, TaxSection.Totals))
  const [expandLeftPanel, setExpandLeftPanel] = useState<boolean>(false)
  const [futureCalc, setFutureCalc] = useState<FutureCalc | undefined>()
  const [futureYear, setFutureYear] = useState<IFutureYear | undefined>()
  const [chartView, setChartView] = useState<TaxesChartView>(TaxesChartView.LifetimeEstTax)

  const [persons, setPersons] = useState<Person[]>([])
  const [timelines, setTimelines] = useState<(IPersonTimeline | null)[]>([])
  const [taxValues, setTaxValues] = useState<TaxValue[]>([])
  // const [incomeSummary, setIncomeSummary] = useState<IIncomeSummary | undefined>()
  // const [taxSummary, setTaxSummary] = useState<ITaxSummary | undefined>()
  const [defaultFilingStatus, setDefaultFilingStatus] = useState<TaxFilingStatus>(TaxFilingStatus.Single)
  const [filingStatus, setFilingStatus] = useState<TaxFilingStatus>(TaxFilingStatus.Single)
  const [filingStatusYear, setFilingStatusYear] = useState<number>(0)
  const [numberOfFilersOver65, setNumberOfFilersOver65] = useState<number>(0)
  const [adjustmentsToIncome, setAdjustmentsToIncome] = useState<string>("0")
  const [adjustmentsToIncomeYear, setAdjustmentsToIncomeYear] = useState<number>(0)
  const [itemizedDeductionsIn, setItemizedDeductionsIn] = useState<string>("0")
  const [itemizedDeductionsInYear, setItemizedDeductionsInYear] = useState<number>(0)
  const [otherQualifyingDeductionsToTaxableIncome, setOtherQualifyingDeductionsToTaxableIncome] = useState<string>("0")
  const [otherQualifyingDeductionsToTaxableIncomeYear, setOtherQualifyingDeductionsToTaxableIncomeYear] = useState<number>(0)
  const [childTaxCreditOrCreditForOtherDependents, setChildTaxCreditOrCreditForOtherDependents] = useState<string>("0")
  const [childTaxCreditOrCreditForOtherDependentsYear, setChildTaxCreditOrCreditForOtherDependentsYear] = useState<number>(0)
  const [additionalCreditsAndPayments, setAdditionalCreditsAndPayments] = useState<string>("0")
  const [additionalCreditsAndPaymentsYear, setAdditionalCreditsAndPaymentsYear] = useState<number>(0)
  const [earnedIncomeCredit, setEarnedIncomeCredit] = useState<string>("0")
  const [earnedIncomeCreditYear, setEarnedIncomeCreditYear] = useState<number>(0)
  const [refundableAdditionalChildTaxCredits, setRefundableAdditionalChildTaxCredits] = useState<string>("0")
  const [refundableAdditionalChildTaxCreditsYear, setRefundableAdditionalChildTaxCreditsYear] = useState<number>(0)
  const [otherPaymentsAndRefundableCredits, setOtherPaymentsAndRefundableCredits] = useState<string>("0")
  const [otherPaymentsAndRefundableCreditsYear, setOtherPaymentsAndRefundableCreditsYear] = useState<number>(0)
  const [taxInput, setTaxInput] = useState<TaxInput>(new TaxInput())
  const [taxEstimate, setTaxEstimate] = useState<TaxEstimate>(new TaxEstimate())

  const theme = useTheme()

  const styles = {
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"],
    },
    fullLabelCell: {
      width: "85%"
    },
    primaryLabelCell: {
      width: "55%"
    },
    secondaryLabelCell: {
      width: "15%",
      textAlign: "right",
      textWrap: "nowrap",
      border: "1px solid rgba(224, 224, 224, 1)"
    },
    dataCell: {
      width: "15%",
      textAlign: "right",
      border: "1px solid rgba(224, 224, 224, 1)"
    },
    labelItem : {
      padding: theme.spacing(1),
      border: "1px solid rgba(224, 224, 224, 1)",
      fontSize: 14,
    },
    valueItem : {
      padding: theme.spacing(1),
      textAlign: "right",
      border: "1px solid rgba(224, 224, 224, 1)",
      fontSize: 14,
      backgroundColor: theme.palette.grey["50"]
    },
    detailItem : {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      backgroundColor: "rgb(221, 221, 221)",
      // border: "1px solid rgba(224, 224, 224, 1)",
      fontSize: 14,
    },
    detailLabel : {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      backgroundColor: "rgb(249, 249, 249)",
      // border: "1px solid rgba(224, 224, 224, 1)",
      fontSize: 14,
    },
    detailValue : {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      textAlign: "right",
      // border: "1px solid rgba(224, 224, 224, 1)",
      fontSize: 14,
      backgroundColor: "rgb(249, 249, 249)",
      // backgroundColor: theme.palette.grey["50"]
    },
    fieldItem: {
      padding: 0,
      marginBottom: 0,
      fontSize: 14,
      ".MuiFormControl-root": {
        marginBottom: 0,
      },
      border: "1px solid rgba(224, 224, 224, 1)",
    },
    autocompleteField: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    },
    outlinedTextField: {
      marginBottom: 0,
      // ".MuiOutlinedInputBase-root": {
      //   borderRadius: 0,
      //   '& fieldset': {
      //     borderRadius: 0,
      //   },
      // },
      // ".MuiInputBase-root": {
      //   borderRadius: 0
      // },
      // '& .MuiOutlinedInput-root': {
      //   '& fieldset': {
      //     borderRadius: 0,
      //   },
      // },
    },
    outlineTextFieldInputProps: {
      fontSize: 16,
      paddingRight: 0,
      textAlign: "right",
      "&::placeholder": {
        textAlign: "right",
      }
    },
    inputAdornment: {
      fontSize: 14,
      ".MuiTypography-body1": {
        fontSize: 14
      }
    }
  }

  const { modelStore, calculator } = useResources()
  const navigate = useNavigate()

  useEffect(() => {
    loadView(model, plan)
    // console.log(`TaxesView updatedAt: ${updatedAt}`)
  }, [model, plan, updatedAt])

  const loadView = async (model: Model, plan: Plan) => {
    if (!currentModel || model.id !== currentModel.id || model.updatedAt !== currentModel.updatedAt ||
        updatedAt !== currentUpdate) {
      console.log(`TaxesView.loadView`)

      setCurrentModel(model)
      setCurrentPlan(plan)
      setCurrentUpdate(updatedAt)
      setPersons(model.persons)
      const lines = model.persons.map((p: Person) => calculator.personTimeline((p)))
      setTimelines(lines)

      const startDate = startOfToday()
      let endDate = startDate

      let defaultFilingStatus: TaxFilingStatus = TaxFilingStatus.Single
      if ((model.persons.length > 0 && model.persons[0].maritalStatus === MaritalStatus.Married) ||
        (model.persons.length > 1 && model.persons[1].maritalStatus === MaritalStatus.Married)) {
        defaultFilingStatus = TaxFilingStatus.MarriedFilingJointly
      }
      setDefaultFilingStatus(defaultFilingStatus)
      const filingStatus = model.taxValues.find((value: TaxValue) => value.key === "filingStatus")
      if (!filingStatus) {
        const input : CreateTaxValueInput = {
          userId: model.userId,
          accountId: model.accountId,
          modelId: model.id,
          year: (new Date()).getFullYear(),
          key: "filingStatus",
          value: defaultFilingStatus
        }
        const taxValue = await modelStore.createTaxValue(input)
        if (taxValue) {
          console.log(`Default filing status tax value created: ${filingStatus}`)
        }
      }


      if (lines.length >= 0 && lines[0]) {
        endDate = lines[0].lifeExpectancyDate
        if (lines.length >= 1 && lines[1]) {
          if (lines[1]?.lifeExpectancyDate.getTime() > endDate.getTime()) {
            endDate = lines[1]?.lifeExpectancyDate
          }
        }
      }
      setTaxValues(model.taxValues)
      setStartYear(startDate.getFullYear())
      setCurrentYear(startDate.getFullYear())
      setEndYear(endDate.getFullYear())
    }

    // Load localStorage view settings
    loadSettings()
  }

  useEffect(() => {
    if (currentModel && timelines && taxValues) {
      console.log(`TaxesView updateValues`)
      let filingStatus = defaultFilingStatus
      let filingStatusYear = currentYear
      let taxValue = getTaxValueObj("filingStatus")
      if (taxValue && taxValue.value !== null) {
        filingStatus = taxValue.value
        filingStatusYear = taxValue.year
      }
      if (filingStatus === TaxFilingStatus.MarriedFilingJointly && model.getExpectedMaritalStatus(currentYear) === MaritalStatus.Single) {
        // Override for death of spouse
        filingStatus = TaxFilingStatus.Single
        filingStatusYear = currentYear
      }
      setFilingStatus(filingStatus)
      setFilingStatusYear(filingStatusYear)

      setNumberOfFilersOver65(getNumberOfFilersOver65())

      taxValue = getTaxValueObj("adjustmentsToIncome")
      if (taxValue && taxValue.value !== null) {
        setAdjustmentsToIncome(taxValue.value.toString())
        setAdjustmentsToIncomeYear(taxValue.year)
      }

      taxValue = getTaxValueObj("itemizedDeductionsIn")
      if (taxValue && taxValue.value !== null) {
        setItemizedDeductionsIn(taxValue.value.toString())
        setItemizedDeductionsInYear(taxValue.year)
      }

      taxValue = getTaxValueObj("otherQualifyingDeductionsToTaxableIncome")
      if (taxValue && taxValue.value !== null) {
        setOtherQualifyingDeductionsToTaxableIncome(taxValue.value.toString())
        setOtherQualifyingDeductionsToTaxableIncomeYear(taxValue.year)
      }

      taxValue = getTaxValueObj("childTaxCreditOrCreditForOtherDependents")
      if (taxValue && taxValue.value !== null) {
        setChildTaxCreditOrCreditForOtherDependents(taxValue.value.toString())
        setChildTaxCreditOrCreditForOtherDependentsYear(taxValue.year)
      }

      taxValue = getTaxValueObj("additionalCreditsAndPayments")
      if (taxValue && taxValue.value !== null) {
        setAdditionalCreditsAndPayments(taxValue.value.toString())
        setAdditionalCreditsAndPaymentsYear(taxValue.year)
      }

      taxValue = getTaxValueObj("earnedIncomeCredit")
      if (taxValue && taxValue.value !== null) {
        setEarnedIncomeCredit(taxValue.value.toString())
        setEarnedIncomeCreditYear(taxValue.year)
      }

      taxValue = getTaxValueObj("refundableAdditionalChildTaxCredits")
      if (taxValue && taxValue.value !== null) {
        setRefundableAdditionalChildTaxCredits(taxValue.value.toString())
        setRefundableAdditionalChildTaxCreditsYear(taxValue.year)
      }

      taxValue = getTaxValueObj("otherPaymentsAndRefundableCredits")
      if (taxValue && taxValue.value !== null) {
        setOtherPaymentsAndRefundableCredits(taxValue.value.toString())
        setOtherPaymentsAndRefundableCreditsYear(taxValue.year)
      }

      if (currentModel) {
        estimateTax()
      }
    }
  }, [currentYear, currentModel, taxValues])

  useEffect(() => {
    if (currentModel) {
      saveSettings()
    }
  }, [currentYear, expandedSections, expandLeftPanel, chartView])

  const loadSettings = () => {
    const data = window.localStorage.getItem(ViewStorageKey)
    if (data) {
      const settings: ITaxesViewSettings = JSON.parse(data)
      setExpandLeftPanel(settings.expandLeftPanel !== undefined ? settings.expandLeftPanel : true)
      setExpandedSections([...settings.expandedSections])
      if (year) {
        setCurrentYear(year)
      } else if (settings.currentYear) {
        setCurrentYear(settings.currentYear)
      }
      if (settings.chartView) {
        setChartView(settings.chartView)
      }
    } else {
      if (year) {
        setCurrentYear(year)
      }
      setExpandLeftPanel(false)
      setExpandedSections(fill(new Array<boolean>(TaxSection.Totals+1), true, 0, TaxSection.Totals))
    }
  }

  const saveSettings = () => {
    const settings = JSON.stringify({
      expandLeftPanel: expandLeftPanel,
      expandedSections: expandedSections,
      currentYear: currentYear,
      chartView: chartView
    })
    window.localStorage.setItem(ViewStorageKey, settings)
  }

  const getExpanded = (index: TaxSection) => {
    if (index <= expandedSections.length + 1) {
      return expandedSections[index]
    } else {
      return false
    }
  }

  const setExpanded = (index: TaxSection, value: boolean) => {
    expandedSections[index] = value
    setExpandedSections([...expandedSections])
  }
  
  const handleChangeYear = (year: number) => {
    setCurrentYear(year)
    navigate(`${RoutesConfig.calc.pathname}/taxes/${year}`)
  }

  const getTaxValue = (key: TaxValueKey, defaultValue?: number): number => {
    let value = 0
    let taxValue = getTaxValueObj(key)
    if (taxValue === undefined) {
      value = defaultValue !== undefined ? defaultValue : 0
    } else if (taxValue.value !== null) {
      value = taxValue.value
    }
    return value
  }

  const getTaxValueObj = (key: TaxValueKey) => {
    // Find the matching TaxValue closest, but less then the currentYear
    let found: TaxValue | undefined
    let index = 0
    while (index < taxValues.length && taxValues[index].year <= currentYear) {
      if (taxValues[index].key === key) {
        found = taxValues[index]
      }
      ++index
    }

    return found
  }

  const getTaxValueIndex = (key: TaxValueKey) => {
    // Find the matching TaxValue closest, but less then the currentYear
    let found = -1
    let index = 0
    while (index < taxValues.length && taxValues[index].year <= currentYear) {
      if (taxValues[index].key === key) {
        found = index
      }
      ++index
    }

    return found
  }

  const setTaxValue = async (key: TaxValueKey, value: string) => {
    let index = getTaxValueIndex(key)
    let taxValue: TaxValue | undefined

    if (index >= 0 && taxValues[index].year === currentYear) {
      // Update it
      taxValue = taxValues[index]
      const update: UpdateTaxValueInput = {
        id: taxValue.id,
        value: parseNumber(value, 0)
      }
      taxValue = await modelStore.updateTaxValue(update)
      if (taxValue) {
        taxValues[index] = taxValue
        setTaxValues([...taxValues])
      }
    } else if (currentModel) {
      const input: CreateTaxValueInput = {
        accountId: currentModel.accountId,
        userId: currentModel.userId,
        modelId: currentModel.id,
        year: currentYear,
        key: key,
        value: parseNumber(value, 0)
      }
      taxValue = await modelStore.createTaxValue(input)
      if (taxValue) {
        const array = [...taxValues, taxValue]
        array.sort((a: TaxValue, b: TaxValue) => a.year - b.year)
        setTaxValues(array)
      }
    }
    if (onChange && taxValue) {
      onChange(taxValue)
    }
    return taxValue
  }

  const getNumberOfFilersOver65 = () => {
    let count = 0
    const taxDate = new Date(currentYear, 12,  31)
    if (timelines.length > 0 && timelines[0]!.birthDate) {
      const taxAge = differenceInYears(taxDate, timelines[0]!.birthDate)
      if (taxAge >= 65) {
        ++count
      }
    }
    if (timelines.length > 1 && timelines[1]!.birthDate) {
      const taxAge = differenceInYears(taxDate, timelines[1]!.birthDate)
      if (taxAge >= 65) {
        ++count
      }
    }
    return count
  }

  const estimateTax = () => {
    if (!currentPlan || !currentModel) {
      return
    }

    setFutureCalc(undefined)
    setFutureYear(undefined)
    setTaxInput(new TaxInput())
    setTaxEstimate(new TaxEstimate())


    let endYear: number = 0
    const latestLifeExpectancyDate = currentModel.getPlanEndDate(currentPlan)
    if (latestLifeExpectancyDate) {
      const latestLifeExpectancyYear = latestLifeExpectancyDate.getFullYear()
      endYear = latestLifeExpectancyYear
    }

    const calc = calculator.calculateFuture(currentModel, currentPlan.name, endYear)
    const futureYear = calc.futureYears[currentYear - startYear]
    if (futureYear && futureYear.taxInput && futureYear.taxEstimate) {
      setFutureCalc(calc)
      setFutureYear(futureYear)
      setTaxInput(futureYear.taxInput)
      setTaxEstimate(futureYear.taxEstimate)
    }
  }

  const renderYearSelector = () => {
    if (startYear > 0) {
      return (
        <BasicMenuButton
          icon={<ExpandMoreIcon sx={{color: theme.palette.primary.main}}/>}
          label={
            <Typography variant="h3" color="primary" sx={{padding:0, margin:0}}>
              {currentYear}
            </Typography>
          }
          labelPosition="left"
          options={range(startYear, endYear).map((year: number) => year.toString())}
          value={currentYear - startYear}
          onClickOption={(index: number) => {
            handleChangeYear(startYear + index)
          }}
        />
      )

    } else {
      return null
    }
  }

  const renderEstimatedTax = () => {
    const estimatedTax = taxEstimate.totalEstimatedTax

    const color = (estimatedTax > 0) ? "red" : "green"
    return (
      <Typography variant="h3" color={color}>{numberToMoneyFormat(estimatedTax, 0)}</Typography>
    )
  }

  const renderHouseholdSection = () => {
    const xp = expandLeftPanel

    return (
      <Section title="Household"
               total={`${persons.length <= 2 ? persons.length : 2}`}
               expanded={getExpanded(TaxSection.Household)}
               onChange={(event: SyntheticEvent, value: boolean) => setExpanded(TaxSection.Household, value)}
      >
        <Grid container direction="column" spacing={0}>
          <Grid container direction="row">
            <Grid item xs={xp ? 7:5} sx={styles.labelItem}>
              Filing Status
            </Grid>
            <Grid item xs={xp ? 5:7} sx={styles.autocompleteField}>
              <TextFieldValidator
                type="text"
                validators={{ required: true }}
                required
                name="filingStatus"
                label=""
                variant="standard"
                size="small"
                // sx={styles.autocompleteField}
                InputProps={{
                  startAdornment: <InputAdornment position="start" sx={styles.inputAdornment}>
                    {filingStatusYear > 0 ? filingStatusYear : ""}
                  </InputAdornment>,
                }}
                autocompleteOptions={{
                  freeSolo: false,
                  options: Object.values(TaxFilingStatus).filter((v) => !isNaN(Number(v))),
                  getOptionLabel: (option: number) => {
                    // return "Label"
                    return(option >= TaxFilingStatus.Single && option <= TaxFilingStatus.MarriedFilingJointly ? humanizeString(TaxFilingStatus[option]) : "")
                  },
                  value: filingStatus,
                  onChange: (event: any, value: number, reason: any) => {
                    if (value === null) {
                      // TODO: Handle deleting a tax value
                    } else {
                      setFilingStatus(value)
                      setFilingStatusYear(currentYear)
                      setTaxValue('filingStatus', value.toString())
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp ? 10:8} sx={styles.labelItem}>
              Number of filers over 65
            </Grid>
            <Grid item xs={xp ? 2:4} sx={styles.valueItem}>
              {numberOfFilersOver65}
            </Grid>
          </Grid>
        </Grid>
      </Section>
    )
  }

  const renderIncomeSection = () => {
    const xp = expandLeftPanel
    const taxableIRADistributions: IAssetDetail[] = futureYear ?
      futureYear.assetDetails.filter((ad: IAssetDetail) => ad.liquidAsset.assetTypeDef.isTaxableIRA && (ad.rmd > 0 || ad.distribution !== 0)) : []

    return (
      <Section title="Income"
               total={numberToMoneyFormat(taxEstimate.taxableIncome, 0)}
               expanded={getExpanded(TaxSection.Income)}
               onChange={(event: SyntheticEvent, value: boolean) => setExpanded(TaxSection.Income, value)}
      >
        <Grid container direction="column" spacing={0}>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              (1) Wages, slaries, tips, etc.
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.income.wagesSalariesTips, 0)}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?5:8} sx={styles.labelItem}>
              (2a) Tax-exempt interest
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.income.taxExemptInterest, 0)}
            </Grid>
            <Grid item xs={xp?3:8} sx={styles.labelItem}>
              (2b) Taxable Interest
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.income.taxableInterest, 0)}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?5:8} sx={styles.labelItem}>
              (3a) Qualifying Dividends
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.income.qualifiedDividends, 0)}
            </Grid>
            <Grid item xs={xp?3:8} sx={styles.labelItem}>
              (3b) Ordinary Dividends
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.income.ordinaryDividends, 0)}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?5:8} sx={styles.labelItem}>
              (4a) Total IRA Distributions
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.income.totalIraDistributions, 0)}
            </Grid>
            <Grid item xs={xp?3:8} sx={styles.labelItem}>
              (4b) Taxable Amount
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.income.taxableIraDistributions, 0)}
            </Grid>
          </Grid>
          {taxableIRADistributions.map((ad: IAssetDetail) =>
            <Grid container direction="row">
              {xp &&
                <Grid item xs={7} sx={styles.detailItem}>
                </Grid>
              }
              <Grid item xs={xp?3:8} sx={styles.detailLabel}>
                {ad.liquidAsset.description}
              </Grid>
              <Grid item xs={xp?2:4} sx={styles.detailValue}>
                {numberToMoneyFormat(ad.rmd + -ad.distribution, 0)}
              </Grid>
            </Grid>
          )}
          <Grid container direction="row">
            <Grid item xs={xp?5:8} sx={styles.labelItem}>
              (5a) Total Pensions and Annuties
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.income.totalPensionsAndAnnuities, 0)}
            </Grid>
            <Grid item xs={xp?3:8} sx={styles.labelItem}>
              (5b) Taxable Amount
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.income.taxablePensionAndAnnuities, 0)}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?5:8} sx={styles.labelItem}>
              (6a) Social Security Benefits
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.income.totalSocialSecurityBenefits, 0)}
            </Grid>
            <Grid item xs={xp?3:8} sx={styles.labelItem}>
              (6b) Taxable Amount
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxEstimate.taxableSocialSecurityBenefits, 0)}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?5:8} sx={styles.labelItem}>
              (7a) Long Term Capital Gains
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.income.qualifiedCapitalGains, 0)}
            </Grid>
            <Grid item xs={xp?3:8} sx={styles.labelItem}>
              (7b) Short Term Capital Gains
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.income.unqualifiedCapitalGains, 0)}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              (8) Other Income (Sch 1 Line 9)
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.income.otherIncome, 0)}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              <b>(9) Total Income</b>
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              <b>{numberToMoneyFormat(taxEstimate.totalIncome, 0)}</b>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              (10) Adjustments to Income (Sch 1 line 26)
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.fieldItem}>
              <TextFieldValidator
                type="number"
                validators={{ isMoney: true }}
                name="adjustmentsToIncome"
                variant="outlined"
                size="small"
                sx={styles.outlinedTextField}
                margin="none"
                fullWidth
                hiddenLabel
                value={adjustmentsToIncome}
                onChange={(event: any) => {
                  setAdjustmentsToIncome(event.target.value)
                  setAdjustmentsToIncomeYear(currentYear)
                }}
                onBlur={(event: any) => {
                  setTaxValue("adjustmentsToIncome", event.target.value)
                }}
                inputProps={{
                  sx: styles.outlineTextFieldInputProps
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" sx={styles.inputAdornment}>
                                    {adjustmentsToIncomeYear > 0 ? adjustmentsToIncomeYear : ""}
                                  </InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              <b>(11) Adjusted Gross Income (AGI)</b>
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              <b>{numberToMoneyFormat(taxEstimate.adjustedGrossIncome, 0)}</b>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              Tax Bracket
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToPercentFormat(taxEstimate.taxBracket, 0)}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              (12a) Standard Deduction
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxEstimate.standardDeduction, 0)}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              (12b) Itemized Deductions
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.fieldItem}>
              <TextFieldValidator
                type="number"
                validators={{ minValue: 0 }}
                name="itemizedDeductionsIn"
                variant="outlined"
                size="small"
                sx={styles.outlinedTextField}
                margin="none"
                fullWidth
                hiddenLabel
                value={itemizedDeductionsIn}
                onChange={(event: any) => {
                  setItemizedDeductionsIn(event.target.value)
                  setItemizedDeductionsInYear(currentYear)
                }}
                onBlur={(event: any) => {
                  setTaxValue("itemizedDeductionsIn", event.target.value)
                }}
                inputProps={{
                  sx: styles.outlineTextFieldInputProps,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" sx={styles.inputAdornment}>
                    {itemizedDeductionsInYear > 0 ? itemizedDeductionsInYear : ""}
                  </InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              (13) Any Other Qualifying Deductions to Income
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.fieldItem}>
              <TextFieldValidator
                type="number"
                validators={{ minValue: 0 }}
                name="otherQualifyingDeductionsToTaxableIncome"
                variant="outlined"
                size="small"
                sx={styles.outlinedTextField}
                margin="none"
                fullWidth
                hiddenLabel
                value={otherQualifyingDeductionsToTaxableIncome}
                onChange={(event: any) => {
                  setOtherQualifyingDeductionsToTaxableIncome(event.target.value)
                  setOtherQualifyingDeductionsToTaxableIncomeYear(currentYear)
                }}
                onBlur={(event: any) => {
                  setTaxValue("otherQualifyingDeductionsToTaxableIncome", event.target.value)
                }}
                inputProps={{
                  sx: styles.outlineTextFieldInputProps,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" sx={styles.inputAdornment}>
                    {otherQualifyingDeductionsToTaxableIncomeYear > 0 ? otherQualifyingDeductionsToTaxableIncomeYear : ""}
                  </InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              <b>(15) Taxable Income</b>
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              <b>{numberToMoneyFormat(taxEstimate.taxableIncome, 0)}</b>
            </Grid>
          </Grid>
        </Grid>
      </Section>
    )
  }
  const renderTaxSection = () => {
    const xp = expandLeftPanel

    return (
      <Section title="Tax"
               total={numberToMoneyFormat(taxEstimate.taxLiability, 0)}
               expanded={getExpanded(TaxSection.Tax)}
               onChange={(event: SyntheticEvent, value: boolean) => setExpanded(TaxSection.Tax, value)}
      >
        <Grid container direction="column" spacing={0}>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              <b>(16) Tax</b>
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              <b>{numberToMoneyFormat(taxEstimate.taxLiability, 0)}</b>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              (17) Additional Tax (Schedule 2, line 3)
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.additionalTax, 0)}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              (19) Nonrefundable Child Tax Credit or Credit for other Dependents (Schedule 8812)
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.fieldItem}>
              <TextFieldValidator
                type="number"
                validators={{ minValue: 0 }}
                name="childTaxCreditOrCreditForOtherDependents"
                variant="outlined"
                size="small"
                sx={styles.outlinedTextField}
                margin="none"
                fullWidth
                hiddenLabel
                value={childTaxCreditOrCreditForOtherDependents}
                onChange={(event: any) => {
                  setChildTaxCreditOrCreditForOtherDependents(event.target.value)
                  setChildTaxCreditOrCreditForOtherDependentsYear(currentYear)
                }}
                onBlur={(event: any) => {
                  setTaxValue("childTaxCreditOrCreditForOtherDependents", event.target.value)
                }}
                inputProps={{
                  sx: styles.outlineTextFieldInputProps,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" sx={styles.inputAdornment}>
                    {childTaxCreditOrCreditForOtherDependentsYear > 0 ? childTaxCreditOrCreditForOtherDependentsYear : ""}
                  </InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              (20) Nonrefundable Credits (Schedule 3, Line 8)
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.fieldItem}>
              <TextFieldValidator
                type="number"
                validators={{ minValue: 0 }}
                name="additionalCreditsAndPayments"
                variant="outlined"
                size="small"
                sx={styles.outlinedTextField}
                margin="none"
                fullWidth
                hiddenLabel
                value={additionalCreditsAndPayments}
                onChange={(event: any) => {
                  setAdditionalCreditsAndPayments(event.target.value)
                  setAdditionalCreditsAndPaymentsYear(currentYear)
                }}
                onBlur={(event: any) => {
                  setTaxValue("additionalCreditsAndPayments", event.target.value)
                }}
                inputProps={{
                  sx: styles.outlineTextFieldInputProps,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" sx={styles.inputAdornment}>
                    {additionalCreditsAndPaymentsYear < 0 ? additionalCreditsAndPaymentsYear : ""}
                  </InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              (23) Other Taxes, including self-employment tax
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.otherTaxes, 0)}
            </Grid>
          </Grid>
        </Grid>
      </Section>
    )
  }

  const renderPaymentsSection = () => {
    const xp = expandLeftPanel

    return (
      <Section title="Payments"
               total={numberToMoneyFormat(taxEstimate.totalPayments, 0)}
               expanded={getExpanded(TaxSection.Payments)}
               onChange={(event: SyntheticEvent, value: boolean) => setExpanded(TaxSection.Payments, value)}
      >
        <Grid container direction="column" spacing={0}>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              (25) Federal Income Tax Withheld
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.federalIncomeTaxWithheld, 0)}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              (27) Earned Income Credit
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.fieldItem}>
              <TextFieldValidator
                type="number"
                validators={{ minValue: 0 }}
                name="earnedIncomeCredit"
                variant="outlined"
                size="small"
                sx={styles.outlinedTextField}
                margin="none"
                fullWidth
                hiddenLabel
                value={earnedIncomeCredit}
                onChange={(event: any) => {
                  setEarnedIncomeCredit(event.target.value)
                  setEarnedIncomeCreditYear(currentYear)
                }}
                onBlur={(event: any) => {
                  setTaxValue("earnedIncomeCredit", event.target.value)
                }}
                inputProps={{
                  sx: styles.outlineTextFieldInputProps,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" sx={styles.inputAdornment}>
                    {earnedIncomeCreditYear > 0 ? earnedIncomeCreditYear : ""}
                  </InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              (28) Refundable child tax credit or additional child tax credit (Schedule 8812)
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.fieldItem}>
              <TextFieldValidator
                type="number"
                validators={{ minValue: 0 }}
                name="refundableAdditionalChildTaxCredits"
                variant="outlined"
                size="small"
                sx={styles.outlinedTextField}
                margin="none"
                fullWidth
                hiddenLabel
                value={refundableAdditionalChildTaxCredits}
                onChange={(event: any) => {
                  setRefundableAdditionalChildTaxCredits(event.target.value)
                  setRefundableAdditionalChildTaxCreditsYear(currentYear)
                }}
                onBlur={(event: any) => {
                  setTaxValue("refundableAdditionalChildTaxCredits", event.target.value)
                }}
                inputProps={{
                  sx: styles.outlineTextFieldInputProps,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" sx={styles.inputAdornment}>
                    {refundableAdditionalChildTaxCreditsYear ? refundableAdditionalChildTaxCreditsYear : ""}
                  </InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              (29-31) Other Payments and Refundable Credits
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.fieldItem}>
              <TextFieldValidator
                type="number"
                validators={{ minValue: 0 }}
                name="otherPaymentsAndRefundableCredits"
                variant="outlined"
                size="small"
                sx={styles.outlinedTextField}
                margin="none"
                fullWidth
                hiddenLabel
                value={otherPaymentsAndRefundableCredits}
                onChange={(event: any) => {
                  setOtherPaymentsAndRefundableCredits(event.target.value)
                  setOtherPaymentsAndRefundableCreditsYear(currentYear)
                }}
                onBlur={(event: any) => {
                  setTaxValue("otherPaymentsAndRefundableCredits", event.target.value)
                }}
                inputProps={{
                  sx: styles.outlineTextFieldInputProps,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" sx={styles.inputAdornment}>
                    {otherPaymentsAndRefundableCreditsYear > 0 ? otherPaymentsAndRefundableCreditsYear : ""}
                  </InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              <b>(33) Total Payments</b>
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              <b>{numberToMoneyFormat(taxEstimate.totalPayments, 0)}</b>
            </Grid>
          </Grid>
        </Grid>
      </Section>
    )
  }

  const renderFederalResultsSection = () => {
    const total = taxEstimate.amountOverpaid > 0 ? -taxEstimate.amountOverpaid : taxEstimate.amountOwed
    const xp = expandLeftPanel

    return (
      <Section title="Federal Results"
               total={numberToMoneyFormat(total, 0)}
               expanded={getExpanded(TaxSection.FederalResults)}
               onChange={(event: SyntheticEvent, value: boolean) => setExpanded(TaxSection.FederalResults, value)}
      >
        <Grid container direction="column" spacing={0}>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              {taxEstimate.amountOverpaid > 0 &&
                <b>(34) Refund - Amount You Overpaid (including tax credits)</b>
              }
              {taxEstimate.amountOverpaid <= 0 &&
                <span>(34) Refund - Amount You Overpaid (including tax credits)</span>
              }
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {taxEstimate.amountOverpaid > 0 &&
                <b>{numberToMoneyFormat(taxEstimate.amountOverpaid, 0)}</b>
              }
              {taxEstimate.amountOverpaid <= 0 &&
                <span>{numberToMoneyFormat(taxEstimate.amountOverpaid, 0)}</span>
              }
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              {taxEstimate.amountOwed > 0 &&
                <b>(37) Amount You Owe</b>
              }
              {taxEstimate.amountOwed <= 0 &&
                <span>(37) Amount You Owe</span>
              }
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {taxEstimate.amountOwed > 0 &&
                <b>{numberToMoneyFormat(taxEstimate.amountOwed, 0)}</b>
              }
              {taxEstimate.amountOwed <= 0 &&
                <span>{numberToMoneyFormat(taxEstimate.amountOwed, 0)}</span>
              }
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              Effective Federal Tax Rate
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToPercentFormat(taxEstimate.effectiveTaxRate, 2)}
            </Grid>
          </Grid>
        </Grid>
      </Section>
    )
  }

  const renderOtherTaxWithholdingSection = () => {
    const total = taxInput.socialSecurityTaxWithheld + taxInput.medicareTaxWithheld +
                  taxInput.stateIncomeTaxWithheld + taxInput.localIncomeTaxWithheld
    const xp = expandLeftPanel

    return (
      <Section title="Other Tax Withholding"
               total={numberToMoneyFormat(total, 0)}
               expanded={getExpanded(TaxSection.OtherTaxWithholding)}
               onChange={(event: SyntheticEvent, value: boolean) => setExpanded(TaxSection.OtherTaxWithholding, value)}
      >
        <Grid container direction="column" spacing={0}>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              Social Security Tax Withheld
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.socialSecurityTaxWithheld, 0)}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              Medicare Security Tax Withheld
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.medicareTaxWithheld, 0)}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              State Income Tax Withheld
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.stateIncomeTaxWithheld, 0)}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              Local Income Tax Withheld
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxInput.localIncomeTaxWithheld, 0)}
            </Grid>
          </Grid>
        </Grid>
      </Section>
    )
  }

  const renderTotalsSection = () => {
    const xp = expandLeftPanel

    return (
      <Section title="Totals"
               total={numberToMoneyFormat(taxEstimate.totalEstimatedTax, 0)}
               expanded={getExpanded(TaxSection.Totals)}
               onChange={(event: SyntheticEvent, value: boolean) => setExpanded(TaxSection.Totals, value)}
      >
        <Grid container direction="column" spacing={0}>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              (24) Total Federal Tax
            </Grid>
            <Grid item xs={4} sm={2} sx={styles.valueItem}>
              {numberToMoneyFormat(taxEstimate.totalTax, 0)}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              Total Other Tax Withholding
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToMoneyFormat(taxEstimate.totalOtherTaxWithholding, 0)}
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              <b>	Total Estimated Tax</b>
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              <b>{numberToMoneyFormat(taxEstimate.totalEstimatedTax, 0)}</b>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={xp?10:8} sx={styles.labelItem}>
              Effective Total Tax Rate
            </Grid>
            <Grid item xs={xp?2:4} sx={styles.valueItem}>
              {numberToPercentFormat(taxEstimate.effectiveTotalTaxRate, 2)}
            </Grid>
          </Grid>
        </Grid>
      </Section>
    )
  }

  const renderViewMenu = () => {
    const options = ["Lifetime Tax", "Taxable Income", "Taxable Income By Bracket", "Tax Tables"]

    return (
      <ViewMenu
        view={chartView}
        options={options}
        variant="auto"
        onChange={(index: number) => {
          Tracking.event({action: "Taxes View", label: options[index]})
          setChartView(index)
        }}
      />
    )
  }

  const renderTaxForm = () => {
    return (
      <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1, mt: 0, pr:"2px"}}>
        <Box sx={{display:"flex", mt:1, pl:0, pb:0, mb:1}}>
          <Typography variant="h2" color="primary">Federal Taxes</Typography>
        </Box>
        <Box sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "row",
          justifyContent: "stretch",
          paddingLeft: 1.5,
          paddingRight: 1.5,
          maxHeight: 50
        }}>
          <Box sx={{display: "flex", flexGrow: 0, flexDirection: "column"}}>
            <Box sx={{display: "flex", flexGrow: 0, justifyContent: "space-between", maxHeight: 16}}>
              <FormLabel sx={styles.formLabel}>Tax Year</FormLabel>
            </Box>
            <Box sx={{display: "flex", flexGrow: 0}}>
              {renderYearSelector()}
            </Box>
          </Box>
          <Box sx={{display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", paddingTop: 1}}>
            <Button color="primary" variant="outlined" size="small" sx={{height: 30}}
              onClick={() => setExpandLeftPanel(!expandLeftPanel)}
            >
              {expandLeftPanel ?
                <CloseFullscreenIcon style={{transform: "rotate(45deg)"}}/>
                :
                <ExpandIcon style={{transform: "rotate(90deg)"}}/>
              }
            </Button>
          </Box>
          <Box sx={{display: "flex", flexGrow: 0, flexDirection: "column", textAlign: "right"}}>
            <FormLabel sx={styles.formLabel}>Estimated Tax</FormLabel>
            {renderEstimatedTax()}
          </Box>
        </Box>

        <FormValidator id="taxForm">
          <TableContainer component={Paper}>
            {renderHouseholdSection()}
            {renderIncomeSection()}
            {renderTaxSection()}
            {renderPaymentsSection()}
            {renderFederalResultsSection()}
            {renderOtherTaxWithholdingSection()}
            {renderTotalsSection()}
          </TableContainer>
        </FormValidator>

      </Box>
    )
  }

  if (expandLeftPanel) {
    return (
      renderTaxForm()
    )
  } else {
    const leftPanelWidth = 360
    const rightPanelWidth = width - leftPanelWidth - 24

    return (
      <React.Fragment>
      <Box sx={{display:"flex", flexDirection:"row", mt:0, minHeight: "calc(100vh - 180px)"}}>
        <Box sx={{display:"flex", flexGrow:0, width: { xs:"100%", sm:leftPanelWidth},
          minWidth: { xs:"100%", sm:leftPanelWidth}, minHeight:"100%",
          overflowY:"scroll", flexDirection:"column"}}>
          {renderTaxForm()}
        </Box>
        <Box sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          marginTop: 1,
          width: rightPanelWidth,
          maxWidth: rightPanelWidth,
        }}>
          <Box display="flex" flexDirection="column" alignContent="left" pl={2} height={50}>
            <FormLabel sx={styles.formLabel}>View</FormLabel>
            {renderViewMenu()}
          </Box>
          <Box display="flex" flexDirection="column" pl={2} mb={0} mr={1}>
            {chartView === TaxesChartView.LifetimeEstTax && futureCalc &&
              <React.Fragment>
                <LifetimeEstimatedTaxesChart year={currentYear} futureCalc={futureCalc}
                                             onChange={(year: number) => setCurrentYear(year)}
                />
                <LifetimeTaxTable year={currentYear} futureCalc={futureCalc}
                                  onChange={(year: number) => setCurrentYear(year)}
                />
              </React.Fragment>
            }
            {chartView === TaxesChartView.TaxableIncomeEstTax && futureCalc &&
              <TaxableIncomeEstimateChart year={currentYear} futureCalc={futureCalc}
                                          onChange={(year: number) => setCurrentYear(year)}
              />
            }
            {chartView === TaxesChartView.TaxableIncomeByTaxBracket && futureCalc &&
              <TaxableIncomeByTaxBracketChart year={currentYear} futureCalc={futureCalc}
                                              onChange={(year: number) => setCurrentYear(year)}
              />
            }
            {chartView === TaxesChartView.TaxTables && taxInput && taxEstimate &&
              <TaxTables year={currentYear} taxInput={taxInput} taxEstimate={taxEstimate}/>
            }
          </Box>
        </Box>
      </Box>
      </React.Fragment>
    )
  }
}

export default TaxesView