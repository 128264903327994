import Person from "./Person";
import INetWorth from "./INetWorth";
import Model from "./Model";
import {isoToLocalDate} from "../stores/StoreUtilities";
import IModelItem from "./IModelItem";

class Liability implements IModelItem, INetWorth {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  userId: string
  modelId: string
  model?: Model
  description: string
  balance: number
  balanceDate: Date
  start: string
  end: string
  payoffDate: string
  interestRate: number
  ownerId: string
  owner?: Person
  sortOrder: number

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.userId = data.userId
    this.modelId = data.modelId
    this.model = data.model
    this.description = data.description ?? "Other"
    this.balance = data.balance ?? 0
    this.balanceDate = data.balanceDate ? isoToLocalDate(data.balanceDate) : isoToLocalDate(data.createdAt)
    this.start = data.start
    this.end = data.end
    this.payoffDate = data.payoffDate
    this.interestRate = data.interestRate
    this.ownerId = data.ownerId
    this.sortOrder = data.sortOrder
  }

  get startDate(): Date | undefined {
    return this.model?.getDate(this.start)
  }

  get endDate(): Date | undefined {
    return this.model?.getDate(this.end)
  }

  get endYear(): number {
    const date = this.endDate
    if (date) {
      return date.getFullYear()
    } else {
      return 0
    }
  }

}

export default Liability