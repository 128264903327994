import Asset, {AssetCategory} from "../../../model/Asset";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Paper,
  Typography,
  useTheme
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
  getISODateFromDate, moneyToNumberFormat, numberToMoneyFormat
} from "../../../stores/StoreUtilities";
import FormValidator from "../../../components/form/FormValidator";
import DatePickerValidator from "../../../components/form/DatePickerValidator";
import TextFieldValidator from "../../../components/form/TextFieldValidator";
import SnapshotIcon from '@mui/icons-material/CameraAlt';
import IconicButton from "../../../components/controls/IconicButton";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import DialogButton from "../../../components/form/DialogButton";
import Snapshot, {ISnapshotDetail, SnapshotDetail, SnapshotDetailTypename} from "../../../model/Snapshot";
import {format} from "date-fns";
import Liability from "../../../model/Liability";
import {CreateSnapshotInput, UpdateSnapshotInput} from "../../../API";
import {useResources} from "../../../stores/ResourceProvider";
import {LiabilityIcon, LiquidAssetIcon, OtherAssetIcon, PersonalAssetIcon, RealAssetIcon} from "../../../styles/Icons";
import INetWorth from "../../../model/INetWorth";
import IModelItem from "../../../model/IModelItem";

class SnapshotEditDetail {
  id: string
  typename: SnapshotDetailTypename
  balance: string

  constructor (item: ISnapshotDetail) {
    this.id = item.id
    this.typename = item.typename
    this.balance = numberToMoneyFormat(item.balance, 0)
  }
}

const SnapshotDialog = ({
  snapshot,
  liquidAssets,
  realAssets,
  personalAssets,
  otherAssets,
  liabilities,
  onCancel,
  onSave,
  onDelete
}: {
  snapshot: Snapshot
  liquidAssets: Asset[]
  realAssets: Asset[]
  personalAssets: Asset[]
  otherAssets: Asset[]
  liabilities: Liability[]

  onCancel?: () => any
  onSave?: (snapshot: Snapshot) => any
  onDelete?: (snapshot: Snapshot) => any
  disableDelete?: boolean
}) => {
  const [isNew, setIsNew] = useState<boolean>(false)
  const [canDelete, setCanDelete] = useState<boolean>(false)
  const [snapshotDate, setSnapshotDate] = useState<Date | undefined>()
  const [snapshotDescription, setSnapshotDescription] = useState<string>("")
  const [snapshotEditItems, setSnapshotEditItems] = useState<SnapshotEditDetail[]>([])

  const { modelStore, confirm, notify } = useResources()

  useEffect(() => {
    loadSnapshot(snapshot)
  }, [snapshot])

  const loadSnapshot = (snapshot: Snapshot) => {
    setIsNew(!snapshot.id)
    setCanDelete(!snapshot.id || snapshot.id !== "current")
    setSnapshotDate(snapshot.date)
    setSnapshotDescription(snapshot.description ?? "")
    const editItems = snapshot.details.map((item: ISnapshotDetail) => new SnapshotEditDetail(item))
    setSnapshotEditItems(editItems)
  }

  const theme = useTheme()

  const styles = {
    snapshotIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    },
    actions: {
      display:"flex",
      flexDirection:"row",
      width:"100%",
      justifyContent:"space-between",
      alignItems:"center"
    },
    itemBox: {
      display:"flex",
      flexGrow:1,
      alignItems:"center",
      width: "100%",
      maxHeight:40,
      backgroundColor: theme.palette.grey["50"],
      borderWidth: 1,
      borderColor: theme.palette.background.default,
      borderStyle: "solid",
      borderRadius: 0,
      padding: 0,
      marginBottom: 1
    },
    accountIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: "#40642F",
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    },
    realEstateIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: "#7f5539",
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    },
    personalPropertyIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: "#b08968",
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    },
    otherPropertyIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: "#ddb892",
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    },
    liabilityIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: "#881E10",
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      paddingTop: "4px",
      color: theme.palette.grey["700"]
    },
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
  }

  const handleSave = async () => {
    if (!snapshotDate) {
      return
    }
    const details = snapshotEditItems.map((item: SnapshotEditDetail) => {
      return new SnapshotDetail({id: item.id, typename: item.typename, balance: moneyToNumberFormat(item.balance, 0)})
    })
    let update
    // Verify there isn't already a snapshot for this date.
    const isoDate = getISODateFromDate(snapshotDate!)
    if (isNew || snapshot.date !== snapshotDate) {
      const found = await modelStore.getSnapshotByDate(snapshot.modelId, isoDate)
      if (found) {
        notify.show('error', `A snapshot already exists for ${format(snapshotDate, "MM/dd/yyyy")}`)
        return
      }
    }

    if (isNew) {
      const input: CreateSnapshotInput = {
        accountId: snapshot.accountId,
        userId: snapshot.userId,
        modelId: snapshot.modelId,
        date: isoDate,
        description: snapshotDescription,
        details: JSON.stringify(details)
      }

      update = await modelStore.createSnapshot(input)
    } else {
      const input: UpdateSnapshotInput = {
        id: snapshot.id,
        accountId: snapshot.accountId,
        userId: snapshot.userId,
        modelId: snapshot.modelId,
        date: getISODateFromDate(snapshotDate!),
        description: snapshotDescription,
        details: JSON.stringify(details)
      }

      update = await modelStore.updateSnapshot(input)
    }

    if (update && onSave) {
      onSave(update)
    }
  }

  const handleDelete = async () => {
    if (onDelete) {
      confirm.show('Confirm Delete', 'Are you sure you want to delete this snapshot?',
        ['Delete', 'Cancel'],
        async () => {
          try {
            const deleted = await modelStore.deleteSnapshot(snapshot.id)
            if (deleted) {
              onDelete(deleted)
              return true
            }
            return false
          }
          catch (err: any) {
            notify.show('error', err.message)
            return false
          }
        })
    }
  }

  const netWorthFilterFn = (a: INetWorth): boolean => {
    // Not currently in use
    if (!snapshotDate) {
      return false
    }
    const startDate = a.startDate
    const endDate = a.endDate
    return ((!startDate || (snapshotDate.getTime() >= startDate.getTime())) &&
            (!endDate   || (snapshotDate.getTime() <= endDate.getTime())))
  }

  const title = snapshot ? "Edit Snapshot" : "New Snapshot"

  const renderCategory = (title: string) => {
    return (
      <Box display="flex" flexGrow={1} justifyContent="space-between">
        <Box display="flex" flexGrow={1}>
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Box display="flex" flexGrow={0} width={130}>
          <FormLabel sx={styles.formLabel}>Balance</FormLabel>
        </Box>
      </Box>
    )
  }

  const renderItem = (item: INetWorth) => {
    let icon
    if (item instanceof Asset) {
      switch (item.assetCategory) {
        case AssetCategory.LiquidInvestableAssets:
          icon = <LiquidAssetIcon/>
          break
        case AssetCategory.RealEstateAndProperty:
          icon = <RealAssetIcon/>
          break
        case AssetCategory.PersonalProperty:
          icon = <PersonalAssetIcon/>
          break
        case AssetCategory.OtherAssets:
          icon = <OtherAssetIcon/>
          break
        default:
          icon = <LiquidAssetIcon/>
          break
      }
    } else {
      icon = <LiabilityIcon/>
    }

    const typename = item instanceof Asset ? "Asset" : item instanceof Liability ? "Liability" : ""
    let editIndex = snapshotEditItems.findIndex((detail: SnapshotEditDetail) => detail.id === item.id && detail.typename === typename)
    let balance = ""
    if (editIndex >= 0) {
      balance = snapshotEditItems[editIndex].balance
    } else if (item.startDate && (snapshotDate && snapshotDate.getTime() < item.startDate.getTime())) {
      // Don't display item if the snapshot date is before the item startDate
      return null
    }

    return (
      <Box display="flex" flexGrow={1} justifyContent="stretch" key={item.id}>
        <Box sx={styles.itemBox}>
          <Box display="flex" flexGrow={0} width={40}>
            {icon}
          </Box>
          <Box display="flex" flexGrow={1} pr={2}>
            <Typography variant="body1">{item.description}</Typography>
          </Box>
          <Box display="flex" flexGrow={0} width={130}>
            <TextFieldValidator
              margin="none"
              name={item.id}
              label=" "
              type="text"
              fullWidth
              variant="standard"
              required
              validators={{ required: false, isMoney: true }}
              value={balance}
              InputLabelProps={{shrink: false}}
              onChange={(event: any) => {
                if (editIndex < 0) {
                  const typename = (item instanceof Asset) ? "Asset" : "Liability"
                  const editItem = new SnapshotEditDetail({id: item.id, typename: typename, balance: 0})
                  snapshotEditItems.push(editItem)
                  editIndex = snapshotEditItems.length - 1
                }
                snapshotEditItems[editIndex].balance = event.currentTarget.value
                setSnapshotEditItems([...snapshotEditItems])
              }}
            />
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Dialog
      id="netWorthSnapshotDialog"
      open={true}
      onClose={handleCancel}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      aria-labelledby="netWorthSnapshotDialogTitle"
    >
      <Paper sx={{width:"100%"}}>
        <FormValidator onSubmit={handleSave} name="snapshotForm" id="snapshotForm">
          <DialogTitle id="netWorthSnapshotDialogTitle">
            {title}
          </DialogTitle>
          <DialogContent sx={{ my:1, p:1, maxHeight:"calc(100vh - 206px)", overflowY:"scroll"}}>
            <Box display="flex" flexGrow={1} justifyContent="stretch">
              <Box display="flex" flexGrow={1} width="100%">
                <Box display="flex" flexGrow={0} width={40} alignItems="center">
                  <SnapshotIcon style={styles.snapshotIcon}/>
                </Box>
                <Box display="flex" flexGrow={1} pr={2}>
                  <TextFieldValidator
                    margin="none"
                    name="snapshotDescription"
                    label="Description"
                    type="text"
                    fullWidth
                    variant="standard"
                    validators={{ required: false }}
                    value={snapshotDescription}
                    onChange={(event: any) => setSnapshotDescription(event.target.value)}
                  />
                </Box>
                <Box display="flex" flexGrow={0} width={130}>
                  <DatePickerValidator
                    name="snapshotDate"
                    label="Date"
                    value={snapshotDate}
                    format="MM-dd-yyyy"
                    variant="standard"
                    margin="dense"
                    required
                    disabled={!canDelete}
                    onChange={(newValue: any) => {
                      // TODO: Validation for valid dates
                      setSnapshotDate(newValue)
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexGrow={1} flexDirection="column">
              {renderCategory("Liquid Assets")}
              {liquidAssets.map((asset: Asset) => renderItem(asset))}
            </Box>
            <Box display="flex" flexGrow={1} flexDirection="column">
              {renderCategory("Real Estate")}
              {realAssets.map((asset: Asset) => renderItem(asset))}
            </Box>
            <Box display="flex" flexGrow={1} flexDirection="column">
              {renderCategory("Personal Property")}
              {personalAssets.map((asset: Asset) => renderItem(asset))}
            </Box>
            <Box display="flex" flexGrow={1} flexDirection="column">
              {renderCategory("Other Assets")}
              {otherAssets.map((asset: Asset) => renderItem(asset))}
            </Box>
            <Box display="flex" flexGrow={1} flexDirection="column">
              {renderCategory("Liabilities")}
              {liabilities.map((liability: Liability) => renderItem(liability))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Box mt={1} sx={styles.actions}>
              <Box>
                {onDelete && canDelete &&
                  <IconicButton onClick={handleDelete}>
                    <DeleteIcon/>
                  </IconicButton>
                }
              </Box>
              <Box display="flex" flexWrap="nowrap">
                <DialogButton variant="secondary" type="button" onClick={handleCancel}>
                  Cancel
                </DialogButton>
                <Box width={16}/>
                <DialogButton variant="primary" type="submit">
                  Save
                </DialogButton>
              </Box>
            </Box>
          </DialogActions>
        </FormValidator>
      </Paper>

    </Dialog>
  )

}

export default SnapshotDialog