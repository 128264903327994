import { API } from "aws-amplify"
import {getErrorMessage} from "../stores/StoreUtilities";
import Account from "../model/Account";
import Logger from "../components/Logger";
import Invoice from "../model/stripe/Invoice";
import User from "../model/User";

class BillingAPI {

  currentCustomer: any = undefined

  getProducts = async () => {
    try {
      const response = await API.get("BillingAPI", "/products", {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.getProducts error", message)
      throw new Error(message)
    }
  }

  getPrices = async () => {
    try {
      const response = await API.get("BillingAPI", "/prices", {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.getPrices error", message)
      throw new Error(message)
    }
  }

  getPlans = async () => {
    try {
      const response = await API.get("BillingAPI", "/plans", {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.getPlans error", message)
      throw new Error(message)
    }
  }

  getCoupons = async () => {
    try {
      const response = await API.get("BillingAPI", "/coupons", {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.getCoupons error", message)
      throw new Error(message)
    }
  }

  getCoupon = async (id: string) => {
    try {
      const response = await API.get("BillingAPI", `/coupons/${id}`, {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.getCoupon error", message)
      throw new Error(message)
    }
  }

  getCustomer = async (id: string) => {
    try {
      const response = await API.get("BillingAPI", `/customers/${id}`, {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.getCustomer error", message)
      throw new Error(message)
    }
  }

  getCurrentCustomer = async (id: string, refresh: boolean = true) => {
    if (!this.currentCustomer || this.currentCustomer['id'] !== id || refresh) {
      this.currentCustomer = await this.getCustomer(id)
    }
    return this.currentCustomer
  }

  createInvoice = async (user: User, account: Account, invoice: Invoice, source?: string) => {
    const init = {
      body: {
        user: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone
        },
        account: {
          id: account.id,
          name: account.name,
          address: account.address,
          city: account.city,
          state: account.state,
          postalCode: account.postalCode
        },
        invoice,
        source
      }
    }
    try {
      const response = await API.post("BillingAPI", "/invoices", init)
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.debug("BillingAPI.createInvoice error", message, init.body)
      throw new Error(message)
    }
  }

  deleteSource = async (customerId: string, sourceId: string) => {
    try {
      const response = await API.del("BillingAPI", `/sources/${customerId}/${sourceId}`, {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.deleteSource error", message)
      throw new Error(message)
    }

  }

  getCharges = async (customerId: string) => {
    try {
      const response = await API.get("BillingAPI", `/charges/${customerId}`, {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.getCharges error", message)
      throw new Error(message)
    }
  }

  createCheckoutSession = async (price: string, return_url: string, customer_email?: string)=> {
    const init = {
      body: {
        price: price,
        return_url: return_url,
        customer_email: customer_email
      }
    }

    try {
      const response = await API.post("BillingAPI", "/create-checkout-session", init)
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.debug("BillingAPI.createCheckoutSession error", message, init.body)
      throw new Error(message)
    }
  }

  getSession = async (sessionId: string) => {
    try {
      const response = await API.get("BillingAPI", `/session?session_id=${sessionId}`, {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.getSession error", message)
      throw new Error(message)
    }
  }

  createCustomerPortalSession = async (customer: string, return_url: string) => {
    const init = {
      body: {
        customer: customer,
        return_url: return_url
      }
    }

    try {
      const response = await API.post("BillingAPI", "/create-customer-portal-session", init)
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.debug("BillingAPI.createCustomerPortalSession error", message, init.body)
      throw new Error(message)
    }
  }

}

export default BillingAPI