import Page from '../../components/page/Page'
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  Typography,
  useTheme
} from '@mui/material';
import { observer } from 'mobx-react';
import React, {ReactElement, useEffect, useState} from 'react';
import { useResources } from '../../stores/ResourceProvider';
import {useLocation, useNavigate} from 'react-router-dom';
import OptimizerView from "./optimizer/OptimizerView";
import MarginRow from "../../components/page/MarginRow";
import BudgetView from "./budget/BudgetView";
import TimelineView from "./timeline/TimelineView";
import RoutesConfig, {RoutesConfigHelpers} from "../../RoutesConfig";
import AssetView from "./networth/NetWorthView";
import TaxesView from "./taxes/TaxesView";
import Model, {ModelSetting} from "../../model/Model";
import Account from "../../model/Account";
import {UpdateUserInput} from "../../API";
import NewModelDialog from "./NewModelDialog";
import EditModelDialog from "./EditModelDialog";
import User from "../../model/User";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SavingsIcon from '@mui/icons-material/Savings';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import InsightsIcon from '@mui/icons-material/Insights';
import TimelineIcon from "@mui/icons-material/Timeline";
import AvTimerIcon from '@mui/icons-material/AvTimer';
// import SettingsIcon from '@mui/icons-material/Settings';
import ModelMenu from "../../components/controls/ModelMenu";
import ClientMenu from "../../components/controls/ClientMenu";
import NavListItem from "../../components/controls/NavListItem";
import Plan from "../../model/Plan";
import PlanChangeList from "./optimizer/PlanChangeList";
import EditIcon from "@mui/icons-material/Edit";
import NewPlanDialog from "./NewPlanDialog";
import EditPlanDialog from "./EditPlanDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Drawer, {closedDrawerWidth, DrawerHeader, openDrawerWidth} from "../../components/mui/Drawer";
import DashboardView from "./dashboard/DashboardView";
import Footer from "../../components/page/Footer";
import {globalColors} from "../../styles/globalStyles";
import PremiumPreview, {PremiumFeature} from "../../components/page/PremiumPreview";
import WarningIcon from '@mui/icons-material/Warning';
import VideoPlayer from "../../components/video/VideoPlayer";
import IModelItem from "../../model/IModelItem";
import {isNumber} from "../../model/ModelUtilities";
// import EditModelSettingsDialog from "./EditModelSettingsDialog";

export interface IAdvisorSettings {
  clientId: string
  // clientModelId: string
  // clientPlanId: string
}
export const advisorSettingsKey = "AdvisorSettings"

export interface ICalcPageSettings {
  showIntro: boolean
}

interface ICalcPageProps {
  view?: string
  year?: string
}

const CalcPage = observer((props: ICalcPageProps) => {
  const { userStore, accountStore, modelStore, notify } = useResources()
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [account, setAccount] = useState<Account | undefined>()
  const [isFree, setIsFree] = useState<boolean>(false)
  const [isAdvisor, setIsAdvisor] = useState<boolean>(false)
  const [clientId, setClientId] = useState<string | undefined>()
  const [client, setClient] = useState<User | undefined>()
  const [models, setModels] = useState<Model[]>([])
  const [currentModelId, setCurrentModelId] = useState<string | undefined>()
  const [currentModel, setCurrentModel] = useState<Model | undefined>()
  const [currentUpdatedAt, setCurrentUpdatedAt] = useState<string>("")
  const [appliedModel, setAppliedModel] = useState<Model | undefined>()
  const [appliedUpdatedAt, setAppliedUpdatedAt] = useState<string>("")
  const [isTimelineComplete, setIsTimelineComplete] = useState<boolean>(true)
  const [plans, setPlans] = useState<Plan[]>([])
  const [currentPlanId, setCurrentPlanId] = useState<string | undefined>()
  const [currentPlan, setCurrentPlan] = useState<Plan | undefined>()
  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState<boolean>(true)
  const [navDrawerWidth, setNavDrawerWidth] = useState<number>(openDrawerWidth)
  const [leftMargin, setLeftMargin] = useState<number>()
  const [width, setWidth] = useState(window.innerWidth)
  const [contentWidth, setContentWidth] = useState<number>(0)
  const [expandedListItems, setExpandedListItems] = useState<Set<string>>(new Set<string>([]))
  const [view, setView] = useState<string | undefined>()
  const [year, setYear] = useState<number | undefined>()
  const [viewNames, setViewNames] = useState<string[]>([])
  const [showNewModelDialog, setShowNewModelDialog] = useState<boolean>(false)
  const [newBaseModel, setNewBaseModel] = useState<Model | undefined>()
  const [showEditModelDialog, setShowEditModelDialog] = useState<boolean>(false)
  const [editModel, setEditModel] = useState<Model | undefined>()
  const [showNewPlanDialog, setShowNewPlanDialog] = useState<boolean>(false)
  const [showEditPlanDialog, setShowEditPlanDialog] = useState<boolean>(false)
  const [showIntroDialog, setShowIntroDialog] = useState<boolean>(false)
  const [editPlan, setEditPlan] = useState<Plan | undefined>()
  const [showSettingsDialog, setShowSettingsDialog] = useState<boolean>(false)
  const [viewStorageKey, setViewStorageKey] = useState<string>("")

  useEffect(() => {
    const handleResize = () => {
      const innerWidth = window.innerWidth
      setWidth(innerWidth);
      // console.log(`setWidth(${innerWidth})`)
    };
    handleResize()

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  useEffect(() => {
    // console.log(`------------------------------`)
    // console.log(`isNavDrawerOpen = ${isNavDrawerOpen}`)
    // console.log(`width = ${width}`)
    if (isNavDrawerOpen) {
      setNavDrawerWidth(openDrawerWidth)
      // console.log(`setNavDrawerWidth(${openDrawerWidth})`)
      setLeftMargin(openDrawerWidth)
      // console.log(`setLeftMargin(${openDrawerWidth})`)
      setContentWidth(width - openDrawerWidth)
      // console.log(`setContentWidth(${width - openDrawerWidth})`)
    } else {
      setNavDrawerWidth(closedDrawerWidth)
      // console.log(`setNavDrawerWidth(${closedDrawerWidth})`)
      setLeftMargin(closedDrawerWidth + 16)
      // console.log(`setLeftMargin(${closedDrawerWidth + 16})`)
      setContentWidth(width - closedDrawerWidth - 16)
      // console.log(`setContentWidth(${width - closedDrawerWidth})`)
    }
  }, [width])

  useEffect(() => {
    const viewName = RoutesConfigHelpers.getPathSegment(location.pathname, 2)
    const year = RoutesConfigHelpers.getPathSegment(location.pathname, 3)

    setIsAdvisor(userStore.isAdvisorOrAdmin)
    setIsFree(userStore.isFree)
    const viewNames = ["dashboard", "timeline", "networth", "budget", "taxes", "optimizer"]
    setViewNames(viewNames)

    if (viewName && viewNames.indexOf(viewName) >= 0) {
      setView(viewName)
      if (year) {
        const yearNum = parseInt(year)
        if (isNumber(yearNum)) {
          setYear(yearNum)
        }
      }
    } else {
      navigate(`${RoutesConfig.calc.pathname}/dashboard`)
    }
    setAccount(accountStore.account)

    if (!userStore.isLoading && !modelStore.isLoading && !clientId) {
      let clientIdParam
      if (location) {
        const searchParams = new URLSearchParams(location.search)
        clientIdParam = searchParams.get("client") ?? undefined
        if (clientIdParam) {
          setClientId(clientIdParam)
        }
      }
      if (!clientIdParam) {
        if (clientId) {
          setClientId(clientId)
        } else if (userStore.isAdvisorOrAdmin) {
          const advisorSettings = getAdvisorSettings()
          if (advisorSettings && advisorSettings.clientId) {
            setClientId(advisorSettings.clientId)
            // setCurrentModelId(advisorSettings.clientModelId)
            // setCurrentPlanId(advisorSettings.clientPlanId)
          } else if (userStore.isLoading === false && userStore.user) {
            setClientId(userStore.user.id)
          }
        } else if (userStore.isLoading === false && userStore.user) {
          setClientId(userStore.user.id)
        }
      }

      const calcPageSettings = getCalcPageSettings()
      if (calcPageSettings && calcPageSettings.showIntro)
        setShowIntroDialog(true)
        saveCalcPageSettings({showIntro: false})
      }

  }, [navigate, userStore.isLoading, modelStore.isLoading, modelStore])

  useEffect(() => {
    if (clientId && !isLoading) {
      setIsLoading(true)
      loadClient(clientId, true)
    }
  }, [clientId])

  useEffect(() => {
    // if (currentModelId && (!currentModel || currentModel.id !== currentModelId)) {
    if (currentModelId) {
      loadModel(currentModelId, false, currentPlanId)
    }
  }, [currentModelId])

  useEffect(() =>{
    if (currentModel) {
      console.log(`CalcPage getAppliedModel`)
      modelStore.getAppliedModel(currentModel.id)
        .then((model?: Model) => {
          if (model) {
            console.log(`CalcPage setAppliedModel`)
            setAppliedModel(model)
            setAppliedUpdatedAt(model.updatedAt)
            if (model.appliedPlan) {
              setCurrentPlan(model.appliedPlan)
              setCurrentPlanId(model.appliedPlan.id)
            }
          }
        })
    }
  }, [currentModel?.updatedAt, currentPlan, currentUpdatedAt])

  useEffect(() => {
    if (userStore.isAdvisorOrAdmin && clientId) {
      saveAdvisorSettings()
    }
  }, [clientId])

  const styles = {
    drawerLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"],
      paddingLeft: theme.spacing(6)
    },
    checkedIcon: {
      height: 24,
      width: 24,
      color: theme.palette.action.active
    },
    uncheckedIcon: {
      color: theme.palette.action.disabled,
      height: 24,
      width: 24
    },
    secondary: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"]
    },
    listLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"],
      paddingLeft: theme.spacing(6)
    },
    drawerButton: {
      color:"inherit",
      p:0,
      mr:1,
      width: 24,
      height: 24,
      backgroundColor: `#dfdfee`,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    }
  }

  // console.log(`getting client: ${clientId}`)
  /*
  userStore.getUser(clientId)
    .then((user?: User) => {
      if (user) {
        setModels([])
        setPlans([])
        setCurrentModel(undefined)
        setCurrentPlan(undefined)
        setClient(user)
        navigate(`${RoutesConfig.calc.pathname}/${viewName ?? "dashboard"}`)
      }
    })
  */

  const getAdvisorSettings = () => {
    if (userStore.isAdvisorOrAdmin) {
      const data = window.localStorage.getItem(advisorSettingsKey)
      if (data) {
        const settings: IAdvisorSettings = JSON.parse(data)
        return settings
      }
    }
    return undefined
  }

  const saveAdvisorSettings = () => {
    if (userStore.isAdvisorOrAdmin && clientId) {
      const settings = JSON.stringify({
        clientId: clientId,
      })
      window.localStorage.setItem(advisorSettingsKey, settings)
    }
  }

  const getCalcPageSettings = () => {
    const data = window.localStorage.getItem(`${userStore.user!.id}.CalcPageSettings`)
    if (data) {
      const settings: ICalcPageSettings = JSON.parse(data)
      return settings
    }
    return undefined
  }

  const saveCalcPageSettings = (settings: ICalcPageSettings) => {
    const json = JSON.stringify(settings)
    window.localStorage.setItem(`${userStore.user!.id}.CalcPageSettings`, json)
  }

  const loadClient = async (clientId: string, forceLoad: boolean = false) => {
    console.debug(`CalcPage.loadClient(${clientId})`)
    const currentUser = modelStore.currentUser
    if (!currentUser || currentUser.id !== clientId || forceLoad) {
      let user: User | undefined
      if (currentUser && currentUser.id === clientId) {
        user = currentUser
      } else {
        user = await userStore.getUser(clientId)
      }
      if (user) {
        setClient(user)
        const models = await modelStore.loadUserModels(user)
        setModels(models)
        let model
        if (currentModelId) {
          model = models.find((m: Model) => m.id === currentModelId)
        }
        if (!model) {
          let modelId: string | undefined
          if (userStore.isAdvisorOrAdmin) {
            modelId = user.advisorModelId
            if (modelId) {
              model = models.find((m: Model) => m.id === modelId)
              if (!model) {
                modelId = undefined
              }
            }
            if (!modelId) {
              // Look for primary advisor model
              model = models.find((m: Model) => m.advisorCreated && m.name.startsWith("P"))
              if (!model) {
                // Look for latest advisor model
                model = models.filter((m: Model) => m.advisorCreated)
                  .reduce((latest: Model, current: Model) => (current.updatedAt > latest.updatedAt) ? current : latest, models[0])
              }
              if (model) {
                modelId = model.id
              }
            }
            if (!modelId) {
              // Default to first model
              modelId = models[0].id
            }
          } else {
            if (user.currentModelId) {
              model = models.find((m: Model) => m.id === user!.currentModelId)
              if (model) {
                modelId = user.currentModelId
              }
            }
            if (!modelId) {
              // Default to first model
              modelId = models[0].id
            }
          }

          setCurrentModelId(modelId)
        }
      } else {
        // Unable to get user so revert to current user
        if (userStore.user) {
          setClientId(userStore.user.id)
          setClient(userStore.user)
        }
      }
    }
    setIsLoading(false)
  }

  const loadModel = async (modelId: string, forceLoad: boolean = false, planId?: string) => {
    console.debug(`CalcPage.loadModel(${modelId}, ${forceLoad}, ${planId})`)
    const model = await modelStore.getCurrentModel(modelId, forceLoad)
    if (model) {
      setCurrentModel(model)
      setCurrentUpdatedAt(model.updatedAt)
      saveCurrentModel(model)
      if (model.isTimelineComplete) {
        setIsTimelineComplete(true)
      } else {
        setIsTimelineComplete(false)
        setView("timeline")
      }
      setPlans([...model.plans])
      let plan
      if (planId) {
        plan = model.plans.find((p: Plan) => p.id === planId)
        if (plan) {
          modelStore.setCurrentPlan(plan)
        }
      }
      if (!plan) {
        plan = await modelStore.getCurrentPlan()
      }
      setCurrentPlan(plan)
    }
    return model
  }

  const saveCurrentModel = async (model: Model) => {
      if (userStore.isAdvisorOrAdmin) {
        // Current Model Id is stored in User.settings
        if (client) {
          if (client.advisorModelId !== model.id) {
            const update: UpdateUserInput = {
              id: client.id,
              advisorModelId: model.id
            }
            await userStore.updateUser(update)
          }
        }
      } else {
        // Current Model Id is stored in user record
        if (client) {
          if (client.currentModelId !== model.id) {
            const update: UpdateUserInput = {
              id: client.id,
              currentModelId: model.id
            }
            await userStore.updateUser(update)
          }
        }
      }
  }

  const setExpandedListItem = (id: string, expand: boolean) => {
    if (expand) {
      expandedListItems.add(id)
    } else {
      expandedListItems.delete(id)
    }
    setExpandedListItems(new Set(expandedListItems))
  }

  const onViewChange = (view: string) => {
    if (isTimelineComplete || view === "timeline") {
      navigate(`${RoutesConfig.calc.pathname}/${view}`)
    }
  }

  const handleChangeClient = (user: User) => {
    if (!client || client.id !== user.id) {
      setClientId(user.id)
      setClient(undefined)
      setModels([])
      setCurrentModel(undefined)
      setCurrentModelId(undefined)
      setPlans([])
      setCurrentPlan(undefined)
      setCurrentPlanId(undefined)
      setAppliedModel(undefined)
      navigate(`${RoutesConfig.calc.pathname}/dashboard`)
    }
  }

  const handleChangeModel = async (model: Model) => {
    if (model.id === currentModelId) {
      // Reload
      loadModel(model.id, true, currentPlan?.id)
    } else if (model.id && userStore.user) {
      if (model.userId === userStore.user.id) {
        const input: UpdateUserInput = {
          id: userStore.user.id,
          currentModelId: model.id
        }
        await userStore.updateUser(input)
      }

      const foundIndex = models.findIndex((m: Model) => m.id === model.id)
      if (foundIndex >= 0) {
        models[foundIndex] = model
        setModels([...models])
      }

      setCurrentModelId(model.id)
      setCurrentModel(undefined)
      setCurrentPlan(undefined)
      setCurrentPlanId(undefined)
      setExpandedListItem("models", false)
    }
  }

  const handleChangePlan = async (plan: Plan) => {
    console.log(`handleChangePlan(${plan.name})`)
    if (plan.id && userStore.user) {
      // const input: UpdateUserInput = {
      //   id: userStore.user.id,
      //   currentPlanId: plan.id
      // }
      // const user = await userStore.updateUser(input)

      modelStore.setCurrentPlan(plan)
      setPlans([...currentModel!.plans])
      setCurrentPlan(plan)
      setExpandedListItem("optimizer", false)
    }
  }

  const handlePlanChange = (plan: Plan, committed?: Model) => {
    console.log(`handlePlanChange(${plan.name})`)
    modelStore.setCurrentPlan(plan)
    if (committed) {
      setCurrentModel(committed)
    } else {
      setPlans([...currentModel!.plans])
      setCurrentPlan(plan)
    }
  }

  const handleNewModel = async (baseModel?: Model) => {
    setNewBaseModel(baseModel)
    setShowNewModelDialog(true)
  }

  const handleEditModel = (model: Model) => {
    setEditModel(model)
    setShowEditModelDialog(true)
  }

  const handleDeleteModel = async (model: Model) => {
    try {
      await modelStore.deleteModel(model.id, true)
      if (client) {
        setModels(modelStore.models)
        // loadClient(client.id, true)
      }
    } catch (err: any) {
      notify.show("error", `Error deleting model (${err.message})`)
    }
  }

  const toggleDrawer = () => {
    if (isNavDrawerOpen) {
      setIsNavDrawerOpen(false)
      // console.log(`setIsNavDrawerOpen(false)`)
      setNavDrawerWidth(closedDrawerWidth)
      setLeftMargin(closedDrawerWidth + 16)
      setContentWidth(width - closedDrawerWidth)
    } else {
      setIsNavDrawerOpen(true)
      // console.log(`setIsNavDrawerOpen(true)`)
      setNavDrawerWidth(openDrawerWidth)
      setLeftMargin(openDrawerWidth)
      setContentWidth(width - openDrawerWidth)
    }
  }

  const renderDrawer = () => {
    let avatar: ReactElement | undefined = undefined
    if (client && isAdvisor && client) {
      const bgcolor = (client.id === userStore.user?.id) ? theme.palette.primary.main : theme.palette.action.active
      avatar = <Avatar sx={{bgcolor: bgcolor, fontSize:13, width:25, height:25}}>
                 {client.initials}
                </Avatar>
    }

    return (
      <Drawer variant="permanent" open={isNavDrawerOpen}
              PaperProps={{
                sx: {
                  backgroundColor: theme.palette.background.default,
                  marginTop: "62px",
                  borderStyle:"none"
                }
              }}
              // onMouseEnter={handleMouseEnterDrawer}
              // onMouseLeave={handleMouseLeaveDrawer}
      >
        <Divider />
        <Box sx={{display:"flex", justifyContent:"stretch", alignItems:"top",
          mt:3.5, pl:2, pb:0, mb:1}}>
          <Box sx={{display:"flex", flexGrow:0, width:closedDrawerWidth, p:0, m:0}}>
            <IconButton size="small" onClick={toggleDrawer} sx={styles.drawerButton}>
              {isNavDrawerOpen ?  <ChevronLeftIcon/> : <ChevronRightIcon/>}
            </IconButton>
          </Box>
          <Box sx={{display:"flex", flexGrow:1}}>
            <Typography variant="h5" color="primary">Retirement Navigator</Typography>
          </Box>
        </Box>
        <List dense>
          {client && isAdvisor &&
            <React.Fragment>
              {/*<FormLabel sx={styles.listLabel}>Client</FormLabel>*/}
              <NavListItem id="client"
                           icon={avatar ?? <ManageAccountsIcon/>}
                           title={`Client: ${client.fullName}`}
                           primary={client.fullName}
                           expanded={expandedListItems.has("clients")}
                           onClick={() => {
                             setExpandedListItem("clients", !expandedListItems.has("clients"))
                           }}
                           onChange={(expanded: boolean) => setExpandedListItem("clients", expanded)}
                           drawerOpen={true}
              >
                <ClientMenu color="primary" client={client}
                            onChangeClient={(client: User) => {
                              setExpandedListItem("clients", false)
                              handleChangeClient(client)
                            }}
                />
              </NavListItem>
            </React.Fragment>
          }
          {/*<FormLabel sx={styles.listLabel}>Model</FormLabel>*/}
          <NavListItem id="models"
                       icon={<AccountTreeIcon/>}
                       title={`Model: ${appliedModel?.name}`}
                       primary={<Typography variant="body2" sx={{whiteSpace:"normal", lineHeight:1.1}}>{appliedModel?.name}</Typography>}
                       divider={true}
                       drawerOpen={true}
                       expanded={expandedListItems.has("models")}
                       onClick={() => {
                         setExpandedListItem("models", !expandedListItems.has("models"))
                       }}
                       onChange={(expanded: boolean) => setExpandedListItem("models", expanded)}
          >
            {client && appliedModel && isNavDrawerOpen &&
              <Box display="flex" px={2} maxWidth={240} width="240px">
                <ModelMenu color="primary" value={appliedModel} options={models}
                           onChange={(option: Model) => handleChangeModel(option)}
                           onNew={(option?: Model) => handleNewModel(option)}
                           onEdit={(option: Model) => handleEditModel(option)}
                           onDelete={(option: Model) => handleDeleteModel(option)}
                />
              </Box>
            }
          </NavListItem>

          <NavListItem id="dashboard"
                       icon={<DashboardIcon/>}
                       title="Dashboard"
                       primary="Dashboard"
                       selected={view === "dashboard"}
                       onClick={onViewChange}
                       drawerOpen={true}
          />

          <NavListItem id="timeline"
                       icon={<AvTimerIcon/>}
                       title="Timeline"
                       primary={isTimelineComplete ? "Timeline" :
                         <Box display="inline-flex">
                           <Typography variant="body2" sx={{color: theme.palette.action.selected, fontWeight:700}}>Timeline </Typography>
                           <WarningIcon sx={{color: globalColors.warning, height:18, marginLeft:"4px"}}/>
                         </Box>
                       }
                       selected={view === "timeline"}
                       onClick={onViewChange}
                       drawerOpen={true}
          />

          <NavListItem id="networth"
                       icon={<SavingsIcon/>}
                       title="Assets & Liabilities"
                       primary="Assets & Liabilities"
                       selected={view === "networth"}
                       onClick={onViewChange}
                       drawerOpen={true}
                       expanded={false}
          >
            {/*<NavListItem name="liquidAssets"*/}
            {/*             primary="Liquid Assets"*/}
            {/*             onClick={() => onNavChange('networth')}*/}
            {/*             drawerOpen={true}*/}
            {/*/>*/}
            {/*<NavListItem name="assetConversions"*/}
            {/*             primary="Asset Conversions"*/}
            {/*             onClick={() => onNavChange('networth')}*/}
            {/*             drawerOpen={true}*/}
            {/*/>*/}
            {/*<NavListItem name="realEstate"*/}
            {/*             primary="Real Estate"*/}
            {/*             onClick={() => onNavChange('networth')}*/}
            {/*             drawerOpen={true}*/}
            {/*/>*/}
            {/*<NavListItem name="personalProperty"*/}
            {/*             primary="Personal Property"*/}
            {/*             onClick={() => onNavChange('networth')}*/}
            {/*             drawerOpen={true}*/}
            {/*/>*/}
            {/*<NavListItem name="otherAssets"*/}
            {/*             primary="Other Assets"*/}
            {/*             onClick={() => onNavChange('networth')}*/}
            {/*             drawerOpen={true}*/}
            {/*/>*/}
            {/*<NavListItem name="liabilites"*/}
            {/*             primary="Liabilities"*/}
            {/*             onClick={() => onNavChange('networth')}*/}
            {/*             drawerOpen={true}*/}
            {/*/>*/}
          </NavListItem>

          <NavListItem id="budget"
                       icon={<MonetizationOnIcon/>}
                       title="Budget"
                       primary="Budget"
                       selected={view === "budget"}
                       onClick={onViewChange}
                       drawerOpen={true}
                       expanded={false}
          >
          </NavListItem>

          <NavListItem id="taxes"
                       icon={<AccountBalanceIcon/>}
                       title="Taxes"
                       primary="Taxes"
                       primaryColor={isFree ? globalColors.premium : undefined}
                       selected={view === "taxes"}
                       onClick={onViewChange}
                       drawerOpen={true}
          />

          <NavListItem id="optimizer"
                       icon={<InsightsIcon/>}
                       title="Retirement Optimizer"
                       primary="Retirement Optimizer"
                       primaryColor={isFree ? globalColors.premium : undefined}
                       selected={view === "optimizer"}
                       onClick={onViewChange}
                       drawerOpen={true}
          />

          {/* TODO */}
          {/*<NavListItem id="settings"*/}
          {/*             icon={<SettingsIcon/>}*/}
          {/*             title="Settings"*/}
          {/*             primary="Settings"*/}
          {/*             selected={showSettingsDialog}*/}
          {/*             onClick={() => setShowSettingsDialog(true)}*/}
          {/*             drawerOpen={true}*/}
          {/*             expanded={false}*/}
          {/*>*/}
          {/*</NavListItem>*/}

          {!isFree &&
            <Box sx={{display:"flex", justifyContent:"stretch", mb:1, ml:2, pt: 2, pb:0}}>
              <Box sx={{display:"flex", flexGrow:0, width:closedDrawerWidth, minWidth:closedDrawerWidth, p:0, mr:0}}>

              </Box>
              <Box sx={{display:"flex", flexGrow:1}}>
                <Typography variant="h5" color={isFree ? globalColors.premium : "primary"}>
                  Scenario
                </Typography>
              </Box>
            </Box>
        }

          {!isFree &&
              <NavListItem id="optimizer"
                           icon={<TimelineIcon/>}
                           primary={currentPlan?.name}
                           title={`Optimizer: ${currentPlan?.name}`}
                           divider={true}
                           selected={view === "optimizer"}
                           onClick={onViewChange}
                           drawerOpen={true}
                           expanded={expandedListItems.has("optimizer")}
                           onChange={(expanded: boolean) => setExpandedListItem("optimizer", expanded)}
              >
                {isNavDrawerOpen && <Box display="flex" width="100%" flexDirection="column">
                {plans!.map((plan: Plan) =>
                  <NavListItem id={plan.id}
                               icon={
                                 plan.id === currentPlan?.id ? <VisibilityIcon sx={styles.checkedIcon}/> :
                                   <VisibilityOffIcon sx={styles.uncheckedIcon}/>
                               }
                               title={plan.name}
                               primary={plan.name}
                               secondaryAction={plan.default ? undefined :
                                 <IconButton size="small"
                                             onClick={(event: any) => {
                                               setEditPlan(plan)
                                               setEditModel(appliedModel)
                                               setShowEditPlanDialog(true)
                                             }}
                                 >
                                   <EditIcon sx={{width:18, height:18}}/>
                                 </IconButton>
                               }
                               onClick={(id: string) => handleChangePlan(plan)}
                               drawerOpen={true}
                  />
                )}
                <NavListItem id="new-plan"
                             icon={<AddCircleIcon sx={{color: theme.palette.secondary.light, paddingRight:"2px"}}/>}
                             primary="New Scenario"
                             onClick={(id: string) => setShowNewPlanDialog(true)}
                             drawerOpen={true}
                />
                </Box> }
              </NavListItem>
          }
        </List>
        {!isFree && appliedModel && currentPlan &&
          <PlanChangeList model={appliedModel} plan={currentPlan} updatedAt={appliedUpdatedAt} drawerOpen={isNavDrawerOpen}
                          onChange={handlePlanChange}/>
        }
      </Drawer>
    )
  }

  const getPageTitle = (view?: string) => {
    let viewName = ""

    switch (view) {
      case "dashboard":
        viewName = "Dashboard"
        break
      case "timeline":
        viewName = "Timeline"
        break
      case "networth":
        viewName = "Net Worth"
        break
      case "budget":
        viewName = "Budget"
        break
      case "taxes":
        viewName = "Taxes"
        break
      case "optimizer":
        viewName = "Optimizer"
    }
    return `Calculator ${viewName}`
  }

  return (
    <Page title={getPageTitle(view)} backgroundColor="paper">
      <MarginRow>
        {renderDrawer()}

        <Box component="main" sx={{ flexGrow:1, ml:`${leftMargin}px`, minHeight: "calc(100vh - 206px)"}}>
          {view === 'dashboard' && appliedModel && currentPlan &&
            <DashboardView model={appliedModel} plan={currentPlan} updatedAt={appliedUpdatedAt} width={contentWidth}/>
          }
          {view === 'timeline' && appliedModel && currentPlan &&
            <TimelineView model={appliedModel} plan={currentPlan} updatedAt={appliedUpdatedAt} onChange={handleChangeModel}/>
          }
          {view === 'networth' && appliedModel && currentPlan &&
            <AssetView model={appliedModel} plan={currentPlan} updatedAt={appliedUpdatedAt}
                       width={isNavDrawerOpen ? contentWidth - 8 : contentWidth - 8}
                       onChange={(item: IModelItem) => setCurrentUpdatedAt(item.updatedAt)}
            />
          }
          {view === 'budget' && appliedModel && currentPlan &&
            <BudgetView model={appliedModel} updatedAt={appliedUpdatedAt}
                        width={isNavDrawerOpen ? contentWidth : contentWidth - 16}
                        onChange={(item: IModelItem) => setCurrentUpdatedAt(item.updatedAt)}
            />
          }
          {view === 'taxes' && appliedModel && currentPlan && !isFree &&
            <TaxesView model={appliedModel} plan={currentPlan} year={year} updatedAt={appliedUpdatedAt}
                       width={isNavDrawerOpen ? contentWidth : contentWidth - 16}
                       onChange={(item: IModelItem) => setCurrentUpdatedAt(item.updatedAt)}
            />
          }
          {view === 'taxes' && isFree &&
            <PremiumPreview feature={PremiumFeature.TaxEstimator}/>
          }
          {view === 'optimizer' && appliedModel && currentPlan && !isFree &&
            <OptimizerView model={appliedModel} plan={currentPlan} updatedAt={appliedUpdatedAt} width={contentWidth}/>
          }
          {view === 'optimizer' && isFree &&
            <PremiumPreview feature={PremiumFeature.Optimizer}/>
          }
        </Box>
        <Footer />
      </MarginRow>
      {showNewModelDialog &&
        <NewModelDialog baseModel={currentModel}
                        onClose={() => setShowNewModelDialog(false)}
                        onCreate={(model: Model) => {
                          setShowNewModelDialog(false)
                          handleChangeModel(model)
                        }}
        />
      }
      {showEditModelDialog && editModel &&
        <EditModelDialog model={editModel}
                         onClose={() => setShowEditModelDialog(false)}
                         onSave={(model: Model) => {
                             setShowEditModelDialog(false)
                             const foundIndex = models.findIndex((m: Model) => m.id === model.id)
                             if (foundIndex >= 0) {
                               models[foundIndex] = model
                               models.sort((a: Model, b: Model) => a.name.localeCompare(b.name))
                               setModels([...models])
                             }
                           }}
                         onDelete={currentModel && editModel.id !== currentModel.id && models.length > 1 ? (model: Model) => {
                           setShowEditModelDialog(false)
                           handleDeleteModel(model)
                         } : undefined}
        />
      }

      {showNewPlanDialog &&
        <NewPlanDialog basePlan={currentPlan} onClose={() => setShowNewPlanDialog(false)}
                       onCreate={(model: Model, plan: Plan) => {
                         setShowNewPlanDialog(false)
                         setPlans(model.plans)
                         handleChangePlan(plan)
                       }}
        />
      }
      {showEditPlanDialog && editPlan && editModel &&
        <EditPlanDialog plan={editPlan} model={editModel}
                        onClose={() => setShowEditPlanDialog(false)}
                        onSave={(plan: Plan, committed: Model) => {
                          setShowEditPlanDialog(false)
                          if (committed) {
                            setCurrentModelId(committed.id)
                            // Force reload
                            setCurrentModel(undefined)
                          } else {
                            handlePlanChange(plan)
                            // setCurrentPlan(plan)
                            // setCurrentUpdatedAt(plan.updatedAt)
                          }
                        }}
                        onDelete={(plan: Plan) => {
                          setShowEditPlanDialog(false)
                          if (currentModel) {
                            setPlans([...currentModel.plans])
                            if (currentPlan && plan.id === currentPlan.id) {
                              handlePlanChange(currentModel.plans[0])
                            }
                          }
                        }}
        />
      }

      {showIntroDialog &&
        <VideoPlayer url="https://www.youtube.com/embed/WMHHFvXCDDg?si=h7QUMNtfCX0ag_M4"
                     open={showIntroDialog}
                     onClose={() => setShowIntroDialog(false)}
        />
      }

      {/* TODO */}
      {/*{showSettingsDialog && currentModel &&*/}
      {/*  <EditModelSettingsDialog model={currentModel}*/}
      {/*                           onClose={() => setShowSettingsDialog(false)}*/}
      {/*                           onSave={(model: Model) => {setShowSettingsDialog(false)*/}
      {/*                             setCurrentModel(model)*/}
      {/*                             setCurrentUpdatedAt(model.updatedAt)*/}
      {/*                           }}*/}
      {/*   />*/}
      {/*}*/}

    </Page>
  )
})

export default CalcPage
