import React, {ReactNode} from "react";
import {Grid, IconButton, Link, Typography, useTheme} from "@mui/material";

const IconicButton = ({
  onClick,
  disabled,
  label,
  color,
  labelColor,
  labelPosition,
  children

}: {
  style?: any
  onClick?: any
  disabled?: boolean
  label?: string | ReactNode
  color?: 'inherit' | 'primary' | 'secondary'
  labelColor?: 'textPrimary' | 'textSecondary' | 'inherit'
  labelPosition?: 'left' | 'right'
  children?: ReactNode
}) => {
  const theme = useTheme()

  // TODO: Styles need work

  const styles = {
    iconButton: {
      padding: 0,
      paddingTop: "2px",
      color: theme.palette.primary.light,
      // boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
      // backgroundColor: theme.palette.primary.light,
      // "&:hover": {
      //   backgroundColor: alpha(theme.palette.primary.light, 0.7),
      // },
      // "&:disabled": {
      //   backgroundColor: alpha(theme.palette.primary.light, 0.1),
      // }
    },
  }

  const renderButton = () => {
    // Handle custom styles
    return (
      <IconButton
        aria-label="Button"
        sx={styles.iconButton}
        color={ color ? color : 'primary' }
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </IconButton>
    )
  }

  const renderButtonWithLabel = () => {
    let useLabel
    if (typeof label === "string") {
      useLabel = <Typography variant="body2" color={ labelColor ? labelColor : 'primary' } sx={{paddingTop:"2px"}} noWrap={true}>
        {label}
      </Typography>
    } else {
      useLabel = label
    }

    return (
      <Grid container direction="row" justifyContent={labelPosition === "right" ? "flex-start" : "flex-end"} alignItems="center" spacing={0.5} sx={{paddingTop:0, paddingLeft:"0px !important", marginRight: "0px !important"}}>
        {(!labelPosition || labelPosition==='right') &&
          <Grid item sx={{paddingLeft:"0px !important", paddingRight:"0px !important"}}>
            {renderButton()}
          </Grid>
        }
        <Grid item>
          <Link component="button" onClick={onClick} sx={{textDecoration:"none"}}>
            {useLabel}
          </Link>
        </Grid>
        {labelPosition && labelPosition==='left' &&
          <Grid item sx={{paddingTop:"0px !important"}}>
            {renderButton()}
          </Grid>
        }
      </Grid>
    )
  }

  if (label) {
    return renderButtonWithLabel()
  } else {
    return renderButton()
  }

}

export default IconicButton