import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { RouteComponentProps } from '@reach/router'
import DialogButton from '../form/DialogButton'
import {inject, observer} from "mobx-react";
import Confirm from "./Confirm";

interface IConfirmDialog {
  confirm?: Confirm
}

@inject("confirm")
@observer
class ConfirmDialog extends React.Component<RouteComponentProps & IConfirmDialog> {

  onCancel = () => {
    if (this.props.confirm) {
      this.props.confirm.cancel();
    }
  };

  onSubmit = (event: any) => {
    if (this.props.confirm) {
      this.props.confirm.confirm()
    }
  }

  render() {
    const { confirm } = this.props

    const title = (confirm && confirm.title) ? confirm.title : "Confirm"
    let content
    if (confirm) {
      if (typeof confirm.content === "string") {
        content = <DialogContentText color="black" pt={2}>
          {confirm.content}
        </DialogContentText>
      } else {
        content = confirm.content
      }
    }
    const confirmLabel = (confirm && confirm.buttons && confirm.buttons[0]) ? confirm.buttons[0] : "Confirm"
    const cancelLabel = (confirm && confirm.buttons && confirm.buttons[1]) ? confirm.buttons[1] : "Cancel"

    let dialog = null
    if (confirm && confirm.open) {
      dialog = <Dialog
        id="confirmDialog"
        open={confirm.open}
        onClose={this.onCancel}
        scroll="paper"
        maxWidth="xs"
        fullWidth
        aria-labelledby="confirm-title">
        <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-evenly",
            paddingBottom: "16px"
          }}
        >
          <DialogButton variant="primary" onClick={this.onSubmit}>
            {confirmLabel}
          </DialogButton>
          {(!confirm.buttons || confirm.buttons[1]) &&
            <DialogButton variant="secondary" onClick={this.onCancel}>
              {cancelLabel}
            </DialogButton>
          }
        </DialogActions>
      </Dialog>
    }

    return (dialog)
  }
}

export default ConfirmDialog
