import Model from "./Model";
import ModelStore from "../stores/ModelStore";

export enum PlanChangeType {
  ConversionStrategy = "ConversionStrategy",
  ExpenseStrategy = "ExpenseStrategy",
  GrowthStrategy = "GrowthStrategy",
  InflationStrategy = "InflationStrategy",
  SocialSecurityStrategy = "SocialSecurityStrategy",
  TimelineStrategy = "TimelineStrategy",
  TaxStrategy = "TaxStrategy",
  WithdrawalStrategy = "WithdrawalStrategy",
}

class PlanChange {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  userId: string
  modelId: string
  planId: string
  changeType: string
  name: string
  description: string
  enabled: boolean
  details: string

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.userId = data.userId
    this.modelId = data.modelId
    this.planId = data.planId
    if (data.changeType === "RothStrategy") {
      this.changeType = "ConversionStrategy"
    } else {
      this.changeType = data.changeType
    }
    this.name = data.name
    this.description = data.description
    this.enabled = data.enabled === true
    this.details = data.details
  }

  apply(model: Model) {

  }

  async commit(model: Model, modelStore: ModelStore) {

  }
}

export default PlanChange