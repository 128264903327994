import * as React from "react";
import {globalColors} from "./globalStyles"
import AccountBalance from '@mui/icons-material/AccountBalance';
import MapsHomeWork from '@mui/icons-material/MapsHomeWork';
import Chair from '@mui/icons-material/Chair';
import DirectionsCar from '@mui/icons-material/DirectionsCar';
import LocalAtm from '@mui/icons-material/LocalAtm';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import SavingsIcon from '@mui/icons-material/Savings';
import HouseIcon from '@mui/icons-material/House';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import CommuteIcon from '@mui/icons-material/Commute';
import PublicIcon from '@mui/icons-material/Public';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import CalculateIcon from '@mui/icons-material/Calculate';
import {ExpenseCategory} from "../model/Expense";
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import MuiEventIcon from '@mui/icons-material/Event';
import TimelineIcon from '@mui/icons-material/AvTimer';
import MuiSecurityIcon from '@mui/icons-material/Security';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import DirectionsIcon from '@mui/icons-material/Directions';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import LockIcon from '@mui/icons-material/Lock';
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PaymentsIcon from '@mui/icons-material/Payments';

export const FoldIcon = () => {
  return (
    <UnfoldLessIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.fold,
      height: 20,
      padding: 0,
      borderRadius: "5px"
    }}/>
  )
}

export const UnfoldIcon = () => {
  return (
    <UnfoldMoreIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.unfold,
      height: 20,
      padding: 0,
      borderRadius: "5px"
    }}/>
  )
}

export const UserIcon = () => {
  return (
    <AccountCircleIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.user,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const SecurityIcon = () => {
  return (
    <LockIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.security,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const SubscriptionIcon = () => {
  return (
    <PaymentsIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.subscription,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}


export const MaleIcon = () => {
  return (
    <PersonIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.maleGender,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const FemaleIcon = () => {
  return (
    <PersonIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.femaleGender,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const LiquidAssetIcon = () => {
  return (
    <AccountBalance sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.liquidAsset,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const RealAssetIcon = () => {
  return (
    <MapsHomeWork sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.realAsset,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const PersonalAssetIcon = () => {
  return (
    <Chair sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.personalAsset,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const OtherAssetIcon = () => {
  return (
    <DirectionsCar sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.otherAsset,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const AssetConversionIcon = () => {
  return (
    <CurrencyExchangeIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.conversionOptimizer,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const LiabilityIcon = () => {
  return (
    <LocalAtm sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.liability,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const IncomeIcon = () => {
  return (
    <AttachMoneyIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.income,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const DistributionIcon = () => {
  return (
    <AccountBalanceIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.withdrawal,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const DeductionExpenseIcon = () => {
  return (
    <SavingsIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.deductionExpense,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const TaxExpenseIcon = () => {
  return (
    <AssuredWorkloadIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.taxExpense,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const EstimatedTaxIcon = () => {
  return (
    <AssuredWorkloadIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.estimatedTax,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const EssentialExpenseIcon = () => {
  return (
    <AttachMoneyIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.essentialExpense,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const DiscretionaryExpenseIcon = () => {
  return (
    <AttachMoneyIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.discretionaryExpense,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const HousingExpenseIcon = ({
  backgroundColor
}: {
  backgroundColor?: string
}) => {
  return (
    <HouseIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: backgroundColor ?? globalColors.expenseCategory[ExpenseCategory.Housing],
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const LoanExpenseIcon = ({
  backgroundColor
}: {
  backgroundColor?: string
}) => {
  return (
    <RequestQuoteIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: backgroundColor ?? globalColors.expenseCategory[ExpenseCategory.LoansAndLiabilities],
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const FoodAndPersonalExpenseIcon = ({
  backgroundColor
}: {
  backgroundColor?: string
}) => {
  return (
    <ShoppingCartIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: backgroundColor ?? globalColors.expenseCategory[ExpenseCategory.FoodAndPersonalCare],
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const InsuranceAndMedicalExpenseIcon = ({
  backgroundColor
}: {
  backgroundColor?: string
}) => {
  return (
    <HealthAndSafetyIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: backgroundColor ?? globalColors.expenseCategory[ExpenseCategory.InsuranceAndMedical],
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const VehiclesAndTransporationExpenseIcon = ({
  backgroundColor
}: {
  backgroundColor?: string
}) => {
  return (
    <CommuteIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: backgroundColor ?? globalColors.expenseCategory[ExpenseCategory.VehiclesAndTransportation],
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const TravelAndEntertainmentExpenseIcon = ({
  backgroundColor
}: {
  backgroundColor?: string
}) => {
  return (
    <PublicIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: backgroundColor ?? globalColors.expenseCategory[ExpenseCategory.TravelAndEntertainment],
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const GivingAndMiscExpenseIcon = ({
  backgroundColor
}: {
  backgroundColor?: string
}) => {
  return (
    <VolunteerActivismIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: backgroundColor ?? globalColors.expenseCategory[ExpenseCategory.GivingAndMisc],
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const NetIncomeIcon = ({
  backgroundColor
}: {
  backgroundColor?: string
}) => {
  return (
    <CalculateIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: backgroundColor ?? globalColors.income,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const PlanIcon = () => {
  return (
    <DirectionsIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.plan,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const EventIcon = ({
  backgroundColor
}: {
  backgroundColor?: string
}) => {
  return (
    <MuiEventIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: backgroundColor ?? globalColors.event,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const WithdrawalIcon = ({
  backgroundColor
}: {
  backgroundColor?: string
}) => {
  return (
    <ViewTimelineIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: backgroundColor ?? globalColors.withdrawalOrder,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const SocialSecurityChangeIcon = () => {
  return (
    <MuiSecurityIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.planChange,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const TaxOptimizerIcon = () => {
  return (
    <AssuredWorkloadIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.taxOptimizer,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const LongTermCareAnalyzerIcon = () => {
  return (
    <WheelchairPickupIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.longTermCareAnalyzer,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const LongevityAnalyzerIcon = () => {
  return (
    <BedtimeIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.longevityAnalyzer,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const EconomyAnalyzerIcon = () => {
  return (
    <TrendingDownIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.economyAnalyzer,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const RothOptimizerIcon = () => {
  return (
    <CurrencyExchangeIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.conversionOptimizer,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

export const GiftingOptimizerIcon = () => {
  return (
    <VolunteerActivismIcon sx={{
      color: globalColors.iconContrast,
      backgroundColor: globalColors.giftingOptimizer,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }}/>
  )
}

