import {roundMoney} from "../../stores/StoreUtilities";

export enum TaxFilingStatus {
  Single = 1,
  MarriedFilingJointly,
  // MarriedFilingSeparately, // Unsupported
  // HeadOfHousehold,         // Unsupported
  // QualifyingWidower        // Unsupported
}

export class TaxInput {
  filingStatus: TaxFilingStatus
  taxYear: number
  livedWithSpouse: boolean     // This is not currently used
  numberOfFilersOver65: number
  numberOfQualifiedChildDependents: number
  numberOfQualifiedOtherDependents: number
  income: {
    wagesSalariesTips: number
    taxExemptInterest: number
    taxableInterest: number
    qualifiedDividends: number
    unqualifiedDividends: number
    ordinaryDividends: number
    totalIraDistributions: number
    taxableIraDistributions: number
    totalPensionsAndAnnuities: number
    nonTaxablePensionAndAnnuities: number
    taxablePensionAndAnnuities: number
    totalSocialSecurityBenefits: number
    capitalGainOrLoss: number
    qualifiedCapitalGains: number
    unqualifiedCapitalGains: number
    otherIncome: number
    adjustmentsToIncome: number
  }
  itemizedDeductionsIn: number
  otherQualifyingDeductionsToTaxableIncome: number
  additionalTax: number
  childTaxCreditOrCreditForOtherDependents: number
  additionalCreditsAndPayments: number
  otherTaxes: number
  federalIncomeTaxWithheld: number
  earnedIncomeCredit: number
  refundableAdditionalChildTaxCredits: number
  otherPaymentsAndRefundableCredits: number
  // previouslyPostponedIRAdistributionTaxes: number
  socialSecurityTaxWithheld: number
  medicareTaxWithheld: number
  stateIncomeTaxWithheld: number
  localIncomeTaxWithheld: number
  inflationRate: number

  constructor() {
    this.filingStatus = TaxFilingStatus.Single
    this.taxYear = 0
    this.livedWithSpouse = true     // This is not currently used
    this.numberOfFilersOver65 = 0
    this.numberOfQualifiedChildDependents = 0
    this.numberOfQualifiedOtherDependents = 0
    this.income = {
      wagesSalariesTips: 0,
      taxExemptInterest: 0,
      taxableInterest: 0,
      qualifiedDividends: 0,
      unqualifiedDividends: 0,
      ordinaryDividends: 0,
      totalIraDistributions: 0,
      taxableIraDistributions: 0,
      totalPensionsAndAnnuities: 0,
      nonTaxablePensionAndAnnuities: 0,
      taxablePensionAndAnnuities: 0,
      totalSocialSecurityBenefits: 0,
      capitalGainOrLoss: 0,
      qualifiedCapitalGains: 0,
      unqualifiedCapitalGains: 0,
      otherIncome: 0,
      adjustmentsToIncome: 0
    }
    this.itemizedDeductionsIn = 0
    this.otherQualifyingDeductionsToTaxableIncome = 0
    this.additionalTax = 0
    this.childTaxCreditOrCreditForOtherDependents = 0
    this.additionalCreditsAndPayments = 0
    this.otherTaxes = 0
    this.federalIncomeTaxWithheld = 0
    this.earnedIncomeCredit = 0
    this.refundableAdditionalChildTaxCredits = 0
    this.otherPaymentsAndRefundableCredits = 0
    // this.previouslyPostponedIRAdistributionTaxes = 0
    this.socialSecurityTaxWithheld = 0
    this.medicareTaxWithheld = 0
    this.stateIncomeTaxWithheld = 0
    this.localIncomeTaxWithheld = 0
    this.inflationRate = 0
  }
}

export class TaxEstimate {
  taxableSocialSecurityBenefits: number
  totalIncome: number
  adjustedGrossIncome: number
  standardDeduction: number
  itemizedDeductions: number
  taxBracket: number
  taxableIncome: number
  taxLiability: number
  // childTaxCreditOrCreditForOtherDependents: number
  totalTax: number
  // refundableAdditionalChildTaxCredits: number
  totalOtherPaymentsAndRefundableCredits: number
  totalPayments: number
  amountOwed: number
  amountOverpaid: number
  effectiveTaxRate: number
  effectiveTotalTaxRate: number
  // postponedIRAdistributionTaxes: number
  totalOtherTaxWithholding: number
  totalEstimatedTax: number

  constructor() {
    this.taxableSocialSecurityBenefits = 0
    this.totalIncome = 0
    this.adjustedGrossIncome = 0
    this.standardDeduction = 0
    this.itemizedDeductions = 0
    this.taxBracket = 0
    this.taxableIncome = 0
    this.taxLiability = 0
    // this.childTaxCreditOrCreditForOtherDependents = 0
    this.totalTax = 0
    // this.refundableAdditionalChildTaxCredits = 0
    this.totalOtherPaymentsAndRefundableCredits = 0
    this.totalPayments = 0
    this.amountOwed = 0
    this.amountOverpaid = 0
    this.effectiveTaxRate = 0
    this.effectiveTotalTaxRate = 0
    // this.postponedIRAdistributionTaxes = 0
    this.totalOtherTaxWithholding = 0
    this.totalEstimatedTax = 0
  }

}

export class TaxEstimator {
  estimateTax = (input: TaxInput) => {
    // console.debug(`estimateTax input`, input)
    let estimate = new TaxEstimate()

    estimate.taxableSocialSecurityBenefits = this.computeTaxableSocialSecurityBenefits(input)

    estimate.totalIncome = input.income.wagesSalariesTips + input.income.taxableInterest +
      input.income.taxableIraDistributions + input.income.ordinaryDividends + input.income.taxablePensionAndAnnuities +
      estimate.taxableSocialSecurityBenefits + input.income.qualifiedCapitalGains + input.income.unqualifiedCapitalGains +
      input.income.otherIncome

    estimate.adjustedGrossIncome = estimate.totalIncome - Math.abs(input.income.adjustmentsToIncome)

    if (estimate.adjustedGrossIncome > 0) {
      let standardDeduction = this.getStandardDeduction(input)

      if (input.itemizedDeductionsIn > standardDeduction) {
        estimate.itemizedDeductions = input.itemizedDeductionsIn
        estimate.taxableIncome = estimate.adjustedGrossIncome - input.itemizedDeductionsIn
      } else {
        estimate.standardDeduction = standardDeduction
        estimate.taxableIncome = estimate.adjustedGrossIncome - standardDeduction
      }

      estimate.taxableIncome -= input.otherQualifyingDeductionsToTaxableIncome
      if (estimate.taxableIncome < 0) {
        estimate.taxableIncome = 0
      }

      const taxBracketRecord = this.getTaxBracket(input, estimate.taxableIncome)
      estimate.taxBracket = taxBracketRecord ? taxBracketRecord.bracket : 0

      estimate.taxLiability = this.computeTaxLiability(input, estimate, taxBracketRecord)

      // estimate.childTaxCreditOrCreditForOtherDependents = this.computeDependentTaxCredit(input, estimate)

      estimate.totalTax = estimate.taxLiability + input.additionalTax -
        input.childTaxCreditOrCreditForOtherDependents - input.additionalCreditsAndPayments +
        input.otherTaxes
      //  + input.previouslyPostponedIRAdistributionTaxes
    }

    // estimate.refundableAdditionalChildTaxCredits = this.computeAdditionalChildTaxCredit(input, estimate)

    estimate.totalPayments = input.federalIncomeTaxWithheld + input.earnedIncomeCredit
      + input.refundableAdditionalChildTaxCredits + input.otherPaymentsAndRefundableCredits

    // Non Federal Taxes are simply added to the total.
    estimate.totalOtherTaxWithholding = input.socialSecurityTaxWithheld + input.medicareTaxWithheld
      + input.stateIncomeTaxWithheld + input.localIncomeTaxWithheld

    estimate.totalEstimatedTax = estimate.totalTax + estimate.totalOtherTaxWithholding

    if (estimate.adjustedGrossIncome > 0) {
      estimate.effectiveTotalTaxRate = estimate.totalEstimatedTax / estimate.adjustedGrossIncome
    }

    // Concerning Federal taxes only.
    if (estimate.adjustedGrossIncome > 0) {
      estimate.effectiveTaxRate = estimate.totalTax / estimate.adjustedGrossIncome
    }
    if (estimate.totalTax > estimate.totalPayments) {
      estimate.amountOwed = estimate.totalTax - estimate.totalPayments
    } else {
      estimate.amountOverpaid = estimate.totalPayments - estimate.totalTax
    }
    // console.debug(`estimateTax esimate`, estimate)
    return estimate
  }

  computeTaxableSocialSecurityBenefits(input: TaxInput) {
    // NOTE: line numbers refer to Social Security Benefits Worksheet
    // https://www.irs.gov/pub/irs-pdf/i1040gi.pdf (page 31)
    const line = new Array(19).fill(0)

    line[1] = input.income.totalSocialSecurityBenefits
    if (line[1] === 0) {
      return 0
    }

    line[2] = Math.round(line[1] * 0.5)

    line[3] = input.income.wagesSalariesTips + input.income.taxableInterest +
      input.income.ordinaryDividends +
      input.income.taxableIraDistributions + input.income.taxablePensionAndAnnuities +
      input.income.qualifiedCapitalGains + input.income.unqualifiedCapitalGains +
      input.income.otherIncome

    line[4] = input.income.taxExemptInterest
    line[5] = line[2] + line[3] + line[4]
    line[6] = input.income.adjustmentsToIncome

    // TODO: Check on applicability of the Social security Benefits Worksheet line 6-7
    // which isn't handled in the Simplified 1040 Tax Calculator

    if (line[6] > line[5]) {
      return 0
    }

    line[7] = line[5] - line[6]

    line[8] = (input.filingStatus === TaxFilingStatus.MarriedFilingJointly) ? 32000 : 25000
    // NOTE: Ignoring living with spouse for Married filing separately

    if (line[8] > line[7]) {
      return 0
    } else {
      line[9] = Math.max(line[7] - line[8], 0)
    }

    line[10] = (input.filingStatus === TaxFilingStatus.MarriedFilingJointly) ? 12000 : 9000
    line[11] = (line[9] > line[10]) ? line[9] - line[10] : 0
    line[12] = Math.min(line[9], line[10])
    line[13] = Math.round(line[12] / 2)
    line[14] = Math.min(line[2], line[13])
    line[15] = Math.round(line[11] * 0.85)
    line[16] = line[14] + line[15]
    line[17] = Math.round(line[1] * 0.85)
    line[18] = Math.min(line[16], line[17])

    return line[18]
  }

  futureValue(presentValue: number, infRate: number, periods: number): number {
    if (infRate === 0 || periods === 0) {
      return presentValue
    } else {
      const futureValue = presentValue * Math.pow(1.0 + infRate, periods)
      return roundMoney(futureValue)
    }
  }

  getStandardDeduction(input: TaxInput): number {
    let deduction = 0

    const taxYear = this.getEffectiveTaxYear(input)
    const infYears = input.taxYear - taxYear
    const infRate = input.inflationRate ?? 0

    const record = StandardDeductionTable.find(r => input.filingStatus === r.filingStatus && taxYear === r.taxYear)
    if (record) {
      deduction = this.futureValue(record.baseDeduction, infRate, infYears)
      if (input.numberOfFilersOver65 > 0) {
        deduction += this.futureValue(record.over65Deduction, infRate, infYears) * input.numberOfFilersOver65
      }
    }

    return deduction
  }

  getTaxBracket(input: TaxInput, agi: number) {
    const effYear = this.getEffectiveTaxYear(input)
    const filingStatus = input.filingStatus
    const infYears = input.taxYear - effYear
    const infRate = input.inflationRate

    const record = TaxBracketTable.find(r =>
       effYear === r.taxYear && filingStatus === r.filingStatus && agi >= r.start && (r.end === null || agi <= r.end ))

    if (record && infYears > 0 && infRate > 0) {
      const infRecord: ITaxBracketRecord = {
        taxYear: input.taxYear,
        filingStatus: record.filingStatus,
        start: this.futureValue(record.start, infRate, infYears),
        end: record.end ? this.futureValue(record.end, infRate, infYears) : null,
        bracket: record.bracket,
        baseTax: this.futureValue(record.baseTax, infRate, infYears)
      }
      return infRecord
    }

    return record
  }

  getTaxBrackets(taxYear: number, filingStatus: TaxFilingStatus, infRate: number = 0) : ITaxBracketRecord[] {
    const year = Math.max(Math.min(taxYear, MaxTaxYear), MinTaxYear)
    const records = TaxBracketTable.filter(r =>
      year === r.taxYear && filingStatus === r.filingStatus)

    const infYears = taxYear - year
    if (infRate > 0 && infYears > 0) {
      const infRecords = new Array<ITaxBracketRecord>(records.length)
      for (let ii = 0; ii < records.length; ii++) {
        const rec = records[ii]
        infRecords[ii] = {
          taxYear: taxYear,
          filingStatus: rec.filingStatus,
          start: this.futureValue(rec.start, infRate, infYears),
          end: rec.end ? this.futureValue(rec.end, infRate, infYears) : null,
          bracket: rec.bracket,
          baseTax: this.futureValue(rec.baseTax, infRate, infYears)
        }
      }
      return infRecords
    }

    return records
  }

  getMedicarePremium(taxYear: number, filingStatus: TaxFilingStatus, agi: number, infRate: number = 0) {
    const year = Math.max(Math.min(taxYear, MaxMedicareYear), MinMedicareYear)

    const record = MedicarePremiumTable.find(r =>
      year === r.year && filingStatus === r.filingStatus && agi >= r.start && (r.end === null || agi <= r.end ))

    if (record) {
      const infYears = taxYear - year
      if (infRate > 0 && infYears > 0) {
        const infRecord: IMedicarePremiumRecord = {
          year: taxYear,
          filingStatus: record.filingStatus,
          start: this.futureValue(record.start, infRate, infYears),
          end: record.end ? this.futureValue(record.end, infRate, infYears) : null,
          amount: this.futureValue(record.amount, infRate, infYears)
        }
        return infRecord
      }
    }

    return record
  }

  getMedicarePremiums(taxYear: number, filingStatus: TaxFilingStatus, infRate: number = 0): IMedicarePremiumRecord[] {
    const year = Math.max(Math.min(taxYear, MaxMedicareYear), MinMedicareYear)
    const records = MedicarePremiumTable.filter(r =>
      year === r.year && filingStatus === r.filingStatus)

    const infYears = taxYear - year

    if (infYears > 0 && infRate > 0 && records.length > 0) {

      const infRecords = new Array<IMedicarePremiumRecord>(records.length)
      for (let ii = 0; ii < records.length; ii++) {
        const record = records[ii]
        infRecords[ii] = {
          year: taxYear,
          filingStatus: record.filingStatus,
          start: this.futureValue(record.start, infRate, infYears),
          end: record.end ? this.futureValue(record.end, infRate, infYears) : null,
          amount: this.futureValue(record.amount, infRate, infYears)
        }
      }
      return infRecords
    }

    return records
  }

  computeTaxLiability(input: TaxInput, estimate: TaxEstimate, taxBracketRecord: any /* TODO */) {

    const qualifiedDividends = input.income.qualifiedDividends
    const shortTermCapitalGains = input.income.unqualifiedCapitalGains
    const longTermCapitalGains = input.income.qualifiedCapitalGains

    if (qualifiedDividends === 0 && shortTermCapitalGains === 0 && longTermCapitalGains === 0) {
      return this.computeTax(input, estimate.taxableIncome, taxBracketRecord)
    } else {
      // Qualified Dividends and Capital Gain Tax Worksheet 2024 version
      // https://www.irs.gov/pub/irs-pdf/i1040gi.pdf (page 36)
      const line = new Array(26).fill(0)
      line[1] = estimate.taxableIncome
      line[2] = qualifiedDividends
      // Line 16 (total capital Gains) from Schedule D
      const totalCapitalGains = shortTermCapitalGains + longTermCapitalGains
      if (longTermCapitalGains <= 0 || totalCapitalGains <= 0) {
        line[3] = 0
      } else {
        // Smaller of line 15 or 16 from Schedule D
        line[3] = Math.min(longTermCapitalGains, totalCapitalGains)
      }
      line[4] = line[2] + line[3]
      line[5] = Math.max(line[1] - line[4], 0)
      line[6] = (input.filingStatus === TaxFilingStatus.MarriedFilingJointly) ? 94050 : 47025
      line[7] = Math.min(line[1], line[6])
      line[8] = Math.min(line[5], line[7])
      line[9] = line[7] - line[8]
      line[10] = Math.min(line[1], line[4])
      line[11] = line[9]
      line[12] = line[10] - line[11]
      line[13] = (input.filingStatus === TaxFilingStatus.MarriedFilingJointly) ? 583750 : 518900
      line[14] = Math.min(line[1], line[13])
      line[15] = line[5] + line[9]
      line[16] = Math.max(line[14] - line[15], 0)
      line[17] = Math.min(line[12], line[16])
      line[18] = Math.round(line[17] * 0.15)
      line[19] = line[9] + line[17]
      line[20] = line[10] - line[19]
      line[21] = Math.round(line[20] * 0.20)
      line[22] = this.computeTax(input, line[5])
      line[23] = line[18] + line[21] + line[22]
      line[24] = this.computeTax(input, line[1])
      line[25] = Math.min(line[23], line[24])

      // this.logLines("Qualified Dividends and Capital Gain Tax Worksheet", line)

      return line[25]
    }
  }

  computeTax(input: TaxInput, taxableIncome: number, taxBracketRecord?: any /* TODO */) {
    let taxLiability = 0
    const taxYear = this.getEffectiveTaxYear(input)
    const filingStatus = input.filingStatus

    if (!taxBracketRecord) {
      taxBracketRecord = this.getTaxBracket(input, taxableIncome)
    }

    if (!taxBracketRecord) {
      return 0
    }

    if (taxBracketRecord.start === 0) {
      taxLiability = taxableIncome * taxBracketRecord.bracket
    } else {
      taxLiability = taxBracketRecord.baseTax + (taxableIncome - taxBracketRecord.start - 1) * taxBracketRecord.bracket
    }

    return Math.round(taxLiability)
  }

  computeDependentTaxCredit(input: TaxInput, estimate: TaxEstimate) {
    // NOTICE: This is from 2020 and no longer used
    return input.childTaxCreditOrCreditForOtherDependents
  }

  computeAdditionalChildTaxCredit(input: TaxInput, estimate: TaxEstimate) {
    // NOTICE: This is from 2020 and no longer used
    return input.refundableAdditionalChildTaxCredits
  }

  logLines(title: string, line: []) {
    console.group(title)
    for (let i=1; i<line.length; i++)
    {
      console.log(i + ": " + line[i])
    }
    console.groupEnd()
  }

  preProcessInputs(input: TaxInput) {
    let income = input.income;

    // If Qualified Dividends is greater than Ordinary Dividends, set Ordinary Dividends equal to Qualified Dividends.
    income.ordinaryDividends = income.qualifiedDividends + income.unqualifiedDividends;
    // income.ordinaryDividends = Math.max(income.ordinaryDividends, income.qualifiedDividends);

    // totalPensionsAndAnnuities is the sum of nonTaxablePensionAndAnnuities and taxablePensionAndAnnuities.
    income.totalPensionsAndAnnuities = income.nonTaxablePensionAndAnnuities + income.taxablePensionAndAnnuities;

    // When Filing Status is Single, numberOfFilersOver65 should max out at 1.
    input.numberOfFilersOver65 = input.filingStatus === TaxFilingStatus.Single
      ? Math.min(1, input.numberOfFilersOver65) : input.numberOfFilersOver65;
  }

  getEffectiveTaxYear(input: TaxInput) {
    if (!input.taxYear) {
      throw new Error("taxYear is required")
    }

    if (input.taxYear < MinTaxYear) {
      return MinTaxYear
    } else if (input.taxYear > MaxTaxYear) {
      return MaxTaxYear
    } else {
      return input.taxYear
    }
  }



}

export const MinTaxYear = 2021
export const MaxTaxYear = 2024
export const MinMedicareYear = 2024
export const MaxMedicareYear = 2024

interface IStandardDeductionRecord {
  filingStatus: number
  taxYear: number
  baseDeduction: number
  over65Deduction: number
}

const StandardDeductionTable: IStandardDeductionRecord[] = [
  {
    "filingStatus": 1,
    "taxYear": 2021,
    "baseDeduction": 12550,
    "over65Deduction": 1700
  },
  {
    "taxYear": 2021,
    "filingStatus": 2,
    "baseDeduction": 25100,
    "over65Deduction": 1350
  },
  {
    "taxYear": 2021,
    "filingStatus": 3,
    "baseDeduction": 12550,
    "over65Deduction": 1350
  },
  {
    "taxYear": 2021,
    "filingStatus": 4,
    "baseDeduction": 18800,
    "over65Deduction": 1700
  },
  {
    "taxYear": 2021,
    "filingStatus": 5,
    "baseDeduction": 25100,
    "over65Deduction": 1350
  },
  {
    "filingStatus": 1,
    "taxYear": 2022,
    "baseDeduction": 12950,
    "over65Deduction": 1750
  },
  {
    "taxYear": 2022,
    "filingStatus": 2,
    "baseDeduction": 25900,
    "over65Deduction": 1400
  },
  {
    "taxYear": 2022,
    "filingStatus": 3,
    "baseDeduction": 12950,
    "over65Deduction": 1400
  },
  {
    "taxYear": 2022,
    "filingStatus": 4,
    "baseDeduction": 19400,
    "over65Deduction": 1750
  },
  {
    "taxYear": 2022,
    "filingStatus": 5,
    "baseDeduction": 25900,
    "over65Deduction": 1400
  },
  /* 2023 */
  {
    "filingStatus": 1,
    "taxYear": 2023,
    "baseDeduction": 13850,
    "over65Deduction": 1850
  },
  {
    "taxYear": 2023,
    "filingStatus": 2,
    "baseDeduction": 27700,
    "over65Deduction": 1500
  },
  {
    "taxYear": 2023,
    "filingStatus": 3,
    "baseDeduction": 13850,
    "over65Deduction": 1500
  },
  {
    "taxYear": 2023,
    "filingStatus": 4,
    "baseDeduction": 19400,
    "over65Deduction": 1850
  },
  {
    "taxYear": 2023,
    "filingStatus": 5,
    "baseDeduction": 27700,
    "over65Deduction": 1500
  },
  /* 2024 */
  {
    "filingStatus": 1,
    "taxYear": 2024,
    "baseDeduction": 14600,
    "over65Deduction": 1950
  },
  {
    "taxYear": 2024,
    "filingStatus": 2,
    "baseDeduction": 29200,
    "over65Deduction": 1550
  },
  {
    "taxYear": 2024,
    "filingStatus": 3,
    "baseDeduction": 14600,
    "over65Deduction": 1550
  },
  {
    "taxYear": 2024,
    "filingStatus": 4,
    "baseDeduction": 21900,
    "over65Deduction": 1950
  },
  {
    "taxYear": 2024,
    "filingStatus": 5,
    "baseDeduction": 29200,
    "over65Deduction": 1550
  }
]

export interface ITaxBracketRecord {
  taxYear: number
  filingStatus: number
  start: number
  end: number | null
  bracket: number
  baseTax: number
}

export const TaxBracketTable: ITaxBracketRecord[] = [
  {
    "taxYear": 2021,
    "filingStatus": 1,
    "start": 0,
    "end": 9950,
    "bracket": 0.10,
    "baseTax": 0
  },
  {
    "taxYear": 2021,
    "filingStatus": 1,
    "start": 9951,
    "end": 40525,
    "bracket": 0.12,
    "baseTax": 995
  },
  {
    "taxYear": 2021,
    "filingStatus": 1,
    "start": 40526,
    "end": 86375,
    "bracket": 0.22,
    "baseTax": 4664
  },
  {
    "taxYear": 2021,
    "filingStatus": 1,
    "start": 86376,
    "end": 164925,
    "bracket": 0.24,
    "baseTax": 14751
  },
  {
    "taxYear": 2021,
    "filingStatus": 1,
    "start": 164926,
    "end": 209425,
    "bracket": 0.32,
    "baseTax": 33603
  },
  {
    "taxYear": 2021,
    "filingStatus": 1,
    "start": 209426,
    "end": 523600,
    "bracket": 0.35,
    "baseTax": 47843
  },
  {
    "taxYear": 2021,
    "filingStatus": 1,
    "start": 523601,
    "end": null,
    "bracket": 0.37,
    "baseTax": 157804.25
  },
  {
    "taxYear": 2021,
    "filingStatus": 2,
    "start": 0,
    "end": 19990,
    "bracket": 0.10,
    "baseTax": 0
  },
  {
    "taxYear": 2021,
    "filingStatus": 2,
    "start": 19991,
    "end": 81050,
    "bracket": 0.12,
    "baseTax": 1999
  },
  {
    "taxYear": 2021,
    "filingStatus": 2,
    "start": 81051,
    "end": 172750,
    "bracket": 0.22,
    "baseTax": 9328
  },
  {
    "taxYear": 2021,
    "filingStatus": 2,
    "start": 172751,
    "end": 329850,
    "bracket": 0.24,
    "baseTax": 29502
  },
  {
    "taxYear": 2021,
    "filingStatus": 2,
    "start": 329851,
    "end": 418850,
    "bracket": 0.32,
    "baseTax": 67206
  },
  {
    "taxYear": 2021,
    "filingStatus": 2,
    "start": 418851,
    "end": 628300,
    "bracket": 0.35,
    "baseTax": 95686
  },
  {
    "taxYear": 2021,
    "filingStatus": 2,
    "start": 628301,
    "end": null,
    "bracket": 0.37,
    "baseTax": 168993.5
  },
  /* 2022 */
  {
    "taxYear": 2022,
    "filingStatus": 1,
    "start": 0,
    "end": 10275,
    "bracket": 0.10,
    "baseTax": 0
  },
  {
    "taxYear": 2022,
    "filingStatus": 1,
    "start": 10276,
    "end": 41775,
    "bracket": 0.12,
    "baseTax": 1027
  },
  {
    "taxYear": 2022,
    "filingStatus": 1,
    "start": 41776,
    "end": 89075,
    "bracket": 0.22,
    "baseTax": 4807
  },
  {
    "taxYear": 2022,
    "filingStatus": 1,
    "start": 89076,
    "end": 170050,
    "bracket": 0.24,
    "baseTax": 15213
  },
  {
    "taxYear": 2022,
    "filingStatus": 1,
    "start": 170051,
    "end": 215950,
    "bracket": 0.32,
    "baseTax": 34647
  },
  {
    "taxYear": 2022,
    "filingStatus": 1,
    "start": 215951,
    "end": 539900,
    "bracket": 0.35,
    "baseTax": 49335
  },
  {
    "taxYear": 2022,
    "filingStatus": 1,
    "start": 539901,
    "end": null,
    "bracket": 0.37,
    "baseTax": 162717
  },
  {
    "taxYear": 2022,
    "filingStatus": 2,
    "start": 0,
    "end": 20550,
    "bracket": 0.10,
    "baseTax": 0
  },
  {
    "taxYear": 2022,
    "filingStatus": 2,
    "start": 20551,
    "end": 83550,
    "bracket": 0.12,
    "baseTax": 2055
  },
  {
    "taxYear": 2022,
    "filingStatus": 2,
    "start": 83551,
    "end": 178150,
    "bracket": 0.22,
    "baseTax": 9615
  },
  {
    "taxYear": 2022,
    "filingStatus": 2,
    "start": 178151,
    "end": 340100,
    "bracket": 0.24,
    "baseTax": 30427
  },
  {
    "taxYear": 2022,
    "filingStatus": 2,
    "start": 340101,
    "end": 431900,
    "bracket": 0.32,
    "baseTax": 69295
  },
  {
    "taxYear": 2022,
    "filingStatus": 2,
    "start": 431901,
    "end": 647850,
    "bracket": 0.35,
    "baseTax": 98671
  },
  {
    "taxYear": 2022,
    "filingStatus": 2,
    "start": 647851,
    "end": null,
    "bracket": 0.37,
    "baseTax": 174253
  },
  /* 2023 */
  {
    "taxYear": 2023,
    "filingStatus": 1,
    "start": 0,
    "end": 11000,
    "bracket": 0.10,
    "baseTax": 0
  },
  {
    "taxYear": 2023,
    "filingStatus": 1,
    "start": 11001,
    "end": 44725,
    "bracket": 0.12,
    "baseTax": 1100
  },
  {
    "taxYear": 2023,
    "filingStatus": 1,
    "start": 44726,
    "end": 95375,
    "bracket": 0.22,
    "baseTax": 5147
  },
  {
    "taxYear": 2023,
    "filingStatus": 1,
    "start": 95376,
    "end": 182100,
    "bracket": 0.24,
    "baseTax": 16290
  },
  {
    "taxYear": 2023,
    "filingStatus": 1,
    "start": 182101,
    "end": 231250,
    "bracket": 0.32,
    "baseTax": 37104
  },
  {
    "taxYear": 2023,
    "filingStatus": 1,
    "start": 231251,
    "end": 578125,
    "bracket": 0.35,
    "baseTax": 52832
  },
  {
    "taxYear": 2023,
    "filingStatus": 1,
    "start": 578126,
    "end": null,
    "bracket": 0.37,
    "baseTax": 174238
  },
  {
    "taxYear": 2023,
    "filingStatus": 2,
    "start": 0,
    "end": 22000,
    "bracket": 0.10,
    "baseTax": 0
  },
  {
    "taxYear": 2023,
    "filingStatus": 2,
    "start": 22001,
    "end": 89450,
    "bracket": 0.12,
    "baseTax": 2200
  },
  {
    "taxYear": 2023,
    "filingStatus": 2,
    "start": 89451,
    "end": 190750,
    "bracket": 0.22,
    "baseTax": 10294
  },
  {
    "taxYear": 2023,
    "filingStatus": 2,
    "start": 190751,
    "end": 364200,
    "bracket": 0.24,
    "baseTax": 32580
  },
  {
    "taxYear": 2023,
    "filingStatus": 2,
    "start": 364201,
    "end": 462500,
    "bracket": 0.32,
    "baseTax": 74208
  },
  {
    "taxYear": 2023,
    "filingStatus": 2,
    "start": 462501,
    "end": 693750,
    "bracket": 0.35,
    "baseTax": 105664
  },
  {
    "taxYear": 2023,
    "filingStatus": 2,
    "start": 693751,
    "end": null,
    "bracket": 0.37,
    "baseTax": 186601
  },
  /* 2024 */
  {
    "taxYear": 2024,
    "filingStatus": 1,
    "start": 0,
    "end": 11600,
    "bracket": 0.10,
    "baseTax": 0
  },
  {
    "taxYear": 2024,
    "filingStatus": 1,
    "start": 11601,
    "end": 47150,
    "bracket": 0.12,
    "baseTax": 1160
  },
  {
    "taxYear": 2024,
    "filingStatus": 1,
    "start": 47151,
    "end": 100525,
    "bracket": 0.22,
    "baseTax": 5426 // (47150-11601) * .12 + 1160
  },
  {
    "taxYear": 2024,
    "filingStatus": 1,
    "start": 100526,
    "end": 191950,
    "bracket": 0.24,
    "baseTax": 17168 // (100525-47151) * .22 + 5426
  },
  {
    "taxYear": 2024,
    "filingStatus": 1,
    "start": 191951,
    "end": 243725,
    "bracket": 0.32,
    "baseTax": 39110 // (191950-100526) * .24 + 17168
  },
  {
    "taxYear": 2024,
    "filingStatus": 1,
    "start": 243726,
    "end": 609350,
    "bracket": 0.35,
    "baseTax": 55678 // (243725-191951) * .32 + 39110
  },
  {
    "taxYear": 2024,
    "filingStatus": 1,
    "start": 609351,
    "end": null,
    "bracket": 0.37,
    "baseTax": 183646 // (609350-243726) * .35 + 55678
  },
  {
    "taxYear": 2024,
    "filingStatus": 2,
    "start": 0,
    "end": 23200,
    "bracket": 0.10,
    "baseTax": 0
  },
  {
    "taxYear": 2024,
    "filingStatus": 2,
    "start": 23201,
    "end": 94300,
    "bracket": 0.12,
    "baseTax": 2320
  },
  {
    "taxYear": 2024,
    "filingStatus": 2,
    "start": 94301,
    "end": 201050,
    "bracket": 0.22,
    "baseTax": 10852 // (94300-23201) *.12 + 2320
  },
  {
    "taxYear": 2024,
    "filingStatus": 2,
    "start": 201051,
    "end": 383900,
    "bracket": 0.24,
    "baseTax": 34337 // (201050-94301) * .22 + 10852
  },
  {
    "taxYear": 2024,
    "filingStatus": 2,
    "start": 383901,
    "end": 487450,
    "bracket": 0.32,
    "baseTax": 78221 // (383900-201051) * .24 + 34337
  },
  {
    "taxYear": 2024,
    "filingStatus": 2,
    "start": 487451,
    "end": 731200,
    "bracket": 0.35,
    "baseTax": 111357 // (487450-383901) * .32 + 78221
  },
  {
    "taxYear": 2024,
    "filingStatus": 2,
    "start": 731201,
    "end": null,
    "bracket": 0.37,
    "baseTax": 196669 // (731200-487451) * .35 + 111357
  }
]

export interface IMedicarePremiumRecord {
  year: number
  filingStatus: number
  start: number
  end: number | null
  amount: number
}

export const MedicarePremiumTable: IMedicarePremiumRecord[] = [
  {
    "year": 2024,
    "filingStatus": 1,
    "start": 0,
    "end": 103000,
    "amount": 175
  },
  {
    "year": 2024,
    "filingStatus": 1,
    "start": 103001,
    "end": 129000,
    "amount": 245
  },
  {
    "year": 2024,
    "filingStatus": 1,
    "start": 129001,
    "end": 161000,
    "amount": 349
  },
  {
    "year": 2024,
    "filingStatus": 1,
    "start": 161001,
    "end": 193000,
    "amount": 454
  },
  {
    "year": 2024,
    "filingStatus": 1,
    "start": 193001,
    "end": 499999,
    "amount": 559
  },
  {
    "year": 2024,
    "filingStatus": 1,
    "start": 500000,
    "end": null,
    "amount": 594
  },

  {
    "year": 2024,
    "filingStatus": 2,
    "start": 0,
    "end": 206000,
    "amount": 175
  },
  {
    "year": 2024,
    "filingStatus": 2,
    "start": 206001,
    "end": 258000,
    "amount": 245
  },
  {
    "year": 2024,
    "filingStatus": 2,
    "start": 258001,
    "end": 322000,
    "amount": 349
  },
  {
    "year": 2024,
    "filingStatus": 2,
    "start": 322001,
    "end": 386000,
    "amount": 454
  },
  {
    "year": 2024,
    "filingStatus": 2,
    "start": 386001,
    "end": 749999,
    "amount": 559
  },
  {
    "year": 2024,
    "filingStatus": 2,
    "start": 750000,
    "end": null,
    "amount": 594
  },
]

export default TaxEstimator
// Only for testing (nodejs)
// if (typeof window === 'undefined') {
//   module.exports = {
//     TaxFilingStatus : TaxFilingStatus,
//     TaxInput : TaxInput,
//     TaxEstimate: TaxEstimate,
//     TaxEstimator : TaxEstimator
//   }
// }