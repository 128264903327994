import * as React from "react";
import {inject} from "mobx-react";
import { Checkbox, CheckboxProps, FormControl, FormControlLabel, FormControlLabelProps, FormHelperText, Theme } from "@mui/material";

interface ICheckboxValidatorProps {
  onChange?: React.EventHandler<any>
  required: boolean
  formvalidator?: any
  label?: string
  labelPlacement?: FormControlLabelProps['labelPlacement']
}

type CheckboxValidatorProps = CheckboxProps | ICheckboxValidatorProps

@inject("formvalidator")
class CheckboxValidator extends React.Component<CheckboxValidatorProps> {

  state = {
    checked: false,
    isValid: true // Start valid until onChange() or validate() event
  }

  componentDidMount() {
    const {formvalidator} = this.props as ICheckboxValidatorProps
    if (formvalidator) {
      formvalidator.attachToForm(this)
    }
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.currentTarget;
    const { props } = this
    const { onChange } = props as ICheckboxValidatorProps

    this.setState({checked, isValid: !this.props.required || checked})

    if (onChange) {
      onChange(event);
    }
  }

  validate = (value: boolean): boolean => {
      // Ignore value because only checked matters
      const isValid = !this.props.required || this.state.checked
      this.setState({isValid: isValid})
      return isValid
  }

  render() {
    const {
      onChange,
      ...rest
    } = this.props

    const { label, labelPlacement } = this.props as ICheckboxValidatorProps

    const checkboxStyle = (this.state.isValid) ? 
    {
      width: 48
    }
      : 
    {
      color: (theme: Theme) => theme.palette.error.main,
      width: 48,
      height: 30
    }

    return (
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              {...rest}
              onChange={this.onChange}
              sx={checkboxStyle}
            />}
          label={label}
          labelPlacement={labelPlacement}
        />

        <FormHelperText 
          error={!this.state.isValid} 
          sx={{
            position: "relative",
            top: -8
          }}
        >
          {this.state.isValid ? "" : "Required"}
        </FormHelperText>
      </FormControl>
    )
  }

}

export default CheckboxValidator