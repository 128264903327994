import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { RouteComponentProps } from '@reach/router'
import DialogButton from '../form/DialogButton'
import {inject, observer} from "mobx-react";
import Alert from "./Alert";

interface IAlertDialog {
  alert?: Alert
}

@inject("alert")
@observer
class AlertDialog extends React.Component<RouteComponentProps & IAlertDialog> {

  onClose = () => {
    if (this.props.alert) {
      this.props.alert.close();
    }
  };

  onSubmit = (event: any) => {
    if (this.props.alert) {
      this.props.alert.close()
    }
  }

  render() {
    const { alert } = this.props

    let dialog = null
    if (alert && alert.open) {
      dialog = <Dialog
        id="alertDialog"
        open={alert.open}
        onClose={this.onClose}
        scroll="paper"
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-title">
        <DialogTitle id="alert-dialog-title">{alert.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {alert.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            paddingBottom: "16px"
          }}
        >
          <DialogButton variant="primary" onClick={this.onSubmit}>
            Ok
          </DialogButton>
        </DialogActions>
      </Dialog>
    }

    return (dialog)
  }
}

export default AlertDialog
