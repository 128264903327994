import {Box, Grid, Typography, useTheme} from "@mui/material";
import AppLogo from "../../images/AppLogo.png"
import {Link} from "@reach/router";
import RoutesConfig from "../../RoutesConfig";
import {useResources} from "../../stores/ResourceProvider";
import {useNavigate} from "react-router-dom";
import useNavigationHelper from "../NavigationHelper";

const Footer = ({

} : {

}) => {
  const theme = useTheme()
  const styles = {
    footer: {
      display: "flex",
      flexGrow: 1,
      width: "100%",
      marginTop: theme.spacing(2),
      marginLeft: "-16px",
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default
    },
    link: {
      color: theme.palette.text.primary,
      fontSize: 18,
      fontWeight: 600,
      textDecoration: "none"
    }
  }

  const {termsOfUse, privacyPolicy} = useResources()
  const navigate = useNavigate()
  const {navigateHome} = useNavigationHelper()

  return (
    <Box sx={styles.footer}>
      <Grid container justifyContent="space-evenly" spacing={0}>
        <Grid item xs={6} sm={3} textAlign="center">
          <Link to="." onClick={() => navigateHome()} style={styles.link}>
            Home
          </Link>
        </Grid>
        <Grid item xs={6} sm={3} textAlign="center">
          <Link to="." onClick={() => privacyPolicy.show()} style={styles.link}>
            Privacy Policy
          </Link>
        </Grid>
        <Grid item xs={6} sm={3} textAlign="center">
          <Link to="." onClick={() => termsOfUse.show()} style={styles.link}>
            Terms of Use
          </Link>
        </Grid>
        <Grid item xs={6} sm={3} textAlign="center">
          <Link to="." onClick={() => window.open('mailto:support@retirementbudgetcalculator.com', "_blank")} style={styles.link}>
            Contact Us
          </Link>
        </Grid>
        <Grid xs={12} justifyContent="center" mt={1}>
          <Typography variant="body2" align="center">
            &copy;&nbsp;2016-2024 Retirement Budget Calculator LLC (All rights reserved)
          </Typography>
        </Grid>
        <Grid xs={12} justifyContent="center">
          <Typography variant="body2" align="center">
            Version 4.0.0
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Footer