import * as React from "react";
import {useEffect, useState} from "react";
import {useResources} from "../../stores/ResourceProvider";
import Dialog from "@mui/material/Dialog";
import FormValidator from "../../components/form/FormValidator";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Model from "../../model/Model";
import DialogActions from "@mui/material/DialogActions";
import {Box, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Switch, useTheme} from "@mui/material";
import CancelButton from "../../components/form/CancelButton";
import ProgressButton from "../../components/form/ProgressButton";
import {ClientAccess} from "../../API";
import Tracking from "../../components/Tracking";


const NewModelDialog = ({
  baseModel,
  onClose,
  onCreate
}: {
  baseModel?: Model,
  onClose(): void
  onCreate(model?: Model): void
}) => {
  const [models, setModels] = useState<Model[]>([])
  const [name, setName] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [model, setModel] = useState<Model | undefined>()
  const [advisorCreated, setAdvisorCreated] = useState<boolean>(false)
  const [clientAccess, setClientAccess] = useState<ClientAccess>(ClientAccess.Owner)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const {modelStore, userStore, notify} = useResources()
  const theme = useTheme()


  const styles = {
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "1.0em",
      maxHeight: 15,
      marginBottom: "1px",
      textWrap: "nowrap",
      color: theme.palette.grey["700"]
    },
  }

  useEffect(() => {
    setModels(modelStore.models)
    setModel(baseModel)
    if (baseModel && baseModel.name) {
      setName(getUniqueName(baseModel.name))
    } else {
      setName(getUniqueName("New Model"))
    }
    if (userStore.isAdvisor && userStore.user?.id !== modelStore.currentUser?.id) {
      setAdvisorCreated(true)
      setClientAccess(ClientAccess.None)
    }
  }, [baseModel, modelStore, modelStore.isLoading, modelStore.models])

  const getUniqueName = (baseName: string) => {
    let num = 2
    let newName = baseName
    let found = false
    do {
      found = Boolean(modelStore.models.find((m: Model) => m.name.toLowerCase() === newName.toLowerCase()))
      if (found) {
        if (baseName.endsWith(" Copy")) {
          newName = `${baseName} Copy ${num}`
          ++num
        } else {
          newName = `${baseName} Copy`
        }
      }
    } while (found)
    return newName
  }

  const handleSubmit = async () => {
    try {
      setIsProcessing(true)
      const newModel = await modelStore.newModel(name, description, model && model.name ? model : undefined, advisorCreated, clientAccess)
      if (newModel) {
        Tracking.event({action: "Model Created"})
        onCreate(newModel)
      }
    } catch (err: any) {
      notify.show("error", `Error creating new model (${err.message})`)
    } finally {
      setIsProcessing(false)
    }
  }

  if (!models.length) {
    return null
  }

  const isAdvisor = userStore.isAdvisor

  return (
    <Dialog
      id="newModelDialog"
      open={true}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="newModelDialogTitle"
    >
      <FormValidator onSubmit={handleSubmit} autoComplete="off">
        <DialogTitle id="newModelDialogTitle">Create New Model</DialogTitle>
        <DialogContent sx={{my:2}}>
          <TextFieldValidator
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            validators={{required:true}}
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
            fullWidth
            variant="standard"
          />
          <TextFieldValidator
            margin="dense"
            name="description"
            label="Description"
            type="text"
            validators={{required:false}}
            value={description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDescription(event.target.value)}
            fullWidth
            variant="standard"
          />
          <TextFieldValidator
            type="text"
            validators={{ required: false }}
            name="baseModelName"
            label="Base Model"
            variant="standard"
            autocompleteOptions={{
              freeSolo: false,
              options: models,
              getOptionLabel: (option: Model) => option && option.name ? option.name : "None",
              isOptionEqualToValue: (option: Model, value: Model) => {
                return (option.id === value.id)
              },
              value: model,
              onChange: (event: any, value: Model, reason: any) => {
                setModel(value)
              }
            }}
          />
          {advisorCreated &&
            <FormGroup>
              <FormLabel sx={styles.formLabel}>Client Access</FormLabel>
              <RadioGroup aria-label="clientAccess" name="discretionary" value={clientAccess} row
                          onChange={(event: any) => {
                            setClientAccess(event.target.value)
                          }}>
                <FormControlLabel
                  value="None"
                  control={<Radio color="secondary" />}
                  label="None"
                  labelPlacement="end"
                />
                {/*<FormControlLabel*/}
                {/*  value="View"*/}
                {/*  control={<Radio color="secondary" />}*/}
                {/*  label="View"*/}
                {/*  labelPlacement="end"*/}
                {/*/>*/}
                <FormControlLabel
                  value="Edit"
                  control={<Radio color="secondary" />}
                  label="Edit"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="Owner"
                  control={<Radio color="secondary" />}
                  label="Owner"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormGroup>
          }
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="flex-end">
            <CancelButton onClick={onClose} />
            <ProgressButton variant="contained" size="medium" color="primary" sx={{ color: "#fff" }}
                            type="submit" processing={isProcessing}>
              Create
            </ProgressButton>
          </Box>
        </DialogActions>

      </FormValidator>

    </Dialog>
  )

}


export default NewModelDialog