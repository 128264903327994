import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {numberToMoneyFormat} from "../../../../stores/StoreUtilities";
import globalStyles from "../../../../styles/globalStyles";
import {Gender} from "../../../../API";
import {FutureCalc} from "../../../../components/calculator/Calculator";

export interface ISeries {
  name: string,
  group: string,
  data: [{x: number, y: number}]
}

const WithdrawalProjectionTable = ({
  futureCalc,
  series,
  year,
  onChange
}: {
  futureCalc: FutureCalc
  series: ISeries[]
  year?: number
  onChange?: (year: number) => any
}) => {
  const [currentYear, setCurrentYear] = useState<number>(0)
  const [totals, setTotals] = useState<number[]>([])

  useEffect( () => {
    let tots: number[] = []
    series.forEach((s: ISeries, index: number) => {
      tots.push(s.data.reduce((accum: number, item: {x: number, y: number}) => {
        return (accum + item.y)
      }, 0))
    })
    setTotals(tots)
  }, [series])

  useEffect(() => {
    if (year) {
      setCurrentYear(year)
    }
  }, [year])

  const handleChange = (year: number) => {
    if (onChange) {
      setCurrentYear(year)
      onChange(year)
    }
  }

  const styles = {
    total: {
      fontWeight: 700
    }
  }

  if (!series || series.length === 0) {
    return null
  }

  return (
    <Box display="flex" flexGrow={1} flexDirection="column" mt={2}>
      <Box display="flex" flexGrow={1} justifyContent="space-between" minHeight={40}>
        <Typography variant="h3" color="primary" gutterBottom>Withdrawal Projection Details</Typography>
        <Box display="flex" alignItems="center" pt={0}>
          <Box width={14} height={14} sx={globalStyles.trRetired}></Box>
          <Typography variant="body2" style={globalStyles.legendLabel}>Planned Retirement</Typography>
          {futureCalc.person1 &&
            <React.Fragment>
              <Box width={14} height={14} sx={futureCalc.person1.gender === Gender.Male ? globalStyles.trHisLifeExpectancy : globalStyles.trHerLifeExpectancy}></Box>
              <Typography variant="body2" style={globalStyles.legendLabel}>{futureCalc.person1.nickname}'s Life Expectancy</Typography>
            </React.Fragment>
          }
          {futureCalc.person2 &&
            <React.Fragment>
              <Box width={14} height={14} sx={futureCalc.person2.gender === Gender.Male ? globalStyles.trHisLifeExpectancy : globalStyles.trHerLifeExpectancy}></Box>
              <Typography variant="body2" style={globalStyles.legendLabel}>{futureCalc.person2.nickname}'s Life Expectancy</Typography>
            </React.Fragment>
          }
          <Box width={14} height={14} sx={globalStyles.trAssetsDepleted}></Box>
          <Typography variant="body2" style={globalStyles.legendLabel}>Liquid Assets Final Year</Typography>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" width="100%">
          <TableHead>
            <TableRow>
              {/*<TableCell width={30}></TableCell>*/}
              <TableCell key="year">Year</TableCell>
              {series.map((s: ISeries, index: number) =>
                <TableCell align="right" key={`h${index}`}>{s.name}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {series[0].data.map((item: {x: number, y: number}, index: number) => {
              let rowStyle
              const year = item.x
              const fy = futureCalc.futureYears[index]
              if (year === futureCalc.latestRetirementYear) {
                rowStyle = globalStyles.trRetired
              } else if (futureCalc.person1 && year === futureCalc.person1!.lifeExpectancyYear) {
                rowStyle = (futureCalc.person1.gender === Gender.Male) ? globalStyles.trHisLifeExpectancy : globalStyles.trHerLifeExpectancy
              } else if (futureCalc.person2 && year === futureCalc.person2!.lifeExpectancyYear) {
                rowStyle = (futureCalc.person2.gender === Gender.Male) ? globalStyles.trHisLifeExpectancy : globalStyles.trHerLifeExpectancy
              } else if (fy.currentLiquidAssets > 0 && fy.netLiquidAssets <= 0) {
                rowStyle = globalStyles.trAssetsDepleted
              }

            return (
                <TableRow selected={currentYear === item.x} key={`row${index}`} style={rowStyle}
                          onClick={() => {handleChange(item.x)}}>
                  <TableCell key={`r${index}c0`}>{item.x}</TableCell>
                  <TableCell  align="right" key={`r${index}c1`}>{numberToMoneyFormat(series[0].data[index].y, 0)}</TableCell>
                  <TableCell  align="right" key={`r${index}c2`}>{numberToMoneyFormat(series[1].data[index].y, 0)}</TableCell>
                  <TableCell  align="right" key={`r${index}c3`}>{numberToMoneyFormat(series[2].data[index].y, 0)}</TableCell>
                  <TableCell  align="right" key={`r${index}c4`}>{numberToMoneyFormat(series[3].data[index].y, 0)}</TableCell>
                  <TableCell  align="right" key={`r${index}c5`}>{numberToMoneyFormat(-series[4].data[index].y, 0)}</TableCell>
                </TableRow> )
              }
            )}
            <TableRow key={`totals`}>
              <TableCell sx={styles.total}>Totals</TableCell>
              {totals.map((total: number, index: number) =>
                <TableCell sx={styles.total}  align="right" key={`rtotalc${index}`}>{numberToMoneyFormat(Math.abs(total), 0)}</TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default WithdrawalProjectionTable