import { Box, Divider, MenuList, Popover } from "@mui/material"
import { useState } from "react"
import { INavItem } from "../../NavigationConfig"
import NavButton from "./NavButton"
import NavMenuItem from "./NavMenuItem"

interface INavSelect {
  item: INavItem 
  // onClickButton?: (e: any) => void
  onClickSubNavItem: (e: any, subNavItem: INavItem) => void
}

const NavSelect = (props: INavSelect) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ anchorEl, setAnchorEl ] = useState<Element | null>(null)
  const { item } = props 
  const subNavItems = item.subNavItems! 

  const renderNavButton = (item: INavItem) => {
    return (
      <NavButton 
        navItem={item} 
        onClickButton={(e: React.MouseEvent) => {
          setAnchorEl(e.currentTarget)
          setIsOpen(!isOpen)
          // if (props.onClickButton) {
          //   props.onClickButton(e)
          // }
        }}
        ButtonProps={{
          'aria-label': 'Popover',
          'aria-owns': isOpen ? "mouse-over-popover" : 'undefined', 
          'aria-haspopup': "true"
        }}
      />
    )
  }

  return (
    <Box>
      { renderNavButton(item) }
      <Popover
        open={ isOpen }
        id="mouseover-popover"
        anchorEl={ anchorEl } 
        disableRestoreFocus
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={ () => { // Needed for handling backdrop clicks. 
          setAnchorEl(null)
          setIsOpen(false) 
        }} 
        sx={{
          mt: 2
        }}
      >
        <MenuList>
          {subNavItems.map((item, index) => {
            return (
              <Box
                key={item.route.title}
              >
                {index > 0 && <Divider variant="middle" />}
                <NavMenuItem 
                  navItem={item} 
                  onClickMenuItem={(e: React.MouseEvent, subMenuItem: INavItem) => {
                    setAnchorEl(null)
                    setIsOpen(false)
                    props.onClickSubNavItem(e, subMenuItem)
                  }} 
                />
              </Box>
            )
          })}
        </MenuList>
      </Popover>
    </Box>
  )
}

export default NavSelect 
