import Page from '../components/page/Page'
import {when} from "mobx";
import Logger from "../components/Logger";
import {observer} from "mobx-react";
import RoutesConfig from '../RoutesConfig';
import SignInDialog from '../components/signIn/SignInDialog';
import Tracking from '../components/Tracking';
import {Box, Grid, Paper, Typography, useTheme} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useCallback, useEffect, useState} from 'react';
import {useResources} from '../stores/ResourceProvider';
import PasswordAssistDialog from '../components/signIn/PasswordAssistDialog';
import {UserStatus} from "../API";
import TrialImg from "../images/30daytrial.png";
import CheckAlt from "../images/CheckAlt.png";
import * as React from "react";
import MarginRow from "../components/page/MarginRow";
import Footer from "../components/page/Footer";
import {advisorSettingsKey} from "./calc/CalcPage";
import useNavigationHelper from "../components/NavigationHelper";
import {globalColors} from "../styles/globalStyles";

enum SignInPageModes {
  signIn = 'signIn',
  forgotPassword = 'forgotPassword'
}

interface ISignInPageProps {
  route?: string
}

const SignInPage = observer((props: ISignInPageProps) => {
  const { accountStore, userStore, progress } = useResources()

  const [isLoading, setIsLoading] = useState(true)
  const [mode, setMode] = useState<SignInPageModes>(SignInPageModes.signIn)

  // If a route is set, assume we are navigating to a protected route that requires signIn first.
  // Thus, display the signIn as a modal over the page, until they log in.
  const { route } = props
  const isModal = route !== undefined

  const navigate = useNavigate()
  const {navigateHome} = useNavigationHelper()

  const theme = useTheme()
  const styles = {
    checkBullet: {
      width: 24,
      height: 24,
      margin: theme.spacing(0.5)
    }
  }

  Logger.debug("SignInPage: mode", mode)
  Logger.debug("SignInPage: isModal", isModal)

  const handleNavigate = useCallback(() => {
    let pathname
    if (route) {
      pathname = route
    // } else if (userStore.isAdminOrAgent) {
    //   // TODO: Change to manage later on, send to My Library for testing for now.
    //   pathname = RoutesConfig.myLibrary.pathname
    //   // pathname = RoutesConfig.manage.pathname
    // } else if (userStore.isEmployer) {
    //   pathname = RoutesConfig.myLibrary.pathname
    } else {
      pathname = RoutesConfig.calc.pathname
    }
    Logger.debug(`SignInPage routing to ${pathname}`)
    navigate(pathname)
  }, [navigate, route])

  useEffect(() => {
    // Show progress while the stores are loanding and user authentication is checked in App.startup.
    progress.show("SignInPage")

    when(
      // Once
      () => userStore!.isLoading === false && accountStore!.isLoading === false,
      // Then
      () => {
        Logger.debug("SignInPage: App completed loading")
        progress!.hide("SignInPage")
        setIsLoading(false)
      }
    )
    when(
      // Once
      () => !userStore!.isLoading && !accountStore!.isLoading && userStore!.isAuthenticated,
      // Then
      () => {
        Logger.debug("SignInPage: Completed loading user")
        progress!.hide("SignInPage")
        setIsLoading(false)
        if (userStore.user && userStore.user.userStatus !== UserStatus.Cancelled && userStore.user.userStatus !== UserStatus.Deleted) {
          handleNavigate()
        }
      }
    )
  }, [handleNavigate, accountStore, userStore, navigate, userStore.isLoading, userStore.isAuthenticated, progress])

  const onSignIn = () => {
    Logger.debug('onSignIn')
    handleNavigate()
  }

  const onLegacySignIn = (legacyUser: any) => {
    navigate(RoutesConfig.welcome.pathname, {state: {legacyUser: legacyUser}})
  }

  const onClose = () => {
    navigateHome()
  }

  const onShowPasswordAssist = () => {
    Tracking.pageView("PasswordAssistDialog")
    setMode(SignInPageModes.forgotPassword)
  }

  const onShowSignUp = () => {
    Tracking.pageView("SignupDialog")
    navigate(RoutesConfig.signUp.pathname!)
  }

  const onShowSignIn = () => {
    Tracking.pageView("SignInDialog")
    setMode(SignInPageModes.signIn)
  }

  const renderPromo = () => {

    return (
      <Paper
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          overflow: 'hidden',
          ml: {
            md: 2
          },
          mt: {
            md: 3
          },
          p: 2,
          maxWidth: {
            md: theme.breakpoints.values.lg
          },
          // minHeight: 532
        }}
        elevation={2}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item textAlign="center">
            <Typography variant="body2" sx={{color:globalColors.premium, fontWeight:600}}>
              WELCOME TO
            </Typography>
            <Typography variant="h2" color="primary">Retirement Budget Calculator <b style={{color:globalColors.premium}}>Pro!</b></Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1"><b>Retirement Budget Calculator Pro</b> is more powerful and easier to use than ever, helping you achieve your goals and live the life you want!</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" gutterBottom><b>RBC Pro features:</b></Typography>
            <Typography variant="body1"><img src={CheckAlt} alt="Check" style={styles.checkBullet}/> Completely redesigned, easy to use interface</Typography>
            <Typography variant="body1"><img src={CheckAlt} alt="Check" style={styles.checkBullet}/> Advanced visualizations of your retirement plan</Typography>
            <Typography variant="body1"><img src={CheckAlt} alt="Check" style={styles.checkBullet}/> Retirement Optimizer to fine-tune your plan</Typography>
            <Typography variant="body1"><img src={CheckAlt} alt="Check" style={styles.checkBullet}/> Retirement Intelligence now available 24/7</Typography>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  return (
    <Page title="Sign in">
      <MarginRow maxWidth={theme.breakpoints.values.xl}>
        { isLoading === false &&
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="top"
            sx={{
              minHeight: 'calc(100vh - 206px)',
              my: {
                sm: 0,
                md: "auto"
              },
              px: {
                sm: 2,
                md: 0
              },
              py: {
                sm: 0,
                md: 4,
              },
            }}
            spacing={6}
          >
            <Grid item md={6} sx={{display: {xs:'none', sm:'none', md:'block'}}}>
              {renderPromo()}
            </Grid>
            <Grid item sm={12} md={6}>
              { mode === 'signIn' &&
                <SignInDialog
                  onSignIn={onSignIn}
                  onLegacySignIn={onLegacySignIn}
                  onClose={onClose}
                  onShowPasswordAssist={onShowPasswordAssist}
                  onShowSignUp={onShowSignUp}
                  isShownAsModal={ false }
                />
              }
              { mode === 'forgotPassword' &&
                <PasswordAssistDialog
                  onClose={onClose}
                  onShowSignIn={onShowSignIn}
                  isShownAsModal={ false }
                />
              }
            </Grid>
          </Grid>
        }
        <Footer/>
      </MarginRow>
    </Page>
  )

})

export default SignInPage