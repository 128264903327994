import React, {useEffect, useState} from 'react'
import Chart from "react-apexcharts";
import {numberToMoneyFormat, numberToPercentFormat} from "../../../../stores/StoreUtilities";
import {globalColors} from "../../../../styles/globalStyles"
import {Box} from "@mui/material";
import cloneDeep from "lodash.clonedeep";
import Asset, {Risk} from "../../../../model/Asset";

const defaultOptions = {
  chart: {
    id: "annual-budget",
    type: 'bar',
    height: 350,
    width: "100%",
    zoom: {
      autoScaleYaxis: true
    },
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      }
    },
    toolbar: {
      show: true,
      tools: {
        download: false
      }
    }
  },
  title: {
    text: "",
    align: "right",
    margin: 0,
    floating: true,
    style: {
      fontSize: "16px",
      fontFamily: "Poppins,sans-serif",
      color: "rgb(31, 92, 167)"
    }
  },
  dataLabels: {
    enabled: true,
    formatter: function (value: number) {
      return numberToMoneyFormat(value, 0)
    },
    offsetY: -20,
    style: {
      fontSize: '12px',
      colors: ["#304758"]
    }
  },
  legend: {
    show: false,
    position: 'top',
    horizontalAlign: 'left'
  },
  xaxis: {
    axisBorder: {
      show: true
    }
  },
  yaxis: {
    // max: 15000,
    labels: {
      formatter: (value: number) => {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  tooltip: {
    y: {
      formatter: function (value: number) {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  plotOptions: {
    bar: {
      distributed: true,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    }
  },
  colors: [globalColors.categorical[0], globalColors.categorical[5], globalColors.categorical[4], globalColors.categorical[9]],
  responsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const LiquidAssetRiskAllocationChart = ({
  liquidAssets,
  height,
  width,
  title
}: {
  liquidAssets: Asset[]
  height?: string
  width?: string
  title?: string
}) => {
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<any>()
  const [data, setData] = useState<any>()

  useEffect(() => {
    calculateData()
  }, [liquidAssets])

  const calculateData = () => {
    if (!liquidAssets) {
      return
    }

    let emergencyAssets = liquidAssets.filter((asset: Asset) => asset.risk === Risk.Emergency)
    let totalEmergency = emergencyAssets.reduce((accum: number, item: Asset) => accum + item.balance, 0)
    let lowRiskAssets = liquidAssets.filter((asset: Asset) => asset.risk === Risk.Low)
    let totalLowRisk = lowRiskAssets.reduce((accum: number, item: Asset) => accum + item.balance, 0)
    let moderateRiskAssets = liquidAssets.filter((asset: Asset) => asset.risk === Risk.Moderate)
    let totalModerateRisk = moderateRiskAssets.reduce((accum: number, item: Asset) => accum + item.balance, 0)
    let aggressiveRiskAssets = liquidAssets.filter((asset: Asset) => asset.risk === Risk.Agressive)
    let totalAggressiveRisk = aggressiveRiskAssets.reduce((accum: number, item: Asset) => accum + item.balance, 0)

    const totalAssets = totalEmergency + totalLowRisk + totalModerateRisk + totalAggressiveRisk
    const emergencyLabel = totalAssets > 0 ? `Emergency: ${numberToPercentFormat(totalEmergency / totalAssets, 0)}` : `Emergency`
    const lowRiskLabel = totalAssets > 0 ? `Low: ${numberToPercentFormat(totalLowRisk / totalAssets, 0)}` : `Low`
    const moderateRiskLabel = totalAssets > 0 ? `Moderate: ${numberToPercentFormat(totalModerateRisk / totalAssets, 0)}` : `Moderate`
    const aggressiveRiskLabel = totalAssets > 0 ? `Aggressive: ${numberToPercentFormat(totalAggressiveRisk / totalAssets, 0)}` : `Aggressive`

    let data = [
      {x: emergencyLabel, y: totalEmergency},
      {x: lowRiskLabel, y: totalLowRisk},
      {x: moderateRiskLabel, y: totalModerateRisk},
      {x: aggressiveRiskLabel, y: totalAggressiveRisk},
    ]
    setData(data)

    const options = cloneDeep(defaultOptions)
    options.title.text = title ?? ""

    const series = [
      {
        data: data
      },
    ]
    setSeries(series)

    if (height || width) {
      options.chart.toolbar.show = false
    }

    setOptions(options)
  }


  if (options && series) {
    return (
      <React.Fragment>
        <Box>
          <Chart
            options={options}
            series={series}
            type="bar"
            height={height ?? "500"}
            width={width ?? "100%"}
          />
        </Box>
      </React.Fragment>
    )
  } else {
    return null
  }
}

export default LiquidAssetRiskAllocationChart