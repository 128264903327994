import React, {useEffect, useState} from 'react'
import Chart from "react-apexcharts";
import {INetWorthCalc} from "../../../../components/calculator/Calculator";
import {globalColors} from "../../../../styles/globalStyles"
import {compareDesc, startOfTomorrow} from "date-fns";
import cloneDeep from "lodash.clonedeep";
import {dateToLocalFormat} from "../../../../stores/StoreUtilities";
import {useTheme} from "@mui/material";

const defaultOptions = {
  chart: {
    id: "area-datetime",
    type: 'area',
    stacked: true,
    height: 350,
    width: "100%",
    zoom: {
      enabled: false,
      autoScaleYaxis: true
    },
    // sparkline: {
    //   enabled: true
    // },
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      }
    },
    toolbar: {
      show: false,
      tools: {
        download: false
      }
    },
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    position: 'top', // TODO: Figure out why this isn't working
    horizontalAlign: 'left',
    onItemClick: {
      toggleDataSeries: false
    }
  },
  markers: {
    size: 5,
    style: 'hollow',
  },
  xaxis: {
    type: 'datetime',
    min: new Date('01 Jan 2020').getTime(),
    tickAmount: 6,
    axisBorder: {
      show: true
    }
  },
  tooltip: {
    x: {
      format: 'dd MMM yyyy'
    }
  },
  colors: [globalColors.liability, globalColors.otherAsset, globalColors.personalAsset, globalColors.realAsset, globalColors.liquidAsset],
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100]
    }
  },
  stroke: {
    width: 3
  },
  resonsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const AssetLiabilityApexChart = ({
  netWorthCalcs,
  selected,
  onSelect
}: {
  netWorthCalcs: INetWorthCalc[]
  selected?: number
  onSelect?(index: number): void
}) => {
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<any>()

  const theme = useTheme()

  useEffect(() => {
    if (netWorthCalcs.length > 0) {
      const newOptions = cloneDeep(defaultOptions)

      const minDate = netWorthCalcs.reduce((accum: Date, item: INetWorthCalc) => compareDesc(accum, item.date) > 0 ? accum : item.date, new Date())
      if (minDate) {
        newOptions.xaxis.min = minDate.getTime()
      }

      if (selected !== undefined) {
        const calc = netWorthCalcs[selected]
        const annotations = {
          xaxis: [
            {
              x: calc.date.getTime(),
              borderColor: theme.palette.secondary.main, //'#00E396',
              label: {
                borderColor: theme.palette.secondary.main, //'#00E396',
                orientation: 'horizontal',
                text: dateToLocalFormat(calc.date, "MMM d, yyyy"),
                style: {
                  fontSize: '14px',
                  fontWeight: 600
                }
              }
            }
          ]
        }
        newOptions["annotations"] = annotations
        newOptions.chart.events["markerClick"] = (event: any, chartContext: any, details: {dataPointIndex: number, seriesIndex: number}) => {
          if (details.dataPointIndex >= 0 && onSelect) {
            onSelect(details.dataPointIndex)
          }
        }
      }
      setOptions(newOptions)

      const liquidData = netWorthCalcs.map((item: INetWorthCalc) => [new Date(item.date).getTime(), item.liquidAssetsTotal.toString()])
      const realData = netWorthCalcs.map((item: INetWorthCalc) => [new Date(item.date).getTime(), item.realAssetsTotal.toString()])
      const personalData = netWorthCalcs.map((item: INetWorthCalc) => [new Date(item.date).getTime(), item.personalAssetsTotal.toString()])
      const otherData = netWorthCalcs.map((item: INetWorthCalc) => [new Date(item.date).getTime(), item.otherAssetsTotal.toString()])
      const liabilityData = netWorthCalcs.map((item: INetWorthCalc) => [new Date(item.date).getTime(), (-item.liabilitiesTotal).toString()])
      if (netWorthCalcs.length === 1) {
        // Add tomorrow to display graph with one data point
        const time = startOfTomorrow().getTime()
        liquidData.push([time, liquidData[0][1]])
        realData.push([time, realData[0][1]])
        personalData.push([time, personalData[0][1]])
        otherData.push([time, otherData[0][1]])
        liabilityData.push([time, liabilityData[0][1]])
      }

      setSeries([
        {name: "Liabilities", data: liabilityData},
        {name: "Other Assets", data: otherData},
        {name: "Personal Assets", data: personalData},
        {name: "Real Assets", data: realData},
        {name: "Liquid Assets", data: liquidData},
      ])
    }
  }, [netWorthCalcs, selected])

  if (options && series) {
    return (
      <Chart
        options={options}
        series={series}
        type="area"
        height="500"
        width="100%"
      />
    )
  } else {
    return null
  }
}

export default AssetLiabilityApexChart