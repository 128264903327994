import {UserRole, UserStatus, UserType} from "../API";
import Account from "./Account";
import Model, {ModelSetting} from "./Model";
import UserSetting, {UserSettingType} from "./UserSetting";

export enum MFAPreference {
  off = "",
  sms = "sms",
  totp = "totp",
}

export type SubscriptionFrequency = "Monthly" | "Yearly"

class User {
  id: string
  createdAt: string
  updatedAt: string
  userStatus: UserStatus
  userType: UserType
  startDate: string
  endDate: string
  accountId: string
  account?: Account
  firstName: string
  lastName: string
  email: string
  phone: string
  mfaPreference: MFAPreference
  role: UserRole
  advisorAccess: boolean
  lastAccess: string
  customerId: string
  currentModelId: string
  advisorModelId: string
  currentPlanId: string   // Deprecated
  models: Model[]
  migrateStatus: string
  migratedOn: string
  subscribedOn: string
  cancelledOn: string
  settings: UserSetting[]
  referrer: string

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.userStatus = data.userStatus
    this.userType = data.userType ?? UserType.Free
    this.accountId = data.accountId
    this.account = data.account ? new Account(data.account) : undefined
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.email = data.email
    this.phone = data.phone
    this.mfaPreference = data.mfaPreference ?? MFAPreference.off
    this.role = data.role
    this.advisorAccess = data.advisorAccess ?? false
    this.lastAccess = data.lastAccess ?? data.updatedAt
    this.customerId = data.customerId
    this.startDate = data.startDate ?? this.createdAt
    this.endDate = data.endDate
    this.currentModelId = data.currentModelId
    this.advisorModelId = data.advisorModelId
    this.currentPlanId = data.currentPlanId
    this.models = []
    if (data.models && data.models.items) {
      this.loadModels(data.models.items)
    }
    this.migrateStatus = data.migrateStatus
    this.migratedOn = data.migratedOn
    this.subscribedOn = data.subscribedOn
    this.cancelledOn = data.cancelledOn
    this.referrer = data.referrer
    this.settings = []
    if (data.settings && data.settings.items) {
      this.loadSettings(data.settings.items)
    }
  }

  loadModels(items: any[]) {
    this.models = items.map((item: any) => new Model(item))
  }

  loadSettings(items: any[]) {
    this.settings = items.map((item: any) => new UserSetting(item))
  }

  get isAdmin(): boolean {
    return this.role === UserRole.Admin
  }

  get isAdvisor(): boolean {
    return this.role === UserRole.Advisor
  }

  get isAdvisorOrAdmin(): boolean {
    return this.role === UserRole.Advisor || this.role === UserRole.Admin
  }

  get isCustomer(): boolean {
    return this.role === UserRole.Customer
  }

  get fullName(): string {
    return `${this.lastName}, ${this.firstName}`
  }

  get initials(): string {
    const names = this.firstName.split(" & ")
    if (names.length === 2) {
      return `${names[0].substring(0, 1)}${names[1].substring(0, 1)}`.toUpperCase()
    } else {
      return `${this.firstName.substring(0, 1)}${this.lastName.substring(0, 1)}`.toUpperCase()
    }
  }

  filterUserSettings(settingType: UserSettingType) {
    return this.settings.filter((setting: UserSetting) => setting.settingType === settingType)
  }

  updateUserSetting(userSetting: UserSetting) {
    const index = this.settings.findIndex((setting: UserSetting) => setting.id === userSetting.id)
    if (index >= 0) {
      this.settings[index] = userSetting
    } else {
      this.settings.push(userSetting)
    }
  }

}

export default User