import React, {useEffect, useState} from 'react'
import Chart from "react-apexcharts";
import {IBudgetCalc, IExpenseCategorySummary} from "../../../../components/calculator/Calculator";
import {numberToMoneyFormat} from "../../../../stores/StoreUtilities";
import {globalColors} from "../../../../styles/globalStyles"
import {Box} from "@mui/material";
import {ExpenseCategory} from "../../../../model/Expense";
import cloneDeep from "lodash.clonedeep";

const defaultOptions = {
  chart: {
    id: "annual-budget",
    type: 'bar',
    height: 350,
    width: "100%",
    zoom: {
      autoScaleYaxis: true
    },
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      }
    },
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: true,
    formatter: function (value: number) {
      return numberToMoneyFormat(value, 0)
    },
    offsetY: -20,
    style: {
      fontSize: '12px',
      colors: ["#304758"]
    }
  },
  legend: {
    show: false,
    position: 'top',
    horizontalAlign: 'left'
  },
  xaxis: {
    axisBorder: {
      show: true
    }
  },
  yaxis: {
    // max: 15000,
    labels: {
      formatter: (value: number) => {
        return numberToMoneyFormat(value, 0)
        // if (value < 1000000) {
        //    return `$${value / 1000}K`
        // } else {
        //    return `$${value / 1000000}M`
        // }
      }
    }
  },
  tooltip: {
    y: {
      formatter: function (value: number) {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  plotOptions: {
    bar: {
      distributed: true,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    }
  },
  colors: [globalColors.income, globalColors.deductionExpense, globalColors.taxExpense, ...globalColors.expenseCategory.slice(1)],
  responsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const AnnualBudgetWidget = ({
  budgetCalc,
  view,
  height,
  width
}: {
  budgetCalc: IBudgetCalc
  view: 'Annual' | 'MonthlyAvg'
  height?: string
  width?: string
}) => {
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<any>()
  const [data, setData] = useState<any>()
  const [divisor, setDivisor] = useState<number>(1)

  useEffect(() => {
    setDivisor(view === 'MonthlyAvg' ? 12 : 1)
    calculateData()
  }, [budgetCalc, view])

  const calculateData = () => {
    if (!budgetCalc) {
      return
    }

    let data = [
      {x: 'Income', y: budgetCalc.totalIncome},
      {x: 'Deductions', y: budgetCalc.totalDeductions},
      {x: 'Taxes', y: budgetCalc.totalEstimatedTaxes ?? budgetCalc.totalTaxes},
      {x: 'Essential', y: budgetCalc.totalEssentialExpenses},
      {x: 'Discretionary', y: budgetCalc.totalDiscretionaryExpenses},
    ]
    setData(data)

    let chartData
    if (divisor === 1) {
      chartData = data
    } else {
      chartData = data.map((item: {x: string, y: number}) => {return ({x: item.x, y: Math.round(item.y / divisor)})})
    }

    const options = cloneDeep(defaultOptions)
    const income = chartData[0].y
    // const maxExpense = Math.max(chartData[1].y, chartData[2].y, chartData[3].y, chartData[4].y)
    // if ((maxExpense / income) < 0.8) {
    //   const yMax = Math.ceil(maxExpense * 1.1 / 1000) * 1000
    //   options.yaxis['max'] = yMax
    //   chartData[0]['goals'] = [
    //     {
    //       name: 'Abbreviated',
    //       value: yMax/2,
    //       strokeHeight: 5,
    //       strokeColor: '#FFFFFF'
    //     },
    //     {
    //       name: 'Max',
    //       value: yMax,
    //       strokeHeight: 11,
    //       strokeColor: '#FFFFFF'
    //     }
    //   ]
    // }

    const series = [
      {
        data: chartData
      },
    ]
    setSeries(series)
    setOptions(options)
  }

  const getExpenseCategoryTotal = (category: ExpenseCategory) => {
    let total = 0
    if (budgetCalc) {
      const summary = budgetCalc.expenseSummary.byCategory.find((summary: IExpenseCategorySummary) => summary.category === category)
      if (summary) {
        total = summary.total
      }
    }
    return total
  }

  if (options && series) {
    return (
      <React.Fragment>
        {/*<TableContainer component={Paper} sx={{paddingTop:1}}>*/}
        <Box display="flex" flexGrow={1} justifyContent="stretch">
          <Chart
            options={options}
            series={series}
            type="bar"
            height={height ?? "500"}
            width={width ?? "100%"}
          />
        </Box>
        {/*</TableContainer>*/}
      </React.Fragment>
    )
  } else {
    return null
  }
}

export default AnnualBudgetWidget