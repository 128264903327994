import RbcAPI from "../apis/RbcAPI";
import {makeObservable, observable} from "mobx";
import Account from "../model/Account";
import * as APITypes from "../API";
import User from "../model/User";
import {CreateUserInviteInput, ModelUserFilterInput, ModelUserInviteFilterInput, UpdateUserInviteInput} from "../API";
import isEqual from "lodash.isequal";
import Person from "../model/Person";
import UserInvite from "../model/UserInvite";
import {generateUniqueCode, getISODateTime, getISODateToday} from "./StoreUtilities";

export const AccountStoreConstants = {
  PRIMARY_ACCOUNT_ID: "primary"
}

class AccountStore {
  rbcAPI: RbcAPI

  @observable account?: Account
  @observable isLoading = false
  private userFilter: ModelUserFilterInput | undefined
  private users: User[] = []

  constructor(options: any) {
    makeObservable(this);
    this.rbcAPI = (options && options.rbcAPI) ? options.rbcAPI : null
  }

  async loadAccount(accountId: string) {
    this.isLoading = true
    // Uncache users
    this.users = []
    const account = await this.rbcAPI.getAccount(accountId)
    if (account) {
      this.account = new Account(account)
      await this.initAccount()
    } else {
      if (accountId === AccountStoreConstants.PRIMARY_ACCOUNT_ID) {

      }
    }
    this.isLoading = false
    return this.account
  }

  init(account?: Account) {
    if (this.account && account && this.account.id !== account.id) {
      this.users = []
      this.userFilter = undefined
    }
    this.account = account
    console.log("Account inited")
    return account
  }

  async initAccount() {
    if (!this.account) {
      return
    }
  }

  // Account methods

  async createAccount(input:  APITypes.CreateAccountInput): Promise<Account | undefined> {
    const account = await this.rbcAPI!.createAccount(input)
      .catch((err) => {
        return undefined
      })

    if (account) {
      this.isLoading = false
      return new Account(account)
    } else {
      return undefined
    }
  }

  async updateAccount(input:  APITypes.UpdateAccountInput) {
    const result = await this.rbcAPI!.updateAccount(input)
    if (result) {
      const account = new Account(result)
      if (this.account && this.account.id === account.id) {
        if (!account.users || account.users.length === 0) {
          account.users = this.account!.users
        }
        this.account = account
      }
      return account
    } else {
      return null
    }
  }

  listUsersByAccount = async (accountId: string, filter?: APITypes.ModelUserFilterInput): Promise<User[]> => {
    if (this.users.length > 0 && this.account && this.account.id === accountId) {
      if (isEqual(filter, this.userFilter)) {
        console.log(`Using cached users`)
        return this.users
      }
    }

    let users: User[] = []

    let data
    let nextToken: string | undefined

    do {
      console.log(`listUsersByAccount`)
      data = await this.rbcAPI.listUsersByAccount(accountId, filter, 1000, nextToken)
      if (data && data.items) {
        data.items.forEach((item: any) => {
          const user = new User(item)
          users.push(user)
        })
        nextToken = data.nextToken ?? undefined
      }
    } while (data && nextToken)

    // Cache
    this.users = users
    this.userFilter = filter

    return users
  }

  listUserInvitesByAccount = async (accountId: string, filter?: APITypes.ModelUserInviteFilterInput): Promise<UserInvite[]> => {
    let userInvites: UserInvite[] = []

    let data
    let nextToken: string | undefined

    do {
      console.log(`listUserInvitessByAccount`)
      data = await this.rbcAPI.listUserInvitesByAccount(accountId, filter, 1000, nextToken)
      if (data && data.items) {
        data.items.forEach((item: any) => {
          const userInvite = new UserInvite(item)
          userInvites.push(userInvite)
        })
        nextToken = data.nextToken ?? undefined
      }
    } while (data && nextToken)

    return userInvites
  }

  getUserInvite = async (id: string) => {
    const data = await this.rbcAPI.getUserInvite(id)
    if (data) {
      return new UserInvite(data)
    } else {
      return undefined
    }
  }

  async sendUserInvite(user: User) {
    let invite: UserInvite | undefined
    // Check for existing UserInvite
    const filter: ModelUserInviteFilterInput = {
      userId: {eq: user.id}
    }
    const invites = await this.listUserInvitesByAccount(user.accountId, filter)
    if (invites.length === 0) {
      const input: CreateUserInviteInput = {
        id: generateUniqueCode(6),
        accountId: user.accountId,
        userId: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.role,
        invitedOn: getISODateTime()
      }
      const data = await this.rbcAPI.createUserInvite(input)
      if (data) {
        invite = new UserInvite(data)
      }
    } else {
      invite = invites[0]
      // Update invite
      const input : UpdateUserInviteInput = {
        id: invite.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.role,
        invitedOn: getISODateTime()
      }
      const data = await this.rbcAPI.updateUserInvite(input)
      if (data) {
        invite = new UserInvite(data)
      }
    }
    return invite
  }

  async revokeUserInvite(user: User) {
    let invite: UserInvite | undefined
    // Check for existing UserInvite
    const filter: ModelUserInviteFilterInput = {
      userId: {eq: user.id}
    }
    const invites = await this.listUserInvitesByAccount(user.accountId, filter)
    if (invites.length > 0) {
      const data = await this.rbcAPI.deleteUserInvite(invites[0].id)
      if (data) {
        invite = new UserInvite(data)
      }
    }
    return invite
  }

  updateUser = (user: User) => {
    const index = this.users.findIndex((u: User) => u.id === user.id)
    if (index >= 0) {
      const users = [...this.users]
      users[index] = user
      this.users = users
    } else {
      this.users = [...this.users, user]
    }
  }

  deleteUser = async (userId: string): Promise<User | undefined> => {
    const data = await this.rbcAPI.deleteUser(userId)
    if (data) {
      const index = this.users.findIndex((u: User) => u.id === userId)
      if (index >= 0) {
        const users = [...this.users]
        users.splice(index, 1)
        this.users = users
      }
      return new User(data)
    } else {
      return undefined
    }
  }

}

export default AccountStore