import {FutureCalc, IFutureYear} from "../../../../components/calculator/Calculator";
import React, {useEffect, useState} from "react";
import {CircularProgress, Grid, Paper, TableContainer} from "@mui/material";
import UserSetting, {IScenario} from "../../../../model/UserSetting";
import {useResources} from "../../../../stores/ResourceProvider";
import Model from "../../../../model/Model";
import Plan from "../../../../model/Plan";
import ScenarioComparisonChart from "./ScenarioComparisonChart";
import isEqual from "lodash.isequal";


const ScenarioComparison = ({
  userSetting,
  futureCalc
} : {
  userSetting?: UserSetting
  futureCalc: FutureCalc
}) => {
  const [scenarios, setScenarios] = useState<IScenario[]>([])
  const [categories, setCategories] = useState<string[]>([])
  const [moneySeries, setMoneySeries] = useState<any>()
  const [depletedSeries, setDepletedSeries] = useState<any>()
  const [minYear, setMinYear] = useState<number>(0)
  const [maxYear, setMaxYear] = useState<number>(0)
  const [ssIncomeSeries, setSSIncomeSeries] = useState<any>()
  const [taxSeries, setTaxSeries] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { modelStore, calculator, notify } = useResources()

  const styles = {
    progress: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      width: "100%",
      justifyContent: "space-evenly",
      alignItems: "center",
      marginTop: "calc(50vh - 200px)",
      marginLeft: "auto",
      marginRight: "auto"
    }
  }

    useEffect(() => {
      const scenes = userSetting?.settings["scenarios"] ?? []
      if (!isEqual(scenes, scenarios) ||
        (futureCalc && scenarios.find((s: IScenario) => s.modelId === futureCalc.model!.id && s.planId === futureCalc.model!.appliedPlan!.id))) {
        console.log(`ScenarioComparison update needed`)
        calculateData()
      } else {
        console.log(`ScenarioComparison update not needed`)
      }
  }, [userSetting, futureCalc])

  const calculateData = async () => {
    try {
      setIsLoading(true)
      const calcs: FutureCalc[] = []
      const scenes: IScenario[] = userSetting?.settings["scenarios"] ?? []
      const models = new Array<Model | undefined>()
      const plans = new Array<Plan | undefined>()
      let firstYear = (new Date()).getFullYear()
      let lastYear = 0
      let numScenarios = 0

      // Get models, plans and latestLifeExpectancyYear
      for (let scene of scenes) {
        let model = await modelStore.getModel(scene.modelId, false, true)
        let plan
        if (model) {
          plan = model.plans.find((p: Plan) => p.id === scene.planId)
          if (plan) {
            plan.applyChanges(model)
            const latestLifeExpectancyYear = model.getLatestLifeExpectancyYear()
            if (latestLifeExpectancyYear > lastYear) {
              lastYear = latestLifeExpectancyYear
            }
          } else {
            model = undefined
          }
        }
        models.push(model)
        plans.push(plan)
        ++numScenarios
      }
      setScenarios(scenes)

      const moneyData = new Array<number>(numScenarios)
      const depletedData = new Array<number>(numScenarios)
      const ssIncomeData = new Array<number>(numScenarios)
      const taxData = new Array<number>(numScenarios)
      const labels = new Array<string>(numScenarios)
      const totalLabels = new Array<string>(numScenarios)

      // Calculate future for every scenario
      for (let n = 0; n < scenes.length; n++) {
        const model = models[n]
        const plan = plans[n]
        if (model && plan) {
          const label = `${model.name} / ${plan.name}`
          const calc = calculator.calculateFuture(model, label, lastYear)
          calcs.push(calc)
          const finalYear = calc.futureYears[calc.futureYears.length - 1]

          labels[n] = label
          moneyData[n] = finalYear.netLiquidAssets

          let depletedYear = calc.futureYears.find((fy: IFutureYear) => fy.netLiquidAssets === 0)
          if (!depletedYear) {
            depletedYear = finalYear
            totalLabels[n] = `${depletedYear.year}+`
          } else {
            totalLabels[n] = String(depletedYear.year)
          }
          depletedData[n] =  depletedYear.year

          let ssIncome = calc.futureYears.reduce((accum: number, item: IFutureYear) => {
            return (accum + (item.taxInput!.income.totalSocialSecurityBenefits ?? 0))
          }, 0)
          ssIncomeData[n] = ssIncome

          let totalTax = calc.futureYears.reduce((accum: number, item: IFutureYear) => {
            return (accum + (item.taxEstimate?.totalEstimatedTax ?? 0))
          }, 0)
          taxData[n] = totalTax
        }
      }

      setCategories(labels)
      setMinYear(firstYear)
      setMaxYear(lastYear)

      // Money Remaining
      setMoneySeries([{
        name: "Money Remaining",
        data: moneyData
      }])

      setDepletedSeries([{
        name: "Year Depleted",
        data: depletedData,
        totalLabels: totalLabels
      }])

      // Social Security Income
      setSSIncomeSeries([{
        name: "Income",
        data: ssIncomeData
      }])

      // Lifetime Taxes
      setTaxSeries([{
        name: "Tax",
        data: taxData
      }])
    } catch(err: any) {
      notify.show("error", err.message)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading || !categories || !moneySeries || !depletedSeries || !ssIncomeSeries || !taxSeries) {
    return (
      <CircularProgress sx={styles.progress} />
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <ScenarioComparisonChart title="Money Remaining At Life Expectancy" categories={categories} series={moneySeries} minRange={0} format="money"/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <ScenarioComparisonChart title="Year Money Is Depleted" categories={categories} series={depletedSeries} minRange={minYear} format="integer"/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <ScenarioComparisonChart title="Lifetime Social Security Income" categories={categories} series={ssIncomeSeries} minRange={0} format="money"/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <ScenarioComparisonChart title="Lifetime Income Tax" categories={categories} series={taxSeries} minRange={0} format="money"/>
      </Grid>
    </Grid>
  )
}

export default ScenarioComparison