import get from "lodash.get"
import RoutesConfig, { IRouteData } from "./RoutesConfig"

/*
  We can have different navigation menu items per product within a given site. 
  For example, govgig.us/docs has a different menu than govgig.us/jobs. 
  The "default" menu items will be used if there are no need to change the menu. 
*/
export interface INavConfig {
  unauthenticated: {
    [pathname: string | '/*'] : { navItems: INavItem[] } 
  }
  authenticated: {
    [role: string | 'defaultRole'] : {
      [pathname: string | '/*'] : { navItems: INavItem[] } 
    }
  }
}

export interface INavItem {
  route: IRouteData, 
  isEmphasized?: boolean // Use brighter styling for this button to make it stand out. 
  icon?: string // TODO: Figure out how to load and pass any arbitrary icon. 
  subNavItems?: INavItem[] // When a menu item has a further drop down of items?
}

export class NavigationConfigHelpers {
  static getNavItems = (isAuthenticated: boolean, role: string | undefined, pathname: string | undefined, navData?: INavConfig) => {
    let navItems: INavItem[] = []
    let authenticationSubset: any 

    const getPathItems = (subset: any, pathname?: string) => {
      if (pathname) {
        // Note: Handle only the first chunk of a pathname for now. 
        const index = pathname.indexOf('/', 1)
        // console.log(index)
        const basePath = index === -1 ? pathname : pathname.substring(0, index)
        // console.log(basePath)
        let items = get(subset, `${basePath}.navItems`)
        if (!items) {
          // The pathname may not have items defined, and that is okay, so return defaults. 
          items = get(subset, '[/*].navItems')
        }
        return items 
      } else {
        return get(subset, '[/*].navItems')
      }
    }

    if (isAuthenticated) {
      authenticationSubset = get(navData ?? NavConfig, 'authenticated') 
      let roleSubset = get(authenticationSubset, role ? `${role}` : 'defaultRole')
      if (!roleSubset) {
        // An unhandled role, grab default items.
        roleSubset = get(authenticationSubset, 'defaultRole')
      }
      navItems = getPathItems(roleSubset, pathname)
    } else {
      authenticationSubset = get(navData ?? NavConfig, 'unauthenticated')
      navItems = getPathItems(authenticationSubset, pathname)
    }
    
    return navItems
  }
}

const NavConfig: INavConfig = {
  unauthenticated: {
    '/*': {
      navItems: [
        {
          route: RoutesConfig.home
        },
        {
          route: RoutesConfig.signIn,
        }
      ]
    }
  },
  authenticated: {
    'defaultRole': {
      '/*': {
        navItems: [
          {
            route: RoutesConfig.home
          },
          {
            route: RoutesConfig.calc
          },
          {
            route: RoutesConfig.community
          },
          {
            route: RoutesConfig.settings,
            icon: 'AccountCircle', 
            subNavItems: [
              {
                route: RoutesConfig.settings 
              },
              {
                route: RoutesConfig.signOut
              }
            ]
          }
        ]
      }
    },
    'Admin': {
      '/*': {
        navItems: [
          {
            route: RoutesConfig.home
          },
          {
            route: RoutesConfig.calc
          },
          {
            route: RoutesConfig.community
          },
          {
            route: RoutesConfig.admin,
          },
          {
            route: RoutesConfig.settings,
            icon: 'AccountCircle', 
            subNavItems: [
              {
                route: RoutesConfig.settings 
              },
              {
                route: RoutesConfig.signOut
              }
            ]
          }
        ]
      }
    },
    'Advisor': {
      '/*': {
        navItems: [
          {
            route: RoutesConfig.home
          },
          {
            route: RoutesConfig.calc
          },
          {
            route: RoutesConfig.community
          },
          {
            route: {...RoutesConfig.admin, title: "Advisor"},
          },
          {
            route: RoutesConfig.settings,
            icon: 'AccountCircle',
            subNavItems: [
              {
                route: RoutesConfig.settings
              },
              {
                route: RoutesConfig.signOut
              }
            ]
          }
        ]
      }
    },
  }
}

export default NavConfig
