import Scheduled from "./Scheduled";
import Person from "./Person";
import {isoToLocalDate} from "../stores/StoreUtilities";
import IModelItem from "./IModelItem";

export enum IncomeType {
  None = 0,
  WagesSalariesTips = 1,
  TaxExemptInterest = 2,
  TaxableInterest = 3,
  QualifiedDividends = 4,
  NonqualifiedDividends = 5,
  PensionsAndAnnuitiesNonTaxable = 6,
  PensionsAndAnnuitiesTaxable = 7,
  SocialSecurityBenefits = 8,
  LongTermCapitalGainsOrLoss = 9,
  ShortTermCapitalGainsOrLoss = 10,
  OtherIncome = 11,
  TaxableIRADistribution = 12,
  SocialSecurityChildBenefits= 13
}

class Income extends Scheduled implements IModelItem {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  userId: string
  modelId: string
  incomeType: IncomeType
  incomeTypeDef: IncomeTypeDef
  description: string
  amount: number
  infLock: boolean
  annualInf: number
  sortOrder: number
  ownerId: string
  owner?: Person
  survivorPercent: number

  constructor(data: any) {
    super(data)
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.userId = data.userId
    this.modelId = data.modelId
    this.incomeType = data.incomeType ?? IncomeType.None
    this.incomeTypeDef = getIncomeTypeDef(this.incomeType)
    this.description = data.description ?? "Other"
    this.amount = data.amount ?? 0
    this.infLock = data.infLock
    this.annualInf = data.annualInf
    this.sortOrder = data.sortOrder !== undefined ? data.sortOrder : 0
    this.ownerId = data.ownerId
    this.survivorPercent = data.survivorPercent
  }

  get endDate(): Date | undefined {
    let endDate = this.model ? this.model.getDate(this.end) : undefined
    if (!endDate) {
      if (this.owner) {
        if (this.incomeType === IncomeType.WagesSalariesTips) {
          endDate = this.owner.retireDate ? isoToLocalDate(this.owner.retireDate) : undefined
        } else if (this.incomeType === IncomeType.SocialSecurityBenefits) {
          endDate = this.owner.latestLifeExpectancyDate ? isoToLocalDate(this.owner.latestLifeExpectancyDate) : undefined
        } else if (this.incomeTypeDef.requiredExtraFields.includes('survivorAnnuityPercent')) {
          if (this.owner.spouse && this.survivorPercent > 0) {
            endDate = this.owner.latestLifeExpectancyDate ? isoToLocalDate(this.owner.latestLifeExpectancyDate) : undefined
          } else {
            endDate = this.owner.lifeExpectancyDate ? isoToLocalDate(this.owner.lifeExpectancyDate) : undefined
          }
        } else {
          endDate = this.owner.lifeExpectancyDate ? isoToLocalDate(this.owner.lifeExpectancyDate) : undefined
        }
      }
    }
    return endDate
  }

  get endYear(): number {
    const date = this.endDate
    if (date) {
      return date.getFullYear()
    } else {
      return 0
    }
  }

}

export class IncomeTypeDef {
  incomeType: IncomeType
  label: string
  allowJoint: boolean
  defaultEndDate: 'retireDate' | 'lifeExpectancy'
  requiredExtraFields: string[]
  
  constructor (data: any) {
    this.incomeType = data.incomeType ?? IncomeType.OtherIncome
    this.label = data.label ?? this.incomeType.toString()
    this.allowJoint = data.allowJoint !== false
    this.defaultEndDate = data.defaultEndDate ?? 'lifeExpectancy'
    this.requiredExtraFields = data.requiredExtraFields ?? []
  }
}

const IncomeTypeDefs: IncomeTypeDef[] = [
  new IncomeTypeDef({incomeType: IncomeType.None,
    label: "None",
    defaultEndDate: 'retireDate'
  }),
  new IncomeTypeDef({incomeType: IncomeType.WagesSalariesTips,
    label: "Wages, Salary, or Tips",
    defaultEndDate: 'retireDate'
  }),
  new IncomeTypeDef({incomeType: IncomeType.TaxExemptInterest,
    label: "Tax Exempt Interest",
    defaultEndDate: 'lifeExpectancy'
  }),
  new IncomeTypeDef({incomeType: IncomeType.TaxableInterest,
    label: "Taxable Interest",
    defaultEndDate: 'lifeExpectancy'
  }),
  new IncomeTypeDef({incomeType: IncomeType.QualifiedDividends,
    label: "Qualified Dividends",
    defaultEndDate: 'lifeExpectancy'
  }),
  new IncomeTypeDef({incomeType: IncomeType.NonqualifiedDividends,
    label: "Nonqualified Dividends",
    defaultEndDate: 'lifeExpectancy'
  }),
  new IncomeTypeDef({incomeType: IncomeType.PensionsAndAnnuitiesNonTaxable,
    label: "Pensions & Annuities (non-taxable)",
    defaultEndDate: 'lifeExpectancy',
    requiredExtraFields: ["survivorAnnuityPercent"]
  }),
  new IncomeTypeDef({incomeType: IncomeType.PensionsAndAnnuitiesTaxable,
    label: "Pensions & Annuities (taxable)",
    defaultEndDate: 'lifeExpectancy',
    requiredExtraFields: ["survivorAnnuityPercent"]
  }),
  new IncomeTypeDef({incomeType: IncomeType.SocialSecurityBenefits,
    label: "Social Security Benefits",
    allowJoint: false,
    defaultEndDate: 'lifeExpectancy'
  }),
  new IncomeTypeDef({incomeType: IncomeType.LongTermCapitalGainsOrLoss,
    label: "Long Term Capital Gains",
    defaultEndDate: 'lifeExpectancy'
  }),
  new IncomeTypeDef({incomeType: IncomeType.ShortTermCapitalGainsOrLoss,
    label: "Short Term Capital Gains",
    defaultEndDate: 'lifeExpectancy'
  }),
  new IncomeTypeDef({incomeType: IncomeType.OtherIncome,
    label: "Other Income",
    defaultEndDate: 'lifeExpectancy'
  }),
  new IncomeTypeDef({incomeType: IncomeType.TaxableIRADistribution,
    label: "Taxable IRA Distribution",
    defaultEndDate: 'lifeExpectancy'
  }),
  new IncomeTypeDef({incomeType: IncomeType.SocialSecurityChildBenefits,
    label: "Social Security Child Benefits",
    allowJoint: false,
    defaultEndDate: 'lifeExpectancy'
  }),
]

export function getIncomeTypeDef(incomeType: IncomeType) : IncomeTypeDef {
  const incomeTypeDef = IncomeTypeDefs.find((item: IncomeTypeDef) => item.incomeType === incomeType)
  return incomeTypeDef ?? IncomeTypeDefs[0]
}

export function getIncomeTypeLabel(incomeType: IncomeType): string {
  const incomeTypeDef = IncomeTypeDefs.find((def: IncomeTypeDef) => def.incomeType === incomeType)
  return incomeTypeDef?.label ?? ""
}


export default Income