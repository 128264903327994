import Scheduled from "./Scheduled";
import Person from "./Person";
import {isoToLocalDate} from "../stores/StoreUtilities";
import IModelItem from "./IModelItem";

export enum TaxType {
  None = 0,
  FederalIncomeTax = 1,
  SocialSecurityTax = 2,
  MedicareTax = 3,
  AdditionalTax = 4,
  StateIncomeTax = 5,
  LocalIncomeTax = 6,
  OtherTax = 7
}

class Tax extends Scheduled implements IModelItem {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  userId: string
  modelId: string
  taxType: TaxType
  description: string
  amount: number
  infLock: boolean
  annualInf: number
  sortOrder: number
  ownerId: string
  owner?: Person

  constructor(data: any) {
    super(data)
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.userId = data.userId
    this.modelId = data.modelId
    this.taxType = data.taxType ?? TaxType.None
    this.description = data.description ?? "Other"
    this.amount = data.amount ?? 0
    this.infLock = data.infLock
    this.annualInf = data.annualInf
    this.sortOrder = data.sortOrder
    this.ownerId = data.ownerId
  }

  get endDate(): Date | undefined {
    let endDate = this.model?.getDate(this.end)
    if (!endDate && (this.owner && this.owner.lifeExpectancyDate)) {
      // Default to owner retire date
      endDate = isoToLocalDate(this.owner.lifeExpectancyDate)
    }
    return endDate
  }

  get endYear(): number {
    const date = this.endDate
    if (date) {
      return date.getFullYear()
    } else {
      return 0
    }
  }

}

export default Tax