
export function parseNumber(value: string, defaultValue: any) {
  const num = Number(value)
  return (value === null || Number.isNaN(num) ? defaultValue : num)
}

export function numberToString(num: number | null | undefined, defaultValue: "") {
  return (num === null || Number.isNaN(num) ? defaultValue : num)
}

export function isNumber(num: number | null | undefined): boolean {
  return (num !== null && num !== undefined && !Number.isNaN(num))
}

export function parseString(value: string, defaultValue: any) {
  return ((value === null || typeof value === 'string') ? value : defaultValue)
}

export function parseEnum<T extends {[index:string]:string | number}>(myEnum:T, enumValue:string, defaultValue:any): number | null {
  const value = Number(enumValue)
  let keys = Object.keys(myEnum).filter(x => myEnum[x] === value);
  return keys.length > 0 ? value : defaultValue;
}

export function parseStringEnum<T extends {[index:string]:string | number}>(myEnum:T, enumValue:string, defaultValue:any): string | null {
  let keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
  return keys.length > 0 ? enumValue : defaultValue;
}
