import * as React from 'react';
import Slider from '@mui/material/Slider';
import {useEffect, useState} from "react";
import {useTheme} from "@mui/material";

const YearSlider = ({
  value,
  min,
  max,
  onChange,
}: {
  value: number
  min: number
  max: number
  onChange?(year: number): any
}) => {
  const [current, setCurrent] = useState<number>(value)
  const [marks, setMarks] = useState<Array<{ label?: string, value: number }> | boolean>()

  const theme = useTheme()

  const styles = {
    slider: {
      '& .MuiSlider-thumb': {
        backgroundColor: theme.palette.secondary.main
      },
      '& .MuiSlider-valueLabel': {
        fontSize: 13,
        fontWeight: 700,
        top: 0,
        backgroundColor: 'unset',
        color: theme.palette.primary.main,
        '&:before': {
          display: 'none',
        },
        '& *': {
          background: 'transparent',
          color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary.dark,
        },
      }
    }
  }

  useEffect(() => {
    setCurrent(value)
    // init()
  }, [value, min, max])

  const init = () => {
    let marks = []
    let year = min
    let endYear = max
    while(year <= endYear) {
      marks.push({value: year, label: year.toString()})
      year += 5
    }
    marks.push({value: endYear, label: endYear.toString()})
    setMarks(marks)
  }

  const valuetext = (value: number) => {
    return `${value}`;
  }

  return (
      <Slider
        aria-label="Date Slider"
        value={current}
        getAriaValueText={valuetext}
        step={1}
        min={min}
        max={max}
        marks={true}
        size="small"
        color="primary"
        valueLabelDisplay="on"
        sx={styles.slider}
        onChange={(event: Event, value: number | number[], activeThumb: number) => {
          // console.log(`onChangeSlider(${value}, ${activeThumb}`)
          if (onChange) {
            onChange(value as number)
          }
        }}
      />
  );

}

export default YearSlider