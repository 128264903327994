import * as React from "react";
import {useResources} from "../../stores/ResourceProvider";
import Dialog from "@mui/material/Dialog";
import FormValidator from "../../components/form/FormValidator";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import {useEffect, useState} from "react";
import Model from "../../model/Model";
import DialogActions from "@mui/material/DialogActions";
import {Box} from "@mui/material";
import CancelButton from "../../components/form/CancelButton";
import ProgressButton from "../../components/form/ProgressButton";
import Plan from "../../model/Plan";
import {CreatePlanChangeInput, CreatePlanInput, UpdatePlanInput} from "../../API";
import PlanChange from "../../model/PlanChange";

const NewPlanDialog = ({
  basePlan,
  onClose,
  onCreate
}: {
  basePlan?: Plan
  onClose(): void
  onCreate(model: Model, plan: Plan): void
}) => {
  const [models, setModels] = useState<Model[]>([])
  const [name, setName] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [model, setModel] = useState<Model | undefined>()
  const [plan, setPlan] = useState<Plan | undefined>()
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const {modelStore, notify} = useResources()

  useEffect(() => {
    setModel(modelStore.currentModel)
    setModels(modelStore.models)
    setPlan(basePlan)
  }, [modelStore, modelStore.isLoading, modelStore.models])

  // const getUniqueName = (baseName: string) => {
  //   let num = 2
  //   let newName = baseName
  //   let found = false
  //   do {
  //     found = Boolean(modelStore.models.find((m: Model) => m.name.toLowerCase() === newName.toLowerCase()))
  //     if (found) {
  //       newName = `${baseName} ${num}`
  //       ++num
  //     }
  //   } while (found)
  //   return newName
  // }

  const handleSubmit = async () => {
    if (!model) {
      return
    }

    try {
      setIsProcessing(true)
      const input: CreatePlanInput = {
        accountId: model.accountId,
        userId: model.userId,
        modelId: model.id,
        name: name,
        description: description,
        enabled: true
      }
      let newPlan = await modelStore.createPlan(input)
      if (newPlan) {
        if (plan) {
          // Copy plan changes
          const promises: Promise<PlanChange | undefined>[] = []
          plan.changes.forEach((chg: PlanChange) => {
            const input: CreatePlanChangeInput = {
              userId: newPlan!.userId,
              accountId: newPlan!.accountId,
              modelId: newPlan!.modelId,
              planId: newPlan!.id,
              changeType: chg.changeType,
              name: chg.name,
              description: chg.description,
              enabled: chg.enabled,
              details: chg.details
            }
            promises.push(modelStore.createPlanChange(input))
          })
          const results = await Promise.all(promises)
          // Update plan to force reload/cache update of plan
          const update: UpdatePlanInput = {
            id: newPlan.id,
            accountId: newPlan.accountId,
            userId: newPlan.userId,
            modelId: newPlan.modelId
          }
          newPlan = await modelStore.updatePlan(update)
        }
        onCreate(model, newPlan!)
      }
    } catch (err: any) {
      notify.show("error", `Error creating new plan (${err.message})`)
    } finally {
      setIsProcessing(false)
    }
  }

  if (!models.length) {
    return null
  }

  return (
    <Dialog
      id="newPlanDialog"
      open={true}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="newPlanDialogTitle"
    >
      <FormValidator onSubmit={handleSubmit} autoComplete="off">
        <DialogTitle id="newPlanDialogTitle">Create New Scenario</DialogTitle>
        <DialogContent sx={{my:2}}>
          <TextFieldValidator
            autoFocus
            margin="dense"
            name="name"
            label="Scenario Name"
            type="text"
            validators={{required:true}}
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
            fullWidth
            variant="standard"
          />
          <TextFieldValidator
            margin="dense"
            name="description"
            label="Scenario Description"
            type="text"
            validators={{required:false}}
            value={description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDescription(event.target.value)}
            fullWidth
            variant="standard"
          />
          <TextFieldValidator
            type="text"
            validators={{ required: true }}
            name="basePlan"
            label="Copy Plan"
            variant="standard"
            autocompleteOptions={{
              freeSolo: false,
              options: model ? model.plans : [],
              getOptionLabel: (option: Plan) => option && option.name ? option.name : "None",
              isOptionEqualToValue: (option: Plan, value: Plan) => {
                return (option.id === value.id)
              },
              value: plan,
              onChange: (event: any, value: Plan, reason: any) => {
                setPlan(value)
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="flex-end">
            <CancelButton onClick={onClose} />
            <ProgressButton variant="contained" size="medium" color="primary" sx={{ color: "#fff" }}
                            type="submit" processing={isProcessing}>
              Create
            </ProgressButton>
          </Box>
        </DialogActions>

      </FormValidator>

    </Dialog>
  )

}


export default NewPlanDialog