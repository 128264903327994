import * as React from 'react';
import Button from '@mui/material/Button';
import { SxProps, Theme } from '@mui/material/styles'

const styles = {
    primaryDialogButton: {
      backgroundColor: (theme: Theme) => theme.palette.primary.main,
      color: (theme: Theme) => theme.palette.primary.contrastText,
      marginTop: 0,
      minWidth: 80,
      boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
      "&:hover": {
        color: (theme: Theme) => theme.palette.primary.contrastText,
        backgroundColor: (theme: Theme) => theme.palette.primary.light,
      }
    },
    secondaryDialogButton: {
      backgroundColor: (theme: Theme) => theme.palette.background.paper,
      color: (theme: Theme) => theme.palette.primary.main,
      marginTop: 0,
      minWidth: 80,
      boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px'
    },
    tertiaryDialogButton: {
      // fontWeight: 'bold',
      color: (theme: Theme) => theme.palette.primary.main,
      paddingLeft: (theme: Theme) => theme.spacing(1),
      paddingRight: (theme: Theme) => theme.spacing(1),
      backgroundColor: (theme: Theme) => theme.palette.grey[50],
      boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
    }
} as const 

interface IDialogButtonProps {
    onClick?: any
    type?: string, 
    variant?: any
    disabled?: boolean
    fullWidth?: boolean
    customClassName?: SxProps<Theme>
    id?: string
    size?: 'large' | 'medium' | 'small'
    spacing?: 'left' | 'right'
    children: React.ReactNode
}
  
class DialogButton extends React.Component<IDialogButtonProps> {
      render() {
        const { onClick, disabled, fullWidth, spacing } = this.props

        const typeProp = this.props.type ?? "button"
        const variantProp = this.props.variant || "contained"
        const childrenProp = this.props.children || 'Button'

        let variant: any
        let buttonStyle: SxProps<Theme> = this.props.customClassName ? this.props.customClassName : null
        let color: any
        let type: any
        const size = this.props.size ?? 'small'

        switch (variantProp) {
            case "primary":
                variant = "contained"
                buttonStyle = buttonStyle ? buttonStyle : styles.primaryDialogButton
                color = "primary"
                type = this.props.type ?? "submit"
                break
            case "secondary":
                variant = "outlined"
                buttonStyle = buttonStyle ? buttonStyle : styles.secondaryDialogButton
                color = "primary"
                type = typeProp
                break;
            case "tertiary":
                variant = "text"
                buttonStyle =  buttonStyle ? buttonStyle : styles.tertiaryDialogButton
                color = "primary"
                type = typeProp
                break;
            default:
                variant = variantProp
                buttonStyle = buttonStyle ? buttonStyle : styles.primaryDialogButton
                color = "primary"
                type = typeProp
        }

        if (spacing) {
          if (spacing === 'left') {
            buttonStyle["marginLeft"] = "8px"
          } else {
            buttonStyle["marginRight"] = "8px"
          }
        }
    
        return (
            <Button type={type} 
                size={size}
                variant={variant} 
                color={color} 
                onClick={onClick}
                disabled={disabled}
                fullWidth={fullWidth}
                id={this.props.id}
                sx={buttonStyle}
            >
               {childrenProp}
            </Button>
        )
    }
}

export default DialogButton
