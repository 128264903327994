import {AppBar, Box, Button, IconButton, LinearProgress, Stack, Toolbar, Typography, useTheme} from "@mui/material";
import * as React from 'react';
import {useEffect, useState} from 'react';
import logo from '../../images/HeaderLogoPro.png';
import ChatbotImg from '../../images/Chatbot.png';
import ChatbotOpenImg from '../../images/ChatbotOpen.png'
import MarginRow from "./MarginRow";
import SimpleMenu from "../navigation/SimpleMenu";
import config from 'react-global-configuration';
import {useResources} from "../../stores/ResourceProvider";
import {UserType} from "../../API";
import {globalColors} from "../../styles/globalStyles";
import SubscriptionEditDialog from "../../pages/settings/SubscriptionEditDialog";
import User from "../../model/User";
import RoutesConfig from "../../RoutesConfig";
import {useNavigate} from "react-router-dom";
import useNavigationHelper from "../NavigationHelper";
import Tracking from "../Tracking";

interface IHeaderProps {
  chatOpen?: boolean
  onChange?(chat: boolean): void
}

const Header = ({
  chatOpen,
  onChange
} : IHeaderProps) => {
  const [user, setUser] = useState<User | undefined>()
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [isFree, setIsFree] = useState<boolean>(false)
  const [isClient, setIsClient] = useState<boolean>(false)
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState<boolean>(false)

  const { progress, userStore} = useResources()
  const navigate = useNavigate()
  const {navigateInvest} = useNavigationHelper()

  const theme = useTheme()
  const styles = {
    premiumButton: {
      color: globalColors.primary,
      backgroundColor: globalColors.webFlowButton,
      letterSpacing: "1px",
      textTransform: "capitalize",
      borderRadius: "50px",
      marginLeft: "20px",
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingTop: 0, // "12px",
      paddingBottom: 0, // "12px",
      fontSize: "14px !important",
      fontWeight: 500,
      lineHeight: "20px",
      "&:hover": {
        color: globalColors.webFlowButton,
        backgroundColor: globalColors.primary
      }
    },
    investButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      letterSpacing: "1px",
      textTransform: "capitalize",
      borderRadius: "50px",
      marginLeft: "20px",
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingTop: 0, // "12px",
      paddingBottom: 0, // "12px",
      fontSize: "14px !important",
      fontWeight: 500,
      lineHeight: "20px",
      "&:hover": {
        color: theme.palette.primary.contrastText,
        backgroundColor: globalColors.webFlowButton
      }
    }

  }

  useEffect(() => {
    const user = userStore.user
    setUser(user)
    setIsChatOpen(user !== undefined && chatOpen === true)
    setIsAuthenticated(user !== undefined)
    setIsFree(user !== undefined && user.userType === UserType.Free)
    setIsClient(user !== undefined && user.userType === UserType.Client)
  }, [chatOpen, userStore.user])

  const homeUrl = config.get("homeUrl")

  return (
      <AppBar 
        position="fixed" 
        sx={{
          top: 0,
          backgroundColor: theme.palette.background.default,
          height: 76,
          maxHeight: 76,
          zIndex: theme.zIndex.drawer + 1,
          boxShadow: "none",
          // borderBottomStyle: "solid",
          // borderBottomWidth: 1,
          // borderBottomColor: theme.palette.grey[400]
        }}
      >
        <MarginRow>
          <Toolbar disableGutters={true}
            sx={{
              minHeight: 76,
              height: 76,
              maxHeight: 76,
              width: "100%",
              alignItems: "flex-start",
              paddingTop: "4px"
            }}
          >
            <a href={homeUrl} style={{ marginBottom: '-5px', marginLeft: 5 }}>
              <img src={logo} alt="logo" height={50}/>
            </a>
            <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }} />
            <Stack sx={{display:"flex", flexGrow:1, justifyContent:"flex-end", whiteSpace:"nowrap"}}>
              <Box display="flex" flexGrow={1} m={0} p={0} justifyContent="flex-end" whiteSpace="nowrap">
                <SimpleMenu />
                {!isFree &&
                  <IconButton sx={{marginRight:0, top:-4}}
                              onClick={(event: any) => {
                                if (onChange) {
                                  if (!isChatOpen) {
                                    Tracking.event({action: "Ask Finn Opened"})
                                  }
                                  onChange(!isChatOpen)
                                }
                              }}
                  >
                    {isAuthenticated && isChatOpen &&
                      <img src={ChatbotOpenImg} alt="chatbot" height={36} style={{borderRadius:"20px"}}/>
                    }
                    {isAuthenticated && !isChatOpen &&
                      <img src={ChatbotImg} alt="chatbot" height={36} style={{borderRadius:"20px"}}/>
                    }
                  </IconButton>
                }
              </Box>
              <Box sx={{display:"flex", flexGrow:1, justifyContent:"flex-end", minHeight: 20, height: 20, paddingRight:isAuthenticated? 1 : 4}}>
                {isAuthenticated && !isClient &&
                  <Button type="button" sx={styles.investButton}
                          onClick={() => navigateInvest()}
                  >
                    Discover Your Wealth Manager
                  </Button>
                }
                {isFree &&
                  <Button type="button" sx={styles.premiumButton}
                          onClick={() => {
                            Tracking.event({action: "Click Unlock RBC Premium"})
                            setShowSubscriptionDialog(true)}
                          }
                  >
                    Unlock RBC Premium
                  </Button>
                }
                {!isAuthenticated &&
                  <Button type="button" sx={styles.premiumButton}
                          onClick={() => navigate(RoutesConfig.signUp)}
                  >
                    Get Started
                  </Button>
                }
              </Box>
            </Stack>
          </Toolbar>
        </MarginRow>
        <Box 
          sx={{ 
            flexGrow: 1, 
            height: 3 
          }}
        >
          { progress!.isVisible && 
            <LinearProgress 
              sx={{
                height: 3,
                backgroundColor: "#d72222" // Logo red
              }}
            />
          }
        </Box>
        {showSubscriptionDialog && user &&
          <SubscriptionEditDialog user={user}
                                  open={showSubscriptionDialog}
                                  onSave={(update: User) => {
                                    setUser(update)
                                    setShowSubscriptionDialog(false)
                                  }}
                                  onClose={() => {
                                    setShowSubscriptionDialog(false)
                                  }}/>
        }
      </AppBar>
    )
}

export default Header