import * as React from 'react';
import {inject, observer} from "mobx-react";
import Notify from "./Notify"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import Slide from "@mui/material/Slide/Slide";
import { Theme, Box } from '@mui/material';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const variantAutoHideDuration = {
  success: 3000,
  info: 3000,
  warning: undefined,
  error: undefined,
}

const styles = {
  success: {
    color: (theme: Theme) => theme.palette.common.white,
    backgroundColor: (theme: Theme) => theme.palette.success.dark,
    fontWeight: 600,
    padding: "0 10px 0 10px"
  },
  info: {
    color: (theme: Theme) => theme.palette.common.white,
    backgroundColor: (theme: Theme) => theme.palette.info.dark,
    fontWeight: 600,
    padding: "0 10px 0 10px"
  },
  warning: {
    color: (theme: Theme) => theme.palette.common.white,
    backgroundColor: (theme: Theme) => theme.palette.warning.dark, // amber[700],
    fontWeight: 600,
    padding: "0 10px 0 10px"
  },
  error: {
    color: (theme: Theme) => theme.palette.common.white,
    backgroundColor: (theme: Theme) => theme.palette.error.dark,
    fontWeight: 600,
    padding: "0 10px 0 10px"
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 1.0,
    marginRight: (theme: Theme) => theme.spacing(1)
  },
}

interface INotifyBarProps {
  notify?: Notify
}

function Transition(props: any) {
  const nodeRef = React.createRef();

  return <Slide {...props} direction="down" nodeRef={nodeRef}/>;
}

@inject("notify")
@observer
class NotifyBar extends React.Component<INotifyBarProps> {

  onClose = (event: object, reason?: string) => {
    const {notify} = this.props
    
    if (notify && (!reason || reason !== "clickaway")) {
      notify.close()
    }
  }

  render() {
    const { notify } = this.props
    const autoHideDuration = variantAutoHideDuration[notify!.variant]

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={notify!.open}
        autoHideDuration={autoHideDuration}
        onClose={this.onClose}
        TransitionComponent={Transition}
        sx={{
          xs: {
            width: "calc(100vw - 12px)",
            mt: 54,
            pl: 6,
            pr: 8 
          },
          md: {
            marginTop: 39,
            px: 0 
          }
        }}
      >
        {this.snackBarContent()}
      </Snackbar>
    )
  }

  snackBarContent = () => {
    const { notify } = this.props;

    const Icon = variantIcon[notify!.variant];

    return (
      <SnackbarContent
        sx={styles[notify!.variant]}
        aria-describedby="client-snackbar"
        message={
          <Box 
            id="client-snackbar" 
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingRight: 5,
              lineHeight: 1.3
            }}
          >
            <Icon sx={{ ...styles.icon, ...styles.iconVariant }} />
            {notify!.message}
          </Box>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.onClose}
            size="medium"
            sx={{
              color: (theme: Theme) => theme.palette.common.white,
              cursor: 'pointer',
              padding: {
                xs: "3px 6px 3px 5px",
                md: "3px 8px 3px 10px"
              }
            }}
          >
            <CloseIcon sx={styles.icon} />
          </IconButton>,
        ]}
      />
    );
  }
}

export default NotifyBar
