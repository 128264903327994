import {Box, Typography} from "@mui/material";
import {useResources} from "../../stores/ResourceProvider";
import {Link} from "@reach/router";
import * as React from "react";

const Methodology = () => {
  const {termsOfUse} = useResources()

  return (
    <Box>
      <Typography variant="body2" gutterBottom>
        <strong>Methodology</strong>&nbsp;
        The logic for Retirement Budget Calculator = Current year liquid assets, minus shortfall, times growth rate, equals, net liquid assets end of year. Example: $350,000 (Current year liquid assets) - $12,000 (shortfall) = $338,000 (account value) X 4% (growth rate) = $13,520 (interest earned) and $338,000 (Net Liquid Assets) + $13,520 (Interest earned) gives us a year end value of $351,520. These calculations are based on annual withdrawals and not the monthly withdrawals you may be taking.
      </Typography>
      <Typography variant="body2" gutterBottom>
        This is a hypothetical example only and is not intended to predict the actual performance of any specific investment or insurance product. The returns are assumed to be constant year over year and this is unlikely to occur. All investments have risks associated with them and future loss is possible. This is not a specific recommendation for an investment or insurance contract. The values are not guaranteed. State income taxes are not being automatically calculated and the taxation of your income and withdrawals from retirement accounts can have a significant impact on these results. By changing your input assumptions regarding estimated annual growth, inflation, spending and taxes can significantly alter the results. It is highly recommended that you meet with a qualified financial professional to help validate the data you have input into the retirement budget calculator. Please review&nbsp;
        <Link to="." onClick={() => termsOfUse.show()}>Terms of Use</Link>.
      </Typography>
      <Typography variant="body2" gutterBottom>
        When selecting one of the estimated growth models such as 70/30 Stocks/Bonds - Aggressive, it is important to remember that past performance is no guarantee of future results, the model assumes historical returns starting in 1978 and repeats those returns starting with year one and continuing for the remainder of the 40 years. These estimated growth models assume an annual re-balance, and no fees. It is not possible to invest directly in a stock market index and you will most likely pay fees. When selecting worst case sequence of returns the calculator uses the years 2000 - 2018 to represent a time period where the negative returns occur during the first years of retirement followed by the financial crisis of 2008. After the return sequence from 2000 - 2018 we then assume historical returns from the year 1978 and project that return sequence for the remainder of the plan.
      </Typography>
    </Box>
  )
}

export default Methodology