import React from "react";
import {Box} from "@mui/material";

export class FormGroupSpacer extends React.Component {

  render() {
    return (
      <Box sx={{flexGrow: 0, width: 16,}}/>
    )
  }
}

export default FormGroupSpacer