import Person from "./Person";
import INetWorth from "./INetWorth";
import Model from "./Model";
import {isoToLocalDate} from "../stores/StoreUtilities";
import IModelItem from "./IModelItem";

export enum Risk {
  Low = 0,
  Moderate = 1,
  Agressive = 2,
  Emergency = 3
}

export enum AssetCategory {
  LiquidInvestableAssets = 1,
  RealEstateAndProperty = 2,
  PersonalProperty = 3,
  OtherAssets = 4
}

export enum AssetType {
  None = 0,
  Brokerage = 1,
  Checking= 2,
  HSA= 3,
  IRANonSpouseInheritedBefore2020 = 4,
  RothIRANonSpouseInheritedBefore2020 = 5,
  MoneyMarket = 6,
  NonQualifiedAnnuity = 7,
  RothIRA = 8,
  RothTSP = 9,
  Roth401k = 10,
  Savings= 11,
  SEPIRA = 12,
  SimpleIRA = 13,
  TraditionalIRA = 14,
  TSP = 15,
  Form1099 = 16,
  Plan401k = 17,
  Plan403b = 18,
  Plan457 = 19,
  Plan529 = 20,
  Roth403b = 21,
  IRANonSpouseInheritedAfter2019 = 22,
  RothIRANonSpouseInheritedAfter2019 = 23
}

interface IAsset {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  userId: string
  modelId: string
  assetCategory: AssetCategory
  assetType: AssetType
  description: string
  risk: Risk
  balance: number
  balanceDate: string
  returnRate: number
  rateLock?: boolean
  withdrawalOrder: number
  inheritedYear: number
  originalOwnerBirthYear: number
  sortOrder: number
  ownerId: string
  start: string
  end: string
  priorEoYBalance?: number
  withdrawalsYTD?: number
}

class Asset implements IModelItem, INetWorth {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  userId: string
  modelId: string
  model?: Model
  assetCategory: AssetCategory
  assetType: AssetType
  assetTypeDef: AssetTypeDef
  description: string
  risk: Risk
  balance: number
  balanceDate: Date
  returnRate: number
  rateLock: boolean
  withdrawalOrder: number
  inheritedYear: number
  originalOwnerBirthYear: number
  sortOrder: number
  ownerId: string
  owner?: Person
  start: string
  end: string
  priorEoYBalance?: number
  withdrawalsYTD: number

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.userId = data.userId
    this.modelId = data.modelId
    this.model = data.model
    this.assetCategory = data.assetCategory ?? AssetCategory.OtherAssets
    this.assetType = data.assetType ?? AssetType.None
    this.assetTypeDef = getAssetTypeDef(this.assetType)
    this.description = data.description ?? this.assetTypeDef.label ?? ""
    this.risk = data.risk
    this.balance = data.balance ?? 0
    this.balanceDate = isoToLocalDate(data.balanceDate)
    this.returnRate = data.returnRate
    this.rateLock = data.rateLock ?? false
    this.withdrawalOrder = data.withdrawalOrder ?? 0
    this.inheritedYear = data.inheritedYear
    this.originalOwnerBirthYear = data.originalOwnerBirthYear
    this.sortOrder = data.sortOrder
    this.ownerId = data.ownerId
    this.start = data.start
    this.end = data.end
    this.priorEoYBalance = data.priorEoYBalance
    this.withdrawalsYTD = data.withdrawalsYTD ?? 0
  }

  toJSON(): IAsset {
    return {
      id: this.id,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      accountId: this.accountId,
      userId: this.userId,
      modelId: this.modelId,
      assetCategory: this.assetCategory,
      assetType: this.assetType,
      description: this.description,
      risk: this.risk,
      balance: this.balance,
      balanceDate: this.balanceDate ? this.balanceDate.toISOString() : "",
      returnRate: this.returnRate,
      rateLock: this.rateLock,
      withdrawalOrder: this.withdrawalOrder,
      inheritedYear: this.inheritedYear,
      originalOwnerBirthYear: this.originalOwnerBirthYear,
      sortOrder: this.sortOrder,
      ownerId: this.ownerId,
      start: this.start,
      end: this.end,
      priorEoYBalance: this.priorEoYBalance
    }
  }
  get startDate(): Date | undefined {
    return this.model ? this.model.getDate(this.start) : undefined
  }

  get endDate(): Date | undefined {
    return this.model ? this.model.getDate(this.end) : undefined
  }
}

export class AssetTypeDef {
  assetType: AssetType
  label: string
  withdrawalOrder: number
  hasRMD: boolean
  useBalanceYStart: boolean
  allowJoint: boolean
  allowContributions: boolean
  defaultEndYearIsRetired: boolean
  requireExtraFields: boolean
  useTable1Once: boolean
  considerSpouseTable: boolean
  use10YDist: boolean
  rmdBeginAge: number
  taxable: boolean
  taxFree: boolean
  taxDeferred: boolean
  isIRA: boolean
  isTaxableIRA: boolean
  isUnqualified: boolean
  isTraditionalIRA: boolean
  convertableTo: AssetType[]

  constructor (data: any) {
    this.assetType = data.assetType ?? AssetType.None
    this.label = data.label ?? this.assetType.toString()
    this.withdrawalOrder = data.withdrawalOrder ?? 0
    this.hasRMD = data.hasRMD ?? false
    this.useBalanceYStart = data.useBalanceYStart ?? false
    this.allowJoint = data.allowJoint ?? false
    this.allowContributions = data.allowContributions ?? false
    this.defaultEndYearIsRetired = data.defaultEndYearIsRetired ?? false
    this.requireExtraFields = data.requireExtraFields ?? false
    this.useTable1Once = data.useTable1Once ?? false
    this.considerSpouseTable = data.considerSpouseTable ?? false
    this.use10YDist = data.use10YDist ?? false
    this.rmdBeginAge = data.rmdBeginAge ?? 0
    this.taxable = data.taxable ?? false
    this.taxFree = data.taxFree ?? false
    this.taxDeferred = data.taxDeferred ?? false
    this.isIRA = data.isIRA
    this.isTaxableIRA = data.isTaxableIRA
    this.isUnqualified = data.isUnqualified
    this.isTraditionalIRA = data.isTraditionalIRA ?? false
    this.convertableTo = data.convertableTo ?? []
  }
}

export const AssetTypeDefs: AssetTypeDef[] = [
  new AssetTypeDef({assetType: AssetType.None,
    label: "Other",
    withdrawalOrder: 0,
    hasRMD: false,
    useBalanceYStart: false,
    allowJoint: true,
    allowContributions: true,
    defaultEndYearIsRetired: false,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: false,
    use10YDist: false,
    rmdBeginAge: 0,
    taxable: true,
    taxFree: false,
    taxDeferred: false,
    isIRA: false,
    isTaxableIRA: false,
    isUnqualified: false,
    convertableTo: []
  }),
  new AssetTypeDef({assetType: AssetType.Brokerage, label: "Brokerage", withdrawalOrder: 5,
    hasRMD: false,
    useBalanceYStart: false,
    allowJoint: true,
    allowContributions: true,
    defaultEndYearIsRetired: false,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: false,
    use10YDist: false,
    rmdBeginAge: 0,
    taxable: true,
    taxFree: false,
    taxDeferred: false,
    isIRA: false,
    isTaxableIRA: false,
    isUnqualified: false,
    convertableTo: [AssetType.Brokerage, AssetType.Checking, AssetType.Savings, AssetType.MoneyMarket, AssetType.NonQualifiedAnnuity]
  }),
  new AssetTypeDef({assetType: AssetType.Checking, label: "Checking", withdrawalOrder: 1,
    hasRMD: false,
    useBalanceYStart: false,
    allowJoint: true,
    allowContributions: true,
    defaultEndYearIsRetired: false,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: false,
    use10YDist: false,
    rmdBeginAge: 0,
    taxable: true,
    taxFree: false,
    taxDeferred: false,
    isIRA: false,
    isTaxableIRA: false,
    isUnqualified: false,
    convertableTo: [AssetType.Brokerage, AssetType.Checking, AssetType.Savings, AssetType.MoneyMarket, AssetType.NonQualifiedAnnuity]
  }),
  new AssetTypeDef({assetType: AssetType.HSA, label: "HSA", withdrawalOrder: 23,
    hasRMD: false,
    useBalanceYStart: false,
    allowJoint: false,
    allowContributions: true,
    defaultEndYearIsRetired: false,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: false,
    use10YDist: false,
    rmdBeginAge: 0,
    taxable: false,
    taxFree: false,
    taxDeferred: false,
    isIRA: false,
    isTaxableIRA: false,
    isUnqualified: false,
    convertableTo: [AssetType.Brokerage, AssetType.Checking, AssetType.Savings, AssetType.MoneyMarket]
  }),
  new AssetTypeDef({assetType: AssetType.IRANonSpouseInheritedBefore2020, label: "IRA Non-spouse inherited before 2020", withdrawalOrder: 7,
    hasRMD: true,
    useBalanceYStart: true,
    allowJoint: false,
    allowContributions: false,
    defaultEndYearIsRetired: false,
    requireExtraFields: true,
    useTable1Once: true,
    considerSpouseTable: false,
    use10YDist: false,
    rmdBeginAge: 0,
    taxable: false,
    taxFree: false,
    taxDeferred: true,
    isIRA: true,
    isTaxableIRA: true,
    isUnqualified: false,
    convertableTo: []
  }),
  new AssetTypeDef({assetType: AssetType.RothIRANonSpouseInheritedBefore2020, label: "Roth IRA Non-spouse inherited before 2020", withdrawalOrder: 18,
    hasRMD: true,
    useBalanceYStart: true,
    allowJoint: false,
    allowContributions: true,
    defaultEndYearIsRetired: false,
    requireExtraFields: true,
    useTable1Once: true,
    considerSpouseTable: false,
    use10YDist: false,
    rmdBeginAge: 0,
    taxable: false,
    taxFree: true,
    taxDeferred: false,
    isIRA: true,
    isTaxableIRA: false,
    isUnqualified: false,
    convertableTo: []
  }),
  new AssetTypeDef({assetType: AssetType.MoneyMarket, label: "Money Market", withdrawalOrder: 4,
    hasRMD: false,
    useBalanceYStart: false,
    allowJoint: true,
    allowContributions: true,
    defaultEndYearIsRetired: false,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: false,
    use10YDist: false,
    rmdBeginAge: 0,
    taxable: true,
    taxFree: false,
    taxDeferred: false,
    isIRA: false,
    isTaxableIRA: false,
    isUnqualified: false,
    convertableTo: [AssetType.Brokerage, AssetType.Checking, AssetType.Savings, AssetType.MoneyMarket, AssetType.NonQualifiedAnnuity]
  }),
  new AssetTypeDef({assetType: AssetType.NonQualifiedAnnuity, label: "Non Qualified Annuity", withdrawalOrder: 15,
    hasRMD: false,
    useBalanceYStart: false,
    allowJoint: true,
    allowContributions: true,
    defaultEndYearIsRetired: true,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: false,
    use10YDist: false,
    rmdBeginAge: 0,
    taxable: false,
    taxFree: false,
    taxDeferred: true,
    isIRA: false,
    isTaxableIRA: false,
    isUnqualified: true,
    convertableTo: [AssetType.Brokerage, AssetType.Checking, AssetType.Savings, AssetType.MoneyMarket]
  }),
  new AssetTypeDef({assetType: AssetType.RothIRA, label: "Roth IRA", withdrawalOrder: 19,
    hasRMD: false,
    useBalanceYStart: true,
    allowJoint: false,
    allowContributions: true,
    defaultEndYearIsRetired: true,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: false,
    use10YDist: false,
    rmdBeginAge: 0,
    taxable: false,
    taxFree: true,
    taxDeferred: false,
    isIRA: true,
    isTaxableIRA: false,
    isUnqualified: false,
    convertableTo: []
  }),
  new AssetTypeDef({assetType: AssetType.RothTSP, label: "Roth TSP", withdrawalOrder: 20,
    hasRMD: false,
    useBalanceYStart: true,
    allowJoint: false,
    allowContributions: true,
    defaultEndYearIsRetired: true,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: true,
    use10YDist: false,
    rmdBeginAge: 0,
    taxable: false,
    taxFree: true,
    taxDeferred: false,
    isIRA: true,
    isTaxableIRA: false,
    isUnqualified: false,
    convertableTo: [AssetType.RothIRA]
  }),
  new AssetTypeDef({assetType: AssetType.Roth401k, label: "Roth 401k", withdrawalOrder: 21,
    hasRMD: false,
    useBalanceYStart: true,
    allowJoint: false,
    allowContributions: true,
    defaultEndYearIsRetired: true,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: true,
    use10YDist: false,
    rmdBeginAge: 0,
    taxable: false,
    taxFree: true,
    taxDeferred: false,
    isIRA: true,
    isTaxableIRA: false,
    isUnqualified: false,
    convertableTo: [AssetType.RothIRA]
  }),
  new AssetTypeDef({assetType: AssetType.Savings, label: "Savings", withdrawalOrder: 2,
    hasRMD: false,
    useBalanceYStart: false,
    allowJoint: true,
    allowContributions: true,
    defaultEndYearIsRetired: false,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: true,
    use10YDist: false,
    rmdBeginAge: 0,
    taxable: true,
    taxFree: false,
    taxDeferred: false,
    isIRA: false,
    isTaxableIRA: false,
    isUnqualified: false,
    convertableTo: [AssetType.Brokerage, AssetType.Checking, AssetType.Savings, AssetType.MoneyMarket, AssetType.NonQualifiedAnnuity]
  }),
  new AssetTypeDef({assetType: AssetType.SEPIRA, label: "SEP IRA", withdrawalOrder: 8,
    hasRMD: true,
    useBalanceYStart: true,
    allowJoint: false,
    allowContributions: true,
    defaultEndYearIsRetired: true,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: true,
    use10YDist: false,
    rmdBeginAge: 73,
    taxable: false,
    taxFree: false,
    taxDeferred: true,
    isIRA: true,
    isTaxableIRA: true,
    isUnqualified: false,
    convertableTo: [AssetType.SimpleIRA, AssetType.TraditionalIRA, AssetType.Plan401k, AssetType.Plan403b, AssetType.Plan457]
  }),
  new AssetTypeDef({assetType: AssetType.SimpleIRA,  label: "Simple IRA", withdrawalOrder: 9,
    hasRMD: true,
    useBalanceYStart: true,
    allowJoint: false,
    allowContributions: true,
    defaultEndYearIsRetired: true,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: true,
    use10YDist: false,
    rmdBeginAge: 73,
    taxable: false,
    taxFree: false,
    taxDeferred: true,
    isIRA: true,
    isTaxableIRA: true,
    isUnqualified: false,
    convertableTo: [AssetType.RothIRA, AssetType.SEPIRA, AssetType.SimpleIRA, AssetType.TraditionalIRA, AssetType.TSP, AssetType.Plan401k, AssetType.Plan403b, AssetType.Plan457]
  }),
  new AssetTypeDef({assetType: AssetType.TraditionalIRA, label: "Traditional IRA", withdrawalOrder: 10,
    hasRMD: true,
    useBalanceYStart: true,
    allowJoint: false,
    allowContributions: true,
    defaultEndYearIsRetired: true,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: true,
    use10YDist: false,
    rmdBeginAge: 73,
    taxable: false,
    taxFree: false,
    taxDeferred: true,
    isIRA: true,
    isTaxableIRA: true,
    // TODO: correspondngRothTypes: []
    isUnqualified: false,
    isTraditionalIRA: true,
    convertableTo: [AssetType.HSA, AssetType.RothIRA, AssetType.SimpleIRA, AssetType.TraditionalIRA, AssetType.TSP, AssetType.Plan401k, AssetType.Plan403b, AssetType.Plan457]
  }),
  new AssetTypeDef({assetType: AssetType.TSP, label: "TSP", withdrawalOrder: 11,
    hasRMD: true,
    useBalanceYStart: true,
    allowJoint: false,
    allowContributions: true,
    defaultEndYearIsRetired: true,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: true,
    use10YDist: false,
    rmdBeginAge: 73,
    taxable: false,
    taxFree: false,
    taxDeferred: true,
    isIRA: true,
    isTaxableIRA: true,
    isUnqualified: false,
    convertableTo: [AssetType.RothIRA, AssetType.TraditionalIRA]
  }),
  new AssetTypeDef({assetType: AssetType.Form1099, label: "1099", withdrawalOrder: 3,
    hasRMD: false,
    useBalanceYStart: false,
    allowJoint: true,
    allowContributions: true,
    defaultEndYearIsRetired: false,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: true,
    use10YDist: false,
    rmdBeginAge: 0,
    taxable: true,
    taxFree: false,
    taxDeferred: false,
    isIRA: false,
    isTaxableIRA: false,
    isUnqualified: false,
    convertableTo: []
  }),
  new AssetTypeDef({assetType: AssetType.Plan401k, label: "401k", withdrawalOrder: 14,
    hasRMD: true,
    useBalanceYStart: true,
    allowJoint: false,
    allowContributions: true,
    defaultEndYearIsRetired: true,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: true,
    use10YDist: false,
    rmdBeginAge: 73,
    taxable: false,
    taxFree: false,
    taxDeferred: true,
    isIRA: true,
    isTaxableIRA: true,
    isUnqualified: false,
    convertableTo: [AssetType.RothIRA, AssetType.Roth401k, AssetType.SEPIRA, AssetType.SimpleIRA, AssetType.TraditionalIRA]
  }),
  new AssetTypeDef({assetType: AssetType.Plan403b, label: "403b", withdrawalOrder: 12,
    hasRMD: true,
    useBalanceYStart: true,
    allowJoint: false,
    allowContributions: true,
    defaultEndYearIsRetired: true,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: true,
    use10YDist: false,
    rmdBeginAge: 73,
    taxable: false,
    taxFree: false,
    taxDeferred: true,
    isIRA: true,
    isTaxableIRA: true,
    isUnqualified: false,
    convertableTo: [AssetType.RothIRA, AssetType.SEPIRA, AssetType.SimpleIRA, AssetType.TraditionalIRA, AssetType.Roth403b]
  }),
  new AssetTypeDef({assetType: AssetType.Plan457, label: "457", withdrawalOrder: 13,
    hasRMD: true,
    useBalanceYStart: true,
    allowJoint: false,
    allowContributions: true,
    defaultEndYearIsRetired: true,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: true,
    use10YDist: false,
    rmdBeginAge: 73,
    taxable: false,
    taxFree: false,
    taxDeferred: false,
    isIRA: true,
    isTaxableIRA: true,
    isUnqualified: false,
    convertableTo: [AssetType.RothIRA, AssetType.RothTSP, AssetType.Roth401k, AssetType.SEPIRA, AssetType.SimpleIRA, AssetType.TraditionalIRA, AssetType.TSP, AssetType.Plan401k, AssetType.Plan403b, AssetType.Plan457, AssetType.Roth403b]
  }),
  new AssetTypeDef({assetType: AssetType.Plan529, label: "529", withdrawalOrder: 16,
    hasRMD: false,
    useBalanceYStart: false,
    allowJoint: false,
    allowContributions: true,
    defaultEndYearIsRetired: false,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: true,
    use10YDist: false,
    rmdBeginAge: 0,
    taxable: false,
    taxFree: true,
    taxDeferred: false,
    isIRA: false,
    isTaxableIRA: false,
    isUnqualified: false,
    convertableTo: [AssetType.RothIRA, AssetType.SEPIRA, AssetType.SimpleIRA]
  }),
  new AssetTypeDef({assetType: AssetType.Roth403b, label: "Roth 403b", withdrawalOrder: 22,
    hasRMD: false,
    useBalanceYStart: true,
    allowJoint: false,
    allowContributions: true,
    defaultEndYearIsRetired: true,
    requireExtraFields: false,
    useTable1Once: false,
    considerSpouseTable: false,
    use10YDist: false,
    rmdBeginAge: 0,
    taxable: false,
    taxFree: true,
    taxDeferred: false,
    isIRA: true,
    isTaxableIRA: false,
    isUnqualified: false,
    convertableTo: [AssetType.RothIRA]
  }),
  new AssetTypeDef({assetType: AssetType.IRANonSpouseInheritedAfter2019, label: "IRA Non-spouse inherited after 2019", withdrawalOrder: 6,
    hasRMD: true,
    useBalanceYStart: true,
    allowJoint: false,
    allowContributions: false,
    defaultEndYearIsRetired: false,
    requireExtraFields: true,
    useTable1Once: false,
    considerSpouseTable: false,
    use10YDist: true,
    rmdBeginAge: 0,
    taxable: false,
    taxFree: false,
    taxDeferred: true,
    isIRA: true,
    isTaxableIRA: true,
    isUnqualified: false,
    convertableTo: []
  }),
  new AssetTypeDef({assetType: AssetType.RothIRANonSpouseInheritedAfter2019, label: "Roth IRA Non-spouse inherited after 2019", withdrawalOrder: 17,
    hasRMD: true,
    useBalanceYStart: true,
    allowJoint: false,
    allowContributions: false,
    defaultEndYearIsRetired: false,
    requireExtraFields: true,
    useTable1Once: false,
    considerSpouseTable: false,
    use10YDist: true,
    rmdBeginAge: 0,
    taxable: false,
    taxFree: true,
    taxDeferred: false,
    isIRA: true,
    isTaxableIRA: false,
    isUnqualified: false,
    convertableTo: []
  })
]

export function listAssetTypes(): AssetType[] {
  return AssetTypeDefs.sort((a, b) => a.label.localeCompare(b.label))
    .map((a) => a.assetType)
}

export function listAssetTypeDefs(): AssetTypeDef[] {
  return AssetTypeDefs.sort((a, b) => a.label.localeCompare(b.label))
}

export function getAssetTypeDef(assetType: AssetType) : AssetTypeDef {
  const assetTypeDef = AssetTypeDefs.find((item: AssetTypeDef) => item.assetType === assetType)
  return assetTypeDef ?? AssetTypeDefs[0]
}

export function getAssetTypeLabel(assetType: AssetType): string {
  const assetTypeDef = AssetTypeDefs.find((def: AssetTypeDef) => def.assetType === assetType)
  return assetTypeDef?.label ?? ""
}

export default Asset