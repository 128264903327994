import Page from '../components/page/Page'
import { Box } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {when} from "mobx";
import RoutesConfig from '../RoutesConfig';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useResources } from '../stores/ResourceProvider';
import { useNavigate } from 'react-router-dom';

interface ILoadingPageProps {
  route?: string
}

const LoadingPage = observer((props: ILoadingPageProps) => {
  const { userStore } = useResources()
  const navigate = useNavigate()

  useEffect(() => {
    when(
      // once...
      () => userStore !== undefined
        && !userStore!.isLoading
        && props.route !== undefined,
      // ... then
      () => {
        const route = props.route
        if (route) {
          const pathname = route
          console.log(`LoadingPage routing to ${pathname}`)
          navigate(pathname)
        } else {
          console.log(`LoadingPage routing to ${RoutesConfig.signIn}`)
          navigate(RoutesConfig.signIn.pathname)
        }
      }
    )
  }, [navigate, userStore.isLoading, props.route, userStore])

  return (
    <Page>
      <Box 
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: "-12px",
            marginLeft: "-12px"
          }}
        />
      </Box>
    </Page>
  )
})

export default LoadingPage
