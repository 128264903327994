/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AuthAPI",
            "endpoint": "https://hwqw77xati.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        },
        {
            "name": "BillingAPI",
            "endpoint": "https://svargzck20.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        },
        {
            "name": "ConvertKitAPI",
            "endpoint": "https://emktfzqjl4.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        },
        {
            "name": "MigrateAPI",
            "endpoint": "https://5krh18e3n3.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://qpvkws2mlfb7flovyba2lqacum.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-6hw2di232ngh7mz5ds7pxqaiam",
    "aws_cognito_identity_pool_id": "us-west-2:e1ea3e0f-bbef-4c7e-965e-262e8094a0b3",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_JEApHyCgc",
    "aws_user_pools_web_client_id": "5butrfbrn12i6aqrcbs881l91m",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
