import React, {useEffect, useState} from 'react'
import Chart from "react-apexcharts";
import {FutureCalc} from "../../../../components/calculator/Calculator";
import {numberToMoneyFormat} from "../../../../stores/StoreUtilities";
import {globalColors} from "../../../../styles/globalStyles"
import {Paper, TableContainer, useTheme} from "@mui/material";
import cloneDeep from "lodash.clonedeep";

const defaultOptions = {
  chart: {
    id: "budget-projection",
    type: 'bar',
    stacked: true,
    height: 350,
    width: "100%",
    // zoom: {
    //   autoScaleYaxis: true
    // },
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      }
    },
    zoom: {
      enabled: false,
      autoScaleYaxis: true
    },
    toolbar: {
      show: false,
      tools: {
        download: false
      }
    }
  },
  title: {
    text: "",
    align: "right",
    margin: 0,
    floating: true,
    style: {
      fontSize: "16px",
      fontFamily: "Poppins,sans-serif",
      color: "rgb(31, 92, 167)"
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    position: 'top', // TODO: Figure out why this isn't working
    horizontalAlign: 'left',
    onItemClick: {
      toggleDataSeries: false
    }
  },
  xaxis: {
    type: 'number',
    min: 2000,
    axisBorder: {
      show: true
    }
  },
  yaxis: {
    labels: {
      formatter: (val: number) => {
        // return numberToMoneyFormat(val, 0)
        if (val < 1000000) {
          return `$${val / 1000}K`
        } else {
          return `$${val / 1000000}M`
        }
      }
    },
    min: 0
  },
  toolbar: {
    show: false,
  },
  tooltip: {
    y: {
      formatter: function (value: number) {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  // tooltip: {
  //   x: {
  //     format: 'dd MMM yyyy'
  //   }
  // },
  colors: [globalColors.liquidAsset, globalColors.realAsset, globalColors.liability],
  // fill: {
  //   type: 'gradient',
  //   gradient: {
  //     shadeIntensity: 1,
  //     opacityFrom: 0.7,
  //     opacityTo: 0.9,
  //     stops: [0, 100]
  //   }
  // },
  // stroke: {
  //   width: 3
  // },
  resonsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const NetWorthProjectionChart = ({
  year,
  futureCalc,
  onChange,
  height,
  width,
  title
}: {
  year: number
  futureCalc: FutureCalc
  onChange?: (year: number) => any
  height?: string
  width?: string
  title?: string
}) => {
  const [startYear, setStartYear] = useState<number>(0)
  const [endYear, setEndYear] = useState<number>(0)
  const [calc, setCalc] = useState<FutureCalc | undefined>()
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<any>()
  const [currentYear, setCurrentYear] = useState<number>(0)

  const theme = useTheme()

  useEffect(() => {
    if (futureCalc.futureYears.length > 0) {
      const start = futureCalc.futureYears[0].year
      setStartYear(start)
      setEndYear(futureCalc.futureYears[futureCalc.futureYears.length-1].year)
      setCurrentYear(start)
      setCalc(futureCalc)
    }
  }, [year, futureCalc])

  useEffect(() => {
    if (startYear > 0 && calc && calc.futureYears.length > 0) {
      calculateData(startYear)
    }
  }, [currentYear, calc])

  const calculateData = (startYear: number) => {
    if (!calc || startYear <= 0 || endYear < startYear) {
      return
    }
    let liquidData = []
    let realData = []
    let liabilityData = []

    for (let index = 0; index < calc.futureYears.length; index++) {
      const year = startYear + index
      const fy = calc.futureYears[index]
      liquidData.push({x: year, y: fy.netLiquidAssets})
      realData.push({x: year, y: fy.netRealAssets})
      liabilityData.push({x: year, y: fy.netLiabilities})
    }

    const series = [
      {
        name: "Liquid Assets",
        group: "Assets",
        data: liquidData
      },
      {
        name: "Real Estate",
        group: "Assets",
        data: realData
      },
      {
        name: "Liabilites",
        group: "liabilities",
        data: liabilityData
      }
    ]
    setSeries(series)

    const newOptions = cloneDeep(defaultOptions)
    newOptions.title.text = title ?? ""
    newOptions.xaxis.min = startYear

    if (currentYear !== undefined) {
      const annotations = {
        xaxis: [
          {
            x: currentYear,
            borderColor: theme.palette.secondary.main, //'#00E396',
            label: {
              borderColor: theme.palette.secondary.main, //'#00E396',
              orientation: 'horizontal',
              text: currentYear.toString(),
              style: {
                fontSize: '14px',
                fontWeight: 600
              }
            }
          }
        ]
      }
      newOptions["annotations"] = annotations
      newOptions.chart.events["dataPointSelection"] = (event: any, chartContext: any, details: {dataPointIndex: number, seriesIndex: number}) => {
        if (details.dataPointIndex >= 0 && onChange) {
          onChange(details.dataPointIndex + startYear)
        }
      }
    }

    if (height) {
      newOptions.chart.toolbar.show = false
    }

    setOptions(newOptions)
  }

  // const handleChange = (year: number) => {
  //   setCurrentYear(year)
  //   if (onChange) {
  //     onChange(year)
  //   }
  // }

  if (options && series) {
    return (
        <TableContainer component={Paper} sx={{paddingTop:1, overflowY:"hidden"}}>
          <Chart
            options={options}
            series={series}
            type="bar"
            height={height ?? "500"}
            width={width ?? "100%"}
          />
        </TableContainer>
    )
  } else {
    return null
  }
}

export default NetWorthProjectionChart