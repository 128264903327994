import { API } from "aws-amplify"
import {getErrorMessage} from "../stores/StoreUtilities";
import Logger from "../components/Logger";
import config from 'react-global-configuration';

export interface ISubscriber {
  email?: string
  first_name?: string,
  last_name?: string,
  tags?: string[]
}

export enum ConvertKitTag {
  Cancellation = "1943537",
  Downgrade = "2713917",
  FreeTrial = "1943533",
  FreeSubscription = "2707228",
  PremiumSubscription = "1943316",
  LifetimeSubscription = "3823661",
  BookAdvisor = "3629559",
  Migrated = "5093627"
}

class ConvertKitAPI {
  private formId = "1762127"
  private enabled: boolean = true

  constructor () {
    this.enabled = config.get("convertKit.enabled")
  }

  addSubscriber = async (subscriber: ISubscriber)=> {
    if (!this.enabled) {
      return "Convert is not enabled"
    }

    try {
      const init = {
        body: {
          ...subscriber
        }
      }
      console.log(`ConvertKit.addSubscriber(${this.formId})`, subscriber)
      const response = await API.post("ConvertKitAPI", `/forms/${this.formId}/subscribe`, init)
      // const response = await API.post("ConvertKitAPI", `/subscribe/${this.formId}`, init)
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("ConvertKitAPI.addSubscriber error", message)
      throw new Error(message)
    }
  }

  addTag = async (tagId: string, email: string)=> {
    if (!this.enabled) {
      return "Convert is not enabled"
    }

    try {
      const init = {
        body: {
          email: email
        }
      }
      console.log(`ConvertKit.addTag(${tagId}, ${email})`)
      const response = await API.post("ConvertKitAPI", `/tags/${tagId}/subscribe`, init)
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("ConvertKitAPI.addTag error", message)
      throw new Error(message)
    }
  }

  getSubscriber = async (email: string) => {
    if (!this.enabled) {
      return "Convert is not enabled"
    }

    try {
      const response = await API.get("ConvertKitAPI", `/subscribers/${email}`, {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("ConvertKitAPI.getSubscriber error", message)
      throw new Error(message)
    }
  }

  putSubscriber = async (subscriberId: string, update: ISubscriber) => {
    if (!this.enabled) {
      return "Convert is not enabled"
    }

    try {
      const init = {
        body: {
          first_name: update.first_name,
          email_address: update.email,
          fields: {
            last_name: update.last_name
          }
        }
      }
      const response = await API.put("ConvertKitAPI", `/subscribers/${subscriberId}`, init)
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("ConvertKitAPI.updateSubscriber error", message)
      throw new Error(message)
    }
  }

}

export default ConvertKitAPI