import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography, useTheme
} from "@mui/material";
import {numberToMoneyFormat, numberToPercentFormat} from "../../../../stores/StoreUtilities";
import {
  DeductionExpenseIcon,
  DiscretionaryExpenseIcon,
  DistributionIcon,
  EssentialExpenseIcon, EstimatedTaxIcon,
  FoodAndPersonalExpenseIcon,
  GivingAndMiscExpenseIcon,
  HousingExpenseIcon,
  IncomeIcon,
  InsuranceAndMedicalExpenseIcon,
  LoanExpenseIcon,
  NetIncomeIcon,
  TaxExpenseIcon,
  TravelAndEntertainmentExpenseIcon,
  VehiclesAndTransporationExpenseIcon,
  WithdrawalIcon
} from "../../../../styles/Icons";


const AnnualBudgetTable = ({
  year,
  data,
  surplus,
}: {
  year: number
  data: any,
  surplus: number
}) => {

  const income = data[0].y

  const icons = [
    <IncomeIcon/>,
    <DistributionIcon/>,
    <DeductionExpenseIcon/>,
    <TaxExpenseIcon/>,
    <EstimatedTaxIcon/>,
    <EssentialExpenseIcon/>,
    <DiscretionaryExpenseIcon/>,
    <HousingExpenseIcon/>,
    <LoanExpenseIcon/>,
    <FoodAndPersonalExpenseIcon/>,
    <InsuranceAndMedicalExpenseIcon/>,
    <VehiclesAndTransporationExpenseIcon/>,
    <TravelAndEntertainmentExpenseIcon/>,
    <GivingAndMiscExpenseIcon/>
  ]

  const theme = useTheme()

  const totalLabel = surplus >= 0 ? "Surplus" : "Shortfall"
  const totalColor = surplus >= 0 ? theme.palette.success.main : theme.palette.error.main

  return (
    <Box display="flex" flexGrow={1} flexDirection="column" mt={2}>
      <Box display="flex" flexGrow={1} justifyContent="space-between" maxHeight={40}>
        <Typography variant="h3" color="primary" gutterBottom>{year} Annual Budget Details</Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" width="100%">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right">Annual</TableCell>
              <TableCell align="right">Monthly Avg</TableCell>
              <TableCell align="right">% of Income</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: any, index: number) =>
              <TableRow key={`row${index}`}>
                <TableCell>{icons[index]}</TableCell>
                <TableCell>{item.x}</TableCell>
                <TableCell
                  align="right">{numberToMoneyFormat(item.y, 0)}</TableCell>
                <TableCell
                  align="right">{numberToMoneyFormat(item.y / 12, 0)}</TableCell>
                <TableCell align="right">{numberToPercentFormat(income !== 0 ? item.y / income : 0, 0)}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell><NetIncomeIcon/></TableCell>
              <TableCell sx={{color: totalColor}}><b>{totalLabel}</b></TableCell>
              <TableCell sx={{color: totalColor}}
                align="right"><b>{numberToMoneyFormat(surplus, 0)}</b></TableCell>
              <TableCell sx={{color: totalColor}}
                align="right"><b>{numberToMoneyFormat(surplus / 12, 0)}</b></TableCell>
              <TableCell sx={{color: totalColor}}
                align="right"><b>{numberToPercentFormat(surplus !==  0 ? surplus / income : 0, 0)}</b></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default AnnualBudgetTable