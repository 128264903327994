import {Typography} from "@mui/material";
import * as React from "react";

const AdviceDisclaimer = () => {

  return (
    <Typography variant="body2" gutterBottom sx={{mt:1}}>
      <strong>Not a Finanical Planner, Broker or Tax Advisor</strong>&nbsp;
      NEITHER COMPANY NOR THE SERVICES ARE INTENDED TO PROVIDE LEGAL, TAX OR FINANCIAL ADVICE. The Services are intended only to assist you in your financial organization and decision- making and is broad in scope. Your personal financial situation is unique, and any information and advice obtained through the Service may not be appropriate for your situation. Accordingly, before making any final decisions or implementing any financial strategy, you should consider obtaining additional information and advice from your accountant or other financial advisers who are fully aware of your individual circumstances.
    </Typography>
  )
}

export default AdviceDisclaimer