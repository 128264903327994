import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Box, Collapse, MenuItem, MenuList, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { INavItem } from "../../NavigationConfig"
import NavMenuItem from "./NavMenuItem"

interface INavExpandableMenuProps {
  item: INavItem
  onClickSubNavItem: (e: React.MouseEvent, subNavItem: INavItem) => void 
}

const NavExpandableMenu = (props: INavExpandableMenuProps) => {
  const [ isExpanded, setIsExpanded ] = useState(true)
  const { item } = props
  const route = item.route
  const subNavItems = item.subNavItems!
  return (
    <Box>
      <MenuItem
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
          <Typography textAlign="center">{route.title}</Typography>
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </Stack>
      </MenuItem>
      <Collapse
        in={isExpanded}
      >
        <Box pl={2}>
          <MenuList>
            {subNavItems.map(item => <NavMenuItem key={item.route.title} navItem={item} onClickMenuItem={(e: React.MouseEvent, item: INavItem) => props.onClickSubNavItem(e, item)} />)}
          </MenuList>
        </Box>
      </Collapse>
    </Box>
  )
}

export default NavExpandableMenu
