import React, {useEffect, useState} from 'react'
import Chart from "react-apexcharts";
import {numberToMoneyFormat, numberToPercentFormat} from "../../../../stores/StoreUtilities";
import {globalColors} from "../../../../styles/globalStyles"
import {Box} from "@mui/material";
import cloneDeep from "lodash.clonedeep";
import Asset from "../../../../model/Asset";

const defaultOptions = {
  chart: {
    id: "annual-budget",
    type: 'bar',
    height: 350,
    width: "100%",
    zoom: {
      autoScaleYaxis: true
    },
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      }
    },
    toolbar: {
      show: true,
      tools: {
        download: false
      }
    }
  },
  title: {
    text: "",
    align: "right",
    margin: 0,
    floating: true,
    style: {
      fontSize: "16px",
      fontFamily: "Poppins,sans-serif",
      color: "rgb(31, 92, 167)"
    }
  },
  dataLabels: {
    enabled: true,
    formatter: function (value: number) {
      return numberToMoneyFormat(value, 0)
    },
    offsetY: -20,
    style: {
      fontSize: '12px',
      colors: ["#304758"]
    }
  },
  legend: {
    show: false,
    position: 'top',
    horizontalAlign: 'left'
  },
  xaxis: {
    axisBorder: {
      show: true
    }
  },
  yaxis: {
    // max: 15000,
    labels: {
      formatter: (value: number) => {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  tooltip: {
    y: {
      formatter: function (value: number) {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  plotOptions: {
    bar: {
      distributed: true,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    }
  },
  colors: [globalColors.categorical[0], globalColors.categorical[1], globalColors.categorical[6]],
  responsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const LiquidAssetTaxAllocationChart = ({
  liquidAssets,
  height,
  width,
  title
}: {
  liquidAssets: Asset[]
  height?: string
  width?: string
  title?: string
}) => {
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<any>()
  const [data, setData] = useState<any>()

  useEffect(() => {
    calculateData()
  }, [liquidAssets])

  const calculateData = () => {
    if (!liquidAssets) {
      return
    }

    let taxFreeAssets = liquidAssets.filter((asset: Asset) => asset.assetTypeDef.taxFree)
    let taxFreeTotal = taxFreeAssets.reduce((accum: number, item: Asset) => accum + item.balance, 0)
    let taxableAssets = liquidAssets.filter((asset: Asset) => asset.assetTypeDef.taxable)
    let taxableTotal = taxableAssets.reduce((accum: number, item: Asset) => accum + item.balance, 0)
    let taxDeferredAssets = liquidAssets.filter((asset: Asset) => asset.assetTypeDef.taxDeferred)
    let taxDeferredTotal = taxDeferredAssets.reduce((accum: number, item: Asset) => accum + item.balance, 0)

    const totalAssets = taxFreeTotal + taxableTotal + taxDeferredTotal
    const taxFreeLabel = totalAssets > 0 ? `Tax Free: ${numberToPercentFormat(taxFreeTotal / totalAssets, 0)}` : `Tax Free`
    const taxableLabel = totalAssets > 0 ? `Taxable: ${numberToPercentFormat(taxableTotal / totalAssets, 0)}` : `Taxable`
    const taxDeferredLabel = totalAssets > 0 ? `Tax Deferred: ${numberToPercentFormat(taxDeferredTotal / totalAssets, 0)}` : `Tax Deferred`

    let data = [
      {x: taxFreeLabel, y: taxFreeTotal},
      {x: taxableLabel, y: taxableTotal},
      {x: taxDeferredLabel, y: taxDeferredTotal}
    ]
    setData(data)

    const options = cloneDeep(defaultOptions)
    options.title.text = title ?? ""

    const series = [
      {
        data: data
      },
    ]
    setSeries(series)

    if (height || width) {
      options.chart.toolbar.show = false
    }

    setOptions(options)
  }


  if (options && series) {
    return (
      <React.Fragment>
        <Box>
          <Chart
            options={options}
            series={series}
            type="bar"
            height={height ?? "500"}
            width={width ?? "100%"}
          />
        </Box>
      </React.Fragment>
    )
  } else {
    return null
  }
}

export default LiquidAssetTaxAllocationChart