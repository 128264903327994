import Scheduled from "./Scheduled";
import Person from "./Person";
import {isoToLocalDate} from "../stores/StoreUtilities";
import Asset from "./Asset";
import Liability from "./Liability";
import IModelItem from "./IModelItem";

export enum ExpenseCategory {
  None = 0,
  Housing = 1,
  FoodAndPersonalCare = 2,
  InsuranceAndMedical = 3,
  VehiclesAndTransportation = 4,
  LoansAndLiabilities = 5,
  TravelAndEntertainment = 6,
  GivingAndMisc = 7
}

export class ExpenseCategoryDef {
  expenseCategory: ExpenseCategory
  label: string

  constructor(expenseCategory: ExpenseCategory, label: string) {
    this.expenseCategory = expenseCategory
    this.label = label
  }
}

const ExpenseCategoryDefs: ExpenseCategoryDef[] = [
  {expenseCategory: ExpenseCategory.None, label: ""},
  {expenseCategory: ExpenseCategory.Housing, label: "Housing"},
  {expenseCategory: ExpenseCategory.FoodAndPersonalCare, label: "Food & Personal Care"},
  {expenseCategory: ExpenseCategory.InsuranceAndMedical, label: "Insurance & Medical"},
  {expenseCategory: ExpenseCategory.VehiclesAndTransportation, label: "Vehicles & Transportation"},
  {expenseCategory: ExpenseCategory.LoansAndLiabilities, label: "Loan & Liabilities"},
  {expenseCategory: ExpenseCategory.TravelAndEntertainment, label: "Travel & Entertainment"},
  {expenseCategory: ExpenseCategory.GivingAndMisc, label: "Giving & Misc"}
]

export function listExpenseCategories(): ExpenseCategory[] {
  return ExpenseCategoryDefs.sort((a, b) => a.label.localeCompare(b.label))
    .map((e) => e.expenseCategory)
}

export function getExpenseCategoryLabel(expenseCategory: ExpenseCategory): string {
  const expenseCategoryDef = ExpenseCategoryDefs.find((def: ExpenseCategoryDef) => def.expenseCategory === expenseCategory)
  return expenseCategoryDef?.label ?? ""
}

class Expense extends Scheduled implements IModelItem {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  userId: string
  modelId: string
  expenseCategory: ExpenseCategory
  description: string
  amount: number
  infLock: boolean
  annualInf: number
  discretionary: boolean
  assetId: string
  asset?: Asset
  liabilityId: string
  liability?: Liability
  sortOrder: number
  ownerId: string
  owner?: Person

  constructor(data: any) {
    super(data)
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.userId = data.userId
    this.modelId = data.modelId
    this.expenseCategory = data.expenseCategory ?? ExpenseCategory.None
    this.description = data.description ?? "Other"
    this.amount = data.amount ?? 0
    this.infLock = data.infLock
    this.annualInf = data.annualInf
    this.discretionary = data.discretionary
    this.assetId = data.assetId
    this.liabilityId = data.liabilityId
    this.sortOrder = data.sortOrder
    this.ownerId = data.ownerId
  }

  get endDate(): Date | undefined {
    let endDate = this.model?.getDate(this.end)
    if (!endDate && (this.owner && this.owner.lifeExpectancyDate)) {
      // Default to owner life expectancy
      endDate = isoToLocalDate(this.owner.lifeExpectancyDate)
    }
    return endDate
  }

  get endYear(): number {
    const date = this.endDate
    if (date) {
      return date.getFullYear()
    } else {
      return 0
    }
  }
}

export default Expense