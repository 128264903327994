import React, {ReactNode, SyntheticEvent, useEffect, useState} from "react";
import {useResources} from "../../../stores/ResourceProvider";
import {Box, FormLabel, Typography, useTheme} from "@mui/material";
import Model from "../../../model/Model";
import Plan from "../../../model/Plan";
import {FutureCalc} from "../../../components/calculator/Calculator";
import FutureViewTable, {IFutureViewSettings} from "./charts/FutureViewTable";
import FutureViewChart from "./charts/FutureViewChart";
import BudgetProjectionChart from "./charts/BudgetProjectionChart";
import BudgetProjectionTable from "./charts/BudgetProjectionTable";
import BucketsChart from "./charts/BucketsChart";
import ViewMenu from "../../../components/controls/ViewMenu";
import WithdrawalProjectionChart from "./charts/WithdrawalProjectionChart";
import NetWorthProjectionChart from "./charts/NetWorthProjectionChart";
import NetWorthProjectionTable from "./charts/NetWorthProjectionTable";
import TaxDisclaimer from "../../../components/page/TaxDisclaimer";
import Methodology from "../../../components/page/Methodology";
import RmdDisclaimer from "../../../components/page/RmdDisclaimer";
import Tracking from "../../../components/Tracking";
import ScenarioComparison from "./charts/ScenarioComparison";
import ScenarioComparisonSettingsDialog from "./ScenarioComparisonSettingsDialog";
import UserSetting, {UserSettingType} from "../../../model/UserSetting";

enum OptimizerChartView {
  NetLiquidAssets,
  NetWorthProjection,
  BudgetProjection,
  Buckets,
  WithdrawalProjection,
  ScenarioComparison
}


interface IOptimizerViewSettings {
  chartView: OptimizerChartView
  futureViewSettings: IFutureViewSettings
}

const OptimizerView = ({
  model,
  plan,
  updatedAt,
  width
} : {
  model: Model
  plan: Plan
  updatedAt: string
  width: number
}) => {
  const [viewStorageKey, setViewStorageKey] = useState<string>("")
  const [currentModel, setCurrentModel] = useState<Model | undefined>()
  const [currentPlan, setCurrentPlan] = useState<Plan | undefined>()
  const [chartView, setChartView] = useState<OptimizerChartView>(OptimizerChartView.NetLiquidAssets)
  const [futureViewSettings, setFutureViewSettings] = useState<IFutureViewSettings | undefined>()
  const [currentYear, setCurrentYear] = useState<number>(0)
  const [retireYear, setRetireYear] = useState<number>(0)
  const [futureCalcs, setFutureCalcs] = useState<FutureCalc[]>([])
  const [scenarioComparisonUserSetting, setScenarioComparisonUserSetting] = useState<UserSetting | undefined>()
  const [showScenarioComparisonSettings, setShowScenarioComparisonSettings] = useState<boolean>(false)

  const theme = useTheme()
  const styles = {
    formLabel: {
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.grey["700"],
    },
    miniButton: {
      height: 20,
      width: 40,
      borderRadius: "5px"
    },
  }

  const { modelStore, userStore, calculator, notify } = useResources()

  useEffect(() => {
    setCurrentModel(model)
    setCurrentPlan(plan)
    loadSettings()
    console.log(`OptimizerView Plan updatedAt: ${updatedAt}`)
  }, [model, plan, updatedAt])

  useEffect(() => {
    saveSettings()
  }, [chartView, futureViewSettings, viewStorageKey])

  useEffect(() => {
    calculateFuture()
  },[currentModel, currentModel?.updatedAt, currentPlan, currentPlan?.updatedAt])

  const loadSettings = () => {
    let storageKey = viewStorageKey
    if (!storageKey) {
      storageKey = `${modelStore.currentUser?.id}.OptimizerViewSettings`
      setViewStorageKey(storageKey)
    }
    const data = window.localStorage.getItem(storageKey)
    if (data) {
      const settings: IOptimizerViewSettings = JSON.parse(data)

      setChartView(settings.chartView ?? OptimizerChartView.NetLiquidAssets)

      if (settings.futureViewSettings) {
        setFutureViewSettings(settings.futureViewSettings)
      }
    }
  }

  const saveSettings = () => {
      const settings = JSON.stringify({
          chartView: chartView,
          futureViewSettings: futureViewSettings
      })
      window.localStorage.setItem(viewStorageKey, settings)
  }

  const calculateFuture = async () => {
    if (!currentModel || !currentPlan) {
      return
    }
    const plan = currentPlan

    let calcs: FutureCalc[] = []

    let maxEndYear: number = 0
    const latestLifeExpectancyDate = currentModel.getPlanEndDate(plan)
    if (latestLifeExpectancyDate) {
      const latestLifeExpectancyYear = latestLifeExpectancyDate.getFullYear()
      maxEndYear = latestLifeExpectancyYear
    }

    console.debug(`OptimizerView.calculateFuture to ${maxEndYear}`)
    const multiModel = false

    const label = multiModel ? `${currentModel.name} - ${plan.name}` : plan.name
    const calc = calculator.calculateFuture(currentModel, label, maxEndYear)
    calcs.push(calc)

    setFutureCalcs(calcs)

    if (modelStore.currentUser && userStore.isAdvisorOrAdmin) {
      let userSetting = modelStore.currentUser.settings.find((setting: UserSetting) => setting.settingType === UserSettingType.scenarioComparison)
      if (!userSetting) {
        // Default to the current scenario
        if (currentModel && currentPlan) {
          userSetting = new UserSetting({
            name: "Default",
            settings: JSON.stringify({
              scenarios: [
                {modelId: currentModel.id, planId: currentPlan.id}
              ]
            })
          })
        }
      }
      setScenarioComparisonUserSetting(userSetting)
    }
  }

  const renderViewMenu = () => {
    const options = ["Future View", "Net Worth Projection", "Budget Projection", "Buckets Strategy", "Withdrawal Projection"]
    if (userStore.isAdvisorOrAdmin) {
      options.push("Scenario Comparison")
    }
    let onSettings
    if (chartView === OptimizerChartView.ScenarioComparison) {
      onSettings = (view: number) => {
        setShowScenarioComparisonSettings(true)
      }
    }

    return (
      <ViewMenu
        view={chartView}
        options={options}
        variant="auto"
        onChange={(index: number) => {
          Tracking.event({action: "Optimizer View", label: options[index]})
          setChartView(index)
        }}
        onSettings={onSettings}
      />
    )
  }

  return(
    <Box sx={{
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      width: width - 24,
      maxWidth: width - 24,
      overflowX:"scroll"
    }}>
      <Box sx={{display:"flex", mt:1, pl:0, pb:0, mb:1}}>
        <Typography variant="h2" color="primary">Retirement Optimizer</Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignContent="left" pl={1} height={50}>
        <FormLabel sx={styles.formLabel}>View</FormLabel>
        {renderViewMenu()}
      </Box>
      <Box display="flex" flexDirection="column" flexGrow={1} pl={0} mb={1} mr={1} pr={0}>
        {chartView === OptimizerChartView.NetLiquidAssets && futureCalcs.length > 0 &&
          <React.Fragment>
            <FutureViewChart year={currentYear} futureCalcs={futureCalcs}/>
            {/*<Box sx={{display:"flex", flexDirection:"row", flexGrow:1, overflowX:"scroll", maxWidth:"80%"}}>*/}
              <FutureViewTable year={currentYear} futureCalcs={futureCalcs}
                               settings={futureViewSettings}
                               onChange={(settings: IFutureViewSettings) => setFutureViewSettings(settings)}
              />
            {/*</Box>*/}
          </React.Fragment>
        }
        {chartView === OptimizerChartView.NetWorthProjection && futureCalcs.length > 0 &&
          <React.Fragment>
            <NetWorthProjectionChart futureCalc={futureCalcs[0]}  year={currentYear}/>
            <NetWorthProjectionTable futureCalc={futureCalcs[0]}  year={currentYear}/>
          </React.Fragment>
        }
        {chartView === OptimizerChartView.BudgetProjection && futureCalcs.length > 0 &&
          <React.Fragment>
            <BudgetProjectionChart futureCalc={futureCalcs[0]} year={currentYear}/>
            <BudgetProjectionTable futureCalc={futureCalcs[0]} year={currentYear}/>
          </React.Fragment>
        }
        {chartView === OptimizerChartView.Buckets && futureCalcs.length > 0 &&
          <React.Fragment>
            <BucketsChart futureCalcs={futureCalcs}/>
          </React.Fragment>
        }
        {chartView === OptimizerChartView.WithdrawalProjection && futureCalcs.length > 0 &&
          <React.Fragment>
            <WithdrawalProjectionChart futureCalc={futureCalcs[0]} year={currentYear}/>
            {/*<WithdrawalProjectionTable futureCalc={futureCalcs[0]} year={currentYear}/>*/}
          </React.Fragment>
        }
        {chartView === OptimizerChartView.ScenarioComparison && futureCalcs.length > 0 &&
          <React.Fragment>
            <ScenarioComparison userSetting={scenarioComparisonUserSetting} futureCalc={futureCalcs[0]}/>
          </React.Fragment>
        }
      </Box>
      {showScenarioComparisonSettings &&
        <ScenarioComparisonSettingsDialog user={modelStore.currentUser!} userSetting={scenarioComparisonUserSetting}
                                  open={showScenarioComparisonSettings}
                                  onSave={(userSetting: UserSetting) => {
                                    setScenarioComparisonUserSetting(userSetting)
                                    setShowScenarioComparisonSettings(false)
                                  }}
                                  onClose={() => {
                                    setShowScenarioComparisonSettings(false)
                                  }}/>
      }
      {chartView !== OptimizerChartView.ScenarioComparison &&
        <Box sx={{display:"flex", flexDirection:"column", m:2}}>
          <Methodology/>
          <TaxDisclaimer/>
          <RmdDisclaimer/>
        </Box>
      }
    </Box>
  )
}


export default OptimizerView