import {useEffect, useState} from "react";
import {FormLabel, Grid, Typography, useTheme} from "@mui/material";
import User from "../../model/User";
import ModelCard2 from "../../components/model/ModelCard2";
import {SubscriptionIcon} from "../../styles/Icons";
import {UserType} from "../../API";
import {useResources} from "../../stores/ResourceProvider";
import {dateToLocalFormat, humanizeString, numberToMoneyFormat} from "../../stores/StoreUtilities";
import {compareAsc} from "date-fns";
import SubscriptionEditDialog from "./SubscriptionEditDialog";
import config from 'react-global-configuration';
import RoutesConfig from "../../RoutesConfig";
import {ConvertKitTag} from "../../apis/ConvertKitAPI";
import Tracking from "../../components/Tracking";

const SubscriptionCard = ({
  user,
  onSave,
}: {
  user: User
  onSave?(user: User): void
}) => {
  const [userModel, setUserModel] = useState<User>(user)
  const [title, setTitle] = useState<string>("")
  const [userType, setUserType] = useState<UserType>(UserType.Free)
  const [customer, setCustomer] = useState<any>()
  const [subscription, setSubscription] = useState<any>()
  const [subscriptionName, setSubscriptionName] = useState<string>("")
  const [status, setStatus] = useState<string>("")
  const [statusDescription, setStatusDescription] = useState<string>("")
  const [amount, setAmount] = useState<string>("")
  const [renewalDate, setRenewalDate] = useState<string>("")

  const [editOpen, setEditOpen] = useState<boolean>(false)

  const {billingAPI, convertKitAPI, userStore, notify} = useResources()

  useEffect(() => {
    init(user)
  }, [user])

  const init = (user: User) => {
    setUserModel(user)
    setTitle("Subscription Settings")
    setUserType(user.userType)
    if (user.userType === UserType.Premium && user.customerId) {
      billingAPI.getCurrentCustomer(user.customerId, false)
        .then((customer: any) => {
          if (customer) {
            setCustomer(customer)
            if (customer["subscriptions"] && customer["subscriptions"]["data"].length > 0) {
              const subscription = customer["subscriptions"]["data"][0]
              setSubscription(subscription)
              const plan = subscription["plan"]
              setSubscriptionName(plan["nickname"])
              let amt = plan["amount"] / 100
              const discount = subscription["discount"]
              if (discount && discount["coupon"]) {
                const coupon = discount["coupon"]
                if (coupon["percent_off"] !== null) {
                  amt -= amt * (coupon["percent_off"] / 100)
                } else if (coupon["amount_off"] !== null) {
                  amt -= coupon["amount_off"] / 100
                }
              }
              setAmount(numberToMoneyFormat(amt))
              let status = subscription["status"]
              setStatus(status)
              let description = ""
              if (subscription["cancel_at"]) {
                const cancelDate = new Date(subscription["cancel_at"] * 1000)
                const today = new Date()
                if (compareAsc(cancelDate, today) > 0) {
                  description = `Cancel on ${dateToLocalFormat(cancelDate)}`
                } else {
                  description = `Cancelled on ${dateToLocalFormat(cancelDate)}`
                }
                userStore.updateCancelDate(user, cancelDate)
                  .then((updated: boolean) => {
                    if (updated) {
                      console.log(`Updated user cancelDate`)
                      addConvertKitTag(ConvertKitTag.Downgrade, user.email)
                    }
                  })
                  .catch((err: any) => {
                    console.log(`Error updating user cancelDate`)
                  })
              } else {
                userStore.updateCancelDate(user, null)
                  .then((updated: boolean) => {
                    if (updated) {
                      console.log(`Updated user cancelDate`)
                      addConvertKitTag(ConvertKitTag.PremiumSubscription, user.email)
                    }
                  })
                  .catch((err: any) => {
                    console.log(`Error updating user cancelDate`)
                  })
                description = humanizeString(status)
              }
              setStatusDescription(description)
              if (status === "active") {
                const date = new Date(subscription["current_period_end"] * 1000)
                setRenewalDate(dateToLocalFormat(date))
              }
            } else {
              user.userType = UserType.Free
              setSubscriptionName("Free")
              setStatusDescription("Active")
            }
          }
        })
        .catch ((err: any) => {
          console.log(`Unable to load Stripe customer: ${user.customerId}`)
          setSubscriptionName(user.userType)
          setStatusDescription("Unknown")
        })
    } else {
      setSubscriptionName(user.userType)
      setStatusDescription("Active")
    }
  }

  const addConvertKitTag = async (tag: ConvertKitTag, email: string) => {
    convertKitAPI.addTag(tag, email)
      .then ((response: any) => {
        console.log(`Added Tag`, response)
      })
      .catch ((err: any) => {
        console.error("Add Tag Error", err)
      })
  }


  const showCustomerPortal = async () => {
    try {
      const returnUrl = `${config.get("baseUrl")}${RoutesConfig.settings.pathname}`
      const portalSession = await billingAPI.createCustomerPortalSession(customer.id, returnUrl)
      if (portalSession) {
        Tracking.event({action: "Stripe Portal Session Created"})
        window.location.href = portalSession.url
      }
    } catch (err: any) {
      notify.show("error", "Unable to open customer portal")
    }
  }

  const theme = useTheme()

  const styles = {
    heading: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    row: {
      height: 40,
      minHeight: 40,
      maxHeight: 40,
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "1.0em",
      maxHeight: 15,
      marginBottom: "1px",
      textWrap: "nowrap",
      color: theme.palette.grey["700"]
    },
    compact: {
      fontSize: 15,
      letterSpacing: -0.25
    }

  }

  const renderContent = () => {
    return (
      <Grid container direction="column" spacing={0}>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={6}>
              <FormLabel sx={styles.formLabel}>Subscription Type</FormLabel>
              <Typography variant="body2" sx={styles.compact}>
                {subscriptionName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormLabel sx={styles.formLabel}>Status</FormLabel>
              <Typography variant="body2" sx={styles.compact}>
                {statusDescription}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {amount &&
          <Grid item xs={12} sx={styles.row}>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={6}>
                <FormLabel sx={styles.formLabel}>Amount</FormLabel>
                <Typography variant="body2">
                  {amount}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormLabel sx={styles.formLabel}>Renewal Date</FormLabel>
                <Typography variant="body2">
                  {renewalDate}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>
    )
  }

  return (
    <ModelCard2
      icon={<SubscriptionIcon/>}
      title={title}
      expanded={true}
      editButtonId={`edit-btn-${userModel.id}`}
      onEdit={(event: any) => {
        if (status === "active" && customer && subscription) {
          showCustomerPortal()
        } else {
          setEditOpen(!editOpen)
        }
      }}
    >
      {renderContent()}
      {editOpen &&
        <SubscriptionEditDialog user={userModel}
                            open={editOpen}
                            onSave={(update: User) => {
                              init(update)
                              setEditOpen(false)
                              if (onSave) {
                                onSave(update)
                              }
                            }}
                            onClose={() => {
                              setEditOpen(false)
                            }}/>
      }
    </ModelCard2>
  )
}

export default SubscriptionCard