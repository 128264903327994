import * as React from "react";
import {useEffect, useState} from "react";
import Plan from "../../../model/Plan";
import Model from "../../../model/Model";
import PlanChange, {PlanChangeType} from "../../../model/PlanChange";
import ConversionStrategyChange from "../../../model/changes/ConversionStrategyChange";
import {Box, Checkbox, DialogContentText, FormControlLabel, Grid, IconButton, Radio, useTheme} from "@mui/material";
import {useResources} from "../../../stores/ResourceProvider";
import ModelEditDialog from "../../../components/model/ModelEditDialog";
import FormValidator from "../../../components/form/FormValidator";
import Asset, {AssetCategory, AssetType} from "../../../model/Asset";
import TextFieldValidator from "../../../components/form/TextFieldValidator";
import {AddCircleRounded} from "@mui/icons-material";
import {CreatePlanChangeInput, UpdatePlanChangeInput} from "../../../API";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import IconicButton from "../../../components/controls/IconicButton";
import AssetConversion, {AssetConversionType} from "../../../model/AssetConversion";
import {
  createUUID,
  getISODateTime,
  moneyToNumberFormat,
  numberToMoneyFormat,
  numberToPercentFormat
} from "../../../stores/StoreUtilities";
import {getIncomeTypeDef, IncomeType} from "../../../model/Income";
import Visible from "../../../components/Visible";
import Tracking from "../../../components/Tracking";

class ConversionEditDetail {
  srcAsset?: Asset
  dstAsset?: Asset
  year: string
  isAmount: boolean
  amount: string
  percent: string
  nonTaxableAmount: string
  incomeType: IncomeType

  constructor(data: any) {
    this.srcAsset = data.srcAsset ?? data.iraAsset
    this.dstAsset = data.dstAsset ?? data.rothAsset
    this.year = data.year ? data.year.toString() : ""
    this.isAmount = data.isAmount
    this.amount = data.amount ?? "$0"
    this.percent = data.percent ?? "100"
    this.nonTaxableAmount = data.nonTaxableAmount ?? "$0"
    this.incomeType = data.incomeType ?? IncomeType.None
  }
}

const ConversionStrategyDialog = ({
  open,
  plan,
  model,
  onClose,
  onSave
}: {
  open? : boolean
  plan: Plan
  model: Model
  onClose?(): void
  onSave?(plan: Plan, update: PlanChange): void
}) => {
  const [planModel, setPlanModel] = useState<Plan>(plan)
  const [isOpen, setIsOpen] = useState<boolean>(open === true)
  const [planChange, setPlanChange] = useState<ConversionStrategyChange | undefined>()
  const [editConversions, setEditConversions] = useState<ConversionEditDetail[]>([])
  const [srcAssets, setSrcAssets] = useState<Asset[]>([])
  const [dstAssets, setDstAssets] = useState<Asset[]>([])
  const [commitChanges, setCommitChanges] = useState<boolean>(false)

  const theme = useTheme()
  const styles = {
    itemBox: {
      display:"flex",
      flexGrow:1,
      alignItems:"center",
      // width: "100%",
      backgroundColor: theme.palette.grey["50"],
      borderWidth: 1,
      borderColor: theme.palette.background.default,
      borderStyle: "solid",
      borderRadius: 0,
      padding: 1,
      marginBottom: 1,
      boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px"
    },
    autoField: {
      marginTop: "8px"
    },
    radio: {
      paddingTop: 0,
      paddingLeft: 0
    }
  }

  const {modelStore, notify} = useResources()

  useEffect(() => {
    setPlanModel(plan)
    setIsOpen(open === true)
    let change: ConversionStrategyChange = plan.getChange(PlanChangeType.ConversionStrategy) as ConversionStrategyChange
    if (!change) {
      change = new ConversionStrategyChange({
        accountId: plan.accountId,
        userId: model.userId,
        modelId: model.id,
        planId: plan.id,
        changeType: PlanChangeType.ConversionStrategy,
        name: "Conversion Strategy",
        description: "",
        enabled: true,
        conversions: []
      })
    }
    setPlanChange(change)
    // let srcs = model.assets.filter((a: Asset) => a.assetTypeDef.assetType === AssetType.TraditionalIRA)
    let srcs = model.assets.filter((a: Asset) => a.assetCategory === AssetCategory.LiquidInvestableAssets || a.assetCategory === AssetCategory.RealEstateAndProperty)
    setSrcAssets(srcs)
    let dsts = model.assets.filter((a: Asset) => a.assetCategory === AssetCategory.LiquidInvestableAssets)
    setDstAssets(dsts)

    const editDetails: ConversionEditDetail[] = []
    if (change.conversions && change.conversions.length > 0) {
      change.conversions.forEach((c: AssetConversion) => {
        const srcAsset = srcs.find((a: Asset) => a.id === c.srcAssetId)
        const dstAsset = dsts.find((a: Asset) => a.id === c.dstAssetId)
        editDetails.push(new ConversionEditDetail({
          srcAsset: srcAsset,
          dstAsset: dstAsset,
          year: String(c.year),
          isAmount: !(c.percent > 0),
          amount: numberToMoneyFormat(c.amount, 0) ?? "",
          percent: (c.percent > 0) ? String(c.percent * 100) : "100",
          nonTaxableAmount: !isNaN(c.nonTaxableAmount) ? numberToMoneyFormat(c.nonTaxableAmount, 0) : numberToMoneyFormat(0, 0),
          incomeType: c.incomeType
        }))
      })
    } else {
      // Add an empty conversion by default
      editDetails.push(new ConversionEditDetail({}))
    }
    setEditConversions(editDetails)
  }, [plan, open])

  const handleClose = async (event: any) => {
    if (onClose) {
      onClose()
    }
  }

  const handleSave = async (event: any) => {
    try {
      let change: PlanChange | undefined

      const details: AssetConversion[] = []
      editConversions.forEach((conv: ConversionEditDetail) => {
        if (conv.srcAsset && conv.dstAsset && conv.year && conv.amount) {
          details.push(new AssetConversion({
            id: createUUID(),
            createdAt: getISODateTime(),
            accountId: plan.accountId,
            userId: plan.userId,
            modelId: plan.modelId,
            description: `${conv.year} ${conv.srcAsset.description} to ${conv.dstAsset.description}`,
            conversionType: conv.dstAsset.assetType === AssetType.RothIRA ? AssetConversionType.RothConversion : AssetConversionType.OtherConversion,
            srcAssetId: conv.srcAsset.id,
            dstAssetId: conv.dstAsset.id,
            year: parseInt(conv.year),
            amount: conv.isAmount ? moneyToNumberFormat(conv.amount, 0) : 0,
            percent: !conv.isAmount ? parseInt(conv.percent) / 100 ?? 0 : 0,
            nonTaxableAmount: moneyToNumberFormat(conv.nonTaxableAmount, 0),
            incomeType: conv.incomeType
          }))
        }
      })

      if (planChange && !planChange.id) {
        const input: CreatePlanChangeInput = {
          accountId: planChange?.accountId,
          userId: planChange.userId,
          modelId: planChange.modelId,
          planId: planChange.planId,
          changeType: planChange.changeType,
          name: planChange.name,
          description: generateDescription(),
          enabled: !commitChanges,
          details: JSON.stringify(details)
        }
        change = await modelStore.createPlanChange(input)
        Tracking.event({action: "Conversion Strategy Created"})
      } else if (planChange) {
        const input: UpdatePlanChangeInput = {
          id: planChange.id,
          description: generateDescription(),
          enabled: !commitChanges,
          details: JSON.stringify(details)
        }
        change = await modelStore.updatePlanChange(input)
        Tracking.event({action: "Conversion Strategy Updated"})
      }

      if (change && commitChanges) {
        await change.commit(model, modelStore)
        const input: UpdatePlanChangeInput = {
          id: change.id,
          description: "",
          enabled: false,
          details: JSON.stringify([])
        }
        change = await modelStore.updatePlanChange(input)
      }

      if (onSave && change) {
        onSave(plan, change)
      } else if (onClose) {
        onClose()
      }
    } catch (err: any) {
      notify.show('error', err.message)
    }
  }

  const generateDescription = () => {
    return `${editConversions.length} Conversions`
  }

    const handleAdd = () => {
    setEditConversions([...editConversions, new ConversionEditDetail({})])
  }

  const handleDelete = (index: number) => {
    editConversions.splice(index, 1)
    setEditConversions([...editConversions])
  }

  if (!planChange) {
    return null
  }

  const minYear = (new Date()).getFullYear()

  const incomeTypes: IncomeType[] = [
    IncomeType.None,
    IncomeType.WagesSalariesTips,
    IncomeType.LongTermCapitalGainsOrLoss,
    IncomeType.ShortTermCapitalGainsOrLoss,
    IncomeType.TaxableIRADistribution,
    IncomeType.OtherIncome
  ]

  const getDestinations = (srcAsset?: Asset): Asset[] => {
    let assetTypes: AssetType[] = []
    let assets: Asset[] = []

    if (srcAsset) {
      if (srcAsset.assetCategory === AssetCategory.LiquidInvestableAssets) {
        assetTypes = srcAsset.assetTypeDef.convertableTo
      } else if (srcAsset.assetCategory === AssetCategory.RealEstateAndProperty) {
        assetTypes = [AssetType.Brokerage, AssetType.Checking, AssetType.Savings, AssetType.MoneyMarket]
      }

      assets = dstAssets.filter((a: Asset) => a.id !== srcAsset.id && assetTypes.findIndex((at: AssetType) => at === a.assetType) >= 0)
      Model.sortAssets(assets)
    } else {
      assets = dstAssets
    }

    return assets
  }

  const renderAmountLabel = (conv: ConversionEditDetail) => {
    return (
      <Box display="inline-block">
        <Radio checked={conv.isAmount} sx={styles.radio}
               onChange={(event: any) => {
                 conv.isAmount = event.target.checked
                 setEditConversions([...editConversions])
               }}/>
        <span>Amount&nbsp;&nbsp;</span>
        <Radio checked={!conv.isAmount} sx={styles.radio} onChange={(event: any) => {
          conv.isAmount = !event.target.checked
          setEditConversions([...editConversions])
        }}/>
        <span>Percent</span>
      </Box>
    )
  }

  const commitDisabled = editConversions.length === 0

  return (
    <ModelEditDialog title="Conversion Strategy" open={isOpen} size="md"
                     onCancel={handleClose}
                     onSave={handleSave}
    >
      <DialogContentText>
        Test future IRA to Roth conversions, 401k rollovers, property sales or other conversions between assets.
      </DialogContentText>
      {editConversions.map((conv: ConversionEditDetail, index: number) =>
        <Box sx={styles.itemBox}>
          <Grid container spacing={1}>
            <Grid item xs={5} sx={styles.autoField}>
              <TextFieldValidator
                type="text"
                validators={{ required: true }}
                // required
                name="srcAsset"
                label="Source Asset"
                variant="standard"
                autocompleteOptions={{
                  freeSolo: false,
                  options: srcAssets,
                  getOptionLabel: (option: Asset) => option ? option.description : "None",
                  isOptionEqualToValue: (option: Asset, value: Asset) => option.id === value.id,
                  value: conv.srcAsset || null,
                  onChange: (event: any, value: Asset, reason: any) => {
                    const conv = editConversions[index]
                    conv.srcAsset = value
                    conv.amount = numberToMoneyFormat(value.balance, 0)
                    let dst = conv.dstAsset

                    if (value.assetCategory === AssetCategory.LiquidInvestableAssets) {
                      // Check if dest asset is convertable to
                      if (dst && !value.assetTypeDef.convertableTo.includes(dst.assetType)) {
                        conv.dstAsset = undefined
                        dst = undefined
                      }
                      if (value.assetTypeDef.isTaxableIRA && (!dst || !dst.assetTypeDef.isTaxableIRA)) {
                        conv.nonTaxableAmount = numberToMoneyFormat(0, 0)
                        conv.incomeType = IncomeType.TaxableIRADistribution
                      } else if (value.assetType === AssetType.Brokerage) {
                        conv.incomeType = IncomeType.ShortTermCapitalGainsOrLoss
                      } else {
                        conv.nonTaxableAmount = numberToMoneyFormat(0, 0)
                        conv.incomeType = IncomeType.None
                      }
                    } else if (value.assetCategory === AssetCategory.RealEstateAndProperty) {
                      conv.nonTaxableAmount = numberToMoneyFormat(0, 0)
                      conv.incomeType = IncomeType.LongTermCapitalGainsOrLoss
                    }

                    if (value.assetTypeDef && value.assetTypeDef.isTaxableIRA) {
                      conv.incomeType = IncomeType.TaxableIRADistribution
                      conv.nonTaxableAmount = numberToMoneyFormat(0, 0)
                    }
                    setEditConversions([...editConversions])
                  }
                }}
              />
            </Grid>
            <Grid item xs={5} sx={styles.autoField}>
              <TextFieldValidator
                type="text"
                validators={{ required: true }}
                // required
                name="dstAsset"
                label="Destination Asset"
                variant="standard"
                autocompleteOptions={{
                  freeSolo: false,
                  options: getDestinations(conv.srcAsset),
                  getOptionLabel: (option: Asset) => option ? option.description : "None",
                  isOptionEqualToValue: (option: Asset, value: Asset) => option.id === value.id,
                  value: conv.dstAsset || null,
                  onChange: (event: any, value: Asset, reason: any) => {
                    const conv = editConversions[index]
                    conv.dstAsset = value
                    const srcAsset = conv.srcAsset
                    if (srcAsset && value) {
                      if (srcAsset.assetCategory === AssetCategory.LiquidInvestableAssets) {
                        if (srcAsset.assetTypeDef.isTaxableIRA && (!value.assetTypeDef.isTaxableIRA)) {
                          conv.nonTaxableAmount = numberToMoneyFormat(0, 0)
                          conv.incomeType = IncomeType.TaxableIRADistribution
                        } else if (srcAsset.assetType === AssetType.Brokerage) {
                          conv.incomeType = IncomeType.ShortTermCapitalGainsOrLoss
                        } else {
                          conv.nonTaxableAmount = numberToMoneyFormat(0, 0)
                          conv.incomeType = IncomeType.None
                        }
                      }
                    }

                    setEditConversions([...editConversions])
                  }
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <TextFieldValidator
                type="number"
                validators={{ required: true, isInt: true, minValue: minYear }}
                name="year"
                variant="standard"
                margin="dense"
                label="Year"
                value={conv.year}
                onChange={(event: any) => {
                  editConversions[index].year = event.target.value
                  setEditConversions([...editConversions])
                }}
              />
            </Grid>
            <Grid item xs={1} sx={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
              <IconicButton
                onClick={() => handleDelete(index)}>
                <DeleteIcon/>
              </IconicButton>
            </Grid>
            <Grid item xs={5}>
              <Box display="flex" justifyContent="space-between">
                <Visible cond={conv.isAmount}>
                  <TextFieldValidator
                    type="string"
                    validators={{ required: conv.isAmount, isMoney: true, minMoney: 0 }}
                    name="amount"
                    variant="standard"
                    margin="dense"
                    label={renderAmountLabel(conv)}
                    value={conv.amount}
                    onChange={(event: any) => {
                      const value = event.target.value === "" ? "$0" : event.target.value
                      editConversions[index].amount = value
                      if (conv.srcAsset && conv.srcAsset.assetTypeDef.isTaxableIRA) {
                        editConversions[index].nonTaxableAmount = "$0"
                      }
                      setEditConversions([...editConversions])
                    }}
                  />
                </Visible>
                <Visible cond={!conv.isAmount}>
                  <TextFieldValidator
                    type="string"
                    validators={{ required: !conv.isAmount, isInt: true, minValue: 0, maxValue: 100 }}
                    name="percent"
                    variant="standard"
                    margin="dense"
                    label={renderAmountLabel(conv)}
                    value={conv.percent}
                    onChange={(event: any) => {
                      const value = event.target.value === "" ? "0" : event.target.value
                      editConversions[index].percent = value
                      setEditConversions([...editConversions])
                    }}
                  />
                </Visible>
              </Box>
            </Grid>
            <Grid item xs={5} sx={styles.autoField}>
              <TextFieldValidator
                type="text"
                validators={{ required: true }}
                required
                name="incomeType"
                label="Taxable Income Type"
                variant="standard"
                autocompleteOptions={{
                  freeSolo: false,
                  options: incomeTypes,
                  getOptionLabel: (option: number) => {
                    const incomeTypeDef = getIncomeTypeDef(option)
                    return(incomeTypeDef.label)
                  },
                  value: conv.incomeType,
                  onChange: (event: any, value: number, reason: any) => {
                    editConversions[index].incomeType = value
                    setEditConversions([...editConversions])
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" justifyContent="space-between">
                <TextFieldValidator
                  type="string"
                  validators={{ required: true, isMoney: true }}
                  name="nonTaxableAmount"
                  variant="standard"
                  margin="dense"
                  label="Tax Exclude Amount"
                  value={conv.nonTaxableAmount}
                  onChange={(event: any) => {
                    editConversions[index].nonTaxableAmount = event.target.value
                    setEditConversions([...editConversions])
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box display="flex" justifyContent="center">
        <IconButton sx={{height:28, width:28}} onClick={handleAdd}>
          <AddCircleRounded sx={{color: theme.palette.secondary.light}}/>
        </IconButton>
      </Box>
      <Box display="flex">
        <FormControlLabel control={
          <Checkbox checked={commitChanges}
                    disabled={commitDisabled}
                    onChange={(event: any) => {
                      setCommitChanges(event.target.checked)
                    }}
          />}
          label={`Commit these conversions to ${model.name} to make them permanent.`}
          sx={{marginTop:1}}
          componentsProps={{
            typography: {
              variant:"body2"
            }}
          }
        />
      </Box>
    </ModelEditDialog>
  )
}

export default ConversionStrategyDialog