import PlanChange from "../PlanChange";
import Model from "../Model";
import ModelStore from "../../stores/ModelStore";
import Expense from "../Expense";

export enum ExpenseFilter {
  All = "All",
  Essential = "Essential",
  Discretionary = "Discretionary"
}

export interface IExpenseStrategyChange {
  filter: ExpenseFilter
}

class ExpenseStrategyChange extends PlanChange implements IExpenseStrategyChange {
  filter: ExpenseFilter
  constructor (data: any) {
    super(data)
    const details = this.details ? JSON.parse(this.details) : {}
    this.filter = details.filter ?? ExpenseFilter.All
  }

  apply(model: Model) {
    if (this.filter && this.filter !== ExpenseFilter.All) {
      model.expenses.forEach((e: Expense) => {
        if ((this.filter === ExpenseFilter.Essential && e.discretionary) ||
            (this.filter === ExpenseFilter.Discretionary && !e.discretionary)) {
          e.amount = 0
          model.addLock(e.id, "amount")
        }
      })
    }
  }

  async commit(model: Model, modelStore: ModelStore) {
    if (this.filter) {
      // TODO

    }
  }
}

export default ExpenseStrategyChange