import React, {ReactElement, ReactNode} from "react";
import {
  Box,
  Collapse,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText, Tooltip,
  Typography, useTheme
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {ExpandLess, ExpandMore} from "@mui/icons-material";


const NavListItem = ({
  id,
  icon,
  primary,
  primaryColor,
  secondary,
  secondaryAction,
  title,
  divider,
  selected,
  checked,
  expanded,
  drawerOpen,
  onClick,
  onChange,
  children
}: {
  id: string
  icon?: ReactElement
  primary: string | ReactNode
  primaryColor?: string
  secondary?: string | ReactNode
  secondaryAction?: ReactNode
  title?: string
  divider?: boolean
  selected?: boolean
  checked?: boolean
  expanded?: boolean
  drawerOpen?: boolean
  onClick?(name: string): void
  onChange?(expand: boolean): void
  children?: ReactNode
}) => {

  const theme = useTheme()

  if (!secondaryAction && children && onChange) {
    secondaryAction = <IconButton onClick={(event: any) => onChange(!expanded)}>
      {expanded ? <ExpandLess/> : <ExpandMore />}
    </IconButton>
  }

  let primaryContent
  if (selected && typeof primary === "string") {
    // primaryContent = <b>{primary}</b>
    primaryContent = <Typography variant="body2" sx={{color: theme.palette.action.selected, fontWeight:700}}>{primary}</Typography>
  } else {
    if (typeof primary === "string" && primaryColor) {
      primaryContent = <Typography variant="body2" sx={{color: primaryColor}}>{primary}</Typography>
    } else {
      primaryContent = primary
    }
  }

  return (
    <React.Fragment>
    <ListItem key={id} disablePadding sx={{ display:'block', minWidth:260 }}
              secondaryAction={secondaryAction}
              divider={divider}
              dense={false}
              disableGutters
    >
      <ListItemButton
        selected={selected}
        sx={{
          // minHeight: 48,
          justifyContent: drawerOpen === true ? 'initial' : 'center',
          pl: 2,
          pr: 0,
          pt: 0,
          pb: 0,
        }}
        onClick={(event: any) => onClick ? onClick(id) : {}}
      >
        {icon &&
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: drawerOpen === true ? 1 : 'auto',
              justifyContent: 'center',
              color: selected ? theme.palette.action.selected : theme.palette.text.secondary
            }}
          >
            <Tooltip title={title ?? ""} placement="right" arrow>
              {icon}
            </Tooltip>
          </ListItemIcon>

        }
        <ListItemText primary={primaryContent} secondary={secondary} sx={{ opacity: drawerOpen ? 1 : 0 }} />
        {/*{children && expanded ? <ExpandLess /> : children ? <ExpandMore /> : null}*/}
      </ListItemButton>
    </ListItem>
      {children !== undefined &&
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{
            justifyContent: drawerOpen ? 'initial' : 'center',
            px: 4,
            pt: 0,
            pb: 0
          }}>
            {children}
          </Box>
        </Collapse>
      }
    </React.Fragment>
  )
}

export default NavListItem