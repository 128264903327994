import PlanChange, {PlanChangeType} from "./PlanChange";
import GrowthStrategyChange from "./changes/GrowthStrategyChange";
import Model from "./Model";
import {UpdatePlanInput} from "../API";
import TimelineStrategyChange from "./changes/TimelineStrategyChange";
import SocialSecurityStrategyChange from "./changes/SocialSecurityStrategyChange";
import WithdrawalStrategyChange from "./changes/WithdrawalStrategyChange";
import ConversionStrategyChange from "./changes/ConversionStrategyChange";
import IModelItem from "./IModelItem";
import ExpenseStrategyChange from "./changes/ExpenseStrategyChange";
import InflationStrategyChange from "./changes/InflationStrategyChange";

export class MilestoneValue {
  id: string
  value: string

  constructor(data: any) {
    this.id = data.id
    this.value = data.value
  }
}

class Plan implements IModelItem {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  userId: string
  modelId: string
  name: string
  description: string
  default: boolean
  changes: PlanChange[]

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.userId = data.userId
    this.modelId = data.modelId
    this.name = data.name
    this.description = data.description
    this.default = data.default
    this.changes = []
    if (data.changes && data.changes.items) {
      this.loadChanges(data.changes.items)
    }
  }

  update(input: UpdatePlanInput, plan: Plan) {
    if (plan.updatedAt) {
      this.updatedAt = plan.updatedAt
    }
    if (input.name !== undefined) {
      this.name = plan.name
    }
    if (input.description !== undefined) {
      this.description = plan.description
    }
    if (input.default !== undefined) {
      this.default = plan.default
    }
  }

  loadChanges(items: any[]) {
    this.changes = items.map((item: any) => {
      if (item.changeType === PlanChangeType.ConversionStrategy) {
        return new ConversionStrategyChange((item))
      } else if (item.changeType === PlanChangeType.ExpenseStrategy) {
        return new ExpenseStrategyChange(item)
      } else if (item.changeType === PlanChangeType.GrowthStrategy) {
        return new GrowthStrategyChange(item)
      } else if (item.changeType === PlanChangeType.InflationStrategy) {
        return new InflationStrategyChange(item)
      } else if (item.changeType === PlanChangeType.SocialSecurityStrategy) {
        return new SocialSecurityStrategyChange(item)
      } else if (item.changeType === PlanChangeType.TimelineStrategy) {
        return new TimelineStrategyChange(item)
      } else if (item.changeType === PlanChangeType.WithdrawalStrategy) {
        return new WithdrawalStrategyChange((item))
      } else {
        return new PlanChange(item)
      }
    })
    // Put latest updated ones first
    this.changes.sort((a: PlanChange, b: PlanChange) => b.updatedAt.localeCompare(a.updatedAt))
  }

  getChange(changeType: PlanChangeType): PlanChange | undefined {
    return this.changes.find((change: PlanChange) => change.changeType === changeType)
  }

  setChange(change: PlanChange) {
    const index = this.changes.findIndex((item: PlanChange) => item.changeType === change.changeType)
    if (index >= 0) {
      this.changes.splice(index, 1, change)
    } else {
      this.changes.push(change)
    }
  }

  applyChanges(model: Model) {
    this.changes.forEach((change: PlanChange) => {
      if (change.enabled) {
        change.apply(model)
      }
    })

    model.appliedPlan = this
    model.updateJointPerson()
  }
}

export default Plan