import Page from "../../components/page/Page";
import {Box, Divider, IconButton, List, Typography, useTheme} from "@mui/material";
import MarginRow from "../../components/page/MarginRow";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import React, {useEffect, useState} from "react";
import theme from "../../styles/theme";
import Drawer, {DrawerHeader, openDrawerWidth} from "../../components/mui/Drawer";
import {useResources} from "../../stores/ResourceProvider";
import NavListItem from "../../components/controls/NavListItem";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import RoutesConfig, {RoutesConfigHelpers} from "../../RoutesConfig";
import {useLocation, useNavigate} from "react-router-dom";
import MigrateUsersView from "./MigrateUsersView";
import ManageUsersView from "./ManageUsersView";
import Footer from "../../components/page/Footer";

const AdminPage = () => {
  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState<boolean>(true)
  const [view, setView] = useState<string | undefined>("legacy-users")
  const viewNames = ["users", "migrate"]

  const {userStore} = useResources()
  const isAdmin = userStore.isAdmin
  const isAdviser = userStore.isAdvisor

  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()

  useEffect(() => {
    const viewName = RoutesConfigHelpers.getPathSegment(location.pathname, 2)
    if (viewName && viewNames.indexOf(viewName) >= 0) {
      setView(viewName)
    } else {
      navigate(`${RoutesConfig.admin.pathname}/users`)
    }
  }, [navigate])

  const handleDrawerClose = () => {

  }

  const onViewChange = (view: string) => {
    navigate(`${RoutesConfig.admin.pathname}/${view}`)
  }

  const renderDrawer = () => {
    const drawerLabel = isAdmin ? "Retirement Admin" : "Retirement Admin"

    return (
      <Drawer variant="permanent" open={isNavDrawerOpen}
              PaperProps={{
                sx: {
                  backgroundColor: theme.palette.background.default,
                  marginTop: "70px",
                  borderStyle:"none"
                }
              }}
      >
        <Divider />
        <Box sx={{display:"flex", mt:3.5, pl:2, pb:0, mb:0,}}>
          <Typography variant="h5" color="primary">{drawerLabel}</Typography>
        </Box>
        <List dense>
          <NavListItem id="users"
                       icon={<ManageAccountsIcon/>}
                       primary="Manage Users"
                       selected={view === "users"}
                       onClick={onViewChange}
                       drawerOpen={true}
          />
          <NavListItem id="migrate"
                       icon={<CloudDownloadIcon/>}
                       primary="Migrate Users"
                       selected={view === "migrate"}
                       onClick={onViewChange}
                       drawerOpen={true}
          />
        </List>
      </Drawer>
    )
  }

  return (
    <Page title="Admin">
      <MarginRow>
        {renderDrawer()}
        <Box component="main" sx={{ flexGrow:1, ml:`${openDrawerWidth}px`}}>
          {view === "users" &&
            <ManageUsersView />
          }
          {view === 'migrate' &&
            <MigrateUsersView/>
          }
          <Footer />
        </Box>
      </MarginRow>
    </Page>
  )

}

export default AdminPage