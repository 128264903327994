import {
  Box,
  Checkbox,
  DialogContentText,
  FormControlLabel,
  Grid,
  InputAdornment,
  Typography,
  useTheme
} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import TextFieldValidator from "../../../components/form/TextFieldValidator";
import {humanizeString, numberToPercentFormat} from "../../../stores/StoreUtilities";
import {CreatePlanChangeInput, UpdatePlanChangeInput} from "../../../API";
import {useResources} from "../../../stores/ResourceProvider";
import Plan from "../../../model/Plan";
import Model from "../../../model/Model";
import PlanChange, {PlanChangeType} from "../../../model/PlanChange";
import ModelEditDialog from "../../../components/model/ModelEditDialog";
import Tracking from "../../../components/Tracking";
import InflationStrategyChange from "../../../model/changes/InflationStrategyChange";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const InflationStrategyDialog = ({
  open,
  plan,
  model,
  onClose,
  onSave
}: {
  open? : boolean
  plan: Plan
  model: Model
  onClose?(): void
  onSave?(plan: Plan, update: PlanChange): void
}) => {
  const [planModel, setPlanModel] = useState<Plan>(plan)
  const [isOpen, setIsOpen] = useState<boolean>(open === true)
  const [inflationStrategyChange, setInflationStrategyChange] = useState<InflationStrategyChange | undefined>()
  const [name, setName] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [deductionRate, setDeductionRate] = useState<string | undefined>()
  const [deductionLock, setDeductionLock] = useState<boolean>(false)
  const [expenseRate, setExpenseRate] = useState<string | undefined>()
  const [expenseLock, setExpenseLock] = useState<boolean>(false)
  const [incomeRate, setIncomeRate] = useState<string | undefined>()
  const [incomeLock, setIncomeLock] = useState<boolean>(false)
  const [taxRate, setTaxRate] = useState<string | undefined>()
  const [taxLock, setTaxLock] = useState<boolean>(false)

  const theme = useTheme()
  const { modelStore, notify } = useResources()

  const styles = {
    actions: {
      display:"flex",
      flexDirection:"row",
      width:"100%",
      justifyContent:"flex-end",
      alignItems:"center",
      marginTop: theme.spacing(1)
    },
    row: {
      maxHeight: 48
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"]
    },
    stateField: {
      top: 5,
      width: "100%"
    },
    detailText: {
      fontSize: 14
    }
  }

  useEffect(() => {
    setPlanModel(plan)
    setIsOpen(open === true)
    let change: InflationStrategyChange = plan.getChange(PlanChangeType.InflationStrategy) as InflationStrategyChange
    if (!change) {
      change = new InflationStrategyChange({
        accountId: plan.accountId,
        userId: model.userId,
        modelId: model.id,
        planId: plan.id,
        changeType: PlanChangeType.InflationStrategy,
        name: "Inflation Strategy",
        description: "",
        enabled: true,
        details: JSON.stringify({
          deduction: {
            rate: 0.03,
            lock: false
          },
          expense: {
            rate: 0.03,
            lock: false
          },
          income: {
            rate: 0.03,
            lock: false
          },
          tax: {
            rate: 0.02,
            lock: false
          }
        })
      })
    }
    setInflationStrategyChange(change)
    setName("Inflation Strategy")
    setDescription(change.description)
    setDeductionRate(numberToPercentFormat(change.deduction.rate * 100, 2, 'decimal'))
    setDeductionLock(change.deduction.lock === true)
    setExpenseRate(numberToPercentFormat(change.expense.rate * 100, 2, 'decimal'))
    setExpenseLock(change.expense.lock === true)
    setIncomeRate(numberToPercentFormat(change.income.rate * 100, 2, 'decimal'))
    setIncomeLock(change.income.lock === true)
    setTaxRate(numberToPercentFormat(change.tax.rate * 100, 2, 'decimal'))
    setTaxLock(change.tax.lock === true)
  }, [plan, open])

  const handleClose = async (event: any) => {
    if (onClose) {
      onClose()
    }
  }

  const handleSave = async (event: any) => {
    try {
      let change: PlanChange | undefined

      if (inflationStrategyChange && !inflationStrategyChange.id) {
        const input: CreatePlanChangeInput = {
          accountId: inflationStrategyChange?.accountId,
          userId: inflationStrategyChange.userId,
          modelId: inflationStrategyChange.modelId,
          planId: inflationStrategyChange.planId,
          changeType: inflationStrategyChange.changeType,
          name: name ?? inflationStrategyChange.name,
          description: generateDescription(),
          enabled: true,
          details: JSON.stringify({
            deduction: {
              rate: deductionRate !== undefined ? parseFloat(deductionRate) / 100.0 : 0.03,
              lock: deductionLock
            },
            expense: {
              rate: expenseRate !== undefined ? parseFloat(expenseRate) / 100.0 : 0.03,
              lock: expenseLock
            },
            income: {
              rate: incomeRate !== undefined ? parseFloat(incomeRate) / 100.0 : 0.03,
              lock: incomeLock
            },
            tax: {
              rate: taxRate !== undefined ? parseFloat(taxRate) / 100.0 : 0.02,
              lock: taxLock
            }
          })
        }
        change = await modelStore.createPlanChange(input)
        Tracking.event({action: "Inflation Strategy Created"})
      } else if (inflationStrategyChange) {
        const input: UpdatePlanChangeInput = {
          id: inflationStrategyChange.id,
          name: name,
          description: generateDescription(),
          enabled: true,
          details: JSON.stringify({
            deduction: {
              rate: deductionRate !== undefined ? parseFloat(deductionRate) / 100.0 : 0.03,
              lock: deductionLock
            },
            expense: {
              rate: expenseRate !== undefined ? parseFloat(expenseRate) / 100.0 : 0.03,
              lock: expenseLock
            },
            income: {
              rate: incomeRate !== undefined ? parseFloat(incomeRate) / 100.0 : 0.03,
              lock: incomeLock
            },
            tax: {
              rate: taxRate !== undefined ? parseFloat(taxRate) / 100.0 : 0.02,
              lock: taxLock
            }
          })
        }
        change = await modelStore.updatePlanChange(input)
        Tracking.event({action: "Inflation Strategy Updated"})
      }

      if (onSave && change) {
        onSave(plan, change)
      } else if (onClose) {
        onClose()
      }
    } catch (err: any) {
      notify.show('error', err.message)
    }
  }

  const generateDescription = () => {
    let desc = ""
    desc += `Deductions: ${deductionRate}% (${deductionLock ? 'locked' : 'default'}), `
    desc += `Expenses: ${expenseRate}% (${expenseLock ? 'locked' : 'default'}), `
    desc += `Incomes: ${incomeRate}% (${incomeLock ? 'locked' : 'default'}), `
    desc += `Taxes: ${taxRate}% (${taxLock ? 'locked' : 'default'})`
    return desc
  }

  if (!inflationStrategyChange) {
    return null
  }

  return (
    <ModelEditDialog title="Inflation Strategy" open={true} size="sm"
                     onCancel={handleClose}
                     onSave={handleSave}
    >
      <DialogContentText>
        Set default inflation rates and optionally lock (override) custom rates on budget items.
      </DialogContentText>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextFieldValidator
            type="number"
            validators={{ required: true, minValue: 0 }}
            name="incomeRate"
            variant="standard"
            margin="dense"
            fullWidth
            label="Income Inflation %"
            helperText={incomeLock ? "Income inflation locked rate" : "Income inflation default rate"}
            value={incomeRate}
            onChange={(event: any) => setIncomeRate(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Checkbox name="incomeLock" value={true}
                            checked={incomeLock}
                            icon={<LockOpenIcon />}
                            checkedIcon={<LockIcon />}
                            onClick={(event: any) => setIncomeLock(event.target.checked)}
                  />                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldValidator
            type="number"
            validators={{ required: true, minValue: 0 }}
            name="expenseRate"
            variant="standard"
            margin="dense"
            fullWidth
            label="Expense Inflation %"
            helperText={expenseLock ? "Expense inflation locked rate" : "Expense inflation default rate"}
            value={expenseRate}
            onChange={(event: any) => setExpenseRate(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Checkbox name="expenseLock" value={true}
                            checked={expenseLock}
                            icon={<LockOpenIcon />}
                            checkedIcon={<LockIcon />}
                            onClick={(event: any) => setExpenseLock(event.target.checked)}
                  />
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
      
      <Grid container spacing={2} sx={{mt:1}}>
        <Grid item xs={12} sm={6}>
          <TextFieldValidator
            type="number"
            validators={{ required: true, minValue: 0 }}
            name="deductionRate"
            variant="standard"
            margin="dense"
            fullWidth
            label="Deduction Inflation %"
            helperText={deductionLock ? "Deduction inflation locked rate" : "Deduction inflation default rate"}
            value={deductionRate}
            onChange={(event: any) => setDeductionRate(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Checkbox name="deductionLock" value={true}
                            checked={deductionLock}
                            icon={<LockOpenIcon />}
                            checkedIcon={<LockIcon />}
                            onClick={(event: any) => setDeductionLock(event.target.checked)}
                  />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldValidator
            type="number"
            validators={{ required: true, minValue: 0 }}
            name="taxRate"
            variant="standard"
            margin="dense"
            fullWidth
            label="Tax Inflation %"
            helperText={taxLock ? "Tax inflation locked rate" : "Tax inflation default rate"}
            value={taxRate}
            onChange={(event: any) => setTaxRate(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Checkbox name="taxLock" value={true}
                            checked={taxLock}
                            icon={<LockOpenIcon />}
                            checkedIcon={<LockIcon />}
                            onClick={(event: any) => setTaxLock(event.target.checked)}
                  />
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>

    </ModelEditDialog>
  )
}

export default InflationStrategyDialog