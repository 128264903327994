import {Typography} from "@mui/material";
import {useResources} from "../../stores/ResourceProvider";
import {Link} from "@reach/router";
import * as React from "react";
import globalStyles from "../../styles/globalStyles";

const RmdDisclaimer = () => {
  const {termsOfUse} = useResources()

  return (
    <Typography variant="body2" gutterBottom sx={{mt:1}}>
      <strong>RMD Disclaimer</strong>&nbsp;
      The required minimum distribution (RMD) calculations are complex and can change depending on your age, the age of the original account owner, the age of a spouse, the timing and receipt of an inheritance, account values and choices you have when beginning distributions. These RMD estimates are intended to account for many of the most common calculations but do not include all possible scenarios. Future RMD’s are in part an estimate based on the growth model of accounts and the timing of distributions. There may be IRS penalties for mistakes when missing or not taking your full RMD. When an IRA is inherited before 2020 we assume the life expectancy method. When inherited after 2019 we assume equal distributions over 10 years. Please consult with the appropriate financial professionals for assistance as well as IRS publications, and do not rely on the estimates within the Retirement Budget Calculator. Please review our&nbsp;
      <Link to="." onClick={() => termsOfUse.show()} style={globalStyles.link}>Terms of Use</Link>.
    </Typography>
  )
}

export default RmdDisclaimer