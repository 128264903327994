const AppConfig = {
  env: process.env.REACT_APP_ENV,
  version: process.env.REACT_APP_VERSION,
  domain: process.env.REACT_APP_DOMAIN ?? "retirementbudgetcalculator.com",
  baseUrl: process.env.REACT_APP_BASE_URL,
  homeUrl: process.env.REACT_APP_HOME_URL,
  investUrl: process.env.REACT_APP_INVEST_URL,
  branch: process.env.REACT_APP_BRANCH,
  google: {
    trackingId: process.env.REACT_APP_GOOGLE_TRACKING_ID,
    options: {
    }
  },
  migrateOnSignin: process.env.REACT_APP_MIGRATE_ON_SIGNIN === "true",
  stripe: {
    key: process.env.REACT_APP_STRIPE_KEY
  },
  convertKit: {
    enabled: process.env.REACT_APP_CONVERTKIT_ENABLED === "true"
  },
  webFlow: {
    enabled: process.env.REACT_APP_WEBFLOW_ENABLED === "true"
  }
};

export default AppConfig;