import React, {useEffect, useState} from 'react'
import Chart from "react-apexcharts";
import {IBudgetCalc, IExpenseCategorySummary, IFutureYear} from "../../../../components/calculator/Calculator";
import {numberToMoneyFormat} from "../../../../stores/StoreUtilities";
import {globalColors} from "../../../../styles/globalStyles"
import {Paper, TableContainer} from "@mui/material";
import {ExpenseCategory} from "../../../../model/Expense";
import cloneDeep from "lodash.clonedeep";
import MonthlyBudgetTable from "./MonthlyBudgetTable";
import {useResources} from "../../../../stores/ResourceProvider";

const defaultOptions = {
  chart: {
    id: "annual-budget",
    type: 'bar',
    height: 350,
    width: "100%",
    zoom: {
      autoScaleYaxis: true
    },
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      }
    },
    toolbar: {
      show: false,
      tools: {
        download: false
      }
    }
  },
  dataLabels: {
    enabled: true,
    formatter: function (value: number) {
      return numberToMoneyFormat(value, 0)
    },
    offsetY: -20,
    style: {
      fontSize: '12px',
      colors: ["#304758"]
    }
  },
  legend: {
    show: false,
    position: 'top',
    horizontalAlign: 'left'
  },
  xaxis: {
    axisBorder: {
      show: true
    }
  },
  yaxis: {
    // max: 15000,
    labels: {
      formatter: (value: number) => {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  tooltip: {
    y: {
      formatter: function (value: number) {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  plotOptions: {
    bar: {
      distributed: true,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    }
  },
  colors: [globalColors.income,
    globalColors.withdrawal,
    globalColors.deductionExpense,
    globalColors.taxExpense,
    globalColors.estimatedTax, globalColors.essentialExpense, globalColors.discretionaryExpense,
    globalColors.expenseCategory[ExpenseCategory.Housing], globalColors.expenseCategory[ExpenseCategory.LoansAndLiabilities],
    globalColors.expenseCategory[ExpenseCategory.FoodAndPersonalCare], globalColors.expenseCategory[ExpenseCategory.InsuranceAndMedical],
    globalColors.expenseCategory[ExpenseCategory.VehiclesAndTransportation],
    globalColors.expenseCategory[ExpenseCategory.TravelAndEntertainment],
    globalColors.expenseCategory[ExpenseCategory.GivingAndMisc]],
  responsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const MonthBudgetApexChart = ({
  budgetCalc,
  futureYear,
  month,
  onChange
}: {
  budgetCalc: IBudgetCalc
  futureYear: IFutureYear
  month: number
  onChange?: (month?: number) => any
}) => {
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<any>()

  const { userStore } = useResources()

  useEffect(() => {
    calculateData()
  }, [budgetCalc, month])

  // const monthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const handleChangeMonth = (month?: number) => {
    if (onChange) {
      onChange(month)
    }
  }
  const calculateData = () => {
    if (!budgetCalc || month === undefined) {
      return
    }

    let data = [
      {x: 'Income', y: budgetCalc.incomeSummary.byMonth[month]},
      {x: 'Avg Distributions', y: futureYear.allDistributions / 12},
      {x: 'Deductions & Contributions', y: budgetCalc.deductionSummary.byMonth[month]},
      {x: 'Tax Withholding', y: budgetCalc.taxSummary.byMonth[month]},
      {x: 'Estimated Taxes', y: budgetCalc.totalEstimatedTaxes/12},
      {x: 'Essential Expenses', y: budgetCalc.expenseSummary.byMonthEssential[month]},
      {x: 'Discretionary Expenses', y: budgetCalc.expenseSummary.byMonthDiscretionary[month]},
      {x: 'Housing', y: getExpenseCategoryTotal(ExpenseCategory.Housing)},
      {x: 'Loans & Liabilities', y: getExpenseCategoryTotal(ExpenseCategory.LoansAndLiabilities)},
      {x: 'Food & Personal Care', y: getExpenseCategoryTotal(ExpenseCategory.FoodAndPersonalCare)},
      {x: 'Insurance & Medical', y: getExpenseCategoryTotal(ExpenseCategory.InsuranceAndMedical)},
      {x: 'Vehicles & Transportation', y: getExpenseCategoryTotal(ExpenseCategory.VehiclesAndTransportation)},
      {x: 'Travel & Entertainment', y: getExpenseCategoryTotal(ExpenseCategory.TravelAndEntertainment)},
      {x: 'Givng & Misc', y: getExpenseCategoryTotal(ExpenseCategory.GivingAndMisc)},
    ]
    const options = cloneDeep(defaultOptions)

    if (userStore.isFree) {
      // Remove Distributions and Estimated taxes
      data.splice(4, 1)
      data.splice(1, 1)
      options.colors.splice(4, 1)
      options.colors.splice(1, 1)
    }

    const series = [
      {
        name: "Month Budget",
        data: data
      },
    ]
    setSeries(series)
    setOptions(options)
  }

  const getExpenseCategoryTotal = (category: ExpenseCategory) => {
    let total = 0
    if (budgetCalc) {
      const summary = budgetCalc.expenseSummary.byCategory.find((summary: IExpenseCategorySummary) => summary.category === category)
      if (summary) {
        if (month !== undefined && summary.byMonth.length >= month+1) {
          total = summary.byMonth[month]
        } else {
          total = 0
        }
      }
    }
    return total
  }

  if (options && series) {
    return (
      <React.Fragment>
        <TableContainer component={Paper} sx={{paddingTop:1, overflowY:"hidden"}}>
          <Chart
            options={options}
            series={series}
            type="bar"
            height="500"
            width="100%"
          />
        </TableContainer>

        <MonthlyBudgetTable budgetCalc={budgetCalc} month={month} onChange={handleChangeMonth}/>
      </React.Fragment>
    )
  } else {
    return null
  }
}

export default MonthBudgetApexChart