import Schedule from "./Schedule";
import Model from "./Model";

class Scheduled {
  schedule: Schedule
  start: string
  end: string
  model?: Model

  constructor(data: any) {
    this.schedule = new Schedule(data.schedule)
    this.start = data.start
    this.end = data.end
    this.model = data.Model
  }

  overlapsWith(startRange: Date, endRange: Date): boolean {
    const thisStartDate = this.startDate
    const thisEndDate = this.endDate
    return ((!thisStartDate || thisStartDate.getTime() <= endRange.getTime()) &&
            (!thisEndDate || thisEndDate.getTime() >= startRange.getTime()))
  }

  get startDate(): Date | undefined {
    return this.model?.getDate(this.start)
  }

  get endDate(): Date | undefined {
    return this.model?.getDate(this.end)
  }

  get endYear(): number {
    const date = this.model?.getDate(this.end)
    if (date) {
      return date.getFullYear()
    } else {
      return 0
    }
  }
}

export default Scheduled