import PlanChange from "../PlanChange";
import GrowthStrategy, {GrowthModel} from "../GrowthStrategy";
import Model from "../Model";
import {humanizeString, numberToPercentFormat} from "../../stores/StoreUtilities";

class GrowthStrategyChange extends PlanChange {
  private _growthStrategy: GrowthStrategy

  constructor (data: any) {
    super(data)
    this._growthStrategy = new GrowthStrategy(this.details ? JSON.parse(this.details) : {})
    // if (!this.description || this.description === "") {
      this.description = this.generateDescription()
    // }
  }

  generateDescription() {
    let desc = ""
    const growthStrategy = this._growthStrategy
    if (growthStrategy) {
      if (growthStrategy.growthModel === GrowthModel.Simple) {
        desc = `Simple ${numberToPercentFormat(growthStrategy.growthRate, 2)} ${growthStrategy.ignoreCustomGrowthRates ? 'override' : 'default'} growth`
      } else {
        desc = `${growthStrategy.growthModel}/${humanizeString(growthStrategy.returnSequence)} ${growthStrategy.ignoreCustomGrowthRates ? 'override' : 'default'} growth`
      }
      if (!growthStrategy.surplusAssetId) {
        desc += `, Spend Surplus`
      } else {
        desc += `, Invest Surplus`
      }
    }
    return desc
  }

  get growthStrategy(): GrowthStrategy {
    return this._growthStrategy
  }

  set growthStrategy(value: GrowthStrategy) {
    this._growthStrategy = structuredClone(value)
    this.details = JSON.stringify(this._growthStrategy)
  }

  apply(model: Model) {
    model.growthStrategy = this._growthStrategy
  }
}

export default GrowthStrategyChange