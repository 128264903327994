import IUserItem from "./IUserItem";

export enum UserSettingType {
  scenarioComparison = "scenarioComparison"
}

class UserSetting implements IUserItem {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  userId: string
  name: string
  description: string
  settingType: string
  settings: any

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.userId = data.userId
    this.name = data.name
    this.description = data.description
    this.settingType = data.settingType
    this.settings = data.settings ? JSON.parse(data.settings) : {}
  }
}

export interface IScenario {
  modelId: string,
  planId: string,
  sortOrder: number
}

export interface IScenarioComparison {
  scenarios: IScenario[]
}

export default UserSetting