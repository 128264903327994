import { Box } from '@mui/material'
import * as React from 'react'
import theme from '../../styles/theme'

interface MarginRowProps {
  maxWidth?: number
  children?: any
}

const MarginRow = (props: MarginRowProps) => {
  return (
    <Box 
      sx={{
        px: {
          xs: theme.spacing(0.5),
          md: theme.spacing(2)
        },
        mx: props.maxWidth ? "auto" : undefined,
        maxWidth: {
          md: props.maxWidth ?? undefined
          // md: theme.breakpoints.values.xl
        },
        width: "100%",
        justifyContent: props.maxWidth ? "center" : "flex-start"
      }}
    >
      {props.children}
    </Box>
  )
}

export default MarginRow
