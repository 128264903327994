import {IconButton, useTheme} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const CloseButton = ({
  onClick
}: {
  onClick(event?: any): void
}) => {
  const theme = useTheme()

  return (
    <IconButton
      sx={{
        margin: 0,
        position: "absolute",
        zIndex: 10,
        cursor: "pointer",
        top: "-3px",
        right: "0px",
        padding: "4px",
        color: theme.palette.text.primary,
        "&:hover": {
          color: theme.palette.action.active
        }
      }}
      onClick={(event: any) => {
        onClick(event)
      }}
      >
      <CloseIcon/>
    </IconButton>
  )
}

export default CloseButton