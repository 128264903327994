import React, {useEffect, useState} from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material";
import Person from "../../../../model/Person";
import {useResources} from "../../../../stores/ResourceProvider";
import {Gender} from "../../../../API";
import {numberToPercentFormat} from "../../../../stores/StoreUtilities";
import {format} from "date-fns";
import {IPersonTimeline} from "../../../../components/calculator/Calculator";

const TimelineChart = ({
  persons,
  showTable,
}: {
  persons: Person[]
  showTable?: boolean
}) => {

  const theme = useTheme()

  const maleStyles = {
    yearsLived: {
      backgroundColor: "rgb(102, 119, 167)",
      borderRightWidth: 2,
      borderRightColor: theme.palette.error.light,
      borderRightStyle: "solid"
    },
    yearsBeforeRetirement: {
      backgroundColor: "rgb(102, 119, 167)",
      borderRightWidth: 2,
      borderRightColor: theme.palette.background.default,
      borderRightStyle: "solid"
    },
    yearsToRetirement: {
      backgroundColor: "rgb(68, 88, 143)",
      borderLeftWidth: 2,
      borderLeftColor: theme.palette.error.light,
      borderLeftStyle: "solid"
    },
    yearsInRetirement: {
      backgroundColor: "rgb(68, 88, 143)",
      borderRightWidth: 2,
      borderRightColor: theme.palette.error.light,
      borderRightStyle: "solid"
    },
    yearsEstimatedInRetirement: {
      backgroundColor: "rgb(29, 48, 101)",
      borderLeftWidth: 2,
      borderLeftColor: theme.palette.background.default,
      borderLeftStyle: "solid"
    },
    yearsRemainingInRetirement: {
      backgroundColor: "rgb(29, 48, 101)",
      borderLeftWidth: 2,
      borderLeftColor: theme.palette.error.light,
      borderLeftStyle: "solid"
    },
    legend1: {
      backgroundColor: "rgb(102, 119, 167)"
    },
    legend2: {
      backgroundColor: "rgb(68, 88, 143)",
    },
    legend3: {
      backgroundColor: "rgb(29, 48, 101)"
    },
    legendText: {
      fontSize: 14,
      fontWeight: 400,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(2)
    },
    keyDate: {
      color: "rgb(68, 88, 143)"
    }
  }
  
  const femaleStyles = {
    yearsLived: {
      backgroundColor: "rgb(154, 95, 124)",
      borderRightWidth: 2,
      borderRightColor: theme.palette.error.light,
      borderRightStyle: "solid"
    },
    yearsBeforeRetirement: {
      backgroundColor: "rgb(154, 95, 124)",
      borderRightWidth: 2,
      borderRightColor: theme.palette.background.default,
      borderRightStyle: "solid"
    },
    yearsToRetirement: {
      backgroundColor: "rgb(121, 62, 92)",
      borderLeftWidth: 2,
      borderLeftColor: theme.palette.error.light,
      borderLeftStyle: "solid"
    },
    yearsInRetirement: {
      backgroundColor: "rgb(121, 62, 92)",
      borderRightWidth: 2,
      borderRightColor: theme.palette.error.light,
      borderRightStyle: "solid"
    },
    yearsEstimatedInRetirement: {
      backgroundColor: "rgb(69, 21, 44)",
      borderLeftWidth: 2,
      borderLeftColor: theme.palette.background.default,
      borderLeftStyle: "solid"
    },
    yearsRemainingInRetirement: {
      backgroundColor: "rgb(69, 21, 44)",
      borderLeftWidth: 2,
      borderLeftColor: theme.palette.error.light,
      borderLeftStyle: "solid"
    },
    legend1: {
      backgroundColor: "rgb(154, 95, 124)",
    },
    legend2: {
      backgroundColor: "rgb(121, 62, 92)",
    },
    legend3: {
      backgroundColor: "rgb(69, 21, 44)",
    },
    legendText: {
      fontSize: 14,
      fontWeight: 400,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(2)
    },
    keyDate: {
      color: "rgb(121, 62, 92)"
    }
  }

  const [person1, setPerson1] = useState<Person | undefined>()
  const [timeline1, setTimeline1] = useState<IPersonTimeline>()
  const [yearsLived1, setYearsLived1] = useState<number>(0)
  const [yearsBeforeRetirement1, setYearsBeforeRetirement1] = useState<number>(0)
  const [yearsToRetirement1, setYearsToRetirement1] = useState<number>(0)
  const [yearsInRetirement1, setYearsInRetirement1] = useState<number>(0)
  const [yearsRemainingInRetirement1, setYearsRemainingInRetirement1] = useState<number>(0)
  const [lifeExpectancy1, setLifeExpectancy1] = useState<number>(0)

  const [person2, setPerson2] = useState<Person | undefined>()
  const [timeline2, setTimeline2] = useState<IPersonTimeline | undefined>()
  const [yearsLived2, setYearsLived2] = useState<number>(0)
  const [yearsBeforeRetirement2, setYearsBeforeRetirement2] = useState<number>(0)
  const [yearsToRetirement2, setYearsToRetirement2] = useState<number>(0)
  const [yearsInRetirement2, setYearsInRetirement2] = useState<number>(0)
  const [yearsRemainingInRetirement2, setYearsRemainingInRetirement2] = useState<number>(0)
  const [lifeExpectancy2, setLifeExpectancy2] = useState<number>(0)

  const { calculator } = useResources()

  useEffect(() => {
    if (persons.length > 0) {
      const person = persons[0]
      setPerson1(person)
      const timeline = calculator.personTimeline(person)
      if (timeline) {
        setTimeline1(timeline)
        setYearsLived1(timeline.age)
        setYearsBeforeRetirement1(timeline.yearsBeforeRetirement)
        setLifeExpectancy1(timeline.lifeExpectancyAge)
        setYearsToRetirement1(timeline.yearsToRetirement)
        setYearsInRetirement1(timeline.yearsInRetirement)
        setYearsRemainingInRetirement1(timeline.yearsRemainingInRetirement)
      }
    }

    if (persons.length > 1) {
      const person = persons[1]
      setPerson2(person)
      const timeline = calculator.personTimeline(person)
      if (timeline) {
        setTimeline2(timeline)
        setYearsLived2(timeline.age)
        setYearsBeforeRetirement2(timeline.yearsBeforeRetirement)
        setLifeExpectancy2(timeline.lifeExpectancyAge)
        setYearsToRetirement2(timeline.yearsToRetirement)
        setYearsInRetirement2(timeline.yearsInRetirement)
        setYearsRemainingInRetirement2(timeline.yearsRemainingInRetirement)
      }
    }
  }, [persons, calculator])

  const range = Math.max(lifeExpectancy1, lifeExpectancy2)
  const styles1 = (persons.length > 0 && persons[0].gender === Gender.Male) ? maleStyles : femaleStyles
  const styles2 = (persons.length > 1 && persons[1].gender === Gender.Male) ? maleStyles : femaleStyles

  // TOOO: Use this later, or discard
  // const renderSegment = (width: string, bgcolor: string, borderLeft: string, borderRight: string) => {
  //   return (
  //     <Box width={width} bgcolor={bgcolor} borderLeft={borderLeft} borderRight={borderRight}></Box>
  //   )
  // }

  const renderRow = (description: string, p1: string, p2?: string) => {
    return (
      <TableRow>
        <TableCell>{description}</TableCell>
        {p1 &&
          <TableCell align="right" sx={styles1.keyDate}>{p1}</TableCell>
        }
        {p2 &&
          <TableCell align="right" sx={styles2.keyDate}>{p2}</TableCell>
        }
      </TableRow>
    )
  }

  return (
    <React.Fragment>
      <TableContainer component={Paper} sx={{paddingTop:1}}>
      {person1 &&
        <Box display="flex" flexGrow={0} flexDirection="column" justifyContent="flex-start" textAlign="left" p={1}>
          <Box display="flex" flexGrow={1} flexWrap="nowrap" maxHeight={20} justifyContent="space-between">
            <Typography variant="h5">{person1.nickname}</Typography>
            <Typography variant="subtitle2">Life Expectancy: {lifeExpectancy1}</Typography>
          </Box>
          {yearsToRetirement1 > 0 &&
            <React.Fragment>
              <Box display="flex" flexGrow={1} height={20} maxHeight={20} mb={0.5}>
                <Box width={numberToPercentFormat(yearsLived1 / range, 1)} sx={styles1.yearsLived}></Box>
                <Box width={numberToPercentFormat(yearsToRetirement1 / range, 1)} sx={styles1.yearsToRetirement}></Box>
                <Box width={numberToPercentFormat(yearsRemainingInRetirement1 / range, 1)} sx={styles1.yearsEstimatedInRetirement}></Box>
              </Box>
              <Box display="flex" flexGrow={1} height={20} maxHeight={20} flexWrap="nowrap" mb={1} alignItems="center">
                <Box display="flex" width={18} height={18} sx={styles1.legend1}></Box>
                <Typography variant="body2" sx={styles1.legendText}>Years Lived: <b>{yearsLived1}</b></Typography>
                <Box display="flex" width={18} height={18} sx={styles1.legend2}></Box>
                <Typography variant="body2" sx={styles1.legendText}>Years to Retirement: <b>{yearsToRetirement1}</b></Typography>
                <Box display="flex" width={18} height={18} sx={styles1.legend3}></Box>
                <Typography variant="body2" sx={styles1.legendText}>Est Years in Retirement: <b>{yearsRemainingInRetirement1}</b></Typography>
              </Box>
            </React.Fragment>
          }
          {yearsToRetirement1 <= 0 &&
            <React.Fragment>
              <Box display="flex" flexGrow={1} height={20} maxHeight={20} mb={0.5}>
                <Box width={numberToPercentFormat(yearsBeforeRetirement1 / range, 1)} sx={styles1.yearsBeforeRetirement}></Box>
                <Box width={numberToPercentFormat(yearsInRetirement1 / range, 1)} sx={styles1.yearsInRetirement}></Box>
                <Box width={numberToPercentFormat(yearsRemainingInRetirement1 / range, 1)} sx={styles1.yearsRemainingInRetirement}></Box>
              </Box>
              <Box display="flex" flexGrow={1} height={20} maxHeight={20} flexWrap="nowrap" mb={1} alignItems="center">
                <Box display="flex" width={18} height={18} sx={styles1.legend1}></Box>
                <Typography variant="body2" sx={styles1.legendText}>Years Before Retirement: <b>{yearsBeforeRetirement1}</b></Typography>
                <Box display="flex" width={18} height={18} sx={styles1.legend2}></Box>
                <Typography variant="body2" sx={styles1.legendText}>Years in Retirement: <b>{yearsInRetirement1}</b></Typography>
                <Box display="flex" width={18} height={18} sx={styles1.legend3}></Box>
                <Typography variant="body2" sx={styles1.legendText}>Est Remaining Years in Retirement: <b>{yearsRemainingInRetirement1}</b></Typography>
              </Box>
            </React.Fragment>
          }
        </Box>
      }

      {person2 &&
        <Box display="flex" flexGrow={0} flexDirection="column" justifyContent="flex-start" textAlign="left" p={1}>
          <Box display="flex" flexGrow={1} flexWrap="nowrap" maxHeight={20} justifyContent="space-between">
            <Typography variant="h5">{person2.nickname}</Typography>
            <Typography variant="subtitle2">Life Expectancy: {lifeExpectancy2}</Typography>
          </Box>
          {yearsToRetirement2 > 0 &&
            <React.Fragment>
              <Box display="flex" flexGrow={1} height={20} maxHeight={20} mb={0.5}>
                <Box width={numberToPercentFormat(yearsLived2 / range, 1)} sx={styles2.yearsLived}></Box>
                <Box width={numberToPercentFormat(yearsToRetirement2 / range, 1)} sx={styles2.yearsToRetirement}></Box>
                <Box width={numberToPercentFormat(yearsRemainingInRetirement2 / range, 1)} sx={styles2.yearsEstimatedInRetirement}></Box>
              </Box>
              <Box display="flex" flexGrow={1} height={20} maxHeight={20} flexWrap="nowrap" mb={1} alignItems="center">
                <Box display="flex" width={18} height={18} sx={styles2.legend1}></Box>
                <Typography variant="body2" sx={styles2.legendText}>Years Lived: <b>{yearsLived2}</b></Typography>
                <Box display="flex" width={18} height={18} sx={styles2.legend2}></Box>
                <Typography variant="body2" sx={styles2.legendText}>Years to Retirement: <b>{yearsToRetirement2}</b></Typography>
                <Box display="flex" width={18} height={18} sx={styles1.legend3}></Box>
                <Typography variant="body2" sx={styles2.legendText}>Est Years in Retirement: <b>{yearsRemainingInRetirement2}</b></Typography>
              </Box>
            </React.Fragment>
          }
          {yearsToRetirement2 <= 0 &&
            <React.Fragment>
              <Box display="flex" flexGrow={1} height={20} maxHeight={20} mb={0.5}>
                <Box width={numberToPercentFormat(yearsBeforeRetirement2 / range, 1)} sx={styles2.yearsBeforeRetirement}></Box>
                <Box width={numberToPercentFormat(yearsInRetirement2 / range, 1)} sx={styles2.yearsInRetirement}></Box>
                <Box width={numberToPercentFormat(yearsRemainingInRetirement2 / range, 1)} sx={styles2.yearsRemainingInRetirement}></Box>
              </Box>
              <Box display="flex" flexGrow={1} height={20} maxHeight={20} flexWrap="nowrap" mb={1} alignItems="center">
                <Box display="flex" width={18} height={18} sx={styles2.legend1}></Box>
                <Typography variant="body2" sx={styles2.legendText}>Years Before Retirement: <b>{yearsBeforeRetirement2}</b></Typography>
                <Box display="flex" width={18} height={18} sx={styles2.legend2}></Box>
                <Typography variant="body2" sx={styles2.legendText}>Years in Retirement: <b>{yearsInRetirement2}</b></Typography>
                <Box display="flex" width={18} height={18} sx={styles2.legend3}></Box>
                <Typography variant="body2" sx={styles2.legendText}>Est Remaining Years in Retirement: <b>{yearsRemainingInRetirement2}</b></Typography>
              </Box>
            </React.Fragment>
          }
        </Box>
      }
    </TableContainer>

  {showTable &&
    <Box display="flex" flexGrow={1} flexDirection="column" mt={2}>
      <Box display="flex" flexGrow={1} justifyContent="space-between" minHeight={40}>
        <Typography variant="h3" color="primary" gutterBottom>Key Dates for Reference</Typography>
      </Box>
      <TableContainer component={Paper} sx={{paddingTop:1}}>
        {/*<Box display="flex" flexGrow={1} flexDirection="column" mt={0} border="1px solid silver">*/}
        {/*  <Box display="flex" justifyContent="space-between" px={2} mb={0} height={50}*/}
        {/*       bgcolor="rgba(0, 0, 0, .03)" alignItems="center">*/}
        {/*    <Typography variant="h3" color="primary">Key Dates for Reference</Typography>*/}
        {/*  </Box>*/}

        <Table width="100%">
          <TableHead>
            <TableRow>
              <TableCell width={persons.length > 1 ? "60%" : "80%"}>Event</TableCell>
              {person1 &&
                <TableCell width="20%" align="right" sx={styles1.keyDate}>{person1.nickname}</TableCell>
              }
              {person2 &&
                <TableCell width="20%" align="right" sx={styles2.keyDate}>{person2.nickname}</TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRow("Retirement",
              timeline1 ? format(timeline1.retireDate, "LLLL yyyy"): "",
              timeline2 ? format(timeline2.retireDate, "LLLL yyyy") : "")}
            {renderRow("IRAs no longer subject to additional 10% early withdrawal tax (age 59.5)",
              timeline1 ? format(timeline1.iraWithdrawalDate, "LLLL yyyy"): "",
              timeline2 ? format(timeline2.iraWithdrawalDate, "LLLL yyyy") : "")}
            {renderRow("Early Social Security (age 62)",
              timeline1 ? format(timeline1.earlySocialSecurityDate, "LLLL yyyy"): "",
              timeline2 ? format(timeline2.earlySocialSecurityDate, "LLLL yyyy") : "")}
            {renderRow("Eligible for Medicare (age 65)",
              timeline1 ? format(timeline1.medicareDate, "LLLL yyyy"): "",
              timeline2 ? format(timeline2.medicareDate, "LLLL yyyy") : "")}
            {renderRow("Full Social Security",
              timeline1 ? format(timeline1.fullSocialSecurityDate, "LLLL yyyy"): "",
              timeline2 ? format(timeline2.fullSocialSecurityDate, "LLLL yyyy") : "")}
            {renderRow("Delayed Social Security (age 70)",
              timeline1 ? format(timeline1.delayedSocialSecurityDate, "LLLL yyyy"): "",
              timeline2 ? format(timeline2.delayedSocialSecurityDate, "LLLL yyyy") : "")}
            {renderRow("Qualified charitable distributions become eligible (age 70.5)",
              timeline1 ? format(timeline1.qualifiedCharitableDistributionDate, "LLLL yyyy"): "",
              timeline2 ? format(timeline2.qualifiedCharitableDistributionDate, "LLLL yyyy") : "")}
            {renderRow("Non-inherited RMDs begin (age 72, 73, or 75 depending on birth year)",
              timeline1 ? format(timeline1.nonInheritedRMDDate, "LLLL yyyy"): "",
              timeline2 ? format(timeline2.nonInheritedRMDDate, "LLLL yyyy") : "")}
            {renderRow("Life Expectancy",
              timeline1 ? format(timeline1.lifeExpectancyDate, "yyyy"): "",
              timeline2 ? format(timeline2.lifeExpectancyDate, "yyyy") : "")}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  }
    </React.Fragment>
  )
}

export default TimelineChart