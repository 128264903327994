
export enum GrowthModel {
  Simple = "Simple",
  Aggressive = "Aggressive",
  Balanced = "Balanced",
  Moderate = "Moderate",
}

export enum ReturnSequence {
  Historical= "Historical",
  BadTiming = "BadTiming"
}

export function getGrowthModelLabel(model: GrowthModel) {
  switch (model) {
    case GrowthModel.Simple:
      return "Simple Growth"
    case GrowthModel.Aggressive:
      return "Aggressive - 70/30 Stocks/Bonds"
    case GrowthModel.Balanced:
      return "Balanced - 60/40 Stocks/Bonds"
    case GrowthModel.Moderate:
      return "Moderate - 50/50 Stocks/Bonds"
  }
}

class GrowthStrategy {
  growthModel: GrowthModel
  returnSequence: ReturnSequence
  growthRate: number
  inflationRate?: number
  ignoreCustomGrowthRates: boolean
  ignoreCustomInflationRates?: boolean
  surplusAssetId?: string

  constructor (data: any) {
    this.growthModel = data.growthModel ?? GrowthModel.Simple
    this.returnSequence = data.returnSequence ?? ReturnSequence.Historical
    this.growthRate = data.growthRate ?? 0.04
    this.inflationRate = data.inflationRate ?? 0.03
    this.ignoreCustomGrowthRates = data.ignoreCustomGrowthRates === true
    this.ignoreCustomInflationRates = data.ignoreCustomInflationRates === true
    this.surplusAssetId = data.surplusAssetId
  }
}

export default GrowthStrategy