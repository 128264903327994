import Page from "../../components/page/Page";
import {Box, Grid, Paper, Typography, useTheme} from "@mui/material";
import MarginRow from "../../components/page/MarginRow";
import * as React from "react";
import TrialImg from "../../images/30daytrial.png";
import CheckAlt from "../../images/CheckAlt.png";
import FormValidator from "../../components/form/FormValidator";
import ProgressButton from "../../components/form/ProgressButton";
import globalStyles from "../../styles/globalStyles";
import {useNavigate} from "react-router-dom";
import RoutesConfig from "../../RoutesConfig";
import Footer from "../../components/page/Footer";
import {useResources} from "../../stores/ResourceProvider";
import Visible from "../../components/Visible";

const HomePage = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { userStore } = useResources()

  const renderPromo = () => {

    return (
      <Paper
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          overflow: 'hidden',
          mx: {
            md: 'auto'
          },
          p: 2,
          maxWidth: {
            md: theme.breakpoints.values.lg
          },
          minHeight: 532
        }}
        elevation={2}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h2" color="primary">Live the life you want with confidence!</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1"><b>Retirement Budget Calculator</b> is an easy-to-use tool designed to help you make the best decisions so that you can achieve your goals and live the life you want.</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1"><b>RBC will help you:</b></Typography>
            <ul>
              <li><Typography variant="body1">Project how much you will need in retirement.</Typography></li>
              <li><Typography variant="body1">Simulate different scenarios.</Typography></li>
              <li><Typography variant="body1">See the impact of different withdrawal strategies.</Typography></li>
              <li><Typography variant="body1">Create a cash flow plan.</Typography></li>
            </ul>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" sx={{py:2}}>
              <Grid item xs={2}>
              </Grid>
              <Grid item xs={2}>
                <img src={TrialImg} alt="Risk Free Trial"/>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1" sx={{fontStyle:"italic"}}>
                  Try Retirement Budget Calculator for free!<br/>
                  No credit card required!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box display="flex" justifyContent="space-evenly" my={2}>
              <Box display="flex" alignItems="center">
                <img src={CheckAlt} alt="Check"/>
                <Typography variant="h3" sx={{pl:2}}>Clarity</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <img src={CheckAlt} alt="Check"/>
                <Typography variant="h3" sx={{pl:2}}>Confidence</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <img src={CheckAlt} alt="Check"/>
                <Typography variant="h3" sx={{pl:2}}>Freedom</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  const renderOptions = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          overflow: 'hidden',
          mx: {
            md: 'auto'
          },
          p: 2,
          maxWidth: {
            md: theme.breakpoints.values.lg
          },
        }}
      >
        {/*<Typography variant="h4" color="primary" gutterBottom sx={{letterSpacing: "0.1em"}}>*/}
        {/*  STEP {step === SignupStep.UserInfo ? "1" : "2"} OF 2*/}
        {/*</Typography>*/}
        <Typography variant="h1" gutterBottom>
          Welcome to RBC
        </Typography>
        <Visible cond={userStore.isAuthenticated}>

        </Visible>
        <Visible cond={!userStore.isAuthenticated}>
          <Typography variant="body1">
            Congratulations, you are just a few steps away from knowing if you are on track for a successful retirement!
          </Typography>
          <FormValidator
            autoComplete="off"
            name="signupForm"
            id="signupForm"
          >
            <Grid container spacing={2} mt={2}>
              <Grid item xs={6} direction="column" alignItems="center" textAlign="center">
                <Typography variant="body1" gutterBottom>I already have an RBC account!</Typography>
                <ProgressButton variant="contained" size="medium" color="primary" sx={{width:120}}
                                type="button" onClick={() => navigate(RoutesConfig.signIn.pathname)}>
                  Sign In
                </ProgressButton>
              </Grid>
              <Grid item xs={6} direction="column" alignItems="center" textAlign="center">
                <Typography variant="body1" gutterBottom>I want to get started with RBC!</Typography>
                <ProgressButton variant="contained" size="medium" color="primary" sx={{width:150, ...globalStyles.webFlowButton}}
                                type="button" onClick={() => navigate(RoutesConfig.signUp.pathname)}>
                  Get Started
                </ProgressButton>
              </Grid>
            </Grid>
          </FormValidator>
        </Visible>
      </Box>
    )
  }

  return (
    <Page title="Home" backgroundColor="default">
      <MarginRow maxWidth={theme.breakpoints.values.xl}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="top"
          sx={{
            // minHeight: '90%',
            my: {
              sm: 0,
              md: "auto"
            },
            px: {
              sm: 2,
              md: 0
            },
            py: {
              sm: 0,
              md: 4,
            },
            minHeight: "calc(100vh - 206px)"
          }}
          spacing={6}
        >
          <Grid item sm={12} md={6}>
            {renderPromo()}
          </Grid>
          <Grid item sm={12} md={6}>
            {renderOptions()}
          </Grid>
        </Grid>
        <Footer />
      </MarginRow>
    </Page>  )

}

export default HomePage