import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Box} from "@mui/material";
import IconicButton from "./IconicButton";

interface IBasicMenuButtonProps {
  icon?: React.ReactNode
  label: string | React.ReactNode
  labelPosition?: 'left' | 'right'
  menuPosition?: 'left' | 'right'
  options: string[]
  value?: number
  onClickOption(selectedOptionIndex: number): void
}

/*
  Based on: https://mui.com/material-ui/react-menu/#basic-menu 
*/
const BasicMenuButton = ({
  icon,
  label,
  labelPosition,
  menuPosition,
  options,
  value,
  onClickOption
}: IBasicMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // const theme = useTheme()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation()
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (index: number) => {
    handleClose()
    onClickOption(index)
  }

  return (
    <Box display="wrap" flexWrap="nowrap" pt={0} justifyContent="flex-end">
      {/*<Button*/}
      {/*  id="basic-button"*/}
      {/*  aria-controls={open ? 'basic-menu' : undefined}*/}
      {/*  aria-haspopup="true"*/}
      {/*  aria-expanded={open ? 'true' : undefined}*/}
      {/*  onClick={handleClick}*/}
      {/*  variant="contained"*/}
      {/*  color="secondary"*/}
      {/*  startIcon={startIcon}*/}
      {/*>*/}
      {/*  {title}*/}
      {/*</Button>*/}
      <IconicButton onClick={handleClick} label={label} labelPosition={labelPosition}>
        {icon}
      </IconicButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={(event: any) => {
          event.stopPropagation()
          handleClose()
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: (!menuPosition || menuPosition==='left') ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: (!menuPosition || menuPosition==='left') ? 'right' : 'left',
        }}
        PaperProps={{
          style: {
            maxHeight: 'calc(50vh)'
          },
        }}
      >
        {options.map((option, index) => {
          return (
            <MenuItem 
              key={`basic-menu.${option}.${index}`}
              selected={value !== undefined && value === index}
              onClick={(event: any) => {
                event.stopPropagation()
                handleMenuItemClick(index)
              }}
            >
              {option}
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  );
}

export default BasicMenuButton 
