import Asset, {
  AssetCategory,
  AssetType,
  AssetTypeDef,
  getAssetTypeDef,
  getAssetTypeLabel,
  listAssetTypes,
  Risk
} from "../../../model/Asset";
import {Box, IconButton, InputAdornment} from "@mui/material";
import React, {useEffect, useState} from "react"
import TextFieldValidator from "../../../components/form/TextFieldValidator";
import {
  getISODateFromDate,
  humanizeString,
  moneyToNumberFormat,
  numberToMoneyFormat,
  numberToPercentFormat,
} from "../../../stores/StoreUtilities";
import {CreateAssetInput, UpdateAssetInput} from "../../../API";
import {useResources} from "../../../stores/ResourceProvider";
import Model from "../../../model/Model";
import DatePickerValidator from "../../../components/form/DatePickerValidator";
import FormGroupSpacer from "../../../components/form/FormGroupSpacer";
import Snapshot, {ISnapshotDetail} from "../../../model/Snapshot";
import MilestoneDateFieldValidator from "../../../components/form/MilestoneDateFieldValidator";
import {compareAsc} from "date-fns";
import ModelEditDialog from "../../../components/model/ModelEditDialog";
import {isNumber} from "../../../model/ModelUtilities";
import Tracking from "../../../components/Tracking";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const AssetEditDialog = ({
  open,
  asset,
  model,
  snapshot,
  onClose,
  onSave,
  onDelete
}: {
  open?: boolean
  asset: Asset
  model: Model
  snapshot?: Snapshot
  onClose?(): void
  onSave?(update: Asset, snapshotItem?: ISnapshotDetail): void
  onDelete?(expense: Asset): void
}) => {
  const [assetModel, setAssetModel] = useState<Asset>(asset)
  const [snapshotDetail, setSnapshotDetail] = useState<ISnapshotDetail | undefined>()
  const [description, setDescription] = useState<string>("")
  const [assetCategory, setAssetCategory] = useState<AssetCategory | undefined>()
  const [assetType, setAssetType] = useState<AssetType | undefined>()
  const [assetTypeDef, setAssetTypeDef] = useState<AssetTypeDef | undefined>()
  const [owner, setOwner] = useState<string | undefined>()
  const [risk, setRisk] = useState<Risk | undefined>()
  const [returnRate, setReturnRate] = useState<string | undefined>()
  const [rateLock, setRateLock] = useState<"Model" | "User" | undefined>()
  const [withdrawalOrder, setWithdrawalOrder] = useState<string | undefined>()
  const [withdrawalOrderPlaceholder, setWithdrawalOrderPlaceholder] = useState<string>("")
  const [withdrawalOrderLock, setWithdrawalOrderLock] = useState<boolean>(false)
  const [balance, setBalance] = useState<string | undefined>()
  const [balanceDate, setBalanceDate] = useState<Date | undefined>()
  const [priorYear, setPriorYear] = useState<number>(0)
  const [priorEoYBalance, setPriorEoYBalance] = useState<string | undefined>()
  const [withdrawalsYTD, setWithdrawalsYTD] = useState<string | undefined>()
  const [start, setStart] = useState<string>()
  const [end, setEnd] = useState<string>()
  const [inheritedYear, setInheritedYear] = useState<string | undefined>()
  const [originalOwnerBirthYear, setOriginalOwnerBirthYear] = useState<string | undefined>()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { modelStore, userStore, notify } = useResources()

  useEffect(() => {
    setAssetModel(asset)
    setDescription(asset.description)
    setAssetCategory(asset.assetCategory)
    setAssetType(asset.assetType)
    setAssetTypeDef(asset.assetTypeDef)
    let person = asset.ownerId ? model.getPerson(asset.ownerId) : undefined
    setOwner(person ? person.nickname : model.getDefaultPersonNickname(!asset.assetTypeDef.isIRA))
    setRisk(asset.risk ?? Risk.Low)
    setReturnRate(isNumber(asset.returnRate) ? numberToPercentFormat(asset.returnRate, 2) : "")
    if (asset.rateLock) {
      setRateLock("User")
    } else if (model.growthStrategy && model.growthStrategy.ignoreCustomGrowthRates) {
      setRateLock("Model")
    }
    setWithdrawalOrder(asset.withdrawalOrder ? asset.withdrawalOrder.toString() : "")
    setWithdrawalOrderPlaceholder(getAssetTypeDef(asset.assetType).withdrawalOrder.toString())
    setWithdrawalOrderLock(model.hasLock(asset.id, "withdrawalOrder"))
    const detail = snapshot ? snapshot.getSnapshotDetail(asset) : undefined
    if (detail) {
      setSnapshotDetail(detail)
      setBalance(numberToMoneyFormat(detail.balance, 0))
      setBalanceDate(snapshot!.date)
    } else {
      setBalance(numberToMoneyFormat(asset.balance, 0))
      setBalanceDate(asset.balanceDate)
    }
    setPriorYear((new Date()).getFullYear() - 1)
    setPriorEoYBalance(asset.priorEoYBalance ? numberToMoneyFormat(asset.priorEoYBalance, 0) : undefined)
    setWithdrawalsYTD(asset.withdrawalsYTD ? numberToMoneyFormat(asset.withdrawalsYTD, 0) : undefined)
    setInheritedYear(asset.inheritedYear ? asset.inheritedYear.toString() : "")
    setOriginalOwnerBirthYear(asset.originalOwnerBirthYear ? asset.originalOwnerBirthYear.toString() : "")
    setStart(asset.start)
    setEnd(asset.end)
    setIsOpen(open === true)
  }, [asset, model, snapshot, open])

  const handleClose = async (event: any) => {
    if (onClose) {
      onClose()
    }
  }

  const handleSave = async (event: any) => {
    try {
      const person = model.getPersonByNickname(owner)
      let updatedAsset: Asset | undefined
      let updatedSnapshotDetail: ISnapshotDetail | undefined
      const balanceValue = moneyToNumberFormat(balance, 0) ?? 0
      const priorBalanceValue = moneyToNumberFormat(priorEoYBalance, 0) ?? null
      const withdrawalsYTDValue = moneyToNumberFormat(withdrawalsYTD, 0) ?? null

      if (!assetModel.createdAt) {
        const input: CreateAssetInput = {
          id: assetModel.id,
          accountId: assetModel.accountId,
          userId: assetModel.userId,
          modelId: assetModel.modelId,
          description: description,
          assetCategory: assetCategory,
          assetType: assetType,
          ownerId: person ? person.id : null,
          risk: risk,
          balance: balanceValue,
          balanceDate: balanceDate ? getISODateFromDate(balanceDate) : null,
          priorEoYBalance: priorBalanceValue,
          withdrawalsYTD: withdrawalsYTDValue,
          withdrawalOrder: withdrawalOrder ? parseInt(withdrawalOrder) : null,
          returnRate: returnRate !== undefined && returnRate >= "0" ? parseFloat(returnRate) / 100 : null,
          rateLock: rateLock === "User",
          inheritedYear: inheritedYear ? parseInt(inheritedYear) : null,
          originalOwnerBirthYear: originalOwnerBirthYear ? parseInt(originalOwnerBirthYear) : null,
          start: start,
          // end: end ?? null,
          sortOrder: assetModel.sortOrder
        }

        updatedAsset = await modelStore.createAsset(input)
        Tracking.event({action: "Asset Created"})
      } else  {
        const input: UpdateAssetInput = {
          id: assetModel.id,
          accountId: assetModel.accountId,
          userId: assetModel.userId,
          modelId: assetModel.modelId,
          description: description,
          assetCategory: assetCategory,
          assetType: assetType,
          ownerId: person ? person.id : null,
          risk: risk,
          balance: balanceValue,
          balanceDate: balanceDate ? getISODateFromDate(balanceDate) : null,
          priorEoYBalance: priorBalanceValue,
          withdrawalsYTD: withdrawalsYTDValue,
          withdrawalOrder: withdrawalOrder ? parseInt(withdrawalOrder) : null,
          returnRate: returnRate !== undefined && returnRate >= "0" ? parseFloat(returnRate) / 100 : null,
          rateLock: rateLock === "User",
          inheritedYear: inheritedYear ? parseInt(inheritedYear) : null,
          originalOwnerBirthYear: originalOwnerBirthYear ? parseInt(originalOwnerBirthYear) : null,
          start: start,
          // end: end ?? null,
        }
        if (withdrawalOrderLock) {
          delete input.withdrawalOrder
        }

        // if (snapshot && compareAsc(snapshot.date, assetModel.balanceDate) ) {
        //   // Don't update the balance if the snapshot date doesn't match the asset balanceDate
        //   delete input.balance
        //   delete input.balanceDate
        // }

        updatedAsset = await modelStore.updateAsset(input)
        Tracking.event({action: "Asset Updated"})
      }

      // Only update the snapshot if the start date <= the snapshot date
      const startDate = model.getDate(start)
      if ((!startDate && snapshot && snapshot.isCurrent) ||
          (startDate && snapshot && compareAsc(startDate, snapshot.date) <= 0)) {
        updatedSnapshotDetail = {
          id: assetModel.id,
          typename: "Asset",
          balance: balanceValue
        }
      }

      if (onSave && updatedAsset) {
        onSave(updatedAsset, updatedSnapshotDetail)
      } else if (onClose) {
        onClose()
      }
    } catch (err: any) {
      notify.show('error', err.message)
    }
  }

  const handleDelete = async () => {
    if (onDelete) {
      if (assetModel.createdAt) {
        const deleted = await modelStore.deleteAsset(assetModel.id)
        if (deleted) {
          onDelete(deleted)
        }
      } else {
        onDelete(assetModel)
      }
    }
  }

  const handleRateLock = () => {
    if (rateLock === "User") {
      if (model.getOverrideGrowthLock()) {
        setRateLock("Model")
        setReturnRate(numberToPercentFormat(model.getDefaultGrowthRate()))
      } else {
        setRateLock(undefined)
      }
    } else if (rateLock === "Model") {
      setRateLock("User")
    } else if (rateLock === undefined) {
      setRateLock("User")
    }
  }

  const renderRateField = () => {
    let endAdornment
    let helperText
    let disabled = false
    if (userStore.isFree) {
      helperText = `Default: ${model.getDefaultGrowthRate()}`
    } else if (rateLock === "User") {
      endAdornment = (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle rate lock"
            onClick={() => handleRateLock()}
            edge="end"
          >
            <LockPersonIcon/>
          </IconButton>
        </InputAdornment>
      )
      helperText = "Locked by User"
    } else if (rateLock === "Model") {
      endAdornment = (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle rate lock"
            onClick={() => handleRateLock()}
            edge="end"
          >
            <LockIcon/>
          </IconButton>
        </InputAdornment>
      )
      helperText = "Locked by Growth Strategy"
      disabled = true
    } else {
      endAdornment = (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle rate lock"
            onClick={() => handleRateLock()}
            edge="end"
          >
            <LockOpenIcon/>
          </IconButton>
        </InputAdornment>
      )
      helperText = `Default: ${numberToPercentFormat(model.getDefaultGrowthRate(), 2)}`
    }

    return (
      <TextFieldValidator
        type="string"
        validators={{ required: false, minValue: 0 }}
        name="returnRate"
        variant="standard"
        margin="dense"
        fullWidth
        label="Return Rate %"
        value={returnRate}
        InputProps={{
          endAdornment: endAdornment
        }}
        helperText={helperText}
        disabled={disabled}
        onChange={(event: any) => setReturnRate(event.target.value)}
      />
    )
  }

  if (!assetModel || !isOpen) {
    return null
  }

  const balanceLabel = (assetCategory === AssetCategory.LiquidInvestableAssets) ? "Balance" : "Value"
  const requireExtraFields = assetTypeDef && assetTypeDef.requireExtraFields

  return (
    <ModelEditDialog title="Edit Asset" open={isOpen}
                     onCancel={handleClose}
                     onSave={handleSave}
                     onDelete={handleDelete}
    >
      <TextFieldValidator
        margin="normal"
        name="description"
        label="Title"
        type="text"
        fullWidth
        variant="standard"
        required
        validators={{ required: true }}
        value={description}
        onChange={(event: any) => setDescription(event.target.value)}
      />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <TextFieldValidator
          type="string"
          validators={{ isMoney: true }}
          name="balance"
          variant="standard"
          margin="dense"
          sx={{width:"50%"}}
          label={balanceLabel}
          value={balance}
          onChange={(event: any) => setBalance(event.target.value)}
        />
        <FormGroupSpacer/>
        <Box pt={1} width="50%">
          <DatePickerValidator
            name="balanceDate"
            label="Snapshot Date"
            value={balanceDate}
            format="MM-dd-yyyy"
            variant="standard"
            margin="dense"
            fullWidth
            required
            disabled
            onChange={(newValue: any) => {
              setBalanceDate(newValue)
            }}
          />
        </Box>
      </Box>
      {assetCategory === AssetCategory.LiquidInvestableAssets &&
        <Box display="flex" flexDirection="row" justifyContent="stretch">
        {assetTypeDef?.useBalanceYStart &&
          <TextFieldValidator
            type="string"
            validators={{ required: false, isMoney: true }}
            name="priorEoYBalance"
            variant="standard"
            margin="dense"
            fullWidth
            label={`${priorYear} End Balance`}
            value={priorEoYBalance}
            helperText="For assets subject to Required Minimum Distributions"
            onChange={(event: any) => setPriorEoYBalance(event.target.value)}
          />
        }
          {assetTypeDef?.isTaxableIRA &&
            <React.Fragment>
              <FormGroupSpacer/>
              <TextFieldValidator
                type="string"
                validators={{ required: false, isMoney: true }}
                name="withdrawalsYTD"
                variant="standard"
                margin="dense"
                fullWidth
                label="YTD Withdrawals"
                value={withdrawalsYTD}
                helperText="Withdrawals not included in Asset Conversions or Expense Assets"
                onChange={(event: any) => setWithdrawalsYTD(event.target.value)}
              />
            </React.Fragment>
          }
        </Box>
      }
      {assetCategory === AssetCategory.LiquidInvestableAssets &&
        <TextFieldValidator
          type="text"
          validators={{ required: true }}
          required
          name="assetType"
          label="Account Type"
          variant="standard"
          autocompleteOptions={{
            freeSolo: false,
            options: listAssetTypes(),
            getOptionLabel: (option: number) => {
              return(getAssetTypeLabel(option))
            },
            value: assetType,
            onChange: (event: any, value: number, reason: any) => {
              setAssetType(value)
              const typeDef = getAssetTypeDef(value)
              setAssetTypeDef(typeDef)
              if (typeDef && typeDef.isIRA && owner && owner === Model.jointNickname) {
                setOwner(undefined)
              }
            }
          }}
        />
      }
      {requireExtraFields &&
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <TextFieldValidator
            type="number"
            validators={{required: true, isInt: true}}
            name="inheritedYear"
            variant="standard"
            margin="dense"
            fullWidth
            label="Inherited Year"
            value={inheritedYear}
            onChange={(event: any) => setInheritedYear(event.target.value)}
          />
          <FormGroupSpacer/>
          {(assetType === AssetType.IRANonSpouseInheritedBefore2020 || assetType === AssetType.RothIRANonSpouseInheritedBefore2020) &&
            <TextFieldValidator
              type="number"
              validators={{required: true, isInt: true}}
              name="originalOwnerBirthYear"
              variant="standard"
              margin="dense"
              fullWidth
              label="Original Owner Birth Year"
              value={originalOwnerBirthYear}
              onChange={(event: any) => setOriginalOwnerBirthYear(event.target.value)}
            />
          }
        </Box>
      }
      <Box display="flex" flexDirection="row" justifyContent="stretch">
        <Box display="flex" flexGrow={1}>
          <TextFieldValidator
            type="text"
            validators={{ required: true }}
            required
            name="owner"
            label="Owner"
            variant="standard"
            fullWidth
            autocompleteOptions={{
              freeSolo: false,
              sx:{width:"100%"},
              options: model.getPersonNicknames(!assetTypeDef || !assetTypeDef.isIRA),
              value: owner,
              onChange: (event: any, value: string, reason: any) => {
                setOwner(value)
              }
            }}
          />
        </Box>
        <FormGroupSpacer/>
        <Box display="flex" flexGrow={1}>
          <TextFieldValidator
            type="text"
            validators={{required: true}}
            required
            name="risk"
            label="Risk"
            variant="standard"
            fullWidth
            autocompleteOptions={{
              freeSolo: false,
              sx:{width:"100%"},
              options: Object.values(Risk).filter((v) => !isNaN(Number(v))),
              getOptionLabel: (option: number) => {
                return (humanizeString(Risk[option]))
              },
              value: risk,
              onChange: (event: any, value: number, reason: any) => {
                setRisk(value)
              }
            }}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <MilestoneDateFieldValidator
          name="start"
          label="Acquisition Date"
          model={model}
          value={start}
          width="50%"
          onChange={(value: string) => setStart(value)}
        />
        <FormGroupSpacer/>
        <Box width="50%"></Box>
        {/* Disabled for now */}
        {/*<MilestoneDateFieldValidator*/}
        {/*  name="end"*/}
        {/*  label="End Date"*/}
        {/*  model={model}*/}
        {/*  value={end}*/}
        {/*  width="50%"*/}
        {/*  onChange={(value: string) => setEnd(value)}*/}
        {/*/>*/}
      </Box>
      {assetCategory === AssetCategory.LiquidInvestableAssets &&
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <TextFieldValidator
            type="number"
            validators={{required: false}}
            name="withdrawalOrder"
            variant="standard"
            margin="dense"
            fullWidth
            label="Withdrawal Order"
            value={withdrawalOrder}
            placeholder={withdrawalOrderPlaceholder}
            disabled={withdrawalOrderLock}
            helperText={withdrawalOrderLock ? "Withdrawal Strategy Set" : undefined}
            onChange={(event: any) => setWithdrawalOrder(event.target.value)}
          />
          <FormGroupSpacer/>
          {renderRateField()}
          {/*<TextFieldValidator*/}
          {/*  type="string"*/}
          {/*  validators={{required: false}}*/}
          {/*  name="returnRate"*/}
          {/*  variant="standard"*/}
          {/*  margin="dense"*/}
          {/*  fullWidth*/}
          {/*  label="Return Rate %"*/}
          {/*  value={returnRate}*/}
          {/*  onChange={(event: any) => setReturnRate(event.target.value)}*/}
          {/*/>*/}
        </Box>
      }
      {assetCategory === AssetCategory.RealEstateAndProperty &&
        <TextFieldValidator
          type="string"
          validators={{required: false}}
          name="returnRate"
          variant="standard"
          margin="dense"
          label="Growth Rate %"
          value={returnRate}
          onChange={(event: any) => setReturnRate(event.target.value)}
        />
      }
    </ModelEditDialog>
  )
}

export default AssetEditDialog