import Plan from "../../../model/Plan";
import Model from "../../../model/Model";
import PlanChange, {PlanChangeType} from "../../../model/PlanChange";
import {useEffect, useState} from "react";
import Person from "../../../model/Person";
import SocialSecurityStrategyChange, {
  ISocialSecurityStrategyChange
} from "../../../model/changes/SocialSecurityStrategyChange";
import Income, {IncomeType} from "../../../model/Income";
import {
  ageToStringFormat,
  getISODateFromDate, isoToLocalDate, isoToLocalDateString,
  moneyToNumberFormat,
  numberToMoneyFormat, numberToStringFormat,
} from "../../../stores/StoreUtilities";
import {useResources} from "../../../stores/ResourceProvider";
import {Box, Checkbox, DialogContentText, FormControlLabel, Grid, Switch} from "@mui/material";
import Milestone from "../../../model/Milestone";
import {IPersonTimeline} from "../../../components/calculator/Calculator";
import {addYears} from "date-fns";
import MilestoneDateFieldValidator from "../../../components/form/MilestoneDateFieldValidator";
import * as React from "react";
import TextFieldValidator from "../../../components/form/TextFieldValidator";
import {CreatePlanChangeInput, UpdatePlanChangeInput} from "../../../API";
import ModelEditDialog from "../../../components/model/ModelEditDialog";
import Tracking from "../../../components/Tracking";

const SocialSecurityStrategyDialog = ({
  open,
  plan,
  model,
  onClose,
  onSave
}: {
  open? : boolean
  plan: Plan
  model: Model
  onClose?(): void
  onSave?(plan: Plan, update: PlanChange): void
}) => {
  const [planModel, setPlanModel] = useState<Plan>(plan)
  const [isOpen, setIsOpen] = useState<boolean>(open === true)
  const [planChange, setPlanChange] = useState<SocialSecurityStrategyChange | undefined>()
  const [person1, setPerson1] = useState<Person | undefined>()
  const [person2, setPerson2] = useState<Person | undefined>()

  const [age1, setAge1] = useState<number | undefined>()
  const [defaultAge1, setDefaultAge1] = useState<number | undefined>()
  const [startDate1, setStartDate1] = useState<string | undefined>()
  const [defaultStartDate1, setDefaultStartDate1] = useState<string | undefined>()
  const [amount1, setAmount1] = useState<string | undefined>()
  const [defaultAmount1, setDefaultAmount1] = useState<string | undefined>()
  const [options1, setOptions1] = useState<Milestone[]>([])

  const [age2, setAge2] = useState<number | undefined>()
  const [defaultAge2, setDefaultAge2] = useState<number | undefined>()
  const [startDate2, setStartDate2] = useState<string | undefined>()
  const [defaultStartDate2, setDefaultStartDate2] = useState<string | undefined>()
  const [amount2, setAmount2] = useState<string | undefined>()
  const [defaultAmount2, setDefaultAmount2] = useState<string | undefined>()
  const [options2, setOptions2] = useState<Milestone[]>([])
  const [commitChanges, setCommitChanges] = useState<boolean>(false)

  const { modelStore, calculator, notify } = useResources()

  useEffect(() => {
    setPlanModel(plan)
    setIsOpen(open === true)
    let change: SocialSecurityStrategyChange = plan.getChange(PlanChangeType.SocialSecurityStrategy) as SocialSecurityStrategyChange
    if (!change) {
      change = new SocialSecurityStrategyChange({
        accountId: plan.accountId,
        userId: model.userId,
        modelId: model.id,
        planId: plan.id,
        changeType: PlanChangeType.SocialSecurityStrategy,
        name: "Social Security Strategy",
        description: "",
        enabled: true,
      })
    }
    setPlanChange(change)
    if (model.person1) {
      const timeline = calculator.personTimeline(model.person1)
      setPerson1(model.person1)

      if (change.startDate1) {
        setStartDate1(change.startDate1)
        setAge1(change.age1)
      }
      if (change.amount1 !== undefined) {
        setAmount1(numberToMoneyFormat(change.amount1, 0))
      }

      // Get defaults
      const income = getSocialSecurityIncome(model.person1)
      if (income) {
        const date = income.startDate
        if (date) {
          setDefaultStartDate1(getISODateFromDate(date))
          setDefaultAge1(model.person1.getAgeOnDate(date))
        }
        setDefaultAmount1(numberToMoneyFormat(income.amount,0))
      } else {
        // Default to full retirement age
        if (timeline?.fullSocialSecurityDate) {
          setDefaultStartDate1(getISODateFromDate(timeline.fullSocialSecurityDate))
          setDefaultAge1(timeline.fullSocialSecurityAge)
        }
      }

      if (timeline) {
        setOptions1(getOptions(timeline))
      }
    }

    if (model.person2) {
      const timeline = calculator.personTimeline(model.person2)
      setPerson2(model.person2)
      if (change.startDate2) {
        setStartDate2(change.startDate2)
        setAge2(change.age2)
      }
      if (change.amount2 !== undefined) {
        setAmount2(numberToMoneyFormat(change.amount2,0))
      }

      // Get defaults
      const income = getSocialSecurityIncome(model.person2)
      if (income) {
        const date = income.startDate
        if (date) {
          setDefaultStartDate2(getISODateFromDate(date))
          setDefaultAge2(model.person2.getAgeOnDate(date))
        }
        setDefaultAmount2(numberToMoneyFormat(income.amount,0))
      } else {
        // Default to full retirement age
        if (timeline?.fullSocialSecurityDate) {
          setDefaultStartDate2(getISODateFromDate(timeline.fullSocialSecurityDate))
          setDefaultAge2(timeline.fullSocialSecurityAge)
        }
      }

      if (timeline) {
        setOptions2(getOptions(timeline))
      }
    }
  }, [plan])

  const getSocialSecurityIncome = (person: Person): Income | undefined => {
    const income = model.incomes.find((i: Income) => i.incomeType === IncomeType.SocialSecurityBenefits && i.ownerId === person.id)
    return income
  }

  const getOptions = (timeline: IPersonTimeline): Milestone[] => {
    const ages = [62, 63, 64, 65, 66, 67, 68, 69, 70]
    const options: Milestone[] = ages.map((age: number) => {
      let label
      if (age === timeline.earlySocialSecurityAge) {
        label = `${age} (Early Social Security)`
      } else if (age === timeline.fullSocialSecurityAge) {
        label = `${age} (Full Social Security)`
      } else if (age === timeline.delayedSocialSecurityAge) {
        label = `${age} (Delayed Social Security)`
      } else {
        label = String(age)
      }
      const date = addYears(timeline.birthDate, age)
      return new Milestone({
        id: getISODateFromDate(date),
        label: label,
        date: date
      })
    })
    return options
  }

  const handleClose = async (event: any) => {
    if (onClose) {
      onClose()
    }
  }

  const handleSave = async (event: any) => {
    try {
      let change: PlanChange | undefined

      const details: ISocialSecurityStrategyChange = {
        age1: age1,
        startDate1: startDate1,
        amount1: moneyToNumberFormat(amount1),
        age2: age2,
        startDate2: startDate2,
        amount2: moneyToNumberFormat(amount2),
      }
      
      if (planChange && !planChange.id) {
        const input: CreatePlanChangeInput = {
          accountId: planChange?.accountId,
          userId: planChange.userId,
          modelId: planChange.modelId,
          planId: planChange.planId,
          changeType: planChange.changeType,
          name: planChange.name,
          description: generateDescription(),
          enabled: !commitChanges,
          details: JSON.stringify(details)
        }
        change = await modelStore.createPlanChange(input)
        Tracking.event({action: "Social Security Strategy Created"})
      } else if (planChange) {
        const input: UpdatePlanChangeInput = {
          id: planChange.id,
          description: generateDescription(),
          enabled: !commitChanges,
          details: JSON.stringify(details)
        }
        change = await modelStore.updatePlanChange(input)
        Tracking.event({action: "Social Security Strategy Updated"})
      }

      if (change && commitChanges) {
        await change.commit(model, modelStore)
      }
      
      if (onSave && change) {
        onSave(plan, change)
      } else if (onClose) {
        onClose()
      }
    } catch (err: any) {
      notify.show('error', err.message)
    }
  }

  const generateDescription = () => {
    let array: string[] = []
    if (person1) {
      if (startDate1) {
        const amount = numberToMoneyFormat(moneyToNumberFormat(amount1), 0)
        array.push(`${person1!.nickname}: ${amount} at age ${ageToStringFormat(age1)}`)
      }
    }
    if (person2) {
      if (startDate2) {
        const amount = numberToMoneyFormat(moneyToNumberFormat(amount2), 0)
        array.push(`${person2!.nickname}: ${amount} at age ${ageToStringFormat(age2)}`)
      }
    }
    return array.join(", ")
  }

  if (!planChange) {
    return null
  }

  const commitDisabled = !(startDate1 || amount1 || startDate2 || amount2)

  return (
    <ModelEditDialog title="Social Security Strategy" open={isOpen} size="sm"
                     onCancel={handleClose}
                     onSave={handleSave}
    >
      <DialogContentText>
        Adjust Social Security start dates and benefit amounts.
      </DialogContentText>
      <Grid container spacing={2}>
        {person1 &&
          <Grid item xs={12} sm={6}>
            <MilestoneDateFieldValidator
              name="startDate1"
              label={`${person1.nickname}'s Start Date`}
              milestones={options1}
              value={startDate1}
              helperText={!startDate1 ? `Default: ${isoToLocalDateString(defaultStartDate1)}` : undefined}
              sx={{paddingTop:"5px"}}
              onChange={(value: string) => {
                setStartDate1(value)
                const date = isoToLocalDate(value)
                setAge1(person1!.getAgeOnDate(date))
              }}
            />
          </Grid>
          }
        {person1 &&
          <Grid item xs={12} sm={6}>
            <TextFieldValidator
              type="string"
              validators={{ required: false, minMoney: 0, isMoney: true }}
              name="amount1"
              variant="standard"
              helperText={amount1 === undefined ? `Default: ${defaultAmount1}` : undefined}
              margin="dense"
              label={`${person1.nickname}'s Benefit`}
              value={amount1}
              onChange={(event: any) => setAmount1(event.target.value)}
            />
          </Grid>
        }
        {person2 &&
          <Grid item xs={12} sm={6}>
            <MilestoneDateFieldValidator
              name="startDate2"
              label={`${person2.nickname}'s Start Date`}
              milestones={options2}
              value={startDate2}
              helperText={!startDate2 ? `Default: ${isoToLocalDateString(defaultStartDate2)}` : undefined}
              sx={{paddingTop:"5px"}}
              onChange={(value: string) => {
                setStartDate2(value)
                const date = isoToLocalDate(value)
                setAge2(person2!.getAgeOnDate(date))
              }}
            />
          </Grid>
        }
        {person2 &&
          <Grid item xs={12} sm={6}>
            <TextFieldValidator
              type="string"
              validators={{ required: false, minMoney: 0, isMoney: true }}
              name="amount2"
              variant="standard"
              margin="dense"
              label={`${person2.nickname}'s Benefit`}
              value={amount2}
              helperText={amount2 === undefined ? `Default: ${defaultAmount2}` : undefined}
              onChange={(event: any) => setAmount2(event.target.value)}
            />
          </Grid>
        }
        <Grid item xs={12}>
          <FormControlLabel control={
            <Checkbox checked={commitChanges}
                      disabled={commitDisabled}
                      onChange={(event: any) => {
                        setCommitChanges(event.target.checked)
                      }}
            />}
            label={`Commit these updates to ${model.name} to make them permanent.`}
            sx={{marginTop:1}}
            componentsProps={{
                typography: {
                  variant:"body2"
              }}
            }
          />
        </Grid>
      </Grid>
    </ModelEditDialog>
  )
}
export default SocialSecurityStrategyDialog