import Model from "../../../model/Model";
import Plan from "../../../model/Plan";
import {Box, FormLabel, Grid, Typography, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {FutureCalc, IBudgetCalc, IFutureYear, INetWorthCalc} from "../../../components/calculator/Calculator";
import {useResources} from "../../../stores/ResourceProvider";
import Person from "../../../model/Person";
import TimelineChart from "../timeline/charts/TimelineChart";
import CountdownWidget from "./widgets/CountdownWidget";
import NetWorthProjectionChart from "../optimizer/charts/NetWorthProjectionChart";
import Snapshot from "../../../model/Snapshot";
import {numberToMoneyFormat} from "../../../stores/StoreUtilities";
import BudgetProjectionChart from "../optimizer/charts/BudgetProjectionChart";
import AnnualBudgetWidget from "./widgets/AnnualBudgetWidget";
import {TaxEstimate, TaxInput} from "../../../components/tax/TaxEstimator";
import TaxableIncomeByTaxBracketChart from "../taxes/charts/TaxableIncomeByTaxBracketChart";
import FutureViewChart from "../optimizer/charts/FutureViewChart";
import LifetimeEstimatedTaxesChart from "../taxes/charts/LifetimeEstimatedTaxesChart";
import RetirementBenchmarksWidget from "./widgets/RetirementBenchmarksWidget";
import RiskAllocationPieChart from "../networth/charts/RiskAllocationPieChart";
import Asset, {AssetCategory} from "../../../model/Asset";


const DashboardView = ({
  model,
  plan,
  updatedAt,
  width
} : {
  model: Model
  plan: Plan
  updatedAt: string
  width: number
}) => {
  const [isFree, setIsFree] = useState<boolean>(false)
  const [currentYear, setCurrentYear] = useState<number>(0)
  const [currentModel, setCurrentModel] = useState<Model | undefined>()
  const [currentPlan, setCurrentPlan] = useState<Plan | undefined>()
  const [persons, setPersons] = useState<Person[]>([])
  const [netWorthCalcs, setNetWorthCalcs] = useState<INetWorthCalc[]>([])
  const [liquidAssets, setLiquidAssets] = useState<Asset[]>([])
  const [currentNetWorthCalc, setCurrentNetWorthCalc] = useState<INetWorthCalc | undefined>()
  const [currentBudgetCalc, setCurrentBudgetCalc] = useState<IBudgetCalc | undefined>()
  const [futureCalc, setFutureCalc] = useState<FutureCalc | undefined>()
  const [taxInput, setTaxInput] = useState<TaxInput | undefined>()
  const [taxEstimate, setTaxEstimate] = useState<TaxEstimate | undefined>()
  const [lifetimeTaxes, setLifetimeTaxes] = useState<number>(0)

  const { userStore, modelStore, calculator } = useResources()

  const theme = useTheme()
  const leftPanelWidth = 360
  const rightPanelWidth = width - leftPanelWidth - 52
  console.log(`width=${width}, rightPanelWidth=${rightPanelWidth}`)

  const styles = {
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"],
    },
  }

  useEffect(() => {
    setIsFree(userStore.isFree)
    setCurrentYear((new Date()).getFullYear())
    setCurrentModel(model)
    setCurrentPlan(plan)
    // loadSettings()
  }, [model, plan, updatedAt])

  useEffect(() => {
    if (currentModel) {
      setPersons(currentModel.persons.filter((p: Person) => p.id !== 'joint'))

      const liquid = currentModel.assets.filter((asset: Asset) => asset.assetCategory === AssetCategory.LiquidInvestableAssets)
      const real = currentModel.assets.filter((asset: Asset) => asset.assetCategory === AssetCategory.RealEstateAndProperty)
      const personal = currentModel.assets.filter((asset: Asset) => asset.assetCategory === AssetCategory.PersonalProperty)
      const other = currentModel.assets.filter((asset: Asset) => asset.assetCategory === AssetCategory.OtherAssets)
      const netWorthCalcs = currentModel.snapshots.map((snapshot: Snapshot) => {
        return calculator.calculateNetWorth(liquid, real, personal, other, currentModel.liabilities, snapshot)
      })
      setNetWorthCalcs(netWorthCalcs)
      setCurrentNetWorthCalc(netWorthCalcs[0])
      calculateFuture()
    }
  },[currentModel, currentPlan, updatedAt])

  const calculateFuture = async () => {
    if (!currentModel || !currentPlan) {
      return
    }

    const plan = currentPlan

    let maxEndYear: number = 0
    const latestLifeExpectancyDate = currentModel.getPlanEndDate(plan)
    if (latestLifeExpectancyDate) {
      const latestLifeExpectancyYear = latestLifeExpectancyDate.getFullYear()
      maxEndYear = latestLifeExpectancyYear
    }

    const multiModel = false

    const cloneModel = await modelStore.getModel(plan.modelId,false, true)
    if (cloneModel) {
      plan.applyChanges(cloneModel)
      modelStore.addCurrentSnapshot(cloneModel)
      const label = multiModel ? `${cloneModel.name} - ${plan.name}` : plan.name
      const calc = calculator.calculateFuture(cloneModel, label, maxEndYear)
      if (calc) {
        setFutureCalc(calc)
        setCurrentBudgetCalc(calc.futureYears[0].budgetCalc)
        setTaxInput(calc.futureYears[0].taxInput)
        setTaxEstimate(calc.futureYears[0].taxEstimate)
        const taxes = calc.futureYears.reduce((accum: number, fy: IFutureYear) => accum + fy.taxEstimate!.totalEstimatedTax ?? accum, 0)
        setLifetimeTaxes(taxes)
        const assets = cloneModel.assets.filter((asset: Asset) => asset.assetCategory === AssetCategory.LiquidInvestableAssets)
        setLiquidAssets(assets)
      }
      return calc
    }
  }

  const renderRetirementCountdown = () => {
    return (
      <Box display="flex" flexDirection="row" flexGrow={1} justifyContent="space-between" pl={0} mb={1} mr={0} pr={0}>
        <Box sx={{display:"flex", flexDirection:"column", flexGrow:0, width: { xs:"100%", sm:360}, minHeight:"100%"}}>
          <Typography variant="h3" color="primary">Retirement Countdown</Typography>
          <Box display="flex" paddingRight={2}>
            <CountdownWidget persons={persons}/>
          </Box>
        </Box>
        <Box display="flex" flexGrow={1}>
          <TimelineChart persons={persons}/>
        </Box>
      </Box>
    )
  }

  const renderBenchmarks = () => {
    if (futureCalc && netWorthCalcs.length > 0) {
      if (isFree) {
        return (
          <Box sx={{display:"flex", flexDirection:"column", width: { xs:"100%", sm:360}, minHeight:"100%"}}>
            <Typography variant="h3" color="primary">Retirement Benchmarks</Typography>
            <Box display="flex" flexDirection="column" flexGrow={1} paddingRight={0} paddingTop={1} width="100%">
              <RetirementBenchmarksWidget futureCalc={futureCalc} netWorthCalc={netWorthCalcs[0]} width="348px"/>
            </Box>
          </Box>
        )
      } else {
        return (
          <Box display="flex" flexDirection="row" flexGrow={1} justifyContent="stretch" pl={0} mt={2} mb={1} mr={0} pr={0}>
            <Box sx={{display:"flex", flexDirection:"column", width: { xs:"100%", sm:360}, minHeight:"100%"}}>
              <Typography variant="h3" color="primary">Retirement Benchmarks</Typography>
              <Box display="flex" flexDirection="column" flexGrow={1} paddingRight={0} paddingTop={1} width="100%">
                <RetirementBenchmarksWidget futureCalc={futureCalc} netWorthCalc={netWorthCalcs[0]} width="348px"/>
              </Box>
            </Box>
            <Box display="flex" flexGrow={1} justifyContent="stretch" sx={{overflowY:"hidden"}}>
              <FutureViewChart year={currentYear} futureCalcs={[futureCalc]} height="300" width={String(rightPanelWidth)} title="Liquid Asset Projection"/>
            </Box>
          </Box>
        )
      }
    } else {
      return null
    }
  }


  const renderNetWorth = () => {
    if (futureCalc && netWorthCalcs.length > 0) {
      if (isFree) {
        return (
          <Box sx={{display:"flex", flexDirection:"column", flexGrow:0, width: { xs:"100%", sm:360}, minHeight:"100%"}}>
            <Typography variant="h3" color="primary">Net Worth {currentYear}</Typography>
            <Box display="flex" paddingRight={2} paddingTop={1}>
              <Typography variant="h2" color="secondary">
                {numberToMoneyFormat(netWorthCalcs[0].netWorthTotal, 0)}
              </Typography>
            </Box>
            <Box display="flex" flexGrow={1} paddingRight={0} paddingTop={1} justifyContent="stretch">
              {/*<NetWorthApexChart netWorthCalcs={netWorthCalcs} selected={0} height="220" width="348"/>*/}
              <RiskAllocationPieChart liquidAssets={liquidAssets} height="348" width="348"/>
              {/*<AssetAllocationPieChart netWorthCalc={netWorthCalcs[0]} height="348" width="348"/>*/}
            </Box>
          </Box>
        )
      } else {
        return (
          <Box display="flex" flexDirection="row" flexGrow={1} justifyContent="stretch" pl={0} mt={2} mb={1} mr={0} pr={0}>
            <Box sx={{display:"flex", flexDirection:"column", flexGrow:0, width: { xs:"100%", sm:360}, minHeight:"100%"}}>
              <Typography variant="h3" color="primary">Net Worth {currentYear}</Typography>
              <Box display="flex" paddingRight={2} paddingTop={1}>
                <Typography variant="h2" color="secondary">
                  {numberToMoneyFormat(netWorthCalcs[0].netWorthTotal, 0)}
                </Typography>
              </Box>
              <Box display="flex" flexGrow={1} paddingRight={0} paddingTop={1} justifyContent="stretch">
                {/*<NetWorthApexChart netWorthCalcs={netWorthCalcs} selected={0} height="220" width="348"/>*/}
                <RiskAllocationPieChart liquidAssets={liquidAssets} height="348" width="348"/>
                {/*<AssetAllocationPieChart netWorthCalc={netWorthCalcs[0]} height="348" width="348"/>*/}
              </Box>
            </Box>
            <Box display="flex" flexGrow={1} justifyContent="stretch">
              <NetWorthProjectionChart futureCalc={futureCalc} year={currentYear} height="300" width={String(rightPanelWidth)} title="Lifetime Net Worth"/>
            </Box>
          </Box>
        )
      }
    } else {
      return null
    }
  }

  const renderBudget = () => {
    if (futureCalc && currentBudgetCalc) {
      const allExpenses = currentBudgetCalc.totalExpenses + currentBudgetCalc.totalDeductions + currentBudgetCalc.totalEstimatedTaxes
      const netIncome = currentBudgetCalc.totalIncome - allExpenses

      if (isFree) {
        return (
          <Box
            sx={{display: "flex", flexDirection: "column", flexGrow: 0, width: {xs: "100%", sm: 360}, minHeight: "100%"}}>
            <Typography variant="h3" color="primary">Budget {currentYear}</Typography>
            <Box display="flex" paddingRight={2} paddingTop={1} flexDirection="row" justifyContent="space-between">
              <Box display="flex" flexDirection="column" width="30%">
                <FormLabel sx={styles.formLabel}>Annual Income</FormLabel>
                <Typography variant="h4" color="secondary" gutterBottom>
                  {numberToMoneyFormat(currentBudgetCalc.totalIncome, 0)}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" width="30%">
                <FormLabel sx={styles.formLabel}>Annual Expenses</FormLabel>
                <Typography variant="h4" color="error" gutterBottom>
                  {numberToMoneyFormat(allExpenses, 0)}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" width="40%">
                <FormLabel sx={styles.formLabel}>Annual Net Income</FormLabel>
                <Typography variant="h3" color={netIncome >= 0 ? "secondary" : "error"} gutterBottom>
                  {numberToMoneyFormat(netIncome, 0)}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexGrow={1} padding={0} justifyContent="stretch" maxHeight={250} width={360}>
              <AnnualBudgetWidget budgetCalc={currentBudgetCalc} view={"Annual"} height="220" width="348"/>
            </Box>
          </Box>
        )
      } else {
        return (
          <Box display="flex" flexDirection="row" flexGrow={1} justifyContent="space-between" pl={0} mt={2} mb={1}
               mr={0} pr={0}>
            <Box
              sx={{display: "flex", flexDirection: "column", flexGrow: 0, width: {xs: "100%", sm: 360}, minHeight: "100%"}}>
              <Typography variant="h3" color="primary">Budget {currentYear}</Typography>
              <Box display="flex" paddingRight={2} paddingTop={1} flexDirection="row" justifyContent="space-between">
                <Box display="flex" flexDirection="column" width="30%">
                  <FormLabel sx={styles.formLabel}>Annual Income</FormLabel>
                  <Typography variant="h4" color="secondary" gutterBottom>
                    {numberToMoneyFormat(currentBudgetCalc.totalIncome, 0)}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" width="30%">
                  <FormLabel sx={styles.formLabel}>Annual Expenses</FormLabel>
                  <Typography variant="h4" color="error" gutterBottom>
                    {numberToMoneyFormat(allExpenses, 0)}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" width="40%">
                  <FormLabel sx={styles.formLabel}>Annual Net Income</FormLabel>
                  <Typography variant="h3" color={netIncome >= 0 ? "secondary" : "error"} gutterBottom>
                    {numberToMoneyFormat(netIncome, 0)}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexGrow={1} padding={0} justifyContent="stretch" maxHeight={250} width={360}>
                <AnnualBudgetWidget budgetCalc={currentBudgetCalc} view={"Annual"} height="220" width="348"/>
              </Box>
            </Box>
            <Box display="flex" flexGrow={1} maxHeight={350}>
              <BudgetProjectionChart futureCalc={futureCalc} year={currentYear} height="300"
                                     width={String(rightPanelWidth)} title="Lifetime Budget"/>
            </Box>
          </Box>
        )
      }
    } else {
      return null
    }
  }

  const renderTaxes = () => {
    if (taxInput && taxEstimate && futureCalc) {
      return (
        <Box display="flex" flexDirection="row" flexGrow={1} justifyContent="stretch" pl={0} mt={2} mb={1} mr={0} pr={0}>
          <Box sx={{display:"flex", flexDirection:"column", flexGrow:0, width: { xs:"100%", sm:360}, minHeight:"100%"}}>
            <Typography variant="h3" color="primary">Taxes {currentYear} </Typography>
            <Box display="flex" paddingRight={2} paddingTop={1} flexDirection="row" justifyContent="space-between">
              <Box display="flex" flexDirection="column" width="33%">
                <FormLabel sx={styles.formLabel}>Total Income</FormLabel>
                <Typography variant="h4" color="secondary" gutterBottom>
                  {numberToMoneyFormat(taxEstimate.totalIncome, 0)}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" width="33%">
                <FormLabel sx={styles.formLabel}>Adj Gross Income</FormLabel>
                <Typography variant="h4" color="secondary" gutterBottom>
                  {numberToMoneyFormat(taxEstimate.adjustedGrossIncome, 0)}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" width="34%">
                <FormLabel sx={styles.formLabel}>Taxable Income</FormLabel>
                <Typography variant="h3" color="secondary" gutterBottom>
                  {numberToMoneyFormat(taxEstimate.taxableIncome, 0)}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" paddingRight={2} paddingTop={0} flexDirection="row" justifyContent="space-between">
              <Box display="flex" flexDirection="column" width="33%">
                <FormLabel sx={styles.formLabel}>Tax Liability</FormLabel>
                <Typography variant="h4" color="error" gutterBottom>
                  {numberToMoneyFormat(taxEstimate.taxLiability, 0)}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" width="33%">
                <FormLabel sx={styles.formLabel}>Total Payments</FormLabel>
                <Typography variant="h4" color="secondary" gutterBottom>
                  {numberToMoneyFormat(taxEstimate.totalPayments, 0)}
                </Typography>
              </Box>
              {taxEstimate.amountOwed > 0 &&
                <Box display="flex" flexDirection="column" width="34%">
                  <FormLabel sx={styles.formLabel}>Amount Owed</FormLabel>
                  <Typography variant="h4" color="error" gutterBottom>
                    {numberToMoneyFormat(taxEstimate.amountOwed, 0)}
                  </Typography>
                </Box>
              }
              {taxEstimate.amountOwed <= 0 &&
                <Box display="flex" flexDirection="column" width="34%">
                  <FormLabel sx={styles.formLabel}>Refund Amount</FormLabel>
                  <Typography variant="h4" color="green" gutterBottom>
                    {numberToMoneyFormat(taxEstimate.amountOwed, 0)}
                  </Typography>
                </Box>
              }
            </Box>
            <Box display="flex" paddingRight={2} paddingTop={0} flexDirection="row" justifyContent="space-between">
              <Box display="flex" flexDirection="column" width="33%">
                <FormLabel sx={styles.formLabel}>Lifetime Taxes</FormLabel>
                <Typography variant="h4" color="error" gutterBottom>
                  {numberToMoneyFormat(lifetimeTaxes, 0)}
                </Typography>
              </Box>
            </Box>
            {/*<Box display="flex" flexGrow={1} paddingRight={0} paddingTop={0} justifyContent="stretch">*/}
              {/*<TaxableIncomeByTaxBracketChart year={currentYear} futureCalc={futureCalc} height="300" width="348"/>*/}
            {/*  <LifetimeEstimatedTaxesChart year={currentYear} futureCalc={futureCalc} height="190" width="348"/>*/}
            {/*</Box>*/}
          </Box>
          <Box display="flex" flexGrow={1} width={800}>
            <LifetimeEstimatedTaxesChart year={currentYear} futureCalc={futureCalc} height="300" width={String(rightPanelWidth)} title="Lifetime Taxes"/>
            {/*<TaxableIncomeByTaxBracketChart year={currentYear} futureCalc={futureCalc} height="300" width={String(rightPanelWidth)} title="Lifetime Taxes"/>*/}
            {/*<TaxableIncomeEstimateChart year={currentYear} futureCalc={futureCalc} height="300" />*/}
          </Box>
        </Box>
      )
    } else {
      return null
    }
  }

  return (
    <Box sx={{
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
    }}>
      <Box sx={{display:"flex", mt:1, pl:0, pb:0, mb:2}}>
        <Typography variant="h2" color="primary">Dashboard</Typography>
      </Box>
      {isFree &&
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {renderRetirementCountdown()}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            {renderBenchmarks()}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            {renderNetWorth()}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            {renderBudget()}
          </Grid>
        </Grid>
      }
      {!isFree &&
        <Box>
          {renderRetirementCountdown()}
          {renderBenchmarks()}
          {renderNetWorth()}
          {renderBudget()}
          {renderTaxes()}
        </Box>
        // <Grid container spacing={0}>
        //   <Grid item xs={12}>
        //     {renderRetirementCountdown()}
        //   </Grid>
        //   <Grid item>
        //     {renderBenchmarks()}
        //   </Grid>
        //   <Grid item>
        //     {renderNetWorth()}
        //   </Grid>
        //   <Grid item>
        //     {renderBudget()}
        //   </Grid>
        //   <Grid item>
        //     {renderTaxes()}
        //   </Grid>
        // </Grid>
      }
    </Box>
  )
}

export default DashboardView