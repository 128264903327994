import {
  FutureCalc,
  IAssetDetail,
  IExpenseCategorySummary, IExpenseDetail,
  IFutureYear,
  IIncomeDetail
} from "../../../../components/calculator/Calculator";
import React, {ReactElement, useEffect, useState} from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import {numberToMoneyFormat, numberToPercentFormat} from "../../../../stores/StoreUtilities";
import clone from "lodash.clone";
import globalStyles, {globalColors} from "../../../../styles/globalStyles";
import Income from "../../../../model/Income";
import {Gender} from "../../../../API";
import BasicMenuButton from "../../../../components/controls/BasicMenuButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FutureComparisonTable from "./FutureComparisonTable";
import {useNavigate} from "react-router-dom";
import RoutesConfig from "../../../../RoutesConfig";
import {getExpenseCategoryLabel} from "../../../../model/Expense";
import {GrowthModel} from "../../../../model/GrowthStrategy";
import {PlanChangeType} from "../../../../model/PlanChange";
import ExpenseStrategyChange, {ExpenseFilter} from "../../../../model/changes/ExpenseStrategyChange";
import {format} from "date-fns";

export interface IFutureViewSettings {
  changeOption: FutureViewChangeOption
}

export enum FutureViewChangeOption {
  BalanceChangePct,
  WithdrawalChangePct,
  YoYChangePct,
}

enum FutureYearDetail {
  Asset = "asset",
  Expense = "expense",
  Income = "income"
}

export enum FutureViewExpenseOption {
  AllExpenses,
  EssentialExpenses
}

const ExpenseColumnLabels = [
  "All Expenses",
  "Essential Expenses"
]

const ExpenseColumnHeadings = [
  <span>All Expenses</span>,
  <span>Essential Expenses</span>
]

const FutureViewChangeLabels = [
  "Balance Change %",
  "Withdrawal Change %",
  "Year over Year 1 (YoY1) Change %",
]

const FutureViewChangeHeadings = [
  <span>Balance Change&nbsp;%</span>,
  <span>Withdrawal Change&nbsp;%</span>,
  <span>YoY1 Change&nbsp;%</span>,
]


const FutureViewTable = ({
  year,
  futureCalcs,
  settings,
  onChange
} : {
  year: number
  futureCalcs: FutureCalc[]
  settings?: IFutureViewSettings
  onChange?: (settings: IFutureViewSettings) => any
}) => {
  const [currentYear, setCurrentYear] = useState<number>(0)
  const [futureCalc, setFutureCalc] = useState<FutureCalc | undefined>()
  const [yearDetail, setYearDetail] = useState<Record<number, FutureYearDetail>>({})
  const [showComparison, setShowComparison] = useState<boolean>(false)
  const [expenseOption, setExpenseOption] = useState<ExpenseFilter>(ExpenseFilter.All)
  const [changeOption, setChangeOption] = useState<FutureViewChangeOption>(FutureViewChangeOption.YoYChangePct)

  const navigate = useNavigate()
  const theme = useTheme()
  const styles = {
    th: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.1,
      height: 30,
      minHeight: 30,
      textAlign: "right",
    },
    tb: {
      fontSize: 14,
      whiteSpace: "nowrap",
    },
    tbIncome: {
      fontSize: 14,
      whiteSpace: "nowrap",
      textDecoration: "underline",
      "&:hover": {
        backgroundColor: "#aadeee99",
        cursor: "zoom-in"
      }
    },
    tbIncomeDetail: {
      fontSize: 14,
      whiteSpace: "nowrap",
      textDecoration: "underline",
      backgroundColor: "#aadeee99",
      cursor: "zoom-out"
    },
    tbExpense: {
      fontSize: 14,
      whiteSpace: "nowrap",
      textDecoration: "underline",
      "&:hover": {
        backgroundColor: "#ff000044",
        cursor: "zoom-in"
      }
    },
    tbExpenseDetail: {
      fontSize: 14,
      whiteSpace: "nowrap",
      backgroundColor: "#ff000044",
      textDecoration: "underline",
      cursor: "zoom-out"
    },
    tbTaxes: {
      fontSize: 14,
      whiteSpace: "nowrap",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer"
      }
    },
    tbAsset: {
      fontSize: 14,
      whiteSpace: "nowrap",
      textDecoration: "underline",
      "&:hover": {
        backgroundColor: "#ffff0055",
        cursor: "zoom-in"
      }
    },
    tbAssetNeg: {
      color: theme.palette.error.main,
      fontSize: 14,
      whiteSpace: "nowrap",
      textDecoration: "underline",
      "&:hover": {
        backgroundColor: "#ffff0055",
        cursor: "zoom-in"
      }
    },
    tbAssetDetail: {
      fontSize: 14,
      whiteSpace: "nowrap",
      backgroundColor: "#ffff0055",
      textDecoration: "underline",
      cursor: "zoom-out"
    },
    tbAssetDetailNeg: {
      color: theme.palette.error.main,
      fontSize: 14,
      whiteSpace: "nowrap",
      backgroundColor: "#ffff0055",
      textDecoration: "underline",
      cursor: "zoom-out"
    },
    tbDetailNotice: {
      color: theme.palette.info.dark,
      fontSize: 14,
      whiteSpace: "nowrap",
      backgroundColor: globalColors.details,
      cursor: "help"
    },
    tbDetail: {
      fontSize: 14,
      whiteSpace: "nowrap",
      backgroundColor: globalColors.details
    },
    tbDetailLabel: {
      fontSize: 11,
      backgroundColor: globalColors.details
    },
    tbDetailHead: {
      fontSize: 12,
      fontWeight: 600,
      backgroundColor: globalColors.details
    },
    trRetired: {
      backgroundColor: "rgba(183, 206, 175, 0.8)"
    },
    trHisLifeExpectancy: {
      backgroundColor: "rgba(153, 206, 224, 0.8)"
    },
    trHerLifeExpectancy: {
      backgroundColor: "rgba(255, 176, 190, 0.8)"
    },
    trAssetsDepleted: {
      backgroundColor: "rgba(255, 255, 0, 0.5)"
    },
    legendLabel: {
      fontSize: 14,
      marginLeft:8,
      marginRight: 16,
      lineHeight: 1.0
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"],
    },
  }

  const unaccountedExpenseTooltip = // "Expenses without an assigned asset account are assumed to be paid from a tax-free asset account. If paid from a taxable asset, then the asset should be designated on the expense or the asset account YTD Withdrawal should be updated so tax may be correctly calculated on the withdrawal."
        <div><p><b>Unaccounted Expenses</b> are previously paid expenses with an unknown payment account. Taxable asset withdrawals in the current year should be accounted for by updating the YTD Withdrawals field for the Asset or setting the Designated Payment Account field for the Expense(s).</p>
          <p>Expenses paid from non-qualified accounts—such as checking, savings, money market, or brokerage accounts—do not require special tax considerations.</p>
        </div>
  const proRatingTooltip =
    <div>
      <p><b>Snapshot Prorating</b> The current year details show the prorated (remaining) income, expenses and asset growth for the year, based on the latest saved (dated) snapshot.</p>
    </div>

  useEffect(() => {
    if (year) {
      setCurrentYear(year)
    }
    if (futureCalcs.length > 1) {
      setShowComparison(true)
    } else if (futureCalcs.length > 0) {
      setShowComparison(false)
      setFutureCalc(futureCalcs[0])
    }
    if (settings) {
      setChangeOption(settings.changeOption ?? FutureViewChangeOption.YoYChangePct)
    }
    setExpenseOption(getExpenseOption(futureCalcs[0]))
  }, [year, futureCalcs, settings])

  const getExpenseOption = (futureCalc: FutureCalc): ExpenseFilter => {
    let option = ExpenseFilter.All
    if (futureCalc.plan) {
      const change = futureCalc.plan.getChange(PlanChangeType.ExpenseStrategy)
      if (change && change.enabled) {
        const filter = (change as ExpenseStrategyChange).filter
        option = (filter && filter !== ExpenseFilter.All) ? filter : ExpenseFilter.All
      }
    }
    return option
  }

  const getExpenseValue = (fy: IFutureYear) => {
    let value = 0
    if (expenseOption === ExpenseFilter.Essential) {
      value = fy.essentialExpenses
    } else if (expenseOption === ExpenseFilter.Discretionary) {
      value = fy.discretionaryExpenses
    } else {
      value = fy.allExpenses
    }
    return value
  }

  const getChangePct = (fy: IFutureYear) => {
    let change = 0
    if (changeOption === FutureViewChangeOption.YoYChangePct) {
      change = fy.yoyChange
    } else if (changeOption === FutureViewChangeOption.WithdrawalChangePct) {
      change = fy.withdrawalRate
    } else if (changeOption === FutureViewChangeOption.BalanceChangePct) {
      change = (fy.netLiquidAssets - fy.currentLiquidAssets) / fy.currentLiquidAssets
    }
    return change
  }

  const getDetailChangePct = (detail: IAssetDetail) => {
    let change = 0
    if (changeOption === FutureViewChangeOption.YoYChangePct) {
      change = detail.yoyChange
    } else if (changeOption === FutureViewChangeOption.WithdrawalChangePct) {
      change = detail.withdrawalRate
    } else if (changeOption === FutureViewChangeOption.BalanceChangePct) {
      change = detail.boyBalance !== 0 ? (detail.eoyBalance - detail.boyBalance) / detail.boyBalance : 0
    }
    return change
  }

  const handleClickDetail = (year: number, detail?: FutureYearDetail) => {
    console.log(`handleClickDetail(${year}, ${detail}`)
    const record = yearDetail[year]
    if (record) {
      if (detail && detail !== record) {
        yearDetail[year] = detail
      } else {
        delete yearDetail[year]
      }
    } else if (detail) {
      yearDetail[year] = detail
    }
    setYearDetail(clone(yearDetail))
  }

  const handleClickTaxes = (year: number) => {
    navigate(`${RoutesConfig.calc.pathname}/taxes/${year}`)
  }

  const renderViewMenu = () => {
    const options = [...futureCalcs.map((fc: FutureCalc) => fc.label), "Plan Comparison"]

    if (futureCalcs.length === 0) {
      return null
    }

    if (futureCalcs.length === 1) {
      return (
        <Typography variant="h3" color="primary" gutterBottom>{futureCalc?.label}</Typography>
      )
    } else {
      const value = showComparison ? futureCalcs.length : futureCalc ? futureCalcs.indexOf(futureCalc) : undefined
      return (
        <BasicMenuButton
          icon={<ExpandMoreIcon sx={{color: theme.palette.primary.main}}/>}
          label={
            <Typography variant="h3" color="primary" sx={{padding:0, margin:0}}>
              {showComparison ? "Plan Comparison" : futureCalc?.label}
            </Typography>
          }
          labelPosition="left"
          menuPosition="right"
          options={options}
          value={value}
          onClickOption={(index: number) => {
            if (index < futureCalcs.length) {
              setFutureCalc(futureCalcs[index])
              setShowComparison(false)
            } else {
              setShowComparison(true)
            }
          }}
        />
      )
    }
  }

  const renderExpenseColumnHeading = () => {
    return (`${expenseOption} Expenses`)
  }

  const renderChangeColumnMenu = () => {
    return (
      <BasicMenuButton
        // icon={<ArrowDropDownIcon sx={{color: theme.palette.primary.main}}/>}
        label={FutureViewChangeHeadings[changeOption]}
        labelPosition="left"
        options={FutureViewChangeLabels}
        value={changeOption}
        onClickOption={(index: number) => {
          setChangeOption(index)
          if (onChange) {
            onChange({changeOption: index})
          }
        }}
      />
    )
  }

  const renderAges = (fc: FutureCalc, fy: IFutureYear) => {
    const spans : ReactElement[] = []
    if (fy.person1Age) {
      let color = (fc.person1?.gender === Gender.Male ? globalColors.maleText : globalColors.femaleText)
      spans.push(<span style={{color:color}}>{fy.person1Age}</span>)
      if (fy.person2Age) {
        spans.push(<span> / </span>)
        let color = (fc.person2?.gender === Gender.Male ? globalColors.maleText : globalColors.femaleText)
        spans.push(<span style={{color:color}}>{fy.person2Age}</span>)
      }
    }
    return spans
  }

  const renderFutureYear = (fc: FutureCalc, fy: IFutureYear) => {
    const detail = yearDetail[fy.year]
    const incomeStyle = (detail === 'income') ? styles.tbIncomeDetail : styles.tbIncome
    const expenseStyle =  (detail === FutureYearDetail.Expense)  ? styles.tbExpenseDetail : styles.tbExpense
    const assetStyle =  (detail === FutureYearDetail.Asset)  ? styles.tbAssetDetail : styles.tbAsset
    const assetStyleNeg =  (detail === FutureYearDetail.Asset)  ? styles.tbAssetDetailNeg : styles.tbAssetNeg
    let rowStyle: any
    if (fy.year === fc.latestRetirementYear) {
      rowStyle = styles.trRetired
    } else if (fc.person1 && fy.year === fc.person1!.lifeExpectancyYear) {
      rowStyle = (fc.person1.gender === Gender.Male) ? styles.trHisLifeExpectancy : styles.trHerLifeExpectancy
    } else if (fc.person2 && fy.year === fc.person2!.lifeExpectancyYear) {
      rowStyle = (fc.person2.gender === Gender.Male) ? styles.trHisLifeExpectancy : styles.trHerLifeExpectancy
    } else if (fy.currentLiquidAssets > 0 && fy.netLiquidAssets <= 0) {
      rowStyle = styles.trAssetsDepleted
    }
    const isProRated = fy.startAt.getMonth() > 0 || fy.startAt.getDate() > 1

    const growthModel = futureCalc!.growthStrategy.growthModel
    let growthRate = ""
    for (let detail of fy.assetDetails) {
      if (detail.boyBalance > 0 || detail.eoyBalance > 0) {
        const newRate = numberToPercentFormat(detail.growthRate, 2)
        if (!growthRate) {
          growthRate = newRate
        } else if (growthRate !== newRate) {
          growthRate = "Var%"
          break
        }
      }
    }
    if (!growthRate) {
      growthRate = futureCalc!.growthStrategy.ignoreCustomGrowthRates ?
        numberToPercentFormat(fy.growthRate, growthModel === GrowthModel.Simple ? 0 : 2) : "Var%"
    }

    const results = []
    results.push(
      <TableRow sx={rowStyle} key={fy.year}>
        <TableCell sx={styles.tb}>{fy.year}</TableCell>
        <TableCell sx={styles.tb}>{renderAges(fc, fy)}</TableCell>
        <TableCell sx={incomeStyle} align="right" onClick={() => handleClickDetail(fy.year, FutureYearDetail.Income)}>
          {numberToMoneyFormat(fy.grossIncome, 0)}
        </TableCell>
        <TableCell sx={styles.tb} align="right">{numberToMoneyFormat(fy.deductions, 0)}</TableCell>
        <TableCell sx={styles.tbTaxes} align="right" onClick={() => handleClickTaxes(fy.year)}>
          {numberToMoneyFormat(fy.taxes, 0)}
        </TableCell>
        <TableCell sx={styles.tb} align="right">{numberToPercentFormat(fy.taxRate, 2)}</TableCell>
        <TableCell sx={styles.tb} align="right">{numberToMoneyFormat(fy.netIncome, 0)}</TableCell>
        <TableCell sx={expenseStyle} align="right" onClick={() => handleClickDetail(fy.year, FutureYearDetail.Expense)}>
          {numberToMoneyFormat(getExpenseValue(fy), 0)}
        </TableCell>
        <TableCell sx={assetStyle} align="right" onClick={() => handleClickDetail(fy.year, FutureYearDetail.Asset)}>
          {numberToMoneyFormat(-fy.rmds, 0)}
        </TableCell>
        <TableCell sx={assetStyle} align="right" onClick={() => handleClickDetail(fy.year, FutureYearDetail.Asset)}>
          {numberToMoneyFormat(fy.contributions, 0)}
        </TableCell>
        <TableCell sx={fy.surplus < 0 ? assetStyleNeg : assetStyle} align="right" onClick={() => handleClickDetail(fy.year, FutureYearDetail.Asset)}>
          {numberToMoneyFormat(fy.surplus, 0)}
        </TableCell>
        <TableCell sx={assetStyle} align="right" onClick={() => handleClickDetail(fy.year, FutureYearDetail.Asset)}>
          {numberToMoneyFormat(fy.currentLiquidAssets, 0)}
        </TableCell>
        <TableCell sx={assetStyle} align="right" onClick={() => handleClickDetail(fy.year, FutureYearDetail.Asset)}>
          {growthRate}
        </TableCell>
        <TableCell sx={assetStyle} align="right" onClick={() => handleClickDetail(fy.year, FutureYearDetail.Asset)}>
          {numberToMoneyFormat(fy.netLiquidAssets, 0)}
        </TableCell>
        <TableCell sx={assetStyle} align="right" onClick={() => handleClickDetail(fy.year, FutureYearDetail.Asset)}>
          {numberToPercentFormat(getChangePct(fy), 1)}
        </TableCell>
      </TableRow>
    )

    const proRateNotice = (isProRated) ?
      <Tooltip title={proRatingTooltip} arrow
               componentsProps={{
                 tooltip: {
                   sx: globalStyles.detailedTooltip
                 }
               }}
      >
        <em>{`As of ${format(fy.startAt, "M/d/yy")}`} snapshot</em>
      </Tooltip>
      : ""

    if (detail === FutureYearDetail.Income) {
      const hasRemaining = fy.incomeDetails.findIndex((detail: IIncomeDetail) => detail.remaining !== undefined) >= 0
      results.push(
        <TableRow>
          <TableCell sx={styles.tbDetailHead} colSpan={2}>Income Details</TableCell>
          <TableCell sx={styles.tbDetailHead} align="right">Total</TableCell>
          {fy.yearNum === 1 && hasRemaining &&
            <TableCell sx={styles.tbDetailHead} align="right">Remaining</TableCell>
          }
          <TableCell sx={styles.tbDetailHead} align="right">Owner</TableCell>
          <TableCell sx={styles.tbDetailHead} colSpan={11}>Description</TableCell>
        </TableRow>
      )

      fy.incomeDetails.forEach((detail: IIncomeDetail, index: number) => {
        let description = ""
        const income = futureCalc!.incomes.find((i: Income) => i.id === detail.id)
        if (income) {
          if (income.owner && income.owner.lifeExpectancyYear < fy.year) {
            description = `${income.description} (inherited)`
          } else {
            description = income.description
          }
        }
        results.push(
          <TableRow key={`${fy.year}-income-${index}`}>
            <TableCell sx={styles.tbDetailNotice} colSpan={2}>{index === 0 ? proRateNotice : ""}</TableCell>
            <TableCell sx={styles.tbDetail} align="right">{numberToMoneyFormat(detail.amount, 0)}</TableCell>
            {fy.yearNum === 1 && hasRemaining &&
              <TableCell sx={styles.tbDetail} align="right">
                {numberToMoneyFormat(detail.remaining ?? 0, 0)}
              </TableCell>
            }
            <TableCell sx={styles.tbDetail} align="right">{income?.owner?.nickname ?? 'Joint'}</TableCell>
            <TableCell sx={styles.tbDetail} colSpan={11}>{description}</TableCell>
          </TableRow>
        )
      })
    } else if (detail === FutureYearDetail.Expense) {
      const hasRemaining = fy.expenseDetails.findIndex((detail: IExpenseDetail) => detail.remaining !== undefined) >= 0
      results.push(
        <TableRow>
          <TableCell sx={styles.tbDetailHead} colSpan={5}>Expense Details</TableCell>
          <TableCell sx={styles.tbDetailHead} colSpan={2}>Category</TableCell>
          <TableCell sx={styles.tbDetailHead} align="right">Total</TableCell>
          {fy.yearNum === 1 && hasRemaining &&
            <TableCell sx={styles.tbDetailHead} align="right">Remaining</TableCell>
          }
        </TableRow>
      )

      fy.expenseDetails.forEach((detail: IExpenseDetail, index: number) => {
        let description = getExpenseCategoryLabel(detail.category)
        results.push(
          <TableRow key={`${fy.year}-income-${index}`}>
            <TableCell sx={styles.tbDetailNotice} colSpan={5}>{index === 0 ? proRateNotice : ""}</TableCell>
            <TableCell sx={styles.tbDetail} colSpan={2}>{description}</TableCell>
            <TableCell sx={styles.tbDetail} align="right">{numberToMoneyFormat(detail.amount, 0)}</TableCell>
            {fy.yearNum === 1 && hasRemaining &&
              <TableCell sx={styles.tbDetail} align="right">
                {numberToMoneyFormat(detail.remaining ?? 0, 0)}
              </TableCell>
            }
          </TableRow>
        )
      })
    } else if (detail === FutureYearDetail.Asset) {
      if (fy.yearNum === 1 && fy.proRateBudget) {
        results.push(
          <TableRow>
            <TableCell sx={styles.tbDetailHead} colSpan={2}>Remaining</TableCell>
            <TableCell sx={styles.tbDetail} align="right">{numberToMoneyFormat(fy.remainingIncome, 0)}</TableCell>
            <TableCell sx={styles.tbDetail} align="right">{numberToMoneyFormat(fy.remainingDeductions, 0)}</TableCell>
            <TableCell sx={styles.tbDetail} align="right">{numberToMoneyFormat(fy.taxes, 0)}</TableCell>
            <TableCell sx={styles.tbDetail} colSpan={2}></TableCell>
            <TableCell sx={styles.tbDetail} align="right">{numberToMoneyFormat(fy.remainingExpenses, 0)}</TableCell>
            <TableCell sx={styles.tbDetail} align="right">{numberToMoneyFormat(-fy.rmds, 0)}</TableCell>
            <TableCell sx={styles.tbDetail} align="right">{numberToMoneyFormat(fy.remainingDeductions, 0)}</TableCell>
            <TableCell sx={styles.tbDetail} align="right">{numberToMoneyFormat(fy.remainingSurplus, 0)}</TableCell>
            <TableCell sx={styles.tbDetail} colSpan={4}></TableCell>
          </TableRow>
        )
      }

      const hasRemaining = fy.assetDetails.findIndex((detail: IAssetDetail) => detail.withdrawals !== undefined && detail.withdrawals !== 0) >= 0
      const beginBalanceLabel = `${format(fy.startAt, "M/d")} Balance`
      results.push(
        <TableRow>
          <TableCell sx={styles.tbDetailHead} colSpan={4}>Asset Details</TableCell>
          <TableCell sx={styles.tbDetailHead} align="right">Owner</TableCell>
          <TableCell sx={styles.tbDetailHead} colSpan={2}>Description</TableCell>
          <TableCell sx={styles.tbDetailHead} align="right">Conversion</TableCell>
          <TableCell sx={styles.tbDetailHead} align="right">RMDs</TableCell>
          <TableCell sx={styles.tbDetailHead} align="right">Contribution</TableCell>
          <TableCell sx={styles.tbDetailHead} align="right">Distributions</TableCell>
          <TableCell sx={styles.tbDetailHead} align="right">{beginBalanceLabel}</TableCell>
          <TableCell sx={styles.tbDetailHead} align="right">Growth %</TableCell>
          <TableCell sx={styles.tbDetailHead} align="right">End Balance</TableCell>
          <TableCell sx={styles.tbDetailHead} align="right">{FutureViewChangeHeadings[changeOption]}</TableCell>
        </TableRow>
      )

      let firstRow = true
      fy.assetDetails.forEach((detail: IAssetDetail, index: number) => {
        if (detail.boyBalance > 0 || detail.eoyBalance > 0 || detail.distribution !== 0) {
          results.push(
            <TableRow key={`${fy.year}-asset-${index}`}>
              <TableCell sx={styles.tbDetailNotice} colSpan={4}>{firstRow ? proRateNotice : ""}</TableCell>
              <TableCell sx={styles.tbDetail} align="right">{detail.liquidAsset.owner?.nickname ?? 'Joint'}</TableCell>
              <TableCell sx={styles.tbDetail} colSpan={2}>{detail.liquidAsset.description}</TableCell>
              <TableCell sx={styles.tbDetail} align="right">{numberToMoneyFormat(detail.conversion, 0)}</TableCell>
              <TableCell sx={styles.tbDetail} align="right">{numberToMoneyFormat(-detail.rmd, 0)}</TableCell>
              <TableCell sx={styles.tbDetail} align="right">{numberToMoneyFormat(detail.contribution, 0)}</TableCell>
              {fy.yearNum === 1 && detail.distribution !== 0 &&
                <TableCell sx={styles.tbDetail} align="right">
                  <span style={styles.tbDetailLabel}>Distributed:</span> {numberToMoneyFormat(detail.withdrawals, 0)}<br/>
                  <span style={styles.tbDetailLabel}>Remaining:</span> {numberToMoneyFormat(detail.distribution - detail.withdrawals, 0)}<br/>
                  <span style={styles.tbDetailLabel}>Est Total:</span> {numberToMoneyFormat(detail.distribution, 0)}
                </TableCell>
              }
              {(fy.yearNum > 1 || detail.distribution === 0) &&
                <TableCell sx={styles.tbDetail} align="right">{numberToMoneyFormat(detail.distribution, 0)}</TableCell>
              }
              <TableCell sx={styles.tbDetail} align="right">{numberToMoneyFormat(detail.boyBalance, 0)}</TableCell>
              <TableCell sx={styles.tbDetail} align="right">{numberToPercentFormat(detail.growthRate, 2)}</TableCell>
              <TableCell sx={styles.tbDetail} align="right">{numberToMoneyFormat(detail.eoyBalance, 0)}</TableCell>
              <TableCell sx={styles.tbDetail} align="right">{numberToPercentFormat(getDetailChangePct(detail), 1)}</TableCell>
            </TableRow>
          )
          firstRow = false
        }
      })

      if (fy.yearNum === 1 && fy.unaccounted < 0) {
        results.push(
          <TableRow key={`${fy.year}-unaccounted`}>
            <TableCell sx={styles.tbDetail} colSpan={5}></TableCell>
            <TableCell sx={styles.tbDetailNotice} colSpan={2}>
              <Tooltip title={unaccountedExpenseTooltip} arrow
                       componentsProps={{
                         tooltip: {
                           sx: globalStyles.detailedTooltip
                         }
                       }}
              >
                <span>Unaccounted Expenses</span>
              </Tooltip>
            </TableCell>
            <TableCell sx={styles.tbDetail} colSpan={3}></TableCell>
            <TableCell sx={styles.tbDetail} align="right">{numberToMoneyFormat(fy.unaccounted, 0)}</TableCell>
            <TableCell sx={styles.tbDetail} colSpan={4}></TableCell>
          </TableRow>
        )
      }
    }

    return results
  }

  if (showComparison) {
    return (
      <Box display="flex" flexGrow={1} flexDirection="column" mt={2}>
        <Box display="flex" flexGrow={1} justifyContent="space-between" minHeight={40}>
          {renderViewMenu()}
        </Box>
        <FutureComparisonTable year={currentYear} futureCalcs={futureCalcs}/>
      </Box>
    )
  }

  if (!futureCalc) {
    return null
  }

  return (
    <Box display="flex" flexGrow={1} flexDirection="column" mt={2}>
      <Box display="flex" flexGrow={1} justifyContent="space-between" minHeight={40}>
        {renderViewMenu()}
        <Box display="flex" alignItems="center" pt={0}>
          <Box width={14} height={14} sx={styles.trRetired}></Box>
          <Typography variant="body2" style={styles.legendLabel}>Planned Retirement</Typography>
          {futureCalc.person1 &&
            <React.Fragment>
              <Box width={14} height={14} sx={futureCalc.person1.gender === Gender.Male ? styles.trHisLifeExpectancy : styles.trHerLifeExpectancy}></Box>
              <Typography variant="body2" style={styles.legendLabel}>{futureCalc.person1.nickname}'s Life Expectancy</Typography>
            </React.Fragment>
          }
          {futureCalc.person2 &&
            <React.Fragment>
              <Box width={14} height={14} sx={futureCalc.person2.gender === Gender.Male ? styles.trHisLifeExpectancy : styles.trHerLifeExpectancy}></Box>
              <Typography variant="body2" style={styles.legendLabel}>{futureCalc.person2.nickname}'s Life Expectancy</Typography>
            </React.Fragment>
          }
          <Box width={14} height={14} sx={styles.trAssetsDepleted}></Box>
          <Typography variant="body2" style={styles.legendLabel}>Liquid Assets Final Year</Typography>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" >
          <TableHead>
            <TableRow >
              <TableCell sx={styles.th}>Year</TableCell>
              <TableCell sx={styles.th}>Age(s)</TableCell>
              <TableCell sx={styles.th}>Gross Income</TableCell>
              <TableCell sx={styles.th}>Deductions</TableCell>
              <TableCell sx={styles.th}>Est Taxes</TableCell>
              <TableCell sx={styles.th}>Est Tax&nbsp;%</TableCell>
              <TableCell sx={styles.th}>Net Income</TableCell>
              <TableCell sx={styles.th}>{renderExpenseColumnHeading()}</TableCell>
              <TableCell sx={styles.th}>RMDs</TableCell>
              <TableCell sx={styles.th}>Contribution</TableCell>
              <TableCell sx={styles.th}>Surplus&nbsp;/ Shortfall</TableCell>
              <TableCell sx={styles.th}>Begin Balance</TableCell>
              <TableCell sx={styles.th}>Growth&nbsp;%</TableCell>
              <TableCell sx={styles.th}>End Balance</TableCell>
              <TableCell sx={styles.th}>{renderChangeColumnMenu()}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {futureCalc.futureYears.map((fy: IFutureYear, index: number) =>
              renderFutureYear(futureCalc, fy)
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default FutureViewTable