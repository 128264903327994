import React from "react";
import { MobXProviderContext } from 'mobx-react'
import Confirm from "../components/confirm/Confirm";
import UserStore from "./UserStore";
import Notify from "../components/notify/Notify";
import Alert from "../components/alert/Alert";
import AccountStore from "./AccountStore";
import Progress from "../components/Progress";
import Calculator from "../components/calculator/Calculator";
import TaxEstimator from "../components/tax/TaxEstimator";
import ModelStore from "./ModelStore";
import AuthAPI from "../apis/AuthAPI";
import MigrateAPI from "../apis/MigrateAPI";
import TermsOfUse from "../components/page/TermsOfUse";
import PrivacyPolicy from "../components/page/PrivacyPolicy";
import BillingAPI from "../apis/BillingAPI";
import ConvertKitAPI from "../apis/ConvertKitAPI";
import RbcAPI from "../apis/RbcAPI";

/*
  As a workaround for using MobX with functional components, we can create a hook for those 
  components to access what the MobX Provider passes down. 
  In the future, we'll probably want to use the React Context API directly, 
  instead of MobX's wrapper. 
  https://mobx-react.js.org/recipes-migration 
*/ 
interface IResources {
  confirm: Confirm
  notify: Notify
  alert: Alert
  progress: Progress
  authAPI: AuthAPI,
  rbcAPI: RbcAPI,
  billingAPI: BillingAPI
  convertKitAPI: ConvertKitAPI
  migrateAPI: MigrateAPI
  privacyPolicy: PrivacyPolicy
  termsOfUse: TermsOfUse
  accountStore: AccountStore
  userStore: UserStore
  modelStore: ModelStore
  calculator: Calculator
  taxEstimator: TaxEstimator
}

export function useResources(): IResources {
  return React.useContext(MobXProviderContext) as IResources
}

/*
  In the future, we may want to move off of using MobX's Provider and @inject, as it's advised
  to use the React Context API directly now. 

  To be able to similarly use our central stores/APIs as we have in the past, 
  we can create a context for use in functional components, and then 
  wrap class components in a higher-order component that passes in the context. 
  https://dev.to/shevchenkonik/react-typescript-mobx-4mei 

  The below code is a rough implementation of that approach. 
*/ 

// import { createContext, ReactNode, useContext } from "react";
// import { RootStore } from "./RootStore";
// import hoistNonReactStatics from 'hoist-non-react-statics';

// let store: RootStore;
// const RootStoreContext = createContext<RootStore | undefined>(undefined);
// RootStoreContext.displayName = "RootStoreContext";

// // export const useStores = (): RootStore => useContext(RootStoreContext);

// export function useRootStore() {
//   const rootStore = useContext(RootStoreContext);
//   if (rootStore === undefined) {
//     throw new Error("useRootStore must be used within RootStoreProvider");
//   }

//   return rootStore;
// }

// export function useUserStore() {
//   const { userStore } = useRootStore();
//   return userStore;
// }

// export function RootStoreProvider({ children }: { children: ReactNode }) {
//   // Only create root store once (store is a singleton). 
//   const root = store ?? new RootStore();

//   return (
//     <RootStoreContext.Provider value={root}>
//       {children}
//     </RootStoreContext.Provider>
//   )
// }

/*
  It may not be good to use @inject any more: 
  https://mobx-react.js.org/recipes-inject

  Instead, create a Higher-order component (HOC) for feeding the stores to class components. 
  https://dev.to/shevchenkonik/react-typescript-mobx-4mei
  https://maxrozen.com/implement-higher-order-component-react-typescript 
*/

// export interface WithRootStoreProps {
//   rootStore?: RootStore
// }

// export type TWithStoreHOC = <P extends object>(Component: React.ComponentType<P>) => (props: P) => JSX.Element

// export const withRootStore: TWithStoreHOC = <P extends object>(WrappedComponent: React.ComponentType<P>) => (props: P) => {
//   const ComponentWithStore = () => {
//     const rootStore = useRootStore()

//     return <WrappedComponent {...props} rootStore={rootStore} />;
//   };

//   ComponentWithStore.defaultProps = { ...WrappedComponent.defaultProps };
//   ComponentWithStore.displayName = `WithStores(${
//       WrappedComponent.name || WrappedComponent.displayName
//   })`;

//   // Recommended in the React docs... 
//   // https://reactjs.org/docs/higher-order-components.html#static-methods-must-be-copied-over
//   hoistNonReactStatics(ComponentWithStore, WrappedComponent);

//   return <ComponentWithStore />;
// }
