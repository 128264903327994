import PlanChange from "../PlanChange";
import Model from "../Model";
import AssetConversion, {AssetConversionType} from "../AssetConversion";
import ModelStore from "../../stores/ModelStore";
import {CreateAssetConversionInput, UpdatePlanChangeInput} from "../../API";

export interface IConversionStrategyChange {
  conversions: AssetConversion[]
}

class ConversionStrategyChange extends PlanChange implements IConversionStrategyChange {
  conversions: AssetConversion[]

  constructor (data: any) {
    super(data)
    this.conversions = this.details ? JSON.parse(this.details) : []
  }

  apply(model: Model) {
    this.conversions.forEach((c: AssetConversion) => {
      // Look for existing conversion
      const found = model.assetConversions.find((a: AssetConversion) =>
        a.srcAssetId === c.srcAssetId && a.dstAssetId === c.dstAssetId && a.year === c.year)
      if (!found) {
        model.assetConversions.push(c)
        model.addLock(c.id, "*")
      }
    })
  }

  async commit(model: Model, modelStore: ModelStore) {
    const promises: Promise<AssetConversion | undefined>[] = []
    this.conversions.forEach((c: AssetConversion) => {
      // Look for existing conversion
      const found = model.assetConversions.find((a: AssetConversion) => a.id === c.id)
      if (!found || !found.updatedAt) {
        const input: CreateAssetConversionInput = {
          id: c.id,
          userId: model.userId,
          accountId: model.accountId,
          modelId: model.id,
          description: c.description,
          conversionType: AssetConversionType.RothConversion,
          srcAssetId: c.srcAssetId,
          dstAssetId: c.dstAssetId,
          year: c.year,
          amount: c.amount,
          taxableAmount: c.taxableAmount,
          incomeType: c.incomeType,
          sortOrder: model.assetConversions.length > 0 ? model.assetConversions[model.assetConversions.length-1].sortOrder + ModelStore.orderInterval : 0
        }
        promises.push(modelStore.createAssetConversion(input))
        model.deleteLock(c.id, "*")
      }
    })

    await Promise.all(promises)
    // await modelStore.deletePlanChange(this.id)
  }
}

export default ConversionStrategyChange