import config from 'react-global-configuration';

export class RoutesConfigHelpers {
  static getRouteForPathname = (pathname: string, routes?: any): IRouteData | undefined => {
    // Chop off the initial "/" in the pathname. 
    const baseURL = config.get("homeUrl")
    const cleanPathname = new URL(pathname, baseURL).pathname  
    const matches: IRouteData[] = []
    Object.values(routes ?? RoutesConfig).forEach((routeData) => {
      const route = routeData as IRouteData
      // Chop off the initial "/" in the path. 
      const checkCleanPathname = route.pathname ? new URL(route.pathname, baseURL).pathname.split("/:")[0] : undefined // route.path ? route.path.split("/")[1] : ""
      if (checkCleanPathname === "/" && cleanPathname === "/") {
        // Special case 
        matches.unshift(route)
      } else if (checkCleanPathname && checkCleanPathname !== "/") {
        if (cleanPathname === checkCleanPathname) {
          matches.unshift(route)
        } else if (cleanPathname.includes(checkCleanPathname)) {
          matches.push(route)
        }
      } 
    })

    // Assume the first value is the correct match. 
    const route: IRouteData | undefined = matches.length > 0 ? matches[0] : undefined

    return route
  }

  static routeExistsForPathname = (pathname: string, routes?: any): boolean => {
    const routeMatch = RoutesConfigHelpers.getRouteForPathname(pathname, routes ?? RoutesConfig)
    if (routeMatch) {
      return true
    }

    return false
  }

  static routeRequiresLogin = (route: IRouteData): boolean => {
    // Check if the route has any requirements
    const routeRequirements = route.routeRequirements
    if (routeRequirements && routeRequirements.isAuthenticated) {
      return true 
    }

    return false
  }

  static roleMeetsRouteRequirements = (route: IRouteData, role: string): boolean => {
    // Check if the route has any role requirements
    const routeRequirements = route.routeRequirements
    if (routeRequirements && routeRequirements.roles) {
      if (!routeRequirements.roles.find((r: string) => r === role)) {
        return false
      }
    }
    
    return true
  }

  static isCurrentRoute(pathname: string, route: IRouteData) {
      const cleanPathname = pathname.split("?")[0].split('/')[1]
      const checkCleanPathname = route.pathname ? route.pathname.split("/")[1] : route.href! 
      
      if (cleanPathname === "" && checkCleanPathname === "") {
        return true 
      } else if (checkCleanPathname !== "" && String(cleanPathname).includes(checkCleanPathname)) {
        return true 
      }
      return false 
    }

    // static getTabName(pathname: string) {
    //   const parts = pathname.split('/')
    //   if (parts.length > 1) {
    //     return parts[parts.length-1]
    //   } else {
    //     return ''
    //   }
    // }

    static getPathSegment(pathname: string, index: number) {
      const segments = pathname.split('/')
      if (segments.length > index) {
        return segments[index]
      } else {
        return ''
      }
  }
}

export interface IRouteData {
  title: string
  pathname?: string
  href?: string
  routeRequirements?: IRouteRequirements // Role requirements for the user to access this route, if needed. 
}

/**
 * undefined -> Allows unauthenticated access. 
 * isAuthenticated -> The Route requires authentication. 
 * roles -> The user is required to have one of the listed roles in order to access the Route. Example: roles["Admin","Agent","Employer"]
 */
export interface IRouteRequirements {
  isAuthenticated: boolean 
  roles: string[]
}

// TODO: It would be nice to define RoutesData with a type, but there are issues using it. 
// export type IRoutesData = { [key: string]: IRouteData }

const RoutesConfig = {
  admin: {
    title: "Admin",
    pathname: "/admin",
    routeRequirements: { isAuthenticated: true, roles: ["Admin", "Advisor"] }
  },
  calc: {
    title: "Calculator",
    pathname: "/calc",
    routeRequirements: { isAuthenticated: true, roles: ["Admin", "Advisor", "Customer"] }
  },
  checkoutReturn: {
    title: "Checkout Return",
    pathname: "/checkout-return"
  },
  community: {
    title: "Community",
    pathname: "/community",
    routeRequirements: { isAuthenticated: true }
  },
  home: {
    title: "Home",
    pathname: "/"
  },
  invite: {
    title: "Invite",
    pathname: "/invite"
  },
  settings: {
    title: "Account Settings",
    pathname: "/settings",
    routeRequirements: { isAuthenticated: true, roles: ["Admin", "Advisor", "Customer"] }
  },
  signIn: {
    title: "Sign in",
    pathname: "/signin"
  },
  signOut: {
    title: "Sign out",
    pathname: "/signout"
  },
  signUp: {
    title: "Get Started",
    pathname: "/signup"
  },
  loading: {
    title: "Loading",
    pathname: "/loading"
  },
  migrate: {
    title: "Migrate Users",
    pathname: "/migrate",
    routeRequirements: { isAuthenticated: true, roles: ["Admin", "Advisor"] }
  },
  notFound: {
    title: "Not Found",
    pathname: "/404"
  },
  privacy: {
    title: "Privacy Policy",
    pathname: "/privacy"
  },
  privacyCCPA: {
    title: "Privacy Policy California",
    pathname: "/privacyCCPA"
  },
  terms: {
    title: "Terms of Use",
    pathname: "/terms"
  },
  users: {
    title: "Users",
    pathname: "/users",
    routeRequirements: { isAuthenticated: true, roles: ["Admin", "Advisor"] }
  },
  welcome: {
    title: "Welcome",
    pathname: "/welcome"
  }
}

export default RoutesConfig 
