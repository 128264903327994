import React, {ReactNode, SyntheticEvent, useEffect, useState} from "react";
import {SxProps} from "@mui/system";
import {Box, Theme, Typography, useTheme} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Section = ({
  title,
  titleSx,
  total,
  button,
  expanded,
  sx,
  children,
  onChange
}: {
  title: string,
  titleSx?: SxProps<Theme>
  total?: string,
  button?: ReactNode
  expanded?: boolean
  sx?: SxProps<Theme>
  children?: any
  onChange?: (event: SyntheticEvent, expanded: boolean) => any
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>( false)

  useEffect(() => {
    setIsExpanded(expanded === true)
  },[expanded])

  const theme = useTheme()

  const onExpand = (event: any) => {
    if (onChange) {
      onChange(event, !isExpanded)
    }
    setIsExpanded(!isExpanded)
  }

  let totalColor = theme.palette.primary.contrastText
  // if (total) {
  //   let value
  //   if (total.charAt(0) === '$') {
  //     value = parseFloat(total.substring(1))
  //   } else {
  //     value = parseFloat(total)
  //   }
  //   if (!isNaN(value) && value >= 0) {
  //     totalColor = theme.palette.secondary.light
  //   } else {
  //     totalColor = 'red'
  //   }
  // }

   return (
     <Box sx={sx}>
       <Box sx={{display:"flex", flexGrow:1, cursor:"pointer", borderBottom:"1px solid white"}} onClick={onExpand}>
         <Box display="flex" flexGrow={1} justifyContent="stretch" bgcolor={theme.palette.primary.main} pt="4px">
           <Box display="flex" flexGrow={0} width={20} pl="4px" pt="8px">
             {expanded ? <ExpandLessIcon sx={{color: theme.palette.primary.contrastText}}/> : <ExpandMoreIcon sx={{color: theme.palette.primary.contrastText}}/>}
           </Box>
           <Box display="flex" px={1.5} py={1}>
             <Typography variant="body1" color={theme.palette.primary.contrastText} sx={titleSx}>{title}</Typography>
           </Box>
           {total &&
             <Box display="flex" flexGrow={1} pl={1.5} pr={button === undefined ? 1.5 : 1} py={1} justifyContent="flex-end">
               <Typography variant="body1" color={totalColor}>{total}</Typography>
             </Box>
           }
           {button &&
             <Box display="flex" flexGrow={0} width={30} pt="4px" pr={0.5}>
               {button}
             </Box>
           }
         </Box>
       </Box>
       {isExpanded && children}
     </Box>
   )
}

export default Section
