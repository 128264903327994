import { createTheme } from '@mui/material/styles';
import {blueGrey, green} from '@mui/material/colors';

const rawTheme = createTheme({
  palette: {
    // type: "light",
    background: {
      default: "#fcfbf8", // '#E7E9EB', // '#fff', // '#EDEEF2', // '#dedada', // '#fff', // '#E1E3E5', // '#E7E1E1', // '#dedada', // blueGrey[50], // off white
      paper: '#fff' // pure white
    },
    primary: {
      main: 'rgb(31, 92, 167)', // blue
      dark: 'rgb(24, 66, 118)',
      light: 'rgb(51, 122, 183)'
    },
    secondary: {
      main: green[500], // orange[500],
      dark: green[900], // orange[900],
      light: green[300], // orange[50]
    },
    action: {
      active: '#0393ee',
      selected: '#0393ee',
      hover: "#87cefa", // "lightskyblue", // '#0393ee'
    },
    text: {
      primary: 'rgb(51, 51, 51)',
      secondary: 'rgb(127,127,127)' //'rgb(192, 192, 192)'
    },
    grey: {
      50: '#f5f7f8',
      100: '#F3F5F7',
      200: '#f5f7f9',
      300: '#e7e9eb',
      400: '#dbdee1',
      500: '#c0c5ca'
    },
  },
  typography: {
    // useNextVariants: true,
    button: {
      textTransform: 'initial',
      fontFamily: [
        'Helvetica Neue',
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(','),
      fontSize: "16px !important",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em"
    },
    h1: {
      fontWeight: 800,
      fontFamily: [
        'Poppins',
        'sans-serif'
      ].join(',')
    },
    h2: {
      fontSize: 50,
      fontWeight: 700,
      fontFamily: [
        'Poppins',
        'sans-serif'
      ].join(',')
    },
    h3: {
      fontSize: 32,
      fontWeight: 600,
      fontFamily: [
        'Poppins',
        'sans-serif'
      ].join(',')
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
      fontFamily: [
        'Poppins',
        'sans-serif'
      ].join(',')
    },
    h5: {
      fontWeight: 800,
      fontFamily: [
        'Helvetica Neue',
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(',')
    },
    h6: {
      fontFamily: [
        'Helvetica Neue',
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(','),
      fontSize: 16
    },
    body1: {
      fontFamily: [
        'Helvetica Neue',
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(','),
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.3
    },
    body2: {
      fontFamily: [
        'Helvetica Neue',
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(','),
      fontSize: 16,
      lineHeight: 1.3
    }
  },
  shape: {
    borderRadius: 10
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'rgb(127, 127, 127)',
          "&.Mui-selected": {
            fontWeight: 600
          }
        }
      }
    }
  }
})

const theme = createTheme(rawTheme, {
  typography: {
    h1: {
      fontSize: 44,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: 32
      },
    },
    h2: {
      fontSize: 32,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: 24
      },
    },
    h3: {
      fontSize: 22,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: 18
      },
    },
    h4: {
      fontSize: 20,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: 18
      }
    },
    h5: {
      fontSize: 16,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: 14
      }
    }
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: rawTheme.palette.primary.contrastText,
            backgroundColor: rawTheme.palette.primary.main
          },
          "&.Mui-selected": {
            backgroundColor: 'lightblue',
            "&:hover": {
              color: rawTheme.palette.primary.contrastText,
              backgroundColor: rawTheme.palette.primary.main
            }
          }
        },

      }
    },
    MuiDialog: {
      styleOverrides: {
        paperFullWidth: {
          [rawTheme.breakpoints.down('xs')]: {
            margin: 0,
            width: 'calc(100% - 32px) !important',
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          [rawTheme.breakpoints.down('xs')]: {
            paddingLeft: rawTheme.spacing(2),
          },
          [rawTheme.breakpoints.up('sm')]: {
            paddingLeft: rawTheme.spacing(3),
          },
          backgroundColor: rawTheme.palette.primary.main,
          color: rawTheme.palette.common.white
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          [rawTheme.breakpoints.down('xs')]: {
            paddingLeft: rawTheme.spacing(2),
            paddingRight: rawTheme.spacing(2),
          },
          [rawTheme.breakpoints.up('sm')]: {
            paddingLeft: rawTheme.spacing(3),
            paddingRight: rawTheme.spacing(3),
          },
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: "flex-end",
          alignItems: "center",
          [rawTheme.breakpoints.down('xs')]: {
            paddingLeft: rawTheme.spacing(2),
            paddingRight: rawTheme.spacing(2),
            paddingTop: rawTheme.spacing(1),
            paddingBottom: rawTheme.spacing(1),
          },
          [rawTheme.breakpoints.up('sm')]: {
            paddingLeft: rawTheme.spacing(3),
            paddingRight: rawTheme.spacing(3),
            paddingTop: rawTheme.spacing(2),
            paddingBottom: rawTheme.spacing(2),
          },
          backgroundColor: blueGrey[50]
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: 1.1
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        root: {
          zIndex: "9999 !important"
        }
      }
    },
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       boxShadow: 'rgba(0, 0, 0, 0.25) 0px 5px 7px 0px'
    //     }
    //   }
    // },
    // MuiRadio: {
    //   styleOverides: {
    //       root: {
    //         color: rawTheme.palette.info.main,
    //         "&.Mui-checked": {
    //           color: rawTheme.palette.info.main,
    //         }
    //       }
    //   }
    // },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'
        },
        elevation2: {
          boxShadow: 'rgba(149, 157, 165, 0.5) 0px 5px 27px'
        }
      }
      // root: {

      // }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: '#E7E9EB',
          // height: 50,
          // minHeight: 50,
          // maxHeight: 50,
          // "&:expanded": {
          //   height: 50,
          //   minHeight: 50,
          //   maxHeight: 50
          // }
        },
      }
    },
    MuiTreeItem: {
      styleOverrides: {
        content: {
          "&:hover": {
            backgroundColor: "lightblue",
            borderRadius: 5,
          }
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid rgba(0, 0, 0, 0.25)"
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        row: {
          "&:hover": {
            backgroundColor: rawTheme.palette.action.hover
          }
        }
      }
    }
  }
})

export default theme