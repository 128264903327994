import * as React from 'react';
import Box from '@mui/material/Box';
import {ReactNode, useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogButton from "../form/DialogButton";
import IconicButton from "../controls/IconicButton";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import {useResources} from "../../stores/ResourceProvider";
import FormValidator from "../form/FormValidator";

const ModelEditDialog = ({
  title,
  open,
  size,
  children,
  onCancel,
  onSave,
  onDelete,
  confirmDelete
}: {
  title: string,
  open: boolean,
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  children: ReactNode
  onCancel?: any
  onSave?: any
  onDelete?: any
  confirmDelete?: boolean
}) => {

  const { confirm } = useResources()

  const handleSave = (event: any) => {
    if (onSave) {
      onSave(event)
    }
  }

  const handleClose = (event: any, reason: string) => {
    if (reason !== 'backdropClick') {
      onCancel()
    }
  }

  const handleDelete = (event: any) => {
    if (confirmDelete === undefined || confirmDelete === true) {
      confirm.show('Confirm Delete', `Are you sure you want to delete this item?`,
        ['Delete', 'Cancel'],
        () => {
          onDelete()
          return true
        })
    } else {
      onDelete()
    }
  }

  return (
    <Dialog
      id="ModelEditDialog"
      open={open}
      onClose={handleClose}
      maxWidth={size ?? 'xs'}
      fullWidth
      aria-modal={true}
      aria-labelledby="ModelEditDialogTitle"
    >
      <FormValidator
        id="modelEditForm"
        name="modelEditForm"
        onSubmit={handleSave}
      >
        <DialogTitle id="ModelEditDialogTitle">
          {title}
        </DialogTitle>
        <DialogContent sx={{mt:2, mb:2, pb:0}}>
          {children}
        </DialogContent>
        <DialogActions>
          <Box display="flex" flexGrow={1} justifyContent={onDelete ? "space-between" : "flex-end"} alignItems="center">
            {onDelete &&
              <IconicButton onClick={handleDelete}>
                <DeleteIcon/>
              </IconicButton>
            }
            <Box display="flex" justifyContent="flex-end">
              <DialogButton variant="secondary" type="button" onClick={onCancel}>
                Cancel
              </DialogButton>
              <DialogButton variant="primary" type="submit" spacing="left" disabled={!onSave}>
                Save
              </DialogButton>
            </Box>
          </Box>
        </DialogActions>
      </FormValidator>
    </Dialog>
  );

}

export default ModelEditDialog