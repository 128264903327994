import {FutureCalc} from "../../../../components/calculator/Calculator";
import React, {useEffect, useState} from "react";
import {numberToMoneyFormat} from "../../../../stores/StoreUtilities";
import {globalColors} from "../../../../styles/globalStyles";
import {Paper, TableContainer} from "@mui/material";
import Chart from "react-apexcharts";
// import cloneDeep from "lodash.clonedeep";

const defaultOptions = {
  chart: {
    id: "future-view",
    type: 'area',
    stacked: true,
    height: 350,
    width: "100%",
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      },
      markerClick: (event: any, chartContext: any, details: any) => {
        console.debug(`markerClick`)
      }
    },
    zoom: {
      enabled: false,
      autoScaleYaxis: true
    },
    toolbar: {
      show: false,
      tools: {
        download: false
      }
    }
  },
  title: {
    text: "",
    align: "right",
    margin: 0,
    floating: true,
    style: {
      fontSize: "16px",
      fontFamily: "Poppins,sans-serif",
      color: "rgb(31, 92, 167)"
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left'
  },
  xaxis: {
    categories: [] as number[],
    axisBorder: {
      show: true
    }
  },
  yaxis: {
    labels: {
      formatter: (value: number) => {
        return numberToMoneyFormat(value, 0)
        // if (value < 1000000) {
        //   return `$${value / 1000}K`
        // } else {
        //   return `$${value / 1000000}M`
        // }
      }
    },
    min: 0
  },
  tooltip: {
    y: {
      formatter: function (value: number) {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  colors: [globalColors.income],
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100]
    }
  },
  responsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const comparisonOptions = {
  chart: {
    id: "future-view-comparison",
    type: 'line',
    stacked: false,
    height: 350,
    width: "100%",
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      }
    },
    toolbar: {
      show: true,
      tools: {
        download: false
      }
    }
  },
  title: {
    text: "",
    align: "center",
    margin: 0,
    floating: true,
    style: {
      fontSize: "16px",
      fontFamily: "Poppins,sans-serif",
      color: "rgb(31, 92, 167)"
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left'
  },
  xaxis: {
    categories: [] as number[],
    axisBorder: {
      show: true
    }
  },
  yaxis: {
    labels: {
      formatter: (value: number) => {
        // return numberToMoneyFormat(value, 0)
        if (value < 1000000) {
          return `$${value / 1000}K`
        } else {
          return `$${value / 1000000}M`
        }
      }
    },
    min: 0
  },
  tooltip: {
    y: {
      formatter: function (value: number) {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  colors: ["rgb(0, 128, 0)", "rgb(51, 122, 183)", "#EB781B", "darkmagenta"],
  fill: {
    type: 'none'
  },
  responsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const FutureViewChart = ({
  year,
  futureCalcs,
  height,
  width,
  title
}: {
  year: number
  futureCalcs: FutureCalc[]
  height?: string
  width?: string
  title?: string
}) => {
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<any>()
  const [currentYear, setCurrentYear] = useState<number>(0)

  useEffect(() => {
    calculateData()
    setCurrentYear(year)
  }, [futureCalcs])


  const calculateData = () => {
    const series = []

    let maxYear = 0
    let categories: number[] = []
    for (let futureCalc of futureCalcs) {
      let netData = []
      let futureYears = futureCalc.futureYears

      for (let i = 0; i < futureYears.length; i++) {
        const fc = futureYears[i]
        if (fc.year > maxYear) {
          categories.push(fc.year)
          maxYear = fc.year
        }
        netData.push(fc.netLiquidAssets)
      }
      series.push({
        name: futureCalc.label,
        data: netData
      })
    }

    setSeries(series)
    let options
    if (series.length > 1) {
      options = comparisonOptions // cloneDeep(comparisonOptions)
    } else {
      options = defaultOptions // cloneDeep(defaultOptions)
    }
    options.title.text = title ?? ""
    options.xaxis.categories = categories

    if (height) {
      options.chart.toolbar.show = false
    }
    setOptions(options)
  }

  if (options && series) {
    return (
      <TableContainer component={Paper} sx={{paddingTop:1, overflowY:"hidden"}}>
        <Chart
          options={options}
          series={series}
          type={series.length === 1 ? "area" : "line"}
          height={height ?? "500"}
          width={width ?? "100%"}
        />
      </TableContainer>
    )
  } else {
    return null
  }
}

export default FutureViewChart