import { AccountCircle, ArrowDropDown } from "@mui/icons-material"
import {Avatar, Box, Button, Link, Stack, Tooltip, Typography, useTheme} from "@mui/material"
import React from "react"
import { useEffect, useState } from "react"
import { Link as RouterLink, useLocation } from "react-router-dom"
import { INavItem } from "../../NavigationConfig"
import { IRouteData, RoutesConfigHelpers } from "../../RoutesConfig"
import Logger from "../Logger"
import {useResources} from "../../stores/ResourceProvider";
import globalStyles from "../../styles/globalStyles";
import useNavigationHelper from "../NavigationHelper";

interface INavButtonProps {
  navItem: INavItem
  onClickButton: (e: React.MouseEvent) => void 
  ButtonProps?: object  
}

const NavButton = (props: INavButtonProps) => {
  const { navItem } = props 
  const route: IRouteData = navItem.route 
  const [isActive, setIsActive] = useState(false) 
  const theme = useTheme()
  const {navigateHome} = useNavigationHelper()

  const styles = {
    link: {
      fontSize: 16,
      fontWeight: 500,
      color: "#333"
    },
    activeLink: {
      fontSize: 16,
      fontWeight: 900,
      color: theme.palette.primary.main
    }
  }


  if (!route) {
    Logger.error('Route does not exist!', navItem)
  }

  const {userStore} = useResources()

  const location = useLocation();
  // Update the active state if the route changes. 
  useEffect(() => {
    const isActive = RoutesConfigHelpers.isCurrentRoute(location.pathname, route)
    setIsActive(isActive)
  }, [location.pathname, route]);

  const renderIcon = (icon: string) => {
    if (icon === 'AccountCircle') {
      if (userStore.user) {
        const user = userStore.user
        const name = `${user.firstName} ${user.lastName}`
        return (
          <Tooltip title={name}>
            <Avatar sx={{bgcolor: theme.palette.primary.main, fontSize:16, width:30, height:30}}
                    alt={name}>
                    {user.initials}
            </Avatar>
          </Tooltip>
          )
      } else {
        return <AccountCircle />
      }
    }
  }

  const renderButton = () => {
    return (
      <Button
        {...props.ButtonProps}
        variant={ navItem.isEmphasized ? 'contained' : 'text' }
        color={ navItem.isEmphasized ? 'primary' : 'inherit' }
        onClick={props.onClickButton}
        // sx={navItem.isEmphasized ? globalStyles.webFlowButton :
        //   {
        //   color: theme.palette.common.white,
        //   fontWeight: 'bold'
        // }}
      >
        <Typography>
          {navItem.route.title}
        </Typography>
      </Button>
    )
  }

  const renderLink = () => {
    let color
    // TODO: Improve this hack
    if (navItem.route.title === "Community" && userStore.isFree) {
      color = "darkorange"
    }
    return (
      <Box
        sx={{
          mt: theme.spacing(0.5),
          height: 26,
          marginBottom: "6px",
          fontWeight: isActive ? 600 : 400,
          borderBottom: `${ isActive ? "3px solid" : "2px solid" }`,
          borderBottomColor: `${ isActive ? 'secondary.main' : 'background.default' }`,
          '&:hover': {
            borderBottom: "3px solid",
            borderBottomColor: 'primary.main'
          },
        }}
      >
        <Link 
          component={RouterLink} 
          to={navItem.route.pathname ?? navItem.route.href!}
          onClick={(event: any) => {
            if (navItem.route.pathname === "/") {
              navigateHome()
            }
          }}
          underline='none'
        >
          <Typography color={color} sx={isActive ? styles.activeLink : styles.link}>
            {navItem.route.title}
          </Typography>
        </Link>
      </Box>
    )
  }

  const renderSelect = () => {
    return (
      <Button
        {...props.ButtonProps}
        variant={ 'text' }
        color={ 'inherit' }
        onClick={props.onClickButton}
        sx={{
          color: theme.palette.primary.main,
          fontWeight: 400,
        }}
      >
        <Stack direction="row" alignItems="center">
          { navItem.icon ? renderIcon(navItem.icon) : 
            <Typography>
              {navItem.route.title}
            </Typography>
          }
          { navItem.subNavItems ? <ArrowDropDown color='primary' /> : null }
        </Stack>  
      </Button>
    )
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          mx: 2, 
          mt: 0,
          mb: 0,
          px: 1 
        }}
      >
        { navItem.isEmphasized ? renderButton() : navItem.subNavItems ? renderSelect() : renderLink() }
      </Box>
    </React.Fragment>
  )
}

export default NavButton
