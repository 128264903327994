import {FutureCalc, IBudgetCalc, IFutureYear} from "../../../../components/calculator/Calculator";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {numberToMoneyFormat} from "../../../../stores/StoreUtilities";
import globalStyles from "../../../../styles/globalStyles";
import {Gender} from "../../../../API";


const BudgetProjectionTable = ({
  futureCalc,
  year,
  onChange
}: {
  futureCalc: FutureCalc
  year?: number
  onChange?: (year: number) => any
}) => {
  const [currentYear, setCurrentYear] = useState<number>(0)

  useEffect(() => {
    if (year) {
      setCurrentYear(year)
    }
  }, [year])

  const handleChange = (year: number) => {
    if (onChange) {
      setCurrentYear(year)
      onChange(year)
    }
  }

  const styles = {
    total: {
      fontWeight: 700
    }
  }

  const totals = {
    income: 0,
    deductions: 0,
    taxes: 0,
    netIncome: 0,
    essentialExpenses: 0,
    discretionaryExpenses: 0,
    allExpenses: 0,
    withdrawals: 0,
    surplus: 0
  }

  return (
    <Box display="flex" flexGrow={1} flexDirection="column" mt={2}>
      <Box display="flex" flexGrow={1} justifyContent="space-between" minHeight={40}>
        <Typography variant="h3" color="primary" gutterBottom>Budget Projection Details</Typography>
        <Box display="flex" alignItems="center" pt={0}>
          <Box width={14} height={14} sx={globalStyles.trRetired}></Box>
          <Typography variant="body2" style={globalStyles.legendLabel}>Planned Retirement</Typography>
          {futureCalc.person1 &&
            <React.Fragment>
              <Box width={14} height={14} sx={futureCalc.person1.gender === Gender.Male ? globalStyles.trHisLifeExpectancy : globalStyles.trHerLifeExpectancy}></Box>
              <Typography variant="body2" style={globalStyles.legendLabel}>{futureCalc.person1.nickname}'s Life Expectancy</Typography>
            </React.Fragment>
          }
          {futureCalc.person2 &&
            <React.Fragment>
              <Box width={14} height={14} sx={futureCalc.person2.gender === Gender.Male ? globalStyles.trHisLifeExpectancy : globalStyles.trHerLifeExpectancy}></Box>
              <Typography variant="body2" style={globalStyles.legendLabel}>{futureCalc.person2.nickname}'s Life Expectancy</Typography>
            </React.Fragment>
          }
          <Box width={14} height={14} sx={globalStyles.trAssetsDepleted}></Box>
          <Typography variant="body2" style={globalStyles.legendLabel}>Liquid Assets Final Year</Typography>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" width="100%">
          <TableHead>
            <TableRow>
              {/*<TableCell width={30}></TableCell>*/}
              <TableCell>Year</TableCell>
              <TableCell align="right">Gross Income</TableCell>
              <TableCell align="right">Deductions</TableCell>
              <TableCell align="right">Est Taxes</TableCell>
              <TableCell align="right">Net Income</TableCell>
              <TableCell align="right">Essential Expenses</TableCell>
              <TableCell align="right">Discretionary Expenses</TableCell>
              <TableCell align="right">Total Expenses</TableCell>
              <TableCell align="right">Withdrawals</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {futureCalc.futureYears.map((fy: IFutureYear, index: number) => {
              let income
              let withdrawals
              if (fy.surplus > 0) {
                income = fy.grossIncome
                withdrawals = fy.rmds
              } else {
                income = fy.grossIncome
                withdrawals = -fy.surplus + fy.rmds
              }
              const surplus = Math.max(income + withdrawals - fy.deductions - fy.taxes - fy.allExpenses, 0)
              totals.income += income
              totals.deductions += fy.deductions
              totals.taxes += fy.taxes
              totals.netIncome += fy.netIncome
              totals.essentialExpenses += fy.essentialExpenses
              totals.discretionaryExpenses += fy.discretionaryExpenses
              totals.allExpenses += fy.allExpenses
              totals.withdrawals += withdrawals

                let rowStyle
                if (fy.year === futureCalc.latestRetirementYear) {
                  rowStyle = globalStyles.trRetired
                } else if (futureCalc.person1 && fy.year === futureCalc.person1!.lifeExpectancyYear) {
                  rowStyle = (futureCalc.person1.gender === Gender.Male) ? globalStyles.trHisLifeExpectancy : globalStyles.trHerLifeExpectancy
                } else if (futureCalc.person2 && fy.year === futureCalc.person2!.lifeExpectancyYear) {
                  rowStyle = (futureCalc.person2.gender === Gender.Male) ? globalStyles.trHisLifeExpectancy : globalStyles.trHerLifeExpectancy
                } else if (fy.currentLiquidAssets > 0 && fy.netLiquidAssets <= 0) {
                  rowStyle = globalStyles.trAssetsDepleted
                }

                return (
                <TableRow selected={currentYear === fy.year} key={`row${index}`}style={rowStyle}
                          onClick={() => {handleChange(fy.year)}}>
                  <TableCell>{fy.year}</TableCell>
                  <TableCell align="right">{numberToMoneyFormat(income, 0)}</TableCell>
                  <TableCell align="right">{numberToMoneyFormat(fy.deductions, 0)}</TableCell>
                  <TableCell align="right">{numberToMoneyFormat(fy.taxes, 0)}</TableCell>
                  <TableCell align="right">{numberToMoneyFormat(fy.netIncome, 0)}</TableCell>
                  <TableCell align="right">{numberToMoneyFormat(fy.essentialExpenses, 0)}</TableCell>
                  <TableCell align="right">{numberToMoneyFormat(fy.discretionaryExpenses, 0)}</TableCell>
                  <TableCell align="right">{numberToMoneyFormat(fy.allExpenses, 0)}</TableCell>
                  <TableCell align="right">{numberToMoneyFormat(withdrawals, 0)}</TableCell>
                </TableRow>
              )
            }
            )}
            <TableRow key={`totals`}>
              <TableCell sx={styles.total}>Totals</TableCell>
              <TableCell sx={styles.total} align="right">{numberToMoneyFormat(totals.income, 0)}</TableCell>
              <TableCell sx={styles.total} align="right">{numberToMoneyFormat(totals.deductions, 0)}</TableCell>
              <TableCell sx={styles.total} align="right">{numberToMoneyFormat(totals.taxes, 0)}</TableCell>
              <TableCell sx={styles.total} align="right">{numberToMoneyFormat(totals.netIncome, 0)}</TableCell>
              <TableCell sx={styles.total} align="right">{numberToMoneyFormat(totals.essentialExpenses, 0)}</TableCell>
              <TableCell sx={styles.total} align="right">{numberToMoneyFormat(totals.discretionaryExpenses, 0)}</TableCell>
              <TableCell sx={styles.total} align="right">{numberToMoneyFormat(totals.allExpenses, 0)}</TableCell>
              <TableCell sx={styles.total} align="right">{numberToMoneyFormat(totals.withdrawals, 0)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default BudgetProjectionTable