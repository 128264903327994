import * as React from 'react';
import DialogButton from './DialogButton';
import { Box } from '@mui/material';

interface ICancelButtonProps {
    onClick(): void
}
  
class CancelButton extends React.Component<ICancelButtonProps> {
  render() {
    return (
      <Box mr={2}>
        <DialogButton variant="secondary" size="medium" onClick={this.props.onClick}>
          Cancel
        </DialogButton>
      </Box>
    )    
  }
}

export default CancelButton
