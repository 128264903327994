import * as React from "react";
import {useEffect, useState} from "react";
import TextFieldValidator from "../form/TextFieldValidator";
import Schedule, {Frequency} from "../../model/Schedule";
import {Box, useTheme} from "@mui/material";
import FormGroupSpacer from "../form/FormGroupSpacer";
import {DateAdapter} from "@rschedule/core";
import Weekday = DateAdapter.Weekday;

interface DayOfWeek {
  weekday: Weekday
  label: string
}

interface DayOfMonth {
  day: number
  label: string
}

interface MonthOfYear {
  month: number
  label: string
}

const ScheduleSelector = ({
  value,
  startDate,
  onChange,
  formvalidator
  }: {
  value: Schedule
  startDate?: Date
  onChange?(value: Schedule): any
  formvalidator?: any
}) => {
  const defaultOptions: Schedule[] = [
    new Schedule({name: "Once", frequency: Frequency.Once, interval: 1}),
    new Schedule({name: "Weekly", frequency: Frequency.Weekly, interval: 1}),
    new Schedule({name: "Bi-weekly", frequency: Frequency.Weekly, interval: 2}),
    // TODO: new Schedule({name: "Semi-Monthly", frequency: Frequency.Monthly, interval: 1}),
    new Schedule({name: "Monthly", frequency: Frequency.Monthly, interval: 1}),
    new Schedule({name: "Semi-Monthly", frequency: Frequency.Monthly, interval: 1, on: [1,15]}),
    new Schedule({name: "Quarterly", frequency: Frequency.Monthly, interval: 3}),
    // new Schedule({name: "Semi-Annual", frequency: Frequency.Monthly, interval: 6}),
    new Schedule({name: "Yearly", frequency: Frequency.Yearly, interval: 1}),
    new Schedule({name: "Custom"})
  ]

  const DayOfWeekOptions: DayOfWeek[] = [
    {weekday: "SU", label: "Sun" },
    {weekday: "MO", label: "Mon" },
    {weekday: "TU", label: "Tue" },
    {weekday: "WE", label: "Wed" },
    {weekday: "TH", label: "Thu" },
    {weekday: "FR", label: "Fri" },
    {weekday: "SA", label: "Sat" },
  ]
  
  const DayOfMonthOptions: DayOfMonth[] = [
    {day: 1, label: "1st day"},
    {day: 2, label: "2nd day"},
    {day: 3, label: "3rd day"},
    {day: 4, label: "4th day"},
    {day: 5, label: "5th day"},
    {day: 6, label: "6th day"},
    {day: 7, label: "7th day"},
    {day: 8, label: "8th day"},
    {day: 9, label: "9th day"},
    {day: 10, label: "10th day"},
    {day: 11, label: "11th day"},
    {day: 12, label: "12th day"},
    {day: 13, label: "13th day"},
    {day: 14, label: "14th day"},
    {day: 15, label: "15th day"},
    {day: 16, label: "16th day"},
    {day: 17, label: "17th day"},
    {day: 18, label: "18th day"},
    {day: 19, label: "19th day"},
    {day: 20, label: "20th day"},
    {day: 21, label: "21st day"},
    {day: 22, label: "22nd day"},
    {day: 23, label: "23rd day"},
    {day: 24, label: "24th day"},
    {day: 25, label: "25th day"},
    {day: 26, label: "26th day"},
    {day: 27, label: "27th day"},
    {day: 28, label: "28th day"},
    {day: 29, label: "29th day"},
    {day: 30, label: "30th day"},
    {day: 31, label: "31st day"},
    {day: -1, label: "Last day"},
  ]

  const MonthOfYearOptions: MonthOfYear[] = [
    {month: 1, label: "Jan"},
    {month: 2, label: "Feb"},
    {month: 3, label: "Mar"},
    {month: 4, label: "Apr"},
    {month: 5, label: "May"},
    {month: 6, label: "Jun"},
    {month: 7, label: "Jul"},
    {month: 8, label: "Aug"},
    {month: 9, label: "Sep"},
    {month: 10, label: "Oct"},
    {month: 11, label: "Nov"},
    {month: 12, label: "Dec"}
  ]
  
  const [schedule, setSchedule] = useState<Schedule>(value)
  const [frequency, setFrequency] = useState<Frequency>(Frequency.Once)
  const [interval, setInterval] = useState<string>("")
  const [dayOfWeekOption, setDayOfWeekOption] = useState<DayOfWeek>(DayOfWeekOptions[0])
  const [dayOfMonthOption, setDayOfMonthOption] = useState<DayOfMonth>(DayOfMonthOptions[0])
  const [monthOfYearOptions, setMonthOfYearOptions] = useState<MonthOfYear[]>([])
  const [options, setOptions] = useState<Schedule[]>(defaultOptions)

  useEffect( () => {
    setFrequency(value.frequency)
    setInterval(value.interval.toString())
    if (value.frequency === Frequency.Weekly) {
      let index = 0
      if (value.on && value.on.length > 0) {
        const weekday = value.on[0]
        index = DayOfWeekOptions.findIndex((option: DayOfWeek) => option.weekday === weekday)
      } else if (startDate) {
        index = startDate.getDay()
      }

      if (index >= 0) {
        setDayOfWeekOption(DayOfWeekOptions[index])
      } else {
        setDayOfWeekOption(DayOfWeekOptions[0])
      }
    } else if (value.frequency === Frequency.Monthly) {
      let index = 0
      if (value.on && value.on.length > 0) {
        const day = value.on[0]
        index = DayOfMonthOptions.findIndex((option: DayOfMonth) => option.day === day)
      } else if (startDate) {
        index = startDate.getDate() - 1
      }

      if (index >= 0) {
        setDayOfMonthOption(DayOfMonthOptions[index])
      } else if (startDate) {
        setDayOfMonthOption(DayOfMonthOptions[startDate.getDate()])
      }
    } else if (value.frequency === Frequency.Yearly) {
      const monthOptions: MonthOfYear[] = []
      if (value.each && value.each.length > 0) {
        value.each.forEach((month: number) => {
          if (month >= 1 && month <= 12) {
            monthOptions.push(MonthOfYearOptions[month-1])
          }
        })
      } else if (startDate) {
        monthOptions.push(MonthOfYearOptions[startDate.getMonth()])
      }
      monthOptions.sort((a: MonthOfYear, b: MonthOfYear) => a.month - b.month)
      setMonthOfYearOptions(monthOptions)

      let index = 0
      if (value.on && value.on.length > 0) {
        const day = value.on[0]
        index = DayOfMonthOptions.findIndex((option: DayOfMonth) => option.day === day)
      } else if (startDate) {
        index = startDate.getDate() - 1
      }

      if (index >= 0) {
        setDayOfMonthOption(DayOfMonthOptions[index])
      } else if (startDate) {
        setDayOfMonthOption(DayOfMonthOptions[startDate.getDate()])
      }
    }
    // Update custom option to match value schedule
    const customOption = defaultOptions[defaultOptions.length-1]
    customOption.frequency = value.frequency
    customOption.interval = value.interval
    setOptions(defaultOptions)
  }, [value, startDate])

  const theme = useTheme()

  const styles = {
    customDetails: {
      backgroundColor: theme.palette.grey["300"]
    },
    chip: {
      color: theme.palette.grey["500"]
    }
  }

  return(
    <React.Fragment>
      <TextFieldValidator
        type="text"
        validators={{ required: true }}
        required
        name="repeats"
        label="Repeats"
        variant="standard"
        autocompleteOptions={{
          freeSolo: false,
          options: options,
          value: schedule,
          disableClearable: true,
          getOptionLabel: (option: Schedule) => {
            return option?.name ?? ""
           },
          isOptionEqualToValue: (option: Schedule, value: Schedule) =>
          {
            return option?.name === value?.name
          },
          onChange: (event: any, value: Schedule, reason: any) => {
            setSchedule(value)
            if (onChange) {
              onChange(value)
            }
          }
        }}
      />
      {value && value.name === "Custom" &&
        <Box display="flex" flexDirection="row" justifyContent="stretch" sx={styles.customDetails}>
          <TextFieldValidator
            type="number"
            validators={{ minValue: 1, isInt: true }}
            name="interval"
            label="Every"
            variant="standard"
            margin="dense"
            styleProp={{width:120}}
            value={interval}
            onChange={(event: any) => {
              setInterval(event.target.value)
              schedule.interval = parseInt(event.target.value)
              if (onChange) {
                onChange(schedule)
              }
            }}
          />
          <FormGroupSpacer/>
          <TextFieldValidator
            type="text"
            validators={{ required: true }}
            required
            styleProp={{width:120}}
            name="frequency"
            label="Frequency"
            variant="standard"
            margin="dense"
            autocompleteOptions={{
              freeSolo: false,
              options: Object.values(Frequency),
              value: frequency,
              disableClearable: true,
              getOptionLabel: (option: Frequency) => {
                switch (option) {
                  case Frequency.Once:
                    return "Once"
                  case Frequency.Weekly:
                    return "Week(s)"
                  case Frequency.Monthly:
                    return "Month(s)"
                  case Frequency.Yearly:
                    return "Year(s)"
                }
              },
              onChange: (event: any, value: Frequency, reason: any) => {
                setFrequency(value)
                schedule.frequency = value
                if (onChange) {
                  onChange(schedule)
                }
              }
            }}
          />
          <FormGroupSpacer/>
          {frequency === Frequency.Weekly &&
            <TextFieldValidator
              type="text"
              validators={{ required: true }}
              required
              styleProp={{width:120}}
              name="dayOfWee"
              label="On"
              variant="standard"
              margin="dense"
              autocompleteOptions={{
                freeSolo: false,
                options: DayOfWeekOptions,
                value: dayOfWeekOption,
                disableClearable: true,
                getOptionLabel: (option: DayOfWeek) => option.label ?? "",
                onChange: (event: any, value: DayOfWeek, reason: any) => {
                  setDayOfWeekOption(value)
                  schedule.on = [value.weekday]
                  if (onChange) {
                    onChange(schedule)
                  }
                }
              }}
            />
          }
          {(frequency === Frequency.Monthly || frequency === Frequency.Yearly) &&
            <TextFieldValidator
              type="text"
              validators={{ required: true }}
              required
              styleProp={{width:120}}
              name="dayOfMonth"
              label="On the"
              variant="standard"
              margin="dense"
              autocompleteOptions={{
                freeSolo: false,
                options: DayOfMonthOptions,
                value: dayOfMonthOption,
                disableClearable: true,
                getOptionLabel: (option: DayOfMonth) => option.label ?? "",
                onChange: (event: any, value: DayOfMonth, reason: any) => {
                  setDayOfMonthOption(value)
                  schedule.on = [value.day]
                  if (onChange) {
                    onChange(schedule)
                  }
                }
              }}
            />
          }
        </Box>
      }
      {(value.name === "Custom" && frequency === Frequency.Yearly) &&
        <Box display="flex" flexGrow={1} width="100%" sx={styles.customDetails}>
          <TextFieldValidator
            type="text"
            validators={{ required: false }}
            required
            name="monthsOfYear"
            label="Of each"
            variant="standard"
            margin="dense"
            fullWidth
            styleProp={{width:"100%"}}
            autocompleteOptions={{
              freeSolo: false,
              multiple: true,
              options: MonthOfYearOptions,
              value: monthOfYearOptions,
              disableClearable: false,
              // filterSelectedOptions: true,
              ChipProps: {color: "info", size: "small"},
              sx: {width: "100%"},
              // sx:{
              //   "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
              //     backgroundColor: "#363636",
              //   },
              //     "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
              //   {
              //     backgroundColor: "#4396e6",
              //   },
              //     "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'].Mui-focused":
              //   {
              //     backgroundColor: "#3878b4",
              //   }
              // },
              // getOptionLabel: (option: MonthOfYear) => option && option.label ? option.label : "",
              isOptionEqualToValue: (option: MonthOfYear, value: MonthOfYear) => option.month === value.month,
              onChange: (event: any, value: MonthOfYear[], reason: any) => {
                setMonthOfYearOptions(value)
                schedule.each = value.map((option: MonthOfYear) => option.month)
                if (onChange) {
                  onChange(schedule)
                }
              }
            }}
          />
        </Box>
      }
    </React.Fragment>
  )
}

export default ScheduleSelector

