import React, {useEffect, useState} from 'react'
import {numberToMoneyFormat, numberToPercentFormat} from "../../../../stores/StoreUtilities";
import {globalColors} from "../../../../styles/globalStyles"
import {Box, Paper, TableContainer, Typography, useTheme} from "@mui/material";


const ScenarioComparisonChart = ({
  title,
  categories,
  series,
  minRange,
  format
}: {
  title: string
  categories: string[]
  series: any[],
  minRange: number,
  format: "money" | "integer"
}) => {

  const styles = {
    label: {
      fontSize: 15
    },
    value: {
      fontSize: 14,
      fontWeight: 600
    }
  }

  const barHeight = 24

  let maxRange = 0
  const data: number[] = series[0]['data']
  data.forEach((value: number) => {
    if (value > maxRange) {
      maxRange = value
    }
  })

  const renderBar = (label: string, value: number, minRange: number, maxRange: number, color: string, totalLabel?: string) => {
    const barPercent = (value - minRange) / (maxRange - minRange)
    const barWidth = numberToPercentFormat(barPercent, 2)
    let formatted
    if (totalLabel) {
      formatted = totalLabel
    } else {
      formatted = (format === "money") ? `${numberToMoneyFormat(value / 1000, 0)}K` : String(value)
    }
    return (
      <Box display="flex" flexDirection="column">
        <Typography variant="body2" sx={styles.label}>{label}</Typography>
        <Box display="flex" mt="0px" mb={1} pr="40px" width="100%" justifyContent="stretch" alignItems="center">
          <Box display="flex" flexGrow={0} height={barHeight} width={barWidth} bgcolor={color} border={`1px solid ${color}`}/>
          <Box position="relative" left="8px">
            <Typography variant="body2" sx={styles.value}>{formatted}</Typography>
          </Box>
          {/*<Box display="flex" flexGrow={1} height={barHeight} minWidth={80} pl={1} pt="2px">*/}
          {/*  <Typography variant="body2" sx={styles.value}>{formatted}</Typography>*/}
          {/*</Box>*/}
        </Box>
      </Box>
    )
  }

  if (categories && series) {
    const data: number[] = series[0]["data"]
    const totalLabels: string[] = series[0]["totalLabels"]

    return (
      <TableContainer component={Paper} sx={{paddingTop:1, paddingLeft:2, paddingBottom:1}}>
        <Box display="flex" mb={1}>
          <Typography variant="h5" color="primary">{title}</Typography>
        </Box>
        {data.map((value: number, index: number) =>
          renderBar(categories[index], value, minRange, maxRange, globalColors.categorical[index], totalLabels ? totalLabels[index] : undefined)
        )}
      </TableContainer>
    )
  } else {
    return null
  }
}

export default ScenarioComparisonChart