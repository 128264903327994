import {CognitoUser, CognitoUserAttribute} from "amazon-cognito-identity-js";
import {Auth} from "aws-amplify";
import {computed, makeObservable, observable} from "mobx";
import * as APITypes from "../API";
import {UpdateUserInput, UserRole, UserType} from "../API";
import User from "../model/User";
import RbcAPI from "../apis/RbcAPI";
import Logger from "../components/Logger";
import {fromUnixTime, isToday} from "date-fns";
import Tracking from "../components/Tracking";
import {getISODateFromDate, getISODateTime, phoneToE164Format} from "./StoreUtilities";
import RoutesConfig from "../RoutesConfig";
import {navigate} from "@reach/router";
import BillingAPI from "../apis/BillingAPI";
import Snapshot from "../model/Snapshot";
import Model from "../model/Model";
import IModelItem from "../model/IModelItem";
import IUserItem from "../model/IUserItem";
import UserSetting from "../model/UserSetting";

export const UserStoreConstants = {

  USER_ALREADY_CONFIRMED: "User cannot be confirm. Current status is CONFIRMED",
  CONFIRMATION_SUCCESS: "SUCCESS",
  USER_NOT_FOUND: "User not found",
  USER_ALREADY_EXISTS: "User already exists",
  USERNAME_EXISTS_EXCEPTION: "UsernameExistsException",
  EMAIL_EXISTS_MESSAGE: "PreSignUp failed with error Email exists.",
  USERNAME_NOT_CONFIRMED_EXCEPTION: "UserNotConfirmedException",
  USER_NOT_CONFIRMED: "User not confirmed",
  NOT_AUTHORIZED_EXCEPTION: "NotAuthorizedException",
  USER_NOT_FOUND_EXCEPTION: "UserNotFoundException",
  CODE_MISMATCH_EXEPTION: "CodeMismatchException",
  CONDITIONAL_REQUEST_FAILED: "The conditional request failed",
  EMAIL_VERIFICATION_PENDING: "Email verification pending",
  PHONE_VERIFICATION_PENDING: "Phone verification pending",
  COMPANY_EMAIL: ""
}

export interface ICognitoAttributes {
  email?: string,
  phone_number?: string,
  "custom:account"?: string,
  "custom:role"?: string
}

export const CognitoAttribute = {
  EMAIL: "email",
  PHONE_NUMBER: "phone_number",
  ACCOUNT: "custom:account",
  ROLE: "custom:role"
}

export const CognitoAttributeValue = {
  FALSE: "false",
  TRUE: "true"
}

class UserStore {
  @observable user?: User
  @observable isLoading: boolean = false
  @observable isAdmin: boolean = false

  rbcAPI: RbcAPI
  billingAPI: BillingAPI

  @computed get isAdvisor() {
    return this.user !== undefined ? this.user.isAdvisor : false
  }

  @computed get isAdvisorOrAdmin() {
    return this.user !== undefined ? this.user.isAdvisor || this.isAdmin : false
  }

  @computed get isCustomer() {
    return this.user !== undefined ? this.user.isCustomer : false
  }

  @computed get isFree() : boolean {
    return (this.user !== undefined && this.user.userType === UserType.Free)
  }

  @observable private _cognitoUser?: CognitoUser
  get cognitoUser() {
    // Logger.debug(`UserStore get cognitoUser = ${this._cognitoUser?.getUsername()}`)
    return this._cognitoUser
  }

  set cognitoUser(cognitoUser: CognitoUser | undefined) {
    // Logger.debug(`UserStore set cognitoUser = ${cognitoUser?.getUsername()}`)
    this._cognitoUser = cognitoUser
  }

  private _attributes: CognitoUserAttribute[] = []

  constructor(options: any) {
    makeObservable(this);
    this.rbcAPI = (options && options.rbcAPI) ? options.rbcAPI : null
    this.billingAPI = (options && options.billingAPI) ? options.billingAPI : null
  }

  async signOutCurrentAuthenticatedUser() {
    try {
      // const user: CognitoUser | undefined = await Auth.currentAuthenticatedUser()
      await Auth.currentAuthenticatedUser()
      // Logger.debug('Current authenticated user', user)
      await Auth.signOut()
    } catch (err) {
      // If we see err === "No current user," then we are not logged in, which is fine.
      // Logger.debug('UserStore.signIn: ', err)
    } finally {
      // this.deleteAllCookies()
    }
  }

  signUp = async (username: string, password: string, email: string, phone?: string, accountId?: string, role?: UserRole) => {
    this.cognitoUser = undefined
    this.user = undefined
    this._attributes = []

    await this.signOutCurrentAuthenticatedUser()

    return new Promise<CognitoUser | any>((resolve, reject) => {
      const attributes: any = {}
      if (email) {
        attributes[CognitoAttribute.EMAIL] = email.toLowerCase()
      }
      if (phone) {
        attributes[CognitoAttribute.PHONE_NUMBER] = phoneToE164Format(phone)
      }
      if (accountId) {
        attributes[CognitoAttribute.ACCOUNT] = accountId
      }
      if (role) {
        attributes[CognitoAttribute.ROLE] = role
      }

      Auth.signUp({
        username,
        password,
        attributes,
        validationData: []  // optional
      })
        .then(data => {
          resolve(data.user)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  confirmSignUp = async (username: string, code: string, options?: any): Promise<string | any> => {
    return await Auth.confirmSignUp(username, code, options)
  }

  resendSignUp = async (username: string): Promise<void> => {
    return await Auth.resendSignUp(username)
  }

  verifyUserAttribute = async (user: CognitoUser, attribute: string) => {
    return await Auth.verifyUserAttribute(user, attribute)
  }

  verifyUserAttributeSubmit = async (user: CognitoUser, attribute: string, code: string) => {
    return await Auth.verifyUserAttributeSubmit(user, attribute, code)
  }

  signIn = async (username: string, password: string) => {
    this.cognitoUser = undefined
    this.user = undefined
    this._attributes = []

    await this.signOutCurrentAuthenticatedUser()

    return new Promise<User | any>((resolve, reject) => {
      Auth.signIn(username.toLowerCase(), password)
        .then(async (cognitoUser: any) => {
          if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED" || cognitoUser.challengeName === "SMS_MFA") {
            // Logger.debug(`Auth.signIn(${username}) = ${cognitoUser.challengeName}`)
            resolve(cognitoUser)
          } else {
            // Logger.debug(`Auth.signIn(${username}) success`)
            // Load and initialize User
            this.initSession(cognitoUser)
              .then(user => {
                // this.createActivity(ActivityType.UserSignIn, result.id)
                resolve(user)
              })
              .catch((reason: any) => {
                // await this.signInAsGuest()
                reject(reason)
              })
          }
        }).catch(err => {
        if (err.code !== UserStoreConstants.USER_NOT_FOUND_EXCEPTION &&
          err.code !== UserStoreConstants.NOT_AUTHORIZED_EXCEPTION) {
          Logger.error("Auth.SignIn error.", err)
        }
        reject(err)
      })
    })
  }

  confirmSignIn = async (authUser: CognitoUser, code: string, mfaType: "SMS_MFA" | "SOFTWARE_TOKEN_MFA")=> {
    const cognitoUser = await Auth.confirmSignIn(authUser, code, mfaType)
    if (cognitoUser) {
      const user = await this.initSession(cognitoUser)
      return user
    }
  }

  rememberDevice = async () => {
    const result = await Auth.rememberDevice()
    return result
  }

  reloadAuthenticatedUser = async (): Promise<User | null | undefined> => {
    const cognitoUser = await this.currentAuthenticatedUser()
    if (cognitoUser) {
      console.log("reloading current authenticated user")
      this.isLoading = true
      // Load and initialize User
      return this.initSession(cognitoUser)
        .then(async (result: any) => {
          console.log("Reloaded user from cache")
          return this.user
        })
        .catch(async (reason: any) => {
          this.isLoading = false
          console.log("Unable to load user from cache")
          return null
        })
    } else {
      console.log("No existing authenticated user found")
      this.isLoading = false
      return null
    }

  }

  signOut = async () => {
    return new Promise<any>((resolve, reject) => {
      this.currentAuthenticatedUser()
        .then((cognitoUser: CognitoUser) => {
          Auth.signOut()
            .then(() => {
              // Logger.debug("Auth.signOut success")
              this.cognitoUser = undefined
              this.user = undefined
              this.isAdmin = false
              this._attributes = []
              if (this.checkInterval) {
                clearInterval(this.checkInterval)
                this.checkInterval = undefined
              }
              resolve(null)
            })
            .catch(err => {
              Logger.error("Auth.signOut error", err)
              reject(err)
            })
          // .finally(() => {
          //   this.deleteAllCookies()
          // })
        })
    })
  }

  currentSession = async () => {
    Auth.currentSession()
      .then(data => {
        return data
      })
      .catch(err => {
        console.log(`Auth.currentSession err: ${JSON.stringify(err)}`)
      });
  }

  currentAuthenticatedUser = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser()
      .catch(err => {
        this.cognitoUser = undefined
      })
    if (cognitoUser) {
      // TODO: Disable logging after testing
      this.logCurrentSession()
      this.cognitoUser = cognitoUser
      return cognitoUser
    } else {
      this.cognitoUser = undefined
      return null
    }
  }

  logCurrentSession = async () => {
    const session = await Auth.currentSession()
    if (session) {
      const idToken = session.getIdToken()
      let issuedDate = new Date(idToken.getIssuedAt() * 1000)
      console.debug(`idToken issuedAt: ${issuedDate.toLocaleString()}`)
      let expirationDate = new Date(idToken.getExpiration() * 1000)
      console.debug(`idToken expiration: ${expirationDate.toLocaleString()}`)

      // const refreshToken = session.getRefreshToken()
      // console.debug(`refreshToken: ${refreshToken.getToken()} `)

      const accessToken = session.getAccessToken()
      issuedDate = new Date(accessToken.getIssuedAt() * 1000)
      console.debug(`accessToken issuedAt: ${issuedDate.toLocaleString()}`)
      expirationDate = new Date(accessToken.getExpiration() * 1000)
      console.debug(`accessToken expiration: ${expirationDate.toLocaleString()}`)
    }
  }

  getUserAttribute = async (cognitoUser: any, name: string) => {
    const attributes = await Auth.userAttributes(cognitoUser ? cognitoUser : this.cognitoUser)
    const attribute = attributes.find(a => a.getName() === name)
    if (attribute) {
      return attribute.getValue()
    } else {
      return null
    }
  }

  getUserAttributes = async (cognitoUser?: any) => {
    return await Auth.userAttributes(cognitoUser ? cognitoUser : this.cognitoUser)
  }

  updateUserAttributes = async (cognitoUser: any, attributes: any) => {
    return await Auth.updateUserAttributes(cognitoUser ? cognitoUser : this.cognitoUser, attributes)
  }


  initSession = async (cognitoUser: CognitoUser) => {

    console.log("UserStore.initSession")
    this.isLoading = true

    return new Promise<CognitoUser | any>(async (resolve, reject) => {
      this.cognitoUser = cognitoUser
      await this.getCurrentSessionPayload()
      const username = cognitoUser.getUsername()
      await this.checkAuthentication()

      this.loadUser(username)
        .then(async (user: any) => {
          await this.verifyRole(user)
          await this.verifyAccount(user)
          await this.verifySubscription(user)
          Tracking.set({ userId: user.email })
          this.isLoading = false
          resolve(user)
        })
        .catch((err: any) => {
          this.isLoading = false
          reject(err)
        })
    })
  }

  updateLastAccess = async (user: User)=> {
    const input: UpdateUserInput = {
      id: user.id,
      lastAccess: getISODateTime()
    }
    await this.updateUser(input)
  }

  async verifyRole(user: User) {
    // Verify the cognito role matches the User record role and update if needed
    const attributes: CognitoUserAttribute[] = await this.getUserAttributes(this.cognitoUser)
    const updateAttributes: ICognitoAttributes = {}

    const role = user.role
    const customRole = attributes.find(attribute => attribute.getName() === CognitoAttribute.ROLE)
    if (!customRole || customRole.getValue() !== role) {
      updateAttributes[CognitoAttribute.ROLE] = role
      console.log(`Updating ${user.email} role to ${role}`)
      await this.updateUserAttributes(null, updateAttributes)
    }
  }

  async verifyAccount(user: User) {
    // Verify the cognito role matches the User record role and update if needed
    const attributes: any = await this.getUserAttributes(this.cognitoUser)
    const updateAttributes: ICognitoAttributes = {}

    const accountId = user.accountId

    const customAccount = attributes.find((attribute: any) => attribute.getName() === CognitoAttribute.ACCOUNT)
    if (!customAccount || customAccount.getValue() !== accountId) {
      updateAttributes[CognitoAttribute.ACCOUNT] = accountId
      console.log(`Updating ${user.email} account to ${accountId}`)
      await this.updateUserAttributes(null, updateAttributes)
    }
  }

  async verifySubscription(user: User) {
    if ((user.userType === UserType.Premium || user.userType === UserType.Free) && user.customerId) {
      try {
        let subscription
        const customer = await this.billingAPI.getCustomer(user.customerId)
        if (customer) {
          if (customer["subscriptions"] && customer["subscriptions"]["data"].length > 0) {
            subscription = customer["subscriptions"]["data"][0]
          }
        }
        if (!subscription || subscription["status"] !== "active") {
          if (user.userType !== UserType.Free) {
            // Switch to Free
            const input: UpdateUserInput = {
              id: user.id,
              userType: UserType.Free
            }
            await this.updateUser(input)
          }
        } else {
          if (user.userType === UserType.Free) {
            // Switch to Premium
            const input: UpdateUserInput = {
              id: user.id,
              userType: UserType.Premium
            }
            await this.updateUser(input)
          }
        }
      } catch (err: any) {
        console.log(`Error checking subscripton`, err.message)
      }
    }
  }

  async getCurrentSessionPayload() {
    const session = await Auth.currentSession()
    // const authTimeValue = session.getIdToken().payload['auth_time']
    // const authTime = fromUnixTime(authTimeValue)
    // Logger.debug(`User authenticated at ${format(authTime!, "M/d/yyyy h:mm:ss aa")}`)
    // const payload = session.getIdToken().payload
    const groups = session.getIdToken().payload['cognito:groups']
    this.isAdmin = (groups && groups.indexOf("Admin") >= 0)
    return groups
  }

  @computed get isAuthenticated() {
    const isAuthenticated = this.cognitoUser !== undefined && this.user !== undefined
    // Logger.debug(`UserStore get isAuthenticated = ${isAuthenticated}`)
    return isAuthenticated
  }

  checkInterval: any

  async checkAuthentication() {
    Logger.debug("checkAuthentication")
    try {
      const session = await Auth.currentSession()
      const authTimeValue = session.getIdToken().payload['auth_time']
      const authTime = fromUnixTime(authTimeValue)

      if (!isToday(authTime)) {  // Sign-out at midnight the day authenticated
        Logger.debug("Authentication expired")
        await this.signOut()
        // Route to home page
        navigate(RoutesConfig.signIn.pathname!)
        // window.location.reload() // necessary for reconfiguring app state after logout
      }
    } catch (err) {
      Logger.error('checkAuthentication error', err)
    }

    if (!this.checkInterval) {
      // Check authentication every hour
      this.checkInterval = setInterval(() => this.checkAuthentication(), 60 * 60000)
    }
  }

  completeNewPassword = async (user: string, newPassword: string) => {
    return await new Promise<any>((resolve, reject) => {
      Auth.completeNewPassword(user, newPassword, null)
        .then(data => {
          Logger.debug("Auth.completeNewPassword success")
          resolve(data)
        })
        .catch(err => {
          Logger.debug("Auth.completeNewPassword error", err)
          reject(err)
        });
    })
  }

  loadUser = async (userId: string): Promise<User | undefined> => {
    Logger.debug(`UserStore.loadUser(${userId})`)

    const data = await this.rbcAPI.getUser(userId)
    console.log("Loaded user")

    if (data) {
      let user = new User(data)
      if (user) {
        this.user = user
        console.log("Initializing Logger")
        Logger.configureUser(this.user.id)
        Logger.debug("Signed in as " + this.user.id)
        console.log("loadUser completed")
      }
    } else {
      throw new Error("User not found")
    }

    return this.user
  }

  getUser = async (userId: string): Promise<User | undefined> => {
    const data = await this.rbcAPI.getUser(userId)
    if (data) {
      return new User(data)
    } else {
      return undefined
    }
  }

  getUserOnly = async (userId: string): Promise<User | undefined> => {
    const data = await this.rbcAPI.getUserOnly(userId)
    if (data) {
      return new User(data)
    } else {
      return undefined
    }
  }

  async createUser(input: APITypes.CreateUserInput, setStoreUser: boolean = false) {
    if (input.phone) {
      input.phone = phoneToE164Format(input.phone)
    }
    const userRes = await this.rbcAPI!.createUser(input)
    if (userRes) {
      Tracking.event({ action: "Create Account" })
      //   const attributes: ICognitoAttributes = {}
      //   let updateAttributes = false
      //   if (user.accountId) {
      //     attributes[CognitoAttribute.ACCOUNT] = user.accountId
      //     updateAttributes = true
      //   }
      //   if (user.role) {
      //     attributes[CognitoAttribute.ROLE] = user.role
      //     updateAttributes = true
      //   }
      //   if (updateAttributes) {
      //     await this.updateUserAttributes(null, attributes)
      //   }

      const user = new User(userRes)
      if (setStoreUser) this.user = user
      // this.createActivity(ActivityType.UserCreate, this.user.id)
      return user
    } else {
      return null
    }
  }

  async updateUser(input: APITypes.UpdateUserInput) {

    if (input.phone) {
      input.phone = phoneToE164Format(input.phone)
    }
    const user = await this.rbcAPI!.updateUser(input)
    if (user) {
      const updatedUser = new User(user)

      if (this.user && user.id === this.user!.id) {
        // Verify custom account user attribute.  Only works for current authenticated user
        const accountValue = await this.getUserAttribute(null, CognitoAttribute.ACCOUNT)
        if (accountValue !== user.accountId) {
          const attributes: ICognitoAttributes = {}
          attributes[CognitoAttribute.ACCOUNT] = user.accountId
          console.log(`Updated custom:Account Cognito attribute: ${user.accountId}`)
          await this.updateUserAttributes(null, attributes)
        }
        const roleValue = await this.getUserAttribute(null, CognitoAttribute.ROLE)
        if (roleValue !== user.role) {
          const attributes: ICognitoAttributes = {}
          attributes[CognitoAttribute.ROLE] = user.role
          console.log(`Updated custom:Role Cognito attribute: ${user.role}`)
          await this.updateUserAttributes(null, attributes)
        }

        if (user.id === this.user!.id) {
          // Preserve related data
          updatedUser.account = this.user!.account
          updatedUser.models = this.user!.models
          this.user = updatedUser
        }
      }

      return updatedUser
    } else {
      return null
    }
  }
  
  // UserSetting

  getUserSetting = async (userSettingId: string): Promise<UserSetting | undefined> => {
    const data = await this.rbcAPI.getUserSetting(userSettingId)
    if (data) {
      return new UserSetting(data)
    } else {
      return undefined
    }
  }

  async createUserSetting(input: APITypes.CreateUserSettingInput) {
    if (input.id === "current") {
      throw new Error("Cannot save current userSetting")
    }
    const data = await this.rbcAPI!.createUserSetting(input)
    if (data) {
      const userSetting = new UserSetting(data)
      this.updateUserItem("settings", userSetting)
      return userSetting
    } else {
      return undefined
    }
  }

  async updateUserSetting(input: APITypes.UpdateUserSettingInput) {
    const data = await this.rbcAPI!.updateUserSetting(input)
    if (data) {
      const userSetting = new UserSetting(data)
      this.updateUserItem("settings", userSetting)
      return userSetting
    } else {
      return undefined
    }
  }

  deleteUserSetting = async (userSettingId: string): Promise<UserSetting | undefined> => {
    const data = await this.rbcAPI.deleteUserSetting(userSettingId)
    if (data) {
      const userSetting = new UserSetting(data)
      this.deleteUserItem("settings", userSetting)
      return userSetting
    } else {
      return undefined
    }
  }

  updateUserItem (list: string, item: IUserItem) {
    if (this.user && this.user.id === item.userId) {
      let array = this.user[list]
      if (array) {
        const index = array.findIndex((item: any) => item["id"] === item.id)
        if (index >= 0) {
          array[index] = item
        } else {
          array.push(item)
        }
      }
    }
  }

  deleteUserItem(list: string, item: IUserItem) {
    if (this.user && this.user.id === item.userId) {
      let array = this.user[list]
      if (array) {
        const index = array.findIndex((item: any) => item["id"] === item.id)
        if (index >= 0) {
          array.splice(index, 1)
        }
      }
    }
  }


  async updateCancelDate(user: User, cancelDate: Date | null) {
    const cancelledOn = cancelDate ? getISODateFromDate(cancelDate) : null

    if (cancelledOn !== user.cancelledOn) {
      const input: UpdateUserInput = {
        id: user.id,
        cancelledOn: cancelledOn
      }
      const updatedUser = await this.updateUser(input)
      return (true)
    } else {
      return (false)
    }
  }

  async forgotPassword(userId: string) {
    return await Auth.forgotPassword(userId)
  }

  async forgotPasswordSubmit(userId: string, code: string, password: string) {
    return await Auth.forgotPasswordSubmit(userId, code, password)
  }

}

export default UserStore