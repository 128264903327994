import {
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  useTheme
} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {CreatePlanChangeInput, UpdatePlanChangeInput} from "../../../API";
import {useResources} from "../../../stores/ResourceProvider";
import Plan from "../../../model/Plan";
import Model from "../../../model/Model";
import ExpenseStrategyChange, {ExpenseFilter} from "../../../model/changes/ExpenseStrategyChange";
import PlanChange, {PlanChangeType} from "../../../model/PlanChange";
import ModelEditDialog from "../../../components/model/ModelEditDialog";
import Tracking from "../../../components/Tracking";

const ExpenseStrategyDialog = ({
  open,
  plan,
  model,
  onClose,
  onSave
}: {
  open? : boolean
  plan: Plan
  model: Model
  onClose?(): void
  onSave?(plan: Plan, update: PlanChange): void
}) => {
  const [planModel, setPlanModel] = useState<Plan>(plan)
  const [isOpen, setIsOpen] = useState<boolean>(open === true)
  const [expenseStrategyChange, setExpenseStrategyChange] = useState<ExpenseStrategyChange | undefined>()
  const [name, setName] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [filter, setFilter] = useState<ExpenseFilter | undefined>()

  const theme = useTheme()
  const { modelStore, notify } = useResources()

  const styles = {
  }

  useEffect(() => {
    setPlanModel(plan)
    setIsOpen(open === true)
    let change: ExpenseStrategyChange = plan.getChange(PlanChangeType.ExpenseStrategy) as ExpenseStrategyChange
    if (!change) {
      change = new ExpenseStrategyChange({
        accountId: plan.accountId,
        userId: model.userId,
        modelId: model.id,
        planId: plan.id,
        changeType: PlanChangeType.ExpenseStrategy,
        name: "Expense Strategy",
        description: "",
        enabled: true,
        details: JSON.stringify({
          filter: ExpenseFilter.All
        })
      })
    }
    setExpenseStrategyChange(change)
    setName("Expense Strategy")
    setDescription(change.description)
    setFilter(change.filter)
  }, [plan, open])

  const handleClose = async (event: any) => {
    if (onClose) {
      onClose()
    }
  }

  const handleSave = async (event: any) => {
    try {
      let change: PlanChange | undefined

      if (expenseStrategyChange && !expenseStrategyChange.id) {
        const input: CreatePlanChangeInput = {
          accountId: expenseStrategyChange?.accountId,
          userId: expenseStrategyChange.userId,
          modelId: expenseStrategyChange.modelId,
          planId: expenseStrategyChange.planId,
          changeType: expenseStrategyChange.changeType,
          name: name ?? expenseStrategyChange.name,
          description: generateDescription(),
          enabled: true,
          details: JSON.stringify({
            filter: filter
          })
        }
        change = await modelStore.createPlanChange(input)
        Tracking.event({action: "Timeline Strategy Created"})
      } else if (expenseStrategyChange) {
        const input: UpdatePlanChangeInput = {
          id: expenseStrategyChange.id,
          name: name,
          description: generateDescription(),
          enabled: true,
          details: JSON.stringify({
            filter: filter
          })
        }
        change = await modelStore.updatePlanChange(input)
        Tracking.event({action: "Expense Strategy Updated"})
      }

      if (onSave && change) {
        onSave(plan, change)
      } else if (onClose) {
        onClose()
      }
    } catch (err: any) {
      notify.show('error', err.message)
    }
  }

  const generateDescription = () => {
    if (!filter || filter === ExpenseFilter.All) {
      return "All expenses"
    } else {
      return `${filter} expenses only`
    }
  }

  if (!expenseStrategyChange) {
    return null
  }

  return (
    <ModelEditDialog title="Expense Strategy" open={isOpen} size="xs"
                     onCancel={handleClose}
                     onSave={handleSave}
    >
      <DialogContentText>
        Set which expenses to include.
      </DialogContentText>
      <Grid container spacing={2}>
        <Grid item>
          <FormGroup>
            <RadioGroup aria-label="type" name="type" value={filter} row
                        onChange={(event: any) => {
                          setFilter(event.target.value)
                        }}>
              <FormControlLabel
                value="All"
                control={<Radio color="secondary" />}
                label="All"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Essential"
                control={<Radio color="secondary" />}
                label="Essential"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Discretionary"
                control={<Radio color="secondary" />}
                label="Discretionary"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormGroup>
        </Grid>
      </Grid>
    </ModelEditDialog>
  )
}

export default ExpenseStrategyDialog