import { API } from "aws-amplify"
import {getErrorMessage} from "../stores/StoreUtilities";
import Logger from "../components/Logger";
import {htmlDecode} from "js-htmlencode";
import {IAsset} from "../../amplify/backend/function/MigrateLambda/lib/RbcLegacyAPI";

export interface IUser {
  user_id?: number
  user_name?: string
  user_email?: string
  user_password?: string
  user_last_access?: Date
  user_lname?: string
  user_fname?: string
  user_start_date?: Date
  user_expire_date?: Date
  account_status?: number
  is_admin?: number
  is_advisor?: number
  city?: string
  state?: string
  zip?: string
  customer_id?: string
  advisor_accessible?: number
  subscribe_date?: Date
  referrer?: string
}

class MigrateAPI {

  test = async () => {
    try {
      const response = await API.get("MigrateAPI", "/test", {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("MigrateAPI.test error", message)
      throw new Error(message)
    }
  }


  listUsers = async (match: string = "*"): Promise<IUser[]> => {
    try {
      const response = await API.get("MigrateAPI", `/user/${match}`, {})
      return response as IUser[]
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("MigrateAPI.getUsers error", message)
      throw new Error(message)
    }
  }

  authenticate = async (email: string, password: string): Promise<IUser> => {
    try {
      const init = {
        body: {
          email: email,
          password: password
        }
      }

      const response = await API.post("MigrateAPI", `/authenticate`, init)
      if (response.user_fname) {
        response.user_fname = htmlDecode(response.user_fname)
        response.user_name = htmlDecode(response.user_name)
      }
      return response as IUser
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("MigrateAPI.authenticate error", message)
      throw new Error(message)
    }
  }

  verify = async (email: string): Promise<IUser> => {
    try {
      const init = {
        body: {
          email: email
        }
      }

      const response = await API.post("MigrateAPI", `/verify`, init)
      return response as IUser
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("MigrateAPI.verify error", message)
      throw new Error(message)
    }
  }

  migrateUser = async (userId: string) => {
    const init = {
      body: {
      }
    }
    try {
      const response = await API.post("MigrateAPI", `/migrate/${userId}`, init)
      return response
    } catch (err: any) {
      throw new Error(err.response.data.message, {cause: err.response.status})
    }

  }

  listAssets = async (budgetId: string): Promise<IAsset[]> => {
    try {
      const response = await API.get("MigrateAPI", `/assets/${budgetId}`, {})
      return response as IAsset[]
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("MigrateAPI.listAssets error", message)
      throw new Error(message)
    }
  }
}

export default MigrateAPI