import {
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material";
import TaxDisclaimer from "../../../../components/page/TaxDisclaimer";
import AdviceDisclaimer from "../../../../components/page/AdviceDisclaimer";
import {useResources} from "../../../../stores/ResourceProvider";
import {useEffect, useState} from "react";
import {
  IMedicarePremiumRecord,
  ITaxBracketRecord,
  TaxEstimate,
  TaxFilingStatus, TaxInput
} from "../../../../components/tax/TaxEstimator";
import {numberToMoneyFormat, numberToPercentFormat} from "../../../../stores/StoreUtilities";


const TaxTables = ({
  year,
  taxInput,
  taxEstimate
} : {
  year: number
  taxInput: TaxInput
  taxEstimate: TaxEstimate
}) => {
  const [taxBrackets, setTaxBrackets] = useState<ITaxBracketRecord[][]>([])
  const [taxBracket, setTaxBracket] = useState<ITaxBracketRecord | undefined>()
  const [medicarePremiums, setMedicarePremiums] = useState<IMedicarePremiumRecord[][]>([])
  const [medicarePremium, setMedicarePremium] = useState<IMedicarePremiumRecord | undefined>()
  const [filingStatus, setFilingStatus] = useState<TaxFilingStatus>(TaxFilingStatus.Single)
  const [maxTaxDataYear, setMaxTaxDataYear] = useState<number>(2024)
  const { taxEstimator } = useResources()

  const theme = useTheme()
  const styles = {
    table : {
      ".MuiTableCell-root": {
        border: "1px solid silver"
      }
    },
    highlight: {
      color: theme.palette.primary.main,
      fontWeight: 700
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: "none"
    },
    tableLink: {
      fontWeight: 700,
      textDecoration: "underline"
    }
  }

  useEffect(() => {
    if (taxInput && taxEstimate) {
      taxInput.taxYear = year
      setFilingStatus(taxInput.filingStatus)
      const infRate = taxInput.inflationRate
      const brackets = new Array<ITaxBracketRecord[]>()
      const singleBrackets = taxEstimator.getTaxBrackets(year, TaxFilingStatus.Single, taxInput.inflationRate)
      const marriedBrackets = taxEstimator.getTaxBrackets(year, TaxFilingStatus.MarriedFilingJointly, taxInput.inflationRate)
      for (let i=0; i < singleBrackets.length; i++) {
        brackets.push([singleBrackets[i], marriedBrackets[i]])
      }
      setTaxBrackets(brackets)
      setTaxBracket(taxEstimator.getTaxBracket(taxInput, taxEstimate.taxableIncome))

      const premiums = new Array<IMedicarePremiumRecord[]>()
      const singlePremiums = taxEstimator.getMedicarePremiums(year, TaxFilingStatus.Single, infRate)
      const marriedPremiums = taxEstimator.getMedicarePremiums(year, TaxFilingStatus.MarriedFilingJointly, infRate)
      for (let i=0; i < singlePremiums.length; i++) {
        premiums.push([singlePremiums[i], marriedPremiums[i]])
      }
      setMedicarePremiums(premiums)
      setMedicarePremium(taxEstimator.getMedicarePremium(year, taxInput.filingStatus, taxEstimate.adjustedGrossIncome, infRate))
    }
  }, [year, taxInput, taxEstimate])

  const renderIncomeRange = (tb: ITaxBracketRecord) => {
    let range: string

    if (tb.end) {
      range = `${numberToMoneyFormat(tb.start, 0)} to ${numberToMoneyFormat(tb.end, 0)}`
    } else {
      range = `${numberToMoneyFormat(tb.start, 0)}+`
    }
    if (tb.filingStatus === filingStatus && taxBracket && tb.start === taxBracket.start) {
      return (<span style={styles.highlight}>{range}</span>)
    } else {
      return range
    }
  }

  const renderTaxBracket = (tb: ITaxBracketRecord) => {
    let rate = numberToPercentFormat(tb.bracket, 0)

    if (tb.filingStatus === filingStatus && taxBracket && tb.start === taxBracket.start) {
      return (<span style={styles.highlight}>{rate}</span>)
    } else {
      return rate
    }
  }

  const renderMedicareRange = (rec: IMedicarePremiumRecord) => {
    let range: string

    if (rec.end) {
      range = `${numberToMoneyFormat(rec.start, 0)} to ${numberToMoneyFormat(rec.end, 0)}`
    } else {
      range = `${numberToMoneyFormat(rec.start, 0)}+`
    }
    if (rec.filingStatus === filingStatus && medicarePremium && rec.start === medicarePremium.start) {
      return (<span style={styles.highlight}>{range}</span>)
    } else {
      return range
    }
  }

  const renderMedicarePremium = (rec: IMedicarePremiumRecord) => {
    let amount = numberToMoneyFormat(rec.amount, 0)

    if (rec.filingStatus === filingStatus && medicarePremium && rec.start === medicarePremium.start) {
      return (<span style={styles.highlight}>{amount}</span>)
    } else {
      return amount
    }
  }

  const renderTaxBracketsTable = () => {
    const title = <Link href="https://www.irs.gov/newsroom/irs-provides-tax-inflation-adjustments-for-tax-year-2024" target="_blank" sx={styles.tableLink}>
      {year > maxTaxDataYear && "Estimated "}{year} Marginal Tax Brackets
    </Link>

    const infRate = taxInput.inflationRate
    const notice = (year > maxTaxDataYear) ? `NOTICE: Tax Brackets estimated based on ${maxTaxDataYear} data and ${numberToPercentFormat(infRate, 2)} annual inflation.` : ""

    return (
      <Table size="small" width="100%" sx={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell>{title}</TableCell>
            <TableCell colSpan={2}>{notice}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>When filing single, or taxable income within:</TableCell>
            <TableCell>When married filing jointly, for taxable income within:</TableCell>
            <TableCell>...tax rate is:</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {taxBrackets.map((tb: ITaxBracketRecord[]) => {
            return (
              <TableRow>
                <TableCell>{renderIncomeRange(tb[0])}</TableCell>
                <TableCell>{renderIncomeRange(tb[1])}</TableCell>
                <TableCell align="center">{renderTaxBracket(tb[filingStatus-1])}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  const renderMedicarePremiumsTable = () => {
    const title = <Link href="https://www.medicare.gov/basics/costs/medicare-costs" target="_blank" sx={styles.tableLink}>
      {year > maxTaxDataYear && "Estimated "}{year} Medicare Part B Premiums
    </Link>
    const infRate = taxInput.inflationRate
    const notice = (year > maxTaxDataYear) ? `NOTICE: Premiums estimated based on ${maxTaxDataYear} data and ${numberToPercentFormat(infRate, 2)} annual inflation.` : ""

    return (
      <Table size="small" width="100%" sx={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell >{title}</TableCell>
            <TableCell colSpan={2}>{notice}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>When filing single, for modified AGI within:</TableCell>
            <TableCell>When married filing jointly, for modified AGI within:</TableCell>
            <TableCell>...monthly payment is:</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {medicarePremiums.map((rec: IMedicarePremiumRecord[]) => {
            return (
              <TableRow>
                <TableCell>{renderMedicareRange(rec[0])}</TableCell>
                <TableCell>{renderMedicareRange(rec[1])}</TableCell>
                <TableCell align="center">{renderMedicarePremium(rec[filingStatus-1])}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="stretch" mr={3} mb={2}>
      <TableContainer component={Paper} sx={{px:2, py:1}}>
        <Box display="flex" flexGrow={1} mt={1} mb={2}>
          {renderTaxBracketsTable()}
        </Box>

        <Box display="flex" flexGrow={1} mt={1} mb={2}>
          {renderMedicarePremiumsTable()}
        </Box>

        <Typography variant="body2" gutterBottom>
        <strong>Federal Tax Calculations</strong> last updated on <strong>12/1/2023</strong>&nbsp;
          (based on <Link href="https://www.irs.gov/newsroom/irs-provides-tax-inflation-adjustments-for-tax-year-2024" target="_blank" sx={styles.link}>IRS-2023-208, Nov.9, 2023</Link>).
        </Typography>
        <Typography variant="body2" gutterBottom>
          <strong>RMD Tables</strong> last updated on <strong>2/16/2023</strong>
        </Typography>
        <TaxDisclaimer/>
        <AdviceDisclaimer/>
      </TableContainer>
    </Box>
  )
}

export default TaxTables