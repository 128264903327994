import * as React from 'react'

interface IVisibleProps {
  cond: boolean
  children?: any
}

const Visible = (props: IVisibleProps) => {
  const { cond, children } = props

  if (cond) {
    return (
      <React.Fragment>
          {children}
      </React.Fragment>
    )
  } else {
    return null
  }
}

export default Visible