import * as React from 'react';
import {useEffect, useState} from 'react';
import Asset, {AssetCategory, AssetType, AssetTypeDef, getAssetTypeLabel, Risk} from "../../../model/Asset";
import {FormLabel, Grid, Typography, useTheme} from "@mui/material";
import ModelCard2 from "../../../components/model/ModelCard2";
import {
  dateToLocalFormat,
  humanizeString,
  numberToMoneyFormat,
  numberToPercentFormat
} from "../../../stores/StoreUtilities";
import Model from "../../../model/Model";
import Snapshot, {ISnapshotDetail} from "../../../model/Snapshot";
import {LiquidAssetIcon, OtherAssetIcon, PersonalAssetIcon, RealAssetIcon} from "../../../styles/Icons";
import AssetEditDialog from "./AssetEditDialog";
import {GrowthModel} from "../../../model/GrowthStrategy";
import {isNumber} from "../../../model/ModelUtilities";

const AssetCard = ({
  asset,
  model,
  snapshot,
  expanded,
  onChange,
  onSave,
  onDelete
}: {
  asset: Asset
  model: Model
  snapshot?: Snapshot
  expanded?: boolean
  onChange?(event: any, expanded: boolean): void
  onSave?(expense: Asset, snapshotItem?: ISnapshotDetail): void
  onDelete?(deleted: Asset): void
}) => {
  const [assetModel, setAssetModel] = useState<Asset>(asset)
  const [snapshotDetail, setSnapshotDetail] = useState<ISnapshotDetail | undefined>()
  const [description, setDescription] = useState<string>("")
  const [assetCategory, setAssetCategory] = useState<AssetCategory | undefined>()
  const [assetType, setAssetType] = useState<AssetType | undefined>()
  const [assetTypeDef, setAssetTypeDef] = useState<AssetTypeDef | undefined>()
  const [owner, setOwner] = useState<string | undefined>()
  const [risk, setRisk] = useState<string | undefined>()
  const [returnRate, setReturnRate] = useState<string | undefined>()
  const [rateLock, setRateLock] = useState<boolean>(false)
  const [withdrawalOrder, setWithdrawalOrder] = useState<string | undefined>()
  const [balance, setBalance] = useState<string | undefined>()
  const [balanceDate, setBalanceDate] = useState<string | undefined>()
  const [priorYear, setPriorYear] = useState<number>(0)
  const [priorEoYBalance, setPriorEoYBalance] = useState<string | undefined>()
  const [withdrawalsYTD, setWithdrawalsYTD] = useState<string | undefined>()
  // TODO
  const [inheritedYear, setInheritedYear] = useState<string | undefined>()
  const [originalOwnerBirthYear, setOriginalOwnerBirthYear] = useState<string | undefined>()
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)

  useEffect(() => {
    init(asset)
  }, [asset, snapshot])

  const init = (asset: Asset) => {
    setAssetModel(asset)
    setDescription(asset.description)
    setAssetCategory(asset.assetCategory)
    setAssetType(asset.assetType)
    setAssetTypeDef(asset.assetTypeDef)
    setOwner(model.getPerson(asset.ownerId)?.nickname ?? Model.jointNickname)
    setRisk(asset.risk !== null && asset.risk !== undefined ? humanizeString(Risk[asset.risk]) : undefined)
    setReturnRate(getReturnRate())
    setRateLock(asset.rateLock)
    setWithdrawalOrder(asset.withdrawalOrder ? asset.withdrawalOrder.toString() : "")
    const detail = snapshot ? snapshot.getSnapshotDetail(asset) : undefined
    if (detail) {
      setSnapshotDetail(detail)
      setBalance(numberToMoneyFormat(detail.balance, 0))
      setBalanceDate(dateToLocalFormat(snapshot!.date))
    } else {
      setBalance(numberToMoneyFormat(0, 0))
      if (snapshot) {
        setBalanceDate(dateToLocalFormat(snapshot!.date))
      }
    }
    setPriorYear((new Date()).getFullYear() - 1)
    setPriorEoYBalance(asset.priorEoYBalance ? numberToMoneyFormat(asset.priorEoYBalance, 0) : undefined)
    setWithdrawalsYTD(asset.withdrawalsYTD ? numberToMoneyFormat(asset.withdrawalsYTD, 0) : undefined)
    setInheritedYear(asset.inheritedYear ? asset.inheritedYear.toString() : "")
    setOriginalOwnerBirthYear(asset.originalOwnerBirthYear ? asset.originalOwnerBirthYear.toString() : "")
    // TODO: receiptMonths, Days
    setEditOpen(!asset.createdAt)
  }

  const theme = useTheme()

  const styles = {
    heading: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    row: {
      height: 40,
      minHeight: 40,
      maxHeight: 40,
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "1.0em",
      maxHeight: 15,
      marginBottom: "1px",
      textWrap: "nowrap",
      color: theme.palette.grey["700"]
    },
    assetIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: "#40642F",
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    },
    accountIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: "#40642F",
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    },
    realEstateIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: "#7f5539",
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    },
    personalPropertyIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: "#b08968",
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    },
    otherPropertyIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: "#ddb892",
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }
  }

  const getReturnRate = () => {
    let rate = ""
    if (asset.assetCategory === AssetCategory.LiquidInvestableAssets) {
      if (asset.rateLock) {
        rate = numberToPercentFormat(asset.returnRate, 2)
      } else {
        const growthStrategy = model.growthStrategy
        if (growthStrategy) {
          if (growthStrategy.growthModel === GrowthModel.Simple) {
            if (!growthStrategy.ignoreCustomGrowthRates && isNumber(asset.returnRate)) {
              rate = numberToPercentFormat(asset.returnRate, 2)
            } else {
              rate = numberToPercentFormat(growthStrategy.growthRate, 2)
            }
          } else {
            if (!growthStrategy.ignoreCustomGrowthRates && isNumber(asset.returnRate)) {
              rate = numberToPercentFormat(asset.returnRate, 2)
            } else {
              rate = `${humanizeString(growthStrategy.returnSequence)}`
            }
          }
        } else {
          rate = numberToPercentFormat(Model.defaultGrowthRate)
        }
      }
    } else if (asset.assetCategory === AssetCategory.RealEstateAndProperty) {
      rate = numberToPercentFormat(asset.returnRate, 2)
    }

    return rate
  }

  const renderContent = () => {
    const balanceLabel = (assetCategory === AssetCategory.LiquidInvestableAssets) ? "Balance" : "Value"
    const requireExtraFields = assetTypeDef && assetTypeDef.requireExtraFields

    return (
      <Grid container direction="column" spacing={0}>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>{balanceLabel}</FormLabel>
              <Typography variant="body2">
                {balance}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>{balanceLabel} Date</FormLabel>
              <Typography variant="body2">
                {balanceDate}
              </Typography>
            </Grid>
            {assetCategory === AssetCategory.LiquidInvestableAssets && assetTypeDef?.useBalanceYStart &&
              <Grid item xs={4}>
                <FormLabel sx={styles.formLabel}>{priorYear} End Balance</FormLabel>
                <Typography variant="body2">
                  {priorEoYBalance}
                </Typography>
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Owner</FormLabel>
              <Typography variant="body2">
                {owner}
              </Typography>
            </Grid>
            {assetCategory === AssetCategory.LiquidInvestableAssets &&
              <Grid item xs={8}>
                <FormLabel sx={styles.formLabel}>Account Type</FormLabel>
                <Typography variant="body2" noWrap={true}>
                  {getAssetTypeLabel(asset.assetType) ?? ""}
                </Typography>
              </Grid>
            }
            {assetCategory === AssetCategory.RealEstateAndProperty &&
              <Grid item xs={4}>
                <FormLabel sx={styles.formLabel}>Growth Rate</FormLabel>
                <Typography variant="body2">
                  {returnRate}
                </Typography>
              </Grid>
            }
          </Grid>
        </Grid>
        {requireExtraFields &&
          <Grid item xs={12} sx={styles.row}>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={4}>
                <FormLabel sx={styles.formLabel}>Inhertied Year</FormLabel>
                <Typography variant="body2">
                  {inheritedYear}
                </Typography>
              </Grid>
              {(assetType === AssetType.IRANonSpouseInheritedBefore2020 || assetType === AssetType.RothIRANonSpouseInheritedBefore2020) &&
                <Grid item xs={8}>
                  <FormLabel sx={styles.formLabel}>Original Owner Birth Year</FormLabel>
                  <Typography variant="body2">
                    {originalOwnerBirthYear}
                  </Typography>
                </Grid>
              }
            </Grid>
          </Grid>
        }
        {assetCategory === AssetCategory.LiquidInvestableAssets &&
          <Grid item xs={12} sx={styles.row}>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={4}>
                <FormLabel sx={styles.formLabel}>Risk</FormLabel>
                <Typography variant="body2">
                  {risk}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormLabel sx={styles.formLabel}>Withdrawal Order</FormLabel>
                <Typography variant="body2">
                  {withdrawalOrder}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormLabel sx={styles.formLabel}>Return Rate</FormLabel>
                <Typography variant="body2">
                  {returnRate}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        }
        {assetTypeDef?.isTaxableIRA && withdrawalsYTD &&
          <Grid item xs={12} sx={styles.row}>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={4}>
                <FormLabel sx={styles.formLabel}>YTD Withdrawls</FormLabel>
                <Typography variant="body2">
                  {withdrawalsYTD}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>
    )
  }

  let icon
  switch (assetCategory) {
    case AssetCategory.LiquidInvestableAssets:
      icon = <LiquidAssetIcon/>
      break
    case AssetCategory.RealEstateAndProperty:
      icon = <RealAssetIcon/>
      break
    case AssetCategory.PersonalProperty:
      icon = <PersonalAssetIcon/>
      break
    case AssetCategory.OtherAssets:
      icon = <OtherAssetIcon/>
      break
    default:
      icon = <LiquidAssetIcon/>
      break
  }

  return (
    <React.Fragment>
      <ModelCard2
        icon={icon}
        title={description}
        value={balance}
        expanded={expanded}
        onChange={onChange}
        editButtonId={`edit-btn-${assetModel.id}`}
        onEdit={onSave ? (event: any) => {
          setEditOpen(!editOpen)
        } : undefined}
      >
        {renderContent()}
      </ModelCard2>
      {editOpen &&
        <AssetEditDialog asset={assetModel} model={model} snapshot={snapshot}
                         open={editOpen}
                         onSave={(update: Asset, snapshotItem?: ISnapshotDetail) => {
                           init(update)
                           setEditOpen(false)
                           if (onSave) {
                             onSave(update, snapshotItem)
                             setSaved(true)
                           }
                         }}
                         onDelete={(deleted: Asset) => {
                           setEditOpen(false)
                           if (onDelete) {
                             onDelete(deleted)
                           }
                         }}
                         onClose={() => {
                           setEditOpen(false)
                           if (!assetModel.createdAt && onDelete) {
                             onDelete(assetModel)
                           }
                         }}/>
      }

    </React.Fragment>
  )
}

export default AssetCard