import * as React from 'react';
import Liability from "../../../model/Liability";
import {FormLabel, Grid, Typography, useTheme} from "@mui/material";
import ModelCard2 from "../../../components/model/ModelCard2";
import {useEffect, useState} from "react";
import {
  dateToLocalFormat,
  isoToLocalDateString,
  numberToMoneyFormat, numberToPercentFormat,
} from "../../../stores/StoreUtilities";
import Model from "../../../model/Model";
import Snapshot, {ISnapshotDetail} from "../../../model/Snapshot";
import {LiabilityIcon} from "../../../styles/Icons";
import LiabilityEditDialog from "./LiabilityEditDialog";
import {isNumber} from "../../../model/ModelUtilities";

const LiabilityCard = ({
  liability,
  model,
  snapshot,
  expanded,
  onChange,
  onSave,
  onDelete
}: {
  liability: Liability
  model: Model
  snapshot?: Snapshot
  expanded?: boolean
  onChange?(event: any, expanded: boolean): void
  onSave?(expense: Liability, snapshotItem?: ISnapshotDetail): void
  onDelete?(deleted: Liability): void
}) => {
  const [liabilityModel, setLiabilityModel] = useState<Liability>(liability)
  const [snapshotDetail, setSnapshotDetail] = useState<ISnapshotDetail | undefined>()
  const [description, setDescription] = useState<string>("")
  const [owner, setOwner] = useState<string | undefined>()
  const [balance, setBalance] = useState<string | undefined>()
  const [balanceDate, setBalanceDate] = useState<string | undefined>()
  const [payoffDate, setPayoffDate] = useState<string | undefined>()
  const [interestRate, setInterestRate] = useState<string | undefined>()
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)

  useEffect(() => {
    init(liability)
  }, [liability, snapshot])

  const init = (liability: Liability) => {
    setLiabilityModel(liability)
    setDescription(liability.description)
    setOwner(model.getPerson(liability.ownerId)?.nickname ?? Model.jointNickname)
    const detail = snapshot ? snapshot.getSnapshotDetail(liability) : undefined
    if (detail) {
      setSnapshotDetail(detail)
      setBalance(numberToMoneyFormat(detail.balance, 0))
      setBalanceDate(dateToLocalFormat(snapshot!.date))
    } else {
      setBalance(numberToMoneyFormat(0, 0))
      setBalanceDate(dateToLocalFormat(snapshot!.date))
    }
    setPayoffDate(dateToLocalFormat(liability.endDate))
    setInterestRate(isNumber(liability.interestRate) ? numberToPercentFormat(liability.interestRate, 2) : "")
    setEditOpen(!liability.createdAt)
  }

  const theme = useTheme()

  const styles = {
    heading: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    row: {
      height: 40,
      minHeight: 40,
      maxHeight: 40,
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "1.0em",
      maxHeight: 15,
      marginBottom: "1px",
      textWrap: "nowrap",
      color: theme.palette.grey["700"]
    },
    liabilityIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: "#881E10",
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    }
  }

  const renderContent = () => {
    return (
      <Grid container direction="column" spacing={0}>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Balance</FormLabel>
              <Typography variant="body2">
                {balance}
              </Typography>
            </Grid>
              <Grid item xs={8}>
                <FormLabel sx={styles.formLabel}>Balance Date</FormLabel>
                <Typography variant="body2">
                  {balanceDate}
                </Typography>
              </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Owner</FormLabel>
              <Typography variant="body2">
                {owner}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Payoff Date</FormLabel>
              <Typography variant="body2" color={liability.end ? "inherit" : "darkgrey"}>
                {payoffDate}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Interest Rate %</FormLabel>
              <Typography variant="body2">
                {interestRate}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <React.Fragment>
      <ModelCard2
        icon={<LiabilityIcon/>}
        title={description}
        value={balance}
        valueColor="#881E10"
        expanded={expanded}
        onChange={onChange}
        editButtonId={`edit-btn-${liabilityModel.id}`}
        onEdit={(event: any) => {
          setEditOpen(!editOpen)
        }}
      >
        {renderContent()}
      </ModelCard2>
      {editOpen &&
        <LiabilityEditDialog liability={liabilityModel} model={model} snapshot={snapshot}
                             open={editOpen}
                             onSave={(update: Liability, snapshotItem?: ISnapshotDetail) => {
                               init(update)
                               setEditOpen(false)
                               if (onSave) {
                                 onSave(update, snapshotItem)
                                 setSaved(true)
                               }
                             }}
                             onDelete={(deleted: Liability) => {
                               setEditOpen(false)
                               if (onDelete) {
                                 onDelete(deleted)
                               }
                             }}
                             onClose={() => {
                               setEditOpen(false)
                               if (!liabilityModel.createdAt && onDelete) {
                                 onDelete(liabilityModel)
                               }
                             }}/>
      }
    </React.Fragment>
  )
}

export default LiabilityCard