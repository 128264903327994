import ReactGA from 'react-ga4';
import Logger from "./Logger";
import {UaEventOptions} from "react-ga4/types/ga4";

interface ITrackingEventArgs {
  category?: string
  action: string
  value?: number
  label?: string
}

export enum TrackingCodes {
  ToDo
}

class Tracking {
  static initialized = false
  static UUIDPattern = new RegExp(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/)


  static initialize(trackingId: string, options: any) {
    ReactGA.initialize(trackingId, options)
    ReactGA.gtag('consent', 'default', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'denied'
    });
    ReactGA.gtag("consent", "update", {
      analytics_storage: "granted",
    });
    this.initialized = true
    Logger.debug(`Tracking.initialize: ${trackingId} options: ${JSON.stringify(options)}`)
  }

  static set(args: any) {
    if (this.initialized) {
      ReactGA.set(args)
      Logger.debug(`Tracking.set: ${JSON.stringify(args)}`)
    }
  }

  static pageView(path: string) {
    if (this.initialized) {
      const cleaned = Tracking.cleanPath(path)
      ReactGA.send({hitType: "pageview", page: cleaned})
      Logger.debug(`Tracking.pageView: ${cleaned}`)
    }
  }

  static modalView(name: string) {
    if (this.initialized) {
      // TODO: ReactGA.modalview(name)
      Logger.debug(`Tracking.modalview: ${name}`)
    }
  }

  static event(args: ITrackingEventArgs) {
    if (this.initialized) {
      const eventArgs: UaEventOptions = {
        category: args.category || Tracking.cleanPath(window.location.pathname),
        action: args.action,
        value: args.value,
        label: args.label
      }
      ReactGA.event(eventArgs)
    }
    Logger.debug(`Tracking.event: ${JSON.stringify(args)}`)
  }

  static gtag(type: string, action: string, params: any) {
    if (this.initialized) {
      ReactGA.gtag(type, action, params)
    }
    Logger.debug(`Tracking.gtag: ${type}, ${action}`, params)
  }

  static cleanPath(path: string) {
    // Clean out the UUIDs from the path for analytic reporting
    const cleaned = path.replace(Tracking.UUIDPattern, "uuid")
    return cleaned
  }

}

export default Tracking