import * as React from 'react';
import {useEffect, useState} from 'react';
import {useResources} from "../../stores/ResourceProvider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {Box, FormControlLabel, InputAdornment, Switch, Typography, useTheme} from "@mui/material";
import User, {MFAPreference, SubscriptionFrequency} from "../../model/User";
import "../../css/normalize.css"
import "../../css/webflow.css"
import "../../css/next-retirement.webflow.css"
import CheckImg from "../../images/Check_1Check.png"
import {numberToMoneyFormat} from "../../stores/StoreUtilities";
import CheckoutForm from "../checkout/CheckoutForm";
import CloseButton from "../../components/controls/CloseButton";
import {UserType} from "../../API";
import useNavigationHelper from "../../components/NavigationHelper";
import Tracking from "../../components/Tracking";

const SubscriptionEditDialog = ({
  open,
  user,
  subscriptionPlan,
  subscriptionFrequency,
  onClose,
  onSave
}: {
  open?: boolean
  user: User
  subscriptionPlan?: "none" | "free" | "premium"
  subscriptionFrequency?: SubscriptionFrequency
  onClose(event?: any): void
  onSave(update: User): void
}) => {
  const prices = {
    "Monthly": "RBC_002M",
    "Yearly": "RBC_002"

  }
  const amounts = {
    "Monthly": 9.95,
    "Yearly": 95.00
  }
  const [plan, setPlan] = useState<"none" | "free" | "premium" | undefined>()
  const [freq, setFreq] = useState<SubscriptionFrequency>("Monthly")
  const [price, setPrice] = useState<string>(prices["Monthly"])
  const [amount, setAmount] = useState<number>(amounts["Monthly"])
  const [showCheckoutForm, setShowCheckoutForm] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("")
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  useEffect( () => {
    if (subscriptionPlan !== undefined) {
      setPlan(subscriptionPlan)
      setFreq(subscriptionFrequency ?? "Monthly")
    } else if (user.userType === UserType.Premium) {
      setPlan("premium")
      setFreq(subscriptionFrequency ?? "Monthly")
    } else {
      setPlan("free")
      setFreq("Monthly")
      Tracking.event({action: "view_item", value: amount, label: freq})
    }
  }, [user, subscriptionPlan, subscriptionFrequency])

  const {userStore} = useResources()
  const {navigateInvest} = useNavigationHelper()

  const theme = useTheme()

  const styles = {
    submitButton: {
      color: "#fff",
    },
    instructions: {
      marginTop: theme.spacing(2),
      marginBottom: 0
    }
  }

  const handleClose = () => {
    setMessage("")
    onClose()
  };

  const handleSubmit = async () => {
    setMessage("")

    try {
      setIsProcessing(true)
      onSave(user)
    } catch (err: any) {
      setMessage(err.message)
    } finally {
      setIsProcessing(false)
    }
  }

  const selectFreq = (freq: SubscriptionFrequency) => {
    setFreq(freq)
    setAmount(amounts[freq])
    setPrice(prices[freq])
    Tracking.gtag("event", "view_item", {currency: "USD", value: amounts[freq], items: [{item_id: price, item_name: freq}]})
  }

  const selectFree = () => {
    setPlan("free")
    onSave(user)
  }

  const selectPremium = async () => {
    setPlan("premium")
    Tracking.gtag("event", "add_to_cart", {currency: "USD", value: amount, items: [{item_id: price, item_name: freq}]})
    Tracking.gtag("event", "begin_checkout", {currency: "USD", value: amount, items: [{item_id: price, item_name: freq}]})
    setShowCheckoutForm(true)
  }

  const renderContent = () => {
    return (
      <div style={{}}>
        <div className="container">
          <div className="align-center" style={{maxHeight:30}}>
            <div className="narrow-content">
              <div className="animation-trigger _1">
                <h1 className="section-title">
                  Subscription Plans
                </h1>
              </div>
            </div>
          </div>
          <div className="animation-trigger _3">
            <div
              data-current="Tab 1"
              data-easing="ease"
              data-duration-in={300}
              data-duration-out={100}
              className="pricing-wrap w-tabs"
            >
              <div className="pricing-tabs w-tab-menu">
                <a data-w-tab="Tab 1"
                  className={`pricing-tab w-inline-block w-tab-link ${freq === "Monthly" ? "w--current" : ""}`}
                  onClick={() => selectFreq("Monthly")}
                >
                  <div>Monthly</div>
                </a>
                <a data-w-tab="Tab 2"
                  className={`pricing-tab w-inline-block w-tab-link ${freq === "Yearly" ? "w--current" : ""}`}
                  onClick={() => selectFreq("Yearly")}
                >
                  <div>Yearly (save 20%)</div>
                </a>
                <a
                  data-w-tab="Tab 3"
                  className="pricing-tab lifetime-tab w-inline-block w-tab-link"
                >
                  <div>Lifetime</div>
                </a>
              </div>
              <div className="w-tab-content">
                <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
                  <div className="pricing-grid">
                    <div id="free-card-1" className="pricing-card">
                      <div id="free-plan-1" className="pricing-plan">
                        Free Plan
                      </div>
                      <div className="price-text">$0</div>
                      <div className="price-sub-text">per month</div>
                      <div className="list-div">
                        <div className="advsor-feature">
                          <img
                            alt=""
                            loading="lazy"
                            src={CheckImg}
                            className="checkmark-icon"
                          />
                          <div>Basic Retirement Dashboard</div>
                        </div>
                        <div className="advsor-feature">
                          <img
                            alt=""
                            loading="lazy"
                            src={CheckImg}
                            className="checkmark-icon"
                          />
                          <div>Retirement Budgeting</div>
                        </div>
                        <div className="advsor-feature">
                          <img
                            alt=""
                            loading="lazy"
                            src={CheckImg}
                            className="checkmark-icon"
                          />
                          <div>Asset Tracking</div>
                        </div>
                        <div className="advsor-feature">
                          <img
                            alt=""
                            loading="lazy"
                            src={CheckImg}
                            className="checkmark-icon"
                          />
                          <div>Free Forever</div>
                        </div>

                      </div>
                      <a
                        id="free-button-1"
                        href="#"
                        className="button-sm free w-button"
                        onClick={() => {
                          if (plan !== "free") { selectFree() }
                        }}
                      >
                        {plan === "free" ? "Current Plan" : "Select Free Plan"}
                      </a>
                    </div>
                    <div id="premium-card-1" className="pricing-card main">
                      <div id="premium-plan-1" className="pricing-plan main">
                        Premium Plan
                      </div>
                      <div id="premium-price-1" className="price-text">
                        {numberToMoneyFormat(amount)}
                      </div>
                      <div className="price-sub-text">per {freq === "Monthly" ? "month" : "year"}</div>
                      <div className="list-div">
                        <div className="advsor-feature">
                          <img
                            alt=""
                            loading="lazy"
                            src={CheckImg}
                            className="checkmark-icon"
                          />
                          <div>Advanced Retirement Dashboard</div>
                        </div>
                        <div className="advsor-feature">
                          <img
                            alt=""
                            loading="lazy"
                            src={CheckImg}
                            className="checkmark-icon"
                          />
                          <div>Retirement Optimizer</div>
                        </div>
                        <div className="advsor-feature">
                          <img
                            alt=""
                            loading="lazy"
                            src={CheckImg}
                            className="checkmark-icon"
                          />
                          <div>Tax Estimator</div>
                        </div>
                        <div className="advsor-feature">
                          <img
                            alt=""
                            loading="lazy"
                            src={CheckImg}
                            className="checkmark-icon"
                          />
                          <div>AI Financial Assistant</div>
                        </div>
                        <div className="advsor-feature">
                          <img
                            alt=""
                            loading="lazy"
                            src={CheckImg}
                            className="checkmark-icon"
                          />
                          <div>RBC Community Access</div>
                        </div>
                      </div>
                      <a
                        id="premium-button-1"
                        href="#"
                        className="button-sm w-button"
                        onClick={() => {
                          if (plan !== "premium") { selectPremium() }
                        }}
                      >
                        {plan === "premium" ? "Current Plan" : "Select Premium Plan"}
                      </a>
                    </div>
                    <div id="advisor-card-1" className="pricing-card">
                      <div id="advisor-plan-1" className="pricing-plan">
                        Investment Consultation
                      </div>
                      <div className="price-text price-custom">
                        <a href="#" className="link" onClick={() => navigateInvest()}>
                          Contact Us
                        </a>
                      </div>
                      <div className="price-sub-text">for details</div>
                      <div className="list-div">
                        <div className="advsor-feature">
                          <img
                            alt=""
                            loading="lazy"
                            src={CheckImg}
                            className="checkmark-icon"
                          />
                          <div>Investment Portfolio Design</div>
                        </div>
                        <div className="advsor-feature">
                          <img
                            alt=""
                            loading="lazy"
                            src={CheckImg}
                            className="checkmark-icon"
                          />
                          <div>Investment Management</div>
                        </div>
                        <div className="advsor-feature">
                          <img
                            alt=""
                            loading="lazy"
                            src={CheckImg}
                            className="checkmark-icon"
                          />
                          <div>Ongoing Support</div>
                        </div>
                        <div className="advsor-feature">
                          <img
                            alt=""
                            loading="lazy"
                            src={CheckImg}
                            className="checkmark-icon"
                          />
                          <div>Monitor and Rebalance Investments</div>
                        </div>
                      </div>
                      <a
                        id="advisor-button-1"
                        href="#"
                        className="button-sm w-button"
                        onClick={() => {
                          Tracking.gtag("event", "generate_lead", {currency: "USD", value: 0})
                          navigateInvest()
                        }}
                      >
                        Get Information
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Dialog
      id="subscription-edit-dialog"
      open={open === true}
      onClose={handleClose}
      scroll="paper"
      maxWidth="lg"
      fullWidth
      aria-labelledby="subscription-edit-dialog-title">
      <DialogContent
        sx={{backgroundColor: theme.palette.background.default}}
      >
        {!showCheckoutForm &&
          renderContent()
        }
        {showCheckoutForm &&
          <CheckoutForm price={price} customer_email={user.email}/>
        }
      </DialogContent>
      <CloseButton onClick={() => {
        Tracking.event({action: "Checkout Session Cancelled"})
        onClose()
        }}
      />
    </Dialog>
  )
}

export default SubscriptionEditDialog