import {Box, Checkbox, DialogContentText, FormControlLabel, Grid, useTheme} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {
  dateToLocalFormat,
  getISODateFromDate,
  isoToLocalDate,
  isoToLocalDateString
} from "../../../stores/StoreUtilities";
import {CreatePlanChangeInput, UpdatePlanChangeInput} from "../../../API";
import {useResources} from "../../../stores/ResourceProvider";
import Plan from "../../../model/Plan";
import Model from "../../../model/Model";
import TimelineStrategyChange from "../../../model/changes/TimelineStrategyChange";
import PlanChange, {PlanChangeType} from "../../../model/PlanChange";
import ModelEditDialog from "../../../components/model/ModelEditDialog";
import Milestone, {MilestoneId} from "../../../model/Milestone";
import Person from "../../../model/Person";
import DatePickerValidator from "../../../components/form/DatePickerValidator";
import {addYears} from "date-fns";
import TextFieldValidator from "../../../components/form/TextFieldValidator";
import Tracking from "../../../components/Tracking";
import MilestoneDateFieldValidator from "../../../components/form/MilestoneDateFieldValidator";
import {IPersonTimeline} from "../../../components/calculator/Calculator";

const TimelineStrategyDialog = ({
  open,
  plan,
  model,
  onClose,
  onSave
}: {
  open? : boolean
  plan: Plan
  model: Model
  onClose?(): void
  onSave?(plan: Plan, update: PlanChange): void
}) => {
  const [planModel, setPlanModel] = useState<Plan>(plan)
  const [isOpen, setIsOpen] = useState<boolean>(open === true)
  const [timelineStrategyChange, setTimelineStrategyChange] = useState<TimelineStrategyChange | undefined>()
  const [name, setName] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [person1, setPerson1] = useState<Person | undefined>()
  const [person2, setPerson2] = useState<Person | undefined>()
  const [defaults, setDefaults] = useState<Map<string, Milestone>>(new Map<string, Milestone>([]))
  const [retirement1, setRetirement1] = useState<string | undefined>(undefined)
  const [options1, setOptions1] = useState<Milestone[]>([])
  const [retirement2, setRetirement2] = useState<string | undefined>(undefined)
  const [options2, setOptions2] = useState<Milestone[]>([])
  const [lifeExpectancy1, setLifeExpectancy1] = useState<string | undefined>()
  const [lifeExpectancy2, setLifeExpectancy2] = useState<string | undefined>()
  const [commitChanges, setCommitChanges] = useState<boolean>(false)

  const theme = useTheme()
  const { modelStore, calculator, notify } = useResources()

  const styles = {
  }

  useEffect(() => {
    setPlanModel(plan)
    setIsOpen(open === true)
    let change: TimelineStrategyChange = plan.getChange(PlanChangeType.TimelineStrategy) as TimelineStrategyChange
    if (!change) {
      change = new TimelineStrategyChange({
        accountId: plan.accountId,
        userId: model.userId,
        modelId: model.id,
        planId: plan.id,
        changeType: PlanChangeType.TimelineStrategy,
        name: "Timeline Strategy",
        description: "",
        enabled: true,
      })
    }
    setTimelineStrategyChange(change)
    setName("Timeline Strategy")
    setDescription(change.description)
    setDefaults(model.milestones)

    if (model.persons.length > 0) {
      setPerson1(model.persons[0])
      const timeline = calculator.personTimeline(model.persons[0])
      if (timeline) {
        setOptions1(getOptions(timeline))
      }
    }
    if (model.persons.length > 1) {
      setPerson2(model.persons[1])
      const timeline = calculator.personTimeline(model.persons[1])
      if (timeline) {
        setOptions2(getOptions(timeline))
      }
    }

    let milestones = change.milestones
    if (milestones) {
      const r1 = milestones.get(MilestoneId.retirement1)
      if (r1) {
        setRetirement1(getISODateFromDate(r1.date))
      }

      const r2 = milestones.get(MilestoneId.retirement2)
      if (r2) {
        setRetirement2(getISODateFromDate(r2.date))
      }

      const l1 = milestones.get(MilestoneId.lifeExpectancy1)
      if (l1) {
        setLifeExpectancy1(String(l1.age))
      }

      const l2 = milestones.get(MilestoneId.lifeExpectancy2)
      if (l2) {
        setLifeExpectancy2(String(l2.age))
      }
    }
  }, [plan, open])

  const getOptions = (timeline: IPersonTimeline): Milestone[] => {
    const ages = [62, 63, 64, 65, 66, 67, 68, 69, 70]
    const options: Milestone[] = ages.map((age: number) => {
      let label
      if (age === timeline.earlySocialSecurityAge) {
        label = `${age} (Early Social Security)`
      } else if (age === timeline.fullSocialSecurityAge) {
        label = `${age} (Full Social Security)`
      } else if (age === timeline.delayedSocialSecurityAge) {
        label = `${age} (Delayed Social Security)`
      } else {
        label = String(age)
      }
      const date = addYears(timeline.birthDate, age)
      return new Milestone({
        id: getISODateFromDate(date),
        label: label,
        date: date
      })
    })
    return options
  }

  const handleClose = async (event: any) => {
    if (onClose) {
      onClose()
    }
  }

  const handleSave = async (event: any) => {
    try {
      let change: PlanChange | undefined
      const milestones = []
      if (retirement1) {
        const def = defaults.get(MilestoneId.retirement1)
        milestones.push({
          id: MilestoneId.retirement1,
          label: def?.label,
          date: retirement1
        })
      }
      if (retirement2) {
        const def = defaults.get(MilestoneId.retirement2)
        milestones.push({
          id: MilestoneId.retirement2,
          label: def?.label,
          date: retirement2
        })
      }
      if (lifeExpectancy1 && person1) {
        const age = parseInt(lifeExpectancy1)
        const def = defaults.get(MilestoneId.lifeExpectancy1)
        milestones.push({
          id: MilestoneId.lifeExpectancy1,
          label: def?.label,
          age: age,
          date: getISODateFromDate(addYears(new Date(person1!.birthDate), age))
        })
      }
      if (lifeExpectancy2 && person2) {
        const age = parseInt(lifeExpectancy2)
        const def = defaults.get(MilestoneId.lifeExpectancy2)
        milestones.push({
          id: MilestoneId.lifeExpectancy2,
          label: def?.label,
          age: age,
          date: getISODateFromDate(addYears(new Date(person2!.birthDate), age))
        })
      }

      if (timelineStrategyChange && !timelineStrategyChange.id) {
        const input: CreatePlanChangeInput = {
          accountId: timelineStrategyChange?.accountId,
          userId: timelineStrategyChange.userId,
          modelId: timelineStrategyChange.modelId,
          planId: timelineStrategyChange.planId,
          changeType: timelineStrategyChange.changeType,
          name: name ?? timelineStrategyChange.name,
          description: generateDescription(),
          enabled: !commitChanges,
          details: JSON.stringify(milestones)
        }
        change = await modelStore.createPlanChange(input)
        Tracking.event({action: "Timeline Strategy Created"})
      } else if (timelineStrategyChange) {
        const input: UpdatePlanChangeInput = {
          id: timelineStrategyChange.id,
          name: name,
          description: generateDescription(),
          enabled: !commitChanges,
          details: JSON.stringify(milestones)
        }
        change = await modelStore.updatePlanChange(input)
        Tracking.event({action: "Timeline Strategy Updated"})
      }

      if (change && commitChanges) {
        await change.commit(model, modelStore)
      }

      if (onSave && change) {
        onSave(plan, change)
      } else if (onClose) {
        onClose()
      }
    } catch (err: any) {
      notify.show('error', err.message)
    }
  }

  const generateDescription = () => {
    let array: string[] = []
    if (retirement1) {
      array.push(`${getMilestoneLabel(MilestoneId.retirement1)}: ${isoToLocalDateString(retirement1)}`)
    }
    if (retirement2) {
      array.push(`${getMilestoneLabel(MilestoneId.retirement2)}: ${isoToLocalDateString(retirement2)}`)
    }
    if (lifeExpectancy1) {
      array.push(`${getMilestoneLabel(MilestoneId.lifeExpectancy1)}: ${lifeExpectancy1}`)
    }
    if (lifeExpectancy2) {
      array.push(`${getMilestoneLabel(MilestoneId.lifeExpectancy2)}: ${lifeExpectancy2}`)
    }
    return array.join(", ")
  }

  const getMilestoneLabel = (id: MilestoneId) => {
    const milestone = defaults.get(id)
    if (milestone) {
      return milestone.label
    } else {
      return id
    }
  }

  const getMilestoneHelperText = (id: MilestoneId, prop: 'age' | 'date') => {
    const milestone = defaults.get(id)
    if (milestone) {
      if (prop === 'age') {
        return `Default: ${String(milestone.age)}`
      } else {
        return `Default: ${dateToLocalFormat(milestone.date, "MM/dd/yyyy")}`
      }
    } else {
      return ""
    }
  }

  if (!timelineStrategyChange) {
    return null
  }

  const commitDisabled = !(retirement1 || retirement2 || lifeExpectancy1 || lifeExpectancy2)

  return (
    <ModelEditDialog title="Timeline Strategy" open={isOpen} size="sm"
                     onCancel={handleClose}
                     onSave={handleSave}
    >
      <DialogContentText>
        Adjust retirement dates and life expectancies.
      </DialogContentText>
      <Grid container spacing={2}>
        {person1 &&
        <Grid item xs={12} sm={6}>
          <MilestoneDateFieldValidator
            name="retirement1"
            label={getMilestoneLabel(MilestoneId.retirement1)}
            milestones={options1}
            value={retirement1}
            helperText={getMilestoneHelperText(MilestoneId.retirement1, 'date')}
            sx={{paddingTop:"5px"}}
            onChange={(value: string) => {
              setRetirement1(value)
            }}
          />
            {/*<DatePickerValidator*/}
            {/*  name="retirement1"*/}
            {/*  label={getMilestoneLabel(MilestoneId.retirement1)}*/}
            {/*  value={retirement1}*/}
            {/*  format="MM-dd-yyyy"*/}
            {/*  helperText={getMilestoneHelperText(MilestoneId.retirement1, 'date')}*/}
            {/*  variant="standard"*/}
            {/*  margin="dense"*/}
            {/*  sx={{paddingTop:"6px"}}*/}
            {/*  fullWidth*/}
            {/*  onChange={(newValue: any) => {*/}
            {/*    setRetirement1(newValue)*/}
            {/*  }}*/}
            {/*/>*/}
        </Grid>
        }
        {person1 &&
          <Grid item xs={12} sm={6}>
            <TextFieldValidator
              type="number"
              validators={{ required: false }}
              name="lifeExpectancy1"
              variant="standard"
              margin="dense"
              fullWidth
              label={getMilestoneLabel(MilestoneId.lifeExpectancy1)}
              value={lifeExpectancy1}
              helperText={getMilestoneHelperText(MilestoneId.lifeExpectancy1, 'age')}
              onChange={(event: any) => setLifeExpectancy1(event.target.value)}
            />
          </Grid>
        }
        {person2 &&
          <Grid item xs={12} sm={6}>
            <MilestoneDateFieldValidator
              name="retirement2"
              label={getMilestoneLabel(MilestoneId.retirement2)}
              milestones={options2}
              value={retirement2}
              helperText={getMilestoneHelperText(MilestoneId.retirement2, 'date')}
              sx={{paddingTop:"5px"}}
              onChange={(value: string) => {
                setRetirement2(value)
              }}
            />
            {/*<DatePickerValidator*/}
            {/*  name="retirement2"*/}
            {/*  label={getMilestoneLabel(MilestoneId.retirement2)}*/}
            {/*  value={retirement2}*/}
            {/*  format="MM-dd-yyyy"*/}
            {/*  helperText={getMilestoneHelperText(MilestoneId.retirement1, 'date')}*/}
            {/*  variant="standard"*/}
            {/*  margin="dense"*/}
            {/*  fullWidth*/}
            {/*  sx={{paddingTop:"6px"}}*/}
            {/*  onChange={(newValue: any) => {*/}
            {/*    setRetirement2(newValue)*/}
            {/*  }}*/}
            {/*/>*/}
          </Grid>
        }
        {person2 &&
          <Grid item xs={12} sm={6}>
            <TextFieldValidator
              type="number"
              validators={{ required: false }}
              name="lifeExpectancy2"
              variant="standard"
              margin="dense"
              fullWidth
              label={getMilestoneLabel(MilestoneId.lifeExpectancy2)}
              value={lifeExpectancy2}
              helperText={getMilestoneHelperText(MilestoneId.lifeExpectancy2, 'age')}
              onChange={(event: any) => setLifeExpectancy2(event.target.value)}
            />
          </Grid>
        }
        <Grid item xs={12}>
          <FormControlLabel control={
            <Checkbox checked={commitChanges}
                      disabled={commitDisabled}
                      onChange={(event: any) => {
                        setCommitChanges(event.target.checked)
                      }}
            />}
            label={`Commit these updates to ${model.name} to make them permanent.`}
            sx={{marginTop:1}}
            componentsProps={{
              typography: {
                variant:"body2"
              }}
            }
          />
        </Grid>
      </Grid>
    </ModelEditDialog>
  )
}

export default TimelineStrategyDialog