import React, {useEffect, useState} from "react";
import {Avatar, FormLabel, Grid, Typography, useTheme} from "@mui/material";
import User from "../../model/User";
import ModelCard2 from "../../components/model/ModelCard2";
import {UserIcon} from "../../styles/Icons";
import UserEditDialog from "./UserEditDialog";

const UserCard = ({
  user,
  onSave,
}: {
  user: User
  onSave?(user: User): void
}) => {
  const [userModel, setUserModel] = useState<User>(user)
  const [title, setTitle] = useState<string>("")
  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [editOpen, setEditOpen] = useState<boolean>(false)

  useEffect(() => {
    init(user)
  }, [user])

  const init = (user: User) => {
    setUserModel(user)
    setTitle("User Settings")
    setFirstName(user.firstName)
    setLastName(user.lastName)
    setEmail(user.email)
  }

  const theme = useTheme()

  const styles = {
    heading: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    row: {
      height: 40,
      minHeight: 40,
      maxHeight: 40,
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "1.0em",
      maxHeight: 15,
      marginBottom: "1px",
      textWrap: "nowrap",
      color: theme.palette.grey["700"]
    }
  }

  const renderContent = () => {
    return (
      <Grid container direction="column" spacing={0}>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={6}>
              <FormLabel sx={styles.formLabel}>First Name</FormLabel>
              <Typography variant="body2">
                {firstName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormLabel sx={styles.formLabel}>Last Name</FormLabel>
              <Typography variant="body2">
                {lastName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.row}>
          <FormLabel sx={styles.formLabel}>Email</FormLabel>
          <Typography variant="body2">
            {email}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <ModelCard2
      // icon={<UserIcon/>}
      icon={
        <Avatar sx={{bgcolor: theme.palette.primary.main, fontSize:16, width:30, height:30}}
                alt={user.initials}>
          {user.initials}
        </Avatar>
      }
      title={title}
      expanded={true}
      editButtonId={`edit-btn-${userModel.id}`}
      onEdit={(event: any) => {
        setEditOpen(!editOpen)
      }}
    >
      {renderContent()}
      {editOpen &&
        <UserEditDialog user={userModel}
                        open={editOpen}
                          onSave={(update: User) => {
                            init(update)
                            setEditOpen(false)
                            if (onSave) {
                              onSave(update)
                            }
                          }}
                          onClose={() => {
                            setEditOpen(false)
                          }}/>
      }
    </ModelCard2>
  )
}

export default UserCard