import PlanChange from "../PlanChange";
import Model from "../Model";
import Milestone, {MilestoneId} from "../Milestone";
import {getISODateFromDate} from "../../stores/StoreUtilities";
import ModelStore from "../../stores/ModelStore";
import {UpdatePersonInput} from "../../API";
import Person from "../Person";

class TimelineStrategyChange extends PlanChange {
  private _milestones: Map<string, Milestone>

  constructor (data: any) {
    super(data)
    const items = this.details ? JSON.parse(this.details) : []
    this._milestones = new Map<string, Milestone>(items.map((item: any) => [item.id, new Milestone(item)]))
  }

  get milestones(): Map<string, Milestone> {
    return this._milestones
  }

  set milestones(value: Map<string, Milestone>) {
    this._milestones = structuredClone(value)
    this.details = JSON.stringify(this._milestones.values())
  }

  apply(model: Model) {
    if (this._milestones.size > 0) {
      this._milestones.forEach((milestone: Milestone, key: string) => {
        if (key === MilestoneId.retirement1) {
          const person1 = model.person1
          if (person1) {
            person1.retireDate = getISODateFromDate(milestone.date)
            model.addLock(person1.id, "retireDate")
          }
        } else if (key === MilestoneId.retirement2) {
          const person2 = model.person2
          if (person2) {
            person2.retireDate = getISODateFromDate(milestone.date)
            model.addLock(person2.id, "retireDate")
          }
        } else if (key === MilestoneId.lifeExpectancy1) {
          const person1 = model.person1
          if (person1) {
            person1.hereditaryAdjust = milestone.age
            model.addLock(person1.id, "hereditaryAdjust")
          }
        } else if (key === MilestoneId.lifeExpectancy2) {
          const person2 = model.person2
          if (person2) {
            person2.hereditaryAdjust = milestone.age
            model.addLock(person2.id, "hereditaryAdjust")
          }
        }
      })
      // Recompute model milestones
      model.loadMilestones()
    }
  }

  async commit(model: Model, modelStore: ModelStore) {
    if (this._milestones.size > 0) {
      let person1Update: UpdatePersonInput | undefined = undefined
      let person2Update: UpdatePersonInput | undefined = undefined
      this._milestones.forEach((milestone: Milestone, key: string) => {
        if (key === MilestoneId.retirement1) {
          const person1 = model.person1
          if (person1) {
            person1.retireDate = getISODateFromDate(milestone.date)
            if (!person1Update) {
              person1Update = {
                id: person1.id,
                accountId: person1.accountId
              }
            }
            person1Update.retireDate = getISODateFromDate(milestone.date)
          }
        } else if (key === MilestoneId.retirement2) {
          const person2 = model.person2
          if (person2) {
            person2.retireDate = getISODateFromDate(milestone.date)
            if (!person2Update) {
              person2Update = {
                id: person2.id,
                accountId: person2.accountId
              }
            }
            person2Update.retireDate = getISODateFromDate(milestone.date)
          }
        } else if (key === MilestoneId.lifeExpectancy1) {
          const person1 = model.person1
          if (person1) {
            person1.hereditaryAdjust = milestone.age
            if (!person1Update) {
              person1Update = {
                id: person1.id,
                accountId: person1.accountId
              }
            }
            person1Update.hereditaryAdjust = milestone.age
          }
        } else if (key === MilestoneId.lifeExpectancy2) {
          const person2 = model.person2
          if (person2) {
            person2.hereditaryAdjust = milestone.age
            if (!person2Update) {
              person2Update = {
                id: person2.id,
                accountId: person2.accountId
              }
            }
            person2Update.hereditaryAdjust = milestone.age
          }
        }
      })
      if (person1Update) {
        await modelStore.updatePerson(person1Update)
      }
      if (person2Update) {
        await modelStore.updatePerson(person2Update)
      }

      // Recompute model milestones
      model.loadMilestones()
    }
  }
}

export default TimelineStrategyChange