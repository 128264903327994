import * as React from 'react';
import Slider from '@mui/material/Slider';
import {useEffect, useState} from "react";
import {useTheme} from "@mui/material";

const MonthSlider = ({
  value,
  onChange,
}: {
  value: number
  onChange?(month: number): any
}) => {
  const [current, setCurrent] = useState<number>(value)
  const [marks, setMarks] = useState<Array<{ label?: string, value: number }> | boolean>()

  const theme = useTheme()

  const styles = {
    slider: {
      '& .MuiSlider-thumb': {
        backgroundColor: theme.palette.secondary.main
      },
      '& .MuiSlider-valueLabel': {
        fontSize: 13,
        fontWeight: 500,
        top: 0,
        backgroundColor: 'unset',
        color: theme.palette.primary.main,
        '&:before': {
          display: 'none',
        },
        '& *': {
          background: 'transparent',
          color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary.dark,
        },
      }
    }
  }

  useEffect(() => {
    setCurrent(value)
  }, [value])

  const valuetext = (value: number) => {
    const months = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "Septempter", "October", "November", "December"]
    return months[value];
  }

  return (
      <Slider
        aria-label="Date Slider"
        value={current}
        getAriaValueText={valuetext}
        step={1}
        min={0}
        max={11}
        marks={true}
        size="small"
        color="primary"
        valueLabelDisplay="on"
        valueLabelFormat={valuetext}
        sx={styles.slider}
        onChange={(event: Event, value: number | number[], activeThumb: number) => {
          // console.log(`onChangeSlider(${value}, ${activeThumb}`)
          if (onChange) {
            onChange(value as number)
          }
        }}
      />
  );

}

export default MonthSlider