import Page from '../components/page/Page'
import UserStore from "../stores/UserStore";
import { Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResources } from '../stores/ResourceProvider';
import useNavigationHelper from "../components/NavigationHelper";
import Tracking from "../components/Tracking";

const LogOutPage = () => {

  const { notify, userStore } = useResources()
  const navigate = useNavigate()
  const {navigateHome} = useNavigationHelper()

  useEffect(() => {
    if (notify)
    notify.close()
    if (userStore)
    (userStore as UserStore).signOut().then(() => {
      Tracking.event({action: "User Sign Out"})
      navigateHome()
    })
  }, [navigate, userStore, notify])

  return (
    <Page>
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <CircularProgress /> 
      </Box>
    </Page>
  )
}

export default LogOutPage