import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Box, IconButton} from "@mui/material";
import {ReactElement} from "react";

const StyledMenu = styled((props: any) => (
  <Menu
    open={false}
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 120,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

interface IMenuButtonProps {
  sx?: any
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined
  label?: string
  icon?: ReactElement
  options: any[]
  getOptionLabel(option: any): string
  onChange(option: any): Promise<void>
}

export default function MenuButton(props: IMenuButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{display:"inline-block"}}>
      {props.label &&
        <Button
          id="menu-button"
          aria-controls={open ? 'menu-button-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={props.icon ?? <KeyboardArrowDownIcon />}
          sx={props?.sx}
          color={props.color ? props.color : 'primary'}
        >
          {props.label}
        </Button>
      }
      {!props.label && props.icon &&
        <IconButton
          id="menu-button"
          aria-controls={open ? 'menu-button-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          size="small"
          onClick={handleClick}
          sx={props?.sx}
          color={props.color ? props.color : 'primary'}
        >
          {props.icon}
        </IconButton>
      }
      <StyledMenu
        id="menu-button-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {props.options.map((option: any) =>
          <MenuItem onClick={(event: any) => {handleClose(); props.onChange(option)}} disableRipple>
            {props.getOptionLabel(option)}
          </MenuItem>
        )}
      </StyledMenu>
    </Box>
  );
}
