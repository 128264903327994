import React, {useEffect, useState} from 'react'
import Chart from "react-apexcharts";
import {
  FutureCalc,
  IBudgetCalc,
  IExpenseCategorySummary,
  IFutureYear
} from "../../../../components/calculator/Calculator";
import {numberToMoneyFormat} from "../../../../stores/StoreUtilities";
import {globalColors} from "../../../../styles/globalStyles"
import {Paper, TableContainer} from "@mui/material";
import {ExpenseCategory} from "../../../../model/Expense";
import cloneDeep from "lodash.clonedeep";
import AnnualBudgetTable from "./AnnualBudgetTable";
import {useResources} from "../../../../stores/ResourceProvider";

const defaultOptions = {
  chart: {
    id: "annual-budget",
    type: 'bar',
    height: 350,
    width: "100%",
    zoom: {
      autoScaleYaxis: true
    },
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      }
    },
    toolbar: {
      show: false,
      tools: {
        download: false
      }
    }
  },
  dataLabels: {
    enabled: true,
    formatter: function (value: number) {
      return numberToMoneyFormat(value, 0)
    },
    offsetY: -20,
    style: {
      fontSize: '12px',
      colors: ["#304758"]
    }
  },
  legend: {
    show: false,
    position: 'top',
    horizontalAlign: 'left'
  },
  xaxis: {
    axisBorder: {
      show: true
    }
  },
  yaxis: {
    // max: 15000,
    labels: {
      formatter: (value: number) => {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  tooltip: {
    y: {
      formatter: function (value: number) {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  plotOptions: {
    bar: {
      distributed: true,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    }
  },
  colors: [globalColors.income,
    globalColors.withdrawal,
    globalColors.deductionExpense,
    globalColors.taxExpense,
    globalColors.estimatedTax, globalColors.essentialExpense, globalColors.discretionaryExpense,
    globalColors.expenseCategory[ExpenseCategory.Housing], globalColors.expenseCategory[ExpenseCategory.LoansAndLiabilities],
    globalColors.expenseCategory[ExpenseCategory.FoodAndPersonalCare], globalColors.expenseCategory[ExpenseCategory.InsuranceAndMedical],
    globalColors.expenseCategory[ExpenseCategory.VehiclesAndTransportation],
    globalColors.expenseCategory[ExpenseCategory.TravelAndEntertainment],
    globalColors.expenseCategory[ExpenseCategory.GivingAndMisc]],
  responsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const AnnualBudgetApexChart = ({
  budgetCalc,
  futureYear,
  view,
  height
}: {
  budgetCalc: IBudgetCalc
  futureYear: IFutureYear
  view: 'Annual' | 'MonthlyAvg'
  height?: string
}) => {
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<any>()
  const [data, setData] = useState<any>()
  const [surplus, setSurplus] = useState<number>(0)
  const [divisor, setDivisor] = useState<number>(1)

  const { userStore } = useResources()

  useEffect(() => {
    setDivisor(view === 'MonthlyAvg' ? 12 : 1)
    calculateData()
  }, [budgetCalc, view])

  const calculateData = () => {
    if (!budgetCalc) {
      return
    }

    let taxes = budgetCalc.totalEstimatedTaxes ?  {x: 'Estimated Taxes', y: budgetCalc.totalEstimatedTaxes} :
                                                  {x: 'Tax Withholding', y: budgetCalc.totalTaxes}

    let data = [
      {x: 'Income', y: budgetCalc.totalIncome},
      {x: 'Distributions', y: futureYear.allDistributions},
      {x: 'Deductions & Contributions', y: budgetCalc.totalDeductions},
      taxes,
      // {x: 'Tax Withholding', y: budgetCalc.totalTaxes},
      // {x: 'Estimated Taxes', y: budgetCalc.totalEstimatedTaxes},
      // {x: 'Essential Expenses', y: budgetCalc.totalEssentialExpenses},
      // {x: 'Discretionary Expenses', y: budgetCalc.totalDiscretionaryExpenses},
      {x: 'Housing', y: getExpenseCategoryTotal(ExpenseCategory.Housing)},
      {x: 'Loans & Liabilities', y: getExpenseCategoryTotal(ExpenseCategory.LoansAndLiabilities)},
      {x: 'Food & Personal Care', y: getExpenseCategoryTotal(ExpenseCategory.FoodAndPersonalCare)},
      {x: 'Insurance & Medical', y: getExpenseCategoryTotal(ExpenseCategory.InsuranceAndMedical)},
      {x: 'Vehicles & Transportation', y: getExpenseCategoryTotal(ExpenseCategory.VehiclesAndTransportation)},
      {x: 'Travel & Entertainment', y: getExpenseCategoryTotal(ExpenseCategory.TravelAndEntertainment)},
      {x: 'Givng & Misc', y: getExpenseCategoryTotal(ExpenseCategory.GivingAndMisc)},
    ]
    setData(data)

    let surplus = budgetCalc.totalIncome - budgetCalc.totalDeductions - budgetCalc.totalExpenses
    if (budgetCalc.totalEstimatedTaxes) {
      surplus -= budgetCalc.totalEstimatedTaxes
    } else {
      surplus -= budgetCalc.totalTaxes
    }
    setSurplus(surplus)

    let chartData
    if (divisor === 1) {
      chartData = data
    } else {
      chartData = data.map((item: {x: string, y: number}) => {return ({x: item.x, y: Math.round(item.y / divisor)})})
    }

    const options = cloneDeep(defaultOptions)

    if (userStore.isFree) {
      // Remove Distributions and Estimated taxes
      chartData.splice(4, 1)
      chartData.splice(1, 1)
      options.colors.splice(4, 1)
      options.colors.splice(1, 1)
    }

    const series = [
      {
        name: "Annual Budget",
        data: chartData
      },
    ]
    setSeries(series)

    if (height) {
      options.chart.toolbar.show = false
    }

    setOptions(options)
  }

  const getExpenseCategoryTotal = (category: ExpenseCategory) => {
    let total = 0
    if (budgetCalc) {
      const summary = budgetCalc.expenseSummary.byCategory.find((summary: IExpenseCategorySummary) => summary.category === category)
      if (summary) {
        total = summary.total
      }
    }
    return total
  }

  if (options && series) {
    return (
      <React.Fragment>
        <TableContainer component={Paper} sx={{paddingTop:1, overflowY:"hidden"}}>
          <Chart
            options={options}
            series={series}
            type="bar"
            height={height ?? "500"}
            width="100%"
          />
        </TableContainer>

        {!height &&
          <AnnualBudgetTable year={budgetCalc.year} data={data} surplus={surplus}/>
        }
      </React.Fragment>
    )
  } else {
    return null
  }
}

export default AnnualBudgetApexChart