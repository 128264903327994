import Person from "../../../../model/Person";
import {Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow} from "@mui/material";
import {useEffect, useState} from "react";
import {
  addMonths,
  addYears,
  compareDesc,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  startOfToday
} from "date-fns";
import {dateToLocalFormat, isoToLocalDate} from "../../../../stores/StoreUtilities";
import {Gender} from "../../../../API";
import {globalColors} from "../../../../styles/globalStyles";

const CountdownWidget = ({
  persons
} : {
  persons: Person[]
}) => {
  const [person1, setPerson1] = useState<Person | undefined>()
  const [person2, setPerson2] = useState<Person | undefined>()
  const [label1, setLabel1] = useState<string>("")
  const [label2, setLabel2] = useState<string>("")
  const [countdown1, setCountdown1] = useState<number[]>([0,0,0])
  const [countdown2, setCountdown2] = useState<number[]>([0,0,0])

  useEffect(() => {
    if (persons.length > 0) {
      let date = startOfToday()
      let person = persons[0]
      setPerson1(person)
      if (person.retireDate) {
        const retireDate = isoToLocalDate(person.retireDate)
        setLabel1(`${person.nickname} (${dateToLocalFormat(retireDate)})`)
        if (compareDesc(retireDate, date) < 0) {
          let years = differenceInYears(retireDate, date)
          date = addYears(date, years)
          let months = differenceInMonths(retireDate, date)
          date = addMonths(date, months)
          let days = differenceInDays(retireDate, date)
          setCountdown1([years, months, days])
        }
      } else {
        setLabel1(person.nickname)
      }

      if (persons.length > 1) {
        date = startOfToday()
        let person = persons[1]
        setPerson2(person)
        if (person.retireDate) {
          const retireDate = isoToLocalDate(person.retireDate)
          setLabel2(`${person.nickname} (${dateToLocalFormat(retireDate)})`)
          if (compareDesc(retireDate, date) < 0) {
            let years = differenceInYears(retireDate, date)
            date = addYears(date, years)
            let months = differenceInMonths(retireDate, date)
            date = addMonths(date, months)
            let days = differenceInDays(retireDate, date)
            setCountdown2([years, months, days])
          }
        } else {
          setLabel2(person.nickname)
        }
      }
    }
  },[persons])

  // const theme = useTheme()

  const styles = {
    table: {
      borderCollapse: "separate",
      borderSpacing: 5,
      maxHeight: 124,
      [`& .${tableCellClasses.root}`]: {
        borderBottom: "none"
      }
    },
    thName: {
      fontSize: 9,
      fontWeight: 600,
      maxWidth: 100,
      height: 10,
      textAlign: "right",
      padding: "0 20px 0 0"
    },
    thTime: {
      width: "48px",
      fontSize: 9,
      fontWeight: 600,
      height: 10,
      textAlign: "center",
      padding: 0
    },
    tdName: {
      fontSize: 14,
      fontWeight: 600,
      maxHeight: "32px",
      textAlign: "right",
      verticalAlign: "middle",
      paddingRight: "20px"
    },
    tdTimeMale: {
      width: "48px",
      height: "32px",
      maxHeight: "32px",
      fontSize: 30,
      fontWeight: 800,
      color: "white",
      backgroundColor: globalColors.maleGender,
      padding: "0px",
      textAlign: "center"
    },
    tdTimeFemale: {
      width: "48px",
      height: "32px",
      maxHeight: "32px",
      fontSize: 30,
      fontWeight: 800,
      color: "white",
      backgroundColor: globalColors.femaleGender,
      padding: "0px",
      textAlign: "center"
    }

  }

  let person1Style = (person1 && person1.gender === Gender.Female) ? styles.tdTimeFemale : styles.tdTimeMale
  let person2Style = (person2 && person2.gender === Gender.Female) ? styles.tdTimeFemale : styles.tdTimeMale

  return (
    <Table sx={styles.table}>
      <TableHead>
        <TableRow>
          <TableCell sx={styles.thName}>NAME</TableCell>
          <TableCell sx={styles.thTime}>YEARS</TableCell>
          <TableCell sx={styles.thTime}>MONTHS</TableCell>
          <TableCell sx={styles.thTime}>DAYS</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {person1 &&
          <TableRow>
            <TableCell sx={styles.tdName}>{label1}</TableCell>
            <TableCell sx={person1Style}>{countdown1[0]}</TableCell>
            <TableCell sx={person1Style}>{countdown1[1]}</TableCell>
            <TableCell sx={person1Style}>{countdown1[2]}</TableCell>
          </TableRow>
        }
        {person2 &&
          <TableRow>
            <TableCell sx={styles.tdName}>{label2}</TableCell>
            <TableCell sx={person2Style}>{countdown2[0]}</TableCell>
            <TableCell sx={person2Style}>{countdown2[1]}</TableCell>
            <TableCell sx={person2Style}>{countdown2[2]}</TableCell>
          </TableRow>
        }
      </TableBody>
    </Table>
  )
}

export default CountdownWidget