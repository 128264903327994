import React, {useEffect, useState} from "react";
import {Paper, TableContainer, useTheme} from "@mui/material";
import {numberToMoneyFormat} from "../../../../stores/StoreUtilities";
import {FutureCalc} from "../../../../components/calculator/Calculator";
import {globalColors} from "../../../../styles/globalStyles";
import cloneDeep from "lodash.clonedeep";
import Chart from "react-apexcharts";

const defaultOptions = {
  chart: {
    id: "taxable-income-estimate-chart",
    type: 'bar',
    stacked: true,
    height: 350,
    width: "100%",
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      }
    },
    toolbar: {
      show: false,
      tools: {
        download: false
      }
    }
  },
  title: {
    text: "",
    align: "right",
    floating: true,
    margin: 0,
    style: {
      fontSize: "16px",
      fontFamily: "Poppins,sans-serif",
      color: "rgb(31, 92, 167)"
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    showForSingleSeries: true,
    onItemClick: {
      toggleDataSeries: false
    }
  },
  xaxis: {
    type: 'number',
    min: 2000,
    axisBorder: {
      show: true
    }
  },
  yaxis: {
    labels: {
      formatter: (val: number) => {
        return val / 1000 + 'K'
      }
    }
  },
  tooltip: {
    y: {
      formatter: function (value: number) {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  // colors: [globalColors.taxable, globalColors.taxDeferredRMD, globalColors.taxDeferredElective, globalColors.taxFree, globalColors.taxExpense],
  resonsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const LifetimeEstimatedTaxesChart = ({
  year,
  futureCalc,
  onChange,
  height,
  width,
  title
} : {
  year: number
  futureCalc: FutureCalc
  onChange?: (year: number) => any
  height?: string
  width?: string
  title?: string
}) => {
  const [startYear, setStartYear] = useState<number>(0)
  const [endYear, setEndYear] = useState<number>(0)
  const [calc, setCalc] = useState<FutureCalc | undefined>()
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<any>()
  const [currentYear, setCurrentYear] = useState<number>(0)

  const theme = useTheme()

  useEffect(() => {
    if (futureCalc.futureYears.length > 0) {
      const start = futureCalc.futureYears[0].year
      setStartYear(start)
      setEndYear(futureCalc.futureYears[futureCalc.futureYears.length-1].year)
      setCurrentYear(year)
      setCalc(futureCalc)
    }
  }, [year, futureCalc])

  useEffect(() => {
    if (startYear > 0 && calc && calc.futureYears.length > 0) {
      calculateData(startYear)
    }
  }, [currentYear, calc])

  const calculateData = (startYear: number) => {
    if (!calc || startYear <= 0 || endYear < startYear) {
      return
    }

    let estTaxData = []

    for (let index = 0; index < calc.futureYears.length; index++) {
      const year = startYear + index
      const fy = calc.futureYears[index]
      const taxInput = fy.taxInput
      const taxEst = fy.taxEstimate
      if (!taxInput || !taxEst) {
        continue
      }
      estTaxData.push({x: year, y: taxEst.taxLiability})
    }

    const series = [
      {
        name: "Estimated Federal Income Tax",
        group: "Taxable Income",
        data: estTaxData,
        color: globalColors.estimatedTax
      },
    ]
    // Filter to only series that have non-zero data values
    const filtered = series.filter((s: any) => {
      return s.data.findIndex((d: any) => d.y !== 0) >= 0
    })
    setSeries(filtered)

    const newOptions = cloneDeep(defaultOptions)
    newOptions.title.text = title ?? ""
    newOptions.xaxis.min = startYear
    if (height) {
      newOptions.chart.toolbar.show = false
    }

    if (currentYear !== undefined) {
      const annotations = {
        xaxis: [
          {
            x: currentYear,
            borderColor: theme.palette.secondary.main, //'#00E396',
            label: {
              borderColor: theme.palette.secondary.main, //'#00E396',
              orientation: 'horizontal',
              text: currentYear.toString(),
              style: {
                fontSize: '14px',
                fontWeight: 600
              }
            }
          }
        ]
      }
      newOptions["annotations"] = annotations
      newOptions.chart.events["dataPointSelection"] = (event: any, chartContext: any, details: {dataPointIndex: number, seriesIndex: number}) => {
        if (details.dataPointIndex >= 0 && onChange) {
          onChange(details.dataPointIndex + startYear)
        }
      }
    }

    setOptions(newOptions)
  }

  // const handleChange = (year: number) => {
  //   setCurrentYear(year)
  //   if (onChange) {
  //     onChange(year)
  //   }
  // }

  if (options && series) {
    return (
      <TableContainer component={Paper} sx={{paddingTop:1, overflowY:"hidden"}}>
        <Chart
          options={options}
          series={series}
          type="bar"
          height={height ?? "500"}
          width={width ?? "100%"}
        />
      </TableContainer>
    )
  } else {
    return null
  }
}

export default LifetimeEstimatedTaxesChart