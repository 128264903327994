import Tax, {TaxType} from "../../../model/Tax";
import {Box, IconButton, InputAdornment} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import FormValidator from "../../../components/form/FormValidator";
import TextFieldValidator from "../../../components/form/TextFieldValidator";
import {
  humanizeString, moneyToNumberFormat, numberToMoneyFormat, numberToPercentFormat
} from "../../../stores/StoreUtilities";
import {CreateTaxInput, UpdateTaxInput} from "../../../API";
import {useResources} from "../../../stores/ResourceProvider";
import Model from "../../../model/Model";
import Schedule, {Frequency} from "../../../model/Schedule";
import ScheduleSelector from "../../../components/controls/ScheduleSelector";
import FormGroupSpacer from "../../../components/form/FormGroupSpacer";
import MilestoneDateFieldValidator from "../../../components/form/MilestoneDateFieldValidator";
import ModelEditDialog from "../../../components/model/ModelEditDialog";
import {isNumber} from "../../../model/ModelUtilities";
import Tracking from "../../../components/Tracking";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const TaxEditDialog = ({
  open,
  tax,
  model,
  onClose,
  onSave,
  onDelete
}: {
  open? : boolean
  tax: Tax
  model: Model
  onClose?(): void
  onSave?(update: Tax): void
  onDelete?(expense: Tax): void
}) => {
  const [taxModel, setTaxModel] = useState<Tax>(tax)
  const [description, setDescription] = useState<string>("")
  const [amount, setAmount] = useState<string | undefined>()
  const [taxType, setTaxType] = useState<TaxType | undefined>()
  const [owner, setOwner] = useState<string | undefined>()
  const [schedule, setSchedule] = useState<Schedule | undefined>()
  const [start, setStart] = useState<string>()
  const [end, setEnd] = useState<string>()
  const [annualInf, setAnnualInf] = useState<string | undefined>()
  const [annualInfLock, setAnnualInfLock] = useState<"Model" | "User" | undefined>()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { modelStore, userStore, notify } = useResources()

  // const styles = {
  //   actions: {
  //     display:"flex",
  //     flexDirection:"row",
  //     width:"100%",
  //     justifyContent:"space-between",
  //     alignItems:"center",
  //     marginTop: theme.spacing(1)
  //   },
  //   row: {
  //     maxHeight: 48
  //   },
  //   formLabel: {
  //     fontSize: 12,
  //     fontWeight: 400,
  //     color: theme.palette.grey["700"]
  //   },
  //   stateField: {
  //     top: 5,
  //     width: "100%"
  //   }
  // }

  useEffect(() => {
    setTaxModel(tax)
    setDescription(tax.description)
    setAmount(numberToMoneyFormat(tax.amount, 0))
    setTaxType(tax.taxType)
    const person = tax.ownerId ? model.getPerson(tax.ownerId) : undefined
    setOwner(person ? person.nickname : model.getDefaultPersonNickname())
    setSchedule(tax.schedule ?? new Schedule({name: "Monthly", frequency: Frequency.Monthly, interval: 1}))
    setStart(tax.start)
    setEnd(tax.end)
    setAnnualInf(isNumber(tax.annualInf) ? numberToPercentFormat(tax.annualInf, 2) : "")
    if (tax.infLock) {
      setAnnualInfLock("User")
    } else if (model.hasLock(tax.id, "annualInf")) {
      setAnnualInfLock("Model")
    }
    setIsOpen(open === true)
  }, [tax, model, open])

  const handleClose = async (event: any) => {
    if (onClose) {
      onClose()
    }
  }

  const handleSave = async (event: any) => {
    try {
      const person = model.getPersonByNickname(owner)
      let updatedTax: Tax | undefined
      const amountValue = moneyToNumberFormat(amount, 0) ?? 0

      if (!taxModel.createdAt) {
        const input: CreateTaxInput = {
          id: taxModel.id,
          accountId: taxModel.accountId,
          userId: taxModel.userId,
          modelId: taxModel.modelId,
          description: description,
          amount: amountValue,
          taxType: taxType,
          schedule: JSON.stringify(schedule),
          start: start,
          end: end ?? null,
          annualInf: annualInf !== undefined ? parseFloat(annualInf) / 100.0 : null,
          infLock: annualInfLock === "User",
          ownerId: person ? person.id : null,
          sortOrder: taxModel.sortOrder
        }

        updatedTax = await modelStore.createTax(input)
        Tracking.event({action: "Tax Created"})
      } else {
        const input: UpdateTaxInput = {
          id: taxModel.id,
          accountId: taxModel.accountId,
          userId: taxModel.userId,
          modelId: taxModel.modelId,
          description: description,
          amount: amountValue,
          taxType: taxType,
          schedule: JSON.stringify(schedule),
          start: start,
          end: end ?? null,
          annualInf: annualInf !== undefined ? parseFloat(annualInf) / 100.0 : null,
          infLock: annualInfLock === "User",
          ownerId: person ? person.id : null
        }

        if (annualInfLock === "Model") {
          delete input.annualInf
        }

        updatedTax = await modelStore.updateTax(input)
        Tracking.event({action: "Tax Updated"})
      }

      if (onSave && updatedTax) {
        onSave(updatedTax)
      } else if (onClose) {
        onClose()
      }
    } catch (err: any) {
      notify.show('error', err.message)
    }
  }

  const handleDelete = async () => {
    if (onDelete) {
      if (taxModel.createdAt) {
        const deleted = await modelStore.deleteTax(taxModel.id)
        if (deleted) {
          onDelete(deleted)
        }
      } else {
        onDelete(taxModel)
      }
    }
  }

  const endDateHelperText = "Default: Owner's Life Expectancy"

  const handleInfLock = (typename: string) => {
    if (annualInfLock === "User") {
      if (model.getOverrideInflationLock(typename)) {
        setAnnualInfLock("Model")
        setAnnualInf(numberToPercentFormat(model.getDefaultInflationRate(typename)))
      } else {
        setAnnualInfLock(undefined)
      }
    } else if (annualInfLock === "Model") {
      setAnnualInfLock("User")
    } else if (annualInfLock === undefined) {
      setAnnualInfLock("User")
    }
  }

  const renderAnnualInfField = (typename: string) => {
    let endAdornment
    let helperText
    let disabled = false
    if (userStore.isFree) {
      helperText = `Default: ${Model.defaultInflationRate}`
    } else if (annualInfLock === "User") {
      endAdornment = (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle inflation lock"
            onClick={() => handleInfLock(typename)}
            edge="end"
          >
            <LockPersonIcon/>
          </IconButton>
        </InputAdornment>
      )
      helperText = "Locked by User"
    } else if (annualInfLock === "Model") {
      endAdornment = (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle inflation lock"
            onClick={() => handleInfLock(typename)}
            edge="end"
          >
            <LockIcon/>
          </IconButton>
        </InputAdornment>
      )
      helperText = "Locked by Inflation Strategy"
      disabled = true
    } else {
      endAdornment = (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle inflation lock"
            onClick={() => handleInfLock(typename)}
            edge="end"
          >
            <LockOpenIcon/>
          </IconButton>
        </InputAdornment>
      )
      helperText = `Default: ${numberToPercentFormat(model.getDefaultInflationRate(typename), 2)}`
    }

    return (
      <TextFieldValidator
        type="string"
        validators={{ required: false, minValue: 0 }}
        name="annualInf"
        variant="standard"
        margin="dense"
        fullWidth
        label="Annual Inflation %"
        value={annualInf}
        InputProps={{
          endAdornment: endAdornment
        }}
        helperText={helperText}
        disabled={disabled}
        onChange={(event: any) => setAnnualInf(event.target.value)}
      />
    )
  }

  if (!taxModel || !isOpen) {
    return null
  }

  return (
    <ModelEditDialog title="Edit Tax Withholding" open={isOpen}
                     onCancel={handleClose}
                     onSave={handleSave}
                     onDelete={handleDelete}
    >
      <TextFieldValidator
        margin="normal"
        name="description"
        label="Title"
        type="text"
        fullWidth
        variant="standard"
        required
        validators={{ required: true }}
        value={description}
        onChange={(event: any) => setDescription(event.target.value)}
      />
      <TextFieldValidator
        type="string"
        validators={{ isMoney: true }}
        name="amount"
        variant="standard"
        margin="dense"
        fullWidth
        label="Amount"
        value={amount}
        onChange={(event: any) => setAmount(event.target.value)}
      />
      <ScheduleSelector
        value={schedule!}
        startDate={model.getDate(start)}
        onChange={(value: Schedule) => setSchedule(value)}
      />
      <TextFieldValidator
        type="text"
        validators={{ required: true }}
        required
        name="owner"
        label="Owner"
        variant="standard"
        autocompleteOptions={{
          freeSolo: false,
          options: model.getPersonNicknames(),
          value: owner,
          onChange: (event: any, value: string, reason: any) => {
            setOwner(value)
          }
        }}
      />
      <TextFieldValidator
        type="text"
        validators={{ required: true }}
        required
        name="taxType"
        label="Tax Type"
        variant="standard"
        autocompleteOptions={{
          freeSolo: false,
          options: Object.values(TaxType).filter((v) => !isNaN(Number(v))),
          getOptionLabel: (option: number) => {
            // return "Label"
            return(humanizeString(TaxType[option]))
          },
          value: taxType,
          onChange: (event: any, value: number, reason: any) => {
            setTaxType(value)
          }
        }}
      />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <MilestoneDateFieldValidator
          name="start"
          label="Start Date"
          model={model}
          value={start}
          width="50%"
          onChange={(value: string) => setStart(value)}
        />
        <FormGroupSpacer/>
        <MilestoneDateFieldValidator
          name="end"
          label="End Date"
          model={model}
          value={end}
          width="50%"
          helperText={endDateHelperText}
          onChange={(value: string) => setEnd(value)}
        />
      </Box>
      {renderAnnualInfField("tax")}
    </ModelEditDialog>
  )
}

export default TaxEditDialog