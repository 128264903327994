import * as React from "react";
import {inject} from "mobx-react";
import {IFieldValidator} from "./FormValidator";
import {
  DatePicker,
  DatePickerProps,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {FormControlProps} from "@mui/material/FormControl";
import {Box} from "@mui/material";

// TODO: When needed
// interface IDatePickerValidations {
//   required?: boolean
//   minDate?: string
//   maxDate?: string
// }

interface IDatePickerValidatorProps {
  name: string
  // error?: boolean
  helperText?: React.ReactNode
  label?: React.ReactNode
  // inputRef?: any
  required?: boolean
  // validators?: IDatePickerValidations
  // errorMessages?: any
  // onBlur?: any // React.EventHandler<any>
  onChange?: any // React.EventHandler<any>
  formvalidator?: any
  validate?: any
  value: Date | null | undefined
  format: string
  disableToolbar?: boolean
  variant?: "standard" | "filled" | "outlined"
  // placeholder?: string
  margin?: 'dense' | 'none' | 'normal'
  fullWidth?: boolean
  // autoOk?: boolean
  id?: string
  minDate?: Date
  maxDate?: Date
  sx?: any
  // renderInput?: any
}

export type DatePickeralidatorProps = DatePickerProps<Date> & IDatePickerValidatorProps & IFieldValidator


@inject("formvalidator")
class DatePickerValidator extends React.Component<DatePickeralidatorProps > {

  state = {
    isValid: true,
    date: null,
    errorMessage: ""
  }

  componentDidMount() {
    const {formvalidator, value} = this.props as IDatePickerValidatorProps
    if (formvalidator) {
      formvalidator.attachToForm(this)
    }
    this.setState({isValid: true, date: value, errorMessage: ""})
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.value && this.props.value !== prevProps.value) {
      this.setState({date: this.props.value})
    }
  }


  render() {
    const {
      // inputRef,
      // error,
      // onBlur,
      onChange,
      // value,
      // required,
      // renderInput,
      // variant,
      fullWidth,
      helperText,
      sx,
      ...rest
    } = this.props as IDatePickerValidatorProps;

    return (
      <Box sx={sx}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            {...rest}
            value={this.state.date}
            onChange={this.onChange}
            slotProps={{
              textField: {
                variant: "standard",
                InputProps: {
                  fullWidth: fullWidth,
                  onBlur: this.onBlur,
                },
                // fullWidth: {fullWidth},
                onBlur: this.onBlur,
                error: !this.state.isValid,
                helperText: (!this.state.isValid && this.state.errorMessage) || helperText
              },
              actionBar: { actions: ["clear", "today", "accept"] }
            }}
          />
        </LocalizationProvider>
      </Box>
    )
  }

  private onBlur = (event: any): void => {
    const { value } = event.currentTarget;
    const { props } = this
    const { onBlur } = props as FormControlProps

    this.validate(value)

    if (onBlur) {
      onBlur(event);
    }
  }

  private onChange = (value: Date | null, context: any): void => {
    const { props } = this
    const { onChange } = props // as FormControlProps

    // console.log(`onChange(${date?.toLocaleString()}, ${date})`)
    this.validate(value ? value.toDateString() : null)

    if (onChange) {
      onChange(value)
    }
  }

  validate = (value: string | null | undefined): boolean => {
    const { required } = this.props
    let isValid = true
    let errorMessage = ""
    if (required && !value) {
      isValid = false
      errorMessage = `${this.props.label} is required`
    }

    this.setState({isValid, errorMessage})

    return isValid
  }

}

export default DatePickerValidator