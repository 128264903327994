import BasicMenuButton from "./BasicMenuButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Box, IconButton, Typography, useMediaQuery, useTheme} from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import SettingsIcon from '@mui/icons-material/SettingsOutlined';

const ViewMenu = ({
  view,
  options,
  variant,
  onChange,
  onSettings
}: {
  view: number
  options: string[]
  variant?: 'popup' | 'tab' | 'auto'
  onChange?(value: number): void
  onSettings?(view: number): void
}) => {
  const theme = useTheme()

  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const useTabMenu = variant === 'tab' || (variant === 'auto' && mdUp)

  const styles = {
    tab: {
      marginRight: 2,
      paddingTop:0,
      paddingBottom: 0,
      color: theme.palette.text.secondary,
      fontSize: 16,
      lineHeight: 1.0,
      // maxHeight: 24
    },
    selectedTab: {
      marginRight: 2,
      paddingTop:0,
      paddingBottom: 0,
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1.0,
      // maxHeight: 24
    }
  }

  if (useTabMenu) {
    return (
      <Box display="flex" justifyContent="stretch">
        <Box display="flex" flexGrow={1} justifyContent="flex-start" maxHeight={30}>
          {options.map((option: string, index: number) =>
              <Button variant="text" size="medium" key={`view-${index}`}
                      sx={index === view ? styles.selectedTab : styles.tab}
                      onClick={(event: any) => {
                        if (onChange) {
                          onChange(index)
                        }
                      }}>{options[index]}
              </Button>
            )}
        </Box>
        {onSettings &&
          <Box display="flex" flexGrow={0} width={30} ml={1} mr={1}>
            <IconButton
              aria-label="Button"
              color="primary"
              sx={{height:24, width:24, padding:0 }}
              onClick={() => onSettings(view)}
            >
              <SettingsIcon/>
            </IconButton>
          </Box>
        }
      </Box>
    )
  } else {
    return (
      <BasicMenuButton
        icon={<ExpandMoreIcon sx={{color: theme.palette.primary.main}}/>}
        label={
          <Typography variant="h3" color="primary" sx={{padding:0, margin:0}}>
            {options[view]}
          </Typography>
        }
        labelPosition="left"
        options={options}
        value={view}
        onClickOption={(index: number) => {
          if (onChange) {
            onChange(index)
          }
        }}
      />
    )
  }
}

export default ViewMenu