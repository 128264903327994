import Plan from "../../../model/Plan";
import Model from "../../../model/Model";
import PlanChange, {PlanChangeType} from "../../../model/PlanChange";
import * as React from "react";
import {ReactElement, ReactNode, useEffect, useState} from "react";
import {
  Box,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  useTheme
} from "@mui/material";
import {useResources} from "../../../stores/ResourceProvider";
import Asset, {AssetCategory} from "../../../model/Asset";
import ModelEditDialog from "../../../components/model/ModelEditDialog";
import AssetCard from "../networth/AssetCard";
import {AddCircleRounded} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import IconicButton from "../../../components/controls/IconicButton";
import {CreatePlanChangeInput, UpdatePlanChangeInput} from "../../../API";
import DraggableList from "../../../components/controls/DraggableList";
import {DragDropContext, DraggableProvided, DroppableProvided, DropResult} from "react-beautiful-dnd";
import {arrayMove} from "../../../stores/StoreUtilities";
import {WithdrawalGroup, WithdrawalStrategyType} from "../../../model/WithdrawalStrategy";
import WithdrawalStrategyChange from "../../../model/changes/WithdrawalStrategyChange";
import Snapshot from "../../../model/Snapshot";
import Tracking from "../../../components/Tracking";

const WithdrawalStrategyDialog = ({
  open,
  plan,
  model,
  onClose,
  onSave
}: {
  open? : boolean
  plan: Plan
  model: Model
  onClose?(): void
  onSave?(plan: Plan, update: PlanChange): void
}) => {
  const [planModel, setPlanModel] = useState<Plan>(plan)
  const [isOpen, setIsOpen] = useState<boolean>(open === true)
  const [liquidAssets, setLiquidAssets] = useState<Asset[]>([])
  const [snapshot, setSnapshot] = useState<Snapshot | undefined>()
  const [withdrawalStrategyChange, setWithdrawalStrategyChange] = useState<WithdrawalStrategyChange | undefined>()
  const [withdrawalStrategyType, setWithdrawalStrategyType] = useState<WithdrawalStrategyType>(WithdrawalStrategyType.Custom)
  const [withdrawalGroups, setWithdrawalGroups] = useState<WithdrawalGroup[]>([])
  const [reservedGroup, setReservedGroup] = useState<WithdrawalGroup | undefined>()
  const [customGroups, setCustomGroups] = useState<WithdrawalGroup[]>([])

  const theme = useTheme()
  const styles = {
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"]
    },
  }

  const {modelStore, calculator, notify} = useResources()

  useEffect(() => {
    setPlanModel(plan)
    const assets = model.assets.filter((a: Asset) => a.assetCategory === AssetCategory.LiquidInvestableAssets)
    const reserved = [...assets]
    setLiquidAssets(assets)
    setSnapshot(model.snapshots[0])  // Current snapshot
    let change: WithdrawalStrategyChange = plan.getChange(PlanChangeType.WithdrawalStrategy) as WithdrawalStrategyChange
    if (!change) {
      change = new WithdrawalStrategyChange({
        accountId: plan.accountId,
        userId: model.userId,
        modelId: model.id,
        planId: plan.id,
        changeType: PlanChangeType.WithdrawalStrategy,
        name: "Withdrawal Strategy",
        description: "",
        enabled: true,
        details: JSON.stringify({
          withdrawalStrategyType: WithdrawalStrategyType.Conventional
        })
      })
      setReservedGroup(getReservedWithdrawalGroup([]))
      const custom = getCustomWithdrawalGroups(assets)
      const customOrder = assets.findIndex((a: Asset) => a.withdrawalOrder > 0) >= 0
      setCustomGroups(custom)
      setWithdrawalGroups(getDefaultWithdrawalGroups(assets))
      if (change.id === undefined && customOrder) {
        // Default to Custom if there is an existing withdrawal order
        // handleChangeWithdrawalStrategy(WithdrawalStrategyType.Custom, assets)
        change.withdrawalStrategyType = WithdrawalStrategyType.Custom
      }
    } else {
      // Assign assets to group
      change.withdrawalGroups.forEach((group: WithdrawalGroup) => {
        group.assets = []
        group.assetIds.forEach((id: string) => {
          const asset = assets.find((a: Asset) => a.id === id)
          if (asset) {
            group.assets.push(asset)
            // Remove from reserved
            const index = reserved.findIndex((a: Asset) => a.id === asset.id)
            if (index >= 0) {
              reserved.splice(index, 1)
            }
          }
        })
      })
      if (change.withdrawalStrategyType === WithdrawalStrategyType.Custom) {
        setCustomGroups(change.withdrawalGroups)
        setWithdrawalGroups(getDefaultWithdrawalGroups(assets, reserved))
      } else {
        setWithdrawalGroups(change.withdrawalGroups)
        setCustomGroups(getCustomWithdrawalGroups(assets, reserved))
      }
      setReservedGroup(getReservedWithdrawalGroup(reserved))
    }
    setWithdrawalStrategyChange(change)
    setWithdrawalStrategyType(change.withdrawalStrategyType)
    setIsOpen(open === true)
  }, [plan, open])

  const getDefaultWithdrawalGroups = (liquidAssets: Asset[], reserved: Asset[] = []) => {
    const groups: WithdrawalGroup[] = [
      {name: "Taxable", withdrawalOrder: 1, assetIds: [], assets: []},
      {name: "Tax-deferred", withdrawalOrder: 2, assetIds: [], assets: []},
      {name: "Tax-free", withdrawalOrder: 3, assetIds: [], assets: []},
      // {name: "Other", withdrawalOrder: 4, assetIds: [], assets: []},
    ]

    liquidAssets.forEach((a: Asset) => {
      if (reserved.findIndex((e: Asset) => e.id === a.id) < 0) {
        const typeDef = a.assetTypeDef
        let group
        if (typeDef.taxable) {
          group = groups[0]
        } else if (typeDef.taxDeferred) {
          group = groups[1]
        } else if (typeDef.taxFree) {
          group = groups[2]
        }
        if (group) {
          group.assets.push(a)
        }
      }
    })

    // Sort within groups
    groups.forEach((group: WithdrawalGroup) => {
      Model.sortAssets(group.assets)
    })

    return groups
  }

  const getReservedWithdrawalGroup = (assets: Asset[]) => {
    return new WithdrawalGroup({name: "Reserved", withdrawalOrder: 4, assetIds: [], assets: assets})
  }

  const handleChangeWithdrawalStrategy = (strategyType: WithdrawalStrategyType, assets: Asset[]) => {
    if (strategyType === WithdrawalStrategyType.Custom) {
      const custom = getCustomWithdrawalGroups(assets)
      const reserved = [...liquidAssets]
      custom.forEach((group: WithdrawalGroup) => {
        group.assets.forEach((a: Asset) => {
          const index = reserved.findIndex((r: Asset) => r.id === a.id)
          if (index >= 0) {
            // Remove from reserved
            reserved.splice(index, 1)
          }
        })
      })
      setCustomGroups(custom)
      setReservedGroup(getReservedWithdrawalGroup(reserved))
    }
    setWithdrawalStrategyType(strategyType)
  }

  const handleClose = async (event: any) => {
    if (onClose) {
      onClose()
    }
  }

  const getCustomWithdrawalGroups = (assets: Asset[], reserved: Asset[] = []) => {
    // Create groups for each withdrawalOrder
    let groups: WithdrawalGroup[] = []
    const otherGroups: WithdrawalGroup[] = []
    assets.forEach((a: Asset) => {
      if (reserved.findIndex((e: Asset) => e.id === a.id) < 0) {
        if (a.withdrawalOrder === 0) {
          let group = otherGroups.find((g: WithdrawalGroup) => g.withdrawalOrder === a.assetTypeDef.withdrawalOrder)
          if (group) {
            group.assets.push(a)
          } else {
            otherGroups.push(new WithdrawalGroup({name: `#${a.assetTypeDef.withdrawalOrder}`, withdrawalOrder: a.assetTypeDef.withdrawalOrder, assets: [a]}))
          }
        } else {
          let group = groups.find((g: WithdrawalGroup) => g.withdrawalOrder === a.withdrawalOrder)
          if (group) {
            group.assets.push(a)
          } else {
            groups.push(new WithdrawalGroup({name: (a.withdrawalOrder > 0 ? `#${a.withdrawalOrder}` : 'Default'), withdrawalOrder: a.withdrawalOrder, assets: [a]}))
          }
        }
      }
    })

    // Sort groups by withdrawal Order
    groups.sort((a: WithdrawalGroup, b: WithdrawalGroup) => a.withdrawalOrder - b.withdrawalOrder)
    // Apply numeric names
    groups.forEach((g: WithdrawalGroup, index: number) => {
      g.withdrawalOrder = index + 1
      g.name = `#${g.withdrawalOrder}`
    })
    let baseWithdrawalOrder = groups.length > 0 ? groups[groups.length-1].withdrawalOrder + 1 : 1
    // Sort otherGroups by typedef withdrawal Order
    otherGroups.sort((a: WithdrawalGroup, b: WithdrawalGroup) => a.withdrawalOrder - b.withdrawalOrder)
    otherGroups.forEach((g: WithdrawalGroup, index: number) => {
      g.withdrawalOrder = baseWithdrawalOrder + index
      g.name = `#${g.withdrawalOrder}`
    })
    // Add otherGroups to the end of the groups
    groups = [...groups, ...otherGroups]
    return groups
  }

  const handleSave = async (event: any) => {
    try {
      let change: PlanChange | undefined

      const groups = withdrawalStrategyType === WithdrawalStrategyType.Custom ? customGroups : withdrawalGroups
      groups.forEach((g: WithdrawalGroup) => {
        g.assetIds = g.assets.map((a: Asset) => a.id)
      })
      if (withdrawalStrategyType === WithdrawalStrategyType.Conventional) {
        // Make the first 2 groups the same order
        groups[0].withdrawalOrder = 1
        groups[1].withdrawalOrder = 2
        groups[2].withdrawalOrder = 3
      } else if (withdrawalStrategyType === WithdrawalStrategyType.Proportional) {
        groups[0].withdrawalOrder = 1
        groups[1].withdrawalOrder = 1
        groups[2].withdrawalOrder = 2
      }

      const details = JSON.stringify({
        withdrawalStrategyType: withdrawalStrategyType,
        withdrawalGroups: groups
      }, (key, value) => {
        if (key === "assets") {
          return undefined
        } else {
          return value
        }
      })
      // console.debug(`WithdrawalStrategyChange.details: ${details}`)

      if (withdrawalStrategyChange && !withdrawalStrategyChange.id) {
        const input: CreatePlanChangeInput = {
          accountId: withdrawalStrategyChange?.accountId,
          userId: withdrawalStrategyChange.userId,
          modelId: withdrawalStrategyChange.modelId,
          planId: withdrawalStrategyChange.planId,
          changeType: withdrawalStrategyChange.changeType,
          name: withdrawalStrategyChange.name,
          description: generateDescription(),
          enabled: true,
          details: details
        }
        change = await modelStore.createPlanChange(input)
        Tracking.event({action: "Withdrawal Strategy Created"})
      } else if (withdrawalStrategyChange) {
          const input: UpdatePlanChangeInput = {
            id: withdrawalStrategyChange.id,
            name: withdrawalStrategyChange.name,
            description: generateDescription(),
            enabled: true,
            details: details
          }
          change = await modelStore.updatePlanChange(input)
        Tracking.event({action: "Withdrawal Strategy Updated"})
      }

      if (onSave && change) {
        onSave(plan, change)
      } else if (onClose) {
        onClose()
      }

    } catch (err: any) {
      notify.show('error', err.message)
    }
  }

  const handleAddCustomGroup = (index: number) => {
    const group = new WithdrawalGroup({
      name: `#${index+1}`,
      withdrawalOrder: index + 1,
      assetIds: [],
      assets: []
    })

    customGroups.splice(index + 1, 0, group)
    renumberCustomGroups(customGroups)
  }

  const renumberCustomGroups = (groups: WithdrawalGroup[]) => {
    groups.forEach((g: WithdrawalGroup, index: number) => {
      g.name = `#${index + 1}`
      g.withdrawalOrder = index + 1
    })
    setCustomGroups([...groups])
  }

  const handleDeleteCustomGroup = (index: number) => {
    // Move any assets to the Reserved group
    const group = customGroups[index]
    if (reservedGroup) {
      group.assets.forEach((a: Asset) => {
        reservedGroup.assets.push(a)
      })
      setReservedGroup(reservedGroup)
    }
    customGroups.splice(index, 1)
    renumberCustomGroups(customGroups)
  }

  const generateDescription = () => {
    return (String(withdrawalStrategyType))
  }

  const renderStrategyContent = (strategyType: WithdrawalStrategyType) => {
    let content: ReactNode
    if (strategyType === WithdrawalStrategyType.Conventional) {
      content = <Typography variant="body2" gutterBottom>
        This sequential withdrawal strategy taps taxable accounts
        first until depleted, then tax-deferred accounts, and
        finally Roth accounts. This strategy seeks to make full
        use of the benefits of tax-deferred growth in tax-
        deferred accounts, such as traditional IRAs and 401(k)s,
        and in Roth accounts.
      </Typography>
    } else if (strategyType === WithdrawalStrategyType.Proportional) {
      content = <Typography variant="body2" gutterBottom>
        This strategy draws
        proportionally from taxable accounts and tax-deferred
        accounts first, then from Roth accounts. Withdrawals
        are taken proportionally from taxable and tax-deferred
        accounts based on the account balance at the time of
        the withdrawal. Once taxable and tax-deferred accounts
        are drained, withdrawals are taken from Roth accounts.
      </Typography>
    } else {
      content = <Typography variant="body2" gutterBottom>
        This strategy allows you to build your own personalized withdrawal strategy.
      </Typography>
    }
    return (
      <Box mb={2}>
        {content}
      </Box>
    )
  }

  const renderWithdrawalGroup = (group: WithdrawalGroup, deleteable: boolean = false) => {
    return (
      <Box display="flex" flexGrow={1} flexDirection="column" justifyContent="flex-start" alignItems="top"
           width="100%" minHeight={75} border="1px solid silver" borderRadius="10px">
        {!deleteable &&
          <Typography variant="h5" color="primary" textAlign="center" pl={1}>{group.name}</Typography>
        }
        {deleteable &&
          <Box display="flex" justifyContent="stretch" pl={2} pr={1}>
            <Box display="flex" flexGrow={0} justifyContent="flex-start" width="45%">
              <Typography variant="h4" color="primary" textAlign="left">{group.name}</Typography>
            </Box>
            <Box display="flex" flexGrow={1} justifyContent="center"  width="10%"color={theme.palette.grey["500"]}>
            </Box>
            <Box display="flex" flexGrow={0} justifyContent="flex-end" width="45%">
              <IconicButton disabled={customGroups.length === 1}
                onClick={() => handleDeleteCustomGroup(group.withdrawalOrder-1)}>
                <DeleteIcon/>
              </IconicButton>
            </Box>
          </Box>
        }
        <DraggableList droppableId={group.name} data={group.assets} renderWrapper={renderListWrapper}
                       renderItem={renderAsset}
        />
      </Box>
    )
  }

  const renderListWrapper = (children: ReactNode, providedMain: DroppableProvided) => {
    return (
      <div ref={providedMain.innerRef} {...providedMain.droppableProps} style={{minHeight:40}}>
        {children}
      </div>
    )
  }

  const renderAsset = (asset: Asset, provided: DraggableProvided): ReactElement => {
    return (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
        <AssetCard asset={asset} model={model} snapshot={snapshot}
                   expanded={false}
        />
      </div>
    )
  }

  const handleAssetDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    }

    let srcIndex = result.source.index
    let dstIndex = result.destination.index
    let srcType: "Standard" | "Custom" | "Reserved" | undefined
    let dstType: "Standard" | "Custom" | "Reserved" | undefined
    let srcGroup
    let dstGroup

    if (result.source.droppableId === "Reserved") {
      srcGroup = reservedGroup
      srcType = "Reserved"
    } else {
      srcGroup = withdrawalGroups.find((g: WithdrawalGroup) => g.name === result.source.droppableId)
      if (srcGroup) {
        srcType = "Standard"
      } else {
        srcGroup = customGroups.find((g: WithdrawalGroup) => g.name === result.source.droppableId)
        if (srcGroup) {
          srcType = "Custom"
        }
      }
    }

    if (result.destination.droppableId === "Reserved") {
      dstGroup = reservedGroup
      dstType = "Reserved"
    } else {
      dstGroup = withdrawalGroups.find((g: WithdrawalGroup) => g.name === result.destination?.droppableId)
      if (dstGroup) {
        dstType = "Standard"
      } else {
        dstGroup = customGroups.find((g: WithdrawalGroup) => g.name === result.destination?.droppableId)
        if (dstGroup) {
          dstType = "Custom"
        }
      }
    }

    if (!srcGroup || !dstGroup || !srcType || !dstType) {
      return
    }

    if (srcGroup === dstGroup) {
      arrayMove(srcGroup.assets, srcIndex, dstIndex)
      if (srcType === "Custom") {
        setCustomGroups([...customGroups])
      } else if (srcType === "Reserved") {
        setReservedGroup(reservedGroup)
      } else {
        setWithdrawalGroups([...withdrawalGroups])
      }
    } else {
      const assets = srcGroup.assets.splice(srcIndex, 1)
      dstGroup.assets.splice(dstIndex, 0, ...assets)
      if (srcType === "Custom" || dstType === "Custom") {
        setCustomGroups([...customGroups])
      } else if (srcType === "Reserved" || dstType === "Reserved") {
        setReservedGroup(reservedGroup)
      } else {
        setWithdrawalGroups([...withdrawalGroups])
      }
    }
  }

  if (!isOpen || !withdrawalStrategyChange) {
    return null
  }

  return (
    <ModelEditDialog title="Withdrawal Strategy" open={isOpen} size="sm"
                     onCancel={handleClose}
                     onSave={handleSave}
    >
      <FormGroup>
        <FormLabel sx={styles.formLabel}>Strategy Type</FormLabel>
        <RadioGroup aria-label="withdrawalStrategyType" name="withdrawalStrategyType" value={withdrawalStrategyType} row
                    onChange={(event: any) => {
                      handleChangeWithdrawalStrategy(event.target.value, liquidAssets)
                    }}>
          <FormControlLabel
            value={WithdrawalStrategyType.Conventional}
            control={<Radio color="secondary" />}
            label={WithdrawalStrategyType.Conventional}
            labelPlacement="end"
          />
          <FormControlLabel
            value={WithdrawalStrategyType.Proportional}
            control={<Radio color="secondary" />}
            label={WithdrawalStrategyType.Proportional}
            labelPlacement="end"
          />
          <FormControlLabel
            value={WithdrawalStrategyType.Custom}
            control={<Radio color="secondary" />}
            label={WithdrawalStrategyType.Custom}
            labelPlacement="end"
          />
        </RadioGroup>
      </FormGroup>
      {renderStrategyContent(withdrawalStrategyType)}
      <DragDropContext onDragEnd={handleAssetDragEnd}>
        {withdrawalStrategyType === WithdrawalStrategyType.Conventional &&
          <Grid container direction="column" spacing={0}>
            <Grid item xs={12} textAlign="center">
              {renderWithdrawalGroup(withdrawalGroups[0])}
              {/*<ArrowDownwardIcon/>*/}
            </Grid>
            <Grid item xs={12} textAlign="center" mt={2}>
              {renderWithdrawalGroup(withdrawalGroups[1])}
              {/*<ArrowDownwardIcon/>*/}
            </Grid>
            <Grid item xs={12} textAlign="center" mt={2}>
              {renderWithdrawalGroup(withdrawalGroups[2])}
            </Grid>
            <Grid item xs={12} textAlign="center" mt={2}>
              {renderWithdrawalGroup(reservedGroup!)}
            </Grid>
          </Grid>
        }
        {withdrawalStrategyType === WithdrawalStrategyType.Proportional &&
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6} textAlign="center">
              {renderWithdrawalGroup(withdrawalGroups[0])}
              {/*<ArrowDownwardIcon/>*/}
            </Grid>
            <Grid item xs={6} textAlign="center">
              {renderWithdrawalGroup(withdrawalGroups[1])}
              {/*<ArrowDownwardIcon/>*/}
            </Grid>
            <Grid item xs={12} textAlign="center">
              {renderWithdrawalGroup(withdrawalGroups[2])}
            </Grid>
            <Grid item xs={12} textAlign="center">
              {renderWithdrawalGroup(reservedGroup!)}
            </Grid>
          </Grid>
        }
        {withdrawalStrategyType === WithdrawalStrategyType.Custom &&
          <Grid container direction="column">
            {customGroups.map((group: WithdrawalGroup, index: number) =>
                <Grid item textAlign="center" >
                  {renderWithdrawalGroup(group, true)}
                  {index < customGroups.length &&
                    <IconButton sx={{height:28, width:28, marginLeft:"10px"}} onClick={() => handleAddCustomGroup(index)}>
                      <AddCircleRounded sx={{color: theme.palette.secondary.light}}/>
                    </IconButton>
                  }
                </Grid>
              )
            }
            {customGroups.length === 0 &&
              <IconButton sx={{height:28, width:28, marginLeft:"10px"}} onClick={() => handleAddCustomGroup(0)}>
                <AddCircleRounded sx={{color: theme.palette.secondary.light}}/>
              </IconButton>
            }
            <Grid item>
              {renderWithdrawalGroup(reservedGroup!)}
            </Grid>
          </Grid>
        }
      </DragDropContext>
    </ModelEditDialog>
  )

}

export default WithdrawalStrategyDialog