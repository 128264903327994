import * as React from 'react';
import Deduction from "../../../model/Deduction";
import {FormLabel, Grid, Typography, useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import {
  dateToLocalFormat,
  numberToMoneyFormat,
  numberToPercentFormat
} from "../../../stores/StoreUtilities";
import Model from "../../../model/Model";
import {DeductionExpenseIcon} from "../../../styles/Icons";
import ModelCard2 from "../../../components/model/ModelCard2";
import Asset from "../../../model/Asset";
import DeductionEditDialog from "./DeductionEditDialog";
import {isNumber} from "../../../model/ModelUtilities";


const DeductionCard = ({
  deduction,
  model,
  projected,
  expanded,
  onChange,
  onSave,
  onDelete
}: {
  deduction: Deduction
  model: Model
  projected?: number
  expanded?: boolean
  onChange?(event: any, expanded: boolean): void
  onSave?(expense: Deduction): void
  onDelete?(deleted: Deduction): void
}) => {
  const [deductionModel, setDeductionModel] = useState<Deduction>(deduction)
  const [description, setDescription] = useState<string>("")
  const [amount, setAmount] = useState<string | undefined>()
  const [projectedAmount, setProjectedAmount] = useState<string | undefined>()
  const [asset, setAsset] = useState<Asset | undefined>()
  const [schedule, setSchedule] = useState<string | undefined>()
  const [startDate, setStartDate] = useState<string | undefined>()
  const [endDate, setEndDate] = useState<string | undefined>()
  const [annualInf, setAnnualInf] = useState<string | undefined>()
  const [annualInfLock, setAnnualInfLock] = useState<boolean>(false)
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)

  useEffect(() => {
    init(deduction)
  }, [deduction, projected])

  const init = (deduction: Deduction) => {
    setDeductionModel(deduction)
    setDescription(deduction.description)
    setAmount(deduction.amount ? numberToMoneyFormat(deduction.amount, 0) : "$0")
    setProjectedAmount(projected ? numberToMoneyFormat(projected, 0) : "$0")
    setAsset(model.getAssetById(deduction.assetId))
    setSchedule(deduction.schedule.name)
    setStartDate(dateToLocalFormat(deduction.startDate))
    setEndDate(dateToLocalFormat(deduction.endDate))
    setAnnualInf(numberToPercentFormat(isNumber(deduction.annualInf) ? deduction.annualInf : model.getDefaultInflationRate("deduction"), 2))
    setAnnualInfLock(!isNumber(deduction.annualInf) || model.hasLock(deduction.id, "annualInf"))
    // TODO: receiptMonths, Days
    setEditOpen(!deduction.createdAt)
  }

  const theme = useTheme()

  const styles = {
    heading: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    row: {
      height: 40,
      minHeight: 40,
      maxHeight: 40,
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "1.0em",
      maxHeight: 15,
      marginBottom: "1px",
      textWrap: "nowrap",
      color: theme.palette.grey["700"]
    }
  }

  const renderContent = () => {
    return (
      <Grid container direction="column" spacing={0}>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Amount</FormLabel>
              <Typography variant="body2">
                {amount}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormLabel sx={styles.formLabel}>Frequency</FormLabel>
              <Typography variant="body2">
                {schedule}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={8}>
              <FormLabel sx={styles.formLabel}>Contribution Account</FormLabel>
              <Typography variant="body2">
                {asset ? asset.description : "None"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Start Date</FormLabel>
              <Typography variant="body2">
                {startDate}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>End Date</FormLabel>
              <Typography variant="body2" color={deduction.end ? "inherit" : "darkgrey"}>
                {endDate}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Annual Inflation %</FormLabel>
              <Typography variant="body2" color={annualInfLock ? "text.secondary" : "inherit"}>
                {annualInf}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <React.Fragment>
      <ModelCard2
        icon={<DeductionExpenseIcon/>}
        title={description}
        value={projectedAmount}
        valueColor="#881E10"
        expanded={expanded}
        onChange={onChange}
        editButtonId={`edit-btn-${deductionModel.id}`}
        onEdit={(event: any) => {
          setEditOpen(!editOpen)
        }}
      >
        {renderContent()}
      </ModelCard2>
      {editOpen &&
        <DeductionEditDialog deduction={deductionModel} model={model}
                             open={editOpen}
                             onSave={(update: Deduction) => {
                               init(update)
                               setEditOpen(false)
                               if (onSave) {
                                 onSave(update)
                                 setSaved(true)
                               }
                             }}
                             onDelete={(deleted: Deduction) => {
                               setEditOpen(false)
                               if (onDelete) {
                                 onDelete(deleted)
                               }
                             }}
                             onClose={() => {
                               setEditOpen(false)
                               if (!deductionModel.createdAt && onDelete) {
                                 onDelete(deductionModel)
                               }
                             }}/>
      }
    </React.Fragment>
  )
}

export default DeductionCard