// import Rollbar from "rollbar"
// import config from 'react-global-configuration';

export enum LogLevel {
  Debug = 0,
  Info = 1,
  Warn = 2,
  Error = 3,
  Critical = 4,
  None = 5,
}

// const transformer = function(payload: any) {
//   if (payload.level !== 'error') {
//     return
//   }

//   payload.code_version = process.env.REACT_APP_COMMIT_ID
//   payload.client.javascript.code_version = process.env.REACT_APP_COMMIT_ID

//   if (payload.body && payload.body.trace) {
    
//     const trace = payload.body.trace
//     if (trace && trace.frames) {
//       // Parse the URL into three parts:
//       // Ex output: ["https://www.govgig.com/profile","https","/profile"]
//       const locRegex = /^(https?):\/\/[a-zA-Z0-9.:_-]+(.*)/i
      
//       for (let i = 0; i < trace.frames.length; i++) {
//         const filename = trace.frames[i].filename
//         if (filename) {
//           const matchResults = filename.match(locRegex)
//           if (matchResults) {
//             // Be sure that the minified_url when uploading source maps includes 'dynamichost'
//             payload.body.trace.frames[i].filename = matchResults[1] + '://dynamichost' + matchResults[2]
//           }
//         }
//       }
//     }

//     if (trace.exception) {
//       const exception = trace.exception
  
//       if (exception.description) {
//         const description = exception.description
//         if (typeof description === 'object') {
//           payload.body.trace.exception.description = JSON.stringify(description)
//         }
//       }
//       if (exception.message) {
//         const message = exception.message
//         if (typeof message === 'object') {
//           payload.body.trace.exception.message = JSON.stringify(message)
//         }
//       }
//     }
//   } 
// }

class Logger {
  static logLevel = LogLevel.Debug
  static rollbar: any // TODO: Use, remove or replace Rollbar? 
  
  static init() {
    // const accessToken = config.get("rollbar.accessToken")
    // if (accessToken) {
    //   const userid = undefined

    //   // Note that "production" for NODE_ENV means any build run with "npm run build".
    //   // However, REACT_APP_ENV is how we specify local, dev, or prod environment for reporting purposes in the payload below.
    //   // The value for REACT_APP_ENV is specified in a local .env file, or as an environment variable in the Amplify console.
      
    //   const nodeEnv = process.env.NODE_ENV
    //   this.logLevel = (nodeEnv === "production") ? LogLevel.Warn : LogLevel.Debug

    //   const rollbarConfig = {
    //     enabled: nodeEnv === "production",
    //     accessToken,
    //     captureUncaught: true,
    //     captureUnhandledRejections: true,
    //     autoInstrument: {
    //       network: true,
    //       networkRequestBody: true,
    //       log: true,
    //       dom: true,
    //       navigation: true,
    //       connectivity: true
    //     },
    //     // reportLevel: 'error',
    //     maxTelemetryEvents: 10,
    //     hostBlackList: [
    //     ],
    //     payload: {
    //       environment: process.env.REACT_APP_ENV,
    //       person: {
    //         id: userid,
    //       },
    //     },
    //     client: {
    //       javascript: {
    //         code_version: process.env.REACT_APP_COMMIT_ID
    //       }
    //     },
    //     transform: transformer 
    //   }

    //   Logger.rollbar = new Rollbar(rollbarConfig)
    //   this.info(`Rollbar initialized logLevel=${this.logLevel}`)
    // } else {
      this.info(`Logger initialized logLevel=${this.logLevel} (Rollbar is disabled)`)
    // }
  }

  static configure(options: any) {
    if (Logger.rollbar) {
      Logger.rollbar.configure(options)
    }
  }

  static configureUser(userId: string) {
    if (Logger.rollbar) {
      Logger.rollbar.configure({
        payload: {
          person: {
            id: userId,
          }
        }
      })
    }
  }

  static debug(msg: string, ...args: any[]) {
    if (args.length === 0) {
      if (Logger.rollbar) {
        Logger.rollbar.debug(msg)
      }
      if (this.logLevel <= LogLevel.Debug) {
        console.debug(msg)
      }
    }
    else {
      if (Logger.rollbar) {
        Logger.rollbar.debug(msg, args)
      }
      if (this.logLevel <= LogLevel.Debug) {
        console.debug(msg, args.length > 0 ? args : undefined)
      }
    }
  }

  static info(msg: string, ...args: any[]) {
    if (args.length === 0) {
      if (Logger.rollbar) {
        Logger.rollbar.info(msg)
      }
      if (this.logLevel <= LogLevel.Info) {
        console.info(msg)
      }
    } else {
      if (Logger.rollbar) {
        Logger.rollbar.info(msg, args)
      }
      if (this.logLevel <= LogLevel.Info) {
        console.info(msg, args)
      }
    }
  }

  static warn(msg: string, ...args: any[]) {
    this.warning(msg, args)
  }

  static warning(msg: string, ...args: any[]) {
    if (args.length === 0) {
      if (Logger.rollbar) {
        Logger.rollbar.warning(msg)
      }
      if (this.logLevel <= LogLevel.Warn) {
        console.warn(msg)
      }
    }
    else {
      if (Logger.rollbar) {
        Logger.rollbar.warning(msg, args)
      }
      if (this.logLevel <= LogLevel.Warn) {
        console.warn(msg, args)
      }
    }
  }

  static error(msg: string, ...args: any[]) {
    if (args.length === 0) {
      if (Logger.rollbar) {
        Logger.rollbar.error(msg)
      }
      if (this.logLevel <= LogLevel.Error) {
        console.error(msg)
      }
    }
    else {
      if (Logger.rollbar) {
        Logger.rollbar.error(msg, args)
      }
      if (this.logLevel <= LogLevel.Error) {
        console.error(msg, args)
      }
    }
  }

  static critical(msg: string, ...args: any[]) {
    if (args.length === 0) {
      if (Logger.rollbar) {
        Logger.rollbar.critical(msg)
      }
      if (this.logLevel <= LogLevel.Critical) {
        console.error(msg)
      }
    }
    else {
      if (Logger.rollbar) {
        Logger.rollbar.critical(msg, args)
      }
      if (this.logLevel <= LogLevel.Critical) {
        console.error(msg, args)
      }
    }
  }
}

export default Logger