import Asset from "./Asset";

export interface IWithdrawalGroup {
  name: string
  withdrawalOrder: number
  assetIds: string[]
  assets: Asset[]
}

export class WithdrawalGroup implements IWithdrawalGroup {
  name: string
  withdrawalOrder: number
  assetIds: string[]
  assets: Asset[]

  constructor (data: any) {
    this.name = data.name
    if (this.name === "Excluded") {
      this.name = "Reserved"
    }
    this.withdrawalOrder = data.withdrawalOrder
    this.assetIds = data.assetIds
    this.assets = data.assets
  }
}

export enum WithdrawalStrategyType {
  Conventional = "Conventional",
  Proportional = "Proportional",
  Custom = "Custom"
}

class WithdrawalStrategy {
  withdrawalStrategyType: WithdrawalStrategyType
  withdrawalGroups: IWithdrawalGroup[]

  constructor (data: any) {
    this.withdrawalStrategyType = data.withdrawalStrategyType
    this.withdrawalGroups = data.withdrawalGroups ?? []
  }
}

export default WithdrawalStrategy