import config from 'react-global-configuration';
import {useNavigate} from "react-router-dom";
import RoutesConfig from "../RoutesConfig";
import Tracking from "./Tracking";

const useNavigationHelper = () => {
  const navigate = useNavigate()

  return ({
    navigateHome: () => {
      if (config.get("webFlow.enabled") === true) {
        window.location.href = config.get("homeUrl")
      } else {
        navigate(RoutesConfig.home.pathname)
      }
    },

    navigateInvest: () => {
      const investUrl = config.get("investUrl")
      if (config.get("webFlow.enabled") === true && investUrl) {
        Tracking.event({action: "Discover Click"})
        window.location.href = investUrl
      } else {
        // Do nothing
      }
    }
  })
}

export default useNavigationHelper
