import { MenuItem, Typography } from "@mui/material"
import { INavItem } from "../../NavigationConfig"
import { IRouteData } from "../../RoutesConfig"

interface INavMenuItemProps {
  navItem: INavItem
  onClickMenuItem: (e: React.MouseEvent, navItem: INavItem) => void 
}

const NavMenuItem = (props: INavMenuItemProps) => { 
  const route: IRouteData = props.navItem.route

  return (
    <MenuItem 
      key={route.title} 
      aria-label={route.title}
      onClick={async (e: React.MouseEvent) => {
        // TODO: Tracking 
        
        // if (route.onClick) {
        //   await route.onClick(e) 
        // } 
        
        await props.onClickMenuItem(e, props.navItem)
      }}
    >
      <Typography textAlign="center">{route.title}</Typography>
    </MenuItem>
  )
}

export default NavMenuItem
