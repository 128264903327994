import * as React from "react";
import {useResources} from "../../stores/ResourceProvider";
import Dialog from "@mui/material/Dialog";
import FormValidator from "../../components/form/FormValidator";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import {useEffect, useState} from "react";
import Model from "../../model/Model";
import DialogActions from "@mui/material/DialogActions";
import {Box, FormControlLabel, Switch, useTheme} from "@mui/material";
import CancelButton from "../../components/form/CancelButton";
import ProgressButton from "../../components/form/ProgressButton";
import {UpdatePlanInput} from "../../API";
import Plan from "../../model/Plan";
import DialogContentText from "@mui/material/DialogContentText";
import IconicButton from "../../components/controls/IconicButton";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import Tracking from "../../components/Tracking";


const EditPlanDialog = ({
  plan,
  model,
  onClose,
  onSave,
  onDelete
}: {
  plan: Plan,
  model: Model,
  onClose(): void
  onSave(plan?: Plan, committed?: Model): void
  onDelete?(plan: Plan): void
}) => {
  const [name, setName] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [commitPlan, setCommitPlan] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const {modelStore, confirm, notify} = useResources()
  const theme = useTheme()

  useEffect(() => {
    setName(plan.name)
    setDescription(plan.description)
    setErrorMessage("")
  }, [plan])

  const handleSubmit = async () => {
    try {
      setIsProcessing(true)
      const found = model.plans.find((p: Plan) => p.name.toLowerCase() === name.toLowerCase())
      if (found && found.id !== plan.id) {
        setErrorMessage("A plan with this name already exists.")
      } else {
        const input: UpdatePlanInput = {
          id: plan.id,
          name: name,
          description: description
        }
        const update = await modelStore.updatePlan(input)
        if (update) {
          if (commitPlan) {
            const updatedModel = await handleCommit()
            if (updatedModel) {
              Tracking.event({action: "Plan Committed"})
              onSave(update, updatedModel)
            }
          } else {
            Tracking.event({action: "Plan Updated"})
          }
          onSave(update)
        }
      }
    } catch (err: any) {
      setErrorMessage(`Error updating plan (${err.message})`)
    } finally {
      setIsProcessing(false)
    }
  }

  const handleCommit = async () => {
      return await modelStore.commitPlanChanges(plan, model)
  }

  const handleDelete = async () => {
    confirm.show('Confirm Delete', `Are you sure you want to delete ${plan.name}?`,
      ['Delete', 'Cancel'],
      async () => {
        try {
          const deleted = await modelStore.deletePlan(plan.id)
          if (deleted) {
            onDelete!(deleted)
          }
          return true
        }
        catch (err: any) {
          notify.show('error', err.message)
          return false
        }
      })
  }

  return (
    <Dialog
      id="editPlanDialog"
      open={true}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="editPlanDialogTitle"
    >
      <FormValidator onSubmit={handleSubmit} autoComplete="off">
        <DialogTitle id="editPlanDialogTitle">Edit Scenario</DialogTitle>
        <DialogContent sx={{my:2}}>
          {errorMessage &&
            <DialogContentText sx={{color: theme.palette.error.main}}>
              {errorMessage}
            </DialogContentText>
          }
          <TextFieldValidator
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            required
            validators={{required:true}}
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
            fullWidth
            variant="standard"
          />
          <TextFieldValidator
            margin="dense"
            name="description"
            label="Description"
            type="text"
            validators={{required:false}}
            value={description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDescription(event.target.value)}
            fullWidth
            variant="standard"
          />
          {/*{plan.id &&*/}
          {/*  <FormControlLabel control={*/}
          {/*      <Switch checked={commitPlan}*/}
          {/*        onChange={(event: any) => {*/}
          {/*            setCommitPlan(event.target.checked)*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    }*/}
          {/*    label={`Commit this scenario to the ${model.name}. This will permanently apply the changes to the model.`}*/}
          {/*    sx={{marginTop:1}}*/}
          {/*  />*/}
          {/*}*/}
        </DialogContent>
        <DialogActions>
          <Box display="flex" width="100%" justifyContent="space-between">
            {onDelete &&
              <IconicButton onClick={handleDelete}>
                <DeleteIcon/>
              </IconicButton>
            }
            <Box display="flex" justifyContent="flex-end">
              <CancelButton onClick={onClose} />
              <ProgressButton variant="contained" size="medium" color="primary" sx={{ color: "#fff" }}
                              type="submit" processing={isProcessing}>
                Save
              </ProgressButton>
            </Box>
          </Box>
        </DialogActions>

      </FormValidator>

    </Dialog>
  )

}


export default EditPlanDialog