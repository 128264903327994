import Liability from "../../../model/Liability";
import {Box, useTheme} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import FormValidator from "../../../components/form/FormValidator";
import TextFieldValidator from "../../../components/form/TextFieldValidator";
import {
  getISODateFromDate, moneyToNumberFormat, numberToMoneyFormat, numberToPercentFormat,
} from "../../../stores/StoreUtilities";
import {CreateLiabilityInput, UpdateExpenseInput, UpdateLiabilityInput} from "../../../API";
import {useResources} from "../../../stores/ResourceProvider";
import Model from "../../../model/Model";
import DatePickerValidator from "../../../components/form/DatePickerValidator";
import FormGroupSpacer from "../../../components/form/FormGroupSpacer";
import Snapshot, {ISnapshotDetail} from "../../../model/Snapshot";
import {compareAsc} from "date-fns";
import MilestoneDateFieldValidator from "../../../components/form/MilestoneDateFieldValidator";
import ModelEditDialog from "../../../components/model/ModelEditDialog";
import {isNumber} from "../../../model/ModelUtilities";
import Expense from "../../../model/Expense";
import Tracking from "../../../components/Tracking";

const LiabilityEditDialog = ({
  open,
  liability,
  model,
  snapshot,
  onClose,
  onSave,
  onDelete
}: {
  open?: boolean
  liability: Liability
  model: Model
  snapshot?: Snapshot
  onClose?(): void
  onSave?(update: Liability, snapshotItem?: ISnapshotDetail): void
  onDelete?(expense: Liability): void
}) => {
  const [liabilityModel, setLiabilityModel] = useState<Liability>(liability)
  const [snapshotItem, setSnapshotItem] = useState<ISnapshotDetail | undefined>()
  const [description, setDescription] = useState<string>("")
  const [owner, setOwner] = useState<string | undefined>()
  const [balance, setBalance] = useState<string | undefined>()
  const [balanceDate, setBalanceDate] = useState<Date | undefined>()
  const [start, setStart] = useState<string>()
  const [end, setEnd] = useState<string>()
  const [interestRate, setInterestRate] = useState<string | undefined>()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const theme = useTheme()
  const { modelStore, userStore, notify } = useResources()

  const styles = {
    actions: {
      display:"flex",
      flexDirection:"row",
      width:"100%",
      justifyContent:"space-between",
      alignItems:"center",
      marginTop: theme.spacing(2)
    },
    row: {
      maxHeight: 48
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"]
    },
    halfWidth: {
      width: "50%"
    },
    halfWidthAutoComplete: {
      width: 190,
      paddingTop: "8px"
    }
  }

  useEffect(() => {
    setLiabilityModel(liability)
    setDescription(liability.description)
    const person = liability.ownerId ? model.getPerson(liability.ownerId) : undefined
    setOwner(person ? person.nickname : model.getDefaultPersonNickname())
    const detail = snapshot ? snapshot.getSnapshotDetail(liability) : undefined
    if (detail) {
      setSnapshotItem(detail)
      setBalance(numberToMoneyFormat(detail.balance, 0))
      setBalanceDate(snapshot!.date)
    } else {
      setBalance(numberToMoneyFormat(liability.balance, 0))
      setBalanceDate(liability.balanceDate)
    }
    setStart(liability.start)
    setEnd(liability.end)
    setInterestRate(isNumber(liability.interestRate) ? numberToPercentFormat(liability.interestRate, 2) : "")
    setIsOpen(open === true)
  }, [liability, model, snapshot, open])

  const handleClose = async (event: any) => {
    if (onClose) {
      onClose()
    }
  }

  const handleSave = async (event: any) => {
    try {
      const person = model.getPersonByNickname(owner)
      let updatedLiability: Liability | undefined
      let updatedSnapshotDetail: ISnapshotDetail | undefined
      const balanceValue = moneyToNumberFormat(balance, 0) ?? 0

      if (!liabilityModel.createdAt) {
        const input: CreateLiabilityInput = {
          id: liabilityModel.id,
          accountId: liabilityModel.accountId,
          userId: liabilityModel.userId,
          modelId: liabilityModel.modelId,
          description: description,
          ownerId: person ? person.id : null,
          balance: balanceValue,
          balanceDate: balanceDate ? getISODateFromDate(balanceDate) : null,
          start: start,
          end: end ?? null,
          interestRate: interestRate !== undefined && interestRate >= "0" ? parseFloat(interestRate) / 100 : null,
          sortOrder: liabilityModel.sortOrder
        }

        updatedLiability = await modelStore.createLiability(input)
        Tracking.event({action: "Liability Created"})
      } else {
        const input: UpdateLiabilityInput = {
          id: liabilityModel.id,
          accountId: liabilityModel.accountId,
          userId: liabilityModel.userId,
          modelId: liabilityModel.modelId,
          description: description,
          ownerId: person ? person.id : null,
          balance: balanceValue,
          balanceDate: balanceDate ? getISODateFromDate(balanceDate) : null,
          start: start,
          end: end ?? null,
          interestRate: interestRate !== undefined && interestRate >= "0" ? parseFloat(interestRate) / 100 : null,
        }

        // if (snapshot && snapshot.date !== liabilityModel.balanceDate) {
        //   // Don't update the balance if the snapshot date doesn't match the asset balanceDate
        //   delete input.balance
        //   delete input.balanceDate
        // }

        updatedLiability = await modelStore.updateLiability(input)
        Tracking.event({action: "Liability Updated"})
      }

      // Look for linked expenses to update
      if (updatedLiability) {
        const expenses = model.expenses.filter((e: Expense) => e.liabilityId === updatedLiability!.id)
        for (let expense of expenses) {
          if (expense.start !== updatedLiability.start || expense.end !== updatedLiability.end || expense.ownerId !== updatedLiability.ownerId) {
            const update: UpdateExpenseInput = {
              id: expense.id,
              start: updatedLiability.start,
              end: updatedLiability.end,
              ownerId: updatedLiability.ownerId
            }
            const updatedExpense = await modelStore.updateExpense(update)
          }
        }
      }

      // Only update the snapshot if the start date <= the snapshot date
      const startDate = model.getDate(start)
      if ((!startDate && snapshot && snapshot.isCurrent) ||
          (startDate && snapshot && compareAsc(startDate, snapshot.date) <= 0)) {
        updatedSnapshotDetail = {
          id: liabilityModel.id,
          typename: "Liability",
          balance: balanceValue
        }
      }

      if (onSave && updatedLiability) {
        onSave(updatedLiability, updatedSnapshotDetail)
      } else if (onClose) {
        onClose()
      }
    } catch (err: any) {
      notify.show('error', err.message)
    }
  }

  const handleDelete = async () => {
    if (onDelete) {
      if (liabilityModel.createdAt) {
        const deleted = await modelStore.deleteLiability(liabilityModel.id)
        if (deleted) {
          onDelete(deleted)
        }
      } else {
        onDelete(liabilityModel)
      }
    }
  }

  const isFutureLiability = () => {
    const startDate = model.getDate(start)
    return (startDate && snapshot && compareAsc(startDate, snapshot.date) > 0)
  }

  if (!liabilityModel || !isOpen) {
    return null
  }

  return (
    <ModelEditDialog title="Edit Liability" open={isOpen}
                     onCancel={handleClose}
                     onSave={handleSave}
                     onDelete={handleDelete}
    >
      <TextFieldValidator
        margin="normal"
        name="description"
        label="Title"
        type="text"
        fullWidth
        variant="standard"
        required
        validators={{ required: true }}
        value={description}
        onChange={(event: any) => setDescription(event.target.value)}
      />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <TextFieldValidator
          type="string"
          validators={{ isMoney: true }}
          name="balance"
          variant="standard"
          margin="dense"
          fullWidth
          label="Balance"
          value={balance}
          helperText={isFutureLiability() ? "This is a future liability" : ""}
          onChange={(event: any) => setBalance(event.target.value)}
        />
        <FormGroupSpacer/>
        <Box pt={1}>
          <DatePickerValidator
            name="balanceDate"
            label="Snapshot Date"
            value={balanceDate}
            format="MM-dd-yyyy"
            variant="standard"
            margin="dense"
            fullWidth
            required
            disabled
            onChange={(newValue: any) => {
              setBalanceDate(newValue)
            }}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <TextFieldValidator
          type="text"
          validators={{ required: true }}
          required
          name="owner"
          label="Owner"
          variant="standard"
          styleProp={styles.halfWidthAutoComplete}
          autocompleteOptions={{
            freeSolo: false,
            options: model.getPersonNicknames(),
            value: owner,
            onChange: (event: any, value: string, reason: any) => {
              setOwner(value)
            }
          }}
        />
        <FormGroupSpacer/>
        <TextFieldValidator
          type="string"
          validators={{required: false, minValue: 0}}
          name="interestRate"
          variant="standard"
          margin="dense"
          styleProp={styles.halfWidth}
          label="Interest Rate %"
          value={interestRate}
          onChange={(event: any) => setInterestRate(event.target.value)}
        />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <MilestoneDateFieldValidator
          name="start"
          label="Start Date"
          model={model}
          value={start}
          width="50%"
          onChange={(value: string) => setStart(value)}
        />
        <FormGroupSpacer/>
        <MilestoneDateFieldValidator
          name="end"
          label="Payoff Date"
          model={model}
          value={end}
          width="50%"
          onChange={(value: string) => setEnd(value)}
        />
      </Box>
    </ModelEditDialog>
  )
}

export default LiabilityEditDialog