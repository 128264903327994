import Plan from "../../../model/Plan";
import React, {useEffect, useState} from "react";
import UserSetting, {IScenario, IScenarioComparison, UserSettingType} from "../../../model/UserSetting";
import User from "../../../model/User";
import ModelEditDialog from "../../../components/model/ModelEditDialog";
import {useResources} from "../../../stores/ResourceProvider";
import Model from "../../../model/Model";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {CircularProgress, DialogContentText, List, Typography, useTheme} from "@mui/material";
import NavListItem from "../../../components/controls/NavListItem";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {CreateUserSettingInput, UpdateUserSettingInput} from "../../../API";

const ScenarioComparisonSettingsDialog = ({
  open,
  user,
  userSetting,
  onClose,
  onSave
} : {
  open?: boolean
  user: User
  userSetting?: UserSetting
  onClose?(): void
  onSave?(userSetting: UserSetting): void
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(open === true)
  const [models, setModels] = useState<Model[]>([])
  // const [comparisons, setComparisons] = useState<IScenarioComparisonSettings[]>([])
  const [settingName, setSettingName] = useState<string | undefined>()
  const [scenarios, setScenarios] = useState<IScenario[]>([])
  const [expandedListItems, setExpandedListItems] = useState<Set<string>>(new Set<string>([]))
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {userStore, modelStore, notify} = useResources()
  const theme = useTheme()

  const defaultName = "Advisor Comparison"
  const maxScenarios = 10

  const styles = {
    checkedIcon: {
      height: 24,
      width: 24,
      color: theme.palette.action.active
    },
    uncheckedIcon: {
      color: theme.palette.action.disabled,
      height: 24,
      width: 24
    },
    progress: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      width: "100%",
      justifyContent: "space-evenly",
      alignItems: "center",
      marginTop: 4,
      marginBottom: 2,
      marginLeft: "auto",
      marginRight: "auto"
    }
  }
    useEffect(() => {
    loadModelsAndSettings()
  }, [user, userSetting])

  const loadModelsAndSettings = async () => {
    const models = modelStore.models
    if (!isLoading && models.length > 0) {
      setIsLoading(true)
      for (let model of models) {
        if (model.plans.length === 0) {
          const plans = await modelStore.listPlansByModel(model.id)
          if (plans) {
            model.plans = plans
          }
        }
      }
      setModels(models)

      if (userSetting) {
        setSettingName(userSetting.name)
        if (userSetting.settings["scenarios"]) {
          let items = userSetting.settings["scenarios"] ?? []
          items = items.filter((item: IScenario) => {
            // Filter out scenarios that no longer exist
            const model = models.find((m: Model) => m.id === item.modelId)
            if (model) {
              const plan = model.plans.find((p: Plan) => p.id === item.planId)
              if (plan) {
                return true
              }
            }
            return false
          })
          setScenarios(items)
          items.forEach((item: IScenario) => setExpandedListItem(item.modelId, true))
        }
      }

      setIsLoading(false)
    }
  }

  const handleClose = async (event: any) => {
    if (onClose) {
      onClose()
    }
  }

  const handleSave = async (event: any) => {
    try {
      scenarios.sort((a: IScenario, b: IScenario) => a.sortOrder - b.sortOrder)
      const settings: IScenarioComparison = {
        scenarios: scenarios
      }
      let result: UserSetting | undefined
      if (userSetting && userSetting.id) {
        const input: UpdateUserSettingInput = {
          id: userSetting.id,
          settings: JSON.stringify(settings)
        }
        result = await userStore.updateUserSetting(input)
      } else {
        const user = modelStore.currentUser
        if (user) {
          const input: CreateUserSettingInput = {
            accountId: user.accountId,
            userId: user.id,
            name: defaultName,
            settingType: UserSettingType.scenarioComparison,
            settings: JSON.stringify(settings)
          }
          result = await userStore.createUserSetting(input)
        }
      }
      if (result && onSave) {
        // modelStore.currentUser.
        modelStore.currentUser?.updateUserSetting(result)
        onSave(result)
      }
    } catch (err: any) {
      notify.show('error', err.message)
    }
  }

  const setExpandedListItem = (id: string, expand: boolean) => {
    if (expand) {
      expandedListItems.add(id)
    } else {
      expandedListItems.delete(id)
    }
    setExpandedListItems(new Set(expandedListItems))
  }

  const findScenarioIndex = (modelId: string, planId: string) => {
    return scenarios.findIndex((scenario: IScenario) => scenario.modelId === modelId && scenario.planId === planId)
  }

  const handleToggleScenario = (modelId: string, planId: string, sortOrder: number) => {
    const index = findScenarioIndex(modelId, planId)
    if (index >= 0) {
      scenarios.splice(index, 1)
    } else if (scenarios.length < maxScenarios) {
      scenarios.push({modelId: modelId, planId: planId, sortOrder: sortOrder})
    }
    setScenarios([...scenarios])
  }

  return (
    <ModelEditDialog title="Scenario Comparisons" open={isOpen} size="sm"
                     onCancel={handleClose}
                     onSave={handleSave}
    >
      <DialogContentText>
        {scenarios.length} out of a max of {maxScenarios} scenarios are selected to compare.
      </DialogContentText>
      {isLoading &&
        <CircularProgress sx={styles.progress} />
      }
      <List dense>
      {models.map((model: Model, modelIndex: number) =>
        <NavListItem id={model.id}
                     icon={<AccountTreeIcon/>}
                     title={model.name}
                     primary={<Typography variant="body2" sx={{whiteSpace:"normal", lineHeight:1.1}}>{model.name}</Typography>}
                     drawerOpen={true}
                     expanded={expandedListItems.has(model.id)}
                     onClick={() => {
                       setExpandedListItem(model.id, !expandedListItems.has(model.id))
                     }}
                     onChange={(expanded: boolean) => {
                       setExpandedListItem(model.id, expanded)
                     }}
        >
          {model.plans.map((plan: Plan, planIndex: number) =>
            <NavListItem id={plan.id}
                         icon={
                           findScenarioIndex(model.id, plan.id) >= 0 ? <VisibilityIcon sx={styles.checkedIcon}/> :
                             <VisibilityOffIcon sx={styles.uncheckedIcon}/>
                         }
                         title={plan.name}
                         primary={plan.name}
                         onClick={(id: string) => handleToggleScenario(model.id, plan.id, modelIndex * 1000 + planIndex)}
                         onChange={(expanded: boolean) => {
                           handleToggleScenario(model.id, plan.id, modelIndex * 1000 + planIndex)
                         }}
                         drawerOpen={true}
            />
          )}
        </NavListItem>
        )}
      </List>
    </ModelEditDialog>
  )
}

export default ScenarioComparisonSettingsDialog