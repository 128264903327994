import React, {useEffect, useState} from 'react'
import Chart from "react-apexcharts";
import {INetWorthCalc} from "../../../../components/calculator/Calculator";
import {compareDesc, startOfTomorrow} from "date-fns";
import {dateToLocalFormat, numberToMoneyFormat} from "../../../../stores/StoreUtilities";
import {useTheme} from "@mui/material";
import cloneDeep from "lodash.clonedeep";
import {globalColors} from "../../../../styles/globalStyles";

const startYear = 2000
const years = 51

const labels: string[] = []
const netWorth: number[] = []
for (let y = 0; y < years; y++) {
  labels.push((startYear + y).toString())
  netWorth.push(200000 + (10000 * y))
}

const defaultOptions = {
  chart: {
    id: "area-datetime",
    type: 'area',
    height: 350,
    width: "100%",
    zoom: {
      enabled: false,
      autoScaleYaxis: true
    },
    // sparkline: {
    //   enabled: true
    // },
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      },
    },
    toolbar: {
      show: false,
      tools: {
        download: false
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    position: 'bottom', // TODO: Figure out why this isn't working
    horizontalAlign: 'left'
  },
  markers: {
    size: 5,
    style: 'hollow',
  },
  xaxis: {
    type: 'datetime',
    min: new Date('01 Jan 2020').getTime(),
    tickAmount: 6,
  },
  yaxis: {
    labels: {
      formatter: (val: number) => {
        return numberToMoneyFormat(val, 0)
        // if (val < 1000000) {
        //   return `$${val / 1000}K`
        // } else {
        //   return `$${val / 1000000}M`
        // }
      }
    },
    min: 0
  },
  tooltip: {
    x: {
      format: 'dd MMM yyyy'
    }
  },
  colors: [globalColors.liquidAsset, globalColors.realAsset],
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100]
    }
  },
  stroke: {
    width: 3
  },
  resonsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const NetWorthApexChart = ({
  netWorthCalcs,
  selected,
  onSelect,
  height,
  width,
  title
}: {
  netWorthCalcs: INetWorthCalc[]
  selected?: number
  onSelect?(index: number): void
  height?: string
  width?: string
  title?: string
}) => {
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<any>()

  const theme = useTheme()

  useEffect(() => {
    if (netWorthCalcs.length > 0) {
      const newOptions = cloneDeep(defaultOptions)

      const minDate = netWorthCalcs.reduce((accum: Date, item: INetWorthCalc) => compareDesc(accum, item.date) > 0 ? accum : item.date, new Date())
      if (minDate) {
        newOptions.xaxis.min = minDate.getTime()
      }
      if (selected !== undefined) {
        const calc = netWorthCalcs[selected]
        const annotations = {
          xaxis: [
            {
              x: calc.date.getTime(),
              borderColor: theme.palette.secondary.main, //'#00E396',
              label: {
                borderColor: theme.palette.secondary.main, //'#00E396',
                orientation: 'horizontal',
                text: dateToLocalFormat(calc.date, "MMM d, yyyy"),
                style: {
                  fontSize: '14px',
                  fontWeight: 600
                }
              }
            }
          ]
        }
        newOptions["annotations"] = annotations
        newOptions.chart.events["markerClick"] = (event: any, chartContext: any, details: {dataPointIndex: number, seriesIndex: number}) => {
          if (details.dataPointIndex >= 0 && onSelect) {
            onSelect(details.dataPointIndex)
          }
        }
      }

      if (height) {
        newOptions.chart.toolbar.show = false
      }
      setOptions(newOptions)

      const data = netWorthCalcs.map((item: INetWorthCalc) => [new Date(item.date).getTime(), item.netWorthTotal.toString()])
      if (data.length === 1) {
        // Add tomorrow to display graph with one data point
        data.push([startOfTomorrow().getTime(), data[0][1]])
      }
      // data.sort((a: (string | number)[], b: (string | number)[]) => (a[0] as number) - (b[0] as number))
      setSeries([
        {name: "Net Worth", data: data}
      ])
    }
  }, [netWorthCalcs, selected])

  if (options && series) {
    return (
      <Chart
        options={options}
        series={series}
        type="area"
        height={height ?? "500"}
        width={width ?? "100%"}
      />
    )
  } else {
    return null
  }
}

export default NetWorthApexChart