import PlanChange from "../PlanChange";
import Model from "../Model";
import Person from "../Person";
import Income, {IncomeType} from "../Income";
import ModelStore from "../../stores/ModelStore";
import {UpdateIncomeInput} from "../../API";

export interface ISocialSecurityStrategyChange {
  age1?: number
  startDate1?: string
  amount1?: number
  age2?: number
  startDate2?: string
  amount2?: number
}

class SocialSecurityStrategyChange extends PlanChange implements ISocialSecurityStrategyChange {
  age1?: number
  startDate1?: string
  amount1?: number
  age2?: number
  startDate2?: string
  amount2?: number
  constructor (data: any) {
    super(data)
    const items = this.details ? JSON.parse(this.details) : {}
    this.age1 = items.age1
    this.startDate1 = items.startDate1
    this.amount1 = items.amount1
    this.age2 = items.age2
    this.startDate2 = items.startDate2
    this.amount2 = items.amount2
  }

  apply(model: Model) {
    // TODO: Handle case if no social security
    if ((this.startDate1 || this.amount1) && model.person1) {
      const income = this.getSocialSecurityIncome(model, model.person1)
      if (income) {
        if (this.startDate1) {
          income.start = this.startDate1
          model.addLock(income.id, "start")
        }
        if (this.amount1) {
          income.amount = this.amount1
          model.addLock(income.id, "amount")
        }
      }
    }
    if ((this.startDate2 || this.amount2) && model.person2) {
      const income = this.getSocialSecurityIncome(model, model.person2)
      if (income) {
        if (this.startDate2) {
          income.start = this.startDate2
          model.addLock(income.id, "start")
        }
        if (this.amount2) {
          income.amount = this.amount2
          model.addLock(income.id, "amount")
        }
      }
    }
  }

  getSocialSecurityIncome(model: Model, person: Person): Income | undefined {
    const income = model.incomes.find((i: Income) => i.incomeType === IncomeType.SocialSecurityBenefits && i.ownerId === person.id)
    return income
  }

  async commit(model: Model, modelStore: ModelStore) {
    if ((this.startDate1 || this.amount1) && model.person1) {
      const income = this.getSocialSecurityIncome(model, model.person1)
      if (income) {
        const update: UpdateIncomeInput = {
          id: income.id,
          accountId: income.accountId,
        }

        if (this.startDate1) {
          // income.start = this.startDate1
          update.start = this.startDate1
        }
        if (this.amount1) {
          // income.amount = this.amount1
          update.amount = this.amount1
        }

        await modelStore.updateIncome(update)
        model.deleteLock(income.id, "start")
        model.deleteLock(income.id, "amount")
      }
    }
    if ((this.startDate2 || this.amount2) && model.person2) {
      const income = this.getSocialSecurityIncome(model, model.person2)
      if (income) {
        const update: UpdateIncomeInput = {
          id: income.id,
          accountId: income.accountId,
        }
        if (this.startDate2) {
          // income.start = this.startDate2
          update.start = this.startDate2
        }
        if (this.amount2) {
          // income.amount = this.amount2
          update.amount = this.amount2
        }

        await modelStore.updateIncome(update)
        model.deleteLock(income.id, "start")
        model.deleteLock(income.id, "amount")
      }
    }
  }
}

export default SocialSecurityStrategyChange