import {FutureCalc, IFutureYear} from "../../../../components/calculator/Calculator";
import React, {useEffect, useState} from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme
} from "@mui/material";
import {numberToMoneyFormat, numberToPercentFormat} from "../../../../stores/StoreUtilities";
import {globalColors} from "../../../../styles/globalStyles";


const FutureComparisonTable = ({
  year,
  futureCalcs,
  onChange
} : {
  year: number
  futureCalcs: FutureCalc[]
  onChange?: (year: number) => any
}) => {
  const [currentYear, setCurrentYear] = useState<number>(0)
  const [futureCalc, setFutureCalc] = useState<FutureCalc | undefined>()

  const theme = useTheme()
  const styles = {
    th: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.1,
      // width: 50,
      height: 30,
      minHeight: 30,
      width: 100,
      maxWidth: 100,
      // maxWidth: 30,
      // textAlign: "right",
      // verticalAlign: "bottom",
      // transformOrigin: "bottom center",
      // transform: "rotate(40deg)",
      // border: "1px solid red"
    },
    tb: {
      fontSize: 14,
      width: 100,
      maxWidth: 100,
    },
    tbIncome: {
      fontSize: 14,
      "&:hover": {
        backgroundColor: "#aadeee99",
        cursor: "zoom-in"
      }
    },
    tbIncomeDetail: {
      fontSize: 14,
      backgroundColor: "#aadeee99",
      cursor: "zoom-out"
    },
    tbAsset: {
      fontSize: 14,
      "&:hover": {
        backgroundColor: "#ffff0055",
        cursor: "zoom-in"
      }
    },
    tbAssetNeg: {
      color: theme.palette.error.main,
      fontSize: 14,
      "&:hover": {
        backgroundColor: "#ffff0055",
        cursor: "zoom-in"
      }
    },
    tbAssetDetail: {
      fontSize: 14,
      backgroundColor: "#ffff0055",
      cursor: "zoom-out"
    },
    tbAssetDetailNeg: {
      color: theme.palette.error.main,
      fontSize: 14,
      backgroundColor: "#ffff0055",
      cursor: "zoom-out"
    },
    tbDetail: {
      fontSize: 14,
      backgroundColor: globalColors.details
    },
    tbDetailHead: {
      fontSize: 12,
      fontWeight: 600,
      backgroundColor: globalColors.details
    },
    trRetired: {
      backgroundColor: "rgba(183, 206, 175, 0.8)"
    },
    trHisLifeExpectancy: {
      backgroundColor: "rgba(153, 206, 224, 0.8)"
    },
    trHerLifeExpectancy: {
      backgroundColor: "rgba(255, 176, 190, 0.8)"
    },
    legendLabel: {
      fontSize: 14,
      marginLeft:8,
      marginRight: 16,
      lineHeight: 1.0
    }
  }

  useEffect(() => {
    if (year) {
      setCurrentYear(year)
    }
    if (futureCalcs.length > 0) {
      setFutureCalc(futureCalcs[0])
    }
  }, [year, futureCalcs])

  const handleChange = (year: number) => {
    if (onChange) {
      setCurrentYear(year)
      onChange(year)
    }
  }

  const getYoYChange = (fc: FutureCalc, net: number) => {
    let change = 0
    if (fc!.futureYears.length > 0) {
      change = net / fc!.futureYears[0].currentLiquidAssets
    }
    return change
  }

  const renderFutureYear = (index: number) => {
    return (
      <TableRow key={index}>
        <TableCell sx={styles.tb}>{futureCalcs[0].futureYears[index].year}</TableCell>
        <TableCell sx={styles.tb}><span style={{whiteSpace:"nowrap"}}>{`${futureCalcs[0].futureYears[index].person1Age}${futureCalcs[0].futureYears[index].person1Age ? ' / ' + futureCalcs[0].futureYears[index].person2Age : ''}`}</span></TableCell>
        {futureCalcs.map((fc: FutureCalc) => [
          <TableCell sx={styles.tb} align="right">
            {numberToMoneyFormat(fc.futureYears[index].netLiquidAssets, 0)}
          </TableCell>,
          <TableCell sx={styles.tb} align="right">
            {numberToPercentFormat(getYoYChange(fc, fc.futureYears[index].netLiquidAssets), 1)}
          </TableCell>
        ])}
      </TableRow>
    )
  }

  const splitLabel = (label: string) => {
    const parts = label.split(" / ")
    if (parts.length > 1) {
      return <span>{parts[0]}<br/>{parts[1]}</span>
    } else {
      return label
    }
  }

  if (futureCalcs.length === 0 || !futureCalc) {
    return null
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{width: "max-content"}}>
        <TableHead>
          <TableRow >
            <TableCell colSpan={2}></TableCell>
            {futureCalcs.map((fc: FutureCalc) =>
              <TableCell colSpan={2} align="center">{splitLabel(fc.label)}</TableCell>
            )}
          </TableRow>
          <TableRow >
            <TableCell sx={styles.th}>Year</TableCell>
            <TableCell sx={styles.th}>Age(s)</TableCell>
            {futureCalcs.map((fc: FutureCalc) => [
              <TableCell sx={styles.th} align="right">End Balance</TableCell>,
              <TableCell sx={styles.th} align="right">Change %</TableCell>
            ])}
          </TableRow>
        </TableHead>
        <TableBody>
          {futureCalc.futureYears.map((fy: IFutureYear, index: number) =>
            renderFutureYear(index)
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default FutureComparisonTable