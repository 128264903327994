import {Typography, useTheme} from "@mui/material";
import {Link} from "@reach/router";
import * as React from "react";
import {useResources} from "../../stores/ResourceProvider";
import globalStyles from "../../styles/globalStyles";

const TaxDisclaimer = () => {
  const {termsOfUse} = useResources()

  return (
    <Typography variant="body2" gutterBottom sx={{mt:1}}>
      <strong>Tax Disclaimer</strong>&nbsp;
      The United States Federal Income Tax System is complicated. The tax estimates provided within the Retirement Budget
      Calculator are limited in scope and should not be relied upon for accuracy or tax preparation and these estimates
      are hypothetical and for illustrative purposes only. These estimates are not guaranteed and actual tax could vary significantly.
      The results herein are dependent in part on the accuracy of the information you have entered. We recommend you meet
      with a tax adviser to validate these calculations for your specific situation. Do not make changes to your financial
      or tax situation based on these estimates without getting guidance from a tax professional. These tax estimates are
      likely to change over time as congress changes the tax law and the tax tables may not be updated in a timely fashion
      to reflect current law. It is your responsibility to validate the accuracy of the information. Please review our&nbsp;
      <Link to="." onClick={() => termsOfUse.show()} style={globalStyles.link}>Terms of Use</Link>.
    </Typography>
  )
}

export default TaxDisclaimer