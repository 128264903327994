import Scheduled from "./Scheduled";
import Asset from "./Asset";
import {isoToLocalDate} from "../stores/StoreUtilities";
import IModelItem from "./IModelItem";

class Deduction extends Scheduled implements IModelItem {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  userId: string
  modelId: string
  description: string
  amount: number
  infLock: boolean
  annualInf: number
  sortOrder: number
  assetId: string
  asset?: Asset

  constructor(data: any) {
    super(data)
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.userId = data.userId
    this.modelId = data.modelId
    this.description = data.description ?? "Other"
    this.amount = data.amount ?? 0
    this.infLock = data.infLock
    this.annualInf = data.annualInf
    this.sortOrder = data.sortOrder
    this.assetId = data.assetId
  }

  get endDate(): Date | undefined {
    let endDate = this.model?.getDate(this.end)
    if (!endDate && (this.asset && this.asset.owner && this.asset.owner.retireDate)) {
      // Default to owner retire date
      endDate = isoToLocalDate(this.asset.owner.retireDate)
    }
    return endDate
  }

  get endYear(): number {
    const date = this.endDate
    if (date) {
      return date.getFullYear()
    } else {
      return 0
    }
  }
}

export default Deduction