
export enum ScheduleNames {
  None = "None",
  Once = "Once",
  Daily = "Daily",
  Weekly = "Weekly",
  BiWeekly = "Bi-weekly",
  SemiMonthly = "Semi-monthly",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  SemiAnnual = "Semi-Annual",
  Annual = "Annual",
  Custom = "Custom"
}

export enum Frequency {
  Once = "Once",
  Daily = "Daily",
  Weekly  = "Weekly",
  Monthly = "Monthly",
  Yearly = "Yearly"
}


class Schedule {
  name: string
  frequency: Frequency
  interval: number
  each?: Array<number>
  on?: Array<number> | Array<string>

  constructor (data: any) {
    if (data) {
      if (typeof data === "string") {
        data = JSON.parse(data)
      }
      this.name = data.name
      this.frequency = data.frequency ? Frequency[data.frequency] : Frequency.Once
      this.interval = data.interval ?? 1
      this.each = data.each
      this.on = data.on
    } else {
      this.name = "Once"
      this.frequency = Frequency.Once
      this.interval = 1
    }
  }

  // get description(): string {
  //   // WIP
  //   if (this.name !== "Custom") {
  //     return this.name
  //   } else {
  //     let desc = "Every "
  //     let freq = ""
  //     switch (this.frequency) {
  //       case Frequency.Daily:
  //         freq = this.interval > 1 ? `${this.interval} Days` : "Day"
  //         break
  //       case Frequency.Weekly:
  //         freq = this.interval > 1 ? `${this.interval} Weeks` : "Week"
  //         break
  //       case Frequency.Monthly:
  //         freq = this.interval > 1 ? `${this.interval} Months` : "Month"
  //         break
  //       case Frequency.Yearly:
  //         freq = this.interval > 1 ? `${this.interval} Years` : "Year"
  //         break
  //     }
  //     return "Special"
  //   }
  // }

  // nextDate(date: Date): Date | null {
  //   if (this.frequency === Frequency.Weekly) {
  //     return addWeeks(date, this.interval)
  //   } else if (this.frequency === Frequency.Monthly) {
  //     return addMonths(date, this.interval)
  //   } else if (this.frequency === Frequency.Yearly) {
  //     return addYears(date, this.interval)
  //   } else {
  //     return null
  //   }
  // }

  static defaultSchedules: Schedule[] = [
    new Schedule({name: "Once", frequency: Frequency.Once, interval: 1}),
    new Schedule({name: "Weekly", frequency: Frequency.Weekly, interval: 1}),
    new Schedule({name: "Bi-weekly", frequency: Frequency.Weekly, interval: 2}),
    new Schedule({name: "Monthly", frequency: Frequency.Monthly, interval: 1}),
    new Schedule({name: "Semi-monthly", frequency: Frequency.Monthly, interval: 1, on: [1, 15]}),
    new Schedule({name: "Quarterly", frequency: Frequency.Yearly, interval: 1, each:[1,4,7,10], on: [1]}),
    new Schedule({name: "Semi-annual", frequency: Frequency.Yearly, interval: 1, each:[1,7], on: [1]}),
    new Schedule({name: "Annual", frequency: Frequency.Yearly, interval: 1}),
    // TODO: new Schedule({name: "Custom"})
  ]

  static getDefaultSchedule(name: string) {
    return this.defaultSchedules.find((option: Schedule) => option.name === name)
  }
}

export default Schedule