import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api/lib/types/index';
import {API, Auth, graphqlOperation} from "aws-amplify";
import { print as gqlToString } from "graphql";
import * as APITypes from "../API";
import Logger from "../components/Logger";
import { getErrorMessage } from "../stores/StoreUtilities";
import * as CustomMutations from './CustomMutations';
import * as CustomQueries from './CustomQueries';

class RbcAPI {
  
  // Account methods

  async getAccount(id: string) {
    const query = gqlToString(CustomQueries.getAccount)
    const variables = { "id": id }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getAccount error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetAccountQuery && (data as APITypes.GetAccountQuery).getAccount) {
      return (data as APITypes.GetAccountQuery).getAccount
    } else {
      return null
    }
  }

  async createAccount(input: APITypes.CreateAccountInput) {
    const query = gqlToString(CustomMutations.createAccount)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateAccountMutation && (data as APITypes.CreateAccountMutation).createAccount) {
        const accountData = (data as APITypes.CreateAccountMutation).createAccount
        return accountData
      } else {
        throw new Error(`Call to create account does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createAccount error", getErrorMessage(e), input)
      return null
    }
  }

  async updateAccount(input: APITypes.UpdateAccountInput) {
    const query = gqlToString(CustomMutations.updateAccount)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updateAccount error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateAccountMutation) {
      return (data as APITypes.UpdateAccountMutation).updateAccount
    } else {
      return null
    }
  }

  async deleteAccount(id: string) {
    const query = gqlToString(CustomMutations.deleteAccount)
    const input: APITypes.DeleteAccountInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deleteAccount error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteAccountMutation).deleteAccount
    }
    return null
  }

  // User methods

  async listUsersByAccount(accountId: string, filter?: APITypes.ModelUserFilterInput, limit?: number, nextToken?: string) {
    const query = gqlToString(CustomQueries.listUsersByAccount)
    const variables = { "accountId": accountId, "filter": filter, "limit": limit ?? 10000, "nextToken": nextToken }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.listUsersByAccount error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListUsersByAccountQuery && (data as APITypes.ListUsersByAccountQuery).listUsersByAccount) {
      return (data as APITypes.ListUsersByAccountQuery).listUsersByAccount
    } else {
      return null
    }
  }

  async getUser(userId: string) {
    const query = gqlToString(CustomQueries.getUser)
    const variables = { "id": userId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getUser error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetUserQuery && (data as APITypes.GetUserQuery).getUser) {
      return (data as APITypes.GetUserQuery).getUser
    } else {
      return null
    }
  }

  async getUserOnly(userId: string) {
    const query = gqlToString(CustomQueries.getUserOnly)
    const variables = { "id": userId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getUser error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetUserQuery && (data as APITypes.GetUserQuery).getUser) {
      return (data as APITypes.GetUserQuery).getUser
    } else {
      return null
    }
  }

  async createUser(input: APITypes.CreateUserInput) {
    const query = gqlToString(CustomMutations.createUser)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)

      if (data as APITypes.CreateUserMutation && (data as APITypes.CreateUserMutation).createUser) {
        const userData = (data as APITypes.CreateUserMutation).createUser
        return userData
      } else {
        throw new Error(`Call to create user does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createUser error", getErrorMessage(e), input)
      return null
    }
  }

  async updateUser(input: APITypes.UpdateUserInput) {
    if (input.email) {
      input.email = input.email.toLowerCase()
    }
    const query = gqlToString(CustomMutations.updateUser)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updateUser error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateUserMutation) {
      return (data as APITypes.UpdateUserMutation).updateUser
    } else {
      return null
    }
  }

  async deleteUser(id: string) {
    const query = gqlToString(CustomMutations.deleteUser)
    const input: APITypes.DeleteUserInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deleteUser error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteUserMutation).deleteUser
    }
    return null
  }

  // UserInvite methods

  async getUserInvite(userInviteId: string) {
    const query = gqlToString(CustomQueries.getUserInvite)
    const variables = { "id": userInviteId }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getUserInvite error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetUserInviteQuery && (data as APITypes.GetUserInviteQuery).getUserInvite) {
      return (data as APITypes.GetUserInviteQuery).getUserInvite
    } else {
      return null
    }
  }


  async listUserInvitesByAccount(accountId: string, filter?: APITypes.ModelUserFilterInput, limit?: number, nextToken?: string) {
    const query = gqlToString(CustomQueries.listUserInvitesByAccount)
    const variables = { "accountId": accountId, "filter": filter, "limit": limit ?? 10000, "nextToken": nextToken }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.listUserInvitesByAccount error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListUserInvitesByAccountQuery && (data as APITypes.ListUserInvitesByAccountQuery).listUserInvitesByAccount) {
      return (data as APITypes.ListUserInvitesByAccountQuery).listUserInvitesByAccount
    } else {
      return null
    }
  }
  
  async createUserInvite(input: APITypes.CreateUserInviteInput) {
    const query = gqlToString(CustomMutations.createUserInvite)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateUserInviteMutation && (data as APITypes.CreateUserInviteMutation).createUserInvite) {
        const userInviteData = (data as APITypes.CreateUserInviteMutation).createUserInvite
        return userInviteData
      } else {
        throw new Error(`Call to create userInvite does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createUserInvite error", getErrorMessage(e), input)
      return null
    }
  }

  async updateUserInvite(input: APITypes.UpdateUserInviteInput) {
    const query = gqlToString(CustomMutations.updateUserInvite)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updateUserInvite error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateUserInviteMutation) {
      return (data as APITypes.UpdateUserInviteMutation).updateUserInvite
    } else {
      return null
    }
  }

  async deleteUserInvite(id: string) {
    const query = gqlToString(CustomMutations.deleteUserInvite)
    const input: APITypes.DeleteUserInviteInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deleteUserInvite error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteUserInviteMutation).deleteUserInvite
    }
    return null
  }
  
  // UserSetting methods

  async getUserSetting(id: string) {
    const query = gqlToString(CustomQueries.getUserSetting)
    const variables = { "id": id }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getUserSetting error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetUserSettingQuery && (data as APITypes.GetUserSettingQuery).getUserSetting) {
      return (data as APITypes.GetUserSettingQuery).getUserSetting
    } else {
      return null
    }
  }

  async createUserSetting(input: APITypes.CreateUserSettingInput) {
    const query = gqlToString(CustomMutations.createUserSetting)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateUserSettingMutation && (data as APITypes.CreateUserSettingMutation).createUserSetting) {
        const userSettingData = (data as APITypes.CreateUserSettingMutation).createUserSetting
        return userSettingData
      } else {
        throw new Error(`Call to create userSetting does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createUserSetting error", getErrorMessage(e), input)
      return null
    }
  }

  async updateUserSetting(input: APITypes.UpdateUserSettingInput) {
    const query = gqlToString(CustomMutations.updateUserSetting)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updateUserSetting error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateUserSettingMutation) {
      return (data as APITypes.UpdateUserSettingMutation).updateUserSetting
    } else {
      return null
    }
  }

  async deleteUserSetting(id: string) {
    const query = gqlToString(CustomMutations.deleteUserSetting)
    const input: APITypes.DeleteUserSettingInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deleteUserSetting error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteUserSettingMutation).deleteUserSetting
    }
    return null
  }

  // Model methods

  async listModelsByUser(userId: string, filter?: APITypes.ModelModelFilterInput, limit?: number, nextToken?: string) {
    const query = gqlToString(CustomQueries.listModelsByUser)
    const variables = { "userId": userId, "filter": filter, "limit": limit ?? 10000, "nextToken": nextToken }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.listModelsByUser error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListModelsByUserQuery && (data as APITypes.ListModelsByUserQuery).listModelsByUser) {
      return (data as APITypes.ListModelsByUserQuery).listModelsByUser
    } else {
      return null
    }
  }


  async getModel(id: string) {
    const query = gqlToString(CustomQueries.getModel)
    const variables = { "id": id }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getModel error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetModelQuery && (data as APITypes.GetModelQuery).getModel) {
      return (data as APITypes.GetModelQuery).getModel
    } else {
      return null
    }
  }

  async createModel(input: APITypes.CreateModelInput) {
    const query = gqlToString(CustomMutations.createModel)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateModelMutation && (data as APITypes.CreateModelMutation).createModel) {
        const modelData = (data as APITypes.CreateModelMutation).createModel
        return modelData
      } else {
        throw new Error(`Call to create model does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createModel error", getErrorMessage(e), input)
      return null
    }
  }

  async updateModel(input: APITypes.UpdateModelInput) {
    const query = gqlToString(CustomMutations.updateModel)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updateModel error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateModelMutation) {
      return (data as APITypes.UpdateModelMutation).updateModel
    } else {
      return null
    }
  }

  async deleteModel(id: string) {
    const query = gqlToString(CustomMutations.deleteModel)
    const input: APITypes.DeleteModelInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deleteModel error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteModelMutation).deleteModel
    }
    return null
  }

  // Snapshot methods

  async listSnapshotsByModel(modelId: string, dateFilter: APITypes.ModelStringKeyConditionInput, filter?: APITypes.ModelSnapshotFilterInput, limit?: number, nextToken?: string) {
    const query = gqlToString(CustomQueries.listSnapshotsByModel)
    const variables = { "modelId": modelId, "date": dateFilter, "filter": filter, "limit": limit ?? 10000, "nextToken": nextToken }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.listSnapshotsByModel error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListSnapshotsByModelQuery && (data as APITypes.ListSnapshotsByModelQuery).listSnapshotsByModel) {
      return (data as APITypes.ListSnapshotsByModelQuery).listSnapshotsByModel
    } else {
      return null
    }
  }

  async getSnapshot(id: string) {
    const query = gqlToString(CustomQueries.getSnapshot)
    const variables = { "id": id }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getSnapshot error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetSnapshotQuery && (data as APITypes.GetSnapshotQuery).getSnapshot) {
      return (data as APITypes.GetSnapshotQuery).getSnapshot
    } else {
      return null
    }
  }

  async createSnapshot(input: APITypes.CreateSnapshotInput) {
    const query = gqlToString(CustomMutations.createSnapshot)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateSnapshotMutation && (data as APITypes.CreateSnapshotMutation).createSnapshot) {
        const snapshotData = (data as APITypes.CreateSnapshotMutation).createSnapshot
        return snapshotData
      } else {
        throw new Error(`Call to create snapshot does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createSnapshot error", getErrorMessage(e), input)
      return null
    }
  }

  async updateSnapshot(input: APITypes.UpdateSnapshotInput) {
    const query = gqlToString(CustomMutations.updateSnapshot)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updateSnapshot error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateSnapshotMutation) {
      return (data as APITypes.UpdateSnapshotMutation).updateSnapshot
    } else {
      return null
    }
  }

  async deleteSnapshot(id: string) {
    const query = gqlToString(CustomMutations.deleteSnapshot)
    const input: APITypes.DeleteSnapshotInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deleteSnapshot error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteSnapshotMutation).deleteSnapshot
    }
    return null
  }

  // Person methods

  async getPerson(id: string) {
    const query = gqlToString(CustomQueries.getPerson)
    const variables = { "id": id }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getPerson error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetPersonQuery && (data as APITypes.GetPersonQuery).getPerson) {
      return (data as APITypes.GetPersonQuery).getPerson
    } else {
      return null
    }
  }

  async createPerson(input: APITypes.CreatePersonInput) {
    const query = gqlToString(CustomMutations.createPerson)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreatePersonMutation && (data as APITypes.CreatePersonMutation).createPerson) {
        const personData = (data as APITypes.CreatePersonMutation).createPerson
        return personData
      } else {
        throw new Error(`Call to create person does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createPerson error", getErrorMessage(e), input)
      return null
    }
  }

  async updatePerson(input: APITypes.UpdatePersonInput) {
    const query = gqlToString(CustomMutations.updatePerson)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updatePerson error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdatePersonMutation) {
      return (data as APITypes.UpdatePersonMutation).updatePerson
    } else {
      return null
    }
  }

  async deletePerson(id: string) {
    const query = gqlToString(CustomMutations.deletePerson)
    const input: APITypes.DeletePersonInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deletePerson error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeletePersonMutation).deletePerson
    }
    return null
  }

  // Asset methods

  async getAsset(id: string) {
    const query = gqlToString(CustomQueries.getAsset)
    const variables = { "id": id }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getAsset error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetAssetQuery && (data as APITypes.GetAssetQuery).getAsset) {
      return (data as APITypes.GetAssetQuery).getAsset
    } else {
      return null
    }
  }

  async createAsset(input: APITypes.CreateAssetInput) {
    const query = gqlToString(CustomMutations.createAsset)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateAssetMutation && (data as APITypes.CreateAssetMutation).createAsset) {
        const assetData = (data as APITypes.CreateAssetMutation).createAsset
        return assetData
      } else {
        throw new Error(`Call to create asset does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createAsset error", getErrorMessage(e), input)
      return null
    }
  }

  async updateAsset(input: APITypes.UpdateAssetInput) {
    const query = gqlToString(CustomMutations.updateAsset)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updateAsset error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateAssetMutation) {
      return (data as APITypes.UpdateAssetMutation).updateAsset
    } else {
      return null
    }
  }

  async deleteAsset(id: string) {
    const query = gqlToString(CustomMutations.deleteAsset)
    const input: APITypes.DeleteAssetInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deleteAsset error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteAssetMutation).deleteAsset
    }
    return null
  }

  // AssetConversion methods

  async getAssetConversion(id: string) {
    const query = gqlToString(CustomQueries.getAssetConversion)
    const variables = { "id": id }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getAssetConversion error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetAssetConversionQuery && (data as APITypes.GetAssetConversionQuery).getAssetConversion) {
      return (data as APITypes.GetAssetConversionQuery).getAssetConversion
    } else {
      return null
    }
  }

  async createAssetConversion(input: APITypes.CreateAssetConversionInput) {
    const query = gqlToString(CustomMutations.createAssetConversion)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateAssetConversionMutation && (data as APITypes.CreateAssetConversionMutation).createAssetConversion) {
        const assetConversionData = (data as APITypes.CreateAssetConversionMutation).createAssetConversion
        return assetConversionData
      } else {
        throw new Error(`Call to create assetConversion does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createAssetConversion error", getErrorMessage(e), input)
      return null
    }
  }

  async updateAssetConversion(input: APITypes.UpdateAssetConversionInput) {
    const query = gqlToString(CustomMutations.updateAssetConversion)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updateAssetConversion error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateAssetConversionMutation) {
      return (data as APITypes.UpdateAssetConversionMutation).updateAssetConversion
    } else {
      return null
    }
  }

  async deleteAssetConversion(id: string) {
    const query = gqlToString(CustomMutations.deleteAssetConversion)
    const input: APITypes.DeleteAssetConversionInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deleteAssetConversion error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteAssetConversionMutation).deleteAssetConversion
    }
    return null
  }
  
  // Liability methods

  async getLiability(id: string) {
    const query = gqlToString(CustomQueries.getLiability)
    const variables = { "id": id }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getLiability error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetLiabilityQuery && (data as APITypes.GetLiabilityQuery).getLiability) {
      return (data as APITypes.GetLiabilityQuery).getLiability
    } else {
      return null
    }
  }

  async createLiability(input: APITypes.CreateLiabilityInput) {
    const query = gqlToString(CustomMutations.createLiability)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateLiabilityMutation && (data as APITypes.CreateLiabilityMutation).createLiability) {
        const liabilityData = (data as APITypes.CreateLiabilityMutation).createLiability
        return liabilityData
      } else {
        throw new Error(`Call to create liability does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createLiability error", getErrorMessage(e), input)
      return null
    }
  }

  async updateLiability(input: APITypes.UpdateLiabilityInput) {
    const query = gqlToString(CustomMutations.updateLiability)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updateLiability error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateLiabilityMutation) {
      return (data as APITypes.UpdateLiabilityMutation).updateLiability
    } else {
      return null
    }
  }

  async deleteLiability(id: string) {
    const query = gqlToString(CustomMutations.deleteLiability)
    const input: APITypes.DeleteLiabilityInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deleteLiability error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteLiabilityMutation).deleteLiability
    }
    return null
  }
  
  // Income methods

  async getIncome(id: string) {
    const query = gqlToString(CustomQueries.getIncome)
    const variables = { "id": id }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getIncome error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetIncomeQuery && (data as APITypes.GetIncomeQuery).getIncome) {
      return (data as APITypes.GetIncomeQuery).getIncome
    } else {
      return null
    }
  }

  async createIncome(input: APITypes.CreateIncomeInput) {
    const query = gqlToString(CustomMutations.createIncome)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateIncomeMutation && (data as APITypes.CreateIncomeMutation).createIncome) {
        const incomeData = (data as APITypes.CreateIncomeMutation).createIncome
        return incomeData
      } else {
        throw new Error(`Call to create income does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createIncome error", getErrorMessage(e), input)
      return null
    }
  }

  async updateIncome(input: APITypes.UpdateIncomeInput) {
    const query = gqlToString(CustomMutations.updateIncome)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updateIncome error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateIncomeMutation) {
      return (data as APITypes.UpdateIncomeMutation).updateIncome
    } else {
      return null
    }
  }

  async deleteIncome(id: string) {
    const query = gqlToString(CustomMutations.deleteIncome)
    const input: APITypes.DeleteIncomeInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deleteIncome error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteIncomeMutation).deleteIncome
    }
    return null
  }
  
  // Deduction methods

  async getDeduction(id: string) {
    const query = gqlToString(CustomQueries.getDeduction)
    const variables = { "id": id }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getDeduction error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetDeductionQuery && (data as APITypes.GetDeductionQuery).getDeduction) {
      return (data as APITypes.GetDeductionQuery).getDeduction
    } else {
      return null
    }
  }

  async createDeduction(input: APITypes.CreateDeductionInput) {
    const query = gqlToString(CustomMutations.createDeduction)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateDeductionMutation && (data as APITypes.CreateDeductionMutation).createDeduction) {
        const deductionData = (data as APITypes.CreateDeductionMutation).createDeduction
        return deductionData
      } else {
        throw new Error(`Call to create deduction does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createDeduction error", getErrorMessage(e), input)
      return null
    }
  }

  async updateDeduction(input: APITypes.UpdateDeductionInput) {
    const query = gqlToString(CustomMutations.updateDeduction)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updateDeduction error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateDeductionMutation) {
      return (data as APITypes.UpdateDeductionMutation).updateDeduction
    } else {
      return null
    }
  }

  async deleteDeduction(id: string) {
    const query = gqlToString(CustomMutations.deleteDeduction)
    const input: APITypes.DeleteDeductionInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deleteDeduction error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteDeductionMutation).deleteDeduction
    }
    return null
  }
  
  // Tax methods

  async getTax(id: string) {
    const query = gqlToString(CustomQueries.getTax)
    const variables = { "id": id }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getTax error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetTaxQuery && (data as APITypes.GetTaxQuery).getTax) {
      return (data as APITypes.GetTaxQuery).getTax
    } else {
      return null
    }
  }

  async createTax(input: APITypes.CreateTaxInput) {
    const query = gqlToString(CustomMutations.createTax)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateTaxMutation && (data as APITypes.CreateTaxMutation).createTax) {
        const taxData = (data as APITypes.CreateTaxMutation).createTax
        return taxData
      } else {
        throw new Error(`Call to create tax does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createTax error", getErrorMessage(e), input)
      return null
    }
  }

  async updateTax(input: APITypes.UpdateTaxInput) {
    const query = gqlToString(CustomMutations.updateTax)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updateTax error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateTaxMutation) {
      return (data as APITypes.UpdateTaxMutation).updateTax
    } else {
      return null
    }
  }

  async deleteTax(id: string) {
    const query = gqlToString(CustomMutations.deleteTax)
    const input: APITypes.DeleteTaxInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deleteTax error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteTaxMutation).deleteTax
    }
    return null
  }
  
  // Expense methods

  async getExpense(id: string) {
    const query = gqlToString(CustomQueries.getExpense)
    const variables = { "id": id }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getExpense error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetExpenseQuery && (data as APITypes.GetExpenseQuery).getExpense) {
      return (data as APITypes.GetExpenseQuery).getExpense
    } else {
      return null
    }
  }

  async createExpense(input: APITypes.CreateExpenseInput) {
    const query = gqlToString(CustomMutations.createExpense)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateExpenseMutation && (data as APITypes.CreateExpenseMutation).createExpense) {
        const expenseData = (data as APITypes.CreateExpenseMutation).createExpense
        return expenseData
      } else {
        throw new Error(`Call to create expense does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createExpense error", getErrorMessage(e), input)
      return null
    }
  }

  async updateExpense(input: APITypes.UpdateExpenseInput) {
    const query = gqlToString(CustomMutations.updateExpense)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updateExpense error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateExpenseMutation) {
      return (data as APITypes.UpdateExpenseMutation).updateExpense
    } else {
      return null
    }
  }

  async deleteExpense(id: string) {
    const query = gqlToString(CustomMutations.deleteExpense)
    const input: APITypes.DeleteExpenseInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deleteExpense error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteExpenseMutation).deleteExpense
    }
    return null
  }
  
  // TaxValue methods

  async getTaxValue(id: string) {
    const query = gqlToString(CustomQueries.getTaxValue)
    const variables = { "id": id }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getTaxValue error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetTaxValueQuery && (data as APITypes.GetTaxValueQuery).getTaxValue) {
      return (data as APITypes.GetTaxValueQuery).getTaxValue
    } else {
      return null
    }
  }

  async createTaxValue(input: APITypes.CreateTaxValueInput) {
    const query = gqlToString(CustomMutations.createTaxValue)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateTaxValueMutation && (data as APITypes.CreateTaxValueMutation).createTaxValue) {
        const taxValueData = (data as APITypes.CreateTaxValueMutation).createTaxValue
        return taxValueData
      } else {
        throw new Error(`Call to create taxValue does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createTaxValue error", getErrorMessage(e), input)
      return null
    }
  }

  async updateTaxValue(input: APITypes.UpdateTaxValueInput) {
    const query = gqlToString(CustomMutations.updateTaxValue)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updateTaxValue error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateTaxValueMutation) {
      return (data as APITypes.UpdateTaxValueMutation).updateTaxValue
    } else {
      return null
    }
  }

  async deleteTaxValue(id: string) {
    const query = gqlToString(CustomMutations.deleteTaxValue)
    const input: APITypes.DeleteTaxValueInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deleteTaxValue error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteTaxValueMutation).deleteTaxValue
    }
    return null
  }
  
  // Event methods

  // async getEvent(id: string) {
  //   const query = gqlToString(CustomQueries.getEvent)
  //   const variables = { "id": id }
  //   const data = await this.makeQuery(query, variables)
  //     .catch(err => {
  //       Logger.error("RbcAPI.getEvent error", err.message, variables)
  //       throw err
  //     })
  //   if (data as APITypes.GetEventQuery && (data as APITypes.GetEventQuery).getEvent) {
  //     return (data as APITypes.GetEventQuery).getEvent
  //   } else {
  //     return null
  //   }
  // }
  //
  // async createEvent(input: APITypes.CreateEventInput) {
  //   const query = gqlToString(CustomMutations.createEvent)
  //   const variables = { input }
  //   try {
  //     const data = await this.makeQuery(query, variables)
  //     if (data as APITypes.CreateEventMutation && (data as APITypes.CreateEventMutation).createEvent) {
  //       const eventData = (data as APITypes.CreateEventMutation).createEvent
  //       return eventData
  //     } else {
  //       throw new Error(`Call to create event does not contain data: ${data}`)
  //     }
  //   } catch (e) {
  //     Logger.error("RbcAPI.createEvent error", getErrorMessage(e), input)
  //     return null
  //   }
  // }
  //
  // async updateEvent(input: APITypes.UpdateEventInput) {
  //   const query = gqlToString(CustomMutations.updateEvent)
  //   const variables = { "input": input }
  //   const data = await this.makeQuery(query, variables)
  //     .catch(err => {
  //       Logger.error("RbcAPI.updateEvent error", err.message, input)
  //       throw err
  //     })
  //   if (data as APITypes.UpdateEventMutation) {
  //     return (data as APITypes.UpdateEventMutation).updateEvent
  //   } else {
  //     return null
  //   }
  // }
  //
  // async deleteEvent(id: string) {
  //   const query = gqlToString(CustomMutations.deleteEvent)
  //   const input: APITypes.DeleteEventInput = {
  //     id
  //   }
  //   const data = await this.makeQuery(query, { input })
  //     .catch(err => {
  //       Logger.error("RbcAPI.deleteEvent error", err.message, input)
  //       throw err
  //     })
  //   if (data) {
  //     return (data as APITypes.DeleteEventMutation).deleteEvent
  //   }
  //   return null
  // }

  // Plan methods

  async listPlansByModel(modelId: string, filter?: APITypes.ModelPlanFilterInput, limit?: number, nextToken?: string) {
    const query = gqlToString(CustomQueries.listPlansByModel)
    const variables = { "modelId": modelId, "filter": filter, "limit": limit ?? 10000, "nextToken": nextToken }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.listPlansByModel error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListPlansByModelQuery && (data as APITypes.ListPlansByModelQuery).listPlansByModel) {
      return (data as APITypes.ListPlansByModelQuery).listPlansByModel
    } else {
      return null
    }
  }
  
  async getPlan(id: string) {
    const query = gqlToString(CustomQueries.getPlan)
    const variables = { "id": id }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getPlan error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetPlanQuery && (data as APITypes.GetPlanQuery).getPlan) {
      return (data as APITypes.GetPlanQuery).getPlan
    } else {
      return null
    }
  }

  async createPlan(input: APITypes.CreatePlanInput) {
    const query = gqlToString(CustomMutations.createPlan)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreatePlanMutation && (data as APITypes.CreatePlanMutation).createPlan) {
        const planData = (data as APITypes.CreatePlanMutation).createPlan
        return planData
      } else {
        throw new Error(`Call to create plan does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createPlan error", getErrorMessage(e), input)
      return null
    }
  }

  async updatePlan(input: APITypes.UpdatePlanInput) {
    const query = gqlToString(CustomMutations.updatePlan)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updatePlan error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdatePlanMutation) {
      return (data as APITypes.UpdatePlanMutation).updatePlan
    } else {
      return null
    }
  }

  async deletePlan(id: string) {
    const query = gqlToString(CustomMutations.deletePlan)
    const input: APITypes.DeletePlanInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deletePlan error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeletePlanMutation).deletePlan
    }
    return null
  }

  // PlanChange methods

  async getPlanChange(id: string) {
    const query = gqlToString(CustomQueries.getPlanChange)
    const variables = { "id": id }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.getPlanChange error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetPlanChangeQuery && (data as APITypes.GetPlanChangeQuery).getPlanChange) {
      return (data as APITypes.GetPlanChangeQuery).getPlanChange
    } else {
      return null
    }
  }

  async createPlanChange(input: APITypes.CreatePlanChangeInput) {
    const query = gqlToString(CustomMutations.createPlanChange)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreatePlanChangeMutation && (data as APITypes.CreatePlanChangeMutation).createPlanChange) {
        const planChangeData = (data as APITypes.CreatePlanChangeMutation).createPlanChange
        return planChangeData
      } else {
        throw new Error(`Call to create planChange does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("RbcAPI.createPlanChange error", getErrorMessage(e), input)
      return null
    }
  }

  async updatePlanChange(input: APITypes.UpdatePlanChangeInput) {
    const query = gqlToString(CustomMutations.updatePlanChange)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("RbcAPI.updatePlanChange error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdatePlanChangeMutation) {
      return (data as APITypes.UpdatePlanChangeMutation).updatePlanChange
    } else {
      return null
    }
  }

  async deletePlanChange(id: string) {
    const query = gqlToString(CustomMutations.deletePlanChange)
    const input: APITypes.DeletePlanChangeInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("RbcAPI.deletePlanChange error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeletePlanChangeMutation).deletePlanChange
    }
    return null
  }
  
  // Helper methods

  async makeQuery(query: string, variables?: {}) {
    try {
      // await this.checkAuthentication()
      const operation = graphqlOperation(query, variables)
      // console.log(JSON.stringify(operation))
      const result = await API.graphql(operation)
      if (result as GraphQLResult) {
        const data = (result as GraphQLResult).data
        return data
      } else {
        return null
      }
    } catch (err: any) {
      // console.log("makeQuery error", err)
      if (err.message) {
        throw err
      } else if (err.errors && err.errors.length > 0) {
        throw new Error(err.errors[0].message)
      } else {
        throw new Error(`Unknown error: ${err}`)
      }
    }
  }

  async makeAPIKeyQuery(query: string, variables?: {}) {
    try {
      // await this.checkAuthentication()
      const result = await API.graphql({
        query: query,
        variables,
        authMode: GRAPHQL_AUTH_MODE.API_KEY
      })
      if (result as GraphQLResult) {
        const data = (result as GraphQLResult).data
        return data
      } else {
        return null
      }
    } catch (err: any) {
      // console.log("makeQuery error", err)
      if (err.message) {
        throw err
      } else if (err.errors && err.errors.length > 0) {
        throw new Error(err.errors[0].message)
      } else {
        throw new Error(`Unknown error: ${err}`)
      }
    }
  }

  async checkAuthentication(): Promise<boolean> {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      if (cognitoUser) {
        return true
      }
    } catch (err) {
      // Logger.debug("checkAuthentication err: " + err.message)
    }

    return false
  }

}

export default RbcAPI