import {CircularProgress} from "@mui/material";

const LoadingProgress = () => {
  const styles = {
    progress: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      width: "100%",
      justifyContent: "space-evenly",
      alignItems: "center",
      marginTop: "calc((100vh - 140px)/2)",
      marginLeft: "auto",
      marginRight: "auto"
    }
  }

  return (
    <CircularProgress sx={styles.progress} />
  )
}

export default LoadingProgress