import {Box, Button, Typography, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useResources} from "../../stores/ResourceProvider";
import {DataGrid, GridColDef, GridRenderCellParams, GridToolbarQuickFilter, useGridApiRef} from "@mui/x-data-grid";
import {isoToLocalDateString} from "../../stores/StoreUtilities";
import User from "../../model/User";
import Account from "../../model/Account";
import UserEditDialog from "../settings/UserEditDialog";
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import MenuButton from "../../components/controls/MenuButton";
import {useNavigate} from "react-router-dom";
import RoutesConfig from "../../RoutesConfig";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {UpdateAssetInput, UpdateSnapshotInput, UserRole, UserStatus, UserType} from "../../API";
import Snapshot, {ISnapshotDetail, SnapshotDetail} from "../../model/Snapshot";
import Asset from "../../model/Asset";
import Liability from "../../model/Liability";
import {ConvertKitTag} from "../../apis/ConvertKitAPI";
import ProgressButton from "../../components/form/ProgressButton";

const ManageUsersView = () => {
  const [account, setAccount] = useState<Account | undefined>()
  const [users, setUsers] = useState<User[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [editUser, setEditUser] = useState<User | undefined>()
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const { userStore, accountStore, notify, modelStore, convertKitAPI, rbcAPI, migrateAPI } = useResources()
  const navigate = useNavigate()
  const theme = useTheme()

  const apiRef = useGridApiRef()

  useEffect(() => {
    if (userStore.isLoading === false && userStore.user && userStore.user.account) {
      setAccount(userStore.user.account)
      setUsers([])
      if (!isLoading) {
        loadUsers(userStore.user.account)
      }
    }
  }, [userStore.isLoading])

  const loadUsers = async (account: Account) => {
    try {
      // const filter: ModelUserFilterInput = {
      //   and : [
      //     {userStatus: {ne: UserStatus.Cancelled}},
      //     {userStatus: {ne: UserStatus.Deleted}},
      //     ]
      //   }

      setIsLoading(true)
      let list = await accountStore.listUsersByAccount(account.id)
      setUsers(list)
      // if (list && list.length > 0) {
      //   let sorted = [...list]
      //   sorted.sort((a: User, b: User) => a.fullName.localeCompare(b.fullName))
      //   setUsers(sorted)
      // }
    } catch (err: any) {
      console.error("loadUsers error", err)
      notify.show("error", err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleAddUser = () => {
    const newUser = new User({
      id: "",
      userType: UserType.Client,
      userStatus: UserStatus.Created,
      accountId: accountStore.account!.id,
      firstName: "",
      lastName: "",
      email: "",
      role: UserRole.Customer,
      advisorAccess: true
    })
    setEditUser(newUser)
  }

  const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay))

  const handleUpdateTags = async () => {
    console.log(`Update Tags`)
    for (let u of users) {
      if (u.migrateStatus === "Success") {
        console.log(`Add RBC Migrated: ${u.email}`)
        await convertKitAPI.addTag(ConvertKitTag.Migrated, u.email)
        sleep(100)
      }
      if (u.startDate && u.startDate >= "2024-06-19" && u.userStatus === "Active") {
        if (u.userType === "Free") {
          console.log(`Add RBC Free: ${u.email}`)
          await convertKitAPI.addSubscriber({
            email: u.email,
            first_name: u.firstName,
            last_name: u.lastName,
            tags: [ConvertKitTag.FreeSubscription]
          })
          sleep(100)
        } else if (u.userType === "Premium") {
          console.log(`Add RBC Premium: ${u.email}`)
          await convertKitAPI.addSubscriber({
            email: u.email,
            first_name: u.firstName,
            last_name: u.lastName,
            tags: [ConvertKitTag.PremiumSubscription]
          })
          sleep(100)
        }
      }
      if (u.subscribedOn) {
        console.log(`Add RBC Premium: ${u.email}`)
      }
    }
  }

  // const handleEdit = (userId?: string) => {
  //   const user = users.find((u: User) => u.id === userId)
  //   if (user) {
  //     setEditUser(user)
  //   }
  // }

  const handleSaveUser = (user: User) => {
    accountStore.updateUser(user)
    const index = users.findIndex((u: User) => u.id === user.id)
    if (index >= 0) {
      const newUsers = [...users]
      newUsers.splice(index, 1, user)
      setUsers(newUsers)
    } else {
      setUsers([...users, user])
    }
  }

  const handleDeleteUser = (user: User) => {
    const index = users.findIndex((u: User) => u.id === user.id)
    if (index >= 0) {
      const newUsers = [...users]
      newUsers.splice(index, 1)
      setUsers(newUsers)
    }
  }

  const handleFixSnapshots = async (user: User) => {
    console.log(`Fixing snapshots for: ${user.fullName}`)
    const models = await modelStore.loadUserModels(user)
    for (let m of models) {
      const model = await modelStore.getModel(m.id)
      if (model) {
        console.log(`Fixing model: ${model.name}`)
        if (model.snapshots.length > 0) {
          // Add missing snapshot detail typenames
          const details: ISnapshotDetail[] = []
          const snapshotPromises: Promise<Snapshot | undefined>[] = []
          model.snapshots.forEach((snapshot: Snapshot) => {
            let isUpdated = false
            const details: SnapshotDetail[] = []
            snapshot.details.forEach((detail: SnapshotDetail) => {
              if (!detail.typename) {
                const asset = model.assets.find((a: Asset) => a.id === detail.id)
                if (asset) {
                  detail.typename = "Asset"
                  isUpdated = true
                } else {
                  const liability = model.liabilities.find((l: Liability) => l.id === detail.id)
                  if (liability) {
                    detail.typename = "Liability"
                    isUpdated = true
                  }
                }
              }
              details.push(detail)
            })

            if (isUpdated) {
              const update: UpdateSnapshotInput = {
                id: snapshot.id,
                details: JSON.stringify(details)
              }
              snapshotPromises.push(modelStore.updateSnapshot(update))
            }
          })

          console.log(`Fixed ${snapshotPromises.length} snapshots`)
          if (snapshotPromises.length > 0) {
            await Promise.all(snapshotPromises)
          }
        }
      }
    }
    console.log(`Fixing completed for: ${user.fullName}`)
  }

  const AddMigratedTag = async (user: User) => {
    convertKitAPI.addTag(ConvertKitTag.Migrated, user.email)
      .then ((response: any) => {
        console.log(`Added Tag`, response)
      })
      .catch ((err: any) => {
        console.error("Add Tag Error", err)
      })
  }

  const formatDate = (value?: string) => {
    return isoToLocalDateString(value, "MM/dd/yyyy")
  }

  const formatDateTime = (value?: string) => {
    return isoToLocalDateString(value, "MM/dd/yyyy h:mm a")
  }

  enum UserMenuOption {
    Access = "Access Account",
    Edit = "Edit",
    ViewInStripe = "View in Stripe",
    FixSnapshots = "Fix snapshots",
    AddMigratedTag = "Add Migrated Tag",
  }

  const getMenuOptions = (user: User): UserMenuOption[] => {
    let options: UserMenuOption[] = [UserMenuOption.Edit]
    if (user.advisorAccess || userStore.isAdmin) {
      options.push(UserMenuOption.Access)
    }
    if (user.customerId) {
      options.push(UserMenuOption.ViewInStripe)
    }
    // options.push(UserMenuOption.FixSnapshots)
    // options.push(UserMenuOption.AddMigratedTag)
    return options
  }

  const handleMenuOption = (option: UserMenuOption, user: User) => {
    if (option === UserMenuOption.Edit) {
      setEditUser(user)
    } else if (option === UserMenuOption.Access) {
      navigate(`${RoutesConfig.calc.pathname}/dashboard?client=${user.id}`)
    } else if (option === UserMenuOption.ViewInStripe) {
      viewStripeCustomer(user.customerId)
    } else if (option === UserMenuOption.FixSnapshots) {
      handleFixSnapshots(user)
    } else if (option === UserMenuOption.AddMigratedTag) {
      AddMigratedTag(user)
    }
  }

  const handleMigrateAssetEndBalances = async () => {
    try {
      setIsProcessing(true)
      const filtered = users.filter((u: User) => u.migrateStatus === "Success")
      console.log(`filtered.length = ${filtered.length}`)
      let start = 0
      let value = window.localStorage.getItem("migrateIndex")
      if (value) {
        let num = parseInt(value)
        if (num > start) {
          start = num
        }
      }

      for (let index = start; index < filtered.length; index++) {
        let user = filtered[index]
        apiRef.current.selectRow(user.id, true, true)
        apiRef.current.scrollToIndexes({rowIndex: index})
        // if (user.fullName !== "Test, Brian") {
        //   continue
        // }
        if (user.userType === UserType.Client) {
          continue
        }
        window.localStorage.setItem("migrateIndex", String(index))
        console.log(`${index}: User: ${user.fullName} Email: ${user.email}`)

        const models = await rbcAPI.listModelsByUser(user.id)
        if (models && models.items) {
          // console.log(`User: ${user.email} Models: ${models && models.items ? models.items.length : 0}`)
          for (let model of models.items) {
            if (model) {
              // Make sure it is a migrated model id
              const budgetId = parseInt(model.id)
              if (!isNaN(budgetId)) {
                const items = await migrateAPI.listAssets(model.id)
                if (items) {
                  // console.log(`Model: ${model.name} Assets: ${items.length}`)
                  for (let item of items) {
                    if (item.balance_ystart) {
                      const asset = await rbcAPI.getAsset(String(item.ua_id))
                      if (asset && !asset.priorEoYBalance) {
                        const update: UpdateAssetInput = {
                          id: asset.id,
                          priorEoYBalance: item.balance_ystart
                        }
                        const updated = await rbcAPI.updateAsset(update)
                        if (updated) {
                          console.log(`Updated: ${model.name} / ${asset.description}: priorEoyBalance: ${updated.priorEoYBalance}`)
                        }
                      }
                    }
                  }
                }
                // await sleep(1000 * 60)
              }
            }
          }
        }
        await sleep (1000 * 60)
      }
    } catch (err: any) {
      notify.show("error", err.message)
    } finally {
      setIsProcessing(false)
    }
  }

  const viewStripeCustomer = (customer_id: string) => {
    const href = window.location.href
    const env = (href.indexOf('localhost') >= 0 || href.indexOf('dev.') >= 0) ? 'test' : 'live'
    const url = `https://dashboard.stripe.com/${env}/customers/${customer_id}`
    window.open(url, '_blank');
  }

  const columns : GridColDef[] = [
    {field: 'lastName', headerName: 'Last Name', width: 120},
    {field: 'firstName', headerName: 'First Name', width: 180, resizable: true},
    {field: 'email', headerName: 'Email', width: 250, resizable: true},
    {field: 'userType', headerName: 'Type', width: 100},
    {field: 'userStatus', headerName: 'Status', width: 100},
    {field: 'startDate', headerName: 'Start Date', width: 120,
      valueFormatter: (value) => formatDate(value)},
    {field: 'lastAccess', headerName: 'Last Login', width: 120,
      valueFormatter: (value) => formatDate(value)},
    {field: 'role', headerName: 'Role', headerAlign: "center", width: 100, align: "center"},
    {field: 'id', headerName: 'Actions', headerAlign: "center", width: 100, align: "center",
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <MenuButton
          icon={<ExpandCircleDownOutlinedIcon/>}
          options={getMenuOptions(params.row)}
          getOptionLabel={(option: any) => String(option)}
          onChange={async (option: UserMenuOption): Promise<void> => {
            handleMenuOption(option, params.row)
          }}
        />
      )
    },
    {field: 'referrer', headerName: 'Referrer', width: 250, resizable: true},
  ]

  const getRowId = (row: any) => {
    return row.id;
  }

  const QuickSearchToolbar = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter
          debounceMs={500}
        />
      </Box>
    );
  }

  return (
    <Box sx={{
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      maxWidth: "calc(100vw - 330px)"
    }}>
      <Box sx={{display:"flex", flexGrow:1, width:"100%", mt:1, pl:0, pb:0, mb:1, justifyContent:"space-between", alignItems:"center"}}>
        <Box display="flex" justifyContent="flex-start">
          <Typography variant="h2" color="primary">Manage Users</Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          {/*<Button*/}
          {/*  color="primary"*/}
          {/*  variant="outlined"*/}
          {/*  size="medium"*/}
          {/*  onClick={(event: any) => {*/}
          {/*    handleUpdateTags()*/}
          {/*  }}*/}
          {/*  sx={{mr:2}}*/}
          {/*>*/}
          {/*  <TagIcon sx={{color: theme.palette.primary.light, marginRight:"8px"}}/>*/}
          {/*  Update Tags*/}
          {/*</Button>*/}
          {/*{userStore.isAdmin &&*/}
          {/*  <ProgressButton variant="outlined" color="primary"*/}
          {/*                  processing={isProcessing}*/}
          {/*                  onClick={(event: any) => {*/}
          {/*                    handleMigrateAssetEndBalances()*/}
          {/*                  }}>*/}
          {/*    Migrate Asset End Balances*/}
          {/*  </ProgressButton>*/}
          {/*}*/}
          <Button
            color="primary"
            variant="outlined"
            size="medium"
            sx={{marginLeft:2}}
            onClick={(event: any) => {
              handleAddUser()
            }}
          >
            <AddCircleIcon sx={{color: theme.palette.secondary.light, marginRight:1}}/>
            Add User
          </Button>
        </Box>
      </Box>
      <Box sx={{height:"calc(100vh - 150px)", maxHeight:"calc(100vh - 150px)"}}>
        <DataGrid
          rows={users}
          loading={isLoading}
          getRowId={getRowId}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          columns={columns}
          apiRef={apiRef}
          initialState={{
            sorting: {
              sortModel: [{ field: 'lastName', sort: 'asc' }, { field: 'firstName', sort: 'asc' }],
            },
          }}
          slots={{ toolbar: QuickSearchToolbar }}
          sx={{overflowX:"scroll"}}
          // slots={{ toolbar: GridToolbar }}
          // slotProps={{
          //   toolbar: {
          //     showQuickFilter: true,
          //   },
          // }}
          // sx={{
          //   "& .MuiDataGrid-row:hover": {
          //     backgroundColor: "skyblue"
          //     // color: "red"
          //   }
          // }}
        />
      </Box>
      {editUser &&
        <UserEditDialog user={editUser}
                        open={editUser !== undefined}
                        onSave={(update: User) => {
                          handleSaveUser(update)
                          setEditUser(undefined)
                        }}
                        onDelete={(deleted: User) => {
                          handleDeleteUser(deleted)
                          setEditUser(undefined)
                        }}
                        onClose={() => {
                          setEditUser(undefined)
                        }}/>
      }

    </Box>
  )
}

export default ManageUsersView