import React, {useEffect, useState} from 'react'
import Chart from "react-apexcharts";
import {numberToPercentFormat} from "../../../../stores/StoreUtilities";
import {globalColors} from "../../../../styles/globalStyles"
import {Box, FormLabel, Paper, TableContainer, useTheme} from "@mui/material";
import cloneDeep from "lodash.clonedeep";
import Asset, {Risk} from "../../../../model/Asset";

const defaultOptions = {
  chart: {
    id: "asset-allocation",
    type: 'donut',
    height: 350,
    width: "100%",
    zoom: {
      autoScaleYaxis: true
    },
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      }
    },
    toolbar: {
      show: true,
      tools: {
        download: false
      }
    }
  },
  title: {
    text: "",
    align: "right",
    margin: 0,
    floating: true,
    style: {
      fontSize: "16px",
      fontFamily: "Poppins,sans-serif",
      color: "rgb(31, 92, 167)"
    }
  },
  dataLabels: {
    enabled: true,
    formatter: function (value: number) {
      return numberToPercentFormat(value / 100, 0)
    },
    // offsetY: -20,
    style: {
      fontSize: '12px',
      colors: ["#304758"]
    }
  },
  legend: {
    show: false,
    position: 'right',
    horizontalAlign: 'left',
    offsetY: 0,
    height: 200
  },
  tooltip: {
    y: {
      formatter: function (value: number) {
        return numberToPercentFormat(value / 100, 0)
      }
    }
  },
  plotOptions: {
    pie: {
      donut: {
        size: '65%'
      },
    }
  },
  colors: [globalColors.categorical[0], globalColors.categorical[5], globalColors.categorical[4], globalColors.categorical[9]],
  responsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const RiskAllocationPieChart = ({
  liquidAssets,
  height,
  width,
  title
}: {
  liquidAssets: Asset[]
  height?: string
  width?: string
  title?: string
}) => {
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<number[]>([])
  const [labels, setLabels] = useState<string[]>([])

  useEffect(() => {
    calculateData()
  }, [liquidAssets])

  const theme = useTheme()

  const styles = {
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"],
      paddingLeft: theme.spacing(4)
    }
  }

  const calculateData = () => {
    if (!liquidAssets) {
      return
    }

    let emergencyAssets = liquidAssets.filter((asset: Asset) => asset.risk === Risk.Emergency)
    let totalEmergency = emergencyAssets.reduce((accum: number, item: Asset) => accum + item.balance, 0)
    let lowRiskAssets = liquidAssets.filter((asset: Asset) => asset.risk === Risk.Low)
    let totalLowRisk = lowRiskAssets.reduce((accum: number, item: Asset) => accum + item.balance, 0)
    let moderateRiskAssets = liquidAssets.filter((asset: Asset) => asset.risk === Risk.Moderate)
    let totalModerateRisk = moderateRiskAssets.reduce((accum: number, item: Asset) => accum + item.balance, 0)
    let aggressiveRiskAssets = liquidAssets.filter((asset: Asset) => asset.risk === Risk.Agressive)
    let totalAggressiveRisk = aggressiveRiskAssets.reduce((accum: number, item: Asset) => accum + item.balance, 0)

    const totalAssets = totalEmergency + totalLowRisk + totalModerateRisk + totalAggressiveRisk
    const emergencyLabel = totalAssets > 0 ? `Emergency: ${numberToPercentFormat(totalEmergency / totalAssets, 0)}` : `Emergency`
    const lowRiskLabel = totalAssets > 0 ? `Low: ${numberToPercentFormat(totalLowRisk / totalAssets, 0)}` : `Low`
    const moderateRiskLabel = totalAssets > 0 ? `Moderate: ${numberToPercentFormat(totalModerateRisk / totalAssets, 0)}` : `Moderate`
    const aggressiveRiskLabel = totalAssets > 0 ? `Aggressive: ${numberToPercentFormat(totalAggressiveRisk / totalAssets, 0)}` : `Aggressive`

    setSeries([totalEmergency, totalLowRisk, totalModerateRisk, totalAggressiveRisk])
    setLabels([emergencyLabel, lowRiskLabel, moderateRiskLabel, aggressiveRiskLabel])

    const newOptions = {
      series: [totalEmergency, totalLowRisk, totalModerateRisk, totalAggressiveRisk],
      labels: [emergencyLabel, lowRiskLabel, moderateRiskLabel, aggressiveRiskLabel],
      chart: {
        type: 'donut',
      },
      dataLabels: {
        enabled: true,
        formatter: function (value: number) {
          return numberToPercentFormat(value / 100, 0)
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
    }
    // const options = cloneDeep(defaultOptions)
    // options.title.text = title ?? ""


    // if (height || width) {
    //   options.chart.toolbar.show = false
    // }

    setOptions(newOptions)
  }


  if (options && series) {
    return (
      <React.Fragment>
        <Box>
          <FormLabel sx={styles.formLabel}>Liquid Asset Risk Allocation</FormLabel>
          <Chart
            options={options}
            series={series}
            labels={labels}
            type="donut"
            height={height ?? "500"}
            width={width ?? "100%"}
          />
        </Box>
      </React.Fragment>
    )
  } else {
    return null
  }
}

export default RiskAllocationPieChart