import PlanChange from "../PlanChange";
import Model from "../Model";
import {numberToPercentFormat} from "../../stores/StoreUtilities";
import Expense from "../Expense";
import Tax from "../Tax";
import Deduction from "../Deduction";
import Income from "../Income";

export interface InflationStrategy {
  deduction: {
    rate: number,
    lock: boolean
  },
  expense: {
    rate: number,
    lock: boolean
  },
  income: {
    rate: number,
    lock: boolean
  },
  tax: {
    rate: number,
    lock: boolean
  }
}

class InflationStrategyChange extends PlanChange implements InflationStrategy {
  deduction: {
    rate: number,
    lock: boolean
  }
  expense: {
    rate: number,
    lock: boolean
  }
  income: {
    rate: number,
    lock: boolean
  }
  tax: {
    rate: number,
    lock: boolean
  }

  constructor (data: any) {
    super(data)
    const details = this.details ? JSON.parse(this.details) : {}
    this.deduction = details.deduction ?? {rate: 0.03, lock: false}
    this.expense = details.expense ?? {rate: 0.03, lock: false}
    this.income = details.income ?? {rate: 0.03, lock: false}
    this.tax = details.tax ?? {rate: 0.03, lock: false}
  }

  generateDescription() {
    let desc = ""
    desc += `Expenses: ${numberToPercentFormat(this.expense.rate, 2)} (${this.expense.lock ? 'locked' : 'default'}), `
    desc += `Taxes: ${numberToPercentFormat(this.tax.rate, 2)} (${this.tax.lock ? 'locked' : 'default'})`
    return desc
  }

  apply(model: Model) {
    model.inflationStrategy = {
      deduction: this.deduction,
      expense: this.expense,
      income: this.income,
      tax: this.tax
    }

    if (this.deduction.lock) {
      model.deductions.forEach((d: Deduction) => {
        if (!d.infLock) {
          d.annualInf = this.deduction.rate
          model.addLock(d.id, "annualInf")
        }
      })
    }

    if (this.expense.lock) {
      model.expenses.forEach((e: Expense) => {
        if (!e.infLock) {
          e.annualInf = this.expense.rate
          model.addLock(e.id, "annualInf")
        }
      })
    }

    if (this.income.lock) {
      model.incomes.forEach((i: Income) => {
        if (!i.infLock) {
          i.annualInf = this.income.rate
          model.addLock(i.id, "annualInf")
        }
      })
    }

    if (this.tax.lock) {
      model.taxes.forEach((t: Tax) => {
        if (!t.infLock) {
          t.annualInf = this.tax.rate
          model.addLock(t.id, "annualInf")
        }
      })
    }
  }
}

export default InflationStrategyChange