import React, {useEffect} from 'react';
import './App.css';
import {useResources} from "./stores/ResourceProvider";
import { ThemeProvider } from '@mui/material/styles';
import theme from "./styles/theme";
// import OptimizerView from "./views/optimizer/OptimizerView";
import {Routes, Route, BrowserRouter, useLocation} from "react-router-dom";
import ControlTower from "./components/navigation/ControlTower";
import RoutesConfig, {RoutesConfigHelpers} from "./RoutesConfig";
import CalcPage from "./pages/calc/CalcPage";
// Config
import config, { Options } from 'react-global-configuration';
import AppConfig from "./AppConfig";
import {Amplify, API} from "aws-amplify";
import aws_exports from './aws-exports';
import SignInPage from "./pages/SignInPage";
import SignOutPage from "./pages/SignOutPage";
import HomePage from "./pages/home/HomePage";
import SettingsPage from "./pages/settings/SettingsPage";
import AdminPage from "./pages/admin/AdminPage";
import SignupPage from "./pages/SignupPage";
import InvitePage from "./pages/InvitePage";
import CommunityPage from "./pages/Community";
import WelcomePage from "./pages/WelcomePage";
import CheckoutReturnPage from "./pages/checkout/CheckoutReturnPage";

config.set(AppConfig, { freeze: false, assign: false } as Options);

Amplify.configure(aws_exports);
API.configure(aws_exports)

// A component for "warming up" the store objects, b/c we need to call these init functions wrapped within the RootStoreProvider component.
const AppStartup = ({
  children
}: {
  children: React.ReactNode
}) => {
  const stores = useResources()
  const location = window.location

  useEffect(() => {
    const initStores = async () => {
      // await stores.init()
      const { userStore, accountStore, modelStore } = stores
      if (userStore) {
        const pathname = `/${RoutesConfigHelpers.getPathSegment(location.pathname, 1)}`
        if (pathname !== RoutesConfig.signIn.pathname &&
            pathname !== RoutesConfig.signUp.pathname &&
            pathname !== RoutesConfig.invite.pathname) {

            const user = await userStore.reloadAuthenticatedUser()
            if (user) {
                accountStore.init(user.account)
                await modelStore.loadUserModels(user)
            }
        }
      }
    }
    initStores()
  }, [
    stores
  ])
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

function App() {

  return (
    <AppStartup>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<ControlTower routes={RoutesConfig} />}>
              <Route path={`${RoutesConfig.admin.pathname}/:view`} element={<AdminPage />} />
              <Route path={`${RoutesConfig.admin.pathname}`} element={<AdminPage />} />
              <Route path={RoutesConfig.home.pathname} element={<HomePage />} />
              <Route path={`${RoutesConfig.calc.pathname}/:view`} element={<CalcPage />} />
              <Route path={`${RoutesConfig.calc.pathname}/:view/:year`} element={<CalcPage />} />
              <Route path={RoutesConfig.calc.pathname} element={<CalcPage />} />
              <Route path={`${RoutesConfig.checkoutReturn.pathname}`} element={<CheckoutReturnPage/>} />
              <Route path={`${RoutesConfig.checkoutReturn.pathname}/:sessionId`} element={<CheckoutReturnPage/>} />
              <Route path={RoutesConfig.community.pathname} element={<CommunityPage />} />
              <Route path={`${RoutesConfig.invite.pathname}/:token`} element={<InvitePage />} />
              <Route path={RoutesConfig.settings.pathname} element={<SettingsPage />} />
              <Route path={RoutesConfig.signIn.pathname} element={<SignInPage />} />
              <Route path={RoutesConfig.signOut.pathname} element={<SignOutPage />} />
              <Route path={RoutesConfig.signUp.pathname} element={<SignupPage />} />
              <Route path={RoutesConfig.welcome.pathname} element={<WelcomePage/>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </AppStartup>
  );
}

export default App;
