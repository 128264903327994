import * as React from 'react';
import {useEffect, useState} from 'react';
import AssetConversion, {AssetConversionType} from "../../../model/AssetConversion";
import {FormLabel, Grid, Typography, useTheme} from "@mui/material";
import ModelCard2 from "../../../components/model/ModelCard2";
import {
  humanizeString,
  isoToLocalDateString,
  numberToMoneyFormat,
  numberToPercentFormat
} from "../../../stores/StoreUtilities";
import Model from "../../../model/Model";
import {ISnapshotDetail} from "../../../model/Snapshot";
import AssetConversionEditDialog from "./AssetConversionEditDialog";
import Asset from "../../../model/Asset";
import {AssetConversionIcon} from "../../../styles/Icons";
import {getIncomeTypeDef, IncomeType} from "../../../model/Income";
import {isNumber} from "../../../model/ModelUtilities";

const AssetConversionCard = ({
  assetConversion,
  model,
  expanded,
  onChange,
  onSave,
  onDelete,
  onCommit
}: {
  assetConversion: AssetConversion
  model: Model
  expanded?: boolean
  onChange?(event: any, expanded: boolean): void
  onSave?(expense: AssetConversion, snapshotItem?: ISnapshotDetail): void
  onDelete?(deleted: AssetConversion): void
  onCommit?(results: Array<Asset | AssetConversion | undefined>): void
}) => {
  const [assetConversionModel, setAssetConversionModel] = useState<AssetConversion>(assetConversion)
  const [description, setDescription] = useState<string>("")
  const [conversionType, setConversionType] = useState<AssetConversionType>(AssetConversionType.OtherConversion)
  const [srcAsset, setSrcAsset] = useState<Asset | undefined>()
  const [dstAsset, setDstAsset] = useState<Asset | undefined>()
  const [year, setYear] = useState<string | undefined>()
  const [amount, setAmount] = useState<string | undefined>()
  const [isAmount, setIsAmount] = useState<boolean>(true)
  const [percent, setPercent] = useState<string | undefined>()
  const [incomeTypeLabel, setIncomeTypeLabel] = useState<string | undefined>()
  const [nonTaxableAmount, setNonTaxableAmount] = useState<string | undefined>()
  const [completedAt, setCompletedAt] = useState<string | undefined>()
  const [isLocked, setIsLocked] = useState<boolean>(false)
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)

  useEffect(() => {
    init(assetConversion)
  }, [assetConversion])

  const init = (assetConversion: AssetConversion) => {
    setAssetConversionModel(assetConversion)
    setDescription(assetConversion.description)
    setConversionType(assetConversion.conversionType)
    const src = model.getAssetById(assetConversion.srcAssetId)
    setSrcAsset(src)
    setDstAsset(model.getAssetById(assetConversion.dstAssetId))
    setYear(String(assetConversion.year))
    if (assetConversion.percent > 0) {
      setIsAmount(false)
      setPercent(isNumber(assetConversion.percent) ? numberToPercentFormat(assetConversion.percent, 0) : "")
    } else {
      setIsAmount(true)
      setAmount(numberToMoneyFormat(assetConversion.amount, 0))
    }
    setNonTaxableAmount(numberToMoneyFormat(assetConversion.nonTaxableAmount, 0))
    const incomeTypeDef = getIncomeTypeDef(assetConversion.incomeType)
    setIncomeTypeLabel(incomeTypeDef?.label)
    setCompletedAt(assetConversion.completedAt ? isoToLocalDateString(assetConversion.completedAt) : undefined)
    setIsLocked(model.hasLock(assetConversion.id, "*"))
    setEditOpen(!assetConversion.createdAt)
  }

  const theme = useTheme()

  const styles = {
    heading: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    fixedRow: {
      height: 40,
      minHeight: 40,
      maxHeight: 40,
      paddingBottom: "4px"
      // border: "1px solid red"
    },
    flexRow: {
      height: 40,
      paddingBottom: "4px"
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "1.0em",
      maxHeight: 15,
      marginBottom: "1px",
      textWrap: "nowrap",
      color: theme.palette.grey["700"]
    },
    value: {
      lineHeight: 1.2,
      // border: "1px solid blue"
    },
    assetConversionIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: "#40642F",
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    },
  }

  const renderContent = () => {

    return (
      <Grid container direction="column" spacing={0}>
        <Grid item xs={12} sx={styles.fixedRow}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={6}>
              <FormLabel sx={styles.formLabel}>Conversion Type</FormLabel>
              <Typography variant="body2" sx={styles.value}>
                {humanizeString(conversionType)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormLabel sx={styles.formLabel}>Completed Date</FormLabel>
              <Typography variant="body2" sx={styles.value}>
                {completedAt}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.flexRow}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={6}>
              <FormLabel sx={styles.formLabel}>Source Asset</FormLabel>
              <Typography variant="body2" sx={styles.value}>
                {srcAsset?.description}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormLabel sx={styles.formLabel}>Destination Asset</FormLabel>
              <Typography variant="body2" sx={styles.value}>
                {dstAsset?.description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.fixedRow}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={6}>
              <FormLabel sx={styles.formLabel}>Year</FormLabel>
              <Typography variant="body2">
                {year}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormLabel sx={styles.formLabel}>Amount</FormLabel>
              <Typography variant="body2">
                {percent ?? amount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.fixedRow}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={6}>
              <FormLabel sx={styles.formLabel}>Taxable Income Type</FormLabel>
              <Typography variant="body2" style={{fontSize:14}}>
                {incomeTypeLabel}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormLabel sx={styles.formLabel}>Tax Excluded Amount</FormLabel>
              <Typography variant="body2">
                {nonTaxableAmount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  let icon = <AssetConversionIcon/>

  return (
    <React.Fragment>
      <ModelCard2
        icon={icon}
        title={description}
        value={isAmount ? amount : percent}
        expanded={expanded}
        bgColor={isLocked ? theme.palette.grey[100] : undefined}
        onChange={onChange}
        editButtonId={`edit-btn-${assetConversionModel.id}`}
        onEdit={onSave ? (event: any) => {
          setEditOpen(!editOpen)
        } : undefined}
      >
        {renderContent()}
      </ModelCard2>
      {editOpen &&
        <AssetConversionEditDialog assetConversion={assetConversionModel} model={model}
                         open={editOpen}
                         onSave={(update: AssetConversion) => {
                           init(update)
                           setEditOpen(false)
                           if (onSave) {
                             onSave(update)
                             setSaved(true)
                           }
                         }}
                         onDelete={(deleted: AssetConversion) => {
                           setEditOpen(false)
                           if (onDelete) {
                             onDelete(deleted)
                           }
                         }}
                         onCommit={(results: Array<Asset | AssetConversion | undefined>) => {
                           setEditOpen(false)
                           if (onCommit) {
                             onCommit(results)
                           }
                         }}
                         onClose={() => {
                           setEditOpen(false)
                           if (!assetConversionModel.createdAt && onDelete) {
                             onDelete(assetConversionModel)
                           }
                         }}/>
      }

    </React.Fragment>
  )
}

export default AssetConversionCard