import React, {ReactNode, SyntheticEvent, useEffect, useState} from "react";
import {Box, Checkbox, IconButton, Stack, SvgIconProps, Tooltip, Typography, useTheme} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export interface IPlanChangeListItemProps {
  labelIcon: React.ElementType<SvgIconProps>
  labelText: string
  content?: string | ReactNode
  iconColor?: string
  nodeId: string
  expanded?: boolean
  onChange?: (event: SyntheticEvent, expanded: boolean) => any
  checked?: boolean
  onCheck?: any
  disabled?: boolean
  editButtonId?: string
  onEdit?: any
  children?: ReactNode
}
const PlanChangeListItem = ({
    labelIcon,
    labelText,
    content,
    iconColor,
    nodeId,
    expanded,
    onChange,
    checked,
    onCheck,
    editButtonId,
    onEdit,
    children,
    ...other
  }: IPlanChangeListItemProps
) => {
  const theme = useTheme();

  const [check, setCheck] = useState<boolean>(false)

  useEffect(() => {
    setCheck(checked ?? false)
  }, [checked])

  const styles = {
    checkedIcon: {
      color: "rgb(76, 175, 80)",
      height: 20,
      width: 20
    },
    uncheckedIcon: {
      color: "rgb(127, 127, 127)",
      height: 20,
      width: 20
    }
  }

  let labelColor
  if (checked) {
    labelColor = (children !== undefined) ? theme.palette.primary.main : theme.palette.text.primary
  } else {
    labelColor = theme.palette.text.secondary
  }
  const labelWeight = (children !== undefined) ? 600 : 400
  const checkboxColor = (children !== undefined) ? "primary" : "secondary"

  const renderContent = (content: string) => {
    const parts = content.split(', ')
    if (parts.length <= 1) {
      return content
    } else {
      const array: Array<ReactNode | string> = []
      parts.forEach((part: string, index: number) => {
        if (index > 0) {
          array.push(<br/>)
        }
        array.push(part)
      })
      return array
    }
  }

  let useIconColor
  if (checked) {
    if (iconColor) {
      useIconColor = iconColor
    } else {
      useIconColor = theme.palette.primary.dark
    }
  } else {
    useIconColor = theme.palette.action.disabled
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: "1px",
        pr: 0,
        cursor: "pointer"
      }}
    >
      <Stack width="100%">
        <Box display="flex" flexGrow={1} justifyContent="flex-start" alignItems="center">
          <Box display="flex" flexGrow={1} justifyContent="flex-start" alignItems="center"
               onClick={(event: any) => {
                 if (onChange) {
                   onChange(event, !expanded)
                 }
                 event.preventDefault()
                 event.stopPropagation()
               }}
          >
            <Tooltip title={labelText} placement="right" arrow>
              <Box component={labelIcon} color={useIconColor} sx={{ mr: 1 }}
                   onClick={(event: any) => {
                     if (onCheck) {
                       event.preventDefault()
                       event.stopPropagation()
                       event.target.checked = !check
                       onCheck(event)
                     }
                   }}
              />
            </Tooltip>
            <Typography variant="body2" sx={{ color:labelColor, fontWeight:labelWeight, flexGrow:1, paddingRight:2, lineHeight:1.1 }}>
              {labelText}
            </Typography>
          </Box>
          {onCheck !== undefined &&
            // <Box display="flex" flexGrow={0} flexShrink={0} pb="6px" width={14} maxWidth={14} height={12} justifyContent="flex-end" alignItems="top">
            <Box display="flex" flexGrow={0} flexShrink={0} width={14} maxWidth={14} justifyContent="flex-end" alignItems="top">
              <IconButton size="small"
                          onClick={(event: any) => {
                                      // Simulate checkbox
                                      event.target.checked = !check
                                      onCheck(event)
                                    }}
                          sx={{width:16, height:16, pr:"14px"}}>
                {check ? <VisibilityIcon sx={styles.checkedIcon}/> : <VisibilityOffIcon sx={styles.uncheckedIcon}/> }
              </IconButton>
              {/*<Checkbox*/}
              {/*  checked={check}*/}
              {/*  color={checkboxColor}*/}
              {/*  icon={<VisibilityOffIcon sx={styles.uncheckedIcon}/>}*/}
              {/*  checkedIcon={<VisibilityIcon sx={styles.checkedIcon}/>}*/}
              {/*  onClick={(event: any) => {*/}
              {/*    event.stopPropagation()*/}
              {/*  }}*/}
              {/*  onChange={(event: any) => {*/}
              {/*    onCheck(event)*/}
              {/*  }}*/}
              {/*  sx={{zIndex:1000}}*/}
              {/*/>*/}
            </Box>
          }
          {onEdit &&
            <Box display="flex" flexGrow={0} flexShrink={0} width={15} maxWidth={15} justifyContent="flex-end" alignItems="top">
              <IconButton id={editButtonId ?? ''} size="small" onClick={onEdit} sx={{width:16, height:16}}>
                <ChevronRightIcon/>
              </IconButton>
            </Box>
          }
        </Box>
        {expanded && (typeof content === "string") &&
          <Box pl={4} pt={0} pb="2px" mt={0}>
            <Typography variant="body2" sx={{color:labelColor, fontSize:13, lineHeight:1.1}}>{renderContent(content)}</Typography>
          </Box>
        }
        {expanded && (typeof content !== undefined && typeof content !== "string") &&
          <Box pl={4} pt={0} pb="2px" mt={0}>
            {content}
          </Box>
        }
      </Stack>
    </Box>
  )
}

export default PlanChangeListItem