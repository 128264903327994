import React, {useEffect, useState} from "react";
import {Paper, TableContainer, useTheme} from "@mui/material";
import {numberToMoneyFormat} from "../../../../stores/StoreUtilities";
import {FutureCalc, IAssetDetail} from "../../../../components/calculator/Calculator";
import {globalColors} from "../../../../styles/globalStyles";
import cloneDeep from "lodash.clonedeep";
import Chart from "react-apexcharts";
import WithdrawalProjectionTable from "./WithdrawalProjectionTable";

const defaultOptions = {
  chart: {
    id: "withdrawal-projection",
    type: 'bar',
    stacked: true,
    height: 350,
    width: "100%",
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      }
    },
    zoom: {
      enabled: false,
      autoScaleYaxis: true
    },
    toolbar: {
      show: false,
      tools: {
        download: false
      }
    }
  },
  title: {
    text: "",
    align: "center",
    margin: 0,
    floating: true,
    style: {
      fontSize: "16px",
      fontFamily: "Poppins,sans-serif",
      color: "rgb(31, 92, 167)"
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    position: 'top', // TODO: Figure out why this isn't working
    horizontalAlign: 'left',
    onItemClick: {
      toggleDataSeries: false
    }
  },
  xaxis: {
    type: 'number',
    min: 2000,
    axisBorder: {
      show: true
    }
  },
  yaxis: {
    labels: {
      formatter: (val: number) => {
        return numberToMoneyFormat(val, 0)
        // if (val < 1000000) {
        //   return `$${val / 1000}K`
        // } else {
        //   return `$${val / 1000000}M`
        // }
      }
    }
  },
  tooltip: {
    y: {
      formatter: function (value: number) {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  colors: [globalColors.taxable, globalColors.taxDeferredRMD, globalColors.taxDeferredElective, globalColors.taxFree, globalColors.taxExpense],
  resonsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const WithdrawalProjectionChart = ({
   year,
   futureCalc,
   onChange,
   title
   }: {
  year: number
  futureCalc: FutureCalc
  onChange?: (year: number) => any
  title?: string
}) => {
  const [startYear, setStartYear] = useState<number>(0)
  const [endYear, setEndYear] = useState<number>(0)
  const [calc, setCalc] = useState<FutureCalc | undefined>()
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<any>()
  const [currentYear, setCurrentYear] = useState<number>(0)

  const theme = useTheme()

  useEffect(() => {
    if (futureCalc.futureYears.length > 0) {
      const start = futureCalc.futureYears[0].year
      setStartYear(start)
      setEndYear(futureCalc.futureYears[futureCalc.futureYears.length-1].year)
      setCurrentYear(start)
      setCalc(futureCalc)
    }
  }, [year, futureCalc])

  useEffect(() => {
    if (startYear > 0 && calc && calc.futureYears.length > 0) {
      calculateData(startYear)
    }
  }, [currentYear, calc])

  const calculateData = (startYear: number) => {
    if (!calc || startYear <= 0 || endYear < startYear) {
      return
    }

    let taxableData = []
    let taxDeferredRMDData = []
    let taxDeferredElectiveData = []
    let taxFreeData = []
    let taxData = []

    for (let index = 0; index < calc.futureYears.length; index++) {
      const year = startYear + index
      const fy = calc.futureYears[index]
      if (!fy) {
        continue // TODO: Figure out why this happens
      }
      let taxable = fy.assetDetails.reduce((accum: number, item: IAssetDetail) => {
        if (item.liquidAsset.assetTypeDef.taxable) {
          return (accum + item.distribution)
        } else return (accum)
      }, 0)
      taxableData.push({x: year, y: -taxable})

      let taxDeferredElective = fy.assetDetails.reduce((accum: number, item: IAssetDetail) => {
        if (item.liquidAsset.assetTypeDef.taxDeferred) {
          return (accum + item.distribution)
        } else return (accum)
      }, 0)
      taxDeferredElectiveData.push({x: year, y: -taxDeferredElective})

      let taxDeferredRMD = fy.assetDetails.reduce((accum: number, item: IAssetDetail) => {
        if (item.rmd > 0) {
          return (accum + item.rmd)
        } else return (accum)
      }, 0)
      taxDeferredRMDData.push({x: year, y: taxDeferredRMD})

      let taxFree = fy.assetDetails.reduce((accum: number, item: IAssetDetail) => {
        if (item.liquidAsset.assetTypeDef.taxFree) {
          return (accum + item.distribution)
        } else return (accum)
      }, 0)
      taxFreeData.push({x: year, y: -taxFree})

      taxData.push({x: year, y: -fy.taxes})
    }

    const series = [
      {
        name: "Taxable",
        group: "Withdrawal",
        data: taxableData
      },
      {
        name: "Tax-deferred RMD",
        group: "Withdrawal",
        data: taxDeferredRMDData
      },
      {
        name: "Tax-deferred Elective",
        group: "Withdrawal",
        data: taxDeferredElectiveData
      },
      {
        name: "Tax-free",
        group: "Withdrawal",
        data: taxFreeData
      },
      {
        name: "Estimated Taxes",
        group: "Withdrawal",
        data: taxData
      },
    ]
    setSeries(series)

    const newOptions = cloneDeep(defaultOptions)
    newOptions.title.text = title ?? ""
    newOptions.xaxis.min = startYear

    if (currentYear !== undefined) {
      const annotations = {
        xaxis: [
          {
            x: currentYear,
            borderColor: theme.palette.secondary.main, //'#00E396',
            label: {
              borderColor: theme.palette.secondary.main, //'#00E396',
              orientation: 'horizontal',
              text: currentYear.toString(),
              style: {
                fontSize: '14px',
                fontWeight: 600
              }
            }
          }
        ]
      }
      newOptions["annotations"] = annotations
      newOptions.chart.events["dataPointSelection"] = (event: any, chartContext: any, details: {dataPointIndex: number, seriesIndex: number}) => {
        if (details.dataPointIndex >= 0 && onChange) {
          onChange(details.dataPointIndex + startYear)
        }
      }
    }

    setOptions(newOptions)
  }

  // const handleChange = (year: number) => {
  //   setCurrentYear(year)
  //   if (onChange) {
  //     onChange(year)
  //   }
  // }

  if (options && series) {
    return (
      <React.Fragment>
        <TableContainer component={Paper} sx={{paddingTop:1, overflowY:"hidden"}}>
          <Chart
            options={options}
            series={series}
            type="bar"
            height="500"
            width="100%"
          />
        </TableContainer>
        <WithdrawalProjectionTable futureCalc={futureCalc} series={series} year={currentYear}/>
      </React.Fragment>
    )
  } else {
    return null
  }
}

export default WithdrawalProjectionChart