import React, {ReactElement, ReactNode, SyntheticEvent, useCallback, useEffect, useState} from "react";
import {useResources} from "../../../stores/ResourceProvider";
import {
  Box,
  Button,
  FormLabel,
  IconButton,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer, TableHead, TableRow,
  Typography,
  useTheme
} from "@mui/material";
import Model from "../../../model/Model";
import Person from "../../../model/Person";
import {
  arrayMove,
  createUUID, dateToLocalFormat, getISODateFromDate, getISODateStartOfYear,
  getISODateToday,
  numberToMoneyFormat
} from "../../../stores/StoreUtilities";
import Asset, {AssetCategory, AssetType, AssetTypeDef, listAssetTypeDefs, listAssetTypes} from "../../../model/Asset";
import Liability from "../../../model/Liability";
import AssetCard from "./AssetCard";
import LiabilityCard from "./LiabilityCard";
import BasicMenuButton from "../../../components/controls/BasicMenuButton";
import {AddCircleRounded} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from '@mui/icons-material/Edit';
import NetWorthApexChart from "./charts/NetWorthApexChart";
import Section from "../../../components/controls/Section";
import Snapshot, {ISnapshotDetail, SnapshotDetail} from "../../../model/Snapshot";
import SnapshotIcon from "@mui/icons-material/CameraAlt";
import {INetWorthCalc} from "../../../components/calculator/Calculator";
import SnapshotDialog from "./SnapshotDialog";
import AssetLiabilityApexChart from "./charts/AssetLiabilityApexChart";
import LiquidAssetsApexChart from "./charts/LiquidAssetsApexChart";
import {
  CreateSnapshotInput,
  UpdateAssetConversionInput,
  UpdateAssetInput,
  UpdateLiabilityInput, UpdateSnapshotInput
} from "../../../API";
import {compareAsc, compareDesc, isToday} from "date-fns";
import ViewMenu from "../../../components/controls/ViewMenu";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreDoubleIcon from '@mui/icons-material/UnfoldMoreDouble';
import {DragDropContext, DraggableProvided, DroppableProvided, DropResult} from "react-beautiful-dnd";
import ModelStore from "../../../stores/ModelStore";
import DraggableList from "../../../components/controls/DraggableList";
import AssetConversion, {AssetConversionType} from "../../../model/AssetConversion";
import AssetConversionCard from "./AssetConversionCard";
import clone from "lodash.clone";
import Plan from "../../../model/Plan";
import LiquidAssetRiskAllocationChart from "./charts/LiquidAssetRiskAllocationChart";
import LiquidAssetTaxAllocationChart from "./charts/LiquidAssetTaxAllocationChart";
import {IncomeType} from "../../../model/Income";
import Expense, {ExpenseCategory} from "../../../model/Expense";
import Schedule, {ScheduleNames} from "../../../model/Schedule";
import ExpenseEditDialog from "../budget/ExpenseEditDialog";
import Tracking from "../../../components/Tracking";
import IModelItem from "../../../model/IModelItem";

enum NetWorthChartView {
  NetWorthHistory,
  AssetLiabilityHistory,
  LiquidAssetHistory,
  RiskAllocation,
  TaxAllocation
}

enum NetWorthSection {
  LiquidAssets = "LiquidAssets",
  AssetConversions = "AssetConversions",
  RealEstateAssets = "RealEstateAssets",
  PersonalPropertyAssets = "PersonalPropertyAssets",
  OtherAssets = "OtherAssets",
  Liabilities = "Liabilities"
}

interface INetWorthViewSettings {
  expandedSections: string[]
  expandedCards: string[]
  chartView: NetWorthChartView
}

const NetWorthView = ({
  model,
  plan,
  updatedAt,
  width,
  onChange
} : {
  model: Model
  plan: Plan
  updatedAt: string
  width: number
  onChange?(item: IModelItem): void
}) => {
  const sectionCount = 5
  const [viewStorageKey, setViewStorageKey] = useState<string>("")
  const [currentModel, setCurrentModel] = useState<Model | undefined>()
  const [snapshots, setSnapshots] = useState<Snapshot[]>([])
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [currentSnapshot, setCurrentSnapshot] = useState<Snapshot | undefined>()
  const [netWorthCalcs, setNetWorthCalcs] = useState<INetWorthCalc[]>([])
  const [currentNetWorthCalc, setCurrentNetWorthCalc] = useState<INetWorthCalc | undefined>()
  const [persons, setPersons] = useState<Person[]>([])
  const [liquidAssets, setLiquidAssets] = useState<Asset[]>([])
  const [assetConversions, setAssetConversions] = useState<AssetConversion[]>([])
  const [assetConversionsTotal, setAssetConversionsTotal] = useState<number>(0)
  const [realAssets, setRealAssets] = useState<Asset[]>([])
  const [personalAssets, setPersonalAssets] = useState<Asset[]>([])
  const [otherAssets, setOtherAssets] = useState<Asset[]>([])
  const [liabilities, setLiabilities] = useState<Liability[]>([])
  const [editSnapshot, setEditSnapshot] = useState<Snapshot | undefined>()
  const [expandedSections, setExpandedSections] = useState<Set<string>>(new Set<string>([]))
  const [expandedCards, setExpandedCards] = useState<Set<string>>(new Set<string>([]))
  const [chartView, setChartView] = useState<NetWorthChartView>(NetWorthChartView.NetWorthHistory)
  const [editExpense, setEditExpense] = useState<Expense | undefined>()

  const theme = useTheme()
  const styles = {
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"],
    },
    miniButton: {
      height: 16,
      minWidth: 0,
      borderRadius: "5px"
    },
    smallButton: {
      height: 30,
    },
    snapshotIcon: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: 20,
      padding: "4px",
      borderRadius: "5px"
    },
  }

  const { modelStore, calculator, notify, confirm } = useResources()

  const loadModel = (model?: Model, snapshotId?: string) => {
    if (model) {
      console.debug(`NetWorthView.loadModel: ${model.name} ${model.updatedAt}`)
      setCurrentModel(model)
      model.persons.sort((a: Person, b: Person) => a.createdAt.localeCompare(b.createdAt))
      setPersons(model.persons)
      model.snapshots.sort((a: Snapshot, b: Snapshot) => compareDesc(a.date, b.date))
      const liquid = model.assets.filter((asset: Asset) => asset.assetCategory === AssetCategory.LiquidInvestableAssets)
      setLiquidAssets(liquid)
      setAssetConversions(model.assetConversions)
      const real = model.assets.filter((asset: Asset) => asset.assetCategory === AssetCategory.RealEstateAndProperty)
      setRealAssets(real)
      const personal = model.assets.filter((asset: Asset) => asset.assetCategory === AssetCategory.PersonalProperty)
      setPersonalAssets(personal)
      const other = model.assets.filter((asset: Asset) => asset.assetCategory === AssetCategory.OtherAssets)
      setOtherAssets(other)
      setLiabilities(model.liabilities)
      setSnapshots(model.snapshots)
      let index = 0
      if (snapshotId) {
        index = model.snapshots.findIndex((s: Snapshot) => s.id === snapshotId)
        if (index < 0) {
          index = 0
        }
      }
      const snap = model.snapshots[index]
      setCurrentSnapshot(snap)
      setCurrentIndex(index)
      const netWorthCalcs = model.snapshots.map((snapshot: Snapshot) => {
        return calculator.calculateNetWorth(liquid, real, personal, other, model.liabilities, snapshot)
      })
      setNetWorthCalcs(netWorthCalcs)
      setCurrentNetWorthCalc(netWorthCalcs[index])
    }
  }

  useEffect(() => {
    const loadSettings = () => {
      let storageKey = viewStorageKey
      if (!storageKey) {
        storageKey = `${modelStore.currentUser?.id}.NetWorthViewSettings`
        setViewStorageKey(storageKey)
      }
      const data = window.localStorage.getItem(storageKey)
      if (data) {
        const settings: INetWorthViewSettings = JSON.parse(data)
        if (settings.expandedSections && settings.expandedSections.length && typeof settings.expandedSections[0] === "string") {
          setExpandedSections(new Set<string>([...settings.expandedSections]))
        } else {
          setAllSectionsExpanded(true)
        }
        if (settings.expandedCards && settings.expandedCards.length) {
          setExpandedCards(new Set<string>([...settings.expandedCards]))
        } else {
          setExpandedCards(new Set<string>([]))
        }
        setChartView(settings.chartView ?? NetWorthChartView.NetWorthHistory)
      }
    }

    if (model) {
      loadModel(model)
      loadSettings()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, plan, updatedAt])

  useEffect(() => {
    const calcNetWorth = (snapshot: Snapshot) => {
      if (!currentModel || !snapshot) {
        return
      }
      console.debug(`NetWorthView.calcNetWorth: ${snapshot.date} ${snapshot.details.length} details`)

      const netWorthCalc = calculator.calculateNetWorth(liquidAssets, realAssets, personalAssets, otherAssets, liabilities, snapshot)
      if (currentSnapshot && currentSnapshot.id === snapshot.id) {
        setCurrentNetWorthCalc(netWorthCalc)
      }
      const index = netWorthCalcs.findIndex((item: INetWorthCalc) => item.id === netWorthCalc.id)
      if (index >= 0) {
        netWorthCalcs.splice(index, 1, netWorthCalc)
      } else if (netWorthCalcs[0].id === "current") {
        // Replacing current
        netWorthCalcs.splice(0, 1, netWorthCalc)
      } else {
        netWorthCalcs.splice(0, 0, netWorthCalc)
      }
      // setNetWorthCalcs([...netWorthCalcs])

      // Update assetConversionsTotal
      setAssetConversionsTotal(assetConversions.reduce((accum: number, item: AssetConversion) => accum + item.amount, 0))
      return netWorthCalcs
    }

    if (currentSnapshot) {
      calcNetWorth(currentSnapshot)
    }
  }, [liquidAssets, assetConversions, realAssets, personalAssets, otherAssets, liabilities, snapshots, currentSnapshot, currentModel, netWorthCalcs, calculator])



  useEffect(() => {
    const saveSettings = () => {
      const settings = JSON.stringify({
        expandedSections: Array.from(expandedSections),
        expandedCards: Array.from(expandedCards),
        chartView: chartView,
      })
      window.localStorage.setItem(viewStorageKey, settings)
    }

    if (currentModel) {
      saveSettings()
    }
  }, [expandedSections, expandedCards, chartView, currentModel, viewStorageKey])

  const getExpandedSection = (id: NetWorthSection) => {
    return expandedSections.has(id)
  }

  const setExpandedSection = (id: NetWorthSection, expand: boolean) => {
    if (expand) {
      expandedSections.add(id)
    } else {
      expandedSections.delete(id)
    }
    setExpandedSections(new Set(expandedSections))
  }

  const getAllSectionsExpanded = (): boolean => {
    return expandedSections.size === sectionCount
  }

  const setAllSectionsExpanded = (expanded: boolean) => {
    if (expanded) {
      setExpandedSections(new Set<string>(Object.values(NetWorthSection)))
    } else {
      setExpandedSections(new Set<string>([]))
    }
  }

  const getExpandedCard = (id: string) => {
    return expandedCards.has(id)
  }

  const setExpandedCard = (id: string, expand: boolean) => {
    if (expand) {
      expandedCards.add(id)
    } else {
      expandedCards.delete(id)
    }
    setExpandedCards(new Set(expandedCards))
  }

  const getAllCardsExpanded = (): boolean => {
    const totalCards = liquidAssets.length + realAssets.length + personalAssets.length + otherAssets.length + liabilities.length
    return (expandedCards.size === totalCards)
  }

  const setAllCardsExpanded = (expanded: boolean) => {
    if (expanded) {
      const set = new Set<string>([])
      liquidAssets.forEach((item: Asset) => set.add(item.id))
      realAssets.forEach((item: Asset) => set.add(item.id))
      personalAssets.forEach((item: Asset) => set.add(item.id))
      otherAssets.forEach((item: Asset) => set.add(item.id))
      liabilities.forEach((item: Liability) => set.add(item.id))
      setExpandedCards(set)
    } else {
      setExpandedCards(new Set<string>([]))
    }
  }

  const liquidAssetMenuOptions = listAssetTypeDefs().map((def: AssetTypeDef) => {
    return new Asset({
      description: def.label,
      assetCategory: AssetCategory.LiquidInvestableAssets,
      assetType: def.assetType
    })
  })

  const realEstateAndPropertyMenuOptions = [
    new Asset({description: "Primary Residence", assetCategory: AssetCategory.RealEstateAndProperty, assetType: AssetType.None}),
    new Asset({description: "Vacation Home", assetCategory: AssetCategory.RealEstateAndProperty, assetType: AssetType.None}),
    new Asset({description: "Other Property", assetCategory: AssetCategory.RealEstateAndProperty, assetType: AssetType.None}),
  ]

  const personalPropertyMenuOptions = [
    new Asset({description: "Computer Equipment", assetCategory: AssetCategory.PersonalProperty, assetType: AssetType.None}),
    new Asset({description: "Electronics", assetCategory: AssetCategory.PersonalProperty, assetType: AssetType.None}),
    new Asset({description: "Furniture", assetCategory: AssetCategory.PersonalProperty, assetType: AssetType.None}),
    new Asset({description: "Jewelry", assetCategory: AssetCategory.PersonalProperty, assetType: AssetType.None}),
    new Asset({description: "Tools", assetCategory: AssetCategory.PersonalProperty, assetType: AssetType.None}),
    new Asset({description: "Other Property", assetCategory: AssetCategory.PersonalProperty, assetType: AssetType.None}),
  ]

  const otherMenuAssetOptions = [
    new Asset({description: "Car #1", assetCategory: AssetCategory.OtherAssets, assetType: AssetType.None}),
    new Asset({description: "Car #2", assetCategory: AssetCategory.OtherAssets, assetType: AssetType.None}),
    new Asset({description: "Other Asset", assetCategory: AssetCategory.OtherAssets, assetType: AssetType.None}),
  ]

  const handleAddAsset = (index: number, assetMenuOptions: Asset[], assetGroup: Asset[]) => {
    const menuAsset = assetMenuOptions[index]
    if (currentModel && menuAsset) {
      const latestSnapshot = currentModel.snapshots[0]
      setCurrentSnapshot(latestSnapshot)
      const asset = new Asset({
        id: createUUID(),
        accountId: currentModel.accountId,
        userId: currentModel.userId,
        modelId: currentModel.id,
        description: menuAsset.description,
        balance: 0,
        balanceDate: latestSnapshot.date,
        assetCategory: menuAsset.assetCategory,
        assetType: menuAsset.assetType,
        start: getISODateStartOfYear(),
        sortOrder: assetGroup.length > 0 ? assetGroup[assetGroup.length-1].sortOrder + ModelStore.orderInterval : 0
      })
      switch (menuAsset.assetCategory) {
        case AssetCategory.LiquidInvestableAssets:
          setLiquidAssets([...liquidAssets, asset])
          setExpandedSection(NetWorthSection.LiquidAssets, true)
          break
        case AssetCategory.RealEstateAndProperty:
          setRealAssets([...realAssets, asset])
          setExpandedSection(NetWorthSection.RealEstateAssets, true)
          break
        case AssetCategory.PersonalProperty:
          setPersonalAssets([...personalAssets, asset])
          setExpandedSection(NetWorthSection.PersonalPropertyAssets, true)
          break
        case AssetCategory.OtherAssets:
          setOtherAssets([...otherAssets, asset])
          setExpandedSection(NetWorthSection.OtherAssets, true)
          break
      }
      setExpandedCard(asset.id, true)
    }
  }

  const handleSaveAsset = async (asset: Asset, snapshotItem?: ISnapshotDetail) => {
    setAsset(asset)
    // Update snapshot
    if (currentSnapshot && snapshotItem) {
      const updatedSnapshot = await modelStore.updateSnapshotDetail(currentSnapshot, snapshotItem)
      if (updatedSnapshot) {
        setCurrentSnapshot(updatedSnapshot)
        const index = snapshots.findIndex((s: Snapshot) => s.id === updatedSnapshot.id)
        if (index >= 0) {
          snapshots[index] = updatedSnapshot
        }
        setSnapshots([...snapshots])
      }
    }
    if (onChange) {
      onChange(asset)
    }
  }

  const getAsset = (id: string) => {
    let asset: Asset | undefined

    asset = liquidAssets.find((a: Asset) => a.id === id)
    if (!asset) {
      asset = realAssets.find((a: Asset) => a.id === id)
    }
    if (!asset) {
      asset = personalAssets.find((a: Asset) => a.id === id)
    }
    if (!asset) {
      asset = otherAssets.find((a: Asset) => a.id === id)
    }
    return asset
  }

  const setAsset = (asset: Asset) => {
    let index
    switch (asset.assetCategory) {
      case AssetCategory.LiquidInvestableAssets:
        index = liquidAssets.findIndex((e: Asset) => e.id === asset.id)
        if (index >= 0) {
          liquidAssets[index] = asset
          setLiquidAssets([...liquidAssets])
        }
        break
      case AssetCategory.RealEstateAndProperty:
        index = realAssets.findIndex((e: Asset) => e.id === asset.id)
        if (index >= 0) {
          realAssets[index] = asset
          setRealAssets([...realAssets])
        }
        break
      case AssetCategory.PersonalProperty:
        index = personalAssets.findIndex((e: Asset) => e.id === asset.id)
        if (index >= 0) {
          personalAssets[index] = asset
          setPersonalAssets([...personalAssets])
        }
        break
      case AssetCategory.OtherAssets:
        index = otherAssets.findIndex((e: Asset) => e.id === asset.id)
        if (index >= 0) {
          otherAssets[index] = asset
          setOtherAssets([...otherAssets])
        }
        break
    }
  }

  const handleDeleteAsset = async (asset: Asset) => {
    let index
    switch (asset.assetCategory) {
      case AssetCategory.LiquidInvestableAssets:
        index = liquidAssets.findIndex((e: Asset) => e.id === asset.id)
        if (index >= 0) {
          liquidAssets.splice(index, 1)
          setLiquidAssets([...liquidAssets])
        }
        break
      case AssetCategory.RealEstateAndProperty:
        index = realAssets.findIndex((e: Asset) => e.id === asset.id)
        if (index >= 0) {
          realAssets.splice(index, 1)
          setRealAssets([...realAssets])
        }
        break
      case AssetCategory.PersonalProperty:
        index = personalAssets.findIndex((e: Asset) => e.id === asset.id)
        if (index >= 0) {
          personalAssets.splice(index, 1)
          setPersonalAssets([...personalAssets])
        }
        break
      case AssetCategory.OtherAssets:
        index = otherAssets.findIndex((e: Asset) => e.id === asset.id)
        if (index >= 0) {
          otherAssets.splice(index, 1)
          setOtherAssets([...otherAssets])
        }
        break
    }

    // Delete snapshot detail
    if (currentSnapshot) {
      const updatedSnapshot = await modelStore.deleteSnapshotDetail(currentSnapshot, asset)
      if (updatedSnapshot) {
        setCurrentSnapshot(updatedSnapshot)
        index = snapshots.findIndex((s: Snapshot) => s.id === currentSnapshot.id)
        if (index >= 0) {
          snapshots[index] = updatedSnapshot
          setSnapshots([...snapshots])
        }
      }
    }

    if (onChange) {
      onChange(asset)
    }
  }

  const renderListWrapper = (children: ReactNode, providedMain: DroppableProvided) => {
    return (
      <div ref={providedMain.innerRef} {...providedMain.droppableProps}>
        {children}
      </div>
    )
  }

  const renderAsset = (asset: Asset, provided: DraggableProvided): ReactElement => {
    return (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <AssetCard asset={asset} model={currentModel!} snapshot={currentSnapshot}
                   expanded={getExpandedCard(asset.id)}
                   onChange={(event: SyntheticEvent, value: boolean) => setExpandedCard(asset.id, value)}
                   onSave={handleSaveAsset} onDelete={handleDeleteAsset}/>
      </div>
    )
  }

  const handleAssetDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    }

    let srcIndex = result.source.index
    let dstIndex = result.destination.index
    let assetGroup: Asset[]
    let setAssetGroup = setLiquidAssets
    let length: number
    let sortOrder
    switch (result.source.droppableId) {
      case "liquidAssets":
        assetGroup = liquidAssets
        setAssetGroup = setLiquidAssets
        break
      case "realAssets":
        assetGroup = realAssets
        setAssetGroup = setRealAssets
        break
      case "personalAssets":
        assetGroup = personalAssets
        setAssetGroup = setPersonalAssets
        break
      case "otherAssets":
        assetGroup = otherAssets
        setAssetGroup = setOtherAssets
        break
      default:
        return
    }

    length = assetGroup.length
    if (assetGroup[0].sortOrder) {
      if (dstIndex === 0) {
        // Beginning of list - Set to halfway between 0 and first item
        sortOrder = assetGroup[0].sortOrder / 2
      } else if (dstIndex >= length-1) {
        // End of list - Set to last item + orderInterval
        sortOrder = assetGroup[length - 1].sortOrder + ModelStore.orderInterval
      } else {
        // Middle of list - Set to halfway between the adjacent item orders
        sortOrder = assetGroup[dstIndex-1].sortOrder + (assetGroup[dstIndex].sortOrder - assetGroup[dstIndex-1].sortOrder) / 2
      }
      let asset = assetGroup[srcIndex]
      // Move before async operation to avoid extra refresh
      // Reorder expenses
      arrayMove(assetGroup, srcIndex, dstIndex)
      setAssetGroup(assetGroup)
      const input: UpdateAssetInput = {
        id: asset.id,
        accountId: asset.accountId,
        sortOrder: sortOrder
      }
      let update = await modelStore.updateAsset(input)
    } else {
      // Reorder assets
      arrayMove(assetGroup, srcIndex, dstIndex)
      setAssetGroup(await modelStore.reorderAssets(assetGroup))
    }
  }
  
  // AssetConversion methods

  const assetConversionMenuOptions = [
    new AssetConversion({description:"Roth Conversion", conversionType: AssetConversionType.RothConversion, taxableAmount: 0, incomeType: IncomeType.TaxableIRADistribution}),
    new AssetConversion({description:"Other Conversion", conversionType: AssetConversionType.OtherConversion, taxableAmount: 0, incomeType: IncomeType.None}),
  ]
  
  const handleAddAssetConversion = (index: number) => {
    const option = assetConversionMenuOptions[index]

    if (currentModel && option) {
      const assetConversion = new AssetConversion({
        id: createUUID(),
        accountId: currentModel.accountId,
        userId: currentModel.userId,
        modelId: currentModel.id,
        description: option.description,
        conversionType: option.conversionType,
        srcAssetId: undefined,
        dstAssetId: undefined,
        amount: 0,
        year: (new Date()).getFullYear(),
        taxableAmount: 0,
        incomeType: option.incomeType,
        sortOrder: assetConversions.length > 0 ? assetConversions[assetConversions.length-1].sortOrder + ModelStore.orderInterval : 0
      })
      setAssetConversions([...assetConversions, assetConversion])
      setExpandedSection(NetWorthSection.AssetConversions, true)
      setExpandedCard(assetConversion.id, true)
    }
  }

  const handleSaveAssetConversion = async (assetConversion: AssetConversion) => {
    const index = assetConversions.findIndex((a: AssetConversion) => a.id === assetConversion.id)
    if (index >= 0) {
      assetConversions[index] = assetConversion
    }
    setAssetConversions([...assetConversions])
    if (onChange) {
      onChange(assetConversion)
    }
  }

  const handleDeleteAssetConversion = async (assetConversion: AssetConversion) => {
    let index = assetConversions.findIndex((e: AssetConversion) => e.id === assetConversion.id)
    if (index >= 0) {
      assetConversions.splice(index, 1)
    }
    setAssetConversions([...assetConversions])
    if (onChange) {
      onChange(assetConversion)
    }
  }
  
  const handleCommitAssetConversion = async (results: Array<Asset | AssetConversion | undefined>) => {
    if (results.length !== 3) {
      notify.show('error', 'Asset conversion incomplete')
      return
    }
    const srcAsset = results[0] as Asset
    const dstAsset = results[1] as Asset
    // const assetConv = results[2] as AssetConversion

    let snapshot: Snapshot
    let details: ISnapshotDetail[] = []
    if (snapshots.length > 0 && isToday(snapshots[0].date)) {
      // Update latest snapshot
      snapshot = snapshots[0]
      details = clone(snapshot.details)
    } else {
      snapshot = new Snapshot({
        accountId: currentModel!.accountId,
        userId: currentModel!.userId,
        modelId: currentModel!.id,
        date: getISODateToday()
      })
      details = buildSnapshotDetails()
    }

    const srcDetail = details.find((d: ISnapshotDetail) => d.id === srcAsset.id)
    if (srcDetail) {
      srcDetail.balance = srcAsset.balance
    } else {
      details.push({id: srcAsset.id, typename: "Asset", balance: srcAsset.balance})
    }

    const dstDetail = details.find((d: ISnapshotDetail) => d.id === dstAsset.id)
    if (dstDetail) {
      dstDetail.balance = dstAsset.balance
    } else {
      details.push({id: dstAsset.id, typename: "Asset", balance: dstAsset.balance})
    }

    let update: Snapshot | undefined
    if (snapshot.id === "current") {
      snapshot.details = details
    } else {
      const createSnapshot: CreateSnapshotInput = {
        accountId: snapshot.accountId,
        userId: snapshot.userId,
        modelId: snapshot.modelId,
        date: getISODateToday(),
        details: JSON.stringify(details)
      }
      await modelStore.createSnapshot(createSnapshot)
    }

    // Reload model
    const appliedModel = await modelStore.getAppliedModel(undefined,true)
    loadModel(appliedModel)
  }
  
  const buildSnapshotDetails = () => {
    const details: ISnapshotDetail[] = [
      ...liquidAssets.map((a: Asset) => new SnapshotDetail({id: a.id, typename: "Asset", balance: a.balance})),
      ...realAssets.map((a: Asset) => new SnapshotDetail({id: a.id, typename: "Asset", balance: a.balance})),
      ...personalAssets.map((a: Asset) => new SnapshotDetail({id: a.id, typename: "Asset", balance: a.balance})),
      ...otherAssets.map((a: Asset) => new SnapshotDetail({id: a.id, typename: "Asset", balance: a.balance})),
      ...liabilities.map((l: Liability) => new SnapshotDetail({id: l.id, typename: "Liability", balance: l.balance})),
    ]
    
    return details
  }

  const renderAssetConversion = (assetConversion: AssetConversion, provided: DraggableProvided): ReactElement => {
    return (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <AssetConversionCard assetConversion={assetConversion} model={currentModel!}
                       expanded={getExpandedCard(assetConversion.id)}
                       onChange={(event: SyntheticEvent, value: boolean) => setExpandedCard(assetConversion.id, value)}
                       onSave={handleSaveAssetConversion} onDelete={handleDeleteAssetConversion}
                       onCommit={handleCommitAssetConversion}/>
      </div>
    )
  }

  const handleAssetConversionDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const srcIndex = result.source.index
    const dstIndex = result.destination.index
    let length
    let sortOrder
    if (result.source.droppableId === "assetConversions") {
      length = assetConversions.length
      if (assetConversions[0].sortOrder) {
        if (dstIndex === 0) {
          // Beginning of list - Set to halfway between 0 and first item
          sortOrder = assetConversions[0].sortOrder / 2
        } else if (dstIndex >= length-1) {
          // End of list - Set to last item + orderInterval
          sortOrder = assetConversions[length - 1].sortOrder + ModelStore.orderInterval
        } else {
          // Middle of list - Set to halfway between the adjacent item orders
          sortOrder = assetConversions[dstIndex-1].sortOrder + (assetConversions[dstIndex].sortOrder - assetConversions[dstIndex-1].sortOrder) / 2
        }
        let assetConversion = assetConversions[srcIndex]
        // Move before async operation to avoid extra refresh
        arrayMove(assetConversions, srcIndex, dstIndex)
        // Don't update scenario conversions
        if (assetConversion.updatedAt) {
          const input: UpdateAssetConversionInput = {
            id: assetConversion.id,
            accountId: assetConversion.accountId,
            sortOrder: sortOrder
          }
          let update = await modelStore.updateAssetConversion(input)
        }
        // if (update) {
        //   assetConversions[dstIndex] = update
        // }
        // setAssetConversions([...assetConversions])
      } else {
        // Reorder assetConversions
        arrayMove(assetConversions, srcIndex, dstIndex)
        setAssetConversions(await modelStore.reorderAssetConversions(assetConversions))
      }
    }
  }

  // Liability methods

  const liabilityMenuOptions = [
    "Home Loan",
    "Car Loan",
    "Personal Loan",
    "Home Equity Line of Credit",
    "Other Liability"
  ]

  const handleAddLiability = (index: number) => {
    const description = liabilityMenuOptions[index]
    if (currentModel && description && currentSnapshot) {
      const latestSnapshot = currentModel.snapshots[0]
      setCurrentSnapshot(latestSnapshot)
      const liability = new Liability({
        id: createUUID(),
        accountId: currentModel.accountId,
        userId: currentModel.userId,
        modelId: currentModel.id,
        description: description,
        balance: 0,
        balanceDate: latestSnapshot.date,
        start: getISODateStartOfYear(),
        sortOrder: liabilities.length > 0 ? liabilities[liabilities.length-1].sortOrder + ModelStore.orderInterval : 0
      })
      setLiabilities([...liabilities, liability])
      setExpandedSection(NetWorthSection.Liabilities, true)
      setExpandedCard(liability.id, true)
    }
  }

  const handleSaveLiability = async (liability: Liability, snapshotItem?: ISnapshotDetail) => {
    const index = liabilities.findIndex((e: Liability) => e.id === liability.id)
    if (index >= 0) {
      liabilities[index] = liability
      setLiabilities([...liabilities])

      if (currentSnapshot && snapshotItem) {
        const updatedSnapshot = await modelStore.updateSnapshotDetail(currentSnapshot, snapshotItem)
        if (updatedSnapshot) {
          setCurrentSnapshot(updatedSnapshot)
        }
      }
    }

    // Check for loan payment expense
    const expense = model.expenses.find((e: Expense) => e.liabilityId === liability.id)
    if (!expense) {
      confirm.show("Add Payment", "Would you like to add a payment expense for this liability? (If one already exists, you can connect it from the Budget view.)",
      ['Yes', 'No'], () => {
        if (currentModel) {
          const expense = new Expense({
            id: createUUID(),
            accountId: currentModel.accountId,
            userId: currentModel.userId,
            modelId: currentModel.id,
            description: `${liability.description} Payment`,
            expenseCategory: ExpenseCategory.LoansAndLiabilities,
            schedule: Schedule.getDefaultSchedule(ScheduleNames.Monthly),
            start: liability.start,
            end: liability.end,
            amount: 0,
            ownerId: liability.ownerId,
            liabilityId: liability.id
          })
          setEditExpense(expense)
        }
        return true
      })
    }
    if (onChange) {
      onChange(liability)
    }
  }

  const handleDeleteLiability = async (liability: Liability) => {
    let index = liabilities.findIndex((e: Liability) => e.id === liability.id)
    if (index >= 0) {
      liabilities.splice(index, 1)
    }
    setLiabilities([...liabilities])

    // Delete snapshot detail
    if (currentSnapshot) {
      const updatedSnapshot = await modelStore.deleteSnapshotDetail(currentSnapshot, liability)
      if (updatedSnapshot) {
        setCurrentSnapshot(updatedSnapshot)
        index = snapshots.findIndex((s: Snapshot) => s.id === currentSnapshot.id)
        if (index >= 0) {
          snapshots[index] = updatedSnapshot
          setSnapshots([...snapshots])
        }
      }
    }
    if (onChange) {
      onChange(liability)
    }
  }

  const renderLiability = (liability: Liability, provided: DraggableProvided): ReactElement => {
    return (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <LiabilityCard liability={liability} model={currentModel!} snapshot={currentSnapshot}
                       expanded={getExpandedCard(liability.id)}
                       onChange={(event: SyntheticEvent, value: boolean) => setExpandedCard(liability.id, value)}
                       onSave={handleSaveLiability} onDelete={handleDeleteLiability}/>      </div>
    )
  }

  const handleLiabilityDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const srcIndex = result.source.index
    const dstIndex = result.destination.index
    let length
    let sortOrder
    if (result.source.droppableId === "liabilities") {
      length = liabilities.length
      if (liabilities[0].sortOrder) {
        if (dstIndex === 0) {
          // Beginning of list - Set to halfway between 0 and first item
          sortOrder = liabilities[0].sortOrder / 2
        } else if (dstIndex >= length-1) {
          // End of list - Set to last item + orderInterval
          sortOrder = liabilities[length - 1].sortOrder + ModelStore.orderInterval
        } else {
          // Middle of list - Set to halfway between the adjacent item orders
          sortOrder = liabilities[dstIndex-1].sortOrder + (liabilities[dstIndex].sortOrder - liabilities[dstIndex-1].sortOrder) / 2
        }
        let liability = liabilities[srcIndex]
        // Move before async operation to avoid extra refresh
        arrayMove(liabilities, srcIndex, dstIndex)
        const input: UpdateLiabilityInput = {
          id: liability.id,
          accountId: liability.accountId,
          sortOrder: sortOrder
        }
        let update = await modelStore.updateLiability(input)
        if (update) {
          liabilities[dstIndex] = update
        }
        setLiabilities([...liabilities])
      } else {
        // Reorder liabilities
        arrayMove(liabilities, srcIndex, dstIndex)
        setLiabilities(await modelStore.reorderLiabilities(liabilities))
      }
    }
  }
  
  const renderSnapshotMenu = () => {
    if (currentSnapshot && currentSnapshot.date) {
      return (
        <BasicMenuButton
          icon={<ExpandMoreIcon sx={{color: theme.palette.primary.main}}/>}
          label={
            <Typography variant="h4" color="primary" sx={{padding:0, margin:0}}>
              {currentSnapshot.isCurrent ? "Current" : dateToLocalFormat(currentSnapshot.date, "MMM d, yyyy")}
            </Typography>
          }
          labelPosition="left"
          options={snapshots.map((s: Snapshot) => {
            if (s.isCurrent) {
              return "Current"
            } else {
              return dateToLocalFormat(s.date, "MMM d, yyyy")
            }
          })}
          value={currentIndex}
          onClickOption={(index: number) => {
            setCurrentIndex(index)
            setCurrentSnapshot(snapshots[index])
            setCurrentNetWorthCalc(netWorthCalcs[index])
          }}
        />
      )
    } else {
      return null
    }
  }

  const renderNetWorth = () => {
    const netWorth = currentNetWorthCalc?.netWorthTotal ?? 0

    const color = (netWorth < 0) ? "red" : "green"
    return (
      <Typography variant="h4" color={color} sx={{paddingTop:"2px"}}>
        {numberToMoneyFormat(netWorth, 0)}
      </Typography>
    )
  }

  const renderUnfoldMenu = () => {
    const allSectionsExpanded = getAllSectionsExpanded()
    const allCardsExpanded = getAllCardsExpanded()

    let icon
    if (allSectionsExpanded) {
      if (allCardsExpanded) {
        icon = <UnfoldLessIcon/>
      } else {
        icon = <UnfoldMoreDoubleIcon/>
      }
    } else {
      icon = <UnfoldMoreIcon/>
    }
    return (
      <IconButton sx={{marginLeft:0, paddingLeft:"4px", paddingRight:0}}
                  onClick={() => {
                    if (allSectionsExpanded) {
                      if (allCardsExpanded) {
                        setAllCardsExpanded(false)
                        setAllSectionsExpanded(false)
                      } else {
                        setAllCardsExpanded(true)
                      }
                    } else {
                      setAllSectionsExpanded(true)
                    }
                  }}
      >
        {icon}
      </IconButton>
    )
  }

  const renderViewMenu = () => {
    const options = ["Net Worth History", "Asset / Liability History", "Liquid Asset History", "Risk Allocation", "Tax Allocation"]

    return (
      <ViewMenu
        view={chartView}
        options={options}
        variant="auto"
        onChange={(index: number) => {
          Tracking.event({action: "Networth View", label: options[index]})
          setChartView(index)
        }}
      />
    )

    // return (
    //   <BasicMenuButton
    //     icon={<ExpandMoreIcon sx={{color: theme.palette.primary.main}}/>}
    //     label={
    //       <Typography variant="h3" color="primary" sx={{padding:0, margin:0}}>
    //         {options[chartView]}
    //       </Typography>
    //     }
    //     labelPosition="left"
    //     options={options}
    //     value={chartView}
    //     onClickOption={(index: number) => {
    //       setChartView(index)
    //     }}
    //   />
    // )
  }

  const handleNewSnapshot = () => {
    // Copy current snapshot
    const current = currentSnapshot ? currentSnapshot : snapshots[0]
    const newSnapshot = new Snapshot({
      accountId: current.accountId,
      userId: current.userId,
      modelId: current.modelId,
      date: current.date,
      description: "",
      details: JSON.stringify(current.details)
    })
    setEditSnapshot(newSnapshot)
  }

  const handleEditSnapshot = (id: string) => {
    const found = snapshots.find((s: Snapshot) => s.id === id)
    if (found) {
      setEditSnapshot(found)
    }
  }

  const handleSaveSnapshot = async (saveSnapshot: Snapshot) => {
    if (isToday(saveSnapshot.date)) {
      await updateNetworthFromSnapshot(saveSnapshot)
    }
    setEditSnapshot(undefined)
    if (onChange) {
      onChange(saveSnapshot)
    }
    // await loadModel(currentModel, saveSnapshot.id)

    // const index = snapshots.findIndex((s: Snapshot) => s.id === saveSnapshot.id)
    // if (index >= 0) {
    //   setCurrentIndex(index)
    //   setCurrentSnapshot(snapshots[index])
    //   const calcs = calcNetWorth(saveSnapshot)
    //   if (calcs) {
    //     setCurrentNetWorthCalc(calcs[index])
    //   }
    //   if (index === 0) {
    //     await updateNetworthFromSnapshot(saveSnapshot)
    //   }
    // } else {
    //   setCurrentIndex(0)
    //   setCurrentSnapshot(snapshots[0])
    //   setCurrentNetWorthCalc(netWorthCalcs[0])
    // }
    //
    // setEditSnapshot(undefined)
  }

  const handleDeleteSnapshot = async (deleteSnapshot: Snapshot) => {
    setEditSnapshot(undefined)
    // await loadModel(currentModel)
    if (onChange) {
      onChange(deleteSnapshot)
    }
  }

  const updateNetworthFromSnapshot = async (snapshot: Snapshot) => {
    const accountId = currentModel!.accountId
    const assetPromises: Promise<Asset | undefined>[] = []
    const liabilityPromises: Promise<Liability | undefined>[] = []
    snapshot.details.forEach((detail: ISnapshotDetail) => {
      if (detail.typename === "Liability") {
        const liability = liabilities.find((l: Liability) => l.id === detail.id)
        if (liability && (liability.balance !== detail.balance || compareAsc(liability.balanceDate, snapshot.date) !== 0)) {
          const update: UpdateLiabilityInput = {
            id: detail.id,
            accountId: accountId,
            balance: detail.balance,
            balanceDate: getISODateFromDate(snapshot.date)
          }
          liabilityPromises.push(modelStore.updateLiability(update))
        }
      } else {
        const asset = getAsset(detail.id)
        if (asset && (asset.balance !== detail.balance || compareAsc(asset.balanceDate, snapshot.date) !== 0)) {
          const update: UpdateAssetInput = {
            id: detail.id,
            accountId: accountId,
            balance: detail.balance,
            balanceDate: getISODateFromDate(snapshot.date)
          }
          assetPromises.push(modelStore.updateAsset(update))
        }
      }
    })
    await Promise.all(assetPromises)
    await Promise.all(liabilityPromises)
  }

  const handleSelectSnapshot = (index: number) => {
    setCurrentIndex(index)
    setCurrentSnapshot(snapshots[index])
    setCurrentNetWorthCalc(netWorthCalcs[index])
  }

  const renderSnapshotDialog = () => {
    if (!editSnapshot) {
      return null
    }

    let deleteFn
    if (snapshots.length > 1) {
      deleteFn = handleDeleteSnapshot
    }

    return (
      <SnapshotDialog
        snapshot={editSnapshot}
        liquidAssets={liquidAssets}
        realAssets={realAssets}
        personalAssets={personalAssets}
        otherAssets={otherAssets}
        liabilities={liabilities}
        onCancel={() => setEditSnapshot(undefined)}
        onSave={handleSaveSnapshot}
        onDelete={deleteFn}
      />
    )
  }

  const renderExpenseEditDialog = () => {
    if (!editExpense) {
      return null
    }

    return (
      <ExpenseEditDialog expense={editExpense} model={model}
                         open={true}
                         onSave={(update: Expense) => {
                           setEditExpense(undefined)
                         }}
                         onClose={() => {
                           setEditExpense(undefined)
                         }}/>
    )
  }

  if (!currentNetWorthCalc) {
    return null
  }

  const leftPanelWidth = 360
  const rightPanelWidth = width - leftPanelWidth - 24

  return (
    <Box sx={{display:"flex", flexDirection:"row", mt:1, minHeight: "calc(100vh - 180px)"}}>
      <Box sx={{display:"flex", flexGrow:0, width: { xs:"100%", sm:leftPanelWidth},
        minWidth: { xs:"100%", sm:leftPanelWidth}, minHeight:"100%", maxHeight: "calc(100vh - 84px)",
        overflowY:"scroll", flexDirection:"column"}}>
        <Box sx={{display:"flex", mt:0, pl:0, pb:0, mb:1}}>
          <Typography variant="h2" color="primary">Assets & Liabilities</Typography>
        </Box>
        <Box sx={{display:"flex", flexGrow:0, flexDirection:"row", justifyContent:"stretch", paddingLeft: 0.5, paddingRight: 0.5, maxHeight: 50}}>
          <Box sx={{display:"flex", flexGrow:0, flexDirection:"column"}}>
            <Box sx={{display:"flex", flexGrow:0, justifyContent:"space-between", maxHeight:16, marginBottom:1}}>
              <FormLabel sx={styles.formLabel}>Snapshot</FormLabel>
              <Button size="small" onClick={handleNewSnapshot} variant="outlined" sx={styles.miniButton}>New</Button>
            </Box>
            <Box sx={{display:"flex", flexGrow:0}}>
              {renderSnapshotMenu()}
            </Box>
          </Box>
          <Box sx={{display:"flex", flexGrow:1, flexDirection:"column", textAlign:"right"}}>
            <FormLabel sx={styles.formLabel}>Net Worth</FormLabel>
            {renderNetWorth()}
          </Box>
          <Box sx={{display:"flex", flexGrow:0, paddingTop:1}}>
            {renderUnfoldMenu()}
          </Box>
        </Box>
        <Section title="Liquid Assets" total={numberToMoneyFormat(currentNetWorthCalc.liquidAssetsTotal, 0)}
                 expanded={getExpandedSection(NetWorthSection.LiquidAssets)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedSection(NetWorthSection.LiquidAssets, value)}
                 button={
                     <BasicMenuButton
                       icon={<AddCircleRounded sx={{color: theme.palette.secondary.light}}/>}
                       label=""
                       options={liquidAssetMenuOptions.map((asset: Asset) => asset.description)}
                       onClickOption={(index: number) => handleAddAsset(index, liquidAssetMenuOptions, liquidAssets)}
                     />
                 }
        >
          <DragDropContext onDragEnd={handleAssetDragEnd}>
            <DraggableList droppableId="liquidAssets" data={liquidAssets} renderWrapper={renderListWrapper}
                           renderItem={renderAsset}
            />
          </DragDropContext>
        </Section>

        <Section title="Asset Conversions" total={numberToMoneyFormat(assetConversionsTotal, 0)}
                 expanded={getExpandedSection(NetWorthSection.AssetConversions)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedSection(NetWorthSection.AssetConversions, value)}
                 button={
                   <BasicMenuButton
                     icon={<AddCircleRounded sx={{color: theme.palette.secondary.light}}/>}
                     label=""
                     options={assetConversionMenuOptions.map((assetConversion: AssetConversion) => assetConversion.description)}
                     onClickOption={(index: number) => handleAddAssetConversion(index)}
                   />
                 }
        >
          <DragDropContext onDragEnd={handleAssetConversionDragEnd}>
            <DraggableList droppableId="assetConversions" data={assetConversions} renderWrapper={renderListWrapper}
                           renderItem={renderAssetConversion}
            />
          </DragDropContext>
        </Section>
        <Section title="Real Estate" total={numberToMoneyFormat(currentNetWorthCalc.realAssetsTotal, 0)}
                 expanded={getExpandedSection(NetWorthSection.RealEstateAssets)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedSection(NetWorthSection.RealEstateAssets, value)}
                 button={
                   <BasicMenuButton
                     icon={<AddCircleRounded sx={{color: theme.palette.secondary.light}}/>}
                     label=""
                     options={realEstateAndPropertyMenuOptions.map((asset: Asset) => asset.description)}
                     onClickOption={(index: number) => handleAddAsset(index, realEstateAndPropertyMenuOptions, realAssets)}
                   />
                 }
        >
          <DragDropContext onDragEnd={handleAssetDragEnd}>
            <DraggableList droppableId="realAssets" data={realAssets} renderWrapper={renderListWrapper}
                           renderItem={renderAsset}
            />
          </DragDropContext>
        </Section>

        <Section title="Personal Property" total={numberToMoneyFormat(currentNetWorthCalc.personalAssetsTotal, 0)}
                 expanded={getExpandedSection(NetWorthSection.PersonalPropertyAssets)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedSection(NetWorthSection.PersonalPropertyAssets, value)}
                 button={
                   <BasicMenuButton
                     icon={<AddCircleRounded sx={{color: theme.palette.secondary.light}}/>}
                     label=""
                     options={personalPropertyMenuOptions.map((asset: Asset) => asset.description)}
                     onClickOption={(index: number) => handleAddAsset(index, personalPropertyMenuOptions, personalAssets)}
                   />
                 }
        >
          <DragDropContext onDragEnd={handleAssetDragEnd}>
            <DraggableList droppableId="personalAssets" data={personalAssets} renderWrapper={renderListWrapper}
                           renderItem={renderAsset}
            />
          </DragDropContext>
        </Section>

        <Section title="Other Assets" total={numberToMoneyFormat(currentNetWorthCalc.otherAssetsTotal, 0)}
                 expanded={getExpandedSection(NetWorthSection.OtherAssets)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedSection(NetWorthSection.OtherAssets, value)}
                 button={
                   <BasicMenuButton
                     icon={<AddCircleRounded sx={{color: theme.palette.secondary.light}}/>}
                     label=""
                     options={otherMenuAssetOptions.map((asset: Asset) => asset.description)}
                     onClickOption={(index: number) => handleAddAsset(index, otherMenuAssetOptions, otherAssets)}
                   />
                 }
        >
          <DragDropContext onDragEnd={handleAssetDragEnd}>
            <DraggableList droppableId="otherAssets" data={otherAssets} renderWrapper={renderListWrapper}
                           renderItem={renderAsset}
            />
          </DragDropContext>
        </Section>

        <Section title="Liabilities"
                 total={numberToMoneyFormat(-currentNetWorthCalc.liabilitiesTotal, 0)}
                 expanded={getExpandedSection(NetWorthSection.Liabilities)}
                 onChange={(event: SyntheticEvent, value: boolean) => setExpandedSection(NetWorthSection.Liabilities, value)}
                 button={
                   <BasicMenuButton
                     icon={<AddCircleRounded sx={{color: theme.palette.secondary.light}}/>}
                     label=""
                     options={liabilityMenuOptions}
                     onClickOption={handleAddLiability}
                   />
                 }
        >
          <DragDropContext onDragEnd={handleLiabilityDragEnd}>
            <DraggableList droppableId="liabilities" data={liabilities} renderWrapper={renderListWrapper}
                           renderItem={renderLiability}
            />
          </DragDropContext>
        </Section>

      </Box>

      <Box sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        width: rightPanelWidth,
        maxWidth: rightPanelWidth,
        minHeight: "100%",
        maxHeight: "calc(100vh - 84px)",
        overflowY:"scroll"
      }}>
        <Box display="flex" flexDirection="column" alignContent="left" pl={2} height={50} mb="8px">
          <FormLabel sx={styles.formLabel}>View</FormLabel>
          {renderViewMenu()}
        </Box>
        <Box display="flex" flexDirection="column" pl={2} mb={2}>
          <TableContainer component={Paper} sx={{paddingTop:1, overflowY:"hidden"}}>
            {chartView === NetWorthChartView.NetWorthHistory &&
              <NetWorthApexChart netWorthCalcs={netWorthCalcs} selected={currentIndex}
                                 onSelect={(index: number) => {
                                   if (index >= 0 && index < snapshots.length) {
                                     setCurrentIndex(index)
                                     setCurrentSnapshot(snapshots[index])
                                     setCurrentNetWorthCalc(netWorthCalcs[index])
                                    }
                                 }}
              />
            }
            {chartView === NetWorthChartView.AssetLiabilityHistory &&
              <AssetLiabilityApexChart netWorthCalcs={netWorthCalcs} selected={currentIndex}
                                       onSelect={(index: number) => {
                                         if (index >= 0 && index < snapshots.length) {
                                           setCurrentIndex(index)
                                           setCurrentSnapshot(snapshots[index])
                                           setCurrentNetWorthCalc(netWorthCalcs[index])
                                         }
                                       }}
              />
            }
            {chartView === NetWorthChartView.LiquidAssetHistory &&
              <LiquidAssetsApexChart liquidAssets={liquidAssets} snapshots={snapshots} selected={currentIndex}
                                     onSelect={(index: number) => {
                                       if (index >= 0 && index < snapshots.length) {
                                         setCurrentIndex(index)
                                         setCurrentSnapshot(snapshots[index])
                                         setCurrentNetWorthCalc(netWorthCalcs[index])
                                       }
                                     }}
              />
            }
            {chartView === NetWorthChartView.RiskAllocation &&
              <LiquidAssetRiskAllocationChart liquidAssets={liquidAssets}/>
            }
            {chartView === NetWorthChartView.TaxAllocation &&
              <LiquidAssetTaxAllocationChart liquidAssets={liquidAssets}/>
            }
          </TableContainer>
        </Box>

        <Box display="flex" flexGrow={1} flexDirection="column" pl={2} py={1}>
          <Box display="flex" flexGrow={1} justifyContent="space-between" maxHeight={40}>
            <Typography variant="h3" color="primary" gutterBottom>Snapshots</Typography>
            <Button size="small" onClick={handleNewSnapshot} variant="outlined" sx={styles.smallButton}>New</Button>
          </Box>
          <TableContainer component={Paper}>
            <Table size="small" width="100%">
              <TableHead>
                <TableRow>
                  <TableCell width={30}></TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Liquid</TableCell>
                  <TableCell align="right">Real Estate</TableCell>
                  <TableCell align="right">Personal</TableCell>
                  <TableCell align="right">Other</TableCell>
                  <TableCell align="right">Total Assets</TableCell>
                  <TableCell align="right">Total Liabilities</TableCell>
                  <TableCell align="right">Net Worth</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {netWorthCalcs.map((n: INetWorthCalc, index: number) =>
                  <TableRow selected={n.id === currentNetWorthCalc.id} key={n.id}
                            onClick={() => {handleSelectSnapshot(index)}}>
                    <TableCell><SnapshotIcon style={styles.snapshotIcon}/></TableCell>
                    <TableCell>{snapshots[index] && snapshots[index].id === "current" ? "Current" : dateToLocalFormat(n.date)}</TableCell>
                    <TableCell align="right">{numberToMoneyFormat(n.liquidAssetsTotal, 0)}</TableCell>
                    <TableCell align="right">{numberToMoneyFormat(n.realAssetsTotal, 0)}</TableCell>
                    <TableCell align="right">{numberToMoneyFormat(n.personalAssetsTotal, 0)}</TableCell>
                    <TableCell align="right">{numberToMoneyFormat(n.otherAssetsTotal, 0)}</TableCell>
                    <TableCell align="right">{numberToMoneyFormat(n.assetsTotal, 0)}</TableCell>
                    <TableCell align="right">{numberToMoneyFormat(-n.liabilitiesTotal, 0)}</TableCell>
                    <TableCell align="right">{numberToMoneyFormat(n.netWorthTotal, 0)}</TableCell>
                    <TableCell align="right">
                        <IconButton onClick={() => handleEditSnapshot(n.id)}>
                          <EditIcon/>
                        </IconButton>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {renderSnapshotDialog()}
      {renderExpenseEditDialog()}
    </Box>
  )
}

export default NetWorthView