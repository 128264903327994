import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Logger from "./components/Logger";
import {configure} from "mobx";
import { Provider } from 'mobx-react';
import UserStore from "./stores/UserStore";
import Confirm from "./components/confirm/Confirm";
import Alert from "./components/alert/Alert";
import Notify from "./components/notify/Notify";
import RbcAPI from "./apis/RbcAPI";
import AccountStore from "./stores/AccountStore";
import Progress from "./components/Progress";
import {render} from "react-dom";
import Calculator from "./components/calculator/Calculator";
import TaxEstimator from "./components/tax/TaxEstimator";
import ModelStore from "./stores/ModelStore";
import AuthAPI from "./apis/AuthAPI";
import MigrateAPI from "./apis/MigrateAPI";
import TermsOfUse from "./components/page/TermsOfUse";
import PrivacyPolicy from "./components/page/PrivacyPolicy";
import BillingAPI from "./apis/BillingAPI";
import ConvertKitAPI from "./apis/ConvertKitAPI";
import config from "react-global-configuration";
import Tracking from "./components/Tracking";

Logger.init()

const trackingId = config.get("google.trackingId")
if (trackingId && trackingId !== "" && trackingId !== "NONE") {
  Tracking.initialize(trackingId, config.get("google.options"))
}

// Migrating from MobX 5 to 6, turning off @action enforcement for now.
// In the future, we'll want to turn on computedRequiresReaction, reactionRequiresObservable and observableRequiresReaction and enforceActions: "observed".
// See docs here: https://mobx.js.org/migrating-from-4-or-5.html
configure({ enforceActions: "never" });

const authAPI = new AuthAPI()
const rbcAPI = new RbcAPI()
const migrateAPI = new MigrateAPI()
const billingAPI = new BillingAPI()
const convertKitAPI = new ConvertKitAPI()

const userStore = new UserStore({
  rbcAPI,
  billingAPI
})

const taxEstimator = new TaxEstimator()

const calculator = new Calculator({
  taxEstimator
})

const accountStore = new AccountStore({
  rbcAPI
})

const modelStore = new ModelStore({
  rbcAPI, calculator, userStore
})

const resources = {
  confirm: new Confirm(),
  notify: new Notify(),
  alert: new Alert(),
  authAPI,
  rbcAPI,
  billingAPI,
  migrateAPI,
  convertKitAPI,
  userStore,
  accountStore,
  modelStore,
  calculator,
  taxEstimator,
  progress: new Progress(),
  privacyPolicy: new PrivacyPolicy(),
  termsOfUse: new TermsOfUse(),
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider {...resources}>
    <App />
  </Provider>
);

// render(<Provider {...resources}>
//        <App />
//      </Provider>, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
