import IModelItem from "./IModelItem";

export type TaxValueKey =
  "filingStatus" |
  "numberOfQualifiedChildDependents" | "numberOfQualifiedOtherDependents" |
  "adjustmentsToIncome" |
  "itemizedDeductionsIn" |
  "otherQualifyingDeductionsToTaxableIncome" |
  "additionalCreditsAndPayments" |
  "earnedIncomeCredit" |
  "otherPaymentsAndRefundableCredits" |
  "childTaxCreditOrCreditForOtherDependents" |
  "refundableAdditionalChildTaxCredits";


class TaxValue implements IModelItem {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  userId: string
  modelId: string
  year: number
  key: TaxValueKey
  value: number | null

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.userId = data.userId
    this.modelId = data.modelId
    this.year = Number(data.year)
    this.key = data.key
    this.value = data.value
  }
}

export default TaxValue