import {Box, IconButton, Typography, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useResources} from "../../stores/ResourceProvider";
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  GridToolbarQuickFilter
} from "@mui/x-data-grid";
import {isoToLocalDateString} from "../../stores/StoreUtilities";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import User from "../../model/User";
import Account from "../../model/Account";
import {IUser} from "../../apis/MigrateAPI";
import MigrateUserDialog from "./MigrateUserDialog";
import {htmlDecode} from "js-htmlencode";
import MenuButton from "../../components/controls/MenuButton";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import RoutesConfig from "../../RoutesConfig";
import {useNavigate} from "react-router-dom";
import UserEditDialog from "../settings/UserEditDialog";
import {UpdateUserInput} from "../../API";

const MigrateUsersView = () => {
  const [legacyUsers, setLegacyUsers] = useState<IUser[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false)
  const [isLoadingLegacy, setIsLoadingLegacy] = useState<boolean>(false)
  const [queryOptions, setQueryOptions] = useState({})
  const [searchInput, setSearchInput] = useState<string>("")
  const [showMigrateUserDialog, setShowMigrateUserDialog] = useState<boolean>(false)
  const [migrateUser, setMigrateUser] = useState<IUser | undefined>()
  const [editUser, setEditUser] = useState<User | undefined>()

  const { accountStore, userStore, migrateAPI, notify } = useResources()

  const navigate = useNavigate()

  useEffect(() => {
    console.log(`useEffect loadUsers`)
    if (!isLoadingUsers && users.length === 0 && accountStore.account) {
      console.log(`Calling loadUsers`)
      loadUsers(accountStore.account)
    }
  }, [])

  useEffect(() => {
    console.log(`searchInput = ${searchInput}`)
    setLegacyUsers([])
    if (searchInput && !isLoadingUsers && !isLoadingLegacy) {
      setIsLoadingLegacy(true)
      loadLegacyUsers(searchInput)
    }
  }, [searchInput])

  const loadUsers = async (account: Account) => {
    try {
      // const filter: ModelUserFilterInput = {
      //   and : [
      //     {userStatus: {ne: UserStatus.Cancelled}},
      //     {userStatus: {ne: UserStatus.Deleted}},
      //     ]
      //   }

      console.log(`Loading users`)
      setIsLoadingUsers(true)
      const users = await accountStore.listUsersByAccount(account.id)
      if (users && users.length > 0) {
        setUsers(users)
      }
      console.log(`Loaded ${users.length} users`)
    } catch (err: any) {
      notify.show("error", err.message)
    } finally {
      setIsLoadingUsers(false)
    }
  }

  const getMigratedUser = (legacy: any): User | undefined => {
    let user
    if (users.length > 0) {
      user = users.find((u: User) => u.id === String(legacy.user_id))
    }

    return user
  }

  const loadLegacyUsers = async (search: string) => {
    //const response = await migrateAPI.test()
    try {
      console.log(`Loading legacy users`)
      const legacy = await migrateAPI.listUsers(search)
      if (legacy && legacy.length > 0) {
        legacy.forEach((lu: any) => {
          const user = getMigratedUser(lu)
          if (user) {
            lu.migratedOn = user.migratedOn ?? user.createdAt
            lu.advisor_accessible = user.advisorAccess
          }
        })
        setLegacyUsers(legacy)
      }
      console.log(`Loaded ${legacy.length} legacy users`)

      // const test = await migrateAPI.test()
      // console.log(`test`, test)
    } catch (err: any) {
      notify.show("error", err.message)
    } finally {
      setIsLoadingLegacy(false)
    }
  }

  const handleMigrate = (row: IUser) => {
    setMigrateUser(row)
    setShowMigrateUserDialog(true)
  }

  const updateUser = async (user: User) => {
    // Check if this was an existing active user
    const existing = users.find((u: User) => u.id === user.id)
    if (existing) {
      // Restore userStatus
      if (existing.userStatus !== user.userStatus) {
        const input: UpdateUserInput = {
          id: user.id,
          accountId: user.accountId,
          userStatus: existing.userStatus
        }
        const updated = await userStore.updateUser(input)
        if (updated) {
          user = updated
        }
      }
    }

    accountStore.updateUser(user)
    loadUsers(accountStore.account!)

    const user_id = parseInt(user.id)
    const index = legacyUsers.findIndex((u: IUser) => u.user_id === user_id)
    if (index >= 0) {
      legacyUsers[index]["migratedOn"] = user.createdAt
      setLegacyUsers([...legacyUsers])
    }
  }

  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  // const accountStatusNames = [
  //   "Cancelled", "Lifetime", "Legacy", "Subscription", "Free", "Client",
  // ]

  const formatAccountStatus = (value: number) => {
    let status: string
    switch (value) {
      case -1:
        status = "Deleted"
        break
      case 0:
        status = "Cancelled"
        break
      case 1:
        status = "Lifetime"
        break
      case 2:
        status = "Legacy"
        break
      case 3:
        status = "Subscription"
        break
      case 4:
        status = "Free"
        break
      case 5:
        status = "Client"
        break
      default:
        status = String(value)
    }
    return status
  }

  const formatBoolean = (value?: number) => {
    return (value === 1) ? "X" : ""
  }

  const formatDate = (value?: string) => {
    return isoToLocalDateString(value, "M/d/yyyy")
  }

  enum UserMenuOption {
    Access = "Access Account",
    Edit = "Edit",
    Migrate = "Migrate",
  }

  const getMenuOptions = (user: IUser) => {
    return [UserMenuOption.Access, UserMenuOption.Edit, UserMenuOption.Migrate]
  }

  const handleMenuOption = (option: UserMenuOption, row: IUser) => {
    if (option === UserMenuOption.Edit) {
      const user = getMigratedUser(row)
      if (user) {
        setEditUser(user)
      }
    } else if (option === UserMenuOption.Migrate) {
      handleMigrate(row)
    } else if (option === UserMenuOption.Access) {
      const user_id = String(row.user_id)
      const user = users.find((u: User) => u.id === user_id)
      if (user) {
        if (user.advisorAccess) {
          navigate(`${RoutesConfig.calc.pathname}/dashboard?client=${row.user_id}`)
        } else {
          notify.show("info", "This user does not have advisor access enabled.")
        }
      }
    }
  }

  const handleSaveUser = async (user: User) => {
    const index = users.findIndex((u: User) => u.id === user.id)
    if (index >= 0) {
      const newUsers = [...users]
      newUsers.splice(index, 1, user)
      setUsers(newUsers)
    }
  }

  const columns : GridColDef[] = [
    {field: 'user_id', headerName: 'User ID', width: 100},
    {field: 'user_lname', headerName: 'Last Name', width: 150},
    {field: 'user_fname', headerName: 'First Name', width: 180,
      valueFormatter: (value) => htmlDecode(value)},
    {field: 'user_email', headerName: 'Email', width: 250, resizable: true},
    {field: 'account_status', headerName: 'Status', width: 125,
      valueFormatter: (value) => formatAccountStatus(value)},
    {field: 'user_last_access', headerName: 'Last Login', width: 100,
      valueFormatter: (value) => formatDate(value)},
    {field: 'is_admin', headerName: 'Admin', width: 75, align: "center",
      valueFormatter: (value) => formatBoolean(value)},
    {field: 'is_advisor', headerName: 'Advisor', width: 75, align: "center",
      valueFormatter: (value) => formatBoolean(value)},
    {field: 'migratedOn', headerName: 'Migrate', width: 75, align: "center",
      renderCell: (params: GridRenderCellParams<any, string | unknown>) => (
        params.value ?
          <MenuButton
            icon={<ExpandCircleDownOutlinedIcon/>}
            options={getMenuOptions(params.row)}
            getOptionLabel={(option: any) => String(option)}
            onChange={async (option: UserMenuOption): Promise<void> => {
              handleMenuOption(option, params.row)
            }}
          /> :
          <IconButton size="small" sx={{width:16, height:16}}
                      onClick={() => handleMigrate(params.row)}
          >
            <CloudDownloadIcon/>
        </IconButton>
      )
    }

  ]

  const getRowId = (row: any) => {
    return row.user_id;
  }

  const QuickSearchToolbar = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput) => {
            setSearchInput(searchInput)
            return searchInput.split(" ")
              .map((value) => value.trim())
              .filter((value) => value !== '')
          }}
          debounceMs={1000}
        />
      </Box>
    );
  }

  return (
    <Box sx={{
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      maxWidth: "calc(100vw - 330px)"
    }}>
      <Box sx={{display:"flex", mt:1, pl:0, pb:0, mb:1}}>
        <Typography variant="h2" color="primary">Migrate Users</Typography>
      </Box>
      <Box sx={{height:"calc(100vh - 150px)", maxHeight:"calc(100vh - 150px)"}}>
        <DataGrid
          rows={legacyUsers}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          loading={isLoadingLegacy}
          getRowId={getRowId}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: 'user_lname', sort: 'asc' }, { field: 'user_fname', sort: 'asc' }],
            },
          }}
          slots={{ toolbar: QuickSearchToolbar }}
          sx={{overflowX:"scroll"}}
          // slotProps={{
          //   toolbar: {
          //     showQuickFilter: true,
          //   },
          // }}
        />
      </Box>
      {showMigrateUserDialog && migrateUser &&
        <MigrateUserDialog open={showMigrateUserDialog}
                           user={migrateUser}
                           onClose={() => {
                             setShowMigrateUserDialog(false)
                             setMigrateUser(undefined)
                           }}
                           onMigrate={(user: User) => {
                             updateUser(user)
                             setShowMigrateUserDialog(false)
                             setMigrateUser(undefined)
                           }}
        />
      }
      {editUser &&
        <UserEditDialog user={editUser}
                        open={editUser !== undefined}
                        onSave={(update: User) => {
                          handleSaveUser(update)
                          setEditUser(undefined)
                        }}
                        onClose={() => {
                          setEditUser(undefined)
                        }}/>
      }

    </Box>
  )
}

export default MigrateUsersView