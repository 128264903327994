import {Box, Button, Grid, Paper, Typography, useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import {globalColors} from "../../styles/globalStyles";
import SubscriptionEditDialog from "../../pages/settings/SubscriptionEditDialog";
import User from "../../model/User";
import * as React from "react";
import {useResources} from "../../stores/ResourceProvider";
import TaxesPreview from "../../images/TaxesPreview.png"
import CommunityPreview from "../../images/CommunityPreview.png"
import OptimizerPreview from "../../images/OptimizerPreview.png"
import AIPreview from "../../images/AIPreview.png"

export enum PremiumFeature {
  Community = "Community",
  TaxEstimator= "TaxEstimator",
  Optimizer = "Optimizer",
  AIAssistant = "AIAssistant"
}

const PremiumPreview = ({
  feature
}: {
  feature: PremiumFeature
}) => {
  const [preview, setPreview] = useState<PremiumFeature | undefined>()
  const [user, setUser] = useState<User | undefined>()
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState<boolean>(false)

  const theme = useTheme()
  const styles = {
    previewImg: {
      display:"flex",
      flexGrow: 1,
      justifyContent: "space-around",
      alignItems:"flex-start",
      width: "100%",
      margin: "auto",
      paddingTop: 4
    }
  }

  const { userStore } = useResources()

  useEffect(() => {
    setPreview(feature)
    setUser(userStore.user)
  }, [feature, userStore.user])

  const PremiumButton = () => {
    const style = {
      bgcolor:globalColors.premium,
      color:theme.palette.primary.contrastText,
      fontWeight:600,
      fontSize:18,
      "&:hover": {
        color: globalColors.iconContrast,
        backgroundColor: globalColors.primary
      },
    }

    return (
      <Button type="button" size="medium"
              sx={style}
              onClick={(event: any) => setShowSubscriptionDialog(true)}
      >
        Unlock My Premium Features
      </Button>
    )
  }

  const PremiumLink = ({title, view} : {title: string, view: PremiumFeature}) => {
    return (
      <Typography variant="body2" component="span" sx={{fontWeight:600, textDecoration:"underline", cursor:"pointer"}}
                  onClick={() => setPreview(view)}>
        {title}
      </Typography>
    )
  }

  const PremiumLinks = ({exclude} : {exclude: PremiumFeature}) => {
    const style = {fontSize: 16, lineHeight: 1.3, marginBottom: "4px"}
    return (
      <ul>
        <li style={style}><PremiumLink view={PremiumFeature.Optimizer} title="Retirement Optimizer"/> - Explore options to optimize your retirement finances</li>
        <li style={style}><PremiumLink view={PremiumFeature.TaxEstimator} title="Tax Estimator"/> - Estimate your Federal 1040 tax</li>
        <li style={style}><PremiumLink view={PremiumFeature.AIAssistant} title="Retirement Intelligence"/> - Meet Finn, your AI Retirement Assistant</li>
        <li style={style}><PremiumLink view={PremiumFeature.Community} title="RBC Community"/> - Exclusive access to valuable posts, videos and content</li>
      </ul>
    )
  }

  const CommunityView = () => {
    return (
      <Grid container spacing={1} direction="row" sx={{}}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h4" color={globalColors.premium}>RBC PREMIUM FEATURE</Typography>
          <Typography variant="h2" sx={{mb:2}}>
            RBC Community
          </Typography>
          <Typography variant="body2">
            Over 3,000 people have signed up for the Retirement Budget Calculator. When you become a premium subscriber we will be excited to welcome you to our private online community. Whether you're getting ready to retire, recently retired or you have been retired for years you have reached the right place.
          </Typography>
          <Box display="flex" justifyContent="space-around" p={2}>
            <PremiumButton/>
          </Box>
          <Typography variant="h4" color={globalColors.premium} gutterBottom>
            ALL PREMIUM FEATURES
          </Typography>
          <PremiumLinks exclude={PremiumFeature.Community}/>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box sx={styles.previewImg}>
            <img src={CommunityPreview}></img>
          </Box>
        </Grid>
      </Grid>
    )
  }

  const TaxesView = () => {
    return (
      <Grid container spacing={1} direction="row">
        <Grid item xs={12} sm={4}>
          <Typography variant="h4" color={globalColors.premium}>RBC PREMIUM FEATURE</Typography>
          <Typography variant="h2" sx={{mb:2}}>
            Tax Estimator
          </Typography>
          <Typography variant="body2">
            As a premium subscriber to the Retirement Budget Calculator, you will have access to the tax estimator. Utilizing the data you provide, our tax estimator will help estimate your federal 1040 income tax liability. Every year, the tax estimator is refreshed to reflect current and up-to-date federal income tax regulations. Not only does it provide an equation for figuring out how much of your social security is taxable, but you can also pick whichever deduction route works best for you - either itemized or standard deductions!
          </Typography>
          <Box display="flex" justifyContent="space-around" p={2}>
            <PremiumButton/>
          </Box>
          <Typography variant="h4" color={globalColors.premium} gutterBottom>
            ALL PREMIUM FEATURES
          </Typography>
          <PremiumLinks exclude={PremiumFeature.TaxEstimator}/>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box sx={styles.previewImg}>
            <img src={TaxesPreview}></img>
          </Box>
        </Grid>
      </Grid>
    )
  }

  const OptimizerView = () => {
    return (
      <Grid container spacing={1} direction="row">
        <Grid item xs={12} sm={4}>
          <Typography variant="h4" color={globalColors.premium}>RBC PREMIUM FEATURE</Typography>
          <Typography variant="h2" sx={{mb:2}}>
            Retirement Optimizer
          </Typography>
          <Typography variant="body2">
            This exclusive feature allows you to compare different retirement strategies such as retirement and social security start dates,
            asset withdrawal order, asset conversions, growth models and inflation rates to optimize your retirement finances.
            It includes future projections for Liquid Assets, Net Worth, Budget, Withdrawals and Bucket Strategy.
          </Typography>
          <Box display="flex" justifyContent="space-around" p={2}>
            <PremiumButton/>
          </Box>
          <Typography variant="h4" color={globalColors.premium} gutterBottom>
            ALL PREMIUM FEATURES
          </Typography>
          <PremiumLinks exclude={PremiumFeature.Optimizer}/>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box sx={styles.previewImg}>
            <img src={OptimizerPreview}></img>
          </Box>
        </Grid>
      </Grid>
    )
  }
  
  const AIAssistantView = () => {
    return (
      <Grid container spacing={1} direction="row">
        <Grid item xs={12} sm={4}>
          <Typography variant="h4" color={globalColors.premium}>RBC PREMIUM FEATURE</Typography>
          <Typography variant="h2" sx={{mb:2}}>
            Finn - AI Assistant
          </Typography>
          <Typography variant="body2">
            "Finn" is our exclusive Retirement Intelligence Assistant available 24/7 to answer many common retirement financial questions
            as well as questions about using the Retirement Budget Calculator.
          </Typography>
          <Box display="flex" justifyContent="space-around" p={2}>
            <PremiumButton/>
          </Box>
          <Typography variant="h4" color={globalColors.premium} gutterBottom>
            ALL PREMIUM FEATURES
          </Typography>
          <PremiumLinks exclude={PremiumFeature.AIAssistant}/>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box sx={styles.previewImg}>
            <img src={AIPreview}></img>
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <Paper
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        margin:"auto",
        p:2,
        border: "1px solid silver",
        maxWidth: feature === PremiumFeature.Community ? "80%" : "100%",
        marginTop: 2
      }}
      elevation={0}
    >
      { preview === PremiumFeature.Community && <CommunityView/> }
      { preview === PremiumFeature.TaxEstimator && <TaxesView/> }
      { preview === PremiumFeature.Optimizer && <OptimizerView/> }
      { preview === PremiumFeature.AIAssistant && <AIAssistantView/>}

      {showSubscriptionDialog && user &&
        <SubscriptionEditDialog user={user}
                                open={showSubscriptionDialog}
                                onSave={(update: User) => {
                                  setUser(update)
                                  setShowSubscriptionDialog(false)
                                }}
                                onClose={() => {
                                  setShowSubscriptionDialog(false)
                                }}/>
      }
    </Paper>
  )
}

export default PremiumPreview