import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  Box,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import Model from "../../model/Model";
import {useEffect, useState} from "react";
import {
  dateToLocalFormat, getISODateFromDate,
  isISODate,
  isoToLocalDate,
} from "../../stores/StoreUtilities";
import Milestone from "../../model/Milestone";
import DateFieldValidator from "./DateFieldValidator";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventIcon from '@mui/icons-material/Event';
import {UseDateFieldProps} from "@mui/x-date-pickers/DateField";
import {BaseSingleInputFieldProps, DateValidationError, FieldSection} from "@mui/x-date-pickers/models";
import {DatePicker, DatePickerProps} from "@mui/x-date-pickers";

/*
  Based on: https://mui.com/material-ui/react-menu/#basic-menu 
*/
const MilestoneDateFieldValidator = ({
  name,
  label,
  model,
  milestones,
  value,
  minDate,
  maxDate,
  width,
  required,
  disabled,
  helperText,
  sx,
  onChange
}: {
  name: string
  label: string
  model?: Model
  milestones?: Milestone[]
  minDate?: Date
  maxDate?: Date
  width?: number | string
  value?: string
  required?: boolean
  disabled?: boolean
  helperText?: string
  sx?: any
  onChange(value?: string): void
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isDate, setIsDate] = useState<boolean>(false)
  const [date, setDate] = useState<Date | undefined>()
  const [text, setText] = useState<string>("")
  const [selected, setSelected] = useState<string | undefined>("")
  const [sorted, setSorted] = useState<Milestone[]>([])
  const [options, setOptions] = useState<Milestone[]>([])
  const [helpText, setHelpText] = useState<string>("")
  const open = Boolean(anchorEl);

  const format = "MM-dd-yyyy"

  useEffect(() => {
    let generated: Milestone[] = []

    if (!value || isISODate(value)) {
      setIsDate(true)
      const date = value ? isoToLocalDate(value) : undefined
      setDate(date)
      setSelected(value)
      const formatted = date ? dateToLocalFormat(date, format) : format
      setText(formatted)
      setHelpText(helperText ?? "")
      let found
      if (milestones) {
        found = milestones.find((m: Milestone) => m.id === value)
      }
      if (found) {
        setHelpText(found.label)
      } else if (date) {
        generated.push(new Milestone({
          id: "",
          label: "None",
          date: undefined
        }))
      }
      else {
        setHelpText(helperText ?? "")
      }
    } else {
      let found
      if (model) {
        found = model.findMilestone(value)
      } else if (milestones) {
        found = milestones.find((m: Milestone) => m.id === value)
      }
      if (found) {
        setIsDate(false)
        setSelected(found.id)
        setText(found.label)
        setDate(found.date)
        setHelpText(helperText ?? found.label)
      } else {
        setIsDate(true)
        setSelected("")
        setText("")
        setDate(undefined)
        setHelpText(helperText ?? "")
      }
      generated.push(new Milestone({
        id: "",
        label: "None",
        date: undefined
      }))
    }

    if (sorted.length === 0) {
      // Set initial milestones
      const array = model ? Array.from(model.milestones.values()) : milestones ?? []
      array.sort((a: Milestone, b: Milestone) => a.label.localeCompare(b.label))
      setSorted(array)
      setOptions([...generated, ...array])
    } else {
      setOptions([...generated, ...sorted])
    }
  }, [value, model, milestones, helperText])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation()
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (milestone: Milestone) => {
    handleClose()
    if (onChange) {
      onChange(milestone.id)
    }
  }

  interface ButtonFieldProps
    extends UseDateFieldProps<Date>,
      BaseSingleInputFieldProps<
        Date | null,
        Date,
        FieldSection,
        DateValidationError
      > {
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  }

  const ButtonField = (props: ButtonFieldProps) => {
    const {
      setOpen,
      label,
      id,
      disabled,
      InputProps: { ref } = {},
      inputProps: { 'aria-label': ariaLabel } = {},
    } = props;

    return (
      <IconButton
        id={id}
        disabled={disabled}
        ref={ref}
        aria-label={ariaLabel}
        sx={{paddingLeft:0, paddingRight:0}}
        onClick={() => setOpen?.((prev) => !prev)}
      >
        <EventIcon/>
      </IconButton>
    );
  }

  const ButtonDatePicker = (
    props: Omit<DatePickerProps<Date>, 'open' | 'onOpen' | 'onClose'>,
  ) => {
    const [open, setOpen] = React.useState(false);

    return (
      <DatePicker
        slots={{ field: ButtonField,
          ...props.slots }}
        slotProps={{ field: { setOpen } as any,
          actionBar: { actions: ["clear", "today", "accept"] }
        }}
        {...props}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      />
    );
  }

  const onDateChange = (newValue: any) => {
    if (newValue && !isNaN(newValue.getTime()) && newValue.getFullYear() > 1900 && onChange) {
      console.log(`onChange ${name}: ${newValue.toISOString()}`)
      setDate(newValue)
      onChange(getISODateFromDate(newValue))
    } else {
      setDate(undefined)
      setSelected(undefined)
      setHelpText("")
      onChange(undefined)
    }
  }

  return (
    <Box display="flex" width={width} sx={sx}>
      <DateFieldValidator
        name={name}
        label={label}
        value={date}
        format={format}
        variant="standard"
        margin="dense"
        required={required}
        sx={{paddingTop:0, marginTop:"3px"}}
        minDate={minDate}
        maxDate={maxDate}
        helperText={helpText}
        disabled={disabled}
        onChange={onDateChange}
        onBlur={(event: any) => {
          if (!date && onChange) {
            onChange(undefined)
          }
        }}
        slotProps={{
          textField: {
            InputProps: {
              placeholder:"MM-DD-YYYY",
              endAdornment:  !disabled && <InputAdornment position="end">
                <ButtonDatePicker
                  value={date}
                  onChange={onDateChange}
                />
                <IconButton
                  aria-label="toggle menu visibility"
                  onClick={handleClick}
                  edge="end"
                >
                  {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </IconButton>
              </InputAdornment>
            }
          }
        }}
      />
      <Menu
        id="milestone-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={(event: any) => {
          event.stopPropagation()
          handleClose()
        }}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            maxHeight: 'calc(50vh)'
          },
        }}
      >
        {options.map((milestone, index) => {
          return (
            <MenuItem
              key={milestone.id}
              onClick={(event: any) => {
                event.stopPropagation()
                handleMenuItemClick(milestone)
              }}
              selected={milestone.id === selected}
            >
              <Typography variant="body2">{milestone.label}</Typography>
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  );
}

export default MilestoneDateFieldValidator 
