import {Box, DialogContentText, FormControlLabel, Radio, Switch, Typography, useTheme} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import TextFieldValidator from "../../../components/form/TextFieldValidator";
import {CreateAssetConversionInput, UpdateAssetConversionInput, UpdateAssetInput} from "../../../API";
import {useResources} from "../../../stores/ResourceProvider";
import Model from "../../../model/Model";
import FormGroupSpacer from "../../../components/form/FormGroupSpacer";
import Asset, {AssetCategory, AssetType, AssetTypeDef, AssetTypeDefs, getAssetTypeDef} from "../../../model/Asset";
import ModelEditDialog from "../../../components/model/ModelEditDialog";
import AssetConversion, {AssetConversionType} from "../../../model/AssetConversion";
import {
  getISODateToday,
  humanizeString,
  isoToLocalDateString,
  moneyToNumberFormat,
  numberToMoneyFormat, numberToPercentFormat
} from "../../../stores/StoreUtilities";
import {getIncomeTypeDef, IncomeType} from "../../../model/Income";
import {grey} from "@mui/material/colors";
import Visible from "../../../components/Visible";
import LockIcon from "@mui/icons-material/Lock";

const AssetConversionEditDialog = ({
  open,
  assetConversion,
  model,
  onClose,
  onSave,
  onDelete,
  onCommit
}: {
  open? : boolean
  assetConversion: AssetConversion
  model: Model
  onClose?(): void
  onSave?(update: AssetConversion): void
  onDelete?(item: AssetConversion): void
  onCommit?(results: Array<Asset | AssetConversion | undefined>): void
}) => {
  const [assetConversionModel, setAssetConversionModel] = useState<AssetConversion>(assetConversion)
  const [description, setDescription] = useState<string>("")
  const [conversionType, setConversionType] = useState<AssetConversionType>(AssetConversionType.OtherConversion)
  const [srcAsset, setSrcAsset] = useState<Asset | undefined>()
  const [dstAsset, setDstAsset] = useState<Asset | undefined>()
  const [srcAssets, setSrcAssets] = useState<Asset[]>([])
  const [dstAssets, setDstAssets] = useState<Asset[]>([])
  const [year, setYear] = useState<number | undefined>()
  const [completedAt, setCompletedAt] = useState<string | undefined>()
  const [amount, setAmount] = useState<string | undefined>()
  const [isAmount, setIsAmount] = useState<boolean>(true)
  const [percent, setPercent] = useState<string | undefined>()
  const [incomeType, setIncomeType] = useState<IncomeType | undefined>()
  const [nonTaxableAmount, setNonTaxableAmount] = useState<string | undefined>()
  const [commit, setCommit] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isLocked, setIsLocked] = useState<boolean>(false)

  const { modelStore, notify, confirm } = useResources()
  const theme = useTheme()

  const styles = {
    halfWidth: {
      width: 100
    },
    halfWidthAutoComplete: {
      width: 270,
      paddingTop: "8px"
    },
    radio: {
      paddingTop: 0,
      paddingLeft: 0
    }
  }

  const incomeTypes: IncomeType[] = [
    IncomeType.None,
    IncomeType.WagesSalariesTips,
    IncomeType.LongTermCapitalGainsOrLoss,
    IncomeType.ShortTermCapitalGainsOrLoss,
    IncomeType.TaxableIRADistribution,
    IncomeType.OtherIncome
  ]

  useEffect(() => {
    setAssetConversionModel(assetConversion)
    setDescription(assetConversion.description)
    setConversionType(assetConversion.conversionType)
    if (assetConversion.percent > 0) {
      setIsAmount(false)
      setPercent(String(assetConversion.percent * 100))
      setAmount(numberToMoneyFormat(0, 0))
    } else {
      setIsAmount(true)
      setAmount(numberToMoneyFormat(assetConversion.amount, 0))
      setPercent("100")
    }
    const src = model.getAssetById(assetConversion.srcAssetId)
    setSrcAsset(src)
    const dst = model.getAssetById(assetConversion.dstAssetId)
    setDstAsset(dst)
    setYear(assetConversion.year ?? undefined)
    setCompletedAt(assetConversion.completedAt ? isoToLocalDateString(assetConversion.completedAt) : undefined)
    // TODO: Update with new field
    setNonTaxableAmount(numberToMoneyFormat(assetConversion.nonTaxableAmount, 0))
    setIncomeType(assetConversion.incomeType)
    setIsOpen(open === true)
    setIsLocked(model.hasLock(assetConversion.id, "*"))
  }, [assetConversion, model, open])

  useEffect(() => {
    if (conversionType === AssetConversionType.RothConversion) {
      const assetTypes = AssetTypeDefs.filter((at: AssetTypeDef) => at.convertableTo.includes(AssetType.RothIRA)).map((at: AssetTypeDef) => at.assetType)
      const rothTypes: AssetType[] = [AssetType.RothIRA, AssetType.RothTSP, AssetType.Roth401k]

      setSrcAssets(model.assets.filter((a: Asset) => assetTypes.includes(a.assetType)))
      const dst = model.assets.filter((a: Asset) => rothTypes.includes(a.assetType))
      setDstAssets(model.assets.filter((a: Asset) => rothTypes.includes(a.assetType)))
      if (srcAsset && !srcAsset.assetTypeDef.convertableTo.includes(AssetType.RothIRA)) {
        setSrcAsset(undefined)
      }
      if (dstAsset && !rothTypes.includes(dstAsset.assetType)) {
        setDstAsset(undefined)
      }
    } else {
      setSrcAssets(model.assets.filter((a: Asset) => a.assetCategory === AssetCategory.LiquidInvestableAssets || a.assetCategory === AssetCategory.RealEstateAndProperty))
      setDstAssets(model.assets.filter((a: Asset) => a.assetCategory === AssetCategory.LiquidInvestableAssets))
    }
  }, [conversionType])

  const handleClose = async (event: any) => {
    if (onClose) {
      onClose()
    }
  }

  const handleSave = async (event: any) => {
    try {
      const pct = percent ? parseInt(percent) / 100 : 0
      let amt = moneyToNumberFormat(amount, 0) ?? 0
      const nonTaxableAmt = moneyToNumberFormat(nonTaxableAmount, 0) ?? 0
      if (commit) {
        const isoDate = getISODateToday()
        if (srcAsset && dstAsset) {
          if (isAmount && srcAsset.balance < amt) {
            notify.show("error", `Insufficient balance of ${numberToMoneyFormat(srcAsset.balance)} in ${srcAsset.description}`)
            return
          }
          if (isAmount && nonTaxableAmt > amt) {
            notify.show("error", "Tax Excludes amount cannot exceed Amount")
            return
          }
          const promises: Promise<Asset | AssetConversion | undefined>[] = []
          if (!isAmount) {
            amt = srcAsset.balance * pct
          }
          const srcUpdate: UpdateAssetInput = {
            id: srcAsset.id,
            accountId: srcAsset.accountId,
            balance: Math.max(srcAsset.balance - amt, 0),
            balanceDate: isoDate
          }
          if (srcAsset.assetCategory === AssetCategory.RealEstateAndProperty && srcUpdate.balance === 0) {
            // End the real estate asset if balance is zero
            srcUpdate.end = getISODateToday()
          }
          promises.push(modelStore.updateAsset(srcUpdate))

          const dstUpdate: UpdateAssetInput = {
            id: dstAsset.id,
            accountId: dstAsset?.accountId,
            balance: dstAsset.balance + amt,
            balanceDate: isoDate
          }
          promises.push(modelStore.updateAsset(dstUpdate))

          if (!assetConversionModel.createdAt) {
            const input: CreateAssetConversionInput = {
              id: assetConversionModel.id,
              accountId: assetConversionModel.accountId,
              userId: assetConversionModel.userId,
              modelId: assetConversionModel.modelId,
              description: description,
              conversionType: conversionType,
              srcAssetId: srcAsset!.id,
              dstAssetId: dstAsset!.id,
              year: year ?? 0,
              amount: isAmount ? amt : 0,
              percent: !isAmount ? pct : 0,
              sortOrder: assetConversionModel.sortOrder,
              completedAt: isoDate,
              nonTaxableAmount: nonTaxableAmt,
              incomeType: incomeType
            }

            promises.push(modelStore.createAssetConversion(input))
          } else {
            const input: UpdateAssetConversionInput = {
              id: assetConversionModel.id,
              accountId: assetConversionModel.accountId,
              userId: assetConversionModel.userId,
              modelId: assetConversionModel.modelId,
              description: description,
              conversionType: conversionType,
              srcAssetId: srcAsset!.id,
              dstAssetId: dstAsset!.id,
              year: new Date().getFullYear(),
              amount: isAmount ? amt : 0,
              percent: !isAmount ? pct : 0,
              completedAt: isoDate,
              nonTaxableAmount: nonTaxableAmt,
              incomeType: incomeType
            }
            promises.push(modelStore.updateAssetConversion(input))
          }

          const results = await Promise.all(promises)

          notify.show("success", "Conversion completed")
          if (onCommit) {
            onCommit(results)
          }
        }
      } else {
        let updated: AssetConversion | undefined

        if (!assetConversionModel.createdAt) {
          const input: CreateAssetConversionInput = {
            id: assetConversionModel.id,
            accountId: assetConversionModel.accountId,
            userId: assetConversionModel.userId,
            modelId: assetConversionModel.modelId,
            description: description,
            conversionType: conversionType,
            srcAssetId: srcAsset!.id,
            dstAssetId: dstAsset!.id,
            year: year ?? 0,
            amount: isAmount ? amt : 0,
            percent: !isAmount ? pct : 0,
            sortOrder: assetConversionModel.sortOrder,
            nonTaxableAmount: moneyToNumberFormat(nonTaxableAmount, 0) ?? 0,
            incomeType: incomeType
          }

          updated = await modelStore.createAssetConversion(input)
        } else {
          const input: UpdateAssetConversionInput = {
            id: assetConversionModel.id,
            accountId: assetConversionModel.accountId,
            userId: assetConversionModel.userId,
            modelId: assetConversionModel.modelId,
            description: description,
            conversionType: conversionType,
            srcAssetId: srcAsset!.id,
            dstAssetId: dstAsset!.id,
            year: year ?? 0,
            amount: isAmount ? amt : 0,
            percent: !isAmount ? pct : 0,
            nonTaxableAmount: moneyToNumberFormat(nonTaxableAmount, 0) ?? 0,
            incomeType: incomeType
          }

          updated = await modelStore.updateAssetConversion(input)
        }

        if (onSave && updated) {
          onSave(updated)
        } else if (onClose) {
          onClose()
        }
      }
    } catch (err: any) {
      notify.show('error', err.message)
    }
  }

  const handleDelete = async () => {
    if (onDelete) {
      if (assetConversionModel.createdAt) {
        const message = (assetConversion.completedAt && assetConversionModel.year === (new Date).getFullYear()) ?
          `This record of the conversion is required to calculate this year's taxes correctly. It should only be deleted if you are trying to reverse the effect of this conversion. It will be removed automatically once this year has passed.` :
          `Are you sure you want to delete this conversion?`
        confirm.show('Confirm Delete', message,
          ['Delete', 'Cancel'],
          async () => {
            try {
              const deleted = await modelStore.deleteAssetConversion(assetConversionModel.id)
              if (deleted) {
                onDelete!(deleted)
              }
              return true
            }
            catch (err: any) {
              notify.show('error', err.message)
              return false
            }
          })
       } else {
        onDelete(assetConversionModel)
      }
    }
  }

  const minYear: number = (new Date()).getFullYear()
  const disabled = completedAt !== undefined || isLocked

  const getDestinations = (srcAsset?: Asset): Asset[] => {
    let assetTypes: AssetType[] = []
    let assets: Asset[] = []

    if (srcAsset) {
      if (srcAsset.assetCategory === AssetCategory.LiquidInvestableAssets) {
        assetTypes = srcAsset.assetTypeDef.convertableTo
      } else if (srcAsset.assetCategory === AssetCategory.RealEstateAndProperty) {
        assetTypes = [AssetType.Brokerage, AssetType.Checking, AssetType.Savings, AssetType.MoneyMarket]
      }

      assets = dstAssets.filter((a: Asset) => a.id !== srcAsset.id && assetTypes.findIndex((at: AssetType) => at === a.assetType) >= 0)
      Model.sortAssets(assets)
    } else {
      assets = dstAssets
    }

    return assets
  }

  const renderAmountLabel = () => {
    return (
      <Box display="inline-block">
        <Radio checked={isAmount} sx={styles.radio}
               onChange={(event: any) => {
                 setIsAmount(event.target.checked)
               }}/>
        <span>Amount&nbsp;&nbsp;</span>
        <Radio checked={!isAmount} sx={styles.radio} onChange={(event: any) => setIsAmount(!event.target.checked)}/>
        <span>Percent</span>
      </Box>
    )
  }

  return (
    <ModelEditDialog title="Edit Asset Conversion" open={isOpen}
                     onCancel={handleClose}
                     onSave={!isLocked ? handleSave : undefined}
                     onDelete={!isLocked ? handleDelete : undefined}
                     confirmDelete={false}
    >
      <DialogContentText>
        Schedule future IRA to Roth conversions, 401k rollovers,
        property sales or other conversions between assets.
      </DialogContentText>
      {isLocked &&
        <Box display="flex" justifyContent="flex-start" alignItems="center" mt={1}>
          <LockIcon sx={{color: theme.palette.primary.main, mr:1}}/>
          <Typography variant="body2" color={theme.palette.primary.main}>
            Locked by Conversion Strategy
          </Typography>
        </Box>
      }
        <TextFieldValidator
          margin="normal"
          name="description"
          label="Title"
          type="text"
          fullWidth
          variant="standard"
          required
          disabled={isLocked}
          validators={{ required: true }}
          value={description}
          onChange={(event: any) => setDescription(event.target.value)}
        />
        <TextFieldValidator
          type="text"
          validators={{ required: true }}
          required
          name="conversionType"
          label="Conversion Type"
          variant="standard"
          autocompleteOptions={{
            freeSolo: false,
            value: conversionType,
            disabled: disabled || isLocked,
            options: Object.values(AssetConversionType),
            getOptionLabel: (option: string) => option ? humanizeString(option) : null,
            onChange: (event: any, value: AssetConversionType, reason: any) => {
              setConversionType(value)
            }
          }}
        />
        <TextFieldValidator
          type="text"
          validators={{ required: true }}
          required
          name="asset"
          label="Source Asset"
          variant="standard"
          autocompleteOptions={{
            freeSolo: false,
            options: srcAssets,
            getOptionLabel: (option: Asset) => option ? option.description : "",
            isOptionEqualToValue: (option: Asset, value: Asset) => option.id === value.id,
            value: srcAsset || null,
            disabled: disabled || isLocked,
            onChange: (event: any, value: Asset, reason: any) => {
              setSrcAsset(value)
              let dst = dstAsset
              if (value) {
                setAmount(numberToMoneyFormat(value.balance, 0))
                if (value.assetCategory === AssetCategory.LiquidInvestableAssets) {
                  // Check if dest asset is convertable to
                  if (dst && !value.assetTypeDef.convertableTo.includes(dst.assetType)) {
                    setDstAsset(undefined)
                    dst = undefined
                  }
                  if (value.assetTypeDef.isTaxableIRA && (!dst || !dst.assetTypeDef.isTaxableIRA)) {
                    setNonTaxableAmount(numberToMoneyFormat(0, 0))
                    setIncomeType(IncomeType.TaxableIRADistribution)
                  } else if (value.assetType === AssetType.Brokerage) {
                    setIncomeType(IncomeType.ShortTermCapitalGainsOrLoss)
                  } else {
                    setNonTaxableAmount(numberToMoneyFormat(0, 0))
                    setIncomeType(IncomeType.None)
                  }
                } else if (value.assetCategory === AssetCategory.RealEstateAndProperty) {
                  setNonTaxableAmount(numberToMoneyFormat(0, 0))
                  setIncomeType(IncomeType.LongTermCapitalGainsOrLoss)
                }
              }
            }
          }}
        />
        <TextFieldValidator
          type="text"
          validators={{ required: true }}
          required
          name="asset"
          label="Destination Asset"
          variant="standard"
          autocompleteOptions={{
            freeSolo: false,
            options: getDestinations(srcAsset),
            getOptionLabel: (option: Asset) => option ? option.description : "",
            isOptionEqualToValue: (option: Asset, value: Asset) => option.id === value.id,
            value: dstAsset || null,
            disabled: disabled || isLocked,
            onChange: (event: any, value: Asset, reason: any) => {
              setDstAsset(value)
              if (srcAsset && value) {
                if (srcAsset.assetCategory === AssetCategory.LiquidInvestableAssets) {
                  if (srcAsset.assetTypeDef.isTaxableIRA && (!value.assetTypeDef.isTaxableIRA)) {
                    setNonTaxableAmount(numberToMoneyFormat(0, 0))
                    setIncomeType(IncomeType.TaxableIRADistribution)
                  } else if (srcAsset.assetType === AssetType.Brokerage) {
                    setIncomeType(IncomeType.ShortTermCapitalGainsOrLoss)
                  } else {
                    setNonTaxableAmount(numberToMoneyFormat(0, 0))
                    setIncomeType(IncomeType.None)
                  }
                }
              }
            }
          }}
        />
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Visible cond={isAmount}>
            <TextFieldValidator
              type="string"
              validators={{ required: isAmount, isMoney: true, minMoney: 0 }}
              name="amount"
              variant="standard"
              margin="dense"
              fullWidth
              label={renderAmountLabel()}
              value={amount}
              disabled={disabled || isLocked}
              onChange={(event: any) => {
                const value = event.target.value === "" ? "$0" : event.target.value
                setAmount(value)
                if (srcAsset && srcAsset.assetTypeDef.isTaxableIRA)
                  setNonTaxableAmount("$0")
              }}
            />
          </Visible>
          <Visible cond={!isAmount}>
            <TextFieldValidator
              type="string"
              validators={{ required: !isAmount, isInt: true, minValue: 0, maxValue: 100 }}
              name="percent"
              variant="standard"
              margin="dense"
              fullWidth
              label={renderAmountLabel()}
              value={percent}
              disabled={disabled || isLocked}
              onChange={(event: any) => {
                const value = event.target.value === "" ? "0" : event.target.value
                setPercent(value)
                // if (srcAsset && srcAsset.assetTypeDef.isTaxableIRA)
                //   setTaxableAmount(event.target.value)
              }}
            />
          </Visible>
          <FormGroupSpacer/>
          <TextFieldValidator
            type="number"
            validators={{ minValue: minYear, isInt: true }}
            name="year"
            variant="standard"
            margin="dense"
            fullWidth
            label="Year"
            value={year}
            disabled={disabled || isLocked}
            onChange={(event: any) => setYear(event.target.value)}
          />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="stretch">
          <TextFieldValidator
            type="text"
            validators={{ required: true }}
            required
            name="incomeType"
            label="Taxable Income Type"
            variant="standard"
            styleProp={styles.halfWidthAutoComplete}
            autocompleteOptions={{
              freeSolo: false,
              options: incomeTypes,
              getOptionLabel: (option: number) => {
                const incomeTypeDef = getIncomeTypeDef(option)
                return(incomeTypeDef.label)
              },
              value: incomeType,
              disabled: disabled || isLocked,
              onChange: (event: any, value: number, reason: any) => {
                setIncomeType(value)
                setNonTaxableAmount("0")
              }
            }}
          />
          <FormGroupSpacer/>
          {incomeType !== IncomeType.None &&
            <TextFieldValidator
              type="string"
              validators={{ required: true, isMoney: true }}
              name="nonTaxableAmount"
              variant="standard"
              margin="dense"
              label="Tax Excludes"
              value={nonTaxableAmount}
              styleProp={styles.halfWidth}
              disabled={disabled || isLocked}
              onChange={(event: any) => setNonTaxableAmount(event.target.value)}
            />
          }
        </Box>
        {!completedAt &&
          <FormControlLabel control={
            <Switch checked={commit}
                    disabled={disabled || Number(year) >= minYear}
                    onChange={(event: any) => {
                      setCommit(event.target.checked)
                    }}
            />}
            label={Number(year) >= minYear ? `Can complete in ${year} or later.` : `Complete the asset conversion on Save.`}
            sx={{marginTop:1}}
          />
        }
        {completedAt &&
          <FormControlLabel control={
            <Switch checked={commit} disabled
            />}
            label={`Completed on ${completedAt}`}
            sx={{marginTop:1}}
          />
        }
    </ModelEditDialog>
  )
}

export default AssetConversionEditDialog