import React, {useEffect, useState} from "react";
import {Paper, TableContainer, useTheme} from "@mui/material";
import {numberToMoneyFormat} from "../../../../stores/StoreUtilities";
import {FutureCalc} from "../../../../components/calculator/Calculator";
import {globalColors} from "../../../../styles/globalStyles";
import cloneDeep from "lodash.clonedeep";
import Chart from "react-apexcharts";
import TaxableIncomeTable from "./TaxablerIncomeTable";

const defaultOptions = {
  chart: {
    id: "taxable-income-estimate-chart",
    type: 'bar',
    stacked: true,
    height: 350,
    width: "100%",
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      }
    },
    toolbar: {
      show: false,
      tools: {
        download: false
      }
    }
  },
  title: {
    text: "",
    align: "center",
    margin: 0,
    floating: true,
    style: {
      fontSize: "16px",
      fontFamily: "Poppins,sans-serif",
      color: "rgb(31, 92, 167)"
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    onItemClick: {
      toggleDataSeries: false
    }
  },
  xaxis: {
    type: 'number',
    min: 2000,
    axisBorder: {
      show: true
    }
  },
  yaxis: {
    labels: {
      formatter: (val: number) => {
        return val / 1000 + 'K'
      }
    }
  },
  tooltip: {
    y: {
      formatter: function (value: number) {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  // colors: [globalColors.taxable, globalColors.taxDeferredRMD, globalColors.taxDeferredElective, globalColors.taxFree, globalColors.taxExpense],
  resonsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const TaxableIncomeEstimateChart = ({
  year,
  futureCalc,
  onChange,
  height,
  width,
  title
}: {
  year: number
  futureCalc: FutureCalc
  onChange?: (year: number) => any
  height?: string
  width?: string
  title?: string
}) => {
  const [startYear, setStartYear] = useState<number>(0)
  const [endYear, setEndYear] = useState<number>(0)
  const [calc, setCalc] = useState<FutureCalc | undefined>()
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<any>()
  const [currentYear, setCurrentYear] = useState<number>(0)

  const theme = useTheme()

  useEffect(() => {
    if (futureCalc.futureYears.length > 0) {
      const start = futureCalc.futureYears[0].year
      setStartYear(start)
      setEndYear(futureCalc.futureYears[futureCalc.futureYears.length-1].year)
      setCurrentYear(year)
      setCalc(futureCalc)
    }
  }, [year, futureCalc])

  useEffect(() => {
    if (startYear > 0 && calc && calc.futureYears.length > 0) {
      calculateData(startYear)
    }
  }, [currentYear, calc])

  const calculateData = (startYear: number) => {
    if (!calc || startYear <= 0 || endYear < startYear) {
      return
    }

    let wageData = []
    let interestData = []
    let dividendData = []
    let iraData = []
    let pensionAnnuityData = []
    let socialSecurityData = []
    let ltCapitalGainData = []
    let stCapitalGainData = []
    let otherIncomeData = []
    let estTaxData = []

    for (let index = 0; index < calc.futureYears.length; index++) {
      const year = startYear + index
      const fy = calc.futureYears[index]
      const taxInput = fy.taxInput
      const taxEst = fy.taxEstimate
      if (!taxInput || !taxEst) {
        continue
      }
      wageData.push({x: year, y: taxInput.income.wagesSalariesTips})
      interestData.push({x: year, y: taxInput.income.taxableInterest})
      dividendData.push({x: year, y: taxInput.income.ordinaryDividends})
      iraData.push({x: year, y: taxInput.income.taxableIraDistributions})
      pensionAnnuityData.push({x: year, y: taxInput.income.taxablePensionAndAnnuities})
      socialSecurityData.push({x: year, y: taxEst.taxableSocialSecurityBenefits})
      ltCapitalGainData.push({x: year, y: taxInput.income.qualifiedCapitalGains})
      stCapitalGainData.push({x: year, y: taxInput.income.unqualifiedCapitalGains})
      otherIncomeData.push({x: year, y: taxInput.income.otherIncome})
      estTaxData.push({x: year, y: -taxEst.taxLiability})
    }

    const series = [
      {
        name: "Wages, Salaries, Tips",
        group: "Taxable Income",
        data: wageData,
        color: globalColors.categorical[0]
      },
      {
        name: "Interest",
        group: "Taxable Income",
        data: interestData,
        color: globalColors.categorical[1]
      },
      {
        name: "Dividends",
        group: "Taxable Income",
        data: dividendData,
        color: globalColors.categorical[2]
      },
      {
        name: "IRA Distributions",
        group: "Taxable Income",
        data: iraData,
        color: globalColors.categorical[3]
      },
      {
        name: "Pensions & Annuities",
        group: "Taxable Income",
        data: pensionAnnuityData,
        color: globalColors.categorical[5]
      },
      {
        name: "Social Security",
        group: "Taxable Income",
        data: socialSecurityData,
        color: globalColors.categorical[6]
      },
      {
        name: "Long Term Capital Gains",
        group: "Taxable Income",
        data: ltCapitalGainData,
        color: globalColors.categorical[7]
      },
      {
        name: "Short Term Capital Gains",
        group: "Taxable Income",
        data: stCapitalGainData,
        color: globalColors.categorical[8]
      },
      {
        name: "Other Income",
        group: "Taxable Income",
        data: otherIncomeData,
        color: globalColors.categorical[9]
      },
      {
        name: "Estimated Tax",
        group: "Taxable Income",
        data: estTaxData,
        color: globalColors.categorical[4]
      },
    ]
    // Filter to only series that have non-zero data values
    const filtered = series.filter((s: any) => {
      return s.data.findIndex((d: any) => d.y !== 0) >= 0
    })
    setSeries(filtered)

    const newOptions = cloneDeep(defaultOptions)
    newOptions.title.text = title ?? ""
    newOptions.xaxis.min = startYear

    if (currentYear !== undefined) {
      const annotations = {
        xaxis: [
          {
            x: currentYear,
            borderColor: theme.palette.secondary.main, //'#00E396',
            label: {
              borderColor: theme.palette.secondary.main, //'#00E396',
              orientation: 'horizontal',
              text: currentYear.toString(),
              style: {
                fontSize: '14px',
                fontWeight: 600
              }
            }
          }
        ]
      }
      newOptions["annotations"] = annotations
      newOptions.chart.events["dataPointSelection"] = (event: any, chartContext: any, details: {dataPointIndex: number, seriesIndex: number}) => {
        if (details.dataPointIndex >= 0 && onChange) {
          onChange(details.dataPointIndex + startYear)
        }
      }
    }

    setOptions(newOptions)
  }

  const handleChange = (year: number) => {
    setCurrentYear(year)
    if (onChange) {
      onChange(year)
    }
  }

  if (options && series) {
    return (
      <React.Fragment>
        <TableContainer component={Paper} sx={{paddingTop:1}}>
          <Chart
            options={options}
            series={series}
            type="bar"
            height={height ?? "500"}
            width={width ?? "100%"}
          />
        </TableContainer>
        {series && series.length > 0 &&
          <TaxableIncomeTable futureCalc={futureCalc} series={series} year={currentYear}/>
        }
      </React.Fragment>
    )
  } else {
    return null
  }
}

export default TaxableIncomeEstimateChart