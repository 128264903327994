import React, { useCallback, useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {useResources} from "../../stores/ResourceProvider";
import RoutesConfig from "../../RoutesConfig";
import config from 'react-global-configuration';
import Tracking from "../../components/Tracking";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripeKey = process.env.REACT_APP_STRIPE_KEY ?? ""
const stripePromise = loadStripe(stripeKey);

const CheckoutForm = ({
  price,
  customer_email
} : {
  price: string
  customer_email: string
}) => {
  const {billingAPI} = useResources()

  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    Tracking.event({action: "Checkout Session Created"})
    const returnUrl = `${config.get("baseUrl")}${RoutesConfig.checkoutReturn.pathname}`
    return billingAPI.createCheckoutSession(price, returnUrl, customer_email)
      .then((data) => {
        return data.clientSecret
      });
  }, []);

  const options = {fetchClientSecret};

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

export default CheckoutForm