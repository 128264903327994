import * as React from 'react'
import Header from "./Header";
import NotifyBar from "../notify/NotifyBar";
import ConfirmDialog from "../confirm/ConfirmDialog";
import AlertDialog from "../alert/AlertDialog";
// import Footer from "./Footer";
import { Box } from '@mui/material';
import theme from '../../styles/theme';
import SwipeableEdgeDrawer from "../mui/SwipeableEdgeDrawer";
import ChatbotDrawer from "../chat/ChatbotDrawer";
import {useEffect, useState} from "react";
import TermsOfUseDialog from "./TermsOfUseDialog";
import PrivacyPolicyDialog from "./PrivacyPolicyDialog";
import {useResources} from "../../stores/ResourceProvider";
import Footer from "./Footer";

export interface IPageViewSettings {
  chatOpen: boolean
}

interface IPageProps {
  hideHeader?: boolean
  hideFooter?: boolean
  title?: string
  backgroundColor?: "default" | "paper"
  children: React.ReactNode
}

const Page = ({
  hideHeader,
  hideFooter,
  title,
  backgroundColor,
  children
}: IPageProps) => {
  const [viewStorageKey, setViewStorageKey] = useState<string>("")
  const [pageViewSettings, setPageViewSettings] = useState<IPageViewSettings | undefined>()
  const [chatOpen, setChatOpen] = useState<boolean>(false)

  const {userStore} = useResources()

  useEffect(() => {
    if (title) {
      document.title = `RBC - ${title}`
    } else {
      document.title = "RBC"
    }
  }, [title])

  useEffect(() => {
    if (userStore.isAuthenticated) {
      loadSettings()
    }
  }, [userStore.isAuthenticated])

  useEffect(() => {
    if (pageViewSettings) {
      saveSettings()
    }
  }, [chatOpen, viewStorageKey])


  const loadSettings = () => {
    let storageKey = viewStorageKey
    if (!storageKey) {
      storageKey = `${userStore.user?.id}.PageViewSettings`
      setViewStorageKey(storageKey)
    }
    const data = window.localStorage.getItem(storageKey)
    if (data && !userStore.isFree) {
      const settings: IPageViewSettings = JSON.parse(data)
      setPageViewSettings(settings)
      setChatOpen(settings.chatOpen === true)
    } else {
      setPageViewSettings({
        chatOpen: false
      })
      setChatOpen(false)
    }
  }

  const saveSettings = () => {
    if (viewStorageKey) {
      const settings = JSON.stringify({
        chatOpen
      })
      window.localStorage.setItem(viewStorageKey, settings)
    }
  }

  const bgColor = backgroundColor ? theme.palette.background[backgroundColor] : theme.palette.background.default

  return (
    <Box
      sx={{
        flexGrow: 1,
        color: theme.palette.text.primary,
        backgroundColor: bgColor,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        minHeight: "100vh",
        overflow: "hidden"
      }}
    >
      {!hideHeader &&
        <Box
          sx={{
            flex: "none",
            height: 76
          }}
        >
          <Header chatOpen={chatOpen} onChange={(open: boolean) => setChatOpen(open)}/>
        </Box>
      }
      <AlertDialog />
      <ConfirmDialog />
      <NotifyBar />
      <TermsOfUseDialog/>
      <PrivacyPolicyDialog/>
      <Box
        sx={{
          display: "flex",
          flex: "auto",
          flexGrow: 1,
          minHeight: "100%",
          overflow: "hidden",
          backgroundColor: bgColor
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            minHeight: "100%",
          }}
        >
          {children}
        </Box>
        <ChatbotDrawer open={chatOpen} onChange={(open: boolean) => setChatOpen(open)}/>
      </Box>
      {/*{!hideFooter &&*/}
      {/*  <Footer />*/}
      {/*}*/}
    </Box>
  )
}

export default Page