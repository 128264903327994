import React, {ReactNode, useEffect, useState} from "react";
import {Box, Card, Checkbox, IconButton, Typography, useTheme} from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ModelCard2 = ({
  elevation,
  children,
  buttons,
  icon,
  title,
  value,
  valueColor,
  expanded,
  onEdit,
  editButtonId,
  checked,
  bgColor,
  onCheck,
  onChange
}: {
  elevation?: number
  children?: ReactNode
  icon?: ReactNode
  buttons?: ReactNode[]
  title?: string | ReactNode
  value?: string
  valueColor?: string
  expanded?: boolean
  onEdit?: any
  editButtonId?: string
  checked?: boolean
  bgColor?: string
  onCheck?: any
  onChange?(event: any, expanded: boolean): void
}) => {
  const theme = useTheme()

  const [expand, setExpand] = useState<boolean>(false)
  const [check, setCheck] = useState<boolean>(false)

  useEffect(() => {
    setCheck(checked === true)
    setExpand(expanded === true)
  }, [checked, expanded])

  const styles = {
    checkedIcon: {
      height: 20,
      width: 20
    },
    uncheckedIcon: {
      height: 20,
      width: 20
    }
  }

  const handleEdit = (event: any) => {
    event.stopPropagation()
    onEdit(event)
  }

  return (
    <Card
      elevation={elevation ?? 1}
      sx={{
        backgroundColor: bgColor ?? theme.palette.background.paper,
        borderWidth: 1,
        borderColor: theme.palette.grey[400],
        borderStyle: "solid",
        borderRadius: 0,
        padding: 0,
        marginBottom: 1,
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px"
      }}
    >
      <Box
        sx={{
          px: 1,
          pb: 0
        }}
      >
        <Box display="flex" flexDirection="row" flexGrow={1} alignItems="center"
             sx={{cursor:"pointer"}}
             onClick={(event: any) => {if (onChange) {onChange(event, !expanded)}}}
        >
          {icon && <Box pt="4px" pr="4px">
            {icon}
          </Box>}
          <Box display="flex" flexGrow={1} pt="4px" maxHeight={40} overflow="hidden">
            { typeof title === "string" && <Typography gutterBottom variant="body2" color="black" textAlign="left" sx={{lineHeight:1.1}}>{title}</Typography> }
            { typeof title !== "string" && title }
          </Box>
          <Box display="flex" flexGrow={0} width={75}  pt="4px" pr="4px" justifyContent="flex-end" alignItems="center">
            <Typography gutterBottom variant="body2" color={valueColor ?? "green"}>{value ?? ""}</Typography>
          </Box>
          {onCheck !== undefined &&
            <Box display="flex" flexGrow={0} flexShrink={0} p={0} width={14} maxWidth={14} justifyContent="flex-end" alignItems="top">
              <Checkbox
                checked={check}
                color="primary"
                icon={<VisibilityOffIcon sx={styles.uncheckedIcon}/>}
                checkedIcon={<VisibilityIcon sx={styles.checkedIcon}/>}
                onChange={(event: any) => {
                  onCheck(event.target.checked)
                }}
              />
            </Box>
          }
          {onEdit &&
            <Box display="flex" flexGrow={0} flexShrink={0} width={15} maxWidth={15} justifyContent="flex-end" alignItems="top">
              <IconButton id={editButtonId ?? ''} size="small" onClick={handleEdit} sx={{width:16, height:16}}>
                <ChevronRightIcon/>
              </IconButton>
            </Box>
          }
        </Box>
        {expand &&
          <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="flex-start" pb={1}>
            {children}
          </Box>
        }
      </Box>
    </Card>
  )
}

export default ModelCard2