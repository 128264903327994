import Person, {GenderOptions} from "../../../model/Person";
import {Box, useTheme} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import TextFieldValidator from "../../../components/form/TextFieldValidator";
import {getISODateFromDate, isoToLocalDate} from "../../../stores/StoreUtilities";
import {
  CreatePersonInput,
  Gender,
  MaritalStatus,
  UpdateAssetInput,
  UpdateIncomeInput,
  UpdateLiabilityInput,
  UpdatePersonInput,
  UpdateTaxInput
} from "../../../API";
import StateSelector from "../../../components/form/StateSelector";
import {useResources} from "../../../stores/ResourceProvider";
import DatePickerValidator from "../../../components/form/DatePickerValidator";
import FormGroupSpacer from "../../../components/form/FormGroupSpacer";
import {differenceInYears} from "date-fns";
import ModelEditDialog from "../../../components/model/ModelEditDialog";
import Model from "../../../model/Model";
import Tracking from "../../../components/Tracking";
import IModelItem from "../../../model/IModelItem";
import Asset from "../../../model/Asset";
import Liability from "../../../model/Liability";
import Income from "../../../model/Income";
import Expense from "../../../model/Expense";
import Tax from "../../../model/Tax";
import {UpdateExpenseInput} from "../../../../amplify/backend/function/shared/api/API";

const PersonEditDialog = ({
  open,
  person,
  model,
  onClose,
  onSave,
  onDelete
}: {
  open?: boolean
  person: Person
  model: Model
  onClose?(): void
  onSave?(update: Person): void
  onDelete?(person: Person): void
}) => {
  const [personModel, setPersonModel] = useState<Person>(person)
  const [title, setTitle] = useState<string>("")
  const [gender, setGender] = useState<Gender | undefined>()
  const [maritalStatus, setMaritalStatus] = useState<MaritalStatus | undefined>()
  const [birthDate, setBirthDate] = useState<Date | null | undefined>(undefined)
  const [retireDate, setRetireDate] = useState<Date | null | undefined>(undefined)
  const [retireDateLock, setRetireDateLock] = useState<boolean>(false)
  const [residenceState, setResidenceState] = useState<string | undefined>()
  const [hereditaryAdjust, setHereditaryAdjust] = useState<string | undefined>()
  const [hereditaryAdjustLock, setHereditaryAdjustLock] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const theme = useTheme()
  const { modelStore, calculator, notify, confirm } = useResources()

  const styles = {
    actions: {
      display:"flex",
      flexDirection:"row",
      width:"100%",
      justifyContent:"space-between",
      alignItems:"center"
    },
    row: {
      maxHeight: 48
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"]
    },
    stateField: {
      top: 5,
      width: "100%"
    }
  }

  useEffect(() => {
    setTitle(person.nickname)
    setGender(person.gender)
    setMaritalStatus(person.maritalStatus)
    setBirthDate(person.birthDate ? isoToLocalDate(person.birthDate) : undefined)
    setRetireDate(person.retireDate ? isoToLocalDate(person.retireDate) : undefined)
    setRetireDateLock(model.hasLock(person.id, "retireDate"))
    setResidenceState(person.state ?? "")
    setHereditaryAdjust(person.hereditaryAdjust > 0 ? String(person.hereditaryAdjust) : "")
    setHereditaryAdjustLock(model.hasLock(person.id, "hereditaryAdjust"))
    setIsOpen(open === true)
  }, [person, open])

  const handleClose = async (event: any) => {
    if (onClose) {
      onClose()
    }
  }

  const handleSave = async (event: any) => {
    try {
      let updatedPerson: Person | undefined

      let lifeExpectancy
      if (birthDate) {
        const age = Math.floor(differenceInYears(new Date(), birthDate))
        lifeExpectancy = Math.round(calculator.lifeExpectancyYears(age, hereditaryAdjust ? parseInt(hereditaryAdjust) : 0, gender === Gender.Male)) + age
      }

      if (!person.createdAt) {
        const input: CreatePersonInput = {
          id: person.id,
          accountId: person.accountId,
          userId: person.userId,
          modelId: person.modelId,
          nickname: title,
          gender: gender,
          maritalStatus: maritalStatus,
          birthDate: birthDate ? getISODateFromDate(birthDate) : null,
          retireDate: retireDate ? getISODateFromDate(retireDate) : null,
          state: residenceState,
          hereditaryAdjust: hereditaryAdjust ? parseInt(hereditaryAdjust) : 0,
          lifeExpectancy: lifeExpectancy
        }

        updatedPerson = await modelStore.createPerson(input)
        Tracking.event({action: "Person Created"})
      } else {
        const input: UpdatePersonInput = {
          id: person.id,
          accountId: person.accountId,
          userId: person.userId,
          modelId: person.modelId,
          nickname: title,
          gender: gender,
          maritalStatus: maritalStatus,
          birthDate: birthDate ? getISODateFromDate(birthDate) : null,
          retireDate: retireDate && !retireDateLock ? getISODateFromDate(retireDate) : null,
          state: residenceState,
          hereditaryAdjust: hereditaryAdjust && !hereditaryAdjustLock ? parseInt(hereditaryAdjust) : null,
          lifeExpectancy: lifeExpectancy
        }

        if (person.nickname !== input.nickname ||
          person.gender !== input.gender ||
          person.maritalStatus !== input.maritalStatus ||
          person.birthDate !== input.birthDate ||
          (!retireDateLock && person.retireDate !== input.retireDate) ||
          person.state !== input.state ||
          (!hereditaryAdjustLock && person.hereditaryAdjust !== input.hereditaryAdjust) ||
          person.lifeExpectancy !== input.lifeExpectancy ||
          person.state !== input.state) {
          updatedPerson = await modelStore.updatePerson(input)
          Tracking.event({action: "Person Updated"})
        }
      }

      if (onSave && updatedPerson) {
        onSave(updatedPerson)
      } else if (onClose) {
        onClose()
      }
    } catch (err: any) {
      notify.show('error', err.message)
    }
  }

  const handleDelete = async () => {
    if (onDelete) {
      const spouse = personModel.spouse
      if (personModel.createdAt && spouse) {
        const owns = findOwns()
        if (owns.length > 0) {
          confirm.show("Confirm Delete",
            `${personModel.nickname} is the owner or joint owner of ${owns.length} items. Select Confirm to assign these to ${spouse.nickname} and to delete ${personModel.nickname}. It is recommended to make a copy of the model first.`,
            ['Confirm' , 'Cancel'],
            async () => {
              try {
                await updateOwner(owns)
                const deleted = await modelStore.deletePerson(personModel.id)
                if (deleted) {
                  await updateSpouse()
                  onDelete(deleted)
                }
                return true
              } catch (err: any) {
                notify.show('error', err.message)
                return false
              }
            })
          return
        } else {
          confirm.show("Confirm Delete", `Are you sure you want to delete this person?`,
            ['Delete', 'Cancel'],
            async () => {
            try {
              const deleted = await modelStore.deletePerson(personModel.id)
              if (deleted) {
                await updateSpouse()
                onDelete(deleted)
              }
              return true
            } catch (err: any) {
                notify.show('error', err.message)
                return false
            }
          })
        }
      } else {
        onDelete(personModel)
      }
    }
  }

  const findOwns = () => {
    const personId = personModel.id
    const jointId = model.jointId
    let found: IModelItem[] = []

    const incomes = model.incomes.filter((item: Income) => item.ownerId === personId || item.ownerId === jointId)
    const expenses = model.expenses.filter((item: Expense) => item.ownerId === personId || item.ownerId === jointId)
    const taxes = model.taxes.filter((item: Tax) => item.ownerId === personId || item.ownerId === jointId)
    const assets = model.assets.filter((item: Asset) => item.ownerId === personId || item.ownerId === jointId)
    const liabilities = model.liabilities.filter((item: Liability) => item.ownerId === personId || item.ownerId === jointId)
    return [...incomes, ...expenses, ...taxes, ...assets, ...liabilities]
  }

  const updateOwner = async (owns: IModelItem[])=> {
    const personId = personModel.id
    const jointId = model.jointId
    const spouse = personModel.spouse
    if (spouse) {
      const spouseId = spouse.id
      let promises : Promise<IModelItem | undefined>[] = []

      const incomes = model.incomes.filter((item: Income) => item.ownerId === personId || item.ownerId === jointId)
      incomes.forEach((income: Income) => {
        const input: UpdateIncomeInput = {
          id: income.id,
          ownerId: spouseId
        }
        promises.push(modelStore.updateIncome(input))
      })
      await Promise.all(promises)
      promises = []

      const expenses = model.expenses.filter((item: Expense) => item.ownerId === personId || item.ownerId === jointId)
      expenses.forEach((expense: Expense) => {
        const input: UpdateExpenseInput = {
          id: expense.id,
          ownerId: spouseId
        }
        promises.push(modelStore.updateExpense(input))
      })
      await Promise.all(promises)
      promises = []

      const taxes = model.taxes.filter((item: Tax) => item.ownerId === personId || item.ownerId === jointId)
      taxes.forEach((tax: Tax) => {
        const input: UpdateTaxInput = {
          id: tax.id,
          ownerId: tax.ownerId
        }
        promises.push(modelStore.updateTax(input))
      })
      await Promise.all(promises)
      promises = []

      const assets = model.assets.filter((item: Asset) => item.ownerId === personId || item.ownerId === jointId)
      assets.forEach((asset: Asset) => {
        const input: UpdateAssetInput = {
          id: asset.id,
          ownerId: spouseId
        }
        promises.push(modelStore.updateAsset(input))
      })
      await Promise.all(promises)
      promises = []

      const liabilities = model.liabilities.filter((item: Liability) => item.ownerId === personId || item.ownerId === jointId)
      liabilities.forEach((liability: Liability) => {
        const input: UpdateLiabilityInput = {
          id: liability.id,
          ownerId: spouseId
        }
        promises.push(modelStore.updateLiability(input))
      })
      await Promise.all(promises)
    }
  }

  const updateSpouse = async () => {
    const spouse = personModel.spouse
    if (spouse && spouse.maritalStatus === MaritalStatus.Married) {
      // Change maritalStatus to Single
      const input: UpdatePersonInput = {
        id: spouse.id,
        maritalStatus: MaritalStatus.Single
      }
      const person = await modelStore.updatePerson(input)
      // Delete the joint person
      modelStore.deleteModelItem("persons", model.jointId)
    }
  }

  if (!personModel || !isOpen) {
    return null
  }

  return (
    <ModelEditDialog title="Edit Person" open={isOpen} size="sm"
                     onCancel={handleClose}
                     onSave={handleSave}
                     onDelete={model.persons.length > 1 ? handleDelete : undefined}
                     confirmDelete={false}
      >
        <TextFieldValidator
          margin="normal"
          name="nickname"
          label="Name"
          type="text"
          fullWidth
          variant="standard"
          required
          validators={{ required: true }}
          value={title}
          onChange={(event: any) => setTitle(event.target.value)}
        />
        <Box display="flex" justifyContent="space-between">
          <TextFieldValidator
            type="text"
            validators={{ required: true }}
            required
            name="gender"
            label="Gender"
            variant="standard"
            styleProp={{width:"100%"}}
            autocompleteOptions={{
              sx: {width:"100%"},
              freeSolo: false,
              options: GenderOptions,
              value: gender,
              onChange: (event: any, value: Gender, reason: any) => {
                setGender(value)
              }
            }}
          />
          <FormGroupSpacer/>
          <TextFieldValidator
            type="text"
            validators={{ required: true }}
            required
            name="maritalStatus"
            label="MaritalStatus"
            variant="standard"
            styleProp={{width:"100%"}}
            autocompleteOptions={{
              sx: {width:"100%"},
              freeSolo: false,
              options: Object.values(MaritalStatus),
              getOptionLabel: (option: string) => option ?? null,
              value: maritalStatus,
              onChange: (event: any, value: MaritalStatus, reason: any) => {
                setMaritalStatus(value)
              }
            }}
          />

        </Box>
        <Box display="flex" justifyContent="stretch">
          <Box pt={1} width="50%">
            <DatePickerValidator
              name="birthDate"
              label="Birth Date"
              value={birthDate}
              format="MM-dd-yyyy"
              variant="standard"
              margin="dense"
              fullWidth
              required
              onChange={(newValue: any) => {
                setBirthDate(newValue)
              }}
            />
          </Box>
          <FormGroupSpacer/>
          <Box pt={1} width="50%">
            <DatePickerValidator
              name="retireDate"
              label="Est Retirement Date"
              value={retireDate}
              format="MM-dd-yyyy"
              variant="standard"
              margin="dense"
              fullWidth
              required
              disabled={retireDateLock}
              helperText={retireDateLock ? "Locked by Timeline Strategy" : undefined}
              onChange={(newValue: any) => {
                setRetireDate(newValue)
              }}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <StateSelector
            label="State"
            value={residenceState}
            isRequired={false}
            sx={styles.stateField}
            onChange={(event: any) => setResidenceState(event.target.value)}
          />
          <FormGroupSpacer/>
          <TextFieldValidator
            type="string"
            validators={{ required: false, minValue: 1, maxValue: 120 }}
            name="hereditaryAdjust"
            variant="standard"
            margin="dense"
            fullWidth
            label="Life Expectancy Adjustment"
            value={hereditaryAdjust}
            locked={hereditaryAdjustLock}
            helperText={hereditaryAdjustLock ? "Locked by Timeline Strategy" : "Override your life expectancy"}

            onChange={(event: any) => setHereditaryAdjust(event.target.value)}
          />
        </Box>
    </ModelEditDialog>
  )
}

export default PersonEditDialog