import * as React from "react";
import {useEffect, useState} from "react";
import {useResources} from "../../stores/ResourceProvider";
import Dialog from "@mui/material/Dialog";
import FormValidator from "../../components/form/FormValidator";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Model from "../../model/Model";
import DialogActions from "@mui/material/DialogActions";
import {Box, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, useTheme} from "@mui/material";
import CancelButton from "../../components/form/CancelButton";
import ProgressButton from "../../components/form/ProgressButton";
import {ClientAccess, UpdateModelInput} from "../../API";
import DialogContentText from "@mui/material/DialogContentText";
import IconicButton from "../../components/controls/IconicButton";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import Tracking from "../../components/Tracking";

const EditModelDialog = ({
  model,
  onClose,
  onSave,
  onDelete
}: {
  model: Model,
  onClose(): void
  onSave(model: Model): void
  onDelete?(model: Model): void
}) => {
  const [name, setName] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [advisorCreated, setAdvisorCreated] = useState<boolean>(false)
  const [clientAccess, setClientAccess] = useState<ClientAccess>(ClientAccess.Owner)
  const [canDelete, setCanDelete] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const {modelStore, userStore, confirm} = useResources()
  const theme = useTheme()

  const styles = {
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "1.0em",
      maxHeight: 15,
      textWrap: "nowrap",
      color: theme.palette.grey["700"]
    },
  }

  useEffect(() => {
    setName(model.name)
    setDescription(model.description)
    setAdvisorCreated(model.advisorCreated)
    setClientAccess(model.clientAccess)
    if (onDelete && (userStore.isAdvisorOrAdmin || model.clientAccess === ClientAccess.Owner)) {
      setCanDelete(true)
    }
    setErrorMessage("")
  }, [model])

  const handleSubmit = async () => {
    try {
      setIsProcessing(true)
      setErrorMessage("")
      const found = modelStore.models.find((m: Model) => m.name.toLowerCase() === name.toLowerCase())
      if (found && found.id !== model.id) {
        setErrorMessage("A model with this name already exists.")
      } else {
        const input: UpdateModelInput = {
          id: model.id,
          name: name,
          description: description
        }
        if (userStore.isAdvisor) {
          input.clientAccess = clientAccess
        }
        const update = await modelStore.updateModel(input)
        if (update) {
          Tracking.event({action: "Model Updated"})
          onSave(update)
        }
      }
    } catch (err: any) {
      setErrorMessage(`Error updating model (${err.message})`)
    } finally {
      setIsProcessing(false)
    }
  }

  const handleDelete = () => {
    confirm.show('Confirm Delete', `Are you sure you want to delete ${model.name}?`,
      ['Delete', 'Cancel'],
      () => {
          onDelete!(model)
          return true
      })
  }

  const isAdvisor = userStore.isAdvisor

  return (
    <Dialog
      id="editModelDialog"
      open={true}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="editModelDialogTitle"
    >
      <FormValidator onSubmit={handleSubmit} autoComplete="off">
        <DialogTitle id="editModelDialogTitle">Edit Model</DialogTitle>
        <DialogContent sx={{my:2}}>
          {errorMessage &&
            <DialogContentText sx={{color: theme.palette.error.main}}>
              {errorMessage}
            </DialogContentText>
          }
          <TextFieldValidator
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            validators={{required:true}}
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
            fullWidth
            variant="standard"
          />
          <TextFieldValidator
            margin="dense"
            name="description"
            label="Description"
            type="text"
            validators={{required:false}}
            value={description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDescription(event.target.value)}
            fullWidth
            variant="standard"
          />
          {isAdvisor &&
            <FormGroup>
              <FormLabel sx={styles.formLabel}>Client Access</FormLabel>
              <RadioGroup aria-label="clientAccess" name="discretionary" value={clientAccess} row
                          onChange={(event: any) => {
                            setClientAccess(event.target.value)
                          }}>
                <FormControlLabel
                  value="None"
                  control={<Radio color="secondary" disabled={!isAdvisor}/>}
                  label="None"
                  labelPlacement="end"
                />
                {/*<FormControlLabel*/}
                {/*  value="View"*/}
                {/*  control={<Radio color="secondary" disabled={!isAdvisor} />}*/}
                {/*  label="View"*/}
                {/*  labelPlacement="end"*/}
                {/*/>*/}
                <FormControlLabel
                  value="Edit"
                  control={<Radio color="secondary" disabled={!isAdvisor} />}
                  label="Edit"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="Owner"
                  control={<Radio color="secondary" disabled={!isAdvisor} />}
                  label="Owner"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormGroup>
          }
        </DialogContent>
        <DialogActions>
          <Box display="flex" flexGrow={1} justifyContent={canDelete ? "space-between" : "flex-end"} alignItems="center">
            {canDelete &&
              <IconicButton onClick={handleDelete}>
                <DeleteIcon/>
              </IconicButton>
            }
            <Box display="flex" justifyContent="flex-end">
              <CancelButton onClick={onClose} />
              <ProgressButton variant="contained" size="medium" color="primary" sx={{ color: "#fff" }}
                              type="submit" processing={isProcessing}>
                Save
              </ProgressButton>
            </Box>
          </Box>
        </DialogActions>

      </FormValidator>

    </Dialog>
  )

}


export default EditModelDialog