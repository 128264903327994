import React, {ReactElement, ReactNode} from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
  DraggableProvided,
  DroppableProvided,
  Direction
} from "react-beautiful-dnd";

interface DraggableListProps<T> {
  droppableId: string;
  data: T[];
  renderItem: (item: T, provided: DraggableProvided) => ReactElement;
  renderWrapper: (
    children: ReactNode,
    providedMain: DroppableProvided,
  ) => ReactElement;
  direction?: Direction;
}

export const DraggableList = <T extends { id: string }>({
  droppableId,
  data,
  renderItem,
  renderWrapper,
  direction,
}: DraggableListProps<T>) => (
  <Droppable droppableId={droppableId} direction={direction}>
    {(providedMain) =>
      renderWrapper(
        <>
          {data.map((item, index) => (
            <Draggable
              key={item.id}
              index={index}
              draggableId={item.id}
            >
              {(provided) => renderItem(item, provided)}
            </Draggable>
          ))}

          {providedMain.placeholder}
        </>,
        providedMain
      )
    }
  </Droppable>
)

export default DraggableList