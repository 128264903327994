import Expense, {ExpenseCategory} from "../../../model/Expense";
import {
  Box,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  useTheme
} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import TextFieldValidator from "../../../components/form/TextFieldValidator";
import {CreateExpenseInput, UpdateExpenseInput} from "../../../API";
import {useResources} from "../../../stores/ResourceProvider";
import Model from "../../../model/Model";
import Schedule, {Frequency} from "../../../model/Schedule";
import ScheduleSelector from "../../../components/controls/ScheduleSelector";
import FormGroupSpacer from "../../../components/form/FormGroupSpacer";
import MilestoneDateFieldValidator from "../../../components/form/MilestoneDateFieldValidator";
import ModelEditDialog from "../../../components/model/ModelEditDialog";
import {moneyToNumberFormat, numberToMoneyFormat, numberToPercentFormat} from "../../../stores/StoreUtilities";
import {isNumber} from "../../../model/ModelUtilities";
import Asset, {AssetCategory} from "../../../model/Asset";
import Liability from "../../../model/Liability";
import Tracking from "../../../components/Tracking";
import LockPersonIcon from '@mui/icons-material/LockPerson';
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const ExpenseEditDialog = ({
  open,
  expense,
  model,
  onClose,
  onSave,
  onDelete
}: {
  open?: boolean
  expense: Expense
  model: Model
  onClose?(): void
  onSave?(update: Expense): void
  onDelete?(expense: Expense): void
}) => {
  const [expenseModel, setExpenseModel] = useState<Expense>(expense)
  const [description, setDescription] = useState<string>("")
  const [amount, setAmount] = useState<string | undefined>()
  const [amountLock, setAmountLock] = useState<boolean>(false)
  const [expenseCategory, setExpenseCategory] = useState<ExpenseCategory | undefined>()
  const [owner, setOwner] = useState<string | undefined>()
  const [schedule, setSchedule] = useState<Schedule | undefined>()
  const [start, setStart] = useState<string>()
  const [end, setEnd] = useState<string>()
  const [annualInf, setAnnualInf] = useState<string | undefined>()
  const [annualInfLock, setAnnualInfLock] = useState<"Model" | "User" | undefined>()
  const [asset, setAsset] = useState<Asset | undefined>()
  const [liability, setLiability] = useState<Liability | undefined>()

  const [type, setType] = useState<string | undefined>()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const theme = useTheme()
  const { modelStore, userStore, notify } = useResources()

  const styles = {
    actions: {
      display:"flex",
      flexDirection:"row",
      width:"100%",
      justifyContent:"space-between",
      alignItems:"center",
      marginTop: theme.spacing(1)
    },
    row: {
      maxHeight: 48
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.grey["700"]
    },
    stateField: {
      top: 5,
      width: "100%"
    }
  }

  useEffect(() => {
    setExpenseModel(expense)
    setDescription(expense.description)
    setAmount(numberToMoneyFormat(expense.amount, 0))
    setAmountLock(model.hasLock(expense.id, "amount"))
    setExpenseCategory(expense.expenseCategory)
    const person = expense.ownerId ? model.getPerson(expense.ownerId) : undefined
    setOwner(person ? person.nickname : model.getDefaultPersonNickname())
    setSchedule(expense.schedule ?? new Schedule({name: "Monthly", frequency: Frequency.Monthly, interval: 1}))
    setStart(expense.start)
    setEnd(expense.end)
    if (expense.expenseCategory === ExpenseCategory.LoansAndLiabilities) {
      setAnnualInf("0")
    } else {
      setAnnualInf(isNumber(expense.annualInf) ? numberToPercentFormat(expense.annualInf, 2) : "")
    }
    if (expense.infLock) {
      setAnnualInfLock("User")
    } else if (model.hasLock(expense.id, "annualInf")) {
      setAnnualInfLock("Model")
    }
    setType(expense.discretionary ? "Discretionary" : "Essential")
    const asset = expense.asset ? expense.asset : model.getAssetById(expense.assetId)
    setAsset(asset)
    const liability = expense.liability ? expense.liability : model.getLiabilityById(expense.liabilityId)
    setLiability(liability)
    setIsOpen(open === true)
  }, [expense, model, open])

  const handleClose = async (event: any) => {
    if (onClose) {
      onClose()
    }
  }

  const handleSave = async (event: any) => {
    try {
      const person = model.getPersonByNickname(owner)
      let updatedExpense: Expense | undefined
      const amountValue = moneyToNumberFormat(amount, 0) ?? 0

      if (!expenseModel.createdAt) {
        const input: CreateExpenseInput = {
          id: expenseModel.id,
          accountId: expenseModel.accountId,
          userId: expenseModel.userId,
          modelId: expenseModel.modelId,
          description: description,
          amount: amountValue,
          expenseCategory: expenseCategory,
          schedule: JSON.stringify(schedule),
          start: start,
          end: end ?? null,
          annualInf: annualInf !== undefined ? parseFloat(annualInf) / 100.0 : null,
          infLock: annualInfLock === "User",
          discretionary: type === "Discretionary",
          ownerId: person ? person.id : null,
          assetId: asset ? asset.id : null,
          liabilityId: liability ? liability.id : null,
          sortOrder: expenseModel.sortOrder
        }

        updatedExpense = await modelStore.createExpense(input)
        Tracking.event({action: "Expense Created"})
      } else {
        const input: UpdateExpenseInput = {
          id: expenseModel.id,
          accountId: expenseModel.accountId,
          userId: expenseModel.userId,
          modelId: expenseModel.modelId,
          description: description,
          amount: amountValue,
          expenseCategory: expenseCategory,
          schedule: JSON.stringify(schedule),
          start: start,
          end: end ?? null,
          annualInf: annualInf !== undefined ? parseFloat(annualInf) / 100.0 : null,
          infLock: annualInfLock === "User",
          discretionary: type === "Discretionary",
          ownerId: person ? person.id : null,
          assetId: asset ? asset.id : null,
          liabilityId: liability ? liability.id : null,
        }

        if (amountLock) {
          delete input.amount
        }
        if (annualInfLock === "Model") {
          delete input.annualInf
        }

        updatedExpense = await modelStore.updateExpense(input)
        Tracking.event({action: "Expense Updated"})
      }

      if (onSave && updatedExpense) {
        onSave(updatedExpense)
      } else if (onClose) {
        onClose()
      }
    } catch (err: any) {
      notify.show('error', err.message)
    }
  }

  const handleDelete = async () => {
    if (onDelete) {
      if (expenseModel.createdAt) {
        const deleted = await modelStore.deleteExpense(expenseModel.id)
        if (deleted) {
          onDelete(deleted)
        }
      } else {
        onDelete(expenseModel)
      }
    }
  }

  if (!expenseModel || !isOpen) {
    return null
  }

  const endDateHelperText = "Default: Owner's Life Exp"

  const handleInfLock = (typename: string) => {
    if (annualInfLock === "User") {
      if (model.getOverrideInflationLock(typename)) {
        setAnnualInfLock("Model")
        setAnnualInf(numberToPercentFormat(model.getDefaultInflationRate(typename)))
      } else {
        setAnnualInfLock(undefined)
      }
    } else if (annualInfLock === "Model") {
      setAnnualInfLock("User")
    } else if (annualInfLock === undefined) {
      setAnnualInfLock("User")
    }
  }

  const renderAnnualInfField = (typename: string) => {
    let endAdornment
    let helperText
    let disabled = false
    if (userStore.isFree) {
     helperText = `Default: ${Model.defaultInflationRate}`
    } else if (annualInfLock === "User") {
      endAdornment = (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle inflation lock"
            onClick={() => handleInfLock(typename)}
            edge="end"
          >
            <LockPersonIcon/>
          </IconButton>
        </InputAdornment>
      )
      helperText = "Locked by User"
    } else if (annualInfLock === "Model") {
      endAdornment = (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle inflation lock"
            onClick={() => handleInfLock(typename)}
            edge="end"
          >
            <LockIcon/>
          </IconButton>
        </InputAdornment>
      )
      helperText = "Locked by Inflation Strategy"
      disabled = true
    } else {
      endAdornment = (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle inflation lock"
            onClick={() => handleInfLock(typename)}
            edge="end"
          >
            <LockOpenIcon/>
          </IconButton>
        </InputAdornment>
      )
      helperText = `Default: ${numberToPercentFormat(model.getDefaultInflationRate(typename), 2)}`
    }

    return (
      <TextFieldValidator
        type="string"
        validators={{ required: false, minValue: 0 }}
        name="annualInf"
        variant="standard"
        margin="dense"
        fullWidth
        label="Annual Inflation %"
        value={annualInf}
        InputProps={{
          endAdornment: endAdornment
        }}
        helperText={helperText}
        disabled={disabled}
        onChange={(event: any) => setAnnualInf(event.target.value)}
      />
    )
  }

  return (
    <ModelEditDialog title="Edit Expense" open={isOpen}
                     onCancel={handleClose}
                     onSave={handleSave}
                     onDelete={handleDelete}
    >
      <TextFieldValidator
        autoFocus
        margin="normal"
        name="description"
        label="Title"
        type="text"
        fullWidth
        variant="standard"
        required
        validators={{ required: true }}
        value={description}
        onChange={(event: any) => setDescription(event.target.value)}
      />
      <TextFieldValidator
        type="string"
        validators={{ isMoney: true }}
        name="amount"
        variant="standard"
        margin="dense"
        fullWidth
        label="Amount"
        value={amount}
        helperText={amountLock ? "Locked by Expense Strategy" : undefined}
        onChange={(event: any) => setAmount(event.target.value)}
        disabled={amountLock}
        InputProps={amountLock ? {
          endAdornment: (
            <InputAdornment position="end">
              <LockIcon/>
            </InputAdornment>
          )
        } : undefined}
      />
      <ScheduleSelector
        value={schedule!}
        startDate={model.getDate(start)}
        onChange={(value: Schedule) => setSchedule(value)}
      />
      <FormGroup>
        <FormLabel sx={styles.formLabel}>Type</FormLabel>
        <RadioGroup aria-label="type" name="type" value={type} row
                    onChange={(event: any) => {
                      setType(event.target.value)
                    }}>
          <FormControlLabel
            value="Essential"
            control={<Radio color="secondary" />}
            label="Essential"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Discretionary"
            control={<Radio color="secondary" />}
            label="Discretionary"
            labelPlacement="end"
          />
        </RadioGroup>
      </FormGroup>
      {expenseCategory === ExpenseCategory.LoansAndLiabilities &&
        <TextFieldValidator
          type="text"
          validators={{ required: false }}
          // required
          name="liability"
          label="Liability Account"
          variant="standard"
          autocompleteOptions={{
            freeSolo: false,
            options: model.liabilities,
            getOptionLabel: (option: Liability) => option ? option.description : "None",
            isOptionEqualToValue: (option: Liability, value: Liability) => option.id === value.id,
            value: liability || null,
            onChange: (event: any, value: Liability, reason: any) => {
              setLiability(value)
              if (value) {
                if (value.start) {
                  setStart(value.start)
                }
                if (value.end) {
                  setEnd(value.end)
                }
                if (value.owner) {
                  setOwner(value.owner.nickname)
                }
              }
            }
          }}
        />
      }
      <TextFieldValidator
        type="text"
        validators={{ required: true }}
        required
        name="owner"
        label="Owner"
        variant="standard"
        disabled={liability !== undefined}
        autocompleteOptions={{
          freeSolo: false,
          options: model.getPersonNicknames(),
          value: owner,
          onChange: (event: any, value: string, reason: any) => {
            setOwner(value)
          }
        }}
      />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <MilestoneDateFieldValidator
          name="start"
          label="Start Date"
          model={model}
          value={start}
          disabled={liability !== undefined}
          width="50%"
          onChange={(value: string) => setStart(value)}
        />
        <FormGroupSpacer/>
        <MilestoneDateFieldValidator
          name="end"
          label="End Date"
          model={model}
          value={end}
          disabled={liability !== undefined}
          width="50%"
          helperText={endDateHelperText}
          onChange={(value: string) => setEnd(value)}
        />
      </Box>
      {expenseCategory !== ExpenseCategory.LoansAndLiabilities &&
        renderAnnualInfField("expense")
      }
      <TextFieldValidator
        type="text"
        validators={{ required: false }}
        // required
        name="asset"
        label="Designated Payment Account (optional)"
        helperText="Use for designated purpose accounts like a 529 Plan, HSA, or major purchase."
        variant="standard"
        autocompleteOptions={{
          freeSolo: false,
          options: model.getAssetsByCategory(AssetCategory.LiquidInvestableAssets),
          getOptionLabel: (option: Asset) => option ? option.description : "None",
          isOptionEqualToValue: (option: Asset, value: Asset) => option.id === value.id,
          value: asset || null,
          onChange: (event: any, value: Asset, reason: any) => {
            setAsset(value)
          }
        }}
      />
    </ModelEditDialog>
  )
}

export default ExpenseEditDialog