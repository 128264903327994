import React, {useEffect, useState} from "react";
import {Box, Paper, TableContainer, Typography, useTheme} from "@mui/material";
import {numberToMoneyFormat} from "../../../../stores/StoreUtilities";
import {FutureCalc} from "../../../../components/calculator/Calculator";
import {globalColors} from "../../../../styles/globalStyles";
import cloneDeep from "lodash.clonedeep";
import Chart from "react-apexcharts";
import {MaxTaxYear} from "../../../../components/tax/TaxEstimator";
import {useResources} from "../../../../stores/ResourceProvider";
import TaxableIncomeByTaxBracketTable from "./TaxableIncomeByTaxBracketTable";

const defaultOptions = {
  chart: {
    id: "taxable-income-estimate-chart",
    type: 'bar',
    stacked: true,
    height: 350,
    width: "100%",
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      }
    },
    toolbar: {
      show: false,
      tools: {
        download: false
      }
    }
  },
  title: {
    text: "",
    align: "right",
    margin: 0,
    floating: true,
    style: {
      fontSize: "16px",
      fontFamily: "Poppins,sans-serif",
      color: "rgb(31, 92, 167)"
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    onItemClick: {
      toggleDataSeries: false
    }
  },
  xaxis: {
    type: 'number',
    min: 2000,
    axisBorder: {
      show: true
    }
  },
  yaxis: {
    labels: {
      formatter: (val: number) => {
        return val / 1000 + 'K'
      }
    }
  },
  tooltip: {
    y: {
      formatter: function (value: number) {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  // colors: [globalColors.taxable, globalColors.taxDeferredRMD, globalColors.taxDeferredElective, globalColors.taxFree, globalColors.taxExpense],
  resonsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const TaxableIncomeByTaxBracketChart = ({
  year,
  futureCalc,
  onChange,
  height,
  width,
  title
}: {
  year: number
  futureCalc: FutureCalc
  onChange?: (year: number) => any
  height?: string
  width?: string
  title?: string
}) => {
  const [startYear, setStartYear] = useState<number>(0)
  const [endYear, setEndYear] = useState<number>(0)
  const [calc, setCalc] = useState<FutureCalc | undefined>()
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<any>()
  const [currentYear, setCurrentYear] = useState<number>(0)

  const { taxEstimator } = useResources()

  const theme = useTheme()

  useEffect(() => {
    if (futureCalc.futureYears.length > 0) {
      const start = futureCalc.futureYears[0].year
      setStartYear(start)
      setEndYear(futureCalc.futureYears[futureCalc.futureYears.length-1].year)
      setCurrentYear(year)
      setCalc(futureCalc)
    }
  }, [year, futureCalc])

  useEffect(() => {
    if (startYear > 0 && calc && calc.futureYears.length > 0) {
      calculateData(startYear)
    }
  }, [currentYear, calc])

  const calculateData = (startYear: number) => {
    if (!calc || startYear <= 0 || endYear < startYear) {
      return
    }

    const numYears = calc.futureYears.length
    const data = Array<Array<{x: number, y: number}>>()

    for (let index = 0; index <numYears; index++) {
      const year = startYear + index
      const fy = calc.futureYears[index]
      const taxInput = fy.taxInput
      const taxEst = fy.taxEstimate
      if (!taxInput || !taxEst) {
        continue
      }
      const brackets = taxEstimator.getTaxBrackets(year, taxInput.filingStatus, taxInput.inflationRate)
      const taxableIncome = taxEst.taxableIncome

      let b = 0
      let amount = 0
      do {
        const bracket = brackets[b]
        if (taxableIncome > bracket.start) {
          if (bracket.end) {
            amount = Math.min(bracket.end, taxableIncome) - Math.min(bracket.start, taxableIncome)
          } else {
            amount = taxableIncome - Math.min(bracket.start, taxableIncome)
          }
          if (data.length < b+1) {
            const array = new Array<{x: number, y: number}>()
            for (let i = 0; i < numYears; i++) {
              array.push({x: i + startYear, y: 0})
            }
            data.push(array)
          }
          data[b][index] = {x: year, y: amount}
        } else {
          amount = 0
        }
        ++b
      } while (amount > 0 && b < brackets.length)
    }

    const taxInput = futureCalc.futureYears[0]!.taxInput!
    const brackets = taxEstimator.getTaxBrackets(startYear, taxInput.filingStatus, taxInput.inflationRate)
    const series: any[] = []
    data.forEach((set: {x: number, y: number}[], index: number) => {
      series.push({
        name: `${brackets[index].bracket * 100}% Tax Bracket`,
        group: "Taxable Income",
        data: data[index],
        color: globalColors.categorical[index]
      })
    })

    setSeries(series)

    const newOptions = cloneDeep(defaultOptions)
    newOptions.title.text = title ?? ""
    newOptions.xaxis.min = startYear

    if (currentYear !== undefined) {
      const annotations = {
        xaxis: [
          {
            x: currentYear,
            borderColor: theme.palette.secondary.main, //'#00E396',
            label: {
              borderColor: theme.palette.secondary.main, //'#00E396',
              orientation: 'horizontal',
              text: currentYear.toString(),
              style: {
                fontSize: '14px',
                fontWeight: 600
              }
            }
          }
        ]
      }
      newOptions["annotations"] = annotations
      newOptions.chart.events["dataPointSelection"] = (event: any, chartContext: any, details: {dataPointIndex: number, seriesIndex: number}) => {
        if (details.dataPointIndex >= 0 && onChange) {
          onChange(details.dataPointIndex + startYear)
        }
      }
    }

    if (height) {
      newOptions.chart.toolbar.show = false
    }
    setOptions(newOptions)
  }

  // const handleChange = (year: number) => {
  //   setCurrentYear(year)
  //   if (onChange) {
  //     onChange(year)
  //   }
  // }

  if (options && series) {
    return (
      <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="stretch">
        <TableContainer component={Paper} sx={{paddingTop:1}}>
          <Chart
            options={options}
            series={series}
            type="bar"
            height={height ?? "500"}
            width={width ?? "100%"}
          />
        </TableContainer>
        <Typography variant="body2">* Based on {MaxTaxYear} tax brackets and rates.</Typography>

        {series && series.length > 0 &&
          <TaxableIncomeByTaxBracketTable futureCalc={futureCalc} series={series} year={currentYear}/>
        }
      </Box>
    )
  } else {
    return null
  }
}

export default TaxableIncomeByTaxBracketChart