import RbcAPI from "../apis/RbcAPI";
import Calculator from "../components/calculator/Calculator";
import User from "../model/User";
import Model, {ModelSetting} from "../model/Model";
import * as APITypes from "../API";
import {
  ClientAccess,
  CreateModelInput,
  CreatePersonInput,
  CreatePlanChangeInput,
  CreatePlanInput,
  UpdateAssetConversionInput,
  UpdateAssetInput,
  UpdateDeductionInput,
  UpdateExpenseInput,
  UpdateIncomeInput,
  UpdateLiabilityInput,
  UpdateModelInput,
  UpdatePersonInput,
  UpdatePlanInput,
  UpdateSnapshotInput,
  UpdateTaxInput
} from "../API";
import Person from "../model/Person";
import Asset from "../model/Asset";
import Liability from "../model/Liability";
import Snapshot, {ISnapshotDetail, SnapshotDetail} from "../model/Snapshot";
import Income from "../model/Income";
import Deduction from "../model/Deduction";
import Tax from "../model/Tax";
import Expense from "../model/Expense";
import TaxValue from "../model/TaxValue";
import Plan from "../model/Plan";
import PlanChange, {PlanChangeType} from "../model/PlanChange";
import GrowthStrategyChange from "../model/changes/GrowthStrategyChange";
import {createUUID, getISODateFromDate, getISODateTime, getISODateToday, numberToPercentFormat} from "./StoreUtilities";
import {isToday, startOfDay} from "date-fns";
import UserStore from "./UserStore";
import TimelineStrategyChange from "../model/changes/TimelineStrategyChange";
import SocialSecurityStrategyChange from "../model/changes/SocialSecurityStrategyChange";
import WithdrawalStrategyChange from "../model/changes/WithdrawalStrategyChange";
import AssetConversion from "../model/AssetConversion";
import GrowthStrategy from "../model/GrowthStrategy";
import IModelItem from "../model/IModelItem";
import ConversionStrategyChange from "../model/changes/ConversionStrategyChange";
import ExpenseStrategyChange from "../model/changes/ExpenseStrategyChange";
import InflationStrategyChange from "../model/changes/InflationStrategyChange";
import UserSetting from "../model/UserSetting";
import {IWithdrawalGroup} from "../model/WithdrawalStrategy";


class ModelStore {
  rbcAPI: RbcAPI
  calculator: Calculator
  userStore: UserStore

  currentUser?: User
  models: Model[] = []
  modelData = {items: new Array()}
  currentModel?: Model
  currentPlan?: Plan
  appliedModel?: Model
  appliedPlan?: Plan
  updatedAt: string = "" // TODO: Remove
  isLoading = false

  static orderInterval = 100
  static defaultPlanName = "Default Plan" // Depracated
  static defaultScenarioName = "Default Scenario"
  static baseScenarioName = "Base Scenario"

  constructor(options: any) {
    this.rbcAPI = (options && options.rbcAPI) ? options.rbcAPI : null
    this.calculator = (options && options.calculator) ? options.calculator : null
    this.userStore = (options && options.userStore) ? options.userStore : null
  }

  async loadUserModels(user: User) : Promise<Model[]> {
    this.isLoading = true
    try {
      this.currentUser = user
      this.currentModel = undefined
      this.currentPlan = undefined
      this.appliedModel = undefined

      if (user) {
        if (user.models.length === 0) {
          const u = await this.userStore.getUser(user.id)
          if (u) {
            user.models = [...u.models]
          }
        }
        if (this.userStore.isCustomer) {
          this.models = user.models.filter((m: Model) => m.clientAccess !== ClientAccess.None)
        } else {
          this.models = [...user.models]
        }
        this.models.sort((a: Model, b: Model) => a.name.localeCompare(b.name))

        if (this.models.length === 0) {
          await this.initModel()
        }

        this.updatedAt = getISODateTime()
      }
    } catch (err: any) {
      console.log(`Error loading user`, err)
    } finally {
      this.isLoading = false
    }

    return this.models
  }

  async createBaseModel(user: User) {
    const modelInput: CreateModelInput = {
      accountId: user.accountId,
      userId: user.id,
      name: "Base Model"
    }
    const model = await this.createModel(modelInput)

    if (model) {
      const personInput: CreatePersonInput = {
        accountId: model.accountId,
        userId: user.id,
        modelId: model.id,
        nickname: this.currentUser ? this.currentUser.firstName : "Person 1"
      }
      const person = await this.createPerson(personInput)
      if (person) {
        model.persons.push(person)
      }

      await this.initPlans(model)

      return model
    }

    return undefined
  }

  async initModel(model?: Model): Promise<Model | undefined> {
    const userId = this.currentUser!.id
    if (!model) {
      model = await this.getCurrentModel()

      if (!model) {
        const modelInput: CreateModelInput = {
          accountId: this.currentUser!.accountId,
          userId: userId,
          name: "Base Model"
        }
        model = await this.createModel(modelInput)
        if (model) {
          this.models.push(model)
          this.currentModel = model
        }
      }
    }

    if (model && model.persons.length === 0) {
      const personInput: CreatePersonInput = {
        accountId: model.accountId,
        userId: userId,
        modelId: model.id,
        nickname: this.currentUser ? this.currentUser.firstName : "Person 1"
      }
      await this.createPerson(personInput)
    } else if (model) {
      for (let person of model.persons) {
        if (person.id !== model.jointId && !person.lifeExpectancy) {
          // Compute life expectancy if missing, like from a migrated account
          const timeline = this.calculator.personTimeline(person)
          if (timeline) {
            const input: UpdatePersonInput = {
              id: person.id,
              userId: person.userId,
              accountId: person.accountId,
              lifeExpectancy: timeline.lifeExpectancyAge
            }
            await this.updatePerson(input)
            person.lifeExpectancy = timeline.lifeExpectancyAge
          }
        }
      }

      // Migrate to InflationStrategy
      for (let plan of model.plans) {
        const inflationStrategyChange = plan.getChange(PlanChangeType.InflationStrategy)
        if (!inflationStrategyChange) {
          const growthStrategyChange = plan.getChange(PlanChangeType.GrowthStrategy)
          if (growthStrategyChange) {
            const growthStrategy = (growthStrategyChange as GrowthStrategyChange).growthStrategy
            if (growthStrategy && growthStrategy.inflationRate) {
              if (growthStrategy.inflationRate === 0.03 && !growthStrategy.ignoreCustomInflationRates) {
                // No need to migrate defaults
                continue
              }
              const infRate = numberToPercentFormat(growthStrategy.inflationRate, 2)
              const infLock = growthStrategy.ignoreCustomInflationRates ?? false
              let desc = `Deductions: ${infRate} (${infLock ? 'locked' : 'default'}), `
              desc += `Expenses: ${infRate} (${infLock ? 'locked' : 'default'}), `
              desc += `Incomes: ${infRate} (${infLock ? 'locked' : 'default'}), `
              desc += `Taxes: ${infRate} (${infLock ? 'locked' : 'default'})`

              const input: CreatePlanChangeInput = {
                accountId: plan.accountId,
                userId: plan.userId,
                modelId: plan.modelId,
                planId: plan.id,
                changeType: PlanChangeType.InflationStrategy,
                name: "Inflation Strategy",
                enabled: growthStrategyChange.enabled,
                details: JSON.stringify({
                  deduction: {
                    rate: growthStrategy.inflationRate,
                    lock: growthStrategy.ignoreCustomInflationRates
                  },
                  expense: {
                    rate: growthStrategy.inflationRate,
                    lock: growthStrategy.ignoreCustomInflationRates
                  },
                  income: {
                    rate: growthStrategy.inflationRate,
                    lock: growthStrategy.ignoreCustomInflationRates
                  },
                  tax: {
                    rate: growthStrategy.inflationRate,
                    lock: growthStrategy.ignoreCustomInflationRates
                  }
                }),
                description: desc

              }
              const planChange = await this.createPlanChange(input)
              if (planChange) {
                plan.changes.push(planChange)
              }
            }
          }
        }
      }
    }

    if (model) {
      await this.initPlans(model)
      // await this.initSnapshots(model)
    }

    this.updatedAt = getISODateTime()
    return model
  }

  async initPlans(model: Model) {
    // Look for a default plan
    let basePlan = model.plans.find((plan: Plan) => plan.default || plan.name === ModelStore.defaultPlanName || plan.name === ModelStore.defaultScenarioName || plan.name === ModelStore.baseScenarioName)
    if (!basePlan) {
      let input: CreatePlanInput = {
        accountId: model.accountId,
        userId: model.userId,
        modelId: model.id,
        name: ModelStore.baseScenarioName,
        description: "",
        default: true
      }
      basePlan = await this.createPlan(input)
      if (basePlan) {
        // Create default GrowthStrategyChange
        const growthStrategy = new GrowthStrategy({})
        const planChangeInput : CreatePlanChangeInput = {
          userId: model.userId,
          accountId: model.accountId,
          modelId: model.id,
          planId: basePlan.id,
          changeType: PlanChangeType.GrowthStrategy,
          name: "Growth Strategy",
          description: `Simple 4% default growth, 3% default inflation, Spend Surplus`,
          enabled: true,
          details: JSON.stringify(growthStrategy)
        }
        const planChange = await this.createPlanChange(planChangeInput)
        if (planChange) {
          basePlan.changes.push(planChange)
        }
        model.plans.push(basePlan)
      }

    } else if (!basePlan.default || basePlan.name.startsWith("Default ")) {

      // Set default property
      let input: UpdatePlanInput = {
        id: basePlan.id,
        userId: basePlan.userId,
        accountId: basePlan.accountId,
        default: true,
        name: ModelStore.baseScenarioName
      }
      basePlan = await this.updatePlan(input)
      if (basePlan) {
        const index = model.plans.findIndex((p: Plan) => p.id === basePlan!.id)
        if (index >= 0) {
          model.plans.splice(index, 1, basePlan)
        }
      }
    }
    Model.sortPlans(model.plans)

    return basePlan
  }

  // TODO: Keep for future use
  // async initEvents(model: Model) {
  //   if (model.persons.length > 0) {
  //     const person = model.persons[0]
  //     const personTimeline = this.calculator.personTimeline(person)
  //     if (personTimeline) {
  //       await this.initEvent(model, EventType.Person1Retirement, `${person.nickname} Retirement`,
  //         personTimeline.retireAge, getISODateFromDate(personTimeline.retireDate))
  //       await this.initEvent(model, EventType.Person1SocialSecurity, `${person.nickname} Social Security`,
  //         personTimeline.fullSocialSecurityAge, getISODateFromDate(personTimeline.fullSocialSecurityDate))
  //       await this.initEvent(model, EventType.Person1LifeExpectancy, `${person.nickname} Life Expectancy`,
  //         personTimeline.lifeExpectancyAge, getISODateFromDate(personTimeline.lifeExpectancyDate))
  //     }
  //   }
  //   if (model.persons.length > 1) {
  //     const person = model.persons[1]
  //     const personTimeline = this.calculator.personTimeline(person)
  //     if (personTimeline) {
  //       await this.initEvent(model, EventType.Person2Retirement, `${person.nickname} Retirement`,
  //         personTimeline.retireAge, getISODateFromDate(personTimeline.retireDate))
  //       await this.initEvent(model, EventType.Person2SocialSecurity, `${person.nickname} Social Security`,
  //         personTimeline.fullSocialSecurityAge, getISODateFromDate(personTimeline.fullSocialSecurityDate))
  //       await this.initEvent(model, EventType.Person2LifeExpectancy, `${person.nickname} Life Expectancy`,
  //         personTimeline.lifeExpectancyAge, getISODateFromDate(personTimeline.lifeExpectancyDate))
  //     }
  //   }
  //   model.timeline.sort((a: Event, b: Event) => a.date.localeCompare(b.date))
  // }
  //
  // async initEvent(model: Model, eventType: EventType, description: string, age: number, date: string) {
  //   let event = model.timeline.find((e: Event) => e.eventType === eventType)
  //   if (!event) {
  //     const input: CreateEventInput = {
  //       accountId: model.accountId,
  //       modelId: model.id,
  //       eventType: eventType,
  //       description: description,
  //       age: age,
  //       date: date
  //     }
  //     event = await this.createEvent(input)
  //     if (event) {
  //       model.timeline.push(event)
  //     }
  //   }
  //   return event
  // }


  initSnapshots = async (model: Model): Promise<Model | undefined> => {

    if (model.snapshots.length > 0) {
      console.log(`Migrating model`)
      // Add missing snapshot detail typenames

      const snapshotPromises: Promise<Snapshot | undefined>[] = []
      model.snapshots.forEach((snapshot: Snapshot) => {
        let isUpdated = false
        const details: SnapshotDetail[] = []
        snapshot.details.forEach((detail: SnapshotDetail) => {
          if (!detail.typename) {
            const asset = model.assets.find((a: Asset) => a.id === detail.id)
            if (asset) {
              detail.typename = "Asset"
              isUpdated = true
            } else {
              const liability = model.liabilities.find((l: Liability) => l.id === detail.id)
              if (liability) {
                detail.typename = "Liability"
                isUpdated = true
              }
            }
          }
          details.push(detail)
        })

        if (isUpdated) {
          const update: UpdateSnapshotInput = {
            id: snapshot.id,
            details: JSON.stringify(details)
          }
          snapshotPromises.push(this.updateSnapshot(update))
        }
      })
      if (snapshotPromises.length > 0) {
        await Promise.all(snapshotPromises)
      }
    }

    return model
  }

  getCurrentModel = async (modelId?: string, forceLoad: boolean = false, clone: boolean = false): Promise<Model | undefined> => {
    console.debug(`modelStore.getCurrentModel(${modelId}, ${forceLoad}, ${clone})`)

    let model
    if ((modelId && this.currentModel && this.currentModel.id === modelId && !forceLoad && !clone) ||
        (!modelId && this.currentModel && !forceLoad && !clone)) {
      model = this.currentModel
    } else if (modelId) {
      model = await this.getModel(modelId, forceLoad, clone)
    } else if (this.currentModel) {
      model = await this.getModel(this.currentModel.id, forceLoad, clone)
    }

    if (!clone) {
      this.currentModel = model
    }

    return model
  }

  setCurrentModel = async (model: Model) => {
    return (await this.getCurrentModel(model.id))
  }

  getAppliedModel = async (modelId?: string, forceLoad: boolean = false) => {
    console.debug(`modelStore.getAppliedModel(${modelId}, ${forceLoad})`)
    if (!this.appliedModel || (modelId && this.appliedModel.id !== modelId) ||
      !this.currentModel || (this.appliedModel.updatedAt !== this.currentModel.updatedAt) ||
      !this.appliedPlan || this.appliedPlan !== this.currentPlan || forceLoad) {
      const model = await this.getCurrentModel(modelId, forceLoad, true)
      if (model) {
        this.appliedPlan = await this.getCurrentPlan()
        if (this.appliedPlan) {
          this.appliedPlan.applyChanges(model)
        }
        this.appliedModel = model
        this.addCurrentSnapshot(this.appliedModel)
        // this.appliedModel.updatedAt = getISODateTime()
      }
    }

    return this.appliedModel
  }

  applyPlanChanges = async (model: Model, plan: Plan) => {
    this.appliedModel = await this.getModel(model.id, false, true)
    if (this.appliedModel) {
      this.appliedPlan = plan
      plan.applyChanges(this.appliedModel)
    }
    return this.appliedModel
  }

  addCurrentSnapshot = (model: Model) => {
    if (model.snapshots.length > 0 && isToday(model.snapshots[0].date)) {
      // Don't add a current snapshot if the latest snapshot is for today.
      return model.snapshots[0]
    }

    const today = startOfDay(new Date())
    let currentSnapshot = model.snapshots.find((s: Snapshot) => s.isCurrent)
    const details = this.buildSnapshotDetails(model, today)

    if (!currentSnapshot) {
      currentSnapshot = new Snapshot({
        id: "current",
        accountId: model!.accountId,
        userId: model!.userId,
        modelId: model!.id,
        date: today,
        details: details
      })
      // this.updateModelItem("snapshots", currentSnapshot.id, undefined, currentSnapshot, Model.sortSnapshots)
      model.snapshots = [currentSnapshot, ...model.snapshots]
    } else {
      currentSnapshot.date = today
      currentSnapshot.details = details
    }

    return currentSnapshot
  }

  buildSnapshotDetails = (model: Model, date: Date) => {
    let details: ISnapshotDetail[] = []
    const isoDate = getISODateFromDate(date)

    model.assets.forEach((a: Asset) => {
      if (a.start <= isoDate) {
        details.push({id: a.id, typename: "Asset", balance: a.balance})
      }
    })
    model.liabilities.forEach((l: Liability) => {
      if (l.start <= isoDate) {
        details.push({id: l.id, typename: "Liability", balance: l.balance})
      }
    })
    return details
  }

  getCurrentPlan = async () => {
    if (this.currentModel && (!this.currentPlan || this.currentPlan.modelId !== this.currentModel.id)) {
      let planId: string | undefined

      if (this.userStore.isAdvisorOrAdmin) {
        planId = this.currentModel.getSetting(ModelSetting.AdvisorPlanId)
      } else {
        planId = this.currentModel.getSetting(ModelSetting.UserPlanId)
      }

      if (!planId && this.currentUser && this.currentUser.currentPlanId) {
        planId = this.currentUser.currentPlanId
      }
      if (planId && this.currentModel.plans.length > 0) {
        this.currentPlan = this.currentModel.plans.find((p: Plan) => p.id === planId)
      }
      if (!this.currentPlan && this.userStore.isAdvisorOrAdmin) {
        // Look for latest plan
        const plan = this.currentModel.plans.reduce((latest: Plan, current: Plan) => (current.updatedAt > latest.updatedAt) ? current : latest, this.currentModel.plans[0])
        if (plan) {
          this.currentPlan = plan
        }
      }
      if (!this.currentPlan && this.currentModel.persons.length > 0) {
        // Get the default plan (create if necessary
        this.currentPlan = await this.initPlans(this.currentModel)
      }
    } else {
      if (this.currentModel && this.currentPlan) {
        this.currentPlan = this.currentModel.plans.find((p: Plan) => p.id === this.currentPlan!.id)
      }
    }
    return this.currentPlan
  }

  setCurrentPlan = (plan: Plan) => {
    this.currentPlan = plan
    if (this.currentModel) {
      if (this.userStore.isAdvisorOrAdmin) {
        if (this.currentModel.getSetting(ModelSetting.AdvisorPlanId) !== plan.id) {
          this.currentModel.setSetting(ModelSetting.AdvisorPlanId, plan.id)
          this.saveSettings(this.currentModel)
        }
      } else {
        if (this.currentModel.getSetting(ModelSetting.UserPlanId) !== plan.id) {
          this.currentModel.setSetting(ModelSetting.UserPlanId, plan.id)
          this.saveSettings(this.currentModel)
        }
      }
    }
    // Deprecated: this.currentUser!.currentPlanId = plan.id
    this.updatedAt = plan.updatedAt
  }

  getAppliedPlan = () => {
    return this.appliedPlan
  }

  newModel = async (name: string, description?: string, baseModel?: Model, advisorCreated: boolean = false, clientAccess: ClientAccess = ClientAccess.Owner) => {
    let model: Model | undefined

    if (!baseModel && this.currentUser) {
      model = await this.createModel({
        userId: this.currentUser!.id,
        accountId: this.currentUser.accountId,
        name: name,
        description: description,
        startedAt: getISODateToday(),
        advisorCreated: advisorCreated,
        clientAccess: clientAccess
      })

      if (model) {
        model = await this.initModel(model)
      }
    } else if (baseModel && this.currentUser) {
      // Copy model
      model = await this.createModel({
        userId: baseModel.userId,
        accountId: baseModel.accountId,
        name: name ? name : `${baseModel.name} 2`, // TODO: Generate unique number
        description: description,
        startedAt: baseModel.startedAt,
        advisorCreated: advisorCreated,
        clientAccess: clientAccess
      })

      if (model) {
        this.currentModel = model
        // Persons
        const personPromises: Promise<Person | undefined>[] = []
        baseModel.persons.forEach((p: Person) => {
          if (p.id !== baseModel!.jointId) {
            personPromises.push(this.createPerson({
              userId: p.userId,
              accountId: p.accountId,
              modelId: model!.id,
              nickname: p.nickname,
              gender: p.gender,
              maritalStatus: p.maritalStatus,
              birthDate: p.birthDate,
              hereditaryAdjust: p.hereditaryAdjust,
              lifeExpectancy: p.lifeExpectancy,
              retireDate: p.retireDate,
              state: p.state
            }))
          }
        })
        const persons = await Promise.all(personPromises)
        if (persons.length > 1) {
          persons.push(model.createJointPerson())
        }
        const personMap = new Map<string, string | undefined>()
        for (let i = 0; i < baseModel.persons.length; i++) {
          personMap.set(baseModel.persons[i].id, persons[i]?.id)
        }

        // Plans
        const planPromises: Promise<Plan | undefined>[] = []
        baseModel.plans.forEach((p: Plan) => {
          planPromises.push(this.createPlan({
            userId: p.userId,
            accountId: p.accountId,
            modelId: model!.id,
            name: p.name,
            description: p.description
          }))
        })
        const plans = await Promise.all(planPromises)
        const planMap = new Map<string, string | undefined>()
        for (let i = 0; i < baseModel.plans.length; i++) {
          planMap.set(baseModel.plans[i].id, plans[i]?.id)
        }

        // Assets
        const assetPromises: Promise<Asset | undefined>[] = []
        baseModel.assets.forEach((a: Asset) => {
          assetPromises.push(this.createAsset({
            userId: a.userId,
            accountId: a.accountId,
            modelId: model!.id,
            assetCategory: a.assetCategory,
            assetType: a.assetType,
            description: a.description,
            risk: a.risk,
            balance: a.balance,
            balanceDate: getISODateFromDate(a.balanceDate),
            returnRate: a.returnRate,
            rateLock: a.rateLock,
            withdrawalOrder: a.withdrawalOrder,
            originalOwnerBirthYear: a.originalOwnerBirthYear,
            start: a.start,
            end: a.end,
            sortOrder: a.sortOrder,
            ownerId: personMap.get(a.ownerId)
          }))
        })
        const assets = await Promise.all(assetPromises)
        const assetMap = new Map<string, string | undefined>()
        for (let i = 0; i < baseModel.assets.length; i++) {
          assetMap.set(baseModel.assets[i].id, assets[i]?.id)
        }

        // AssetConversions
        const assetConversionPromises: Promise<AssetConversion | undefined>[] = []
        baseModel.assetConversions.forEach((a: AssetConversion) => {
          assetConversionPromises.push(this.createAssetConversion({
            userId: a.userId,
            accountId: a.accountId,
            modelId: model!.id,
            description: a.description,
            srcAssetId: a.srcAssetId,
            dstAssetId: a.dstAssetId,
            year: a.year,
            amount: a.amount,
            sortOrder: a.sortOrder,
          }))
        })
        const assetConversions = await Promise.all(assetConversionPromises)
        const assetConversionMap = new Map<string, string | undefined>()
        for (let i = 0; i < baseModel.assetConversions.length; i++) {
          assetConversionMap.set(baseModel.assetConversions[i].id, assetConversions[i]?.id)
        }

        // Liabilities
        const liabilityPromises: Promise<Liability | undefined>[] = []
        baseModel.liabilities.forEach((l: Liability) => {
          liabilityPromises.push(this.createLiability({
            userId: l.userId,
            accountId: l.accountId,
            modelId: model!.id,
            description: l.description,
            balance: l.balance,
            balanceDate: getISODateFromDate(l.balanceDate),
            payoffDate: l.payoffDate,
            ownerId: personMap.get(l.ownerId),
            sortOrder: l.sortOrder,
            start: l.start,
            end: l.end
          }))
        })
        const liabilities = await Promise.all(liabilityPromises)
        const liabilityMap = new Map<string, string | undefined>()
        for (let i = 0; i < baseModel.liabilities.length; i++) {
          liabilityMap.set(baseModel.liabilities[i].id, liabilities[i]?.id)
        }

        // Incomes
        const incomePromises: Promise<Income | undefined>[] = []
        baseModel.incomes.forEach((i: Income) => {
          incomePromises.push(this.createIncome({
            userId: i.userId,
            accountId: i.accountId,
            modelId: model!.id,
            incomeType: i.incomeType,
            description: i.description,
            amount: i.amount,
            schedule: JSON.stringify(i.schedule),
            infLock: i.infLock,
            annualInf: i.annualInf,
            start: i.start,
            end: i.end,
            sortOrder: i.sortOrder,
            ownerId: personMap.get(i.ownerId),
            survivorPercent: i.survivorPercent
          }))
        })
        await Promise.all(incomePromises)

        // Deductions
        const deductionPromises: Promise<Deduction | undefined>[] = []
        baseModel.deductions.forEach((d: Deduction) => {
          deductionPromises.push(this.createDeduction({
            userId: d.userId,
            accountId: d.accountId,
            modelId: model!.id,
            description: d.description,
            amount: d.amount,
            schedule: JSON.stringify(d.schedule),
            infLock: d.infLock,
            annualInf: d.annualInf,
            start: d.start,
            end: d.end,
            sortOrder: d.sortOrder,
            assetId: assetMap.get(d.assetId)
          }))
        })
        await Promise.all(deductionPromises)

        // Taxes
        const taxPromises: Promise<Tax | undefined>[] = []
        baseModel.taxes.forEach((t: Tax) => {
          taxPromises.push(this.createTax({
            userId: t.userId,
            accountId: t.accountId,
            modelId: model!.id,
            taxType: t.taxType,
            description: t.description,
            amount: t.amount,
            schedule: JSON.stringify(t.schedule),
            infLock: t.infLock,
            annualInf: t.annualInf,
            start: t.start,
            end: t.end,
            sortOrder: t.sortOrder,
            ownerId: personMap.get(t.ownerId)
          }))
        })
        await Promise.all(taxPromises)

        // Expenses
        const expensePromises: Promise<Expense | undefined>[] = []
        baseModel.expenses.forEach((e: Expense) => {
          expensePromises.push(this.createExpense({
            userId: e.userId,
            accountId: e.accountId,
            modelId: model!.id,
            expenseCategory: e.expenseCategory,
            description: e.description,
            amount: e.amount,
            schedule: JSON.stringify(e.schedule),
            infLock: e.infLock,
            annualInf: e.annualInf,
            start: e.start,
            end: e.end,
            discretionary: e.discretionary,
            sortOrder: e.sortOrder,
            ownerId: personMap.get(e.ownerId),
            assetId: assetMap.get(e.assetId),
            liabilityId: liabilityMap.get(e.liabilityId)
          }))
        })
        await Promise.all(expensePromises)

        // Snapshots
        const snapshotPromises: Promise<Snapshot | undefined>[] = []
        baseModel.snapshots.forEach((s: Snapshot) => {
          const details: ISnapshotDetail[] = []
          s.details.forEach((d: ISnapshotDetail) => {
            const id = assetMap.get(d.id) ?? liabilityMap.get(d.id)
            if (id) {
              details.push({id: id, typename: d.typename, balance: d.balance})
            }
          })
          snapshotPromises.push(this.createSnapshot({
            userId: s.userId,
            accountId: s.accountId,
            modelId: model!.id,
            date: getISODateFromDate(s.date),
            description: s.description,
            details: JSON.stringify(details)
          }))
        })
        await Promise.all(snapshotPromises)

        // TaxValues
        const taxValuePromises: Promise<TaxValue | undefined>[] = []
        baseModel.taxValues.forEach((t: TaxValue) => {
          taxValuePromises.push(this.createTaxValue({
            userId: t.userId,
            accountId: t.accountId,
            modelId: model!.id,
            year: t.year,
            key: t.key,
            value: t.value ?? 0
          }))
        })
        await Promise.all(taxValuePromises)

        // PlanChanges
        const planChangePromises: Promise<PlanChange | undefined>[] = []
        baseModel.plans.forEach((p: Plan) => {
          p.changes.forEach((c: PlanChange) => {
            const input : APITypes.CreatePlanChangeInput = {
              userId: c.userId,
              accountId: c.accountId,
              modelId: model!.id,
              planId: planMap.get(c.planId) ?? "",
              changeType: c.changeType,
              name: c.name,
              description: c.description,
              enabled: c.enabled,
              details: c.details
            }
            if (c.changeType === PlanChangeType.ConversionStrategy) {
              // Map assets in conversions
              const change = c as ConversionStrategyChange
              const conversions = change.conversions.map((conv: AssetConversion) => {
                return new AssetConversion({
                  ...conv,
                  id: createUUID(),
                  modelId: model!.id,
                  srcAssetId: assetMap.get(conv.srcAssetId),
                  dstAssetId: assetMap.get(conv.dstAssetId)
                })
              })
              input.details = JSON.stringify(conversions)
            } else if (c.changeType === PlanChangeType.GrowthStrategy) {
              // Map surplusAssetId
              const change = c as GrowthStrategyChange
              if (change.growthStrategy.surplusAssetId) {
                change.growthStrategy.surplusAssetId = assetMap.get(change.growthStrategy.surplusAssetId)
                input.details = JSON.stringify(change.growthStrategy)
              }
            } else if (c.changeType === PlanChangeType.WithdrawalStrategy) {
              // Map assets
              const change = c as WithdrawalStrategyChange
              const withdrawalStrategy = change.withdrawalStrategy
              withdrawalStrategy.withdrawalGroups.forEach((group: IWithdrawalGroup) => {
                group.assetIds = group.assetIds.map((id: string) => assetMap.get(id) ?? "")
              })
              input.details = JSON.stringify({
                withdrawalStrategyType: withdrawalStrategy.withdrawalStrategyType,
                withdrawalGroups: withdrawalStrategy.withdrawalGroups
              }, (key, value) => {
                if (key === "assets") {
                  return undefined
                } else {
                  return value
                }
              })
            }
            planChangePromises.push(this.createPlanChange(input))
          })
        })

        await Promise.all(planChangePromises)

        // Settings
        const settings = {...baseModel.settings}

        let value = settings[ModelSetting.UserPlanId]
        if (value) {
          settings[ModelSetting.UserPlanId] = planMap.get(value)
        }
        value = settings[ModelSetting.AdvisorPlanId]
        if (value) {
          settings[ModelSetting.AdvisorPlanId] = planMap.get(value)
        }
        const update: UpdateModelInput = {
          id: model.id,
          settings: JSON.stringify(settings)
        }
        await this.updateModel(update)
      }

      // Reload the full model
      model = await this.getModel(model!.id, true)
      if (model) {
        this.currentModel = model
      }
    }

    // if (model) {
    //   this.models.push(model)
    // }
    return model
  }

  findModel = (id: string) => {
    return this.models.find((m: Model) => m.id === id)
  }

  findModelIndex = (id: string) => {
    return this.models.findIndex((m: Model) => m.id === id)
  }

  getModel = async (modelId: string, forceLoad: boolean = false, clone: boolean = false, noLoad: boolean = false): Promise<Model | undefined> => {
    console.debug(`modelStore.getModel(${modelId}, ${forceLoad}, ${clone}, ${noLoad})`)

    let model = this.findModel(modelId)
    if (model && noLoad) {
      return model
    }

    let data
    if (!model || model.persons.length === 0 || forceLoad) {
      console.debug(`rbcAPI.getModel(${modelId})`)
      data = await this.rbcAPI.getModel(modelId)
      if (data) {
        model = new Model(data)
        if (forceLoad) {
          console.debug(`Force loaded "${model.name}"`)
        }
        let index = this.modelData.items.findIndex((item: any) => item.id === modelId)
        if (index >= 0) {
          this.modelData.items[index] = data
        } else {
          this.modelData.items.push(data)
        }

        index = this.findModelIndex(modelId)
        if (index >= 0) {
          this.models[index] = model
        } else {
          this.models.push(model)
        }
        await this.initModel(model)
      }
    } else {

    }

    if (model && clone) {
      if (!data) {
        data = this.modelData.items.find((item: any) => item.id === modelId)
      }
      if (data) {
        model = new Model(data)
      }
    }

    return model
  }

  async createModel(input: APITypes.CreateModelInput) {
    let model
    const data = await this.rbcAPI!.createModel(input)
    if (data) {
      model = new Model(data)
      if (this.currentUser && model.userId === this.currentUser.id) {
        this.models.push(model)
        this.models.sort((a: Model, b: Model) => a.name.localeCompare(b.name))
        this.modelData.items.push(data)
        this.modelData.items.sort((a: Model, b: Model) => a.name.localeCompare(b.name))
      }
    }
    return model
  }

  async updateModel(input: APITypes.UpdateModelInput) {
    const data = await this.rbcAPI!.updateModel(input)
    if (data) {
      // Update cached data
      const existingData = this.modelData.items.find((item: any) => item.id === input.id)
      if (existingData) {
        this.updateModelData(input, data, existingData)
      }

      const updatedModel = new Model(data)
      const existingModel = this.findModel(updatedModel.id)
      if (existingModel) {
        const sort = existingModel.name !== updatedModel.name
        existingModel.update(input, data)
        if (sort) {
          this.models.sort((a: Model, b: Model) => a.name.localeCompare(b.name))
        }
        this.models = [...this.models]

        // Update appliedModel
        // if (this.appliedModel && this.appliedModel.id === updatedModel.id) {
        //   this.appliedModel.update(input, data)
        //   return this.appliedModel
        // } else {
        //   return existingModel
        // }
        return existingModel
      } else {
        return updatedModel
      }
    } else {
      return undefined
    }
  }

  updateModelData(input: UpdateModelInput, data: any, existingData: any) {
    if (input.updatedAt) {
      existingData.updatedAt = data.updatedAt
    }
    if (input.name !== undefined) {
      existingData.name = data.name
    }
    if (input.description !== undefined) {
      existingData.description = data.description
    }
    if (input.advisorCreated !== undefined) {
      existingData.advisorCreated = data.advisorCreated
    }
    if (input.clientAccess !== undefined) {
      existingData.clientAccess = data.clientAccess
    }
    if (input.settings !== undefined) {
      existingData.settings = data.settings
    }
  }

  canView = (model: Model): boolean => {
    return (this.userStore?.isAdvisor || (model.clientAccess && model.clientAccess !== ClientAccess.None))
  }

  canEdit = (model: Model): boolean => {
    return (this.userStore?.isAdvisor || (model.clientAccess === ClientAccess.Edit || model.clientAccess === ClientAccess.Owner))
  }

  canDelete = (model: Model): boolean => {
    return (this.userStore?.isAdvisor || model.clientAccess === ClientAccess.Owner)
  }

  deleteModel = async (modelId: string, cascade?: boolean): Promise<Model | undefined> => {
    if (!cascade) {
      const data = await this.rbcAPI.deleteModel(modelId)
      if (data) {
        return new Model(data)
      } else {
        return undefined
      }
    }

    // Cascading delete
    const model = await this.getModel(modelId, true)
    if (model) {
      // Persons
      const personPromises: Promise<Person | undefined>[] = []
      model.persons.forEach((p: Person) => {
        if (p.id !== model.jointId) {
          personPromises.push(this.deletePerson(p.id))
        }
      })
      await Promise.all(personPromises)

      // Plans
      // PlanChanges
      const planPromises: Promise<Plan | undefined>[] = []
      const planChangePromises: Promise<PlanChange | undefined>[] = []
      model.plans.forEach((p: Plan) => {
        p.changes.forEach((p: PlanChange) => {
          planChangePromises.push(this.deletePlanChange(p.id))
        })
        planPromises.push(this.deletePlan(p.id))
      })
      await Promise.all(planPromises)
      await Promise.all(planChangePromises)

      // Assets
      const assetPromises: Promise<Asset | undefined>[] = []
      model.assets.forEach((p: Asset) => {
        assetPromises.push(this.deleteAsset(p.id))
      })
      await Promise.all(assetPromises)

      // AssetConversions
      const assetConversionPromises: Promise<AssetConversion | undefined>[] = []
      model.assetConversions.forEach((p: AssetConversion) => {
        assetConversionPromises.push(this.deleteAssetConversion(p.id))
      })
      await Promise.all(assetConversionPromises)

      // Liabilities
      const liabilityPromises: Promise<Liability | undefined>[] = []
      model.liabilities.forEach((p: Liability) => {
        liabilityPromises.push(this.deleteLiability(p.id))
      })
      await Promise.all(liabilityPromises)

      // Incomes
      const incomePromises: Promise<Income | undefined>[] = []
      model.incomes.forEach((p: Income) => {
        incomePromises.push(this.deleteIncome(p.id))
      })
      await Promise.all(incomePromises)

      // Deductions
      const deductionPromises: Promise<Deduction | undefined>[] = []
      model.deductions.forEach((p: Deduction) => {
        deductionPromises.push(this.deleteDeduction(p.id))
      })
      await Promise.all(deductionPromises)

      // Taxes
      const taxPromises: Promise<Tax | undefined>[] = []
      model.taxes.forEach((p: Tax) => {
        taxPromises.push(this.deleteTax(p.id))
      })
      await Promise.all(taxPromises)

      // Incomes
      const expensePromises: Promise<Expense | undefined>[] = []
      model.expenses.forEach((p: Expense) => {
        expensePromises.push(this.deleteExpense(p.id))
      })
      await Promise.all(expensePromises)

      // Snapshots
      const snapshotPromises: Promise<Snapshot | undefined>[] = []
      model.snapshots.forEach((p: Snapshot) => {
        snapshotPromises.push(this.deleteSnapshot(p.id))
      })
      await Promise.all(snapshotPromises)

      // TaxValues
      const taxValuePromises: Promise<TaxValue | undefined>[] = []
      model.taxValues.forEach((p: TaxValue) => {
        taxValuePromises.push(this.deleteTaxValue(p.id))
      })
      await Promise.all(taxValuePromises)

      // Model
      await this.deleteModel(model.id)

      let index = this.findModelIndex(modelId)
      if (index >= 0) {
        this.models.splice(index, 1)
        this.models = [...this.models]
      }
      index = this.modelData.items.findIndex((item: any) => item.id === modelId)
      if (index >= 0) {
        this.modelData.items.splice(index, 1)
      }
    }

  }

  // Cached model update/delete methods

  updateModelItem = (list: string, id: string, data: object | undefined, update: IModelItem, sortFn?: (array: any[]) => void) => {
    const updatedAt = update["updatedAt"] ?? getISODateTime()
    if (this.currentModel) {
      let array = this.currentModel[list]
      if (array) {
        const index = array.findIndex((item: any) => item["id"] === id)
        if (index >= 0) {
          array[index] = update
        } else {
          array.push(update)
        }
        if (sortFn) {
          sortFn(array)
        }
        if (id !== "current") {
          // Don't update when adding current snapshot
          this.currentModel.updatedAt = updatedAt
        }
      }
      if (data) {
        let modelData = this.modelData.items.find((item: any) => item.id === this.currentModel!.id)
        if (modelData) {
          array = modelData[list]
          if (array) {
            const items = array["items"]
            if (items) {
              const index = items.findIndex((item: any) => item["id"] === id)
              if (index >= 0) {
                items[index] = data
              } else {
                items.push(data)
              }
              modelData.updatedAt = updatedAt
            }
          }
        }
      }
    }

    // if (this.appliedModel) {
    //   const array = this.appliedModel[list]
    //   if (array) {
    //     const index = array.findIndex((item: any) => item["id"] === id)
    //     if (index >= 0) {
    //       array[index] = update
    //     } else {
    //       array.push(update)
    //     }
    //     if (sortFn) {
    //       sortFn(array)
    //     }
    //     this.appliedModel.updatedAt = updatedAt
    //   }
    // }
    this.updatedAt = updatedAt
  }

  deleteModelItem = (list: string, id: string) => {
    const updatedAt = getISODateTime()

    if (this.currentModel) {
      let array = this.currentModel[list]
      if (array) {
        const index = array.findIndex((item: any) => item["id"] === id)
        if (index >= 0) {
          array.splice(index, 1)
          this.currentModel.updatedAt = updatedAt
        }
      }
      let modelData = this.modelData.items.find((item: any) => item.id === this.currentModel!.id)
      if (modelData) {
        array = modelData[list]
        if (array) {
          const items = array["items"]
          if (items) {
            const index = items.findIndex((item: any) => item["id"] === id)
            if (index >= 0) {
              items.splice(index, 1)
              modelData["updatedAt"] = updatedAt
            }
          }
        }
      }
    }

    // if (this.appliedModel) {
    //   const array = this.appliedModel[list]
    //   if (array) {
    //     const index = array.findIndex((item: any) => item["id"] === id)
    //     if (index >= 0) {
    //       array.splice(index, 1)
    //       this.appliedModel.updatedAt = updatedAt
    //     }
    //   }
    // }
  }

  // Setting methods
  saveSettings = async (model: Model) => {
    const input: UpdateModelInput = {
      id: model.id,
      settings: JSON.stringify(model.settings)
    }
    const update = await this.updateModel(input)

    return update
  }

  // Person methods

  getPerson = async (personId: string): Promise<Person | undefined> => {
    const data = await this.rbcAPI.getPerson(personId)
    if (data) {
      return new Person(data)
    } else {
      return undefined
    }
  }

  async createPerson(input: APITypes.CreatePersonInput) {
    const data = await this.rbcAPI!.createPerson(input)
    if (data) {
      const person = new Person(data)
      this.updateModelItem("persons", person.id, data, person, Model.sortPersons)
      // if (this.currentModel && this.currentModel.persons.length > 1) {
      //   // Create spouse connections and add joint person
      //   this.currentModel.persons[0].spouse = this.currentModel.persons[1]
      //   this.currentModel.persons[1].spouse = this.currentModel.persons[0]
      //   this.currentModel.persons[2] = this.currentModel.createJointPerson()
      // }
      return person
    } else {
      return undefined
    }
  }

  async updatePerson(input: APITypes.UpdatePersonInput) {
    const data = await this.rbcAPI!.updatePerson(input)
    if (data) {
      const updatedPerson = new Person(data)
      this.updateModelItem("persons", updatedPerson.id, data, updatedPerson, Model.sortPersons)
      // if (this.currentModel && this.currentModel.persons.length > 1) {
      //   // Restore spouse connections and update joint person
      //   this.currentModel.persons[0].spouse = this.currentModel.persons[1]
      //   this.currentModel.persons[1].spouse = this.currentModel.persons[0]
      //   this.currentModel.persons[2] = this.currentModel.createJointPerson()
      // }

      return updatedPerson
    } else {
      return undefined
    }
  }

  deletePerson = async (personId: string): Promise<Person | undefined> => {
    const data = await this.rbcAPI.deletePerson(personId)
    if (data) {
      this.deleteModelItem("persons", personId)
      return new Person(data)
    } else {
      return undefined
    }
  }

  findOwner(id: string): Person | undefined {
    let owner

    const model = this.currentModel
    if (model) {
      if (!id || id === model.jointId) {
        owner = model.persons.find((p: Person) => p.id === model.jointId)
      } else {
        owner = model.persons.find((p: Person) => p.id === id)
      }
      if (!owner && model.persons.length > 0) {
        owner = model.persons[0]
      }

    }
    return owner
  }

// Asset methods

  getAsset = async (assetId: string): Promise<Asset | undefined> => {
    const data = await this.rbcAPI.getAsset(assetId)
    if (data) {
      const asset = new Asset(data)
      asset.model = this.findModel(asset.modelId)
      asset.owner = this.findOwner(asset.ownerId)
      return asset
    } else {
      return undefined
    }
  }

  async createAsset(input: APITypes.CreateAssetInput) {
    const data = await this.rbcAPI!.createAsset(input)
    if (data) {
      const asset = new Asset(data)
      asset.model = this.findModel(asset.modelId)
      asset.owner = this.findOwner(asset.ownerId)
      this.updateModelItem("assets", asset.id, data, asset)
      return asset
    } else {
      return undefined
    }
  }

  async updateAsset(input: APITypes.UpdateAssetInput) {
    const data = await this.rbcAPI!.updateAsset(input)
    if (data) {
      const asset = new Asset(data)
      asset.model = this.findModel(asset.modelId)
      asset.owner = this.findOwner(asset.ownerId)
      this.updateModelItem("assets", asset.id, data, asset, Model.sortAssets)
      return asset
    } else {
      return undefined
    }
  }

  deleteAsset = async (assetId: string): Promise<Asset | undefined> => {
    const data = await this.rbcAPI.deleteAsset(assetId)
    if (data) {
      this.deleteModelItem("assets", assetId)
      return new Asset(data)
    } else {
      return undefined
    }
  }

  async reorderAssets(assets: Asset[]) {
    const promises: Promise<Asset | undefined>[] = []
    let sortOrder = ModelStore.orderInterval
    assets.forEach((asset: Asset) => {
      const input: UpdateAssetInput = {
        id: asset.id,
        accountId: asset.accountId,
        sortOrder: sortOrder
      }
      promises.push(this.updateAsset(input))
      sortOrder += ModelStore.orderInterval
    })
    const results = await Promise.all(promises)
    return results.filter((item: Asset | undefined) => item !== undefined) as Asset[]
  }

// AssetConversion methods

  getAssetConversion = async (assetConversionId: string): Promise<AssetConversion | undefined> => {
    const data = await this.rbcAPI.getAssetConversion(assetConversionId)
    if (data) {
      const assetConversion = new AssetConversion(data)
      assetConversion.model = this.findModel(assetConversion.modelId)
      if (assetConversion.model) {
        assetConversion.srcAsset = assetConversion.model.getAssetById(assetConversion.srcAssetId)
        assetConversion.dstAsset = assetConversion.model.getAssetById(assetConversion.dstAssetId)
      }
      return assetConversion
    } else {
      return undefined
    }
  }

  async createAssetConversion(input: APITypes.CreateAssetConversionInput) {
    const data = await this.rbcAPI!.createAssetConversion(input)
    if (data) {
      const assetConversion = new AssetConversion(data)
      assetConversion.model = this.findModel(assetConversion.modelId)
      if (assetConversion.model) {
        assetConversion.srcAsset = assetConversion.model.getAssetById(assetConversion.srcAssetId)
        assetConversion.dstAsset = assetConversion.model.getAssetById(assetConversion.dstAssetId)
      }
      this.updateModelItem("assetConversions", assetConversion.id, data, assetConversion)
      return assetConversion
    } else {
      return undefined
    }
  }

  async updateAssetConversion(input: APITypes.UpdateAssetConversionInput) {
    const data = await this.rbcAPI!.updateAssetConversion(input)
    if (data) {
      const assetConversion = new AssetConversion(data)
      assetConversion.model = this.findModel(assetConversion.modelId)
      if (assetConversion.model) {
        assetConversion.srcAsset = assetConversion.model.getAssetById(assetConversion.srcAssetId)
        assetConversion.dstAsset = assetConversion.model.getAssetById(assetConversion.dstAssetId)
      }
      this.updateModelItem("assetConversions", assetConversion.id, data, assetConversion, Model.sortAssetConversions)
      return assetConversion
    } else {
      return undefined
    }
  }

  deleteAssetConversion = async (assetConversionId: string): Promise<AssetConversion | undefined> => {
    const data = await this.rbcAPI.deleteAssetConversion(assetConversionId)
    if (data) {
      this.deleteModelItem("assetConversions", assetConversionId)
      return new AssetConversion(data)
    } else {
      return undefined
    }
  }

  async reorderAssetConversions(assetConversions: AssetConversion[]) {
    const promises: Promise<AssetConversion | undefined>[] = []
    let sortOrder = ModelStore.orderInterval
    assetConversions.forEach((assetConversion: AssetConversion) => {
      const input: UpdateAssetConversionInput = {
        id: assetConversion.id,
        accountId: assetConversion.accountId,
        sortOrder: sortOrder
      }
      promises.push(this.updateAssetConversion(input))
      sortOrder += ModelStore.orderInterval
    })
    const results = await Promise.all(promises)
    return results.filter((item: AssetConversion | undefined) => item !== undefined) as AssetConversion[]
  }

  // Liability methods

  getLiability = async (liabilityId: string): Promise<Liability | undefined> => {
    const data = await this.rbcAPI.getLiability(liabilityId)
    if (data) {
      const liability = new Liability(data)
      liability.model = this.findModel(liability.modelId)
      liability.owner = this.findOwner(liability.ownerId)
      return liability
    } else {
      return undefined
    }
  }

  async createLiability(input: APITypes.CreateLiabilityInput) {
    const data = await this.rbcAPI!.createLiability(input)
    if (data) {
      const liability = new Liability(data)
      liability.model = this.findModel(liability.modelId)
      liability.owner = this.findOwner(liability.ownerId)
      this.updateModelItem("liabilities", liability.id, data, liability)
      return liability
    } else {
      return undefined
    }
  }

  async updateLiability(input: APITypes.UpdateLiabilityInput) {
    const data = await this.rbcAPI!.updateLiability(input)
    if (data) {
      const liability = new Liability(data)
      liability.model = this.findModel(liability.modelId)
      liability.owner = this.findOwner(liability.ownerId)
      this.updateModelItem("liabilities", liability.id, data, liability)
      return liability
    } else {
      return undefined
    }
  }

  deleteLiability = async (liabilityId: string): Promise<Liability | undefined> => {
    const data = await this.rbcAPI.deleteLiability(liabilityId)
    if (data) {
      this.deleteModelItem("liabilities", liabilityId)
      return new Liability(data)
    } else {
      return undefined
    }
  }

  async reorderLiabilities(liabilities: Liability[]) {
    const promises: Promise<Liability | undefined>[] = []
    let sortOrder = ModelStore.orderInterval
    liabilities.forEach((liability: Liability) => {
      const input: UpdateLiabilityInput = {
        id: liability.id,
        accountId: liability.accountId,
        sortOrder: sortOrder
      }
      promises.push(this.updateLiability(input))
      sortOrder += ModelStore.orderInterval
    })
    const results = await Promise.all(promises)
    return results.filter((item: Liability | undefined) => item !== undefined) as Liability[]
  }

// Snapshot methods

  getSnapshotByDate = async (modelId: string, date: string) => {
    const dateFilter: APITypes.ModelStringKeyConditionInput = {
      eq: date
    }
    const snapshots = await this.listSnapshotsByModel(modelId, dateFilter)
    if (snapshots && snapshots.length > 0) {
      return snapshots[0]
    } else {
      return undefined
    }
  }

  listSnapshotsByModel = async (modelId: string, dateFilter: APITypes.ModelStringKeyConditionInput, filter?: APITypes.ModelSnapshotFilterInput): Promise<Snapshot[]> => {
    let snapshots: Snapshot[] = []

    let data
    let nextToken: string | undefined

    do {
      data = await this.rbcAPI.listSnapshotsByModel(modelId, dateFilter, filter, 1000, nextToken)
      if (data && data.items) {
        data.items.forEach((item: any) => {
          snapshots.push(new Snapshot(item))
        })
        nextToken = data.nextToken ?? undefined
      }
    } while (data && nextToken)

    return snapshots
  }

  getSnapshot = async (snapshotId: string): Promise<Snapshot | undefined> => {
    const data = await this.rbcAPI.getSnapshot(snapshotId)
    if (data) {
      return new Snapshot(data)
    } else {
      return undefined
    }
  }

  async createSnapshot(input: APITypes.CreateSnapshotInput) {
    if (input.id === "current") {
      throw new Error("Cannot save current snapshot")
    }
    const data = await this.rbcAPI!.createSnapshot(input)
    if (data) {
      const snapshot = new Snapshot(data)
      this.updateModelItem("snapshots", snapshot.id, data, snapshot, Model.sortSnapshots)
      if (isToday(snapshot.date)) {
        // Delete current snapshot if present
        this.deleteModelItem("snapshots", "current")
      }
      return snapshot
    } else {
      return undefined
    }
  }

  async updateSnapshot(input: APITypes.UpdateSnapshotInput) {
    if (input.id === "current") {
      return new Snapshot(input)
    }
    const data = await this.rbcAPI!.updateSnapshot(input)
    if (data) {
      const updatedSnapshot = new Snapshot(data)
      this.updateModelItem("snapshots", updatedSnapshot.id, data, updatedSnapshot, Model.sortSnapshots)
      return updatedSnapshot
    } else {
      return undefined
    }
  }

  deleteSnapshot = async (snapshotId: string): Promise<Snapshot | undefined> => {
    if (snapshotId === "current") {
      throw new Error("Cannot delete current snapshot")
    }
    const data = await this.rbcAPI.deleteSnapshot(snapshotId)
    if (data) {
      data.updatedAt = getISODateTime() // Need later date to trigger applied model update
      this.deleteModelItem("snapshots", snapshotId)
      return new Snapshot(data)
    } else {
      return undefined
    }
  }

  updateSnapshotDetail = async (snapshot: Snapshot, update: ISnapshotDetail): Promise<Snapshot | undefined> => {
    let input: UpdateSnapshotInput = {
      id: snapshot.id
    }
    const index = snapshot.details.findIndex((i: ISnapshotDetail) => i.id === update.id && i.typename === update.typename)
    if (index >= 0) {
      const detail = snapshot.details[index]
      if (detail.balance !== update.balance) {
        detail.balance = update.balance
        input.details = JSON.stringify(snapshot.details)
      }
    } else {
      snapshot.details.push(update)
      input.details = JSON.stringify(snapshot.details)
    }

    if (input.id === "current") {
      return snapshot
    } else if (input.details) {
      return(await this.updateSnapshot(input))
    }

    return undefined
  }

  deleteSnapshotDetail = async (snapshot: Snapshot, item: Asset | Liability): Promise<Snapshot | undefined> => {
    let input: UpdateSnapshotInput = {
      id: snapshot.id
    }
    const typename = item instanceof Asset ? "Asset" : item instanceof Liability ? "Liability" : ""
    const index = snapshot.details.findIndex((i: ISnapshotDetail) => i.id === item.id && i.typename === typename)
    if (index >= 0) {
      snapshot.details.splice(index, 1)
      input.details = JSON.stringify(snapshot.details)
    }

    if (snapshot.id === "current") {
      return snapshot
    } else if (input.details) {
      return(await this.updateSnapshot(input))
    }

    return undefined
  }

// Income methods

  getIncome = async (incomeId: string): Promise<Income | undefined> => {
    const data = await this.rbcAPI.getIncome(incomeId)
    if (data) {
      const income = new Income(data)
      income.model = this.findModel(income.modelId)
      income.owner = this.findOwner(income.ownerId)
      return income
    } else {
      return undefined
    }
  }

  async createIncome(input: APITypes.CreateIncomeInput) {
    const data = await this.rbcAPI!.createIncome(input)
    if (data) {
      const income = new Income(data)
      income.model = this.findModel(income.modelId)
      income.owner = this.findOwner(income.ownerId)
      this.updateModelItem("incomes", income.id, data, income)
      return income
    } else {
      return undefined
    }
  }

  async updateIncome(input: APITypes.UpdateIncomeInput) {
    const data = await this.rbcAPI!.updateIncome(input)
    if (data) {
      const income = new Income(data)
      income.model = this.findModel(income.modelId)
      income.owner = this.findOwner(income.ownerId)
      this.updateModelItem("incomes", income.id, data, income, Model.sortIncomes)
      return income
    } else {
      return undefined
    }
  }

  deleteIncome = async (incomeId: string): Promise<Income | undefined> => {
    const data = await this.rbcAPI.deleteIncome(incomeId)
    if (data) {
      this.deleteModelItem("incomes", incomeId)
      return new Income(data)
    } else {
      return undefined
    }
  }

  async reorderIncomes(incomes: Income[]) {
    const promises: Promise<Income | undefined>[] = []
    let sortOrder = ModelStore.orderInterval
    incomes.forEach((income: Income) => {
      const input: UpdateIncomeInput = {
        id: income.id,
        accountId: income.accountId,
        sortOrder: sortOrder
      }
      promises.push(this.updateIncome(input))
      sortOrder += ModelStore.orderInterval
    })
    const results = await Promise.all(promises)
    return results.filter((item: Income | undefined) => item !== undefined) as Income[]
  }

// Deduction methods

  getDeduction = async (deductionId: string): Promise<Deduction | undefined> => {
    const data = await this.rbcAPI.getDeduction(deductionId)
    if (data) {
      const deduction = new Deduction(data)
      deduction.model = this.findModel(deduction.modelId)
      return deduction
    } else {
      return undefined
    }
  }

  async createDeduction(input: APITypes.CreateDeductionInput) {
    const data = await this.rbcAPI!.createDeduction(input)
    if (data) {
      const deduction = new Deduction(data)
      deduction.model = this.findModel(deduction.modelId)
      deduction.asset = deduction.model?.getAssetById(deduction.assetId)
      this.updateModelItem("deductions", deduction.id, data, deduction)
      return deduction
    } else {
      return undefined
    }
  }

  async updateDeduction(input: APITypes.UpdateDeductionInput) {
    const data = await this.rbcAPI!.updateDeduction(input)
    if (data) {
      const deduction = new Deduction(data)
      deduction.model = this.findModel(deduction.modelId)
      deduction.asset = deduction.model?.getAssetById(deduction.assetId)
      this.updateModelItem("deductions", deduction.id, data, deduction, Model.sortDeductions)
      return deduction
    } else {
      return undefined
    }
  }

  deleteDeduction = async (deductionId: string): Promise<Deduction | undefined> => {
    const data = await this.rbcAPI.deleteDeduction(deductionId)
    if (data) {
      this.deleteModelItem("deductions", deductionId)
      return new Deduction(data)
    } else {
      return undefined
    }
  }

  async reorderDeductions(deductions: Deduction[]) {
    const promises: Promise<Deduction | undefined>[] = []
    let sortOrder = ModelStore.orderInterval
    deductions.forEach((deduction: Deduction) => {
      const input: UpdateDeductionInput = {
        id: deduction.id,
        accountId: deduction.accountId,
        sortOrder: sortOrder
      }
      promises.push(this.updateDeduction(input))
      sortOrder += ModelStore.orderInterval
    })
    const results = await Promise.all(promises)
    return results.filter((item: Deduction | undefined) => item !== undefined) as Deduction[]
  }

// Tax methods

  getTax = async (taxId: string): Promise<Tax | undefined> => {
    const data = await this.rbcAPI.getTax(taxId)
    if (data) {
      const tax = new Tax(data)
      tax.model = this.findModel(tax.modelId)
      tax.owner = this.findOwner(tax.ownerId)
      return tax
    } else {
      return undefined
    }
  }

  async createTax(input: APITypes.CreateTaxInput) {
    const data = await this.rbcAPI!.createTax(input)
    if (data) {
      const tax = new Tax(data)
      tax.model = this.findModel(tax.modelId)
      tax.owner = this.findOwner(tax.ownerId)
      this.updateModelItem("taxes", tax.id, data, tax)
      return tax
    } else {
      return undefined
    }
  }

  async updateTax(input: APITypes.UpdateTaxInput) {
    const data = await this.rbcAPI!.updateTax(input)
    if (data) {
      const tax = new Tax(data)
      tax.model = this.findModel(tax.modelId)
      tax.owner = this.findOwner(tax.ownerId)
      this.updateModelItem("taxes", tax.id, data, tax, Model.sortTaxes)
      return tax
    } else {
      return undefined
    }
  }

  deleteTax = async (taxId: string): Promise<Tax | undefined> => {
    const data = await this.rbcAPI.deleteTax(taxId)
    if (data) {
      this.deleteModelItem("taxes", taxId)
      return new Tax(data)
    } else {
      return undefined
    }
  }

  async reorderTaxes(taxes: Tax[]) {
    const promises: Promise<Tax | undefined>[] = []
    let sortOrder = ModelStore.orderInterval
    taxes.forEach((tax: Tax) => {
      const input: UpdateTaxInput = {
        id: tax.id,
        accountId: tax.accountId,
        sortOrder: sortOrder
      }
      promises.push(this.updateTax(input))
      sortOrder += ModelStore.orderInterval
    })
    const results = await Promise.all(promises)
    return results.filter((item: Tax | undefined) => item !== undefined) as Tax[]
  }

  // Expense methods

  getExpense = async (expenseId: string): Promise<Expense | undefined> => {
    const data = await this.rbcAPI.getExpense(expenseId)
    if (data) {
      const expense = new Expense(data)
      expense.model = this.findModel(expense.modelId)
      expense.owner = this.findOwner(expense.ownerId)
      return expense
    } else {
      return undefined
    }
  }

  async createExpense(input: APITypes.CreateExpenseInput) {
    const data = await this.rbcAPI!.createExpense(input)
    if (data) {
      const expense = new Expense(data)
      expense.model = this.findModel(expense.modelId)
      expense.owner = this.findOwner(expense.ownerId)
      expense.asset = expense.model?.getAssetById(expense.assetId)
      expense.liability = expense.model?.getLiabilityById(expense.liabilityId)
      this.updateModelItem("expenses", expense.id, data, expense)
      return expense
    } else {
      return undefined
    }
  }

  async updateExpense(input: APITypes.UpdateExpenseInput) {
    const data = await this.rbcAPI!.updateExpense(input)
    if (data) {
      const expense = new Expense(data)
      expense.model = this.findModel(expense.modelId)
      expense.owner = this.findOwner(expense.ownerId)
      expense.asset = expense.model?.getAssetById(expense.assetId)
      expense.liability = expense.model?.getLiabilityById(expense.liabilityId)
      this.updateModelItem("expenses", expense.id, data, expense, Model.sortExpenses)
      return expense
    } else {
      return undefined
    }
  }

  deleteExpense = async (expenseId: string): Promise<Expense | undefined> => {
    const data = await this.rbcAPI.deleteExpense(expenseId)
    if (data) {
      this.deleteModelItem("expenses", expenseId)
      return new Expense(data)
    } else {
      return undefined
    }
  }

  async reorderExpenses(expenses: Expense[]) {
    const promises: Promise<Expense | undefined>[] = []
    let sortOrder = ModelStore.orderInterval
    expenses.forEach((expense: Expense) => {
      const input: UpdateExpenseInput = {
        id: expense.id,
        accountId: expense.accountId,
        sortOrder: sortOrder
      }
      promises.push(this.updateExpense(input))
      sortOrder += ModelStore.orderInterval
    })
    const results = await Promise.all(promises)
    return results.filter((item: Expense | undefined) => item !== undefined) as Expense[]
  }

  // TaxValue methods

  getTaxValue = async (taxValueId: string): Promise<TaxValue | undefined> => {
    const data = await this.rbcAPI.getTaxValue(taxValueId)
    if (data) {
      return new TaxValue(data)
    } else {
      return undefined
    }
  }

  async createTaxValue(input: APITypes.CreateTaxValueInput) {
    const data = await this.rbcAPI!.createTaxValue(input)
    if (data) {
      const taxValue = new TaxValue(data)
      this.updateModelItem("taxValues", taxValue.id, data, taxValue)
      return taxValue
    } else {
      return undefined
    }
  }

  async updateTaxValue(input: APITypes.UpdateTaxValueInput) {
    const data = await this.rbcAPI!.updateTaxValue(input)
    if (data) {
      const taxValue = new TaxValue(data)
      this.updateModelItem("taxValues", taxValue.id, data, taxValue)
      return taxValue
    } else {
      return undefined
    }
  }

  deleteTaxValue = async (taxValueId: string): Promise<TaxValue | undefined> => {
    const data = await this.rbcAPI.deleteTaxValue(taxValueId)
    if (data) {
      this.deleteModelItem("taxValues", taxValueId)
      return new TaxValue(data)
    } else {
      return undefined
    }
  }

  // Event methods

  // getEvent = async (eventId: string): Promise<Event | undefined> => {
  //   const data = await this.rbcAPI.getEvent(eventId)
  //   if (data) {
  //     return new Event(data)
  //   } else {
  //     return undefined
  //   }
  // }

  // async createEvent(input: APITypes.CreateEventInput) {
  //   const data = await this.rbcAPI!.createEvent(input)
  //   if (data) {
  //     return new Event(data)
  //   } else {
  //     return undefined
  //   }
  // }
  //
  // async updateEvent(input: APITypes.UpdateEventInput) {
  //   const data = await this.rbcAPI!.updateEvent(input)
  //   if (data) {
  //     const updatedEvent = new Event(data)
  //     return updatedEvent
  //   } else {
  //     return undefined
  //   }
  // }
  //
  // deleteEvent = async (eventId: string): Promise<Event | undefined> => {
  //   const data = await this.rbcAPI.deleteEvent(eventId)
  //   if (data) {
  //     return new Event(data)
  //   } else {
  //     return undefined
  //   }
  // }

  // Plan methods

  listPlansByModel = async (modelId: string, filter?: APITypes.ModelPlanFilterInput): Promise<Plan[]> => {
    let plans: Plan[] = []

    let data
    let nextToken: string | undefined

    do {
      data = await this.rbcAPI.listPlansByModel(modelId, filter, 1000, nextToken)
      if (data && data.items) {
        data.items.forEach((item: any) => {
          const plan = new Plan(item)
          plans.push(plan)
        })
        nextToken = data.nextToken ?? undefined
      }
    } while (data && nextToken)

    Model.sortPlans(plans)
    return plans
  }
  
  getPlan = async (planId: string): Promise<Plan | undefined> => {
    const data = await this.rbcAPI.getPlan(planId)
    if (data) {
      const plan = new Plan(data)
      this.updateModelItem("plans", plan.id, data, plan, Model.sortPlans)
    } else {
      return undefined
    }
  }

  async createPlan(input: APITypes.CreatePlanInput) {
    const data = await this.rbcAPI!.createPlan(input)
    if (data) {
      const plan = new Plan(data)
      if (plan) {
        this.updateModelItem("plans", plan.id, data, plan)
      }
      return plan
    } else {
      return undefined
    }
  }

  async updatePlan(input: APITypes.UpdatePlanInput) {
    let data = await this.rbcAPI!.updatePlan(input)
    if (data) {
      // HACK: For some reason updatePlan isn't returning the PlanChanges
      data = await this.rbcAPI!.getPlan(input.id)
      const plan = new Plan(data)
      // this.updateModelItem("plans", plan.id, data, plan)
      if (this.currentModel && this.currentModel.id === plan.modelId) {
        const index = this.currentModel.plans.findIndex((p: Plan) => p.id === plan.id)
        if (index) {
          this.currentModel.plans[index] = plan
          Model.sortPlans(this.currentModel.plans)
        }
      }
      return plan
    } else {
      return undefined
    }
  }


  deletePlan = async (planId: string): Promise<Plan | undefined> => {
    const data = await this.rbcAPI.deletePlan(planId)
    if (data) {
      const plan = new Plan(data)
      if (plan) {
        this.deleteModelItem("plans", plan.id)
      }
      return plan
    } else {
      return undefined
    }
  }

  // PlanChange methods

  getPlanChange = async (planChangeId: string): Promise<PlanChange | undefined> => {
    const data = await this.rbcAPI.getPlanChange(planChangeId)
    let planChange: PlanChange | undefined
    if (data) {
      switch (data.changeType) {
        case PlanChangeType.ConversionStrategy:
          planChange = new ConversionStrategyChange(data)
          break
        case PlanChangeType.ExpenseStrategy:
          planChange = new ExpenseStrategyChange(data)
          break
        case PlanChangeType.GrowthStrategy:
          planChange = new GrowthStrategyChange(data)
          break
        case PlanChangeType.InflationStrategy:
          planChange = new InflationStrategyChange(data)
          break
        case PlanChangeType.SocialSecurityStrategy:
          planChange = new SocialSecurityStrategyChange(data)
          break
        case PlanChangeType.TimelineStrategy:
          planChange = new TimelineStrategyChange(data)
          break
        case PlanChangeType.WithdrawalStrategy:
          planChange = new WithdrawalStrategyChange(data)
          break
        default:
          planChange = new PlanChange(data)
          break
      }
      this.updatePlanChangeItem(data, planChange)
    }
    return planChange
  }

  async createPlanChange(input: APITypes.CreatePlanChangeInput) {
    const data = await this.rbcAPI!.createPlanChange(input)
    let planChange: PlanChange | undefined
    if (data) {
      switch (data.changeType) {
        case PlanChangeType.ConversionStrategy:
          planChange = new ConversionStrategyChange(data)
          break
        case PlanChangeType.ExpenseStrategy:
          planChange = new ExpenseStrategyChange(data)
          break
        case PlanChangeType.GrowthStrategy:
          planChange = new GrowthStrategyChange(data)
          break
        case PlanChangeType.InflationStrategy:
          planChange = new InflationStrategyChange(data)
          break
        case PlanChangeType.SocialSecurityStrategy:
          planChange = new SocialSecurityStrategyChange(data)
          break
        case PlanChangeType.TimelineStrategy:
          planChange = new TimelineStrategyChange(data)
          break
        case PlanChangeType.WithdrawalStrategy:
          planChange = new WithdrawalStrategyChange(data)
          break
        default:
          planChange = new PlanChange(data)
          break
      }
      this.updatePlanChangeItem(data, planChange)
    }
    return planChange
  }

  async updatePlanChange(input: APITypes.UpdatePlanChangeInput) {
    const data = await this.rbcAPI!.updatePlanChange(input)
    let planChange: PlanChange | undefined
    if (data) {
      switch (data.changeType) {
        case PlanChangeType.ConversionStrategy:
          planChange = new ConversionStrategyChange(data)
          break
        case PlanChangeType.ExpenseStrategy:
          planChange = new ExpenseStrategyChange(data)
          break
        case PlanChangeType.GrowthStrategy:
          planChange = new GrowthStrategyChange(data)
          break
        case PlanChangeType.InflationStrategy:
          planChange = new InflationStrategyChange(data)
          break
        case PlanChangeType.SocialSecurityStrategy:
          planChange = new SocialSecurityStrategyChange(data)
          break
        case PlanChangeType.TimelineStrategy:
          planChange = new TimelineStrategyChange(data)
          break
        case PlanChangeType.WithdrawalStrategy:
          planChange = new WithdrawalStrategyChange(data)
          break
        default:
          planChange = new PlanChange(data)
          break
      }
      this.updatePlanChangeItem(data, planChange)
    }
    return planChange
  }

  deletePlanChange = async (planChangeId: string): Promise<PlanChange | undefined> => {
    const data = await this.rbcAPI.deletePlanChange(planChangeId)
    if (data) {
      const planChange = new PlanChange(data)
      this.deletePlanChangeItem(planChange)
      return planChange
    } else {
      return undefined
    }
  }

  updatePlanChangeItem = (data: object | undefined, update: PlanChange) => {
    const planId = update.planId
    const id = update.id
    const updatedAt = update["updatedAt"] ?? getISODateTime()
    if (this.currentPlan && this.currentPlan.id === planId && this.currentModel) {
      let array = this.currentPlan.changes
      if (array) {
        const index = array.findIndex((item: any) => item["id"] === id)
        if (index >= 0) {
          array[index] = update
        } else {
          array.push(update)
        }
        this.currentPlan.updatedAt = updatedAt
        this.currentModel.updatedAt = updatedAt
      }
      if (data) {
        let modelData = this.modelData.items.find((item: any) => item.id === this.currentModel!.id)
        if (modelData && modelData.plans && modelData.plans.items) {
          const planItem = modelData.plans.items.find((item: APITypes.Plan) => item.id === planId)
          if (planItem && planItem.changes && planItem.changes.items) {
            const planChangeItems = planItem.changes.items
            const index = planChangeItems.findIndex((item: APITypes.PlanChange) => item.id === id)
            if (index >= 0) {
              planChangeItems[index] = data
            } else {
              planChangeItems.push(data)
            }
            modelData.updatedAt = updatedAt
          }
        }
      }
    }

    this.updatedAt = updatedAt
  }

  deletePlanChangeItem = (change: PlanChange) => {
    const model = this.models.find((m: Model) => m.id === change.modelId)
    if (model) {
      const plan = model.plans.find((p: Plan) => p.id === change.planId)
      if (plan) {
        let changes = plan.changes
        if (changes) {
          const index = changes.findIndex((item: any) => item["id"] === change.id)
          if (index >= 0) {
            changes.splice(index, 1)
          }
        }
      }
    }

    // Update data
    let modelData = this.modelData.items.find((item: any) => item.id === change.modelId)
    if (modelData) {
      const planData = modelData.plans.items.find((p: any) => p.id === change.planId)
      if (planData) {
        const changes = planData.changes.items
        const index = changes.findIndex((item: any) => item.id === change.id)
        if (index >= 0) {
          changes.splice(index, 1)
        }
      }
    }
  }

  commitPlanChanges = async (plan: Plan, model: Model) => {
    try {
      const promises: Promise<void>[] = []
      plan.changes.forEach((change: PlanChange) => {
        if (change.enabled) {
          promises.push(change.commit(model, this))
        }
      })
      await Promise.all(promises)

      // Delete PlanChanges
      const deletePromises: Promise<PlanChange | undefined>[] = []
      plan.changes.forEach((change: PlanChange) => {
        if (change.enabled && change.changeType !== PlanChangeType.GrowthStrategy) {
          deletePromises.push(this.deletePlanChange(change.id))
        }
      })
      await Promise.all(deletePromises)

      // Reload current plan
      this.currentPlan = await this.getPlan(plan.id)
      return this.getAppliedModel(model.id,true)
    } catch (err: any) {
      console.log(`Error committing plan ${err.message}`)
      throw err
    }
  }

}

export default ModelStore