import Person from "../../../model/Person";
import {Box, FormLabel, Grid, Typography, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import PersonEditPopper from "./PersonEditPopper";
import {Gender, MaritalStatus} from "../../../API";
import {isoToLocalDateString} from "../../../stores/StoreUtilities";
import ModelCard2 from "../../../components/model/ModelCard2";
import {FemaleIcon, MaleIcon} from "../../../styles/Icons";
import PersonEditDialog from "./PersonEditDialog";
import Model from "../../../model/Model";
import {globalColors} from "../../../styles/globalStyles";
import WarningIcon from "@mui/icons-material/Warning";
import Expense from "../../../model/Expense";


const PersonCard = ({
  person,
  model,
  onSave,
  onDelete
}: {
  person: Person
  model: Model
  onSave?(person: Person): void
  onDelete?(deleted: Person): void
}) => {
  const [personModel, setPersonModel] = useState<Person>(person)
  const [title, setTitle] = useState<string>("")
  const [gender, setGender] = useState<Gender | undefined>()
  const [maritalStatus, setMaritalStatus] = useState<MaritalStatus | undefined>()
  const [birthDate, setBirthDate] = useState<string>("")
  const [retireDate, setRetireDate] = useState<string>("")
  const [retireDateLock, setRetireDateLock] = useState<boolean>(false)
  const [residenceState, setResidenceState] = useState<string>("")
  const [hereditaryAdjust, setHereditaryAdjust] = useState<string>("")
  const [hereditaryAdjustLock, setHereditaryAdjustLock] = useState<boolean>(false)
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const [editOpen, setEditOpen] = useState<boolean>(false)

  useEffect(() => {
    init(person)
  }, [person])

  const init = (person: Person) => {
    setPersonModel(person)
    setTitle(person.nickname)
    setGender(person.gender)
    setMaritalStatus(person.maritalStatus)
    setBirthDate(isoToLocalDateString(person.birthDate, "M/yyyy"))
    setRetireDate(isoToLocalDateString(person.retireDate, "M/d/yyyy"))
    setRetireDateLock(model.hasLock(person.id, "retireDate"))
    setResidenceState(person.state)
    setHereditaryAdjust(person.hereditaryAdjust > 0 ? String(person.hereditaryAdjust) : "")
    setHereditaryAdjustLock(model.hasLock(person.id, "hereditaryAdjust"))
    setIsComplete(person.isComplete)
    setEditOpen(!person.createdAt)
  }

  const theme = useTheme()

  const styles = {
    heading: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    row: {
      height: 40,
      minHeight: 40,
      maxHeight: 40,
    },
    formLabel: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "1.0em",
      maxHeight: 15,
      marginBottom: "1px",
      textWrap: "nowrap",
      color: theme.palette.grey["700"]
    },
    warningLabel: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "1.0em",
      maxHeight: 15,
      marginBottom: "1px",
      textWrap: "nowrap",
      color: globalColors.warning
    }
  }

  const renderContent = () => {
    return (
      <Grid container direction="column" spacing={0}>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={gender ? styles.formLabel : styles.warningLabel}>Gender</FormLabel>
              <Typography variant="body2">
                {gender}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormLabel sx={birthDate ? styles.formLabel : styles.warningLabel}>Birth Date</FormLabel>
              <Typography variant="body2">
                {birthDate}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormLabel sx={retireDate ? styles.formLabel : styles.warningLabel}>Est Retire Date</FormLabel>
              <Typography variant="body2">
                {retireDate}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.row}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={4}>
              <FormLabel sx={maritalStatus ? styles.formLabel : styles.warningLabel}>Marital Status</FormLabel>
              <Typography variant="body2">
                {maritalStatus}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>State</FormLabel>
              <Typography variant="body2">
                {residenceState}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormLabel sx={styles.formLabel}>Life Exp Adj</FormLabel>
              <Typography variant="body2">
                {hereditaryAdjust}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <ModelCard2
      icon={personModel.gender === Gender.Male ? <MaleIcon/> : <FemaleIcon/>}
      title={title}
      expanded={true}
      editButtonId={`edit-btn-${personModel.id}`}
      onEdit={(event: any) => {
        setEditOpen(!editOpen)
      }}
    >
      {renderContent()}
      {editOpen &&
        <PersonEditDialog person={personModel} model={model}
                          open={editOpen}
                          onSave={(update: Person) => {
                            init(update)
                            setEditOpen(false)
                            if (onSave) {
                              onSave(update)
                            }
                          }}
                          onDelete={(deleted: Person) => {
                            setEditOpen(false)
                            if (onDelete) {
                              onDelete(deleted)
                            }
                          }}
                          onClose={() => {
                            setEditOpen(false)
                            if (!personModel.createdAt && onDelete) {
                              onDelete(personModel)
                            }
                          }}/>
      }
    </ModelCard2>
  )
}

export default PersonCard