import React, {useEffect, useState} from 'react'
import Chart from "react-apexcharts";
import {IBudgetCalc} from "../../../../components/calculator/Calculator";
import {numberToMoneyFormat} from "../../../../stores/StoreUtilities";
import {globalColors} from "../../../../styles/globalStyles"
import {Paper, TableContainer} from "@mui/material";
import MonthlyBudgetTable from "./MonthlyBudgetTable";
import cloneDeep from "lodash.clonedeep";
import {useResources} from "../../../../stores/ResourceProvider";

const defaultOptions = {
  chart: {
    id: "budget-projection",
    type: 'bar',
    stacked: false,
    height: 350,
    width: "100%",
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      }
    },
    toolbar: {
      show: false,
      tools: {
        download: false
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    position: 'top', // TODO: Figure out why this isn't working
    horizontalAlign: 'left',
    onItemClick: {
      toggleDataSeries: false
    }
  },
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    axisBorder: {
      show: true
    }
  },
  yaxis: {
    labels: {
      formatter: (value: number) => {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  tooltip: {
    y: {
      formatter: function (value: number) {
        return numberToMoneyFormat(value, 0)
      }
    }
  },
  colors: [globalColors.income, globalColors.deductionExpense, globalColors.taxExpense, globalColors.essentialExpense, globalColors.discretionaryExpense],
  responsive: [{
    breakpoint: undefined,
    options: {}
  }]
}

const MonthlyBudgetApexChart = ({
  budgetCalc,
  onChange
}: {
  budgetCalc: IBudgetCalc
  onChange?: (month?: number) => any
}) => {
  const [options, setOptions] = useState<any>()
  const [series, setSeries] = useState<any>()

  const { userStore } = useResources()

  useEffect(() => {
    calculateData()
  }, [budgetCalc])

  // const monthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const handleChangeMonth = (month?: number) => {
    if (onChange) {
      onChange(month)
    }
  }

  const calculateData = () => {
    if (!budgetCalc) {
      return
    }
    const isFree = userStore.isFree

    let incomeData = []
    let deductionData = []
    let taxData = []
    let essentialData = []
    let discretionaryData = []
    for (let month = 0; month < 12; month++) {
      incomeData.push(budgetCalc.incomeSummary.byMonth[month])
      deductionData.push(budgetCalc.deductionSummary.byMonth[month])
      if (isFree) {
        taxData.push(budgetCalc.taxSummary.byMonth[month])
      } else {
        taxData.push(budgetCalc.totalEstimatedTaxes / 12)
      }
      essentialData.push(budgetCalc.expenseSummary.byMonthEssential[month])
      discretionaryData.push(budgetCalc.expenseSummary.byMonthDiscretionary[month])
    }

    const series = [
      {
        name: "Income",
        group: "Income",
        data: incomeData
      },
      {
        name: "Deductions & Contributions",
        group: "Expenses",
        data: deductionData
      },
      {
        name: isFree ? "Tax Withheld" : "Estimated Taxes",
        group: "Expenses",
        data: taxData
      },
      {
        name: "Essential",
        group: "Expenses",
        data: essentialData
      },
      {
        name: "Discretionary",
        group: "Expenses",
        data: discretionaryData
      },
    ]
    setSeries(series)
    setOptions(defaultOptions)
  }

  if (options && series) {
    return (
      <React.Fragment>
        <TableContainer component={Paper} sx={{paddingTop:1, overflowY:"hidden"}}>
          <Chart
            options={options}
            series={series}
            type="bar"
            height="500"
            width="100%"
          />
        </TableContainer>

        <MonthlyBudgetTable budgetCalc={budgetCalc} onChange={handleChangeMonth}/>
      </React.Fragment>
    )
  } else {
    return null
  }
}

export default MonthlyBudgetApexChart