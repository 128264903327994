import PlanChange from "../PlanChange";
import Model from "../Model";
import WithdrawalStrategy, {WithdrawalGroup} from "../WithdrawalStrategy";
import Asset, {AssetCategory} from "../Asset";
import ModelStore from "../../stores/ModelStore";
import {UpdateAssetInput} from "../../API";

class WithdrawalStrategyChange extends PlanChange {
  _withdrawalStrategy: WithdrawalStrategy

  constructor (data: any) {
    super(data)
    this._withdrawalStrategy = new WithdrawalStrategy(this.details ? JSON.parse(this.details) : {})
  }

  get withdrawalStrategy(): WithdrawalStrategy {
    return this._withdrawalStrategy
  }

  set withdrawalStrategy(value: WithdrawalStrategy) {
    this._withdrawalStrategy = structuredClone(value)
    this.details = JSON.stringify(this._withdrawalStrategy)
  }

  get withdrawalStrategyType () {
    return this._withdrawalStrategy.withdrawalStrategyType
  }

  set withdrawalStrategyType(value) {
    this._withdrawalStrategy.withdrawalStrategyType = value
  }

  get withdrawalGroups () {
    return this._withdrawalStrategy.withdrawalGroups
  }

  set withdrawalGroups(value: WithdrawalGroup[]) {
    this._withdrawalStrategy.withdrawalGroups = value
  }

  apply(model: Model) {
    // Update liquid asset withdrawalOrder and remove reserved assets
    const liquidAssets = model.assets.filter((a: Asset) => a.assetCategory === AssetCategory.LiquidInvestableAssets)

    this._withdrawalStrategy.withdrawalGroups.forEach((g: WithdrawalGroup) => {
      g.assetIds.forEach((id: string) => {
        const index = liquidAssets.findIndex((a: Asset) => a.id === id)
        if (index >= 0) {
          liquidAssets[index].withdrawalOrder = g.withdrawalOrder
          model.addLock(id, "withdrawalOrder")
          liquidAssets.splice(index, 1)
        }
      })
    })
    // Set withdrawalOrder to -1 to any remaining (reserved) assets
    liquidAssets.forEach((a: Asset) => {
      a.withdrawalOrder = 999
      model.addLock(a.id, "withdrawalOrder")
    })
  }

  async commit(model: Model, modelStore: ModelStore) {
    const liquidAssets = model.assets.filter((a: Asset) => a.assetCategory === AssetCategory.LiquidInvestableAssets)

    const promises: Promise<Asset | undefined>[] = []

    this._withdrawalStrategy.withdrawalGroups.forEach((g: WithdrawalGroup) => {
      g.assetIds.forEach((id: string) => {
        const index = liquidAssets.findIndex((a: Asset) => a.id === id)
        if (index >= 0) {
          const asset = liquidAssets[index]
          asset.withdrawalOrder = g.withdrawalOrder
          const update: UpdateAssetInput = {
            id: asset.id,
            accountId: asset.accountId,
            withdrawalOrder: g.withdrawalOrder
          }
          promises.push(modelStore.updateAsset(update))
          liquidAssets.splice(index, 1)
        }
      })
    })
    // Set withdrawalOrder to -1 to any remaining (reserved) assets
    liquidAssets.forEach((asset: Asset) => {
      asset.withdrawalOrder = -1
      const update: UpdateAssetInput = {
        id: asset.id,
        accountId: asset.accountId,
        withdrawalOrder: -1
      }
      promises.push(modelStore.updateAsset(update))
    })

    await Promise.all(promises)
  }
}

export default WithdrawalStrategyChange