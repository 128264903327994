import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { RouteComponentProps } from '@reach/router'
import DialogButton from '../form/DialogButton'
import {inject, observer} from "mobx-react";
import { observable, makeObservable } from "mobx";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import {UserStoreConstants} from "../../stores/UserStore";
import Confirm from "../confirm/Confirm";
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Stack,
  Switch,
  Typography
} from "@mui/material";
import CancelButton from '../form/CancelButton';
import ProgressButton from "../form/ProgressButton";
import { Link as RouterLink } from 'react-router-dom';
import RoutesConfig from '../../RoutesConfig';
import Notify from "../notify/Notify";
import {UserStatus} from "../../API";
import globalStyles from "../../styles/globalStyles";
import config from 'react-global-configuration';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {advisorSettingsKey} from "../../pages/calc/CalcPage";
import Tracking from "../Tracking";


interface ISignInDialogProps {
    onSignIn: any,
    onLegacySignIn(legacyUser: any): void,
    onClose: any,
    onShowPasswordAssist: any
    onShowSignUp: any
    userStore?: any // UserStore
    accountStore?: any
    modelStore?: any
    migrateAPI?: any
    confirm?: Confirm
    notify?: Notify
    isShownAsModal?: boolean 
}

@inject("userStore", "accountStore", "modelStore", "confirm", "notify", "migrateAPI")
@observer
class SignInDialog extends React.Component<RouteComponentProps & ISignInDialogProps> {
  @observable values = {
    username: "",
    password: "",
    newPassword: "",
    mfaCode: "",
    userId: "",
    phone: "",
    code: ""
  }
  @observable message = ""
  @observable newPasswordRequired = false
  @observable smsMfaRequired = false
  @observable rememberDevice = false
  @observable showPassword = false
  @observable isProcessing = false

  private cognitoUser: any

  onClose = () => {
    if (this.props.onClose) {
      this.message = ""
      this.props.notify!.close()
      this.props.onClose()
    }
  };

  onSignIn = () => {
    this.props.onSignIn()
  }

  onSubmit = async () => {
    const { userStore, accountStore, modelStore, notify } = this.props

    this.isProcessing = true
    this.message = ""
    notify!.close()

    if (this.smsMfaRequired) {
      try {
        const user = await userStore.confirmSignIn(this.cognitoUser, this.values.mfaCode, "SMS_MFA")
        if (user) {
          if (this.rememberDevice) {
            await userStore.rememberDevice()
          }

          await accountStore!.loadAccount(user.accountId)
          await modelStore.loadModels(user)
        }
        this.isProcessing = false
        if (this.props.onSignIn) {
          this.message = "";
          this.onSignIn()
        }
      } catch (err: any) {
        this.isProcessing = false
        if (err.code === "CodeMismatchException") {
          this.message = "Code does not match. Please recheck or request a new code."
        } else {
          this.message = err.message
        }
      }
      return
    }

    const password = this.values.password

    userStore!.signIn(this.values.username, password)
      .then(async (user: any) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          if (this.values.newPassword) {
            userStore!.completeNewPassword(user, this.values.newPassword)
              .then((data: any) => {
                // SignIn with the new password
                userStore!.signIn(this.values.username, this.values.newPassword)
                  .then(async (user2: any) => {
                    if (userStore!.user && userStore!.user.accountId) {
                      await accountStore!.loadAccount(userStore!.user.accountId)
                      await modelStore.loadModels(userStore!.user)
                    }
                    this.isProcessing = false
                    if (this.props.onSignIn) {
                      this.message = "";
                      this.onSignIn()
                    }
                  })
                  .catch((err: any) => {
                    this.message = err.message
                    this.isProcessing = false
                  });
              })
              .catch((err: any) => {
                this.message = err.message
                this.isProcessing = false
              });
          } else {
            // Prompt for new password
            this.newPasswordRequired = true
            this.message = "A new password is required"
            this.isProcessing = false
          }
        } else if (user.challengeName === "SMS_MFA") {
          this.cognitoUser = user
          this.smsMfaRequired = true
          this.isProcessing = false
        } else {
          // userStore!.cognitoUser = user
          if (userStore!.user) {
            if (userStore!.user.userStatus === UserStatus.Cancelled || userStore!.user.userStatus === UserStatus.Deleted) {
              const errorMsg = `This account has been ${userStore!.user.userStatus}. Please contact support if you need assistance.`
              notify!.show("error", errorMsg)
              Tracking.event({action: "User Sign In Error", label: errorMsg})
              await userStore.signOut()
              throw new Error(errorMsg)
            } else {
              // Clear advisor settings
              if (userStore.isAdvisorOrAdmin) {
                window.localStorage.removeItem(advisorSettingsKey)
              }
              // Disabled opening AI Chat panel every sign-in
              // if (!userStore.isFree) {
              //   // Open chat drawer
              //   const pageViewSettings = {
              //     chatOpen: true
              //   }
              //   window.localStorage.setItem(`${userStore.user!.id}.PageViewSettings`, JSON.stringify(pageViewSettings))
              // }
              await userStore.updateLastAccess(userStore!.user)
              await accountStore!.loadAccount(userStore!.user.accountId)
              await modelStore.loadModels(userStore!.user)
              Tracking.gtag("event", "login", {})
            }
          }
          if (this.props.onSignIn) {
            this.message = "";
            this.onSignIn()
          }
          this.isProcessing = false
        }
    })
    .catch((err: any) => {
      if (err.code === UserStoreConstants.USER_NOT_FOUND_EXCEPTION) {
        if (config.get("migrateOnSignin") === true) {
          this.legacySignin()
        } else {
          this.message = "Incorrect username or password"
        }
      } else if (err.message === UserStoreConstants.USER_NOT_CONFIRMED ||
        err.message === UserStoreConstants.EMAIL_VERIFICATION_PENDING ||
        err.message === UserStoreConstants.PHONE_VERIFICATION_PENDING) {
        // Signup is incomplete
        this.message = "Please complete your sign up."
    } else {
        this.message = err.message;
      }
      this.isProcessing = false
    });
  }

  legacySignin = async () => {
    const { migrateAPI, onLegacySignIn } = this.props

    let legacyUser
    try {
      legacyUser = await migrateAPI.authenticate(this.values.username, this.values.password)
      console.log(`legacyUser`, legacyUser)
      if (legacyUser) {
        legacyUser.user_password = this.values.password
        onLegacySignIn(legacyUser)
      }
    } catch (err: any) {
      if (err.message === "Incorrect username or password") {
        legacyUser = await migrateAPI!.verify(this.values.username)
          .catch((err: any) => {
            this.message = err.message
          })
        if (legacyUser) {
          // Go ahead with migration since we do an email verification there
          legacyUser.user_password = this.values.password
          onLegacySignIn(legacyUser)
          return
        }
      }
      this.message = err.message
      this.isProcessing = false
      return
    }

  }

  onForgotPassword = () => {
    if (this.props.onShowPasswordAssist) {
      this.message = "";
      this.props.onShowPasswordAssist()
    }
  }

  onResendCode = (event: any) => {
    event.preventDefault()
    const { userStore, notify } = this.props
    this.message = ""

    const password = this.values.password
    userStore!.signIn(this.values.username, password)
      .then(async (user: any) => {
        if (user.challengeName === "SMS_MFA") {
          this.cognitoUser = user
          this.smsMfaRequired = true
          this.isProcessing = false
          notify!.show("success", "Verification code resent")
        }
      })
      .catch((err: any) => {
        this.message = err.message;
      })
  }

  onShowSignUp = () => {
    if (this.props.onShowSignUp) {
      this.message = "";
      this.props.onShowSignUp()
    }
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  constructor(props: any) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { isShownAsModal } = this.props 
    return (
      <React.Fragment>
        { isShownAsModal ? 
          <Dialog
            id="signInDialog"
            open={true}
            onClose={this.onClose}
            scroll="paper"
            maxWidth="xs"
            fullWidth
            aria-labelledby="signIn-dialog-title"
          >
            {this.renderSignInForm()}    
          </Dialog>
        :
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              overflow: 'hidden',
              mx: {
                md: 'auto'
              },
              p: 2,
              // maxWidth: {
              //   md: theme.breakpoints.values.lg
              // },
            }}
          >
            <Typography variant="h1" gutterBottom>Sign in</Typography>
            <Typography variant="body1">Welcome back to Retirement Budget Calculator!</Typography>
            {this.renderSignInForm()}
          </Box>
        }
      </React.Fragment>
    );
  }

  renderSignInForm() {
    return (
      <FormValidator autoComplete="off" onSubmit={this.onSubmit} name="signInForm" id="signInForm">
        <Box sx={{ my: 2 }}>
          <TextFieldValidator
            autoFocus
            margin="normal"
            name="username"
            id="usernameInput"
            label="Email"
            type="email"
            validators={{required:true}}
            value={this.values.username}
            onChange={this.onChange}
            fullWidth
            autoCorrect="off"
            autoCapitalize="off"
            autoComplete="off"
            variant="standard"
            disabled={this.smsMfaRequired}
          />
          <TextFieldValidator
            margin="normal"
            name="password"
            id="passwordInput"
            label="Password"
            type={this.showPassword ? "text" : "password"}
            validators={{required:true, minLength:6}}
            value={this.values.password}
            onChange={this.onChange}
            fullWidth
            variant="standard"
            inputProps={{ autoComplete: 'current-password'}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => this.showPassword = !this.showPassword}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {this.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            disabled={this.smsMfaRequired}
          />
          {this.newPasswordRequired &&
            <TextFieldValidator
              margin="normal"
              name="newPassword"
              label="New Password"
              type="password"
              validators={{required:true, isStrongPassword:3}}
              value={this.values.newPassword}
              onChange={this.onChange}
              fullWidth
              helperText="Please enter a new password of at least 8 characters with at least one digit or symbol."
              variant="standard"
              inputProps={{ autoComplete: 'new-password' }}
            />            
          }
          {this.smsMfaRequired &&
            <Box>
              <TextFieldValidator
                margin="dense"
                name="mfaCode"
                label="Verification code"
                helperText="Check your phone for a text message verification code"
                type="text"
                variant="standard"
                value={this.values.mfaCode}
                validators={{required:true, matches:"^\\d{6}$"}}
                onChange={this.onChange}
                fullWidth
              />
              <Box display="flex" flexGrow={1} justifyContent="space-between">
                <FormControlLabel control={
                  <Switch checked={this.rememberDevice}
                          onChange={(event: any) => {
                            this.rememberDevice = event.target.checked
                          }}
                  />
                }
                                  label="Remember this device"
                />
                <DialogButton variant="tertiary" type="button" onClick={this.onResendCode}>
                  Resend verification code
                </DialogButton>
              </Box>
            </Box>
          }
          {this.message && 
            <DialogContentText 
              sx={{
                color: 'red',
                marginTop: "10px"
              }}
            >
              {this.message}
            </DialogContentText>
          }
          {!this.smsMfaRequired &&
            <Stack direction="row" spacing={1} sx={{ pt: 2 }}>
              <Typography>Don't have an account?</Typography>
              <Link
                component={RouterLink}
                to={RoutesConfig.signUp.pathname!}
              >
                <Typography>Sign Up</Typography>
              </Link>
            </Stack>
          }
        </Box>
        <Grid container>
          <Grid item xs={4}>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <DialogButton variant="secondary" size="medium" type="button" onClick={this.onForgotPassword}>
                  Forgot&nbsp;Password?
                </DialogButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container justifyContent="flex-end">
              <CancelButton onClick={this.onClose} />
              <ProgressButton variant="contained" size="medium" color="primary" sx={{width:120}}
                              type="submit" processing={this.isProcessing}>
                Sign In
              </ProgressButton>
            </Grid>
          </Grid>
        </Grid>
      </FormValidator>
    )
  }
}

export default SignInDialog
