import * as React from "react";
import {ButtonProps} from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";

interface IProgressButtonProps {
  processing: boolean
  fullWidth?: boolean
  children?: any
}

export type ProgressButtonProps = ButtonProps | IProgressButtonProps

class ProgressButton extends React.Component<ProgressButtonProps> {

  state = {
    processing: false
  }

  componentDidUpdate(prevProps: any) {
    const processing = (this.props as IProgressButtonProps).processing
    if (processing !== prevProps.processing) {
      this.setState({processing})
    }
  }

  render() {
    const {
      processing,
      children,
      ...rest
    } = this.props as IProgressButtonProps

    return (
      <LoadingButton 
        loading={this.state.processing}
        {...rest}
      >
        {children}
      </LoadingButton>    
    )
  }
}

export default ProgressButton
