import * as React from "react";
import { Provider } from "mobx-react";

export interface IFieldValidator {
  validate?(value: string): boolean
}

interface IFormValidatorProps {
  autoComplete?: any
  name?: string
  className?: any
  onSubmit?: any
  id?: string
  children: React.ReactNode
}

class FormValidator extends React.Component<IFormValidatorProps> {

  state = {
    isValid: true
  }

  fields: any[]

  constructor(props: any) {
    super(props);
    this.fields = []
  }

  render() {
    const {
      onSubmit,
      ...rest
    } = this.props

    const resources = {
      formvalidator: this
    }

    return (
      <Provider {...resources}>
        <form
          {...rest}
          onSubmit={this.onSubmit}
          noValidate={true}
        >
          {this.props.children}
        </form>
      </Provider>
    )
  }

  attachToForm = (component: any) => {
    this.fields.push(component)
  }

  private onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    const { onSubmit } = this.props

    // Validate fields
    const isValid = this.validateFields()
    this.setState({isValid})

    if (isValid && onSubmit) {
      onSubmit(event);
    }
  }

  public validateFields = (): boolean => {
    let isValid = true

    this.fields.forEach((field: any) => {
      if (field.validate) {
        const isFieldValid = field.props.autocompleteOptions ? field.validate(field.props.autocompleteOptions.value) : field.validate(field.props.value)
        if (!isFieldValid) {
          isValid = false
        }
      }
    })

    return isValid
  }
}

export default FormValidator