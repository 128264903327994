import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogButton from '../form/DialogButton'
import { observable, makeObservable } from "mobx";
import {inject, observer} from "mobx-react";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Tracking from "../Tracking";
import {Box, Grid, Paper, Stack, Typography} from "@mui/material";
import CancelButton from '../form/CancelButton';
import globalStyles from "../../styles/globalStyles";
import ProgressButton from "../form/ProgressButton";

interface IPasswordAssistDialogProps {
  onShowSignIn: any,
  onClose: any,
  userStore?: any // UserStore
  confirm?: any
  notify?: any
  isShownAsModal?: boolean 
}

@inject("userStore", "confirm", "notify")
@observer
class PasswordAssistDialog extends React.Component<IPasswordAssistDialogProps> {
  @observable values = {
    email: "",
    code: "",
    newPassword: ""
  }

  @observable email = ""
  @observable message = ""
  @observable reset = false

  onClose = () => {
    if (this.props.onClose) {
      this.message = ""
      this.reset = false
      this.props.onClose();
    }
  };

  onSubmit = () => {
    const { userStore, notify } = this.props
    this.message = ""

    if (!this.reset) {
      userStore.forgotPassword(this.values.email)
        .then((data: any) => {
          Tracking.event({action: "Password reset verification sent"})
          this.message = "Please check your phone or email for a password reset verification code."
          this.reset = true
        })
        .catch((err: any) => {
            this.message = err.message;
         })
    } else {
      userStore.forgotPasswordSubmit(this.values.email, this.values.code, this.values.newPassword)
        .then((data:any) => {
          Tracking.event({action: "Password reset"})
          if (this.props.onShowSignIn) {
            this.message = ""
            this.reset = false
            notify!.show("info", "Your password has been reset. Please sign in using the new password.")
            this.props.onShowSignIn();
          }
        })
        .catch((err:any) => {
          this.message = err.message
        });

    }
  }

  onResendCode = () => {
    const { userStore } = this.props
    this.message = ""

    userStore.forgotPassword(this.values.email)
      .then((data: any) => {
        console.log(data)
        Tracking.event({action: "Password reset verification resent"})
        this.message = "Please check your phone or email for a password reset verification code."
      })
      .catch((err:any) => {
        this.message = err.message
      });
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  constructor(props: any) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { isShownAsModal } = this.props 
    return (
      <React.Fragment>
        { isShownAsModal === true ? 
          <Dialog
            id="passwordAssistDialog"
            open={true}
            onClose={this.onClose}
            scroll="paper"
            maxWidth="xs"
            fullWidth
            aria-labelledby="password-assist-dialog-title"
          >
            {this.renderLoginForm()}
          </Dialog>
        :
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              overflow: 'hidden',
              mx: {
                md: 'auto'
              },
              p: 2,
              // maxWidth: {
              //   md: theme.breakpoints.values.lg
              // },
            }}
          >
            <Typography variant="h1" gutterBottom>
              Password Assistance
            </Typography>
            <Typography variant="body1">
              Enter the email address associated with your account.
            </Typography>
            <Typography variant="body2" sx={{mt:1}}>
              <em>Note: If this is your first sign-in to the new RBC Pro, Password Assistance will not work.
                Instead, please email <a href="mailto:support@retirementbudgetcalculator.com">support@retirementbudgetcalculator.com</a> for assistance.</em>
            </Typography>

            {this.renderLoginForm()}
          </Box>
        }

      </React.Fragment>
    );
  }

  renderLoginForm() {
    return (
      <FormValidator onSubmit={this.onSubmit} autoComplete="off">
        <Box sx={{ my: 2 }}>
          <TextFieldValidator
            autoFocus
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            validators={{required:true, isEmail:null}}
            value={this.values.email}
            onChange={this.onChange}
            fullWidth
            variant="standard"
          />
          <DialogContentText 
            variant="body2"
            sx={{
              color: 'blue'
            }}
          >
            {this.message}
          </DialogContentText>
          {this.reset &&
            <TextFieldValidator
              autoFocus
              margin="dense"
              name="code"
              label="Verification Code"
              type="text"
              validators={{required:true, matches:"^\\d{6}$"}}
              value={this.values.code}
              onChange={this.onChange}
              fullWidth 
              variant="standard"
            />
          }
          {this.reset &&
            <TextFieldValidator
              margin="dense"
              name="newPassword"
              label="New Password"
              type="password"
              validators={{required:true, isStrongPassword:3}}
              value={this.values.newPassword}
              onChange={this.onChange}
              autoComplete="new-password"
              fullWidth 
              variant="standard"
            />
          }
        </Box>
        {/*<Stack direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{ pb: 2 }}>*/}
        {/*  <Typography>Or, go back to </Typography>*/}
        {/*  <DialogButton size="medium" variant="secondary" onClick={this.props.onShowSignIn}>*/}
        {/*    Sign in*/}
        {/*  </DialogButton>*/}
        {/*</Stack>*/}
          <Grid container justifyContent="space-between">
            <Grid item>
              {this.reset &&
                <DialogButton variant="secondary" onClick={this.onResendCode}>
                  Resend verification code
                </DialogButton>
              }
            </Grid>
            <Grid item>
              <Stack direction="row">
                { this.props.isShownAsModal === true && <CancelButton onClick={this.onClose} /> }
                <CancelButton onClick={this.props.onShowSignIn} />
                <ProgressButton variant="contained" type="submit" size="medium" color="secondary" sx={{width:120, ...globalStyles.webFlowLargeButton}}>
                  Continue
                </ProgressButton>
              </Stack>
            </Grid>
          </Grid>
      </FormValidator>
    )
  }
}

export default PasswordAssistDialog
